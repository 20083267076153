import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['cms'].url + ':' + lstModules['cms'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getBannerListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/banner/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstBanner = response.data.lstBanner
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function getBannerSvc (pBannerId, pos, pth, callBack) {
  axios({
    url: httpURL + '/sms/cms/img/view',
    method: 'GET',
    params: { imgPath: pth },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(pos, pBannerId, response)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
function saveBannerSvc (file, pBannerId, callBack) {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('bannerId', pBannerId)

  axios({
    url: httpURL + '/sms/cms/banner/img/upload',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data, pBannerId)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
function deleteBannerSvc (pBannerId, callBack) {
  // console .log('pBannerId = ' + pBannerId)
  axios({
    url: httpURL + '/sms/cms/banner/img/delete',
    method: 'POST',
    params: { bannerId: pBannerId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

export { getBannerListSvc, getBannerSvc, saveBannerSvc, deleteBannerSvc }
