import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

// project related common imports

import lstModules from 'common/json/lstModules.json';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import TermTable from './termTable.js';
import TermForm from './termForm.js';

const TermCtrl = () => {
    const [tableLoader, setTableLoader] = useState('none')
    const [formLoader, setFormLoader] = useState('none')
    const [termObj, setTermObj] = useState({})
    const [termId, setTermId] = useState(undefined)
    const [randNo, setRandNo] = useState(0);
    
    function termClick(pTermObj) {
        // console .log('termCtrl: termClick() '+JSON.stringify(pTermObj))
        setTermId(pTermObj.termId)
        setTermObj(pTermObj);
    }
    function generateRandomNo() {
        // setTermId(pTermId)
        setRandNo(Math.floor(Math.random() * 999999))
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" >
                <div className="col-md-11 col-centered clearfix">
                    <div className="col-md-6 col-xs-6">
                        <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: tableLoader }}>
                            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                        </div>

                        <TermTable rowClick={termClick} key={randNo} termId={termId}/>

                    </div>

                    <div className="col-md-4  col-xs-12" >
                        <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: formLoader }}>
                            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                        </div>
                        <TermForm setFormLoader={setFormLoader} rowClick={termClick} termObj={termObj} refreshTable={generateRandomNo} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TermCtrl