import React, { useEffect, useState } from "react";
import "sms/tt/teacher/css/tabList.css";
import "common/css/master.css";

const SectionList = (props) => {
  const [lstSection, setLstSection] = useState(props.lstSection);
  useEffect(() => {
    // console .log("useEffect for section")
    // console .log("props.selSectionId in useEffect in sectionList",props.selSectionId);
    setLstSection(props.lstSection);
    // console .log("props.lstSection in useEffect in sectionList",props.lstSection);
  }, [props.classId, props.sectionId, props.lstSection]);

  return (
    <div className="tab">
      {lstSection.map((obj) => {
        // console .log("obj.sectionId in lstsession.map in sectionList)",obj.sectionId, "props.selSectionId ",props.selSectionId);
        var isSelected = obj.sectionId == props.selSectionId;
        return (
          <div
            className={isSelected ? "sellected" : ""}
            defaultValue={props.selSectionId}
            onClick={() => props.sectionClick(obj.sectionId, obj.section)}
            key={obj.sectionId}
          >
            <span hidden>{obj.sectionId}</span>
            <span>{obj.section}</span>
            <br></br>
            <span>{obj.pct + "%"}</span>
          </div>
        );
      })}
    </div>
  );
};
export default SectionList;
