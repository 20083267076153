import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import SliderType from './SliderType'
import { viewSignSvc, saveStaffSignSvc } from './StaffSvc'
import '../css/StaffCtrl.css'

const StaffSign = props => {
  const [loader, setLoader] = useState('none')
  const [sign, setSign] = useState('')
  useEffect(() => {
    if (
      props.selectedUserId !== 0 &&
      props.flgSliderType == SliderType.SLIDER_SIGN
    ) {
      var promise = new Promise((resolve, reject) => {
        if (props.selectedUserId !== 0) viewSign(props.selectedUserId)
      })
    }
  }, [props.flgSliderType, props.selectedUserId, props.roleId])

  function viewSign (userId) {
    setLoader('block')
    viewSignSvc(userId, cbViewSignResp)
  }

  function cbViewSignResp (url, response) {
    // console .log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      setSign(url)
    }
    setLoader('none')
  }

  const saveStaffSign = e => {
    // console .log(e.target.files[0].name)
    // console .log('src:' + URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      setSign(URL.createObjectURL(e.target.files[0]))
      setLoader()
      saveStaffSignSvc(
        props.selectedUserId,
        props.roleId,
        e.target.files[0],
        cbSaveStaffSignResp
      )
    }
  }
  function handleSignClick () {
    // console .log(document.getElementById('img_logo'))
    // document.getElementById("img_logo").dispatchEvent(new Event('click'));
    document.getElementById('img_logo').click()
  }

  const onError = () => {
    // var errorURL = `https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350`
    var errorURL = window.appURL + '/no-image.png'
    setSign(errorURL)
  }

  function cbSaveStaffSignResp (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      setLoader('none')
    } else {
      toast.error(data.SvcMsg)
      setLoader('none')
      viewSign(props.selectedUserId)
    }
  }
  return (
    <form
      className='form form-horizontal'
      style={{
        width: '100%',
        margin: '0 auto',
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10,
        pointerEvents: loader === 'none' ? 'auto' : 'none',
        overflowY: 'hidden'
      }}
    >
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div
        className='EnquiryHead col-sm-12 col-centered'
        style={{
          border: '1px solid #aaa',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          fontWeight: 'bold'
        }}
      >
        <h4>Staff Sign</h4>
      </div>
      <div className='col-sm-12'>
        <label className='col-sm-2 control-label text-left'>Sign</label>
        <div
          style={{ padding: 0, display: 'flex', width: 100, height: 100 }}
          className='form-group col-sm-3'
        >
          <input
            docid='3'
            type='file'
            id='img_logo'
            name='img'
            onChange={saveStaffSign}
            className='hidden'
            accept='image/*'
          />
          <img
            style={{
              display: 'block',
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              border: 0,
              objectFit: 'fill'
            }}
            id='logo_img'
            src={sign}
            alt='img'
            onClick={e => handleSignClick(e)}
            onError={e => onError()}
          />
        </div>
      </div>
    </form>
  )
}
export default StaffSign
