import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import $ from "jquery";
import axios from 'axios';
import TableHead from 'common/js/tableHead';
import TableBodyTch from './tableBodyTch';

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;


export default class TeacherTable extends Component {
    constructor(props){
        super(props);
    }
    componentDidMount() {
        var wingId = 1;
        var classSectionId = 1;
        var classId = 4;
        this.setState({ loader: 'block', opacity: 0.5 });
        // console .log("subjectTable props in lstSubject: "+this.props.lstSubject);
    }
  
    teacherDragHandler=(e)=>{
        alert(e+"subject dragged"+e.target.id);
        // e.dataTransfer.setData("text",e.target.id);
    }

    clickAlert=(e)=>{
        alert("I am working: "+e);
    }

    dragStartHandler(e){
        // console .log("dragStartHandler: "+$(e.target).attr('id')+", "+$(e.target).text());
        e.dataTransfer.setData("text",$(e.target).text());
        e.dataTransfer.setData("id",$(e.target).attr('id'));
    }

    

    render() {
        return (
            <>
                <div className="subjectRefresh refreshButton">
                    <span className="glyphicon glyphicon-refresh" title="Refresh Subject" onClick={this.refreshSubject}></span>
                </div>
                <TableHead headCls={"tableLayout1 subjectWrap"} 
                            heads={[
                                    ["Subject Id",0,'no-display'],
                                    ["",20,''],
                                    ["Teacher",40,''],
                                    // ["Time\nAlloted",40,'br'],
                                    // ["Syllabus\nCovered",40,'br'],
                                    ["Remarks",60,''],
                                ]}
                />

                <div className="teacherTableDiv" style={{ height: 90, overflowY: "scroll", border: '1px solid #ccc' }}>
                    {
                        this.props.lstTeacher 
                            && 
                        this.props.lstTeacher.length > 0
                            &&
                        <TableBodyTch 
                            bodyCls ={"subjectTableDiv"} 
                            tableId="teacherTable" 
                            tbodyId="teacherTableBody" 
                            colswidth={[20,40,60]}
                            lstTeacher={this.props.lstTeacher}
                            onChildClick={this.clickAlert}
                            onDragStarted={(e)=>this.dragStartHandler(e)}
                        />
                    }
                </div>
            </>
        )
    }
}