import React from 'react';
function TableBody({ bodyCls, tableId, tbodyId, colswidth, lstSubject, onDragStarted}) {  
    // console .log("lstSubject = "+JSON.stringify(lstSubject));
    
    return (
          <div >
                <table id={tableId} className="tableLayout">
                <colgroup>
                {colswidth.map((width,idx)=>{
                    return  <col key={idx} width={width} ></col>
                })}
                </colgroup>
                <tbody id={tbodyId}> 
                {lstSubject.map((item,index)=>{   
                    
                    return <tr key={index}>
                        <td className="no-display">{item.subjectId}</td>
                        <td>{(index+1)}</td>
                        <td id={item.subjectId} draggable="true" onDragStart={onDragStarted}>{item.subject}</td>
                        <td>{item.timeAlloted}</td>
                        <td>{item.syllabusCovered}</td>
                        <td>{item.remarks}</td>
                    </tr>
                })}
                </tbody>
                </table>
          </div>
    );
}
export default TableBody;