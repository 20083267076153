import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import lstModules from 'common/json/lstModules.json';
import "common/css/master.css";
import Header from 'common/js/header/header';
import StudentPersonalDetails from "../studentPersonalDetails/studentPersonalDetails";
import { getClassListSvc, getStateListSvc, studentDetailsSvc, docDtlsSvc } from "./studentDetailsSvc";
import StudentCategory from "../studentCategoryDetails/studentCategory";
import StudentCommunication from "../StudentCommunication/studentCommunication";
import StudentParentDetails from "../studentParentDetails/studentParentDetails";
import StudentSiblingDetails from "../studentSiblings/studentSiblings";
import StudentHistory from "../studentHistory/studentHistory";
import StudentDocs from "../studentDocuments/studentDocs";


const StudentDetails = () => {

    const params = new URLSearchParams(window.location.search);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [studentId, setStudentId] = useState(0);
    const [lstCls, setLstCls] = useState([]);
    const [lstState, setLstState] = useState([]);
    const [personalDtls, setPersonalDtls] = useState({});
    const [categoryDtls, setCategoryDtls] = useState({});
    const [medicalDtls, setMedicalDtls] = useState({});
    const [historyDtls, setHistoryDtls] = useState({});
    const [studentPhotoPath, setStudentPhotoPath] = useState("");
    const [lstDocDtls, setLstDocDtls] = useState([]);
    // const [studentIdPath, setStudentIdPath] = useState("");
    // const [studentAgePath, setStudentAgePath] = useState("");
    // const [studentAddressPath, setStudentAddressPath] = useState("");
    // const [studentCategoryPath, setStudentCategoryPath] = useState("");

    useEffect(() => {
        // console .log("useEffect called in StudentDetails");
        const sId = params.get("studentId");
        setStudentId(sId);
        getClassList();
        getStateList();
        if (sId != 0) {
            getStudentDtls(sId);
            getStudentDocs(sId);
        }
    }, []);


    const getClassList = () => {
        setLoader("block");
        setOpacity(0.5);
        getClassListSvc(handleGetClassListResponse);
    };

    const handleGetClassListResponse = (data) => {
        // console .log("data in handleGetClassListResponse, data", data);
        const obj = data;
        if (obj.status === 'Success') {
            setLstCls(obj.lstCls);
            // Add any additional processing if needed
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    };

    const getStateList = () => {
        setLoader("block");
        setOpacity(0.5);
        getStateListSvc(handleGetStateListResponse);
    };

    const getStudentDocs = (pStudentId) => {
        setLoader("block");
        setOpacity(0.5);
        docDtlsSvc(pStudentId, docDetailsResponse);
    };

    const docDetailsResponse = (data) => {
        // console .log("docDetailsResponse called, data", data);
        const obj = data;
        if (obj.status === 'Success') {
            setDocPaths(obj.lstDocDtls);
            setLstDocDtls(obj.lstDocDtls);
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const setDocPaths = (pLstDocDtls) => {
        // console .log("setDocPaths called, pLstDocDtls", pLstDocDtls);
        const httpURLStudent = lstModules["students"].url + ":" + lstModules["students"].port;
        for (var i = 0; i < pLstDocDtls.length; i++) {
            var docObj = pLstDocDtls[i];
            // if (docObj.type === 10) {
            //     setStudentIdPath(httpURLStudent + "/sms/student/docs/view?imgPath=" + docObj.imgPath)
            // }
            // if (docObj.type === 20) {
            //     setStudentAgePath(httpURLStudent + "/sms/student/docs/view?imgPath=" + docObj.imgPath)
            // }
            // if (docObj.type === 30) {
            //     setStudentAddressPath(httpURLStudent + "/sms/student/docs/view?imgPath=" + docObj.imgPath);
            // }
            // if (docObj.type === 40) {
            //     setStudentCategoryPath(httpURLStudent + "/sms/student/docs/view?imgPath=" + docObj.imgPath);
            // }
            if (docObj.typeId === 50) {
                setStudentPhotoPath(httpURLStudent + "/sms/student/docs/view?imgPath=" + docObj.imgPath);
            }
        }
    }

    const getStudentDtls = (pStudentId) => {
        // console .log('getStudentDtls called, pStudentId', pStudentId);
        setLoader("block");
        setOpacity(0.5);
        studentDetailsSvc(pStudentId, handleStudentDetails);
    }

    const handleStudentDetails = (data) => {
        // console .log("handleStudentDetails called, data", data);
        const obj = data;
        if (obj.status === 'Success') {
            setPersonalDtls(data.studentDtls.personal);
            setHistoryDtls(data.studentDtls.history);
            setCategoryDtls(data.studentDtls.category);
            setMedicalDtls(data.studentDtls.medical);
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleGetStateListResponse = (data) => {
        // console .log("data handleGetStateListResponse, data", data);
        const obj = data;
        if (obj.status === 'Success') {
            setLstState(obj.lstState);
            // Add any additional processing if needed
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    };


    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}
            >
            </div>

            <div className="col-md-12 col-xs-12 col-centered" style={{ marginTop: "15px", opacity: opacity }}>
                <div className="mainContainer studentsDtls">
                    <div className="container-fluid">
                        <div className="col-sm-12 col-centered">
                            <div
                                className="col-sm-12 col-centered"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: 10,
                                }}
                            >
                                <div className="col-sm-12 col-xs-12">
                                    <StudentPersonalDetails
                                        setLoader={setLoader}
                                        setOpacity={setOpacity}
                                        studentId={studentId}
                                        lstClass={lstCls}
                                        lstStates={lstState}
                                        personalDtls={personalDtls}
                                        studentPhotoPath={studentPhotoPath}
                                    />
                                    <StudentCategory
                                        setLoader={setLoader}
                                        setOpacity={setOpacity}
                                        studentId={studentId}
                                        categoryDtls={categoryDtls}
                                        flgDisabled={!studentId || studentId == 0}
                                    />
                                    <StudentCommunication
                                        setLoader={setLoader}
                                        setOpacity={setOpacity}
                                        studentId={studentId}
                                        flgDisabled={!studentId || studentId == 0}
                                    />
                                    <StudentParentDetails
                                        setLoader={setLoader}
                                        setOpacity={setOpacity}
                                        studentId={studentId}
                                        flgDisabled={!studentId || studentId == 0}
                                        lstStates={lstState}
                                    />
                                    <StudentSiblingDetails
                                        setLoader={setLoader}
                                        setOpacity={setOpacity}
                                        studentId={studentId}
                                        lstClass={lstCls}
                                        flgDisabled={!studentId || studentId == 0}
                                    />
                                    <StudentHistory
                                        setLoader={setLoader}
                                        setOpacity={setOpacity}
                                        studentId={studentId}
                                        historyDtls={historyDtls}
                                        medicalDtls={medicalDtls}
                                        lstStates={lstState}
                                        lstClass={lstCls}
                                        flgDisabled={!studentId || studentId == 0}
                                        />
                                    <StudentDocs
                                        studentId={studentId}
                                        lstDocDtls={lstDocDtls}
                                        flgDisabled={!studentId || studentId == 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentDetails;