import React, { useState, useEffect } from 'react'
import LblTxt from './LblTxt'
import DropDown from './DropDown'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
const RegistrationHistory = ({ admissionId }) => {
  const [studentHistory, setStudentHistory] = useState({})
  const [medicalHistory, setMedicalHistory] = useState({})
  const [lstState, setLstState] = useState([])
  const [lstCity, setLstCity] = useState([])
  const [lstClass, setLstClass] = useState([])
  const [loader, setLoader] = useState('none')

  function cbStudentHistoryResponse (obj) {
    if (obj.SvcStatus === 'Success') {
      getCityListSvc(cbCityListResponse, obj.history.lastStateId)
      setStudentHistory(obj.history)
    } else {
      toast.error(obj.SvcMsg)
    }
    setLoader('none')
  }

  function cbMedicalHistoryResponse (obj) {
    if (obj.SvcStatus === 'Success') {
      setMedicalHistory(obj.medical)
    } else {
      toast.error(obj.SvcMsg)
    }
    setLoader('none')
  }

  function getStudentHistoryDtlsSvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/history/dtls',
      method: 'POST',
      params: { admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }

  function getMedicalHistoryDtlsSvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/medical/dtls',
      method: 'POST',
      params: { admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log('Medical History:' + Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }

  useEffect(() => {
    var promise = new Promise((resolve, reject) => {
      setLoader('block')
      getStateListSvc(cbStateListResponse)
      getClassListSvc(cbClassListResponse)
    })
  }, [])
  function getClassListSvc (cb) {
    axios({
      url: httpURLCommon + '/sms/mst/cls/list',
      method: 'POST',
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          cb(response.data)
        } else {
          toast.error(response.data.SvcMsg)
        }
      })
      .catch(error => {
        toast.error('Service failed ' + error)
      })
  }
  function getStateListSvc (callBack) {
    axios({
      url: httpURLCommon + '/sms/mst/state/list',
      method: 'POST',
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }

  function getCityListSvc (callBack, pstateId) {
    axios({
      url: httpURLCommon + '/sms/mst/city/list',
      method: 'POST',
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }

  function cbClassListResponse (obj) {
    if (obj.SvcStatus === 'Success') {
      setLstClass(obj.lstClass)
    } else {
      toast.error(obj.SvcMsg)
    }
    setLoader('none')
  }
  function cbStateListResponse (pLstState) {
    if (pLstState.SvcStatus === 'Success') {
      setLstState(pLstState.lstState)
      getStudentHistoryDtlsSvc(admissionId, cbStudentHistoryResponse)
      getMedicalHistoryDtlsSvc(admissionId, cbMedicalHistoryResponse)
    } else {
      toast.error(pLstState.SvcMsg)
    }
    setLoader('none')
  }

  function cbCityListResponse (pLstCity) {
    if (pLstCity.SvcStatus === 'Success') {
      setLstCity(pLstCity.lstCity)
    } else {
      toast.error(pLstCity.SvcMsg)
    }
    setLoader('none')
  }
  function handleStateChange (pStateId) {
    getCityListSvc(cbCityListResponse, pStateId)
    setStudentHistory({ ...studentHistory, lastStateId: pStateId })
  }
  function handleCityChange (pCityId, pLocalityId) {
    setStudentHistory({ ...studentHistory, lastCityId: pCityId })
    // getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId)
  }
  function saveHistory () {
    var history = {
      lastSchool: studentHistory.lastSchool,
      lastClsId: studentHistory.lastClsId,
      lastStateId: studentHistory.lastStateId,
      lastCityId: studentHistory.lastCityId,
      lastPct: parseInt(studentHistory.lastPct),
      board: studentHistory.board
    }
    setLoader('block')
    saveHistorySvc(history, cbSaveHistoryResponse)
  }
  function cbSaveHistoryResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function saveHistorySvc (history, callBack) {
    history = JSON.stringify(history)
    axios({
      url: httpURL + '/sms/admission/history/save',
      method: 'POST',
      params: { admissionId: admissionId, history: history },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  function saveMedicalHistory () {
    var medHistory = {
      height: medicalHistory.height,
      weight: medicalHistory.weight,
      bloodGroup: medicalHistory.bloodGroup,
      disease: medicalHistory.disease,
      eyes: medicalHistory.eyes,
      ears: medicalHistory.ears,
      allergicTo: medicalHistory.allergicTo
    }
    setLoader('block')
    saveMedicalHistorySvc(medHistory, cbSaveMedicalHistoryResponse)
  }
  function cbSaveMedicalHistoryResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function saveMedicalHistorySvc (medHistory, callBack) {
    medHistory = JSON.stringify(medHistory)
    axios({
      url: httpURL + '/sms/admission/medical/save',
      method: 'POST',
      params: { admissionId: admissionId, medical: medHistory },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  function setLastSchool (pVal) {
    setStudentHistory({ ...studentHistory, lastSchool: pVal })
  }
  function setLastPct (pVal) {
    setStudentHistory({ ...studentHistory, lastPct: pVal })
  }
  function setLastClsId (pVal) {
    setStudentHistory({ ...studentHistory, lastClsId: pVal })
  }
  function setBoard (pVal) {
    setStudentHistory({ ...studentHistory, board: pVal })
  }
  function setAllergicTo (pVal) {
    setMedicalHistory({ ...medicalHistory, allergicTo: pVal })
  }
  function setEyes (pVal) {
    setMedicalHistory({ ...medicalHistory, eyes: pVal })
  }
  function setEars (pVal) {
    setMedicalHistory({ ...medicalHistory, ears: pVal })
  }
  function setHeight (pVal) {
    setMedicalHistory({ ...medicalHistory, height: pVal })
  }
  function setWeight (pVal) {
    setMedicalHistory({ ...medicalHistory, weight: pVal })
  }
  function setBloodGroup (pVal) {
    setMedicalHistory({ ...medicalHistory, bloodGroup: pVal })
  }
  function setDisease (pVal) {
    setMedicalHistory({ ...medicalHistory, disease: pVal })
  }
  return (
    <>
      <div
        className='col-sm-12'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '1%'
        }}
      >
        <div
          className='col-sm-12'
          style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: 'fixed',
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: '50%',
              top: '50%',
              display: loader
            }}
          >
            <img
              className='loderImg'
              src={require('common/images/loader.gif')}
              alt='Loader...'
            />
          </div>
          <div className='col-sm-5' style={{ background: 'transparent' }}>
            <LblTxt
              label='Previous School:'
              dataType='text'
              dataValue={studentHistory.lastSchool}
              onChange={setLastSchool}
            ></LblTxt>
            <DropDown
              label='Previous Class:'
              onChangeDropDown={setLastClsId}
              option1='Previous Class'
              key={studentHistory.lastClsId}
              lst={lstClass}
              dataValue={studentHistory.lastClsId}
              keyId='clsId'
              keyVal='cls'
            ></DropDown>
            <DropDown
              label='State:'
              onChangeDropDown={handleStateChange}
              option1='State'
              key={studentHistory.lastStateId}
              lst={lstState}
              dataValue={studentHistory.lastStateId}
              keyId='stateId'
              keyVal='state'
            ></DropDown>
            <DropDown
              label='City:'
              onChangeDropDown={handleCityChange}
              option1='City'
              key={studentHistory.lastStateId + '_' + studentHistory.lastCityId}
              lst={lstCity}
              dataValue={studentHistory.lastCityId}
              keyId='cityId'
              keyVal='city'
            ></DropDown>
            <LblTxt
              label='Board:'
              dataType='text'
              dataValue={studentHistory.board}
              onChange={setBoard}
            ></LblTxt>
            <LblTxt
              label='Result:'
              dataType='text'
              dataValue={studentHistory.lastPct}
              onChange={setLastPct}
            ></LblTxt>
          </div>

          <div
            className='col-sm-offset-1 col-sm-5'
            style={{ background: 'transparent' }}
          >
            <LblTxt
              label='Height:'
              dataType='text'
              dataValue={medicalHistory.height}
              onChange={setHeight}
            ></LblTxt>
            <LblTxt
              label='Weight:'
              dataType='text'
              dataValue={medicalHistory.weight}
              onChange={setWeight}
            ></LblTxt>
            <LblTxt
              label='Blood Group:'
              dataType='text'
              dataValue={medicalHistory.bloodGroup}
              onChange={setBloodGroup}
            ></LblTxt>
            <LblTxt
              label='Disease:'
              dataType='text'
              dataValue={medicalHistory.disease}
              onChange={setDisease}
            ></LblTxt>
            <LblTxt
              label='Eyes:'
              dataType='text'
              dataValue={medicalHistory.eyes}
              onChange={setEyes}
            ></LblTxt>
            <LblTxt
              label='Ears:'
              dataType='text'
              dataValue={medicalHistory.ears}
              onChange={setEars}
            ></LblTxt>
            <LblTxt
              label='Allergic To:'
              dataType='text'
              dataValue={medicalHistory.allergicTo}
              onChange={setAllergicTo}
            ></LblTxt>
          </div>
          <div className='col-sm-12'>
            <div className='col-sm-5'>
              <button
                type='button'
                className='btn-info btn pull-right'
                onClick={e => saveHistory()}
              >
                Save
              </button>
            </div>
            <div className='col-sm-offset-1 col-sm-5 padding-remove'>
              <button
                type='button'
                className='btn-info btn pull-right'
                onClick={e => saveMedicalHistory()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationHistory
