import React, { useState, useEffect } from 'react';
import '../css/wardTab.css';
// import WardTab from './wardTab.js';
/* var lstDt = [
    {
        date: '23-July'
    },
    {
        date: '25-July'
    },
    {
        date: '26-July'
    },
    {
        date: '27-July'
    },
    {
        date: '28-July'
    }
]
 */
const WardTabList = (props) => {
    const [state, setState] = useState(
        {
            lstWard: props.lstWard,
            selWardId: props.selWardId,
            dynamicWidth: 'calc(100% / ' + Object.keys(props.lstWard).length + ')',
        }
    )

    useEffect(() => {
        // console .log(props.lstWard)
        //setLstDt(props.lstDt)
        setState({
            lstWard: props.lstWard,
            selWardId: props.selWardId,
            dynamicWidth: 'calc(100% / ' + Object.keys(props.lstWard).length + ')'
        })

    }, [])


    return (
        <>
            {/* <div id={state.selWardId} className="col-sm-11 padding-remove" style={{ width: '100%', boxShadow: '#ccc 0px 1px 4px 0px', borderRight: '1px solid #ccc', borderWidth: '1px 0px 1px 0px', height: '54px' }}> */}
            <div id={state.selWardId} className="col-sm-11 ward-menu-wrapper padding-remove" style={{}}>

                {state.dynamicWidth && props.lstWard && props.lstWard.length !== 0 && props.lstWard.map((obj, idx) => (
                    <div key={idx} obj={JSON.stringify(obj)} className={obj.wardId === state.selWardId ? "ward-card" : "ward-card"} style={{ width: state.dynamicWidth, height: '100%' }} onClick={() => props.onWardTabClick(obj.wardId)}>
                        <div className="" style={{ height: '100%' }}>
                            <div className={obj.wardId === props.selWardId ? "card-body ward-active" : "card-body"} style={{ height: '100%' }}>
                                <div className="pull-left" style={{ width: '25%', margin: '0 auto' }}>
                                    <img className="wardImg" src={require('common/images/avatar.png')} alt="Profile" style={{ padding: '6%' }} />
                                </div>
                                <div style={{ width: '75%', padding: '0.2rem 0.5rem' }} className="pull-right">
                                    <div className="card-title">{obj.ward}</div>
                                    <div className='card-text'>{obj.enrolmentNo}</div>
                                </div>


                            </div>
                        </div>
                    </div>))
                }
            </div >

        </>);
}

export default WardTabList;