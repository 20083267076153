import React, { useState, useEffect } from 'react';
import lstModules from 'common/json/lstModules.json';

const httpURLStudent = lstModules['students'].url + ':' + lstModules['students'].port

const ImageSlider = (props) => {
    const [loader, setLoader] = useState("none")
    const [eventId, setEventId] = useState(props.eventId)
    const [lstImg, setLstImg] = useState(props.lstImg)
    const [galleryPath, setGalleryPath] = useState(props.galleryPath)

    useEffect(() => {
        // console .log(eventId)
        // console .log(galleryPath)
        // console .log(lstImg)
    }, [])

    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider} style={{ right: "50%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >
                <div className="col-sm-2 padding-remove col-centered" style={{ border: '4px solid #202020', height: 600, width: 320, borderRadius: '20px' }}>
                    {eventId && <div className='col-sm-12 padding-remove' style={{ marginTop:60, borderTop: '1px solid #ddd'}}>
                        {lstImg.length > 0 && lstImg.map((mainitem, x) => {
                            return <div className="col-sm-12 padding-remove">
                                {mainitem.lstImg.map((item, i) => {
                                    return <img style={{ boxShadow:'2px 1px 2px #ddd',margin:4, height:133, width: item.portrait ? 94 : 179, border: '1px solid #ddd', borderRadius: 6 }} src={httpURLStudent + "/sms/student/docs/view?imgPath=" + galleryPath + eventId + "_" + item.imgId + ".jpg"} />
                                })
                                }
                            </div>

                        })}
                    </div>
                    }
                </div>
            </form>
        </>
    )
}

export default ImageSlider