import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

// const httpURL = "http://192.168.1.5:8280" + "/sms/wt/test/student";
const httpURL =  lstModules['test'].url + ':' + lstModules['test'].port + "/sms/wt/test/student";
const tokenid = localStorage.getItem('tokenid');
const schoolId = 31270;


export const leaderBoardSvc = (testId, studentId, pSchoolId, rankers, cbLeaderBoard) => {
    // console .log("leaderBoardSvc called, url", httpURL + '/leader_board');
    // console .log("schoolId", schoolId, ", testId", testId, ", studentId", studentId);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    formData.append("studentId", studentId);
    formData.append("leaderboardCount", parseInt(rankers));
    const obj = { status: "", message: "", "testId":testId, "studentId":studentId, "schoolId":pSchoolId, "rankers":rankers  };
    axios({
        url: httpURL + '/leader_board',
        method: "POST",
        // params: { "testId": testId, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in leaderBoardSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstLeaderBoard = response.data.lstLeaderBoard;
            obj.studentScore = response.data.myScore;
            obj.finalScore = response.data.finalScore;
        }
        cbLeaderBoard(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLeaderBoard(obj);
    })
}


export const nextQuestionSvc = (testId, studentId, qusNo, pSchoolId, rankers, cbNextQuestion) => {
    // console .log("nextQuestionSvc called, url", httpURL + '/check_next_action');
    // console .log("schoolId", schoolId, ", testId", testId, ", studentId", studentId);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    formData.append("studentId", studentId);
    formData.append(" qusNo",  qusNo);
    const obj = { status: "", message: "", "testId":testId, "studentId":studentId, "schoolId":pSchoolId, "rankers": rankers  };
    axios({
        url: httpURL + '/check_next_action',
        method: "POST",
        // params: { "testId": testId, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in nextQuestionSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.nextAction = response.data.nextAction;      // 10: Stay, 20: next qus, 30:leaderboard
        }
        cbNextQuestion(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbNextQuestion(obj);
    })
}