import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/leaderboard.css';

const TopStudents = ({ lstLeaderBoard }) => {


    const [avatarPath, setAvatarPath] = useState("");

    useEffect(() => {
        // console .log("TopStudents called lstLeaderBoard",lstLeaderBoard);
        getPathAvatar();
    }, [lstLeaderBoard]);


    const getPathAvatar = () => {
        // console .log("getPathAvatar Called");
        const tempPathAvatar = localStorage.getItem('avatarPath');
        // console .log("tempPathAvatar in getMapAvatar", tempPathAvatar);
        setAvatarPath(tempPathAvatar);
    }



    return (
        <div className="col-sm-12 col-xs-12 padding-remove" >
            <div className="col-sm-10 col-xs-12 leaderbord-poles col-centered" >
                <div className="col-sm-12 col-xs-12 leaderboard-pole-scorer padding-remove" >
                    <div className="rankDiv div1 col-sm-4 col-xs-4 padding-remove text-center"  >
                        {lstLeaderBoard[1] ?
                            <div className="col-sm-12 col-xs-12 padding-remove">
                                <div className="col-sm-12 col-xs-12 top-scorer-name">
                                    {lstLeaderBoard[1].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-2">
                                        <div className="avatar-image-2">
                                            <img src={avatarPath + "/" + lstLeaderBoard[1].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="rankDiv div2 col-sm-4 col-xs-4 padding-remove text-center" >
                        {lstLeaderBoard[0] ?
                            <>
                                <div className="col-sm-12 col-xs-12 top-scorer-name">
                                    {lstLeaderBoard[0].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-1">
                                        <div className="avatar-image-1">
                                            <img src={avatarPath + "/" + lstLeaderBoard[0].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                    <div className="rankDiv div3 col-sm-4 col-xs-4 padding-remove text-center">
                        {lstLeaderBoard[2] ?
                            <>
                                <div className="col-sm-12 col-xs-12 top-scorer-name" >
                                    {lstLeaderBoard[2].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-3">
                                        <div className="avatar-image-3">
                                            <img src={avatarPath + "/" + lstLeaderBoard[2].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>
            <div className="col-sm-10 col-xs-12 col-centered" style={{ height: 50, background: '#104b5d', border: '2px solid #104b5d' }}>
                <div className="col-sm-12 col-xs-12 padding-remove">
                    <div className="col-sm-4 col-xs-4 padding-remove text-center" style={{ borderRight: '2px solid #fff', height: 50, alignContent:"center" }}>
                        {lstLeaderBoard[1] ?
                            <div className="col-sm-12 toper-score col-xs-12" >
                                {lstLeaderBoard[1].score}
                            </div>
                            : null}
                    </div>
                    <div className="col-sm-4 col-xs-4 padding-remove text-center" style={{ borderRight: '2px solid #fff', height: 50, alignContent:"center"  }}>
                        {lstLeaderBoard[0] ?
                            <div className="col-sm-12 toper-score col-xs-12" >
                                {lstLeaderBoard[0].score}
                            </div>
                            : null}
                    </div>
                    <div className="col-sm-4 col-xs-4 padding-remove text-center" style={{ height: 50, alignContent:"center"  }}>
                        {lstLeaderBoard[2] ?
                            <div className="col-sm-12 toper-score col-xs-12" >
                                {lstLeaderBoard[2].score}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopStudents;