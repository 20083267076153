import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');

var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
function getCloseRemarksSvc(callBack, pEnqId) {
    if (!pEnqId) {
        return;
    }
    axios(
        {
            url: httpURL+"/sms/enquiry/close",
            method: 'POST',
            params: { enqId: pEnqId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
function saveCloseRemarksSvc(pEnqId, pReasonId, pRemarks, cbSaveFollowUp) {
    axios({
        url: httpURL+"/sms/enquiry/close/save",
        method: "POST",
        params: { enqId: pEnqId, reasonId: pReasonId, closeRemarks: pRemarks },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveFollowUp(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}
function getCloseReasonSvc(callBack) {
    axios(
        {
            url: httpURL+"/sms/enquiry/close_reason/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
export { getCloseRemarksSvc };
export { saveCloseRemarksSvc };
export { getCloseReasonSvc };