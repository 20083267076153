import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import Header from 'common/js/header/header.js';
import TeacherTable from './TeacherTable.js'
import PersonalDtlsSlider from './PersonalDtlsSlider.js'
import AddressDtlsSlider from './AddressDtlsSlider.js'
import EducationSlider from './EducationSlider.js'
import ExperienceSlider from './ExperienceSlider.js'
import AwardSlider from './AwardSlider.js'
import ClsSubjectSlider from './ClsSubjectSlider.js';
import ProfileSlider from './ProfileSlider.js';
import SliderType from './SliderType.js';
import AttachmentSlider from './AttachmentSlider.js';


function TeacherProfileCtrl() {
    const [tchId, setTchId] = useState(0)
    const [flgSliderType, setFlgSliderType] = useState(0)
    const [randNo, setRandNo] = useState(0)
    const [selectedClsId, setSelectedClsId] = useState(0)



    function refreshScreen() {
        setTchId(0)
        setFlgSliderType(0);
        generateRandomNo();
    }
    function getRandNo() {

        var lRandNo = Math.floor(Math.random() * 99999)
        // console .log(lRandNo);
        setRandNo(lRandNo)
        return lRandNo
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return <div className='MainContainer'>
        <Header wingFromChild={getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />

        <div className='enquiryTblWrapper'>

            <div className='enquiryTbl'>

                <TeacherTable

                    tchId={tchId}
                    setTchId={setTchId}
                    setFlgSliderType={setFlgSliderType}
                    setSelectedClsId={setSelectedClsId}

                />

            </div>
            <div className="addNew" onClick={(e) => setFlgSliderType(SliderType.SLIDER_PERSONAL_DTLS)}>
                <span>+</span>
            </div>
        </div>

        <div style={{ display: flgSliderType === SliderType.SLIDER_PERSONAL_DTLS ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={refreshScreen}>
                <span>X</span>
            </button>
            <PersonalDtlsSlider
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />

        </div>
        <div style={{ width: '50%', display: flgSliderType === SliderType.SLIDER_ADDRESS_DTLS ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '90%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <AddressDtlsSlider
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />
        </div>
        <div style={{ width: '80%', display: flgSliderType === SliderType.SLIDER_EDUCATION ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '82%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <EducationSlider
                onGetRandNo={getRandNo}
                key={tchId}

                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />
        </div>
        <div style={{ width: '80%', display: flgSliderType === SliderType.SLIDER_EXPERIENCE ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '82%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <ExperienceSlider
                onGetRandNo={getRandNo}
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />
        </div>
        <div style={{ width: '50%', display: flgSliderType === SliderType.SLIDER_AWARD ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '50%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <AwardSlider
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}

            />
        </div>
        <div style={{ width: '50%', display: flgSliderType === SliderType.SLIDER_CLS_SUBJECT ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '50%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <ClsSubjectSlider
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />
        </div>

        <div style={{ width: '85%', display: flgSliderType === SliderType.SLIDER_PROFILE ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '85%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <ProfileSlider
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />
        </div>
        <div style={{ width: '50%', display: flgSliderType === SliderType.SLIDER_ATTACHMENTS ? 'block' : 'none' }} className='enquirySlider'>
            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '55%' }} onClick={refreshScreen}>
                <span>X</span>
            </button>
            <AttachmentSlider
                key={tchId}
                tchId={tchId}
                closeSlider={refreshScreen}
                generateRandomNo={generateRandomNo}
                flgSliderType={flgSliderType}
            />
        </div>

    </div>;
}

export default TeacherProfileCtrl;
