import React, { Component, useState } from 'react';
import ReactDom from 'react-dom';
import axios from 'axios';
// import './outstanding.css';
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header';
import $ from "jquery";
import lstModules from 'common/json/lstModules.json';
import 'common/css/master.css';

//import OutStandingSummaryList from './outStandingSummary.json'
var httpURL = '';
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
var queryString = require('querystring');
//var lstOutstanding = [];

var lstMonth = [
    {
        "monthId": 1,
        "month": "Jan"
    },
    {
        "monthId": 2,
        "month": "Feb"
    },
    {
        "monthId": 3,
        "month": "Mar"
    },
    {
        "monthId": 4,
        "month": "Apr"
    },
    {
        "monthId": 5,
        "month": "May"
    },
    {
        "monthId": 6,
        "month": "Jun"
    },
    {
        "monthId": 7,
        "month": "Jul"
    },
    {
        "monthId": 8,
        "month": "Aug"
    },
    {
        "monthId": 9,
        "month": "Sep"
    },
    {
        "monthId": 10,
        "month": "Oct"
    },
    {
        "monthId": 11,
        "month": "Nov"
    },
    {
        "monthId": 12,
        "month": "Dec"
    }
];

var lstClass = [
    // {
    //     "classId" : 1,
    //     "clas": "I"
    // },
    // {
    //     "classId" : 2,
    //     "clas": "II"
    // },
    // {
    //     "classId" : 3,
    //     "clas": "III"
    // },
    // {
    //     "classId" : 4,
    //     "clas": "IV"
    // },
    // {
    //     "classId" : 5,
    //     "clas": "V"
    // },
];

var lstSection = [
    // {
    //     "secId" : 11,
    //     "sec": "A"
    // },
    // {
    //     "secId" : 22,
    //     "sec": "B"
    // },
    // {
    //     "secId" : 33,
    //     "sec": "C"
    // },
    // {
    //     "secId" : 44,
    //     "sec": "D"
    // }
]

var studentCountFeesRemaining = 3;

var lstSectionWiseStudentCount = [
    {
        classSectionId: 1,
        "studentCount": 2,
        "totalStudent": 2
    },
    {
        classSectionId: 2,
        "studentCount": 2,
        "totalStudent": 2
    },
    {
        classSectionId: 3,
        "studentCount": 2,
        "totalStudent": 2
    },
    {
        classSectionId: 4,
        "studentCount": 2,
        "totalStudent": 2
    },
    {
        classSectionId: 5,
        "studentCount": 2,
        "totalStudent": 2
    }
];

var screenId = 33;

export default class Outstanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: localStorage.getItem('tokenid'),
            lstOutstanding: [],
            lstMonth: lstMonth,
            lstClass: lstClass,
            lstClassSection: [],
            lstSectionWiseStudentCount: lstSectionWiseStudentCount,
            studentCountFeesRemaining: studentCountFeesRemaining,
            lstStudentDtls: [
                {
                    classId: 1,
                    className: "class-I",
                    sectionId: 1,
                    section: "A",
                    studentId: 1,
                    student: "ranveer",
                    father: "father",
                    fPhone: "123123123",
                    fEmail: "ranveer311295@gmail.com",
                    mother: "mother",
                    mPhone: "456456456",
                    mEmail: "ranveer311295@gmail.com",
                    guardian: "guardian",
                    gPhone: "789789789",
                    gEmail: "ranveer311295@gmail.com",
                    totalFees: 5000,
                    feeDue: "",
                    action: ''
                },
                {
                    classId: 2,
                    className: "class-I",
                    sectionId: 1,
                    section: "A",
                    studentId: 1,
                    student: "ranveer",
                    father: "father",
                    fPhone: "123123123",
                    fEmail: "ranveer311295@gmail.com",
                    mother: "mother",
                    mPhone: "456456456",
                    mEmail: "ranveer311295@gmail.com",
                    guardian: "guardian",
                    gPhone: "789789789",
                    gEmail: "ranveer311295@gmail.com",
                    totalFees: 5000,
                    feeDue: "",
                    action: ''
                },
                {
                    classId: 3,
                    className: "class-I",
                    sectionId: 1,
                    section: "A",
                    studentId: 1,
                    student: "ranveer",
                    father: "father",
                    fPhone: "123123123",
                    fEmail: "ranveer311295@gmail.com",
                    mother: "mother",
                    mPhone: "456456456",
                    mEmail: "ranveer311295@gmail.com",
                    guardian: "guardian",
                    gPhone: "789789789",
                    gEmail: "ranveer311295@gmail.com",
                    totalFees: "",
                    feeDue: 4400,
                    action: ''
                },
                {
                    classId: 4,
                    className: "class-I",
                    sectionId: 1,
                    section: "A",
                    studentId: 1,
                    student: "ranveer",
                    father: "father",
                    fPhone: "123123123",
                    fEmail: "ranveer311295@gmail.com",
                    mother: "mother",
                    mPhone: "456456456",
                    mEmail: "ranveer311295@gmail.com",
                    guardian: "guardian",
                    gPhone: "789789789",
                    gEmail: "ranveer311295@gmail.com",
                    totalFees: 5000,
                    feeDue: 4400,
                    action: ''
                },
                {
                    classId: 5,
                    className: "class-I",
                    sectionId: 1,
                    section: "A",
                    studentId: 1,
                    student: "ranveer",
                    father: "father",
                    fPhone: "123123123",
                    fEmail: "ranveer311295@gmail.com",
                    mother: "mother",
                    mPhone: "456456456",
                    mEmail: "ranveer311295@gmail.com",
                    guardian: "guardian",
                    gPhone: "789789789",
                    gEmail: "ranveer311295@gmail.com",
                    totalFees: 5000,
                    feeDue: 4400,
                    action: ''
                },
                {
                    classId: 6,
                    className: "class-I",
                    sectionId: 1,
                    section: "A",
                    studentId: 1,
                    student: "ranveer",
                    father: "father",
                    fPhone: "123123123",
                    fEmail: "ranveer311295@gmail.com",
                    mother: "mother",
                    mPhone: "456456456",
                    mEmail: "ranveer311295@gmail.com",
                    guardian: "guardian",
                    gPhone: "789789789",
                    gEmail: "ranveer311295@gmail.com",
                    totalFees: "",
                    feeDue: 4400,
                    action: ''
                }
            ],
        }
        // this.handleChange = this.handleChange.bind(this);
        // this.handleChange1 = this.handleChange1.bind(this);
    }

    // handleChange(date) {
    //     this.setState({fromDt: date, });
    // }
    // handleChange1(date) {
    //     this.setState({toDt: date });
    // }
    componentDidMount = () => {
        this.getClassList();
        //        this.getSectionList();
        //this.getTableList();
    }

    getTableList = () => {
        // console .log('get table list');
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + 'sms/fee/report/monthly/outstanding',
            method: "POST",
            params: { month: this.state.month, screenId: screenId, classId: this.state.classId, sectionId: this.state.sec },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstStudentDtls: response.data.lstStudentDtls,
                });
                //this.fillMonth();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    getClassList = (classId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURLCommon + '/sms/mst/class/list',
            method: "POST",
            params: { classId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                //lstClass = response.data.lstClass;
                this.setState({ lstClass: response.data.lstClass });
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    // getSummary = (classId) => {
    //     axios({
    //         url: httpURL + '/sms/wings/cls/section/list',
    //         method: "POST",
    //         data: queryString.stringify({ 'classId': classId, screenId }),
    //         headers: { 'tokenid': this.state.tokenid },
    //         withCredentials: true,
    //     }).then((response) => {
    //         if (response.data.SvcStatus === 'Success') {
    //             lstSectionWiseStudentCount = response.data.lstClassSection;
    //             this.setState({ lstClassSection: lstClassSection });
    //             //this.fillClassSection();
    //             toast.success(response.data.SvcMsg);

    //             if (lstClassSection.length !== 0) {
    //                 $('.classSection').show();
    //             }
    //             else {
    //                 $('.classSection').hide();
    //             }

    //         }
    //         else {
    //             toast.error(response.data.SvcMsg);
    //             $('.classSection').hide();
    //             if (response.data.SvcMsg == 'You are not Logged In') {
    //                 setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
    //                 return false;

    //             }
    //             else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
    //                 setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
    //                 return false;
    //             }
    //         }
    //     }).catch((error) => {
    //         toast.error("Service failed " + error);
    //     });
    //     this.setState({ loader: 'none', opacity: 1 });
    // }

    getSectionList = (classId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        // console .log('classId:' + classId);
        axios({
            url: httpURL + '/sms/wings/cls/section/list',
            method: "POST",
            params: { classId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({ lstClassSection: response.data.lstClassSection });
                toast.success(response.data.SvcMsg);

                if (this.state.lstClassSection.length !== 0) {
                    $('.classSection').show();
                }
                else {
                    $('.classSection').hide();
                }
            }
            else {
                toast.error(response.data.SvcMsg);
                $('.classSection').hide();
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }

    changeMonth = (e) => {
        // var classId = $('#classCmb').find('option:selected').val();
        // var month = $('#monthCmb').find('option:selected').val();

        //        this.getTableList(classId, month);
        this.setState({
            month: e.target.value
        });
    }

    changeClass = (e) => {
        var classId = e.target.value;
        //        alert('classId'+classId);
        //let className = $('#classCmb').find('option:selected').val();

        //this.getTableList(classId, className);
        this.setState({
            classId: classId,
            //lstStudentDtls: [],
            //className: className
        });
        this.getSectionList(classId);
    }

    changeSection = (e) => {
        var sectionId = e.target.value;
        this.setState({
            sec: sectionId
        });
        this.getTableList();
    }

    // addClassOnBody = (id) => {
    //     $('#tableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    // }


    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }

    render() {
        httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
        return (
            <>
                <Header wingFromChild={this.state.getWingId} />
                <div className="topSectionWrap">
                    <div className="col-xs-2 padding-remove">
                        <div className="form-group">
                            <div className="col-sm-10">
                                <select autoComplete="off" id="monthCmb" onChange={this.changeMonth} className="form-control">
                                    <option id={0} key={0}>Month</option>
                                    {this.state.lstMonth.map((obj) => {
                                        return <option value={obj.month.toLowerCase()} key={obj.monthId}>{obj.month}</option>
                                    })}</select>
                            </div>
                        </div>

                    </div>
                    <div className="col-xs-2 padding-remove">
                        <div className="form-group">
                            <div className="col-sm-10 ">
                                <select autoComplete="off" id="classCmb" onChange={this.changeClass} className="form-control">
                                    <option id={0} key={0}>Class</option>
                                    {this.state.lstClass.map((obj) => {
                                        return <option value={obj.classId} key={obj.classId}>{obj.className}</option>
                                    })}</select>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-2 padding-remove">
                        <div className="form-group">
                            <div className="col-sm-10 ">
                                <select autoComplete="off" id="secCmb" onChange={this.changeSection} className="form-control">
                                    <option id={0} key={0}>Section</option>
                                    {this.state.lstClassSection.map((obj) => {
                                        return <option value={obj.classSectionId} key={obj.classSectionId}>{obj.section}</option>
                                    })}</select>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 padding-remove topSectionWrap">
                        {
                            this.state.lstStudentDtls.length ?
                                <div className="col-xs-12 padding-remove timeLineParentWrap">
                                    <div className="padding-remove timeLineParent  col-xs-4">
                                        <label className="control-label">Summary</label>
                                    </div>
                                    <div className="timeLineWrap col-xs-5 padding-remove">
                                        {this.state.lstSectionWiseStudentCount.map((stats, classSectionId, studentCount, totalStudent) => (

                                            <div key={classSectionId} style={{ width: stats.totalStudent }}
                                                className={`stats` + studentCount.totalStudent}
                                            >
                                                {<label>{this.state.classId}</label>}
                                                {<p id="numberCircle">Total: {stats.totalStudent}</p>}
                                                {<p>Dues:{stats.totalStudent - stats.studentCount}</p>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className="col-xs-11 scrollbar" id='customTable'>
                    <table className="tableLayout" id='customTable'>
                        <thead>
                            <tr>
                                <th width="50">Class</th>
                                <th width="50">Section</th>
                                <th width="100">Name</th>
                                <th width="150">Father(Name/Phone)</th>
                                <th width="150">Mother(Name/Phone)</th>
                                <th width="150">Guardian(Name/Phone)</th>
                                <th width="100">Fees</th>
                                <th width="100">Amount Due</th>
                                <th width="100">Last Email Sent</th>
                                <th width="50">Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="col-xs-11 " id='customTable'>
                    <table id="customTable" className="tableLayout">
                        <tbody id="tableBody">
                            {this.state.lstStudentDtls.map((obj) => (
                                <tr key={obj.classId} id={obj.classId}>
                                    <td width="100" className="no-display">{obj.classId}</td>
                                    <td width="50" className="text-center">{obj.className}</td>
                                    <td width="50" className="text-center">{obj.section}</td>
                                    <td width="100" className="text-center">{obj.student}</td>
                                    <td width="150" className="text-center" >{obj.father} <br /> {obj.fPhone}</td>
                                    <td width="150" className="text-center">{obj.mother}  <br />{obj.mPhone}</td>
                                    <td width="150" className="text-center">{obj.guardian} <br /> {obj.gPhone}</td>
                                    <td width="100" className="text-center">{obj.totalFees == "" ? "-" : obj.totalFees}</td>
                                    <td width="100" className="text-center">{obj.feeDue == "" ? "-" : obj.feeDue}</td>
                                    <td width="100" className="text-center">{obj.lastEmailSent}</td>
                                    <td width="50"><a href="mailto:youremail@domain.tld?subject=feedback"><button style={{ marginRight: 15, marginLeft: 15 }} type="button" className="btn btn--submit glyphicon glyphicon-envelope"></button></a></td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>)
    };
}



