import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import { getSyallabus, saveSyallabus } from "./teacherSyllabusService";

const Syallbus = (props) => {
  var initialState = {
    loader: 'none',
    opacity: 1,
  };
  const [state, setState] = useState(initialState);
  const [subjects, setAllSubject] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    showLoader();
    const { tchId } = props;
    const { classId, sectionId } = props.selectedSubject;
    getSyallabus(tchId, classId, sectionId, handleGetSyallbus);
  }, [props]);

  function handleGetSyallbus(data) {
    if (data.SvcStatus === "Success") {
      setAllSubject(data.lstSubject);
      hideLoader()
    } else {
      hideLoader();
      toast.error(data.SvcMsg);
      setErrorMsg(data.SvcMsg);
    }
  }

  function showLoader() {
    setState({ ...state, loader: 'block', opacity: 0.5 });
  }
  function hideLoader() {
    setState({ ...state, loader: 'none', opacity: 1 });
  }
  function saveSubjects(subject) {
    const { tchId } = props;
    const { classId, sectionId } = props.selectedSubject;
    const { subId, rating } = subject;
    // toast("saving....");
    saveSyallabus(
      tchId,
      classId,
      sectionId,
      subId,
      parseInt(rating),
      handleSaveSubjects
    );
  }

  function handleRatingChange(item, rating) {
    const _subjects = [...subjects];
    const index = _subjects.indexOf(item);
    _subjects[index].rating = rating;
    setAllSubject(_subjects);
  }

  function handleSaveSubjects(data) {
    hideLoader()
    if (data.SvcStatus === "Success") {
      // console .log({ data });
      toast.success(data.SvcMsg);
    } else {
      // console .log({ data });
      toast.error(data.SvcMsg);
    }
  }
  return (
    <div style={{ height: '100%' }}>

      <ToastContainer autoClose={3000} hideProgressBar />
      <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div>
      <div className="container-fluid mainContainer" style={{ opacity: state.opacity }}>
        <div className="syllabus-card">
          <React.Fragment>
            <h6 style={{ margin: "0px" }}>Subjects from Syllabus</h6>
            <hr />
            <div className="table-responsive table---">
              <table cellSpacing="0" cellPadding="0" className="subTable tableLayout1">
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Type</th>
                    <th>Rating</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
              <div className="tableDiv" style={{ height: '65vh' }}><table id="subtable" className="tableLayout">
                <tbody>
                  {subjects.map((subject, index) => (
                    <tr className="" key={index}>
                      <td className="text-center">{subject.sub}</td>
                      <td className="text-center">{subject.subType}</td>
                      <td className="text-center" style={{ padding: "5px" }}>
                        <select
                          name="rating"
                          id="rating"
                          defaultValue={subject.rating}
                          onChange={(e) =>
                            handleRatingChange(subject, e.target.value)
                          }
                        >
                          {[...Array(11)].map((item, index) => {
                            return <option value={index} key={index}>{index}</option>;
                          })}
                        </select>
                      </td>
                      <td
                        className="syllabus-save"
                        onClick={() => saveSubjects(subject)}
                      >
                        <b>Save</b>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </React.Fragment>

        </div>
      </div>
    </div>
  );
};
export default Syallbus;
