import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lstModules from 'common/json/lstModules.json';






const httpURL = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port;
const httpURLAcdSch = lstModules['academicSchedule'].url + ":" + lstModules['academicSchedule'].port;

var tokenid = localStorage.getItem("tokenid");
var screenId = 8;



function getLstSession(callback) {
    var acdYrId = localStorage.getItem('academicYearId');
    var wingId = localStorage.getItem('wingId');
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURLAcdSch + '/sms/acdSch/list',
        method: "POST",
        params: { screenId, acdYrId, wingId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstSession = response.data.lstAcademicSchedule;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console .log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        console.error(error)
    });
    /* var obj = {};
    obj.lstSession = [{ sessionId: 1, session: 'Ist Term', pct: '15%', startDt: '01-Mar', endDt: '4-May' }
        , { sessionId: 2, session: 'Session-2', pct: '40%', startDt: '01-Jun', endDt: '4-Sep' }
        , { sessionId: 3, session: 'Session-3', pct: '30%', startDt: '21-Sep', endDt: '24-Dec' }
        , { sessionId: 4, session: 'Session-4', pct: '15%', startDt: '05-Jan', endDt: '23-Feb' }];
    obj.SvcStatus = 'Success';
    // console .log(typeof obj)
    // console .log(typeof cb)
    cb(obj); */
}


function getLstTTSummary(sessionId, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/summary/cls_sec/tt_status/list',
        method: "POST",
        params: { screenId, sessionId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.lstTTSummary = response.data.lstTTStatus;
        obj.message = response.data.SvcMsg;
        obj.status = 'Success';

        callback(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        // callback(obj)
    });
}



function deleteRow(clsId, sectionId, sessionId, callback) {
    var obj = { status: status };
    var status = 'Success'

    var retVal = window.confirm("Are you sure to delete this section timetable ?");

    if (retVal == true) {
        axios({
            url: httpURL + '/sms/tt/v1/summary/cls_sec/tt/delete',
            method: "POST",
            params: { clsId, sectionId, sessionId, screenId },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {

                obj.message = response.data.SvcMsg;
                getLstTTSummary()
                obj.status = 'Success';

            }

        }).catch((error) => {

            obj.status = 'Exception';
            obj.message = error;
            callback(obj)
        });
        return true;
    } else {
        return false;
    }
}

function publish(clsId, sectionId, sessionId, callback) {
    var obj = { status: status };
    var status = 'Success'
    var retVal = window.confirm("Do you want to publish ?");
    if (retVal == true) {
        axios({
            url: httpURL + '/sms/tt/v1/summary/cls_sec/tt/publish',
            method: "POST",
            params: { clsId, sectionId, sessionId, screenId },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                obj.message = response.data.SvcMsg;
                getLstTTSummary()
                obj.status = 'Success';
                toast.success(response.data.SvcMsg)
            } else {
                toast.error(response.data.SvcMsg)
            }


        }).catch((error) => {

            obj.status = 'Exception';
            obj.message = error;
            callback(obj)
        });
        return true;
    } else {
        return false;
    }
}




export { getLstSession, getLstTTSummary, deleteRow, publish };