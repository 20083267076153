import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getMonthWiseCollection } from "./ActualCollectionSvc";
const MonthWiseCollection = (props) => {
    const [loader, setLoader] = useState('none')
    const [monthId, setMonthId] = useState(0)
    const [month, setMonth] = useState("")
    const [lstMonthWiseCollection, setLstMonthWiseCollection] = useState([])
    const [lstLabel, setLstLabel] = useState([])
    const [selWingId, setSelWingId] = useState(0)
    useEffect(() => {
        if (props.selMonthId != 0) {
            setLoader("block")
            setMonthId(props.selMonthId)
            setMonth(props.selMonth)
            getMonthWiseCollection(props.selMonthId, cbMonthWiseCollection)
        }
    }, [props.selMonthId, props.selMonth])
    function cbMonthWiseCollection(data) {
        setLoader("none")
        if (data.SvcStatus === 'Success') {
            //setLstMonthWiseCollection(data.lstCollectionDtls)
            setLstLabel(data.lstCollectionDtls[0].lstLabel)
            addTotalInMonthCollection(data.lstCollectionDtls)
            window.dispatchEvent(new Event('resize'))
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function setSelectedWingId(param) {
        setSelWingId(param)
    }
    function addTotalInMonthCollection(pLstTemp) {
        var totalExp = 0;
        var totalAct = 0;
        var totalDiff = 0;
        var tmpTotal = [];
        for (var row = 0; row < pLstTemp.length; row++) {
            totalExp += pLstTemp[row].totalExpected
            totalAct += pLstTemp[row].totalActual
            totalDiff += pLstTemp[row].totalDifference
            // console .log("total:" + totalExp)
        }
        for (var row = 0; row < pLstTemp[0].lstLabel.length; row++) {
            var total = 0;
            for (var col = 0; col < pLstTemp.length; col++) {
                var lblAmtObj = pLstTemp[col].lstLabel[row];
                total += lblAmtObj.amt;
            }
            var obj = { labelId: lblAmtObj['labelId'], label: lblAmtObj['label'], amt: total }
            tmpTotal.push(obj);
        }
        pLstTemp.unshift({ clsSection: "-", sectionId: 0, wingId: 0, wing: "Total", totalActual: totalAct, totalExpected: totalExp, totalDifference: totalDiff, lstLabel: tmpTotal })
        setLstMonthWiseCollection(pLstTemp);
    }
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
    </colgroup>
    return (
        <div>
            <div id='tableHead' className="col-sm-4 col-centered">
                <> Actual Collection for the Month {month}</>
            </div>
            <table className='tableLayout1'>
                {colgrp}
                <thead><tr>
                    <th>Wing</th>
                    <th>Class</th>
                    <th>Billed</th>
                    <th>Collection</th>
                    <th>Difference</th>
                    {lstLabel.map((obj, idx) => {
                        return <th key={idx}>
                            {obj.label}</th>
                    })}</tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>{
                        lstMonthWiseCollection.map((obj, idx) => {
                            
                                if(obj.sectionId == 0) {return <tr
                                    className={obj.sectionId === selWingId ? 'selected' : ''}
                                    onClick={(e) => setSelectedWingId(obj.sectionId)}
                                    key={idx} >
                                    <td style={{ background: "rgb(213, 223, 233)", fontWeight:"bold" }}>{obj.wing}</td>
                                    <td style={{ background: "rgb(213, 223, 233)", fontWeight:"bold" }}>{obj.clsSection}</td>
                                    <td style={{ textAlign: "right",background: "rgb(213, 223, 233)", fontWeight:"bold"  }}>{Number(obj.totalExpected).toLocaleString("en-in")}</td>
                                    <td style={{ textAlign: "right",background: "rgb(213, 223, 233)", fontWeight:"bold"  }}>{Number(obj.totalActual).toLocaleString("en-in")}</td>
                                    <td style={{ textAlign: "right",background: "rgb(213, 223, 233)" , fontWeight:"bold" }}>{Number(obj.totalDifference).toLocaleString("en-in")}</td>
                                    {obj.lstLabel.map((label, idx1) => {
                                        return <td key={idx1} style={{ textAlign: "right",background: "rgb(213, 223, 233)", fontWeight:"bold"  }}>
                                            {Number(label.amt).toLocaleString("en-in")}</td>
                                    })}
                                </tr>}
                            else{
                            return <tr
                                className={obj.sectionId === selWingId ? 'selected' : ''}
                                onClick={(e) => setSelectedWingId(obj.sectionId)}
                                key={idx} >
                                <td style={{ background: "rgb(213, 223, 233)" }}>{obj.wing}</td>
                                <td>{obj.clsSection}</td>
                                <td style={{ textAlign: "right" }}>{Number(obj.totalExpected).toLocaleString("en-in")}</td>
                                <td style={{ textAlign: "right" }}>{Number(obj.totalActual).toLocaleString("en-in")}</td>
                                <td style={{ textAlign: "right" }}>{Number(obj.totalDifference).toLocaleString("en-in")}</td>
                                {obj.lstLabel.map((label, idx1) => {
                                    return <td key={idx1} style={{ textAlign: "right" }}>
                                        {Number(label.amt).toLocaleString("en-in")}</td>
                                })}
                            </tr>}
                        })}
                    </tbody>
                </table>

            </div>
        </div>
    )
}
export default MonthWiseCollection;