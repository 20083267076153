import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
function getTestListSvc (callBack, admissionId, clsId) {
  axios({
    url: httpURL + '/sms/admission/schedule/test/list',
    method: 'POST',
    params: { admissionId: admissionId, clsId: clsId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      if (data.SvcStatus == 'Success') {
        // console .log('Test list fetching success')
        callBack(data.lstTest)
      } else {
        toast.error(data.SvcMsg)
      }
    })
    .catch(error => {
      // console .log('Test List Catch error')
    })
}

function getSlotListSvc (cbSlotListResponse, admissionId, clsId) {
  axios({
    url: httpURL + '/sms/enquiry/slot/list',
    method: 'POST',
    params: { admissionId: admissionId, clsId: clsId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      if (data.SvcStatus == 'Success') {
        // console .log('list fetching success')
        cbSlotListResponse(data.lstSlot)
      } else {
        toast.error(data.SvcMsg)
      }
    })
    .catch(error => {
      // console .log('error')
    })
}

function getStaffListSvc (cbStaffListResponse) {
  axios({
    url: httpURLCommon + '/sms/mst/staff/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      if (data.SvcStatus == 'Success') {
        // console .log('list fetching success')
        cbStaffListResponse(data.lstStaff)
      } else {
        toast.error(data.SvcMsg)
      }
    })
    .catch(error => {
      // console .log('error')
    })
}

function assignSlotSvc (cbAssignSlotResponse, admissionId, lstTestId, slotId) {
  var formData = new FormData()
  formData.append('admissionId', admissionId)
  formData.append('lstTestId', JSON.stringify(lstTestId))
  formData.append('slotId', slotId)
  axios({
    url: httpURL + '/sms/enquiry/slot/assign',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      if (data.SvcStatus === 'Success') {
        cbAssignSlotResponse(data)
      } else {
        toast.error(data.SvcMsg)
      }
    })
    .catch(error => {
      // console .log('error')
    })
}

function assignSlotAndInformCandSvc (
  cbAssignSlotAndInformResp,
  admissionId,
  lstTestId,
  slotId
) {
  var formData = new FormData()
  formData.append('admissionId', admissionId)
  formData.append('lstTestId', JSON.stringify(lstTestId))
  formData.append('slotId', slotId)
  axios({
    url: httpURL + '/sms/enquiry/slot/assign_inform_cand',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbAssignSlotAndInformResp(data)
    })
    .catch(error => {
      // console .log('error')
    })
}

function informCandSvc (cbInformCandResp, admissionId, lstTestId, slotId) {
  var formData = new FormData()
  formData.append('admissionId', admissionId)
  formData.append('lstTestId', JSON.stringify(lstTestId))
  formData.append('slotId', slotId)
  // console .log(admissionId)
  axios({
    url: httpURL + '/sms/enquiry/slot/inform',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbInformCandResp(data)
    })
    .catch(error => {
      // console .log('error')
    })
}

function assignEvalSvc (cbAssignEvalResponse, lstTestSlotId, evalId) {
  var formData = new FormData()
  formData.append('lstTestSlotId', JSON.stringify(lstTestSlotId))
  formData.append('evalId', evalId)

  axios({
    url: httpURL + '/sms/enquiry/eval/assign',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbAssignEvalResponse(data)
    })
    .catch(error => {
      // console .log('error')
    })
}

function assignAndInformEvalSvc (
  cbAssignAndInformEvalResp,
  lstTestSlotId,
  evalId
) {
  var formData = new FormData()
  formData.append('lstTestSlotId', JSON.stringify(lstTestSlotId))
  formData.append('evalId', evalId)

  axios({
    url: httpURL + '/sms/enquiry/eval/assign_inform',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbAssignAndInformEvalResp(data)
    })
    .catch(error => {
      // console .log('error')
    })
}
function informEvalSvc (cbInformEval, lstTestSlotId) {
  var formData = new FormData()
  formData.append('lstTestSlotId', JSON.stringify(lstTestSlotId))
  // console .log('LstTestSlotId in SVC ' + lstTestSlotId)
  axios({
    url: httpURL + '/sms/enquiry/eval/inform',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbInformEval(data)
    })
    .catch(error => {
      // console .log('error')
    })
}

export { getTestListSvc }
export { getSlotListSvc }
export { getStaffListSvc }
export { assignSlotSvc }
export { assignSlotAndInformCandSvc }
export { informCandSvc }
export { assignEvalSvc }
export { assignAndInformEvalSvc }
export { informEvalSvc }
