import React, { useState, useEffect } from "react";
import RdoLbl from "./RdoLbl";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import lstModules from "common/json/lstModules.json";
import SliderType from "./sliderType";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
const STUDENT = 10;
const FATHER = 20;
const MOTHER = 30;
const GUARDIAN = 40;
const DONOR = 50;
const OTHERS = 60;

const RegistrationCommunication = (props) => {
  const [commId, setCommId] = useState(0);
  const [loader, setLoader] = useState("none");
  const [regsId, setRegsId] = useState(0);

  useEffect(() => {
    if (props.flgSliderType === SliderType.SLIDER_REGISTER) {
      if (props.regsId) {
        setLoader("block");
        getCommunicationSvc(props.regsId, cbCommunicationResponse);
        setRegsId(props.regsId);
      }
    }
  }, [props.regsId, props.flgSliderType]);

  function cbCommunicationResponse(obj) {
    // console .log(obj);
    if (obj.SvcStatus === "Success") {
      setCommId(obj.commId);
    } else {
      toast.error(obj.SvcMsg);
    }
    setLoader("none");
  }

  function getCommunicationSvc(regsId, callBack) {
    axios({
      url: httpURL + "/sms/regs/comm/dtls",
      method: "POST",
      params: { regsId: regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;
        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function saveCommunication() {
    setLoader("block");
    saveCommunicationSvc(commId, cbSaveCommDtlsResponse);
  }
  function cbSaveCommDtlsResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveCommunicationSvc(commId, callBack) {
    axios({
      url: httpURL + "/sms/regs/comm/save",
      method: "POST",
      params: { commId: commId, regsId: regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div
        className="col-sm-10"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "0.5%",
          paddingBottom: "0.5%",
        }}
      >
        <div
          className="col-sm-12"
          style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: "fixed",
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: "50%",
              top: "50%",
              display: loader,
            }}
          >
            <img
              className="loderImg"
              src={require("common/images/loader.gif")}
              alt="Loader..."
            />
          </div>

          {/* <RdoLbl
            label="Student"
            dataValue={STUDENT}
            key={"st_" + commId}
            checked={commId == STUDENT ? true : false}
            onChecked={setCommId}
          /> */}
          <RdoLbl
            label="Father"
            dataValue={FATHER}
            key={"fa_" + commId}
            checked={commId == FATHER ? true : false}
            onChecked={setCommId}
          />
          <RdoLbl
            label="Mother"
            dataValue={MOTHER}
            key={"mo_" + commId}
            checked={commId == MOTHER ? true : false}
            onChecked={setCommId}
          />
          <RdoLbl
            label="Guardian"
            dataValue={GUARDIAN}
            key={"gu_" + commId}
            checked={commId == GUARDIAN ? true : false}
            onChecked={setCommId}
          />
          {/* <RdoLbl
            label="Donor"
            dataValue={DONOR}
            key={"do_" + commId}
            checked={commId == DONOR ? true : false}
            onChecked={setCommId}
          />
          <RdoLbl
            label="Others"
            dataValue={OTHERS}
            key={"ot_" + commId}
            checked={commId == OTHERS ? true : false}
            onChecked={setCommId}
          /> */}
        </div>
      </div>
      <div
        className="col-sm-2"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "0.5%",
          paddingBottom: "0.5%",
        }}
      >
        <button
          type="button"
          className="btn-info btn"
          onClick={(e) => saveCommunication()}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default RegistrationCommunication;
