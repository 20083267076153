import React, { useEffect, useState, Fragment } from 'react';
import { Document, Page, PDFViewer, View, Text, Image, Font } from '@react-pdf/renderer';

import { TestHeader, PageHeader, PageFooter } from './pdfComponents';
import { pageStyle, textStyle, styleQuestion } from './styleQuestionsPDF';

import NaanumGothicRegular from "common/fonts/Nanum_Gothic/NanumGothic-Regular.ttf";
import NaanumGothicBold from "common/fonts/Nanum_Gothic/NanumGothic-Bold.ttf";

// import font from '/styles/localFonts/Poppins-Medium.ttf'
// Font.register({ family: 'Poppins', src: font })
Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});
Font.register({ family: "Inconsolata", src: "http://fonts.gstatic.com/s/inconsolata/v15/7bMKuoy6Nh0ft0SHnIGMuaCWcynf_cDxXwCLxiixG1c.ttf", });

Font.register({
    family: 'Nanum Gothic',
    fonts: [
        { src: NaanumGothicRegular, fontWeight: 'normal' },
        { src: NaanumGothicBold, fontWeight: 'bold' }
    ]
});

const TestQuestionPDF = (props) => {
    const { logoUrl, subject, clsSec, school, academicYear, duration, questionCount, lstQuestions, flgAnswerBox } = props;

    // const [isFirstPage, setIsFirstPage] = useState(true);
    const [className, setClassName] = useState("class-name");
    const [pageNo, setPageNo] = useState(1);

    useEffect(() => {
        // console .log("useEffect in TestQuestionPDF called, test", test, ", lstQuestions", lstQuestions)
    }, []);


    const questions = [];

    const getQuesRow = (question) => {
        // console .log("getQuestRow called", question);
        return (
            <View style={styleQuestion.questionRow}>
                <View style={[styleQuestion.questionNo]}>
                    <Text style={textStyle.textLeftSmallBold}>{question.qusNo}.</Text>
                </View>
                <View style={styleQuestion.question}>
                    {
                        question.qus !== "" ?
                            <View style={styleQuestion.questionText}>
                                <Text style={textStyle.textLeftSmall}>{question.qus}</Text>
                            </View>
                            : null
                    }
                    {
                        question.qusImg !== null ?
                            <View style={styleQuestion.questionImg}>
                                <Image style={textStyle.imageQuestion} src={question.qusImg} />
                            </View>
                            : null
                    }
                </View>
            </View>
        );
    }

    const getOptionSingleRow = (question) => {
        // console .log("getOptionSingleRow called", question);
        return (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"A) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceQuarter}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opA}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"B) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceQuarter}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opB}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"C) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceQuarter}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opC}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"D) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceQuarter}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opD}</Text>
                            </View>
                        </View>
                    </View>

                </View>
            </View>
        );
    }

    const getOptionTwoRows = (question) => {
        // console .log("getOptionTwoRow called", question);
        const row1 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"A) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opA}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"B) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opB}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
        const row2 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"C) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opC}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"D) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opD}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );

        questions.push(<Fragment>{row1}</Fragment>);
        questions.push(<Fragment>{row2}</Fragment>);
    }
    const getTwoOptionRow = (question) => {
        // console .log("getTwoOptionRow called", question);
        const row1 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"A) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opA}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"B) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opB}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );

        questions.push(<Fragment>{row1}</Fragment>);
    }

    const getOptionMultiRow = (question) => {
        // console .log("getOptionMultiRow called", question);
        const row1 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"A) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceFull}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opA}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
        const row2 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"B) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceFull}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opB}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
        const row3 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"C) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceFull}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opC}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
        const row4 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"D) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceFull}>
                            <View style={styleQuestion.optionText}>
                                <Text style={textStyle.optionTextLeft}>{question.opD}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );

        questions.push(<Fragment>{row1}</Fragment>);
        questions.push(<Fragment>{row2}</Fragment>);
        questions.push(<Fragment>{row3}</Fragment>);
        questions.push(<Fragment>{row4}</Fragment>);
    }

    const getOptionOnlyImages = (question) => {
        // console .log("getOptionOnlyImages called", question);
        const row1 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"A) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            {
                                question.opAImg !== null ?
                                    <View style={styleQuestion.optionImage}>
                                        <Image style={textStyle.imageOption} src={question.opAImg} />
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"B) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            {
                                question.opBImg !== null ?
                                    <View style={styleQuestion.optionImage}>
                                        <Image style={textStyle.imageOption} src={question.opBImg} />
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                </View>
            </View>
        );
        const row2 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"C) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            {
                                question.opCImg !== null ?
                                    <View style={styleQuestion.optionImage}>
                                        <Image style={textStyle.imageOption} src={question.opCImg} />
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"D) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceHalf}>
                            {
                                question.opDImg !== null ?
                                    <View style={styleQuestion.optionImage}>
                                        <Image style={textStyle.imageOption} src={question.opDImg} />
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                </View>
            </View>
        );
        questions.push(<Fragment>{row1}</Fragment>);
        questions.push(<Fragment>{row2}</Fragment>);
    }

    const getOptionWithImages = (question) => {
        // console .log("getOptionWithImages called", question);
        const row1 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"A) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceCombined}>
                            {
                                question.opA !== "" ?
                                    <View style={styleQuestion.textSection}>
                                        <View style={styleQuestion.optionTextCombined}>
                                            <Text style={textStyle.optionTextLeft}>{question.opA}</Text>
                                        </View>
                                    </View>
                                    : null
                            }
                            {
                                question.opAImg !== null ?
                                    <View style={styleQuestion.imageSection}>
                                        <View style={styleQuestion.optionImage}>
                                            <Image style={textStyle.imageOption} src={question.opAImg} />
                                        </View>
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                </View>
            </View >
        );
        const row2 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"B) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceCombined}>
                            {
                                question.opB !== "" ?
                                    <View style={styleQuestion.textSection}>
                                        <View style={styleQuestion.optionTextCombined}>
                                            <Text style={textStyle.optionTextLeft}>{question.opB}</Text>
                                        </View>
                                    </View>
                                    : null
                            }
                            {
                                question.opBImg !== null ?
                                    <View style={styleQuestion.imageSection}>
                                        <View style={styleQuestion.optionImage}>
                                            <Image style={textStyle.imageOption} src={question.opBImg} />
                                        </View>
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                </View>
            </View >
        );
        const row3 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"C) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceCombined}>
                            {
                                question.opC !== "" ?
                                    <View style={styleQuestion.textSection}>
                                        <View style={styleQuestion.optionTextCombined}>
                                            <Text style={textStyle.optionTextLeft}>{question.opC}</Text>
                                        </View>
                                    </View>
                                    : null
                            }
                            {
                                question.opCImg !== null ?
                                    <View style={styleQuestion.imageSection}>
                                        <View style={styleQuestion.optionImage}>
                                            <Image style={textStyle.imageOption} src={question.opCImg} />
                                        </View>
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                </View>
            </View >
        );
        const row4 = (
            <View style={styleQuestion.row}>
                <View style={styleQuestion.optionLeftSpace}>
                </View>
                <View style={styleQuestion.optionRow}>
                    <View style={styleQuestion.optionCell}>
                        <View style={styleQuestion.optionNameSpace}>
                            <Text style={textStyle.optionTextLeft}>{"D) "}</Text>
                        </View>
                        <View style={styleQuestion.optionSpaceCombined}>
                            {
                                question.opD !== "" ?
                                    <View style={styleQuestion.textSection}>
                                        <View style={styleQuestion.optionTextCombined}>
                                            <Text style={textStyle.optionTextLeft}>{question.opD}</Text>
                                        </View>
                                    </View>
                                    : null
                            }
                            {
                                question.opDImg !== null ?
                                    <View style={styleQuestion.imageSection}>
                                        <View style={styleQuestion.optionImage}>
                                            <Image style={textStyle.imageOption} src={question.opDImg} />
                                        </View>
                                    </View>
                                    : null
                            }
                        </View>
                    </View>
                </View>
            </View >
        );

        questions.push(<Fragment>{row1}</Fragment>);
        questions.push(<Fragment>{row2}</Fragment>);
        questions.push(<Fragment>{row3}</Fragment>);
        questions.push(<Fragment>{row4}</Fragment>);
    }

    const getAnswerRow = () => {
        const ansRow = (
            <View style={[styleQuestion.row, {}]}>
                <View style={styleQuestion.answerLeftSpace}>
                </View>
                <View style={styleQuestion.answerRow}>
                    <View style={styleQuestion.answerCell}>
                        <Text style={textStyle.textLeftMedium}>{"A) "}</Text>
                    </View>
                    <View style={styleQuestion.answerCell}>
                        <Text style={textStyle.textLeftMedium}>{"B) "}</Text>
                    </View>
                    <View style={styleQuestion.answerCell}>
                        <Text style={textStyle.textLeftMedium}>{"C) "}</Text>
                    </View>
                    <View style={styleQuestion.answerCell}>
                        <Text style={textStyle.textLeftMedium}>{"D) "}</Text>
                    </View>
                </View>
            </View>
        );
        // questions.push(<Fragment>{ansRow}</Fragment>);
        questions.push(ansRow);

    }


    const insertQuestion = (question) => {
        // console .log("insertQuestion called, question", question);
        const areAllImagesNull = (question.opAImg === null && question.opBImg === null && question.opCImg === null && question.opDImg === null);
        const areAllOptionsEmpty =
            ((!question.opA || question.opA === "") &&
                (!question.opB || question.opB === "") &&
                (!question.opC || question.opC === "") &&
                (!question.opD || question.opD === ""));
        const areAllOptionsVerySort =
            (!areAllOptionsEmpty &&
                ((!!question.opA && question.opA.length < 20) || !question.opA) &&
                ((!!question.opB && question.opB.length < 20) || !question.opB) &&
                ((!!question.opC && question.opC.length < 20) || !question.opC) &&
                ((!!question.opD && question.opD.length < 20) || !question.opD));
        const areAllOptionsSort =
            (!areAllOptionsEmpty && !areAllOptionsVerySort &&
                ((!!question.opA && question.opA.length < 45) || !question.opA) &&
                ((!!question.opB && question.opB.length < 45) || !question.opB) &&
                ((!!question.opC && question.opC.length < 45) || !question.opC) &&
                ((!!question.opD && question.opD.length < 45) || !question.opD));


        const questionRow = getQuesRow(question);
        questions.push(<Fragment>{questionRow}</Fragment>);

        if (question.optionType === 20) {
            getTwoOptionRow(question);
        }
        else if (!areAllImagesNull) {
            if (!areAllOptionsEmpty) {
                getOptionWithImages(question);
            }
            else {
                getOptionOnlyImages(question);
            }
        }
        else {
            if (areAllOptionsEmpty || areAllOptionsVerySort) {
                const optionRow = getOptionSingleRow(question);
                questions.push(<Fragment>{optionRow}</Fragment>);
            }
            else if (areAllOptionsSort) {
                getOptionTwoRows(question);
            }
            else {
                getOptionMultiRow(question);
            }
        }
        if (flgAnswerBox) {
            getAnswerRow();
        }
    }

    for (let i = 0; i < lstQuestions.length; i++) {
        insertQuestion(lstQuestions[i]);
        // console .log("questions after insertQuestion ", questions);
    }


    return (
        <div className='col-sm-12 padding-remove' style={{ marginTop: "1vh" }}>
            <PDFViewer style={{ width: "100%", height: "98vh", overflowY: "scroll" }}>
                <Document >
                    <Page style={pageStyle.page} wrap>
                        {pageNo === 1 ?
                            <View style={pageStyle.testHeader} >
                                <TestHeader
                                    logoUrl={props.logoUrl}
                                    subject={subject}
                                    clsSec={clsSec}
                                    school={school}
                                    academicYear={academicYear}
                                    duration={duration}
                                    questionCount={questionCount}
                                />
                            </View>
                            : null
                        }
                        <View style={pageStyle.fullPage}>
                            <View style={pageStyle.pageHeader} fixed>
                                <PageHeader
                                    className={className}
                                />
                                <View style={pageStyle.horizontalBar2}></View>
                            </View>
                            <View style={pageStyle.pageContent}>
                                {questions.map((question, index) => (
                                    <Fragment key={index} >
                                        {question}
                                    </Fragment>
                                ))}
                            </View>
                            <View style={pageStyle.pageFooter} fixed>
                                <View style={pageStyle.horizontalBar2}></View>
                                <PageFooter
                                    pageNo={pageNo}
                                />
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    );
}

export default TestQuestionPDF;
