import axios from "axios";
import lstModules from '../../../common/json/lstModules.json';
import 'react-toastify/dist/ReactToastify.css';

let httpURL = "";
var sysopstokenid = localStorage.getItem("sysopstokenid");
let queryString = require('querystring');
httpURL = lstModules['saLogin'].url + ":" + lstModules['saLogin'].port;

export const loginSvc = (loginObj, cbLogin) => {
    // console .log("loginSvc called");
    // console .log("url called", httpURL + "/cooledsys/sa/login");
    var obj = { status: 'Success', message:"" };
    const formData = new FormData();
    formData.append("loginInfo", JSON.stringify(loginObj));
    axios({
        url: httpURL + "/cooledsys/sysops/login",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        // params:{ "loginInfo":loginObj },
        data: formData,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.tokenid = response.headers.tokenid;
        obj.userDtls = response.data.userDtls;
        // console .log("response", response);
        // console .log("response.headers.tokenid", response.headers.tokenid);
        cbLogin(obj);
    }).catch(() => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbLogin(obj);
    })
}

export const forgotPasswordSvc = (mail, cbForgotPassword) => {
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/cooledsys/sysops/forgotpassword",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        params:{ "email":mail },
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbForgotPassword(obj);
    }).catch(() => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbForgotPassword(obj);
    })
}