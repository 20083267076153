import React, { Component, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
// import lstModules from 'common/json/lstModules.json';
//import './class.css';
// var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var queryString = require('querystring');

const screenId = 37;


const EditSlider = (props) => {
    const [classData1, setClassData1] = useState([])
    /* const { state, setState } = useState({
        classId: props.classData1.classId,
        classSectionId: props.classData1.classSectionId,
        section: props.classData1.section,
        maxStudents: props.classData1.maxStudents,
    }); */
    const [lstClass, setLstClass] = useState([]);
    const [classId, setClassId] = useState(0);
    const [classSectionId, setClassSectionId] = useState(0);
    const [section, setSection] = useState("");
    const [maxStudents, setMaxStudents] = useState("");

    useEffect(() => {
        // console .log("EditSlider called, props.classData1", props.classData1);
        // console .log("props.key", props.key);
        setClassData1(props.classData1)
        setLstClass(props.lstClass)
        setClassId(props.classData1.classId)
        setClassSectionId(props.classData1.classSectionId)
        setSection(props.classData1.section)
        setMaxStudents(props.classData1.maxStudents)
        // console .log("states updation called");
    }, [props]);



    function handleClassChange(e) {
        setClassId(e.target.value);
        setClassData1({ ...classData1, classId: e.target.value, });
    }

    function handleSectionChange(e) {
        setSection(e.target.value);
        setClassData1({ ...classData1, section: e.target.value, });
    }

    function handleMaxStudentChange(e) {
        setMaxStudents(e.target.value)
        setClassData1({ ...classData1, maxStudents: e.target.value, });
    }

    function saveClass() {
        props.saveClass(classData1)

    }

    const clearFormData = () => {
        setClassData1({ ...classData1, classId: 0, maxStudents:"", section:"", classSectionId:0 });
        setClassId(0)
        setClassSectionId(0)
        setSection("")
        setMaxStudents("")
    }

    return (
        <>
            <form id="addForm" className="col-centered col-sm-8 form-horizontal formWrapper clearfix">
                <div className="ab">
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Class<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-7 padding-remove">
                            <select autoComplete="off" name="classId" id="classesCmb"
                                type="text" className="form-control "
                                defaultValue={props.classData1.classId}
                                value={classId}
                                onChange={(e) => handleClassChange(e)}
                            >
                                <option value="0" >Class</option>
                                {props.lstClass.map((classData1) => (
                                    <option value={classData1.clsId}
                                        key={classData1.clsId} id={classData1.clsId} >{classData1.cls}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Section<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-7 padding-remove">
                            <input autoComplete="off" name="section" id="section"
                                type="text" className="form-control text-left"
                                defaultValue={props.classData1.section}
                                value={section}
                                onChange={handleSectionChange}
                                placeholder="Enter the section" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Max. Students <span style={{ color: 'red', marginLeft: 1 }}>*</span> </label>
                        <div className="col-sm-7 padding-remove">
                            <   input autoComplete="off" name="maxStudents" id="maxStudents"
                                type="text" className="form-control text-right"
                                defaultValue={props.classData1.maxStudents}
                                value={maxStudents}
                                onChange={handleMaxStudentChange}
                                placeholder="Max. Students" />
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 padding-remove paymentButtons">
                    <div className="form-group" >
                        <div className="ab1" >
                            <button type="button" style={{ marginTop: 20 }}
                                title="Save"
                                onClick={saveClass}
                                className="btn btn-info">Save</button>
                        </div>
                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={()=> {props.removeClassFromBody();}}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>

                </div>
            </form >
        </>

    )
}

export default EditSlider;