import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");


function getDashboardSummaryList(pExamId, callback) {
    var obj = { status: "Success", lstSummary: [] }
    axios({
        url: httpURL + '/sms/performance/dashboard/summary',
        method: "POST",
        params: { examId: pExamId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;

        if (response.data.SvcStatus === "Success") {
            obj.lstSummary = response.data.lstSummary;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstSummary = [];
        callback(obj);
    });
}
export { getDashboardSummaryList }