import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL =  lstModules['performance'].url + ':' + lstModules['performance'].port + '/sms/perf/validate/final';
const tokenid = localStorage.getItem('tokenid');


export const getLstExamSvc = (cbLstExam) => {
    // console .log("lstExamSvc called, url", httpURL + '/list_exam');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/list_exam',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in lstExamSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstExam = response.data.lstExam;
        }
        cbLstExam(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstExam(obj);
    })
}


export const getLstStudentScoreSvc = (sectionId, syllabusId, cbLstStudentScore) => {
    // console .log("getLstStudentScoreSvc called, url", httpURL + '/list_student');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/list_student',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: {"sectionId": sectionId,"syllabusId":syllabusId},
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getLstStudentScoreSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstStudentScore = response.data.lstStudentScore;
        }
        cbLstStudentScore(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstStudentScore(obj);
    })
}


export const getValidationDtlsSvc = (sectionId, examId, cbValidationDtls) => {
    // console .log("getValidationDtlsSvc called, url", httpURL + '/validation_dtls');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/validation_dtls',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: {"sectionId": sectionId,"examId":examId},
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getValidationDtlsSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.examId = examId;
        if (response.data.SvcStatus === "Success") {
            obj.examScoreValidationDtls = response.data.examScoreValidationDtls;
        }
        cbValidationDtls(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbValidationDtls(obj);
    })
}


export const saveValidationDtlsSvc = (sectionId, examId, cbValidateDtls) => {
    // console .log("saveValidationDtlscalled, url", httpURL + '/save_validation_dtls');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/save_validation_dtls',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: {"sectionId": sectionId,"examId":examId},
        withCredentials: true,
    }).then((response) => {
        // console .log("response in saveValidationDtls", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.examId = examId;
        cbValidateDtls(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbValidateDtls(obj);
    })
}