import React, { useState, useEffect } from "react";
import lstModules from "common/json/lstModules.json";
import axios from "axios";
import "common/css/master.css";
import "common/js/tabs.css";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";
import {
  getFacilityListSvc,
  saveSpecialtyListSvc,
} from "./ListingSpecialtySvc.js";

var tokenid = localStorage.getItem("tokenid");
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;
var schoolId = 77;

const Specialty = () => {
  const [lstFacility, setLstFacility] = useState([]);
  const [lstFac, setLstFac] = useState([]);
  const [lstFacId, setLstFacId] = useState([]);
  const [lstIdx, setLstIdx] = useState([]);
  const [lstSelFacId, setLstSelFacId] = useState([]);

  useEffect(() => {
    getFacList();
  }, []);
  function getFacList() {
    getFacilityListSvc(cbFaciltyListResponse);
  }
  function cbFaciltyListResponse(obj) {
    if (obj.status === "Success") {
      setLstFacility(obj.lstFacility);
      var lstTmpSelFacId = [];
      obj.lstFacility.forEach(function (obj) {
        if (obj.isAvailable) lstTmpSelFacId.push(obj.facilityId);
      });
      setLstSelFacId(lstTmpSelFacId);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj.message);
    }
  }
  const colgrp = (
    <colgroup>
      <col width="4%" />
      <col width="7%" />
      <col width="9%" />
    </colgroup>
  );

  async function handleLstFacIdChange(e, pId, pChecked) {
    // console .log(pId);
    // console .log(pChecked);
    var tmpLstFac = lstSelFacId;
    pId = parseInt(pId);
    pChecked = !!pChecked;

    if (pChecked && !lstSelFacId.includes(pId)) {
      if (lstSelFacId.length > 3) {
        e.target.checked = false;
        alert("Max 4 facilities are allowed");
        return;
      }
      tmpLstFac.push(parseInt(pId));
      setLstSelFacId(tmpLstFac);
    } else {
      tmpLstFac.splice(tmpLstFac.indexOf(pId), 1);
      setLstSelFacId(tmpLstFac);
    }
    setLstFacId(tmpLstFac);
  }

  function saveFacList() {
    var tmpLst = lstSelFacId;
    for (var i = 0; i <= 4 - lstSelFacId.length; i++) {
        tmpLst.push(0);
    }
    var facObj = { lstFac: tmpLst, schoolId: 77 };
    saveSpecialtyListSvc(facObj, cbSaveFacResponse);
  }

  function cbSaveFacResponse(data) {
    if (data.status === "Success") {
      getFacList();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  }
  return (
    <div className="col-sm-12">
      <Header />
      <ToastContainer autoClose={3000} />
      <form
        className="form form-horizontal col-sm-4 col-centered"
        style={{ marginTop: 20 }}
      >
        <div className="">
          <table className="tableLayout1">
            {colgrp}
            <thead>
              <tr>
                <th>Select</th>
                <th>Group</th>
                <th>Facility</th>
              </tr>
            </thead>
          </table>
          <div className="" style={{ height: "70vh", overflowY: "scroll" }}>
            <table className="tableLayout">
              {colgrp}
              <tbody>
                {lstFacility.map((obj, key) => {
                  return (
                    <tr key={key}>
                      <td
                        className="text-center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          key={obj.grpId + "_" + obj.facilityId}
                          id={"cbox_" + obj.facilityId}
                          onClick={(e) =>
                            handleLstFacIdChange(
                              e,
                              obj.facilityId,
                              e.target.checked
                            )
                          }
                          type="checkbox"
                          defaultChecked={obj.isAvailable !== 0}
                        />
                      </td>

                      <td className="text-center">{obj.grp}</td>
                      <td className="text-center">{obj.facility}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="form-group col-md-12">
          <button
            type="button"
            className="btn btn-warning pull-right"
            onClick={(e) => saveFacList()}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Specialty;
