import React, { useEffect, useState } from "react";
// import Header from 'common/js/header/header.js';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';
import '../css/academicSchedule.css';
import 'common/css/master.css';

import scheduleTimelineList from '../schedulTimeline.json'
import SchedulePeriodForm from './schdlPeriodForm'
// import SchedulePeriodForm from './old-schedulePeriodForm'
import AcademicScheduleForm from './academicScheduleForm';
import AcademicScheduleTable from './academicScheduleTbl';

import { getAcademicYearListSvc, getAcademicScheduleListSvc, deleteAcademicScheduleSvc, getAcademicYearWingListSvc } from "./academicScheduleSvc";

const AcademicScheduleCtrl = () => {

    const [flg, setFlg] = useState(true);
    const [fromDt, setFromDt] = useState("");
    const [toDt, setToDt] = useState("");
    const [academicScheduleData, setAcademicScheduleData] = useState([]);
    const [lstAcademicScheduleClass, setLstAcademicScheduleClass] = useState([]);

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstAcademicSchedule, setLstAcademicSchedule] = useState([]);
    const [lstAcademicYear, setLstAcademicYear] = useState([]);
    const [academicScheduleId, setAcademicScheduleId] = useState(0);
    const [academicYearId, setAcademicYearId] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [academicYearApplyLevel, setAcademicYearApplyLevel] = useState(0);
    const [isWingLevelVisible, setIsWingLevelVisible] = useState(false);
    const [lstWing, setLstWing] = useState([]);
    const [wingId, setWingId] = useState(0);
    const [isScheduleFormSliderOpen, setIsScheduleFormSliderOpen] = useState(false);
    const [isSchedulePeriodFormSliderOpen, setIsSchedulePeriodFormSliderOpen] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        // console .log("useEffect called in AcademicScheduleCtrl");
        if(key){
            getLstAcademicSchedule(academicYearId, wingId);
        } else {
            getAcademicYearList();
        }
    }, [key]);

    const getWingList = (acdYrId) => {
        setLoader("block");
        setOpacity(0.5);
        getAcademicYearWingListSvc(acdYrId, wingListResponse);
    }

    const wingListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstWing(obj.lstWing);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const getAcademicYearList = () => {
        setLoader("block");
        setOpacity(0.5);
        getAcademicYearListSvc(academicYearListResponse);
        // setDefaultAcademicYearId();
    }

    const academicYearListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstAcademicYear(obj.lstAcademicYear);
            // console .log("obj.lstAcademicYear", obj.lstAcademicYear);
            if (key === 0) {
                setDefaultAcademicYearId(obj.lstAcademicYear);
            }
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const getLstAcademicSchedule = (acdYrId, wingId) => {
        // console .log("getAcademicScheduleList method called");
        setLoader("block");
        setOpacity(0.5);
        getAcademicScheduleListSvc(acdYrId, wingId, lstAcademicScheduleResponse);
    }

    const lstAcademicScheduleResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstAcademicSchedule(obj.lstAcademicSchedule);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const changeYearStartEndDate = (yId) => {
        // console .log("changeYearStartEndDate called, lstAcademicYear", lstAcademicYear);
        // console .log("academic YearId", yId);
        const acdYear = lstAcademicYear.find(year => year.academicYearId == yId);
        // console .log("academicYear found, acdYear", acdYear);
        if (acdYear) {
            setFromDate(acdYear.fromDt);
            setToDate(acdYear.toDt);
        }
    }

    const changeAcademicYear = (yId) => {
        // console .log("changeAcademicYear called, academicYearId", yId);
        changeAcademicYearId(yId);
        changeYearStartEndDate(yId);
        getLstAcademicSchedule(yId, wingId);
    }

    const changeWingId = (wId) => {
        setWingId(wId);
        getLstAcademicSchedule(academicYearId, wId);
    }

    const changeAcademicYearId = (yId) => {
        // console .log("changeAcademicYearId method called, academicYearId", yId);
        setAcademicYearId(yId);
        if (lstAcademicYear)
            for (let i = 0; i < lstAcademicYear.length; i++) {
                // console .log("lstAcademicYear loop running, lstAcademicYear[i]", lstAcademicYear[i]);
                if (lstAcademicYear[i].academicYearId == yId) {
                    // console .log("academicYearId matched, applyLevel", lstAcademicYear[i].applyLevel);
                    changeAcademicYearApplyLevel(lstAcademicYear[i].applyLevel, yId);
                    break;
                }
            }
    }

    const setDefaultAcademicYearId = (lstAcdYr) => {
        // console .log("setDefaultAcademicYearId called, lstacademicyear", lstAcdYr);
        for (let i = 0; i < lstAcdYr.length; i++) {
            if (isPastDate(lstAcdYr[i].fromDt) && isFutureDate(lstAcdYr[i].toDt)) {
                // console .log("academicYear matched, lstAcdYr[i].academicYearId", lstAcdYr[i].academicYearId);
                changeAcademicYear(lstAcdYr[i].academicYearId);
                setFromDate(lstAcdYr[i].fromDt);
                setToDate(lstAcdYr[i].toDt);
                break;
            }
        }
    }

    const isPastDate = (dt) => {
        // console .log("isPastDate called, dt",dt);
        if (!dt)
            return false;
        const currDt = new Date();
        const [Day, Month, Year] = dt.split('-');
        const tmpDt = new Date(Year, Month - 1, Day);
        const differenceInTime = currDt.getTime() - tmpDt.getTime();
        // console .log("differenceInTime in isPastDate", differenceInTime);
        // console .log("returned ",differenceInTime >= 0);
        return differenceInTime >= 0;
    }

    const isFutureDate = (dt) => {
        // console .log("isFutureDate called, dt",dt);
        if (!dt)
            return false;
        const currDt = new Date();
        const [Day, Month, Year] = dt.split('-');
        const tmpDt = new Date(Year, Month - 1, Day);
        const differenceInTime = tmpDt.getTime() - currDt.getTime();
        // console .log("differenceInTime in isFutureDate", differenceInTime);
        // console .log("returned ",differenceInTime >= 0);
        return differenceInTime >= 0;
    }

    const setAcademicScheduleForm = (acdSchId) => {
        // console .log("setAcademicScheduleForm called, academicScheduleId", acdSchId);
        // console .log("lstAcademicSchedule", lstAcademicSchedule);
        let sessionTypeId = 0;
        let fromDt = "";
        let toDt = "";
        let label = "";
        let startTm = "";
        let endTm = "";
        let periods = 0;
        let wingId = "";
        if (lstAcademicSchedule) {
            for (let i = 0; i < lstAcademicSchedule.length; i++) {
                if (lstAcademicSchedule[i].academicScheduleId == acdSchId) {
                    // academicYearId = lstAcademicSchedule[i].academicYearId;
                    sessionTypeId = lstAcademicSchedule[i].sessionTypeId;
                    fromDt = lstAcademicSchedule[i].fromDt;
                    toDt = lstAcademicSchedule[i].toDt;
                    label = lstAcademicSchedule[i].label;
                    startTm = lstAcademicSchedule[i].startTm;
                    endTm = lstAcademicSchedule[i].endTm;
                    periods = lstAcademicSchedule[i].periods;
                    wingId = lstAcademicSchedule[i].wingId;
                    break;
                }
            }
        }
        const acdSchdlForm = {
            academicScheduleId: acdSchId,
            academicYearId: academicYearId,
            sessionTypeId: sessionTypeId,
            fromDt: fromDt,
            toDt: toDt,
            label: label,
            startTm: startTm,
            endTm: endTm,
            periods: periods,
            wingId: wingId,

        }
        // setAcademicScheduleData(acdSchdlForm);
        openScheduleSlider(acdSchId);
    }

    const deleteAcademicSchedule = (acdSchId) => {
        var retVal = window.confirm("Do you want to Delete This?");
        if (retVal == true) {
            setLoader("block");
            setOpacity(0.5);
            deleteAcademicScheduleSvc(acdSchId, wingId, deleteAcademicScheduleResponse);
        }
    }

    const deleteAcademicScheduleResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            getLstAcademicSchedule(academicYearId, wingId);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const editSchedulePeriod = (acdSchId) => {
        openSchedulePeriodSlider(acdSchId);
    }







    const openScheduleSlider = (acdSchId) => {
        setAcademicScheduleId(acdSchId);
        setAcademicYearId(academicYearId);
        setWingId(wingId);
        setIsScheduleFormSliderOpen(true);
    }

    const closeScheduleSlider = () => {
        setIsScheduleFormSliderOpen(false);
    }

    const openSchedulePeriodSlider = (acdSchId) => {
        setAcademicScheduleId(acdSchId);
        setAcademicYearId(academicYearId);
        setWingId(wingId);
        setIsSchedulePeriodFormSliderOpen(true);
    }

    const closeSchedulePeriodSlider = () => {
        setIsSchedulePeriodFormSliderOpen(false);
    }

    const updateKey = () => {
        setKey(prevKey => prevKey + 1);
    }

    const changeAcademicYearApplyLevel = (aplyLvl, yId) => {
        setAcademicYearApplyLevel(aplyLvl);
        if (aplyLvl == 20) {
            getWingList(yId);
            setIsWingLevelVisible(true);
        } else {
            setIsWingLevelVisible(false);
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer " style={{ opacity: opacity }}>
                <div className="col-md-11 col-centered">
                    <div className="col-xs-12 padding-remove topSectionWrap">
                        <div className="col-xs-2 padding-remove">
                            <div className="form-group">
                                <div className="col-sm-10 padding-remove">
                                    <select
                                        className="form-control"
                                        value={academicYearId}
                                        onChange={(e) => changeAcademicYear(e.target.value)}
                                    >
                                        <option value={0}>Academic Year</option>
                                        {lstAcademicYear &&
                                            lstAcademicYear.map((academicYear) => (
                                                <option
                                                    key={academicYear.academicYearId}
                                                    value={academicYear.academicYearId}>
                                                    {academicYear.academicYear}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group fromToDtWrap col-sm-12 padding-remove">
                                <span>{fromDate}</span>
                                <span>{toDate}</span>
                            </div>
                        </div>
                        {isWingLevelVisible &&
                            <div className="col-xs-2 padding-remove">
                                <div className="form-group">
                                    <div className="col-sm-10 padding-remove">
                                        <select
                                            className="form-control"
                                            defaultValue={wingId}
                                            onChange={(e) => changeWingId(e.target.value)}
                                        >
                                            <option value={0}>Wings</option>
                                            {lstWing &&
                                                lstWing.map((wing) => (
                                                    <option
                                                        key={wing.wingId}
                                                        value={wing.wingId}>
                                                        {wing.wing}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xs-8 padding-remove">
                            {lstAcademicSchedule &&
                                lstAcademicSchedule.length > 0 &&
                                <div className="col-xs-12 padding-remove timeLineParentWrap">
                                    <div className="padding-remove timeLineParent  col-xs-2">
                                        <label className="control-label">Timeline</label>
                                    </div>
                                    <div className="timeLineWrap col-xs-10 padding-remove">
                                        {lstAcademicSchedule.map((timeLine, index) => (
                                            <div key={index} style={{ width: timeLine.percentage, minWidth: timeLine.sessionType !== "Regular" ? '40px' : 'calc(' + timeLine.percentage + '%' + ' - ' + '40px)', padding: timeLine.sessionType !== "Regular" ? '0' : '0 10px' }}
                                                className={'timeLine' + timeLine.sessionType}
                                                title={timeLine.sessionType === "Regular" ? timeLine.fromDt + ' to ' + timeLine.toDt : timeLine.label}>
                                                {timeLine.sessionType === "Regular" ? <div >{timeLine.label}</div> : <div ></div>}
                                                {timeLine.sessionType === "Regular" ? <div>{timeLine.percentage + '%'}</div> : <div></div>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-xs-11  padding-remove">
                            <AcademicScheduleTable
                                lstAcademicSchedule={lstAcademicSchedule}
                                setAcademicScheduleForm={setAcademicScheduleForm}
                                deleteAcademicSchedule={deleteAcademicSchedule}
                                editSchedulePeriod={editSchedulePeriod}
                            />
                        </div>
                        <div className="col-xs-1 ">
                            <span title="Add" className="addIcon glyphicon glyphicon-plus-sign" onClick={() => setAcademicScheduleForm(0)}></span>
                        </div>
                        {isScheduleFormSliderOpen &&
                            <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }}>
                                <AcademicScheduleForm
                                    acdSchId={academicScheduleId}
                                    acdYrId={academicYearId}
                                    wngId={wingId}
                                    closeSlider={closeScheduleSlider}
                                    updateKey={updateKey}
                                    // acdScheduleData={academicScheduleData}
                                    setLoader={setLoader}
                                    setOpacity={setOpacity}

                                />
                            </div>
                        }
                        {isSchedulePeriodFormSliderOpen &&
                            <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }}>
                                <SchedulePeriodForm
                                    closeSlider={closeSchedulePeriodSlider}
                                    acdSchId={academicScheduleId}
                                    updateKey={updateKey}
                                    setLoader={setLoader}
                                    setOpacity={setOpacity}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AcademicScheduleCtrl;