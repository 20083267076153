import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { saveTestSvc } from './TestConfigSvc';
import { getWingsListSvc } from './TestConfigSvc';
import { getClassListSvc } from './TestConfigSvc';
import { deleteTestSvc } from './TestConfigSvc';

const TestConfigSlider = (props) => {

    const [testId, setTestId] = useState(props.testId)
    const [wingId, setWingId] = useState(props.wingId)
    const [clsId, setClsId] = useState(props.clsId)
    const [test, setTest] = useState(props.test)
    const [duration, setDuration] = useState(props.duration)
    const [maxScore, setMaxScore] = useState(props.maxScore)
    const [passScore, setPassScore] = useState(props.passScore)
    const [mandatory, setMandatory] = useState(!!props.mandatory)
    const [lstWings, setLstWings] = useState([])
    const [lstClass, setLstClass] = useState([])
    const [lstTest, setLstTest] = useState([])
    const [loader, setLoader] = useState("none")

    function cbWingsListResponse(data) {
        if (data.SvcStatus === "Success") {
            setLstWings(data.lstWings)
        }
        else {
            setLstWings([]);
            toast.error(data.SvcMsg)
        }
        setLoader('none');
    }
    function cbClassListResponse(data, pClassId) {
        if (data.SvcStatus === "Success") {
            setLstClass(data.lstClass)
            setClsId(pClassId)
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none');
    }

    useEffect(() => {
        // setTestId(props.testId)
        // console .log(props)
        // console .log("WingId" + props.wingId)
        var promise = new Promise((resolve, reject) => {
            setLoader('block');
            getWingsListSvc(cbWingsListResponse)

        })
        setTestId(props.testId)
        setWingId(props.wingId)
        setTest(props.test)
        setDuration(props.duration)
        setMaxScore(props.maxScore)
        setPassScore(props.passScore)
        setMandatory(!!props.mandatory)
        if (props.wingId) {
            setLoader('block');
            getClassListSvc(cbClassListResponse, props.wingId, props.clsId)
        }
    }, [props.wingId, props.clsId, props.testId, props.test, props.duration, props.maxScore, props.passScore, props.mandatory])
    function handleWingIdChange(param) {
        setWingId(param)
        getClassListSvc(cbClassListResponse, param, 0)
    }
    function handleClsIdChange(param) {
        setClsId(param)

    }
    function handleTestChange(param) {
        setTest(param)
    }
    function handleDurationChange(param) {
        setDuration(param)
    }
    function handleMaxScoreChange(param) {
        setMaxScore(param)
    }
    function handlePassScoreChange(param) {
        setPassScore(param)
    }
    function handleMandatoryChange(param) {
        setMandatory(param)
    }
    function updateTestSlider(test) {
        setTestId(test.testId)
        setWingId(test.wingId)
        setClsId(test.clsId)
        setTest(test.test)
        setDuration(test.duration)
        setMaxScore(test.maxScore)
        setPassScore(test.passScore)
        setMandatory(test.mandatory)
    }
    function cbSaveTestResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.closeSlider(data.testId)
            /*if (!data.testId) {
                var p2 = new Promise(function (resolve, reject) {
                    resolve(props.setSelTestId(data.testId));
                });

                p2.then(function () {
                    props.closeSlider();
                })
            }*/

        } else {
            toast.error(data.SvcMsg)
            // props.closeSlider()
        }
    }
    function saveTest() {
        const pTestObj = {
            wingId: wingId,
            clsId: clsId,
            test: test,
            mandatory: mandatory ? 1 : 0,
            duration: duration,
            maxScore: maxScore,
            passScore: passScore,
            testId: !testId ? 0 : testId
        }
        setLoader("block")

        saveTestSvc(pTestObj, cbSaveTestResponse);
        // console .log(pTestObj);
    }
    function deleteTest(pTestId) {
        if (!pTestId) {
            toast.warn("Please select the test");
            return;
        }
        var lConfirmation = window.confirm("Are you sure to delete the test?");
        if (lConfirmation) {
            setLoader("block")
            deleteTestSvc(pTestId, cbDeleteTestResponse)
        }
    }

    function cbDeleteTestResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
            setTestId(0)
        } else {
            toast.error(data.SvcMsg)
        }
        props.closeSlider()
    }
    return (
        <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: '100%', width: '100%', margin: '0px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Wing</label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleWingIdChange(e.target.value)} value={wingId}>
                            <option value={0}>Wing</option>
                            <option value={99}>All</option>
                            {lstWings.map((wing, idx) => {
                                return <option key={idx} value={wing.wingId}>{wing.wing}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Class</label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleClsIdChange(e.target.value)} value={clsId}>
                            <option value={0}>Class</option>
                            <option value={99}>All</option>
                            {lstClass.map((cls, idx) => {
                                return <option key={idx} value={cls.clsId}>{cls.cls}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Test Name</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" minLength={50} defaultValue={test} onChange={(e) => handleTestChange(e.target.value)} required />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Duration(mins)</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='number' className="form-control" min={15} max={180} defaultValue={duration} onChange={(e) => handleDurationChange(e.target.value)} required />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Max. Score</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='number' className="form-control" min={0} defaultValue={maxScore} onChange={(e) => handleMaxScoreChange(e.target.value)} required />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Passing Score</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='number' className="form-control" min={0} max={maxScore} defaultValue={passScore} onChange={(e) => handlePassScoreChange(e.target.value)} required />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Mandatory</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='checkbox' className="form-control" defaultValue={mandatory} defaultChecked={!mandatory ? 0 : 1} onChange={(e) => handleMandatoryChange(e.target.checked)} required />
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="button" className="btn btn-info btn-space" onClick={(e) => saveTest()}>Save</button>
                {/* </div>
            <div className="row"> */}
                <button type="button" className="btn btn-info btn-space" onClick={(e) => deleteTest(testId)}>Delete</button>
            </div>
        </form>
    );
};
export default TestConfigSlider;