import React from 'react'
import '../css/EnquiryCtrl.css'
import { useState, useEffect } from 'react'
import InterviewTestTable from './InterviewTestTable'
import {
  getInterviewDtlsSvc,
  getClassListSvc,
  getSectionListSvc,
  saveInterviewEvaluationSvc
} from './EvaluateInterviewSliderSvc'
import { toast, ToastContainer } from 'react-toastify'
import SliderType from './sliderType'

function EvaluateInterviewSlider (props) {
  const [lstTest, setLstTest] = useState([])
  const [name, setName] = useState('')
  const [gender, setGender] = useState('')
  const [dob, setDob] = useState('')
  const [classAppliedForId, setClassAppliedForId] = useState()
  const [classAppliedFor, setClassAppliedFor] = useState()
  const [result, setResult] = useState('')
  const [remarksforStudent, setRemarksforStudent] = useState('')
  const [internalRemarks, setInternalRemarks] = useState('')
  const [lstClass, setLstClass] = useState([])
  const [lstSection, setLstSection] = useState([])
  const [classAllocated, setClassAllocated] = useState(0)
  const [sectionAllocated, setSectionAllocated] = useState(0)
  const [loader, setLoader] = useState('none')
  const controller = new AbortController()
  const signal = controller.signal

  function saveInterviewEvaluation () {
    setLoader('block')
    const saveObj = {
      admissionId: props.admissionId,
      result: result,
      remarks: remarksforStudent,
      internalRemarks: internalRemarks,
      classAllocatedId: classAllocated,
      sectionId: sectionAllocated
    }
    if (result != 'P' && result != 'F') {
      toast.warn('Please select the result')
      return
    }
    if (classAllocated === 0) {
      toast.warn('Please select the class')
      return
    } else {
      if (classAllocated != classAppliedForId) {
        const confirmBox = window.confirm(
          "Allocated class is different from the class applied for. Once Saved, it can't be changed. Are you sure?"
        )
        if (confirmBox === true) {
          setLoader('block')
          saveInterviewEvaluationSvc(cbSaveInterviewEvaluationResponse, saveObj)
        }
      }
    }
  }
  function cbSaveInterviewEvaluationResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      setLoader('none')
    } else {
      toast.error(data.SvcMsg)
      setLoader('none')
    }
  }
  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])
  useEffect(() => {
    if (
      props.admissionId &&
      props.flgSliderType === SliderType.SLIDER_INTERVIEW_RESULT
      )
      setLoader('block')
      getInterviewDtlsSvc(cbInterviewDtlsResponse, props.admissionId)
    getClassListSvc(cbGetLstClassResponse)
  }, [props.admissionId, props.flgSliderType])

  function cbGetLstClassResponse (data) {
    if (data.SvcStatus === 'Success') {
      setLstClass(data.lstClass)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function cbInterviewDtlsResponse (data) {
    var promise = new Promise((resolve, reject) => {
      if (data.SvcStatus === 'Success') {
        // console .log('Test list fetching success')
        setLoader('none')
        getSectionListSvc(
          cbGetSectionListSvc,
          data.interviewResult.classAllocatedId
        )
        setInterviewResultForm(data.interviewResult)
      } else {
        toast.error(data.SvcMsg)
        setLoader('none')
      }
      // setSectionAllocated(data.sectionId)
    })
  }

  function setInterviewResultForm (data) {
    setName(data.name)
    setGender(data.gender)
    setDob(data.dob)
    setClassAppliedForId(data.appliedForClsId)
    setLstTest(data.lstTestResult)
    setRemarksforStudent(data.remarks)
    setInternalRemarks(data.internalRemarks)
    setResult(data.result)
    setClassAllocated(data.classAllocatedId)
    setSectionAllocated(data.sectionId)
  }
  function getClassAppliedFor (param) {
    // console .log('inside getclassappliedfor')
    for (var i = 0; i < lstClass.length; i++) {
      if (lstClass[i].clsId == param) {
        setClassAppliedFor(lstClass[i].cls)
        // console .log('inside if')
        break
      }
    }
  }

  function handleResultChange (param) {
    setResult(param)
  }

  function handleRemarksforStudentChange (param) {
    setRemarksforStudent(param)
  }

  function handleInternalRemarksChange (param) {
    setInternalRemarks(param)
  }

  function handleClassAllocatedChange (param) {
    setClassAllocated(param)
    getSectionListSvc(cbGetSectionListSvc, param)

    // setClassAllocated(param);
    // getSectionListSvc(cbGetSectionListSvc, param);
  }

  function cbGetSectionListSvc (data) {
    if (data.SvcStatus === 'Success') {
      setLstSection(data.lstSection)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function handleSectionAllocatedChange (param) {
    // console .log('Inside hadnleSection')
    for (var i = 0; i < lstSection.length; i++) {
      // console .log('Inside For of handle Section')
      if (lstSection[i].sectionId === parseInt(param)) {
        // console .log('Inside first If')
        if (lstSection[i].currentStudentCount < lstSection[i].maxStudentCount) {
          // console .log('Inside second If')
          setSectionAllocated(param)
        } else {
          const confirmBox = window.confirm(
            'Section has reached maximum no. of students? You can change the section later. Are you sure?'
          )
          if (confirmBox === false) {
            setSectionAllocated(0)
          }
        }

        break
      }
    }
  }

  return (
    <div>
      <div
        className='col-sm-12'
        style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
      >
        <ToastContainer autoClose={3000} hideProgressBar />

        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'> Name:</label>
            <div className='col-sm-6 padding-remove'>
              <input
                type='text'
                defaultValue={name}
                className='form-control'
                readOnly
              ></input>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'> Gender:</label>
            <div className='col-sm-6 padding-remove'>
              <input
                type='text'
                defaultValue={gender}
                className='form-control'
                readOnly
              ></input>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'> DOB:</label>
            <div className='col-sm-6 padding-remove'>
              <input
                type='text'
                defaultValue={dob}
                className='form-control'
                readOnly
              ></input>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'>
              {' '}
              Class Applied For:
            </label>
            <div className='col-sm-6 padding-remove'>
              <input
                type='text'
                defaultValue={classAppliedForId}
                className='form-control'
                readOnly
              ></input>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'> Test Table </label>
            <div className='col-sm-6 padding-remove'>
              <InterviewTestTable
                className='interviewResultTable'
                lstTestResult={lstTest}
              ></InterviewTestTable>
            </div>
          </div>
        </div>

        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'>
              Result<span className='red-color'>*</span>
            </label>
            <div className='col-sm-6 padding-remove'>
              <label className='radio-inline'>
                <input
                  type='radio'
                  name='result'
                  value='P'
                  checked={result === 'P'}
                  onChange={e => handleResultChange(e.target.value)}
                ></input>
                Pass
              </label>
              <label className='radio-inline'>
                <input
                  type='radio'
                  name='result'
                  value='F'
                  checked={result === 'F'}
                  onChange={e => handleResultChange(e.target.value)}
                ></input>
                Fail
              </label>
            </div>
          </div>
        </div>

        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'>
              {' '}
              Remarks For Student:
            </label>
            <div className='col-sm-6 padding-remove'>
              <textarea
                rows='8'
                cols='80'
                maxLength={500}
                type='text'
                defaultValue={remarksforStudent}
                onChange={e => handleRemarksforStudentChange(e.target.value)}
                className='form-control'
              ></textarea>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'> Internal Remarks:</label>
            <div className='col-sm-6 padding-remove'>
              <textarea
                rows='8'
                cols='80'
                maxLength={500}
                type='text'
                defaultValue={internalRemarks}
                className='form-control'
                onChange={e => handleInternalRemarksChange(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'>
              Class<span className='red-color'>*</span>
            </label>
            <div className='col-sm-6 padding-remove'>
              <select
                className='form-control'
                value={classAllocated}
                onChange={e => handleClassAllocatedChange(e.target.value)}
              >
                <option value={0}>Class</option>
                {lstClass.map((cls, idx) => {
                  return (
                    <option key={idx} value={cls.clsId}>
                      {cls.cls}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='row' style={{ width: '100%' }}>
          <div className='form-group col-md-6'>
            <label className='control-label col-sm-5'>Section</label>
            <div className='col-sm-6 padding-remove'>
              <select
                className='form-control'
                value={sectionAllocated}
                onChange={e => handleSectionAllocatedChange(e.target.value)}
              >
                <option value={0}>Section</option>
                {lstSection.map((sec, idx) => {
                  return (
                    <option key={idx} value={sec.sectionId}>
                      {sec.section}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        <div className='row' style={{ width: '100%' }}>
          <button
            type='button'
            className='btn btn-warning '
            onClick={e => saveInterviewEvaluation()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default EvaluateInterviewSlider
