
import React, { useState, useEffect } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { styleFooter } from './examReportCardStyles.js';


const FooterSection = (props) => {
    const { principalSign, teacherSign } = props;

    const [principalSignUrl, setPrincipalSignUrl] = useState("");
    const [teacherSignUrl, setTeacherSignUrl] = useState("");
    

    useEffect(() => {
        // console .log("FooterSection called, principalSign", principalSign, ", teacherSign", teacherSign);
        setTeacherSignUrl(teacherSign);
        setPrincipalSignUrl(principalSign);
        return () => {
            setTeacherSignUrl("");
        };
    }, [principalSign, teacherSign]);

    return (
        <View style={styleFooter.block}>
            <View style={styleFooter.empty_row}>
                <View style={styleFooter.colSign}>
                    {
                        !!teacherSignUrl ?
                            <Image style={styleFooter.image_sign} src={teacherSignUrl} />
                            : null
                    }
                </View>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                    {
                        !!principalSignUrl ?
                            <Image style={styleFooter.image_sign} src={principalSignUrl} />
                            : null
                    }
                </View>
            </View>
            <View style={styleFooter.row_labels}>
                <View style={styleFooter.colSignLabel}>
                    <Text style={[styleFooter.sign_text, {}]}>CLASS TEACHER</Text>
                </View>
                <View style={styleFooter.colSignLabel}>
                    <Text style={styleFooter.sign_text}>PARENT</Text>
                </View>
                <View style={styleFooter.colSignLabel}>
                    <Text style={styleFooter.sign_text}>PRINCIPAL</Text>
                </View>
            </View>
        </View>
    );
}

export default FooterSection;