import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

import 'common/css/header.css'
import PasswordChecklist from 'react-password-checklist'
import { changePasswordSvc, logoutSvc } from './headerSvc'

var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['userlogin'].url + ':' + lstModules['userlogin'].port

function ChangePass(props) {
  const { closePass, setOpacity, setLoader } = props;

  useEffect(() => {
   }, [])

  const [password, setPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = useState('')
  const [reset, setReset] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [showOldPass, setShowOldPass] = useState(false)
  const [showAgainPass, setShowAgainPass] = useState(false)
  const [hide, setHide] = useState(false)
  const [hide2, setHide2] = useState(false)
  const [hide3, setHide3] = useState(false)
  function showPassword() {
    if (showPass === true) {
      setShowPass(false)
      setHide2(false)
    } else {
      setShowPass(true)
      setHide2(true)
    }
  }
  function showAgainPassword() {
    if (showAgainPass === true) {
      setShowAgainPass(false)
      setHide3(false)
    } else {
      setShowAgainPass(true)
      setHide3(true)
    }
  }

  function showOldPassword() {
    if (showOldPass === true) {
      setShowOldPass(false)
      setHide(false)
    } else {
      setShowOldPass(true)
      setHide(true)
    }
  }

  function handleOldPass(e) {
    setOldPassword(e.target.value)
  }
  function handlePass(e) {
    setPassword(e.target.value)
  }
  function handlePassAgain(e) {
    setPasswordAgain(e.target.value)
  }


  function resetPassword(cbResetPassword) {
    // console .log('inside reset password')
    if (passwordAgain != password) {
      toast.warn('The Passwords do not match')
      return
    }
    setLoader("block");
    setOpacity(0.5);
    changePasswordSvc(oldPassword, password, cbResetPassword);
  }
  function cbResetPassword(data) {
    var obj = data;
    if (data.SvcStatus === 'Success') {
      logout()
      toast.success(data.SvcMsg)
      /* setTimeout(function () {
        window.location.href = '/'
      }, 2000) */
    } else if (obj.message == 'Your Session is expired, login again') {
      setTimeout(function () {
        window.location.href = "/";
      }, 2000);
      return false;
    } else {
      toast.error(obj.message);
    }

    setLoader("none");
    setOpacity(1);
  }

  const logout = () => {
    setLoader("block");
    setOpacity(0.5);
    logoutSvc(logoutResponse);
  }

  const logoutResponse = (data) => {
    var obj = data;
    if (obj.status === 'Success') {
      toast.success(obj.message);
      setTimeout(function () {
        window.location.href = "/";
      }, 2000);
      localStorage.removeItem('studentId');
      localStorage.removeItem('teacherId');
      localStorage.removeItem('tokenid');
      localStorage.removeItem('email');
    }
    else {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      }
      else if (obj.message == 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }
    setLoader("none");
    setOpacity(1);
  }

  function resetState(e) {
    // console .log('inside reset  ' + e)
    if (e === false) {
      setReset(false)
      return
    }
    setReset(true)
    // document.getElementById("resetBtn").removeAttribute('disabled')
  }
  return (
    <div style={{ height: 'fit-content' }}>
      <ToastContainer autoClose={3000} hideProgressBar />
      <button
        type='button'
        className='btn btn-info pull-right'
        onClick={() => closePass()}
      >
        <span
          id='close'
          title='Close'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
      <form>
        <div className='form-group col-md-12'>
          <label className='control-label col-md-4' align='right'>
            Old Password:
          </label>
          <div className='col-sm-6 padding-remove'>
            <input
              type={showOldPass ? 'text' : 'password'}
              className='form-control'
              onChange={e => handleOldPass(e)}
            />
            <span
              toggle='#password-field'
              onClick={e => showOldPassword()}
              className={
                hide === true
                  ? 'fa fa-fw field_icon toggle-password fa-eye-slash'
                  : 'fa fa-fw fa-eye field_icon toggle-password'
              }
            ></span>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <label
            style={{ align: 'right' }}
            className='control-label col-md-4'
            align='right'
          >
            Password:
          </label>
          <div className='col-sm-6 padding-remove'>
            <input
              type={showPass ? 'text' : 'password'}
              className='form-control'
              onChange={e => handlePass(e)}
            />
            <span
              toggle='#password-field'
              onClick={e => showPassword()}
              className={
                hide2 === true
                  ? 'fa fa-fw field_icon toggle-password fa-eye-slash'
                  : 'fa fa-fw fa-eye field_icon toggle-password'
              }
            ></span>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <label
            style={{ align: 'right' }}
            className='control-label col-md-4'
            align='right'
          >
            Confirm Password:
          </label>
          <div className='col-sm-6 padding-remove'>
            <input
              type={showAgainPass ? 'text' : 'password'}
              className='form-control'
              onChange={e => handlePassAgain(e)}
            />
            <span
              toggle='#password-field'
              onClick={e => showAgainPassword()}
              className={
                hide3 === true
                  ? 'fa fa-fw field_icon toggle-password fa-eye-slash'
                  : 'fa fa-fw fa-eye field_icon toggle-password'
              }
            ></span>
          </div>
        </div>
        <div className='form-group col-md-10 col-md-offset-2'>
          <div className=' col-sm-12 padding-remove'>
            <PasswordChecklist
              rules={['minLength', 'specialChar', 'number', 'capital']}
              minLength={8}
              iconSize={10}
              value={password}
              valueAgain={passwordAgain}
              onChange={isValid => {
                resetState(isValid)
              }}
              messages={{
                minLength: 'Minimum length should be 8 chars',
                specialChar: 'Should contain at least one special char',
                number: 'Should contain at least one number.',
                capital: 'Should contain at least one Capital letter'
              }}
            />
          </div>
        </div>
        <div className='form-group row col-sm-4 col-md-offset-4'>
          {/* <button type="button"  className="btn btn-info btn-space" onClick={(e) => forgetPassword(cbForgetPassword)}>
                Forget Password?
                </button> */}
          <button
            type='button'
            id='resetBtn'
            className='btn btn-info btn-space'
            onClick={e => resetPassword(cbResetPassword)}
            disabled={!reset}
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  )
}
export { ChangePass }
