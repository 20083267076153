import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function ClassWise(props) {
  const [labelCls, setlabelCls] = useState(props.labelCls);
  const [lstClassTbl, setLstClassTbl] = useState(props.lstClassTbl);
  const [selRowId,setSelRowId]=useState(0)
  var mTotal=0;
  var lTotal;
  useEffect(() => {
    setlabelCls(props.labelCls);
    setLstClassTbl(props.lstClassTbl);
  }, []);
     function setSelRow(param){
      setSelRowId(param)
    }
  return (
    <div id="monthTable">

      <div id='tableHead' className="col-sm-4 col-centered">
        <> Class Wise Planned Collection</>
      </div>
      <table className='col-sm-12'>
        <thead>
          <tr>
            {/* {// console .log(props.labelCls)} */}
            {props.labelCls.map((head,idx) => (
              <th key={idx}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>

          

          {
            lstClassTbl.map((obj1,idx1)=>{

              return (

                <tr id={obj1.monthId} style={{ background: idx1 === 0 ? '#d5dfe9' : '', fontWeight: idx1 === 0 ? 'bold' : '' }} key={idx1} className={idx1 == selRowId ? "selected" : ""} onClick={(e)=>setSelRow(idx1)}>

                <td style={{ background: '#d5dfe9' }}>{obj1.cls}</td>
                  { 
                      obj1.lstLabelAmt.map((obj2,idx2)=>{
                        
                        return(

                          <td key={idx2}>{Number(obj2.amt).toLocaleString("en-in")}</td>

                      
                      )
                    })
                  }
                  <td style={{ background: '#d5dfe9', fontWeight: 'bold' }}>{obj1.lstLabelAmt.map((obj2,idx2)=>{
                    mTotal=mTotal+obj2.amt
                    // return(
                    //   <div>{mTotal}</div>
                    //   )
                  })}{Number(mTotal).toLocaleString("en-in")}</td>
                    <td style={{display:'none'}}>{mTotal=0}</td>                
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}
