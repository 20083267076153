import React, { useState, useEffect } from "react";
import axios from "axios";
import LblTxt from "common/js/LblTxt";
import DropDownEvent from "common/js/DropDownEvent";
import lstModules from "common/json/lstModules.json";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";

var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
lstModules["schools"].url + ":" + lstModules["schools"].port;

var schoolId = 77;

export default function Info() {
  const [lstBoard, setLstBoard] = useState([]);
  const [lstLevel, setLstLevel] = useState([]);
  const [address1, setAddress1] = useState(null);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [localityId, setLocalityId] = useState(0);
  const [pin, setPin] = useState("");
  const [lstCountry, setLstCountry] = useState([]);
  const [lstState, setLstState] = useState([]);
  const [lstCity, setLstCity] = useState([]);

  const [lstLocality, setLstLocality] = useState([]);
  const [displayStateCmb, setDisplayStateCmb] = useState(true);
  const [displayCityCmb, setDisplayCityCmb] = useState(true);
  const [displayLocalityCmb, setDisplayLocalityCmb] = useState(true);
  const [displayCityInp, setDisplayCityInp] = useState(false);
  const [displayLocalityInp, setDisplayLocalityInp] = useState(false);

  const [schState, setSchState] = useState("");
  const [schCity, setSchCity] = useState("");
  const [schLocality, setSchLocality] = useState("");
  // const [schoolId, setSchoolId] = useState(localStorage.getItem("schoolId"));
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [board, setBoard] = useState([]);
  const [levelId, setLevelId] = useState(0);
  const [estd, setEstd] = useState("");
  const [loader, setLoader] = useState("none");
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    setLoader("block");
    getAdminInfoSvc(cbAdminInfoResponse);
    return () => {
      controller.abort();
    };
  }, []);

  function cbLevelResponse(pObj, pLevelId) {
    if (pObj.status === "Success") {
      setLstLevel(pObj.lstLevel);
      setLevelId(pLevelId);
    } else {
      toast.error(pObj.message);
    }
    setLoader("none");
  }
  function cbBoardResponse(pObj, pBoard) {
    if (pObj.status === "Success") {
      setLstBoard(pObj.lstBoard);
      setBoard(pBoard);
    } else {
      toast.error(pObj.message);
    }
    setLoader("none");
  }
  function handleStateChange(e) {
    var pStateId = e.target.value;
    var selTmpState = e.target.options[e.target.selectedIndex].text;
    setStateId(pStateId);
    setSchState(selTmpState);
    setLocalityId(0);
    setSchCity("");
    setCityId(0);
    setSchLocality("");
    if (selTmpState == "State") {
      setStateId(0);
      setDisplayLocalityInp(false);
      setDisplayCityInp(false);
      setSchState("");
    }
    getCityListSvc(cbCityListResponse, pStateId, 0);
  }
  function handleCityChange(e) {
    var pCityId = e.target.value;
    var selTmpCity = e.target.options[e.target.selectedIndex].text;
    getLocalityListSvc(cbLocalityListResponse, pCityId, 0);
    setCityId(pCityId);
    setSchCity(selTmpCity);
    setLocalityId(0);
    setSchLocality("");

    if (selTmpCity == "City") {
      setDisplayCityInp(false);
      setDisplayLocalityInp(false);
      setCityId(0);
      setSchCity("");
    }
    if (selTmpCity === "Others") {
      setDisplayCityInp(true);
      setSchCity("");
    } else {
      setDisplayCityInp(false);
    }
  }

  function handleLocalityChange(e) {
    var pLocalityId = e.target.value;

    var selTmpLocality = e.target.options[e.target.selectedIndex].text;
    setLocalityId(pLocalityId);
    if (selTmpLocality == "Locality") {
      setSchLocality("");
      setDisplayLocalityInp(false);
      setLocalityId(0);
    }
    setSchLocality(selTmpLocality);
    if (selTmpLocality === "Others") {
      setDisplayLocalityInp(true);
      setSchLocality("");
    } else {
      setDisplayLocalityInp(false);
    }
    // setLocalityId(pLocalityId);
  }

  function getStateListSvc(cb, pId) {
    var obj = { status: "Success", message: "", lstState };
    axios({
      url: httpSchoolURL + "/schools/mst/state/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        obj.status = data.SvcStatus;

        if (data.SvcStatus === "Success") {
          obj.lstState = data.lstState;
        } else {
          obj.message = data.SvcMsg;
        }

        cb(obj, pId);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getLocalityListSvc(cb, pCityId, pId) {
    var obj = { status: "Success", message: "", lstLocality: [] };
    axios({
      url: httpSchoolURL + "/schools/mst/locality/list",
      method: "POST",
      params: { cityId: pCityId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        obj.status = data.SvcStatus;

        if (data.SvcStatus === "Success") {
          obj.lstLocality = data.lstLocality;
        } else {
          obj.message = data.SvcMsg;
        }

        cb(obj, pId);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCityListSvc(cb, pStateId, pId) {
    var obj = { status: "Success", message: "", lstCity: [] };
    axios({
      url: httpSchoolURL + "/schools/mst/city/list",
      method: "POST",
      params: { stateId: pStateId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        obj.status = data.SvcStatus;

        if (data.SvcStatus === "Success") {
          obj.lstCity = data.lstCity;
        } else {
          obj.message = data.SvcMsg;
        }

        cb(obj, pId);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function cbStateListResponse(pLstState) {
    if (pLstState.SvcStatus === "Success") {
      setLstState(pLstState.lstState);
    } else {
      toast.error(pLstState.SvcMsg);
    }
    setLoader("none");
  }
  function cbCityListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstCity(data.lstCity);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbLocalityListResponse(data, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstLocality(data.lstLocality);
      setLocalityId(pLocalityId);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  function getLevelListSvc(cb, pId) {
    var obj = { status: "Success", message: "" };
    obj.lstLevel = [];
    // console .log(httpSchoolURL + "/schools/mst/level/list");
    axios({
      url: httpSchoolURL + "/schools/mst/level/list",
      method: "POST",
      params: {},
      headers: {},
      withCredentials: true,
    })
      .then((response) => {
        var data = response.data;
        // console .log(data);
        obj.status = data.SvcStatus;

        if (data.SvcStatus === "Success") {
          obj.lstLevel = data.lstLevel;
        } else {
          obj.message = data.SvcMsg;
        }

        cb(obj, pId);
      })
      .catch((error) => {
        obj.status = "error";
        obj.message = error;
        cb(obj);
      });
  }

  function getBoardListSvc(cb, pId) {
    var obj = { status: "Success", message: "" };
    obj.lstBoard = [];
    // console .log(httpSchoolURL + "/schools/mst/board/list");
    axios({
      url: httpSchoolURL + "/schools/mst/board/list",
      method: "POST",
      params: {},
      headers: {},
      withCredentials: true,
    })
      .then((response) => {
        var data = response.data;
        // console .log(data);
        obj.status = data.SvcStatus;

        if (data.SvcStatus === "Success") {
          obj.lstBoard = data.lstBoard;
        } else {
          obj.message = data.SvcMsg;
        }

        cb(obj, pId);
      })
      .catch((error) => {
        obj.status = "error";
        obj.message = error;
        cb(obj);
      });
  }
  const cbStateListApiEffect = async (obj, pStateId) => {
    if (obj.status === "Success") {
      // console .log(obj);
      await setLstState(obj.lstState);
      setStateId(pStateId);
    } else {
      console.error("Api failed " + obj);
    }
  };

  const cbCityListApiEffect = async (obj, pCityId) => {
    if (obj.status === "Success") {
      var tmpLstCity = obj.lstCity;
      await setLstCity(obj.lstCity);
      setCityId(pCityId);
      for (var i = 0; i < tmpLstCity.length; i++) {
        var objCity = tmpLstCity[i];
        // console .log(objCity.cityId + ", " + pCityId);
        // console .log(objCity.city + ", " + "Others");
        if (objCity.cityId == pCityId && objCity.city === "Others") {
          setDisplayCityInp(true);
          break;
        }
      }
    } else {
      console.error("Api failed " + obj);
    }
  };
  const cbLocalityListApiEffect = (obj, pLocalityId) => {
    if (obj.status === "Success") {
      var tmpLstLocality = obj.lstLocality;
      setLstLocality(tmpLstLocality);
      setLocalityId(pLocalityId);
      for (var i = 0; i < tmpLstLocality.length; i++) {
        var objLocality = tmpLstLocality[i];
        // console .log(objLocality.localityId + ", " + pLocalityId);
        // console .log(objLocality.locality + ", " + "Others");
        if (
          objLocality.localityId == pLocalityId &&
          objLocality.locality === "Others"
        ) {
          setDisplayLocalityInp(true);
          break;
        }
      }
    } else {
      console.error("Api failed " + obj);
    }
  };

  function cbAdminInfoResponse(pObj) {
    if (pObj.status === "Success") {
      setCityId(0);
      setSchCity("");
      setLocalityId(0);
      setSchLocality("");
      getStateListSvc(cbStateListApiEffect, pObj.info.stateId);
      getCityListSvc(cbCityListApiEffect, pObj.info.stateId, pObj.info.cityId);
      getLocalityListSvc(
        cbLocalityListApiEffect,
        pObj.info.cityId,
        pObj.info.localityId
      );
      setAddress1(pObj.info.address1);
      setSchCity(pObj.info.city);
      setSchLocality(pObj.info.locality);
      setPin(pObj.info.pin);
      setSchState(pObj.info.state);
      getLevelListSvc(cbLevelResponse, pObj.info.levelId);
      getBoardListSvc(cbBoardResponse, pObj.info.board.split(","));

      setInfo(pObj.info);
    } else {
      toast.error(pObj.message);
    }
    setLoader("none");
  }
  function setInfo(data) {
    setEstd(data.estd);
    setAddress1(data.address1);
    setStateId(data.stateId);
    setCityId(data.cityId);
    setLocalityId(data.localityId);
    setPin(data.pin);
    setLat(data.lat);
    setLng(data.lng);
  }

  function getAdminInfoSvc(cb) {
    var obj = { status: "Success", message: "" };
    obj.info = {};
    // console .log(httpSchoolURL + "/schools/admin/info/dtls");
    axios({
      url: httpSchoolURL + "/schools/admin/info/dtls",
      method: "POST",
      params: { schoolId: schoolId },
      headers: {},
      withCredentials: true,
    })
      .then((response) => {
        var data = response.data;
        // console .log(data);
        obj.status = data.SvcStatus;

        if (data.SvcStatus === "Success") {
          obj.info = data.info;
        } else {
          obj.message = data.SvcMsg;
        }

        cb(obj);
      })
      .catch((error) => {
        obj.status = "error";
        obj.message = error;
        cb(obj);
      });
  }
  function cbSaveInfoResponse(pObj) {
    if (pObj.status === "Success") {
      toast.success(pObj.message);
    } else {
      toast.error(pObj.message);
    }
    setLoader("none");
  }
  function saveInfo(cb) {
    var info = {
      schoolId: schoolId,
      board: board.toString(),
      levelId: levelId,
      estd: estd,
      address1: address1,
      stateId: stateId,
      cityId: cityId,
      city: schCity,
      localityId: localityId,
      locality: schLocality,
      pin: pin,
      lat: lat,
      lng: lng,
    };
    // console .log(info);
    var obj = { status: "Success", message: "", lstState };
    axios({
      url: httpSchoolURL + "/schools/admin/info/save",
      method: "POST",
      params: { info: JSON.stringify(info) },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        obj.status = data.SvcStatus;

        obj.message = data.SvcMsg;

        cb(obj);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <form
        id="schoolAddForm"
        className="col-sm-7 col-centered form-horizontal formWrapper"
      >
        <div className="row">
          <div className="col-sm-12">
            <label className="control-label col-sm-2">Established On</label>
            <div className="form-group col-sm-2">
              <input
                name="estb"
                id="txt_estb"
                type="number"
                className="form-control "
                placeholder="YYYY"
                defaultValue={estd}
                onChange={(e) => setEstd(e.target.value)}
                max={4}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <label className="control-label col-sm-2">Board</label>
            <div className="form-group col-sm-2">
              <select
                name="board"
                className="form-control"
                id="cmb_board"
                style={{ width: "100%" }}
                value={board}
                onChange={(e) =>
                  setBoard(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
                multiple
              >
                <option value="Board">Board</option>
                {lstBoard.map((item, idx) => {
                  return (
                    <option key={"brd_" + (idx + 1)} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <label className="control-label col-sm-2">Level</label>
            <div className="form-group col-sm-2">
              <select
                name="level"
                className="form-control"
                id="cmb_level"
                style={{ width: "100%" }}
                value={levelId}
                onChange={(e) => setLevelId(e.target.value)}
              >
                <option value="Level">Level</option>
                {lstLevel.map((item, idx) => {
                  return (
                    <option key={"lvl_" + (idx + 1)} value={item.levelId}>
                      {item.level}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {/* <LblTxt
        label="Address1:"
        dataType="text"
        dataValue={address1}
        onChange={setAddress1}
      ></LblTxt>
      <DropDownEvent
        label="State:"
        onChangeDropDown={handleStateChange}
        wrapperClass=""
        divClass=""
        option1="State"
        lst={lstState}
        dataValue={stateId}
        keyId="stateId"
        keyVal="state"
      ></DropDownEvent>
 */}
        {/* --------start---------- */}
        <div className="row">
          <div className="col-sm-12">
            <label className="col-sm-2 control-label">State</label>
            <div className="form-group">
              <div
                className="col-sm-4 padding-remove"
                style={{ display: displayStateCmb ? "block" : "none" }}
              >
                <select
                  value={stateId}
                  onChange={(e) => {
                    handleStateChange(e);
                  }}
                  className="form-control inp-search"
                  id="cmbState"
                >
                  <option value={0}>State</option>
                  {lstState &&
                    lstState.map((obj, idx) => {
                      return (
                        <option key={idx} text={obj.state} value={obj.stateId}>
                          {obj.state}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          {displayCityCmb && (
            <div className="col-sm-12">
              <label className="control-label col-sm-2">City</label>
              <div className="form-group">
                <div
                  className="col-sm-4 padding-remove"
                  style={{ display: displayCityCmb ? "block" : "none" }}
                >
                  <select
                    value={cityId}
                    onChange={(e) => {
                      handleCityChange(e);
                    }}
                    className="form-control inp-search"
                    id="cmbCity"
                  >
                    <option value={0}>City</option>
                    {lstCity &&
                      lstCity.map((obj, idx) => {
                        return (
                          <option key={idx} text={obj.city} value={obj.cityId}>
                            {obj.city}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {(!displayCityCmb || displayCityInp) && (
                  <div
                    className="col-sm-4 padding-remove"
                    style={{
                      display:
                        !displayCityCmb || displayCityInp ? "block" : "none",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="City"
                      defaultValue={schCity}
                      className="form-control"
                      onChange={(e) => {
                        // setCityId(0);
                        setSchCity(e.target.value);
                      }}
                    ></input>
                  </div>
                )}
              </div>
            </div>
          )}

          {displayLocalityCmb && (
            <div className="col-sm-12">
              <label className="col-sm-2 control-label">Locality</label>
              <div className="form-group">
                <div
                  className="col-sm-4 padding-remove"
                  style={{ display: displayLocalityCmb ? "block" : "none" }}
                >
                  <select
                    value={localityId}
                    onChange={(e) => {
                      handleLocalityChange(e);
                    }}
                    className="form-control inp-search"
                    id="cmbLocality"
                  >
                    <option value={0}>Locality</option>
                    {lstLocality &&
                      lstLocality.map((obj, idx) => {
                        return (
                          <option
                            key={idx}
                            text={obj.locality}
                            value={obj.localityId}
                          >
                            {obj.locality}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {(!displayLocalityCmb || displayLocalityInp) && (
                  <div
                    className="col-sm-4 padding-remove"
                    style={{
                      display:
                        !displayLocalityCmb || displayLocalityInp
                          ? "block"
                          : "none",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Locality"
                      defaultValue={schLocality}
                      className="form-control"
                      onChange={(e) => {
                        setSchLocality(e.target.value);
                      }}
                    ></input>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="col-sm-12">
            <label className="col-sm-2 control-label">Pin</label>
            <div className="form-group">
              <div className="col-sm-4 padding-remove">
                <input
                  type="text"
                  placeholder="Pin"
                  defaultValue={pin}
                  className="form-control"
                  onChange={(e) => setPin(e.target.value)}
                ></input>
              </div>
            </div>
          </div>

          <LblTxt
            lblCls="col-sm-2 control-label"
            valCls="form-group"
            divCls=""
            wrapperClass="col-sm-12 "
            cls="text-left"
            label="Address"
            placeholder="Address"
            dataType="text"
            dataValue={address1}
            onChange={setAddress1}
          />
        </div>
        {/* -------end---------- */}

        {/* <DropDownEvent
        label="City:"
        onChangeDropDown={handleCityChange}
        option1="City"
        lst={lstCity}
        dataValue={cityId}
        keyId="cityId"
        keyVal="city"
      ></DropDownEvent>
      
      {displayCityCmb && (
        <div className="col-sm-12">
          <div className="form-group">
            <div
              className="col-sm-6 padding-remove"
              style={{ display: displayCityCmb ? "block" : "none" }}
            >
              <select
                value={cityId}
                onChange={(e) => handleCityChange(e)}
                className="form-control inp-search"
                id="cmbCity"
              >
                <option value={0}>City</option>
                {lstCity &&
                  lstCity.map((obj, idx) => {
                    return (
                      <option key={idx} text={obj.city} value={obj.cityId}>
                        {obj.city}
                      </option>
                    );
                  })}
              </select>
            </div>
            {(!displayCityCmb || displayCityInp) && (
              <div
                className="col-sm-6 padding-remove"
                style={{
                  display: !displayCityCmb || displayCityInp ? "block" : "none",
                }}
              >
                <input
                  type="text"
                  placeholder="City"
                  defaultValue={schCity}
                  className="form-control"
                  onChange={(e) => {
                    // setCityId(0);
                    setSchCity(e.target.value);
                  }}
                ></input>
              </div>
            )}
          </div>
        </div>
      )}
      <DropDownEvent
        label="Locality:"
        option1="Locality"
        onChangeDropDown={handleLocalityChange}
        lst={lstLocality}
        dataValue={localityId}
        keyId="localityId"
        keyVal="locality"
      ></DropDownEvent>
      <LblTxt
        wrapperClass="row"
        divClass="form-group col-sm-2"
        label="Pin:"
        dataType="text"
        dataValue={pin}
        onChange={setPin}
      ></LblTxt> */}
        <div className="row">
          <div className="col-sm-12">
            <label className="control-label col-sm-2">Lat Long</label>
            <div className="form-group col-sm-3">
              <input
                name="lat"
                id="txt_lat"
                type="text"
                className="form-control "
                placeholder="Lat"
                defaultValue={lat}
                onChange={(e) => setLat(e.target.value)}
              />
            </div>
            <div className="form-group col-sm-3">
              <input
                name="lng"
                id="txt_lng"
                type="text"
                className="form-control "
                placeholder="Lng"
                defaultValue={lng}
                onChange={(e) => setLng(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-12 text-center userButtonWrap">
            <button
              type="button"
              title="Save"
              className="btn btn-info"
              style={{ marginTop: 20 }}
              onClick={(e) => saveInfo(cbSaveInfoResponse)}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
