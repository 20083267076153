import React, { useEffect, useState } from 'react';
import Header from 'common/js/header/header';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';

import { getClassSectionListSvc } from './classExamSyllabusSvc';
import ExamSyllaubsSlider from './examSyllabusSlider';

const ClassesTable = (props) => {
    const { examId, setLoader, setOpacity } = props;


    const [classFilter, setClassFilter] = useState("");
    const [sectionFilter, setSectionFilter] = useState("");
    const [lstAllClassSections, setLstAllClassSection] = useState([]);
    const [lstAllClassSectionsFiltered, setLstAllClassSectionsFiltered] = useState([]);
    const [classSectionId, setClassSectionId] = useState(0);
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [className, setClassName] = useState("");


    useEffect(() => {
        // console .log("ClassesTable called");
        getClassSectionList();
    }, [examId]);

    const getClassSectionList = () => {
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        getClassSectionListSvc(classSectionListResponse);
    }

    const filterCls = e => {
        setClassFilter(e.target.value);
        filterTable(e.target.value, sectionFilter);
    }
    const filterSec = e => {
        setSectionFilter(e.target.value);
        filterTable(classFilter, e.target.value);
    }

    const filterTable = (fClass, fSection) => {
        // console .log("lstAllClassSections", lstAllClassSections);
        let filterClassSecList = [];
        filterClassSecList = lstAllClassSections.filter(clsSec =>
            clsSec.className.toString().toLowerCase().includes(fClass.toLowerCase()));

        filterClassSecList = filterClassSecList.filter(clsSec =>
            clsSec.section.toString().toLowerCase().includes(fSection.toLowerCase()));

        setLstAllClassSectionsFiltered(filterClassSecList);
    }


    const classSectionListResponse = (data) => {
        // console .log("classSectionListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstAllClassSection(obj.lstAllClassSections)
            setLstAllClassSectionsFiltered(obj.lstAllClassSections)
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
    }

    const openSlider = () => {
        // console .log("openSliderMethod called");
        setIsSliderOpen(true);
    }

    const closeSlider = () => {
        // console .log("closeSliderMethod called");
        setIsSliderOpen(false);
    }

    const sendPdf = async (pClassSectionId, e) => {
        e.preventDefault()
        await setClassSectionId(pClassSectionId);
        // console .log("sendPdf method called, pClassSectionId", pClassSectionId);
    }

    const viewPdf = async (obj, e) => {
        e.preventDefault();
        await setClassSectionId(obj.classSectionId);
        await setClassName(obj.className + " - " + obj.section);
        // console .log("viewPdf method called, pClassSectionId", pClassSectionId);
        openSlider();
    }

    return (
        <div className="col-sm-12 padding-remove">
            <div className='col-md-12 padding-remove' style={{ width: '100%' }}>
                <table className='tableLayout1' id='customTable'>
                    <thead>
                        <tr>
                            <th style={{ width: 40 + '%' }}>
                                <input
                                    type='text'
                                    value={classFilter}
                                    onChange={filterCls}
                                    className='form-control inp-search'
                                    placeholder='Class'
                                />
                            </th>
                            <th style={{ width: 30 + '%' }}>
                                <input
                                    type='text'
                                    value={sectionFilter}
                                    onChange={filterSec}
                                    className='form-control inp-search'
                                    placeholder='Section'
                                />
                            </th>
                            <th style={{ width: 30 + '%' }}>Action</th>
                        </tr>
                    </thead>
                </table>

                <div className='tableDiv' style={{ height: 450 + 'px' }}>
                    <table id='periodTypeTable' className='tableLayout'>
                        <tbody id='periodTypeTableBody'>
                            {lstAllClassSectionsFiltered && lstAllClassSectionsFiltered.map(obj => (
                                <tr
                                    className={obj.classSectionId === parseInt(classSectionId) ? 'selected' : ''}
                                    key={obj.classSectionId}
                                    id={obj.classSectionId}
                                >
                                    <td hidden>{obj.classId}</td>
                                    <td style={{ width: 40 + '%' }} className='text-left'>
                                        {obj.className}
                                    </td>
                                    <td hidden>{obj.classSectionId}</td>
                                    <td style={{ width: 30 + '%' }} className='text-left'>
                                        {obj.section}
                                    </td>
                                    <td
                                        style={{ width: 30 + '%' }}
                                        className='text-center'
                                        title="View Exam Syllabus"
                                        data={JSON.stringify(obj)}
                                        onClick={e =>
                                            viewPdf(obj, e)
                                        }
                                    >
                                        <span className='fa fa-eye' title="View"></span>
                                        <span
                                            className='glyphicon glyphicon-share'
                                            title="Send PDF"
                                            data={JSON.stringify(obj)}
                                            onClick={e =>
                                                sendPdf(obj.classSectionId, e)
                                            }
                                        ></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isSliderOpen ?
                <div className='slider1-60'>
                    <ExamSyllaubsSlider
                        examId={examId}
                        classSectionId={classSectionId}
                        className={className}
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                        closeSlider={closeSlider}
                    />
                </div>
                : null
            }
        </div>
    );

}

export default ClassesTable;