import React, { useState, useEffect, useRef } from 'react'
import { getLstDateSvc, getLstSectionSvc } from './ExamScheduleTableSvc';
import { toast } from 'react-toastify';
import CellForExam from './CellForExam';


const ExamScheduleTable = (props) => {

    const [lstDates, setLstDates] = useState([])
    const [lstSection, setLstSection] = useState([])

    const colgrp = <colgroup>
        {
            lstDates.map((obj, i) => {
                return <col key={i} width={100 / lstDates.length + "%"}></col>
            })
        }
    </colgroup>


    useEffect(() => {
        if (props.examId !== 0) {
            getLstDateSvc(props.examId, cb);
        }
        getLstSectionSvc(cbLstSectionResponse)

    }, [props.examId])

    function cb(data) {
        // console .log(data)
        if (data.status === 'Success') {
            toast.success(data.message);
            var tmpLstDates = data.lstDates;
            tmpLstDates.unshift({})
            setLstDates(tmpLstDates);

        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(data.message);
                return false;
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }
    function cbLstSectionResponse(data) {
        // console .log(data)
        if (data.status === 'Success') {
            toast.success(data.message);
            setLstSection(data.lstSection);
            document.getElementById('1_0').dispatchEvent(
                new KeyboardEvent('keydown', {
                    'key': 'Tab'
                }));

        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(data.message);
                return false;
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }
    function clickNext(e) {
        // console .log(document.activeElement.getAttribute('id'))
        // console .log(document.activeElement.getAttribute('sectionid'))
        // console .log(document.activeElement.getAttribute('dt'))
        // console .log(document.activeElement.getAttribute('examid'))
    }
    function handleFocusOut(e) {

        e.preventDefault()
        e.currentTarget.previousElementSibling.dispatchEvent(
            new KeyboardEvent('keydown', {
                'key': 'Tab'
            }));
        // console .log(document.activeElement)
        /* var inputs = document.querySelectorAll('td.tabable')
        const event = new KeyboardEvent('keydown', { key: 'tab', }); */
        /* // console .log(inputs[0].id)
        document.getElementById('0_1').focus()
        // console .log(window.getComputedStyle(document.querySelector('td:focus')))
        // console .log(window.getComputedStyle(document.querySelector('td:focus').nextElementSibling).border)
        // console .log(window.getComputedStyle(document.querySelector('td:focus').nextElementSibling).borderRadius) */


    }


    return (
        <>
            <table className="tableLayout1">
                {colgrp}
                <thead>
                    <tr>
                        {lstDates.map((obj, i) => {
                            return <th key={i} style={{ background: obj.flgHoliday ? '#e0e0e0' : '' }}>
                                <div>{obj.examDt}</div>
                                <div>{obj.weekdayNm}</div>
                            </th>
                        })}
                    </tr>
                </thead>
            </table>
            <div className="" style={{ height: '83vh', overflowY: 'scroll' }}>
                <table className="tableLayout">
                    {colgrp}
                    <tbody>
                        {lstSection.map((objSec, j) => {
                            return <tr key={j}>

                                <td style={{ cursor: 'not-allowed' }} tabIndex={j + '_0'} onFocus={(e) => clickNext(e)}>{objSec.clsSection}</td>
                                {
                                    lstDates.map((obj, i) => {
                                        return i !== 0 && <td
                                            className="tabable"
                                            onClick={(e) => handleFocusOut(e)}
                                            onFocus={(e) => clickNext(e)}
                                            tabIndex={obj.flgHoliday ? -1 : 10 * j + 1}
                                            key={j + "_" + i}
                                            id={j + "_" + i}
                                            sectionid={objSec.sectionId}
                                            dt={obj.backendExamDt}
                                            examid={props.examId}
                                            style={{ background: obj.flgHoliday ? '#e0e0e0' : '', pointerEvents: obj.flgHoliday ? 'none' : 'auto' }}

                                        >
                                            <CellForExam examDt={obj.backendExamDt} sectionId={objSec.sectionId} examId={props.examId} />
                                        </td>
                                    })
                                }
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ExamScheduleTable