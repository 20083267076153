import React, { useEffect, useState } from 'react'
import ClassList from './classList';
import SectionList from './sectionList';




const ClsHeader = (props) => {
    const [state, setState] = useState({});

    useEffect(() => {
        // console .log("clsHeader.js called");
        // console .log("props.lstSession ttSubHead",props.lstSession);
        // console .log("props.selSessionId ttSubHead",props.selSessionId);
        // console .log("props.lstCls ttSubHead",props.lstCls);
        // console .log("props.lstSection ttSubHead",props.lstSection);
        // console .log("props.classId ttSubHead",props.classId);
        // console .log("props.selSectionId ttSubHead",props.selSectionId);
    }, [props.lstCls]);


    return (
        <div className="row" style={{ padding: '0px 15px', height: 'auto' }}>
            <div className="col-sm-3 padding-remove">
                <ClassList
                    lstCls={props.lstCls}
                    classClick={props.changeClass}
                    classId={props.classId}
                />
            </div>
            <div className="col-sm-9 padding-remove">
                <SectionList
                    lstSection={props.lstSection}
                    sectionClick={props.changeSection}
                    selSectionId={props.selSectionId}
                    classId={props.classId}
                />
            </div>
        </div>
    )
}

export default ClsHeader;