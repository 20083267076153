import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';

const InstructionText = (props) => {
    const { noOfQuestions } = props;

    const [instructionText, setinstructionText] = useState("");
    const [instructionNote, setInstructionNote] = useState("NOTE: Once an answer option is clicked, it can't be changed.");


    useEffect(() => {
        let instruction = "There will be " + noOfQuestions + " questions in total. After each question, the lead board will be displayed. The system will automatically move to the next question."
        setinstructionText(instruction);
        // console .log("InstructionText called");
    }, [noOfQuestions]);

    return (
        <div className="col-sm-12 col-centered padding-remove">
            <div className="question-instruction" >
                <div >
                    {instructionText}
                </div>
                <div style={{ minHeight: "10px" }}>
                </div>
                <div >
                    {instructionNote}
                </div>
            </div>
            {/* <div className="form-group col-sm-12 padding-remove"  >
                <textarea
                    maxLength={200}
                    rows={3}
                    value={instructionText}
                    className='form-control'
                    readOnly
                    disabled
                ></textarea>
            </div> */}
        </div>
    );
}

export default InstructionText;