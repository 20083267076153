import React, { useEffect, useState, Fragment } from 'react';
import { Document, Page, PDFViewer, View, Text, Image, Font, StyleSheet } from '@react-pdf/renderer';


import { pageStyle, otpPDFStyle, textStyle, headerStyle, styleQR } from './styleStudentOtpPdf';
import StaffOTPQR from './staffOTPQR';

import NaanumGothicRegular from "common/fonts/Nanum_Gothic/NanumGothic-Regular.ttf";
import NaanumGothicBold from "common/fonts/Nanum_Gothic/NanumGothic-Bold.ttf";

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});

Font.register({
    family: 'Nanum Gothic',
    fonts: [
        { src: NaanumGothicRegular, fontWeight: 'normal' },
        { src: NaanumGothicBold, fontWeight: 'bold' }
    ]
});
const StaffOTPPDF = (props) => {
    const [schoolId, setSchoolId] = useState(0);
    const { testId, test, lstOtp } = props;

    useEffect(() => {
        // console .log("useEffect in StaffOTPPDF called, test", test, ", lstOtp", lstOtp);
        setSchoolId(parseInt(localStorage.getItem("schoolId")));
    }, [lstOtp]);

    const students = [];
    function formatNumber(number) {
        // Convert the number to a string and split it into an array of characters
        const numberArray = number.toString().split('');
        // Join the array elements with a space
        return numberArray.join(' ');
    }

    const getStudentRow = (pStd1, pStd2, pStd3, pStd4, pStd5, isFirstRow) => {
        // console .log("getStudentRow called, pStd1", pStd1, ", pStd2", pStd2, ", pStd3", pStd3, ", pStd4", pStd4, ", isFirstRow", isFirstRow);
        return (
            <View style={isFirstRow ? otpPDFStyle.firstOtpRow : otpPDFStyle.otpRow}>
                <View style={styleQR.studentCell}>
                    {
                        !!pStd1 ?
                            <View key={pStd1.studentId} style={otpPDFStyle.column}>
                                <View style={styleQR.studentCellColumn}>
                                    <StaffOTPQR
                                        schoolId={schoolId}
                                        testId={testId}
                                        otp={pStd1.otp}
                                        qrSize={100}
                                    />
                                </View>
                                <View style={styleQR.studentCellColumn}>
                                    <Text style={styles.textCenterSmallBold}>{"Code: " + formatNumber(pStd1.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={styleQR.studentCell}>
                    {
                        !!pStd2 ?
                            <View key={pStd2.studentId} style={otpPDFStyle.column}>
                                <View style={styleQR.studentCellColumn}>
                                    <StaffOTPQR
                                        schoolId={schoolId}
                                        testId={testId}
                                        otp={pStd2.otp}
                                        qrSize={100}
                                    />
                                </View>
                                <View style={styleQR.studentCellColumn}>
                                    <Text style={styles.textCenterSmallBold}>{"Code: " + formatNumber(pStd2.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={styleQR.studentCell}>
                    {
                        !!pStd3 ?
                            <View key={pStd3.studentId} style={otpPDFStyle.column}>
                                <View style={styleQR.studentCellColumn}>
                                    <StaffOTPQR
                                        schoolId={schoolId}
                                        testId={testId}
                                        otp={pStd1.otp}
                                        qrSize={100}
                                    />
                                </View>
                                <View style={styleQR.studentCellColumn}>
                                    <Text style={styles.textCenterSmallBold}>{"Code: " + formatNumber(pStd3.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={styleQR.studentCell}>
                    {
                        !!pStd4 ?
                            <View key={pStd4.studentId} style={otpPDFStyle.column}>
                                <View style={styleQR.studentCellColumn}>
                                    <StaffOTPQR
                                        schoolId={schoolId}
                                        testId={testId}
                                        otp={pStd1.otp}
                                        qrSize={100}
                                    />
                                </View>
                                <View style={styleQR.studentCellColumn}>
                                    <Text style={styles.textCenterSmallBold}>{"Code: " + formatNumber(pStd4.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={styleQR.studentCell}>
                    {
                        !!pStd5 ?
                            <View key={pStd5.studentId} style={otpPDFStyle.column}>
                                <View style={styleQR.studentCellColumn}>
                                    <StaffOTPQR
                                        schoolId={schoolId}
                                        testId={testId}
                                        otp={pStd1.otp}
                                        qrSize={100}
                                    />
                                </View>
                                <View style={styleQR.studentCellColumn}>
                                    <Text style={styles.textCenterSmallBold}>{"Code: " + formatNumber(pStd5.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
            </View>
        )
    }

    for (let i = 0; i < lstOtp.length; i = i + 5) {
        const studentRow = getStudentRow(lstOtp[i], lstOtp[i + 1], lstOtp[i + 2], lstOtp[i + 3], lstOtp[i + 4], i === 0);
        students.push(studentRow);
    }

    return (
        <div className='col-sm-12 padding-remove' style={{ marginTop: "1vh" }}>
            <PDFViewer style={{ width: "100%", height: "98vh", overflowY: "scroll" }}>
                <Document >
                    <Page style={pageStyle.page} wrap>
                        <View style={pageStyle.fullPage}>
                            <View style={pageStyle.testHeader} >
                                <View style={headerStyle.column}>
                                    <View style={headerStyle.row}>
                                        <View style={headerStyle.viewLeft}>
                                            <Text style={textStyle.textCenterMediumBold}>{"List of OTP"}</Text>
                                        </View>
                                    </View>
                                    <View style={headerStyle.row}>
                                        <View style={headerStyle.leftSection}>
                                            <View style={headerStyle.viewLeft}>
                                                <Text style={textStyle.textLeftMediumBold}>
                                                    {test}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={headerStyle.rightSection}>
                                            <View style={headerStyle.viewRight}>
                                                <Text style={textStyle.textRightMediumBold}>{"Test Id: " + testId}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={pageStyle.horizontalBar2} fixed></View>
                            <View style={pageStyle.pageContent}>
                                {students.map((student, index) => (
                                    <Fragment key={index} >
                                        {student}
                                    </Fragment>
                                ))}
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    );
}

export default StaffOTPPDF;


const styles = StyleSheet.create({

    textCenterSmallBold: {
        fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
});