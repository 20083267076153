import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import { getPrinciSignSvc, getLogoSvc, tcDtlsSvc } from './tcSvc';
import TcPdf from '../pdf/tcPdf';

const TcPdfSlider = (props) => {
    const { certificateId, closePdfSlider } = props;

    const [pdfLoader, setPdfLoader] = useState("none");
    const [pdfOpacity, setPdfOpacity] = useState(1);
    const [schoolLogo, setSchoolLogo] = useState("");
    const [principalSign, setPrincipalSign] = useState("");
    const [pdfDtls, setPdfDtls] = useState({});
    const [studentDtlsObj, setStudentDtlsObj] = useState({});

    useEffect(() => {
        // console .log("TcPdfSlider called, certificateId", certificateId);
        getPrinciSignSvc(cbPrinciSign);
        getLogoSvc(cbLogoResponse);
        getTcDtls(certificateId);

    }, [certificateId]);


    function cbLogoResponse(imageUrl) {
        // console .log("cbLogoResponse called, imageUrl", imageUrl);
        setSchoolLogo(imageUrl)
    }

    function cbPrinciSign(imageUrl) {
        setPrincipalSign(imageUrl);
    }

    

    const getTcDtls = (pCertificateId) => {
        //// console .log("getTcDtls called");
        setPdfLoader("block");
        setPdfOpacity(0.5);
        tcDtlsSvc(pCertificateId, tcDtlsResponse);
    }

    const tcDtlsResponse = (data) => {
        //// console .log("tcDtlsResponse called, data", data);
        if (data.status === "Success") {
            //// console .log("data.certificateDtls", data.certificateDtls);
            setStudentDtlsObj(data.certificateDtls);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setPdfLoader("none");
        setPdfOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    return (
        <div >
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closePdfSlider()} style={{ right: "80%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%', display: pdfLoader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{

                    cursor: (pdfLoader === "none") ? 'pointer' : 'none',
                    pointerEvents: (pdfLoader === "none") ? 'auto' : 'none',
                    opacity: pdfOpacity
                }}>
            </div>

            <TcPdf
                certificateId={certificateId}
                schoolLogo={schoolLogo}
                principalSign={principalSign}
                studentDtlsObj={studentDtlsObj}
            />
        </div>
    );

}

export default TcPdfSlider;