import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import "../css/EnquiryCtrl.css";
import {
  assignSlotAndInformCandSvc,
  assignSlotSvc,
  getSlotListSvc,
  informCandSvc,
} from "./ScheduleTestSvc";
import SliderType from "./sliderType";

const SlotTable = (props) => {
  const [lstSlot, setLstSlot] = useState([]);
  const [lstTestSlot, setLstTestSlot] = useState([]);
  const [regsId, setRegsId] = useState(0);
  const [lstTestId, setLstTestId] = useState(props.lstTestId);
  const [slotId, setSlotId] = useState(0);

  function cbSlotListResponse(lstSlot) {
    setLstSlot(lstSlot);
  }
  useEffect(() => {
    // console .log(props);
    if (props.flgSliderType === SliderType.SLIDER_SCHEDULE_TEST) {
      if (props.regsId) {
        setRegsId(props.regsId);
        if (props.clsId) {
          getSlotListSvc(cbSlotListResponse, props.regsId, props.clsId);
        }
        setLstTestId(props.lstTestId);
        if (props.slotId) {
          setSlotId(props.slotId);
        }
      }     
    }
    
    // assignSlotSvc(cbAssignSlotResponse);
    // assignSlotAndInformCandSvc(cbAssignSlotAndInformResponse)
    // informCandSvc()
  }, [
    props.regsId,
    props.clsId,
    props.slotId,
    props.flgSliderType,
    props.lstTestId,
  ]);

  async function cbAssignSlotAndInformResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      getSlotListSvc(cbSlotListResponse, props.regsId, props.clsId);
      // await props.refreshTestTable();
    } else {

      if (data.SvcMsg === 'You are not logged in') {
          setTimeout(function () { window.location.href = ('/') }, 2000);
          return false;
      }
      else if (data.SvcMsg === 'Your Session is expired, login again') {
          setTimeout(function () { window.location.href = ('/') }, 2000);
          return false;
      } else {
          toast.error(data.SvcMsg);
      }
  }
  }
  function assignSlotAndInformCand() {
    assignSlotAndInformCandSvc(
      cbAssignSlotAndInformResponse,
      regsId,
      lstTestId,
      slotId
    );
  }

  function cbAssignSlotResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      getSlotListSvc(cbSlotListResponse, props.regsId, props.clsId);
      // props.refreshTestTable();
    } else {

      if (data.SvcMsg === 'You are not logged in') {
          setTimeout(function () { window.location.href = ('/') }, 2000);
          return false;
      }
      else if (data.SvcMsg === 'Your Session is expired, login again') {
          setTimeout(function () { window.location.href = ('/') }, 2000);
          return false;
      } else {
          toast.error(data.SvcMsg);
      }
  }
  }
  function assignSlot() {
    // console .log( "/TestId/" + lstTestId + "/RegsId/" + regsId + "/SlotId/" + slotId );
    assignSlotSvc(cbAssignSlotResponse, regsId, lstTestId, slotId);
  }

  function cbInformCandResp(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {

      if (data.SvcMsg === 'You are not logged in') {
          setTimeout(function () { window.location.href = ('/') }, 2000);
          return false;
      }
      else if (data.SvcMsg === 'Your Session is expired, login again') {
          setTimeout(function () { window.location.href = ('/') }, 2000);
          return false;
      } else {
          toast.error(data.SvcMsg);
      }
  }
  }

  function informCand() {
    informCandSvc(cbInformCandResp, regsId, lstTestId, slotId);
  }

  function selectSlot(param) {
    // console .log("clicked on slotId " + param);
    props.setSlotId(param);
  }
  const colgrp = (
    <colgroup>
      <col width="6%"></col>
      <col width="16%"></col>
      <col width="8%"></col>
      <col width="14%"></col>
      <col width="16%"></col>
      <col width="6%"></col>
    </colgroup>
  );
  return (
    <div className="slotTable">
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Slot Id</th>
            <th>Date</th>
            <th>Day</th>
            <th>Time</th>
            <th>Class</th>
            <th>Alloted</th>
          </tr>
        </thead>
      </table>

      <div className="" style={{ height: "30vh", overflowY: "scroll" }}>
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstSlot.map((slot, idx) => {
              return (
                <tr
                  key={"sl_" + idx}
                  onClick={(e) => selectSlot(slot.slotId)}
                  className={slot.slotId == props.slotId ? "selected" : ""}
                >
                  <td>{slot.slotId}</td>
                  <td>{slot.slotDt}</td>
                  <td>{slot.day}</td>
                  <td>{slot.slotTm}</td>
                  <td>{slot.cls}</td>
                  <td>{slot.allotedCount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        className="col-sm-12"
        style={{ border: "1px solid #ddd", padding: 2, marginTop: 2 }}
      >
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button
            className="btn btn-primary pull-right"
            id="bAdd"
            type="button"
            onClick={(e) => assignSlotAndInformCand()}
          >
            Assign Slot And Inform Candidate
          </button>
        </div>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button
            id="bAdd"
            className="btn btn-primary pull-right"
            type="button"
            onClick={(e) => assignSlot()}
          >
            Assign Slot
          </button>
        </div>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button
            id="bAdd"
            className="btn btn-primary pull-right"
            type="button"
            onClick={(e) => informCand()}
          >
            Inform Candidate
          </button>
        </div>
      </div>
    </div>
  );
};
export default SlotTable;
