import axios from 'axios'
import React from 'react'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
function getEnquiryTatSvc(callBack) {
    axios({
      url: httpURL+"/sms/mst/tat/dtls",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }).then((Response) => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
     
    }).catch((error) => {
      console.error(error)
    });
}

function saveEnquiryTatSvc(pObj, callBack) {
    axios({
        url: httpURL+"/sms/mst/tat/save",
        method: "POST",
        params: { tat: JSON.stringify(pObj) },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        callBack(data);
    }).catch((error) => { 
      console.error(error) 
  });
}

export {getEnquiryTatSvc, saveEnquiryTatSvc };
