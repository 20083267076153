import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import '../css/payrollSetupCtrl.css'
import { getGradeListSvc, getStaffTypeList, getEmployeeDtlsSvc, saveEmployeeDtls, getBankDtlsSvc, saveBankDtlsSvc, getAllowanceListSvc, getDeductionListsvc, saveAllowanceSvc, saveDeductionSvc } from './employeeSvc';
const EmployeeSlider = (props) => {
    const [staffId, setStaffId] = useState(props.staffId)
    const [empCode, setEmpCode] = useState(props.staffObj.empCode)
    const [staff, setStaff] = useState(props.staffObj.staff)
    const [role, setRole] = useState(props.staffObj.role)
    const [grade, setGrade] = useState(props.staffObj.grade)
    const [staffType, setStaffType] = useState(props.staffObj.staffType)
    const [pfAccNo, setPfAccNo] = useState("")
    const [esiAccNo, setEsiAccNo] = useState("")
    const [panNo, setPanNo] = useState("")
    const [lstStdAllowance, setLstStdAllowance] = useState([])
    const [lstSplAllowance, setLstSplAllowance] = useState([])
    const [lstStdDeduction, setLstStdDeduction] = useState([])
    const [lstSplDeduction, setLstSplDeduction] = useState([])
    const [bankName, setBankName] = useState("")
    const [bankIfsc, setBankIfsc] = useState("")
    const [bankAccNo, setBankAccNo] = useState("")
    const [beneficiaryName, setBeneficiaryName] = useState("")
    const [lstAllowanceSpl, setLstAllowanceSpl] = useState([])
    const [lstDeductionSpl, setLstDeductionSpl] = useState([])
    const [lstAllowanceStd, setLstAllowanceStd] = useState([])
    const [lstDeductionStd, setLstDeductionStd] = useState([])
    const [lstGrade, setLstGrade] = useState([])
    const [lstStaffType, setLstStaffType] = useState([])
    const [loader, setLoader] = useState("none");
    useEffect(() => {
        setEmpCode(props.staffObj.empCode)
        setStaff(props.staffObj.staff)
        setRole(props.staffObj.role)
        setGrade(props.staffObj.grade)
        setStaffId(props.staffId)
        if (props.staffId) {
            getEmployeeDtlsSvc(props.staffId, handleEmpDtlsResponse)
            getBankDtlsSvc(props.staffId, handleBankDtlsResponse)
            getAllowanceListSvc(props.staffId, handleAllowanceListResponse)
            getDeductionListsvc(props.staffId, handleDeductionListResponse)
            getGradeListSvc(handleGradeListResponse)
            getStaffTypeList(handleStaffTypeResponse)
        }
    }, [props])
    function handleGradeListResponse(data) {
        if (data.SvcStatus === 'Success') {
            setLstGrade(data.lstGrade)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleStaffTypeResponse(data) {
        if (data.SvcStatus === 'Success') {
            setLstStaffType(data.lstStaffType)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleEmpDtlsResponse(data) {
        if (data.SvcStatus === 'Success') {
            setPfAccNo(data.empDtls.pfAccNo)
            setEsiAccNo(data.empDtls.esiAccNo)
            setPanNo(data.empDtls.panNo)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleBankDtlsResponse(data) {
        if (data.SvcStatus === 'Success') {
            setBankName(data.bankDtls.bankName)
            setBankAccNo(data.bankDtls.bankAccNo)
            setBankIfsc(data.bankDtls.bankIfsc)
            setBeneficiaryName(data.bankDtls.beneficiaryName)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleAllowanceListResponse(data) {
        if (data.SvcStatus === 'Success') {
            setLstSplAllowance(data.lstSplAllowance)
            setLstStdAllowance(data.lstStdAllowance)
            for (var i = 0; i < data.lstSplAllowance.length; i++) {
                if (data.lstSplAllowance[i].selected === true) {
                    document.getElementById("inp_" + data.lstSplAllowance[i].allowanceId).removeAttribute('readOnly')
                    var obj = { allowanceId: data.lstSplAllowance[i].allowanceId, amount: data.lstSplAllowance[i].amount, flgPctOfBasic: data.lstSplAllowance[i].flgPctOfBasic, pctBasic: data.lstSplAllowance[i].pctBasic }
                    lstAllowanceSpl.push(obj)
                }
                else {
                    document.getElementById("inp_" + data.lstSplAllowance[i].allowanceId).setAttribute('readOnly', true)
                }
            }
            for (var i = 0; i < data.lstStdAllowance.length; i++) {
                var obj = { allowanceId: data.lstStdAllowance[i].allowanceId, amount: data.lstStdAllowance[i].amount, flgPctOfBasic: data.lstStdAllowance[i].flgPctOfBasic, pctBasic: data.lstStdAllowance[i].pctBasic }
                lstAllowanceStd.push(obj)
            }
            // console .log(lstAllowanceSpl)
            // console .log(lstAllowanceStd)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleDeductionListResponse(data) {
        if (data.SvcStatus === 'Success') {
            setLstSplDeduction(data.lstSplDeduction)
            setLstStdDeduction(data.lstStdDeduction)
            for (var i = 0; i < data.lstSplDeduction.length; i++) {
                if (data.lstSplDeduction[i].selected === true) {
                    document.getElementById("inpd_" + data.lstSplDeduction[i].deductionId).removeAttribute('readOnly')
                    var obj = { deductionId: data.lstSplDeduction[i].deductionId, amount: data.lstSplDeduction[i].amount }
                    lstDeductionSpl.push(obj)
                }
                else {
                    document.getElementById("inpd_" + data.lstSplDeduction[i].deductionId).setAttribute('readOnly', true)
                }
            }
            for (var i = 0; i < data.lstStdDeduction.length; i++) {
                var obj = { deductionId: data.lstStdDeduction[i].deductionId, amount: data.lstStdDeduction[i].amount }
                lstDeductionStd.push(obj)
            }
            // console .log(lstDeductionSpl)
            // console .log(lstDeductionStd)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handlePfAccNoChange(param) {
        setPfAccNo(param)
    }
    function handleEsiAccNoChange(param) {
        setEsiAccNo(param)
    }
    function handlePanChange(param) {
        setPanNo(param)
    }
    function handleBankNameChange(param) {
        setBankName(param)
    }
    function handleBankAccNoChange(param) {
        setBankAccNo(param)
    }
    function handleBankIfscChange(param) {
        setBankIfsc(param)
    }
    function handleBeneficiaryNameChange(param) {
        setBeneficiaryName(param)
    }
    function saveEmpDtls() {
        var tempGradeId = 0
        for (var i = 0; i < lstGrade.length; i++) {
            if (lstGrade[i].grade === grade) {
                tempGradeId = lstGrade[i].gradeId
            }
        }
        var tempStaffTypeId = 0
        for (var j = 0; j < lstStaffType.length; j++) {
            if (lstStaffType[j].type === staffType) {
                tempStaffTypeId = lstStaffType[j].typeId
            }
        }
        const pEmpDtls = {
            staffId: staffId,
            pfAccNo: pfAccNo,
            esiAccNo: esiAccNo,
            panNo: panNo,
            mainGradeId: tempGradeId,
            staffTypeId: tempStaffTypeId
        }
        setLoader("block")
        saveEmployeeDtls(pEmpDtls, cbSaveEmpDtls)
    }
    function cbSaveEmpDtls(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function saveBankDtls() {
        const pBankDtls = {
            staffId: staffId,
            bankName: bankName,
            bankIfsc: bankIfsc,
            bankAccNo: bankAccNo,
            beneficiaryName: beneficiaryName
        }
        setLoader("block")
        saveBankDtlsSvc(pBankDtls, cbSaveBankDtls)
    }
    function cbSaveBankDtls(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function saveAllowance() {
        const pLstAllowance = {
            staffId: staffId,
            lstAllowance: lstAllowanceStd.concat(lstAllowanceSpl)
        }
        setLoader("block")
        saveAllowanceSvc(pLstAllowance, cbSaveAllowance)
    }
    function cbSaveAllowance(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function saveDeduction() {
        const pLstDeduction = {
            staffId: staffId,
            lstDeduction: lstDeductionStd.concat(lstDeductionSpl)
        }
        setLoader("block")
        saveDeductionSvc(pLstDeduction, cbSaveDeduction)
    }
    function cbSaveDeduction(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function handleCBoxChangeAllowance(allowance_id, status) {
        // console .log("handleCBoxChange triggered");
        var lstTempArr = lstAllowanceSpl
        if (status && !lstAllowanceSpl.includes(allowance_id)) {
            document.getElementById("inp_" + allowance_id).removeAttribute('readOnly')
            for (var i = 0; i < lstSplAllowance.length; i++) {
                if (lstSplAllowance[i].allowanceId === allowance_id) {
                    var obj = { allowanceId: lstSplAllowance[i].allowanceId, amount: lstSplAllowance[i].amount }
                    lstTempArr.push(obj)
                }
            }
            setLstAllowanceSpl(lstTempArr)
            // console .log("checked " + lstTempArr)
        }
        else {
            document.getElementById("inp_" + allowance_id).setAttribute('readOnly', true)
            for (var i = 0; i < lstAllowanceSpl.length; i++) {
                if (lstAllowanceSpl[i].allowanceId === allowance_id) {
                    lstTempArr.splice(i, 1)
                }
            }
            setLstAllowanceSpl(lstTempArr)
            // console .log("unchecked" + lstTempArr)
        }
        // console .log(lstAllowanceSpl)
    }
    function handleCBoxChangeDeduction(deduction_id, status) {
        // console .log("handleCBoxChange triggered");
        var lstTempArr = lstDeductionSpl
        if (status && !lstDeductionSpl.includes(deduction_id)) {
            document.getElementById("inpd_" + deduction_id).removeAttribute('readOnly')
            for (var i = 0; i < lstSplDeduction.length; i++) {
                if (lstSplDeduction[i].deductionId === deduction_id) {
                    var obj = { deductionId: lstSplDeduction[i].deductionId, amount: lstSplDeduction[i].amount }
                    lstTempArr.push(obj)
                }
            }
            setLstDeductionSpl(lstTempArr)
            // console .log("checked " + lstTempArr)
        }
        else {
            document.getElementById("inpd_" + deduction_id).setAttribute('readOnly', true)
            for (var i = 0; i < lstDeductionSpl.length; i++) {
                if (lstDeductionSpl[i].deductionId === deduction_id) {
                    lstTempArr.splice(i, 1)
                }
            }
            setLstDeductionSpl(lstTempArr)
            // console .log("unchecked" + lstTempArr)
        }
        // console .log(lstDeductionSpl)

    }
    function handleSplAllowanceChange(allowance_id, value) {
        var lstTemp = lstAllowanceSpl
        for (var i = 0; i < lstTemp.length; i++) {
            if (lstTemp[i].allowanceId === allowance_id) {
                lstTemp[i].amount = value
            }
        }
        setLstAllowanceSpl(lstTemp)
        // console .log("splAllow" + JSON.stringify(lstTemp))
    }
    function handleStdAllowanceChange(pIdx, value) {
        var lstTemp = lstAllowanceStd
        lstTemp[pIdx].amount = value
        setLstAllowanceStd(lstTemp)
        // console .log("stdAllow" + JSON.stringify(lstTemp))
    }
    function handleStdDeductionChange(pIdx, value) {
        var lstTemp = lstDeductionStd
        lstTemp[pIdx].amount = value
        setLstDeductionStd(lstTemp)
        // console .log("stdDeduct" + JSON.stringify(lstTemp))
    }
    function handleSplDeductionChange(deduction_id, value) {
        var lstTemp = lstDeductionSpl
        for (var i = 0; i < lstTemp.length; i++) {
            if (lstTemp[i].deductionId === deduction_id) {
                lstTemp[i].amount = value
            }
        }
        setLstDeductionSpl(lstTemp)
        // console .log("splDeduct" + JSON.stringify(lstTemp))
    }
    function handleGradeChange(param) {
        setGrade(param)
    }
    function handleStaffTypeChange(param) {
        setStaffType(param)
    }
    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                {/* <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div> */}
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Emp. Code</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={empCode} className="form-control" readOnly></input>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Role</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={role} className="form-control" readOnly></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Name</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={staff} className="form-control" readOnly></input>
                        </div>
                    </div>

                </div>
            </form>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Grade</label>
                        <div className='col-sm-6 padding-remove'>
                            <select className="form-control" onChange={(e) => handleGradeChange(e.target.value)} value={grade}>
                                <option value={0}>Grade</option>
                                {lstGrade.map((obj, idx) => {
                                    return <option key={idx} value={obj.grade}>{obj.grade}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Staff Type</label>
                        <div className='col-sm-6 padding-remove'>
                            <select className="form-control" onChange={(e) => handleStaffTypeChange(e.target.value)} value={staffType}>
                                <option value={0}>Staff Type</option>
                                {lstStaffType.map((obj, idx) => {
                                    return <option key={idx} value={obj.type}>{obj.type}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-4">
                        <label className="control-label col-sm-5">PF Account No.</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={pfAccNo} className="form-control" onChange={(e) => handlePfAccNoChange(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label col-sm-5">ESI Account No.</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={esiAccNo} className="form-control" onChange={(e) => handleEsiAccNoChange(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="control-label col-sm-5">PAN</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={panNo} className="form-control" onChange={(e) => handlePanChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveEmpDtls()}>Save</button>
                    </div>
                </div>
            </form>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <h5>Standard Allowances</h5>
                    </div>
                    <div className="form-group col-md-6">
                        <h5>Special Allowances</h5>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <>{
                            lstStdAllowance.map((allow, idx) => {
                                return <div className="form-group col-md-12" key={idx}>
                                    <label className="control-label col-sm-5">{allow.allowance}</label>
                                    <div className='col-sm-6 padding-remove'>
                                        <input style={{ textAlign: "right" }}
                                            type="number" defaultValue={allow.amount} className="form-control" onChange={(e) => handleStdAllowanceChange(idx, e.target.value)}
                                        />
                                    </div>
                                </div>
                            })
                        }</>
                    </div>
                    <div className="form-group col-md-6">
                        <div className='tablebodyWrapper1' style={{ height: '22vh' }}>
                            <table className='tableLayout'><tbody>{
                                lstSplAllowance.map((allow, idx) => {
                                    return <tr key={idx}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                defaultChecked={allow.selected}
                                                onClick={(e) => handleCBoxChangeAllowance(allow.allowanceId, e.target.checked)}
                                                id={"cbx_" + allow.allowanceId}
                                            />
                                        </td>
                                        <td>{allow.allowance}</td>
                                        <td>
                                            <input style={{ textAlign: "right" }}
                                                id={"inp_" + allow.allowanceId}
                                                type="number" defaultValue={allow.amount} className="form-control" onChange={(e) => handleSplAllowanceChange(allow.allowanceId, e.target.value)}
                                                readOnly
                                            />
                                            {/* {lstAllowanceSpl.map((temp) => {
                                                temp.allowanceId === allow.allowanceId ?
                                                    <input
                                                        style={{ textAlign: "right" }} type="text" defaultValue={allow.amount} className="form-control" onChange={(e) => handleSplAllowanceChange(idx, allow.allowanceId, e.target.value)}
                                                    /> : <input
                                                        style={{ textAlign: "right" }} type="text" defaultValue={allow.amount} className="form-control" readOnly
                                                    />
                                            })} */}
                                        </td>
                                    </tr>
                                })
                            }</tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveAllowance()}>Save</button>
                    </div>
                </div>
            </form>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <h5>Standard Deductions</h5>
                    </div>
                    <div className="form-group col-md-6">
                        <h5>Special Deductions</h5>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <>{
                            lstStdDeduction.map((allow, idx) => {
                                return <div className="form-group col-md-12" key={idx}>
                                    <label className="control-label col-sm-4">{allow.deduction}</label>
                                    <div className='col-sm-4'>
                                        <input style={{ textAlign: "right" }} type="text" defaultValue={allow.amount} className="form-control" onChange={(e) => handleStdDeductionChange(idx, e.target.value)}></input>
                                    </div>
                                    <div className='col-sm-4'>
                                        <input type="text" defaultValue={allow.strPct} className="form-control" readOnly></input>
                                    </div>
                                </div>
                            })
                        }</>
                    </div>
                    <div className="form-group col-md-6">
                        <div className='tablebodyWrapper1' style={{ height: '22vh' }}>
                            <table className='tableLayout'><tbody>{

                                lstSplDeduction.map((allow, idx) => {
                                    return <tr key={idx}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                defaultChecked={allow.selected}
                                                onClick={(e) => handleCBoxChangeDeduction(allow.deductionId, e.target.checked)}
                                            />
                                        </td>
                                        <td>{allow.deduction}</td>
                                        <td>
                                            <input style={{ textAlign: "right" }}
                                                id={"inpd_" + allow.deductionId} readOnly
                                                type="text" defaultValue={allow.amount} className="form-control" onChange={(e) => handleSplDeductionChange(allow.deductionId, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                })
                            }</tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveDeduction()}>Save</button>
                    </div>
                </div>
            </form>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className='row' style={{ width: '100%' }}>
                    <h4>Bank Details</h4>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Bank Name</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={bankName} className="form-control" onChange={(e) => handleBankNameChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">IFSC</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={bankIfsc} className="form-control" onChange={(e) => handleBankIfscChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Account No.</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={bankAccNo} className="form-control" onChange={(e) => handleBankAccNoChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Beneficiary Name</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={beneficiaryName} className="form-control" onChange={(e) => handleBeneficiaryNameChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveBankDtls()}>Save</button>
                    </div>
                </div>
            </form>
        </>
    )
};
export default EmployeeSlider;