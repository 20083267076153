import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { deleteTestSvc } from "./TestConfigSvc";
import { getTestListSvc } from "./TestConfigSvc";

const TestConfigTable = (props) => {
  const [lstTest, setLstTest] = useState([]);
  const [testId, setTestId] = useState(0);
  const [flgNew, setFlgNew] = useState(0);
  const [wingId, setWingId] = useState("");
  const [clsId, setClsId] = useState("");
  const [test, setTest] = useState("");
  const [duration, setDuration] = useState(0);
  const [mandatory, setMandatory] = useState(false);
  const [loader, setLoader] = useState("none");
  const [selTestId, setSelTestId] = useState();

  useEffect(() => {
    getTestListSvc(handleTestResponse);
  }, []);

  function handleTestResponse(pLstTest) {
    setLstTest(pLstTest);
    setFoundLst(pLstTest);
  }
  function editTest(testId) {
    // console .log(testId);
    props.setTestforEdit(testId);
    props.openSlider();
  }

  function updateTestList(test) {
    setTestId(test.testId);
    setWingId(test.wingId);
    setClsId(test.clsId);
    setTest(test.test);
    setDuration(test.duration);
    setMandatory(test.mandatory);
  }

  function deleteTest(pTestId) {
    var pConfirmation = window.confirm("Are you sure to delete the test?");
    if (pConfirmation) {
      setLoader("block");
      deleteTestSvc(pTestId, cbDeleteTestResponse);
    }
  }
  function cbDeleteTestResponse(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.getLstTest();
      props.generateRandomNo();
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function handleSelTestId(param) {
    setSelTestId(param);
  }
  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );

  const [wing, setWing] = useState("");
  const [foundLst, setFoundLst] = useState(lstTest);
  const filterWing = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = foundLst.filter((user) => {
        return user.wing.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundLst(results);
    } else {
      setFoundLst(lstTest);
      // If the text field is empty, show all users
    }

    setWing(keyword);
  };
  const [cls, setCls] = useState("");
  const filterCls = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = foundLst.filter((user) => {
        return user.cls.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundLst(results);
    } else {
      setFoundLst(lstTest);
      // If the text field is empty, show all users
    }

    setCls(keyword);
  };
  const [tst, setTst] = useState("");
  const filterTest = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = foundLst.filter((user) => {
        return user.test.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundLst(results);
    } else {
      setFoundLst(lstTest);
      // If the text field is empty, show all users
    }

    setTst(keyword);
  };

  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th style={{}}>
              <input
                type="text"
                onChange={filterWing}
                className="form-control inp-search"
                id="wing"
                placeholder="Wing"
              />
            </th>
            <th style={{}}>
              <input
                type="text"
                onChange={filterCls}
                className="form-control inp-search"
                id="class"
                placeholder="Class"
              />
            </th>
            <th style={{}}>
              <input
                type="text"
                onChange={filterTest}
                className="form-control inp-search"
                id="class"
                placeholder="Test Name"
              />
            </th>
            <th>Mandatory</th>
            <th>Duration(mins)</th>
            <th className="text-left">Created On</th>
            <th className="text-left">Updated On</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {foundLst.map((test, idx) => {
              return (
                <tr
                  onClick={(e) => handleSelTestId(test.testId)}
                  className={test.testId === selTestId ? "selected" : ""}
                  key={idx}
                >
                  <td className="text-left">{test.wing}</td>
                  <td className="text-left">{test.cls}</td>
                  <td className="text-left">{test.test}</td>
                  <td>{test.mandatory ? "Yes" : "-"}</td>
                  <td>{test.duration}</td>
                  <td className="text-left">{test.createdOn}</td>
                  <td className="text-left">{test.updatedOn}</td>
                  <td className="text-center">
                    <span
                      className="glyphicon glyphicon-edit"
                      onClick={(e) => editTest(test.testId)}
                    ></span>
                    <span
                      className="glyphicon glyphicon-trash"
                      onClick={(e) => deleteTest(test.testId)}
                    ></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestConfigTable;
