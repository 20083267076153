import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'jquery-ui';
import 'common/css/master.css';
import '../css/dashboard.css';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

let httpURL = '';
let queryString = require('querystring');
let tokenid = localStorage.getItem('tokenid');
let lstenquiryData = [];
let lstAdmissionTestData = [];
let lstAdmissionData = [];
let lstConversionData = [];
let weekLabelsEnquiry = [];
let weekLabelsAdmission = [];
let weekLabelsAdmissionTest = [];
let screenId = 30;
let wingId = 1;
weekLabelsEnquiry = ['week-1', 'week-2', 'week-3', 'week-4', 'week-5',];
let classLabels = ['class-1', 'class-2', 'class-3', 'class-4', 'class-5',];
let enquirySeriesData = [];
let admissionTestSeriesData = [];
let admissionSeriesData = [];
let conversionSeriesData = [];


class Dashborad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {},
            series: [],
            conversionOptions: {},
            conversionSeries: [],
            enquiryOptions: {},
            enquirySeries: [],
            admissionTestOptions: {},
            admissionTestSeries: [],
            admissionOptions: {},
            admissionSeries: [],
            tokenid: tokenid,
            enquirySeriesData: [],
            seriesData: [],
        };
    }

    componentDidMount = () => {
        this.getEnuiryGraphData();
        //this.getAdmissionTestGraphData();
        this.getAdmissionGraphData();
        this.getBarGraph();
    }

    getEnuiryGraphData = async () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        await axios.post(httpURL + '/sms/dashboard/class_wise/enquiry',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                weekLabelsEnquiry = response.data.lstWeekLabels;
                lstenquiryData = response.data.lstData;
                for (let i = 0; i < lstenquiryData.length; i++) {
                    let obj = lstenquiryData[i];
                    let dataObj = { 'name': obj.className, 'type': 'line', 'data': obj.lstEnquiry }
                    enquirySeriesData.push(dataObj);
                }
                // console .log(enquirySeriesData);
                this.setState({
                    enquirySeries: enquirySeriesData,
                    enquiryOptions: {
                        chart: {
                            height: 500,
                            type: 'line',
                            toolbar: {
                                show: false,
                            }
                        },
                        stroke: {
                            width: [3, 3, 3, 3, 3],
                            show: true,
                            dashArray: 0,
                            lineCap: 'butt',
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'right',
                            horizontalAlign: 'left',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            markers: {
                                width: 25,
                                height: 12,
                                strokeWidth: 0,
                                strokeColor: '#fff',
                                radius: 12,
                                offsetX: 0,
                                offsetY: 0
                            },
                            itemMargin: {
                                horizontal: 5,
                                vertical: 5
                            },
                        },
                        markers: {
                            size: [4, 4, 4, 4, 4],
                            width: 12,
                            height: 12,
                        },
                        title: {
                            text: 'Class wise Enquiry',
                            align: 'center',
                        },
                        dataLabels: {
                            enabled: false,
                            enabledOnSeries: [0],
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },


                        xaxis: {
                            categories: weekLabelsEnquiry,
                            labels: {
                                offsetX: 6,
                                rotate: -45,
                                show: true,
                            },
                            axisTicks: {
                                show: true,
                            },
                            title: {
                                text: 'Weeks',
                                offsetX: 0,
                                offsetY: -40,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },

                            axisBorder: {
                                show: true,
                                color: '#000'
                            },

                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                },
                                // formatter: function (val) {
                                //     return val.toFixed(0);
                                // }
                            },
                            title: {
                                text: 'Enquiry',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }]
                    },
                })
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg)
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });


    }

    getAdmissionTestGraphData = async () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        await axios.post(httpURL + '/sms/dashboard/class_wise/admission_test',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                weekLabelsAdmissionTest = response.data.lstWeekLabels;
                lstAdmissionTestData = response.data.lstData;

                for (let i = 0; i < lstAdmissionTestData.length; i++) {
                    let obj = lstAdmissionTestData[i];
                    let dataObj = { 'name': obj.className, 'type': 'line', 'data': obj.lstAdmissionTest }
                    admissionTestSeriesData.push(dataObj);
                }
                // console .log(admissionTestSeriesData);
                this.setState({
                    admissionTestSeries: admissionTestSeriesData,
                    admissionTestOptions: {
                        chart: {
                            height: 500,
                            type: 'line',
                            toolbar: {
                                show: false,
                            }
                        },
                        stroke: {
                            width: [3, 3, 3, 3, 3],
                            show: true,
                            dashArray: 0,
                            lineCap: 'butt',
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'right',
                            horizontalAlign: 'left',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            markers: {
                                width: 25,
                                height: 12,
                                strokeWidth: 0,
                                strokeColor: '#fff',
                                radius: 12,
                                offsetX: 0,
                                offsetY: 0
                            },
                            itemMargin: {
                                horizontal: 5,
                                vertical: 5
                            },
                        },
                        markers: {
                            size: [4, 4, 4, 4, 4],
                            width: 12,
                            height: 12,
                        },
                        title: {
                            text: 'Class wise Admission Test',
                            align: 'center',
                        },
                        dataLabels: {
                            enabled: false,
                            enabledOnSeries: [0],
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },


                        xaxis: {
                            categories: weekLabelsAdmissionTest,
                            labels: {
                                offsetX: 6,
                                rotate: -45,
                                show: true,
                            },
                            axisTicks: {
                                show: true,
                            },
                            title: {
                                text: 'Weeks',
                                offsetX: 0,
                                offsetY: -40,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000'
                            },
                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                },
                                // formatter: function (val) {
                                //     return val.toFixed(0);
                                // }
                            },
                            title: {
                                text: 'Admission Test',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }]
                    },
                })
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    getAdmissionGraphData = async () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        await axios.post(httpURL + '/sms/dashboard/class_wise/admission',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                weekLabelsAdmission = response.data.lstWeekLabels;
                lstAdmissionData = response.data.lstData;
                for (let i = 0; i < lstAdmissionData.length; i++) {
                    let obj = lstAdmissionData[i];
                    let dataObj = { 'name': obj.className, 'type': 'line', 'data': obj.lstAdmission }
                    admissionSeriesData.push(dataObj);
                }
                // console .log(admissionSeriesData);
                this.setState({
                    admissionSeries: admissionSeriesData,
                    admissionOptions: {
                        chart: {
                            height: 500,
                            type: 'line',
                            toolbar: {
                                show: false,
                            }
                        },
                        stroke: {
                            width: [3, 3, 3, 3, 3],
                            show: true,
                            dashArray: 0,
                            lineCap: 'butt',
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'right',
                            horizontalAlign: 'left',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            markers: {
                                width: 25,
                                height: 12,
                                strokeWidth: 0,
                                strokeColor: '#fff',
                                radius: 12,
                                offsetX: 0,
                                offsetY: 0
                            },
                            itemMargin: {
                                horizontal: 5,
                                vertical: 5
                            },
                        },
                        markers: {
                            size: [4, 4, 4, 4, 4],
                            width: 12,
                            height: 12,
                        },
                        title: {
                            text: 'Class wise Admission',
                            align: 'center',
                        },
                        dataLabels: {
                            enabled: false,
                            enabledOnSeries: [0],
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },
                        xaxis: {
                            categories: weekLabelsAdmission,
                            labels: {
                                offsetX: 6,
                                rotate: -45,
                                show: true,
                            },
                            axisTicks: {
                                show: true,
                            },
                            title: {
                                text: 'Weeks',
                                offsetX: 0,
                                offsetY: -40,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000'
                            },
                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                },
                                // formatter: function (val) {
                                //     return val.toFixed(0);
                                // }
                            },
                            title: {
                                text: 'Admission',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }]
                    },
                })
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getBarGraph = async () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        await axios.post(httpURL + '/sms/dashboard/class_wise/conversion',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                classLabels = response.data.lstClassLabels;
                lstConversionData = response.data.lstConversionData;
                for (let i = 0; i < lstConversionData.length; i++) {
                    let obj = lstConversionData[i];
                    var dataObj = {
                        'name': obj.processName
                        , 'type': 'column'
                        , 'data': obj.conversionData
                    }
                    conversionSeriesData.push(dataObj);
                }
                // console .log(conversionSeriesData);

                this.setState({
                    conversionSeries: conversionSeriesData,
                    conversionOptions: {
                        chart: {
                            height: 500,
                            type: 'column',
                            toolbar: {
                                show: false,
                            }
                        },
                        stroke: {
                            width: [3, 3, 3, 3],
                            show: true,
                            dashArray: 0,
                            lineCap: 'butt',
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'right',
                            horizontalAlign: 'left',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            markers: {
                                width: 25,
                                height: 12,
                                strokeWidth: 0,
                                strokeColor: '#fff',
                                radius: 12,
                                offsetX: 0,
                                offsetY: 0
                            },
                            itemMargin: {
                                horizontal: 5,
                                vertical: 5
                            },
                        },
                        markers: {
                            size: [4, 4, 4, 4, 4],
                            width: 12,
                            height: 12,
                        },
                        title: {
                            text: 'Class wise Conversion',
                            align: 'center',
                        },
                        dataLabels: {
                            enabled: false,
                            enabledOnSeries: [0],
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },


                        xaxis: {
                            categories: classLabels,
                            labels: {
                                offsetX: 6,
                                rotate: -45,
                                show: true,
                            },
                            axisTicks: {
                                show: true,
                            },
                            title: {
                                text: 'Classes',
                                offsetX: 0,
                                offsetY: -40,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000'
                            },
                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                },
                                // formatter: function (val) {
                                //     return val.toFixed(0);
                                // }
                            },
                            title: {
                                text: 'Conversion',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }]
                    },
                })
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }

    render() {
        httpURL = lstModules['components'].url + ":" + lstModules['components'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer timeTableWrapper" style={{ opacity: this.state.opacity }}>
                    <div className="Dashboard graphWrapper clearfix" style={{ opacity: this.state.opacity }}>
                        <div className="col-md-4 col-xs-6 graphSection">
                            <ReactApexChart options={this.state.enquiryOptions} series={this.state.enquirySeries} type="line" />
                        </div>
                        <div className="col-md-4 col-xs-6 graphSection">
                            <ReactApexChart options={this.state.admissionTestOptions} series={this.state.admissionTestSeries} type="line" />
                        </div>
                        <div className="col-md-4 col-xs-6 graphSection">
                            <ReactApexChart options={this.state.admissionOptions} series={this.state.admissionSeries} type="line" />
                        </div>
                        <div className="col-md-4 col-xs-6 graphSection">
                            <ReactApexChart
                                options={this.state.conversionOptions}
                                series={this.state.conversionSeries}
                                type="bar"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Dashborad;