import React from 'react'
import '../css/EnquiryCtrl.css'
import TestTable from './TestTable.js';
import SlotTable from './SlotTable.js';
import EvalList from './EvalList';
import { useState } from 'react';

function SchTestSlider(props) {
  const [loader, setLoader] = useState('none')
  const [lstTestId, setLstTestId] = useState([])
  const [lstTestSlotId, setLstTestSlotId] = useState([])
  const [randNoSlotTbl, setRandNoSlotTbl] = useState(0);
  const [randNoEvalList, setRandNoEvalList] = useState(0);
  // const [selectedSlot,setSelectedSlot]=useState()

  function setSelectedTestId(param) {
    // console .log("setSelectedTestId triggered in app.js   " + param)
    setLstTestId(param);
    setRandNoSlotTbl(generateRandomNo);
  }

  function setSelectedTestSlotId(param) {
    // console .log("setSelectedTestSlotId triggered in app.js   " + param)
    setLstTestSlotId(param);
    setRandNoEvalList(generateRandomNo);
  }

  // function getSelectedSlot(param)
  // {
  //   setSelectedSlot(param)
  // }
  function generateRandomNo() {
    return Math.floor(Math.random() * 999999);
  }

  return (
    <div>
      <div className="col-sm-12" style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
          <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <div className="col-sm-8">
          <TestTable
            setSelectedTestId={setSelectedTestId}
            setSelectedTestSlotId={setSelectedTestSlotId}
            enquiryId={props.enquiryId}
            clsId={props.clsId} >
            Test-Table
          </TestTable>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <SlotTable
              lstTestId={lstTestId}
              key={'rnst_' + randNoSlotTbl}
              enquiryId={props.enquiryId}
              closeSlider={props.closeSlider}
              flgSliderType={props.flgSliderType}
              clsId={props.clsId}>
              Slot-Table
            </SlotTable>
            <EvalList
              key={'rnel_' + randNoEvalList}
              lstTestSlotId={lstTestSlotId}
              closeSlider={props.closeSlider}
            >
              Assign Evaluator
            </EvalList>
          </div>
        </div>
      </div>


    </div>
  );
}

export default SchTestSlider;