import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import $ from 'jquery';
import 'jquery-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from 'common/js/header/header.js';
import 'common/css/master.css';
import '../css/reportcardtemplate.css';
import 'common/bootstrap/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';



var httpURL = "";
var queryString = require('querystring');
var schoolId = 1;
var lstClass = [];
var lstSubject = [];
var lstAreas = [];
var lstLabels = [];
var wingId = 1;
const params = new URLSearchParams(window.location.search);
var tokenid = localStorage.getItem("tokenid");
class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstAreas: lstAreas,
            lstLabels: lstLabels,
            flg: true,
            width: '97%',
            loader: 'none',
            opacity: 1,
            tokenid: tokenid
        }
    }
    async componentWillMount() {
        // console .log("componentWillMount() called:"+localStorage.studentId);

        await this.setState({
            termId: parseInt(localStorage.getItem("termId")),
            term: localStorage.getItem("term"),
            flg: localStorage.getItem("flg"),
        });

    }
    componentDidMount = () => {
        this.handleResize();
        window.addEventListener("load", this.handleResize);
        window.addEventListener("resize", this.handleResize);
        this.getMasterData();
        this.getMstClassList(schoolId);
        this.getReportHeader();
    }

    handleResize = () => {
        var width = ($('.tableLayout1').parent().width()) - 18;
        this.setState({ width: width });
        var width = ($('.tableLayout2').parent().width());
        this.setState({ width2: width });
        var width = ($('.tableLayout3').parent().width());
        this.setState({ width3: width });
        var width = ($('.tableLayout4').parent().width());
        this.setState({ width4: width });
    }

    getMasterData = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        this.getAreasList(this.state.termId);
    }

    getMstClassList = (schoolId) => {
        axios({
            url: httpURL + '/sms/mst/class/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstClass: response.data.lstClass,
                })
                lstClass = this.state.lstClass;
                this.fillClassCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    fillClassCmb() {
        $('#inp_class').empty();
        $('#inp_class').prepend($("<option> </option>").val('Class').html('Class'));

        for (var i = 0; i < lstClass.length; i++) {
            // console .log(lstClass[i].classLabel);
            var clsId = lstClass[i].classId;
            var clsNm = lstClass[i].className;
            $('#inp_class').append($("<option> </option>").val(clsId).html(clsNm));
        }
        $('#inp_class').val(4);
        this.changeClass();
    }

    changeClass = () => {
        var clsId = $('#inp_class').find("option:selected").val();
        this.getScholasticArea(clsId);
        this.getCoScholasticArea(clsId);
        this.getDisciplineArea(clsId);
        this.setDateToday();
        this.getPrincipalSign();
        this.getClassTeacherSign(clsId);
    }

    getScholasticArea = (classId) => {
        axios({
            url: httpURL + '/sms/performance/subject/scholastic/list',
            method: "POST",
            data: queryString.stringify({ 'classId': classId, 'termId': this.state.termId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstSubject = response.data.lstReportCardScholasticSubjects;
                var scholasticSummary = response.data.scholasticSummary;
                this.setScholasticAreaBody(lstSubject);
                this.setScholasticAreaSummary(scholasticSummary);
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }

    getCoScholasticArea = (classId) => {
        axios({
            url: httpURL + '/sms/performance/subject/co_scholastic/list',
            method: "POST",
            data: queryString.stringify({ 'classId': classId, 'termId': this.state.termId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var lstCoSubject = response.data.lstReportCardCoscholasticSubjects;
                this.setCoScholasticAreaBody(lstCoSubject);
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }

    getDisciplineArea = (classId) => {
        axios({
            url: httpURL + '/sms/performance/subject/discipline/list',
            method: "POST",
            data: queryString.stringify({ 'classId': classId, 'termId': this.state.termId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var lstDisSubject = response.data.lstReportCardDisciplineSubjects;
                this.setDisciplineAreaBody(lstDisSubject);
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }

    setDateToday = () => {
        var dt = new Date();
        var dd = dt.getDate();
        var mm = dt.getMonth();
        var yyyy = dt.getFullYear();
        $('#val_dt').text(dd + '/' + mm + '/' + yyyy);
    }

    getPrincipalSign = () => {

        var xhr = new XMLHttpRequest();
        xhr.open("GET", httpURL + '/sms/performance/report_card/sign_principal/get', true);
        xhr.setRequestHeader('tokenid', this.state.tokenid);

        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            var img = document.querySelector("#img_sign_principal");
            img.src = imageUrl;
        };

        xhr.send();


    }
    getClassTeacherSign = (clsId) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", httpURL + '/sms/performance/report_card/sign_class_teacher/get?classId=' + clsId, true);
        xhr.setRequestHeader('tokenid', this.state.tokenid);

        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            var img = document.querySelector("#img_sign_classteacher");
            img.src = imageUrl;
        };

        xhr.send();
    }
    getAreasList = (termId) => {

        axios({
            url: httpURL + '/sms/performance/term_area/list',
            method: "POST",
            data: queryString.stringify({ 'termId': parseInt(termId) }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstAreas: response.data.lstTermAreas,
                })
                lstAreas = this.state.lstAreas;
                this.setAreasTable(lstAreas);
                this.setScholasticAreaHeader(lstAreas);
                this.getScholasticAreaFooter();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    async setAreasTable(lstAreas) {
        $('#areaTableBody tr').remove();
        var tbody = document.getElementById('areaTableBody');
        lstAreas = lstAreas.sort(this.dynamicsort("areaOrder", "asc"));
        for (var i = 0; i < lstAreas.length; i++) {
            var obj = lstAreas[i];
            var areaId = obj.areaId;
            var area = obj.area;
            var maxScore = obj.maxScore;
            var passScore = obj.passScore;
            var areaOrder = obj.areaOrder;

            var row = document.createElement('tr');
            row.id = areaId;
            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');

                this.setState({
                    flg: false,
                });

            }
            this.addTD(row, areaId, 'no-display');
            this.addTD(row, area, '');
            this.addTD(row, maxScore, '');
            this.addTD(row, passScore, '');
            this.addAreaTDActions(row, 'text-center');
            tbody.append(row);
        }

    }

    dynamicsort(property, order) {
        var sort_order = 1;
        if (order === "desc") {
            sort_order = -1;
        }
        return function (a, b) {
            if (a[property] < b[property]) {
                return -1 * sort_order;
            } else if (a[property] > b[property]) {
                return 1 * sort_order;
            } else {
                return 0 * sort_order;
            }
        }
    }
    addAreaTDActions(row, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var span1 = document.createElement("span");
        span1.className = "glyphicon glyphicon-arrow-up";
        $(span1).css('padding-right', 2);

        span1.addEventListener('click', (e) => this.changeAreaOrder(e, true));
        var span2 = document.createElement("span");
        span2.className = "glyphicon glyphicon-arrow-down";
        span2.addEventListener('click', (e) => this.changeAreaOrder(e, false));

        var img1 = document.createElement("img");
        img1.src = require("common/images/edit.png");
        $(img1).css('padding-left', 2);
        img1.width = 15;
        img1.addEventListener('click', (e) => this.setAreaPopup(e));

        var img2 = document.createElement("img");
        img2.src = require("common/images/bin.png");
        $(img2).css('padding-left', 2);
        img2.width = 15;
        img2.addEventListener('click', (e) => this.deleteArea(e));

        cell.append(span1);
        cell.append(span2);
        cell.append(img1);
        cell.append(img2);
        row.append(cell);
    }

    changeAreaOrder = (e, up) => {
        e.stopPropagation();
        var termId = this.state.termId;
        this.setState({ loader: 'none', opacity: '0.1' });
        var lstAreaId = [];
        $('#areaTableBody tr').find('td:eq(0)').each(function () {
            lstAreaId.push(parseInt($(this).text()));
        });
        var areaId = parseInt($(e.currentTarget).closest('tr').find('td:eq(0)').text());
        // console .log("e.areaId = " + areaId);
        if (up) {
            // console .log("up");
            for (var i = 0; i < lstAreaId.length; i++) {
                if (areaId == lstAreaId[i] && i != 0) {
                    var val = lstAreaId[i - 1];
                    lstAreaId[i - 1] = areaId;
                    lstAreaId[i] = val;
                    break;
                }
            }
        } else {
            // console .log("down");
            for (var i = 0; i < lstAreaId.length; i++) {
                if (areaId == lstAreaId[i] && i != lstAreaId.length - 1) {
                    var val = lstAreaId[i + 1];
                    lstAreaId[i + 1] = areaId;
                    lstAreaId[i] = val;
                    break;
                }
            }
        }
        axios.post(httpURL + '/sms/performance/term_area/change_order',
            queryString.stringify({ 'lstTermAreaId': JSON.stringify(lstAreaId) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 0.5 });
                this.getAreasList(termId);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    deleteArea = (event) => {
        event.stopPropagation();
        var termId = localStorage.getItem("termId");
        var areaId = $(event.target).closest('tr').find("td:eq(0)").text();

        axios.post(httpURL + '/sms/performance/term_area/delete',
            queryString.stringify({ 'termAreaId': areaId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getAreasList(termId);

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not logged in') {
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addCell(row, val, width) {
        var cell = document.createElement('td');
        cell.style.width = width;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    highlightRow(table, id) {
        $('#' + table + ' tr').each(function () {
            var termId = $(this).find("td:eq(0)").text();
            if (termId === id) {
                $(this).addClass('selected').siblings().removeClass('selected');
            }
        });
    }


    setAreaPopup = (event) => {
        $(event.target).closest('tr').click();
        this.setState({
            selArea: $(event.target).closest('tr').find("td:eq(0)").text()
        });

        var selArea = this.state.selArea;
        // console .log("selArea: " + selArea);
        this.setAreaData(selArea);

        this.openAreaPopup();
    }


    getAreaData = (pAreaId) => {
        var lstArea = this.state.lstAreas;
        for (var i = 0; i < lstArea.length; i++) {
            var obj = lstArea[i];
            if (pAreaId == obj.areaId) {
                return obj;
            }
        }
    }
    setAreaData(pAreaId) {
        var obj = this.getAreaData(pAreaId);
        // console .log(obj);
        $('#inp_area').val(obj.areaId);
        $('#inp_maxmarks').val(obj.maxScore);
        $('#inp_passmarks').val(obj.passScore);
        $('#inp_order').val(obj.areaOrder);

    }

    openAreaPopup = () => {
        $('.cd-panel').addClass('is-visible');
        $('#areaSlider').show();
        $('#areaSlider').siblings('#areaSlider').hide();
        $('.mainContainer').addClass('addOpacity');
        $('.areaPopup').css("display", 'block');
    }


    onClickAddAreaIcon = () => {
        this.openAreaPopup();
        alert("termId = " + this.state.termId);
        $('body').addClass('showPopup');
    }

    hideAreaSlider(e) {
        e.preventDefault();
        $('.cd-panel').removeClass('is-visible');
        $('.mainContainer').removeClass('addOpacity');
        $('.areaPopup').hide();
        $('body').removeClass('showPopup');
    }
    maintainArea = () => {
        var areaId = parseInt($('#areaTableBody tr.selected').find('td:eq(0)').text());
        if (areaId == 0 || isNaN(areaId)) {
            this.addArea();
        } else {
            this.editArea();
        }
    }

    addArea = () => {
        var area = {};
        var areaId = 0;
        var area = $('#inp_area').val();
        var maxScore = parseInt($('#inp_maxmarks').val());
        var passScore = parseInt($('#inp_passmarks').val());

        var termArea = {
            'termId': this.state.termId,
            'areaId': areaId,
            'area': area,
            'maxScore': maxScore,
            'passScore': passScore,
            'areaOrder': 99,
            'wingId': wingId

        };
        axios.post(httpURL + '/sms/performance/term_area/save',
            queryString.stringify({ 'termArea': JSON.stringify(termArea) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 1 });
                this.getAreasList(this.state.termId);
                $('.cd-panel').removeClass('is-visible');
                $('.mainContainer').removeClass('addOpacity');
                $('.areaPopup').hide();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }

    editArea = () => {
        var area = {};
        var areaId = parseInt($('#areaTableBody tr.selected').find('td:eq(0)').text());
        var area = $('#inp_area').val();
        var maxScore = parseInt($('#inp_maxmarks').val());
        var passScore = parseInt($('#inp_passmarks').val());
        var areaOrder = parseInt($('#inp_order').val());

        var termArea = {
            'termId': this.state.termId,
            'areaId': areaId,
            'area': area,
            'maxScore': maxScore,
            'passScore': passScore,
            'areaOrder': areaOrder,
            'wingId': wingId

        };
        axios.post(httpURL + '/sms/performance/term_area/save',
            queryString.stringify({ 'termArea': JSON.stringify(termArea) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 0.5 });
                this.getAreasList(this.state.termId);
                $('.cd-panel').removeClass('is-visible');
                $('.mainContainer').removeClass('addOpacity');
                $('.areaPopup').hide();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    addAreaReady = () => {
        var areaId = parseInt($('#areaTableBody tr.selected').find('td:eq(0)').text());

        if (!this.state.flg) {
            localStorage.setItem("flg", this.state.flg);
            areaId = $('#areaTableBody tr.selected').attr('id');
        } else {
            localStorage.setItem("flg", true);
        }
        this.onClickAddAreaIcon();
    }

    getReportHeader = () => {
        this.getReportHeaderLeftImage();
        this.getReportHeaderCenterImage();
        this.getReportHeaderRightImage();
        this.getLabelList();//sms/performance/label/list

    }
    getReportHeaderLeftImage = () => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", httpURL + '/sms/performance/report_card/left_image/get?wingId=' + wingId + '&tokenid=' + this.state.tokenid, true);
        xhr.setRequestHeader('tokenid', this.state.tokenid);

        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            var img = document.querySelector("#img_left");
            img.src = imageUrl;
        };

        xhr.send();

    }

    getReportHeaderCenterImage = () => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", httpURL + '/sms/performance/report_card/center_image/get?wingId=' + wingId + '&tokenId=' + this.state.tokenid, true);
        xhr.setRequestHeader('tokenid', this.state.tokenid);

        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            var img = document.querySelector("#img_center");
            img.src = imageUrl;
        };

        xhr.send();
    }

    getReportHeaderRightImage = () => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", httpURL + '/sms/performance/report_card/right_image/get?wingId=' + wingId + '&tokenId=' + this.state.tokenid, true);
        xhr.setRequestHeader('tokenid', this.state.tokenid);

        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            var img = document.querySelector("#img_right");
            img.src = imageUrl;
        };

        xhr.send();
    }
    setScholasticAreaHeader = (lstAreas) => {
        this.setTermLabel();

        var termHeader = document.getElementById('lbl_term');
        termHeader.setAttribute('colSpan', lstAreas.length + 2);
        var row = document.getElementById('tr_sublist');
        $(row).empty();

        this.createHeadCell(row, 'Subjects', 2, 'auto');

        for (var i = 0; i < lstAreas.length; i++) {
            var obj = lstAreas[i];
            var cell = document.createElement('th');
            cell.style.width = ($('.tableLayout2').parent().width() / (lstAreas.length - 3)) + 'px';
            var text = document.createTextNode(obj.area);
            cell.append(text);
            row.append(cell);
        }
        var rowMain = document.getElementById('tr_main');

        this.createHeadCell(row, 'Total', 1, 'auto');
        this.createHeadCell(row, 'Grade', 2, 'auto');

        var rowMaxScore = document.createElement('tr');
        var tot = 0;
        for (var x = 0; x < lstAreas.length; x++) {
            var objA = lstAreas[x];
            var cell = document.createElement('th');
            var newWidth = (parseInt($('.tableLayout2').parent().width()) / (lstAreas.length - 3)) + 'px';
            // console .log('newWidth = ' + newWidth);
            tot += objA.maxScore;
            cell.style.width = newWidth;
            var text = document.createTextNode(objA.maxScore);
            cell.append(text);
            rowMaxScore.append(cell);
        }
        var cell = document.createElement('th');
        var newWidth = (parseInt($('.tableLayout2').parent().width()) / (lstAreas.length - 3)) + 'px';
        cell.style.width = newWidth;
        var text = document.createTextNode(tot);
        cell.append(text);
        rowMaxScore.append(cell);
        $(row).after(rowMaxScore);
        $('#lbl_term').append(' (' + tot + ' Marks)');

    }

    getScholasticAreaFooter = () => {
        axios({
            url: httpURL + '/sms/performance/footer/scholastic',
            method: "POST",
            data: queryString.stringify({ 'wingId': wingId, 'termId': this.state.termId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var footerLabel = response.data.footerLabel;
                var footerValue = response.data.footerValue;
                this.setScholasticAreaFooter(footerLabel, footerValue);

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }

    setScholasticAreaFooter = (footerLabel, footerValue) => {
        // console .log('fl:' + footerLabel);

        $('#lbl_footer').text(footerLabel);
        $('#val_footer').text(footerValue);
    }

    createHeadCell(row, val, spanVal, width) {
        var cell = document.createElement('th');
        cell.style.width = width;
        cell.setAttribute('rowSpan', spanVal);
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    setScholasticAreaBody = (lstSubject) => {

        $('#type1BodyTable tr').remove();
        var subjectwidth = $('#tr_sublist').find("th:eq(0)").outerWidth();
        var areacolwidth = $('#tr_sublist').find("th:eq(1)").outerWidth();
        var lastColWidth = $('#tr_sublist').find("th:nth-last-child(1)").outerWidth();
        // console .log("subjectwidth=" + subjectwidth);
        // console .log("lastColWidth=" + lastColWidth);
        var scholasticAreaTbody = document.getElementById('type1BodyTable');
        for (var i = 0; i < lstSubject.length; i++) {

            var obj = lstSubject[i];
            var row = document.createElement('tr');
            this.addCell(row, obj.subject, subjectwidth + 'px');
            var lstScore = obj.lstAreaScore;

            for (var j = 0; j < lstScore.length; j++) {
                var score = lstScore[j];
                this.addCell(row, score, areacolwidth + 'px');
            }
            this.addCell(row, obj.total, areacolwidth + 'px');
            this.addCell(row, obj.grade, lastColWidth + 'px');
            scholasticAreaTbody.append(row);
        }


    }
    setScholasticAreaSummary = (scholasticSummary) => {
        $('#val_marks').text(scholasticSummary.overallMarks);
        $('#val_pct').text(scholasticSummary.percentage);
        $('#val_grade').text(scholasticSummary.grade);
    }
    setCoScholasticAreaBody = (lstCoSubject) => {
        $('#type2BodyTable tr').remove();
        var cosubjectwidth = $('#tr_co_sublist').find("th:eq(0)").outerWidth();
        var colastColWidth = $('#tr_co_sublist').find("th:eq(1)").outerWidth();
        var coScholasticAreaTbody = document.getElementById('type2BodyTable');
        for (var i = 0; i < lstCoSubject.length; i++) {

            for (var i = 0; i < lstCoSubject.length; i++) {

                var obj = lstCoSubject[i];
                var row = document.createElement('tr');
                this.addCell(row, obj.subject, cosubjectwidth + 'px');
                this.addCell(row, obj.grade, colastColWidth + 'px');
                coScholasticAreaTbody.append(row);
            }
        }
    }
    setDisciplineAreaBody = (lstDisSubject) => {
        $('#type3BodyTable tr').remove();
        var dissubjectwidth = $('#tr_dis_sublist').find("th:eq(0)").outerWidth();
        var dislastColWidth = $('#tr_dis_sublist').find("th:eq(1)").outerWidth();
        var disScholasticAreaTbody = document.getElementById('type3BodyTable');
        for (var i = 0; i < lstDisSubject.length; i++) {
            var obj = lstDisSubject[i];
            var row = document.createElement('tr');
            this.addCell(row, obj.subject, dissubjectwidth + 'px');
            this.addCell(row, obj.grade, dislastColWidth + 'px');
            disScholasticAreaTbody.append(row);
        }
    }
    getLabelList = () => {
        axios({
            url: httpURL + '/sms/performance/label/list',
            method: "POST",
            data: queryString.stringify({ 'wingId': wingId, }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstLabels: response.data.lstLabels,
                })
                lstLabels = this.state.lstLabels;

                // console .log(lstLabels);
                this.setLabelList(lstLabels);

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    setLabelList = (lstLabels) => {
        for (var i = 0; i < lstLabels.length; i++) {
            var obj = lstLabels[i];
            $('#lbl_' + obj.labelCode).text(obj.label);
            $('#val_' + obj.labelCode).text(obj.sampleValue);

        }
    }

    setTermLabel = () => {
        $('#lbl_term').text(this.state.term);
        $('#val_academic_session').text(this.state.term);
        $('#co_lbl_term').html(this.state.term);
        $('#dis_lbl_term').html(this.state.term);
    }
    render() {
        return (
            <div className="col-sm-7" id="reportcard_printpreview" style={{}}>
                <div className="col-centered clearfix">
                    <div className="row col-sm-12 col-centered" style={{}}>
                        <div className="col-sm-3 padding-remove" >
                            <img id="img_left" alt="left-image" className="header_image" />
                        </div>
                        <div className="col-sm-6 padding-remove" >
                            <img id="img_center" alt="center-image" width={'100%'} className="header_image" />
                        </div>
                        <div className="col-sm-3 padding-remove" >
                            <img id="img_right" alt="right-image" className="header_image" />
                        </div>
                    </div>
                    <div className="row report_body">
                        <div className="form-group">
                            <div className="col-sm-12 text-center">
                                <h2><label className="control-label" >Report Card</label></h2>
                            </div>
                            <div className="col-sm-12 text-center">
                                <label className="control-label" >Class:
                                    <span className="report_header_label">I</span>
                                    (<span className="report_header_label">A</span>)
                                </label>
                            </div>
                            <div className="col-sm-12 text-center">
                                <label className="control-label" >Academic Session:
                                    <span className="report_header_label" >2019-2020</span>
                                    (<span className="report_header_label" id="val_academic_session">-</span>)
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-12 padding-remove">
                                <div className="col-sm-6 padding-remove">

                                    <label className="control-label col-sm-5" id="lbl_student_name">Student's Name</label>
                                    <div className="col-sm-7 input_val" >
                                        <label className="control-label lbl" id="val_student_name">Amit Kumar</label>
                                    </div>
                                </div>
                                <div className="col-sm-offset-2 col-sm-4">
                                    <label className="control-label col-sm-7" id="lbl_roll_no">Roll No.</label>
                                    <div className="col-sm-5 input_val">
                                        <label className="control-label lbl" id="val_roll_no">1</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-12 padding-remove">
                                <div className="col-sm-6 padding-remove">
                                    <label className="control-label col-sm-5" id="lbl_father_name">Father's Name</label>
                                    <div className="col-sm-7 input_val">
                                        <label className="control-label lbl" id="val_father_name">Mr. Ramesh Mehta</label>
                                    </div>
                                </div>
                                <div className="col-sm-offset-2 col-sm-4">
                                    <label className="control-label col-sm-7" id="lbl_admission_no">Admission No.</label>
                                    <div className="col-sm-5 input_val">
                                        <label className="control-label lbl" id="val_admission_no">1681</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-12 padding-remove">
                                <div className="col-sm-6 padding-remove">
                                    <label className="control-label col-sm-5 " id="lbl_mother_name">Mother's Name</label>
                                    <div className="col-sm-7 input_val">
                                        <label className="control-label lbl" id="val_mother_name">Mrs. Sheetal Rani</label>
                                    </div>
                                </div>
                                <div className="col-sm-offset-2 col-sm-4">
                                    <label className="control-label col-sm-7" id="lbl_attendance">Attendance.</label>
                                    <div className="col-sm-5 input_val">
                                        <label className="control-label lbl" id="val_attendance">16/81</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-12 padding-remove">
                                <div className="col-sm-6 padding-remove">
                                    <label className="control-label col-sm-5 " id="lbl_dob">Date of Birth</label>
                                    <div className="col-sm-7 input_val">
                                        <label className="control-label lbl" id="val_dob">11/11/2004</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group" style={{ marginTop: 0 }}>
                            <div className="col-sm-12">
                                <div className="col-sm-6 padding-remove">
                                    <label className="control-label col-sm-5" id="lbl_address">Address</label>
                                    <div className="col-sm-7 input_val">
                                        <label className="control-label lbl" id="val_address">#44, Model Town, let it go to next line as well</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-12 col-centered wrapperTable clearfix form-group">
                        <div className="col-md-12  col-xs-12  padding-remove">
                            <table className="tableLayout2" style={{ width: this.state.width2 }}>
                                <thead>
                                    <tr id="tr_main">
                                        <th style={{ display: 'none' }}> Id </th>
                                        <th>Scholastic Area </th>
                                        <th colSpan="6" id="lbl_term">Ist Term</th>

                                    </tr>
                                    <tr id="tr_sublist">

                                    </tr>
                                </thead>
                            </table>
                            <div className="type1TableDiv" style={{ height: 'auto' }} >
                                <table id="type1Table" className="tableLayout">

                                    <tbody id="type1BodyTable">

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="col-md-12  col-xs-12 padding-remove" id="scholastic_area_footer" >
                            <label className="control-label col-sm-3 text-right" id="lbl_footer"></label>
                            <div className="col-sm-9" >
                                <label className="control-label lbl" id="val_footer"></label>
                            </div>
                        </div>
                        <div className="col-md-12  col-xs-12 report_summary" id="scholastic_area_summary">
                            <div className="col-sm-3">
                                <label className="control-label col-sm-6 label_summary" id="lbl_marks">Marks</label>
                                <label className="col-sm-6 control-label val_summary" id="val_marks" ></label>
                            </div>
                            <div className="col-sm-3" style={{ width: '33%' }}>
                                <label className="control-label col-sm-7 label_summary" id="lbl_pct" >Percentage</label>
                                <label className="col-sm-5 control-label val_summary" id="val_pct" ></label>
                            </div>
                            <div className="col-sm-3">
                                <label className="control-label col-sm-6 label_summary" id="lbl_grade" >Grade</label>
                                <label className="col-sm-6 control-label val_summary" id="val_grade" ></label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12  col-xs-12  padding-remove">
                        <div className="col-sm-6">
                            <table className="tableLayout3" style={{ width: this.state.width3 }}>
                                <colgroup>
                                    <col width='70%' />
                                    <col width='30%' />
                                </colgroup>
                                <thead>
                                    <tr id="tr_co_main">
                                        <th style={{ display: 'none' }}> Id </th>
                                        <th colSpan="2">Co-Scholastic Area</th>

                                    </tr>
                                    <tr id="tr_co_sublist">
                                        <th id="co_lbl_term">Ist Term</th>
                                        <th >Grade</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="type2TableDiv" >
                                <table id="type2Table" className="tableLayout">

                                    <tbody id="type2BodyTable">

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <table className="tableLayout4" style={{ width: this.state.width4 }}>
                                <colgroup>
                                    <col width='70%' />
                                    <col width='30%' />
                                </colgroup>
                                <thead>
                                    <tr id="tr_dis_main">
                                        <th style={{ display: 'none' }}> Id </th>
                                        <th colSpan="2">Discipline</th>
                                    </tr>
                                    <tr id="tr_dis_sublist">
                                        <th id="dis_lbl_term">Ist Term</th>
                                        <th>Grade</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="type3TableDiv" >
                                <table id="type3Table" className="tableLayout">

                                    <tbody id="type3BodyTable">

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-12 footer_report" style={{ 'minHeight': '4em' }}>
                            <div className="col-sm-3 padding-remove valign-bottom">
                                <label className="control-label col-sm-5">Date:</label>
                                <label className="control-label col-sm-7 lbl" id="val_dt"></label>
                            </div>
                            <div className="col-sm-3 padding-remove" >
                                <img id="img_sign_principal" alt="center-image" className="footer_image" />
                                <label className="control-label col-sm-12" style={{ position: 'absolute', bottom: 0, left: 0 }}>Principal</label>
                            </div>
                            <div className="col-sm-3 padding-remove" >
                                <img id="img_sign_classteacher" alt="right-image" className="footer_image" />
                                <label className="control-label col-sm-12" style={{ position: 'absolute', bottom: 0, left: 0 }}>Class Teacher</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class ReportCardTemplate extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            lstAreas: lstAreas,
            lstLabels: lstLabels,
            flg: true,
            width: '97%',
            loader: 'none',
            opacity: 1,
            tokenid: tokenid
        }
    }
    async componentWillMount() {
        // console .log("componentWillMount() called:"+localStorage.studentId);

        await this.setState({
            // termId: parseInt(localStorage.getItem("termId")),
            // term: localStorage.getItem("term"),
            // flg: localStorage.getItem("flg"),
            termId : params.get("termId"),
            term : params.get("term"),
            flg : params.get("flg"),
        });

    }
    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    render() {
        httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port;
        return (
            <div>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>


                <div className="mainContainer" style={{ opacity: this.state.opacity }}>
                    <div className="container-fluid">
                        <div className="col-sm-12 col-centered">
                            <div className="col-sm-4 padding-remove" style={{ marginTop: 10 }}>
                                <table className="tableLayout1" style={{ width: this.state.width }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50, display: 'none' }}>Area Id</th>
                                            <th style={{ width: 100 }}>Area</th>
                                            <th style={{ width: 70 }}>Max. Marks</th>
                                            <th style={{ width: 70 }}>Pass. Marks</th>
                                            <th style={{ width: 70 }}>Action</th>

                                        </tr>
                                    </thead>
                                </table>
                                <div className="areaTableDiv col-sm-12 col-xs-11 padding-remove">
                                    <table id="areaTable" className="tableLayout">
                                        <colgroup>
                                            <col width="100" />
                                            <col width="70" />
                                            <col width="70" />
                                            <col width="70" />
                                        </colgroup>
                                        <tbody id="areaTableBody">
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-1 col-xs-1" style={{ width: '5%' }}>
                                <span id="addArea" title="Add Term" onClick={this.addAreaReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                            </div>
                            <div className="col-sm-7 select-class" >
                                <div className="col-sm-5 form-group">
                                    <label htmlFor="inp_class" className="control-label col-sm-6" style={{ margin: '0.6rem 0' }}>Class</label>
                                    <div className="col-sm-6 padding-remove">
                                        <select className="form-control" id="inp_class" onChange={this.changeClass}>
                                        </select>
                                    </div>
                                </div>
                                <ReactToPrint content={() => this.componentRef}>
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (<span onClick={handlePrint} className="valign-center col-sm-1 glyphicon glyphicon-print"></span>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>

                            </div>
                            <ComponentToPrint ref={e1 => (this.componentRef = e1)} />
                        </div>
                    </div>
                </div>
                <div className="areaPopup" style={{ width: '33%', left: '77%', height: '60%' }}>
                    <header style={{ width: '90%' }}>
                        <h3 id="classHeader">Add/Edit Area</h3>
                        <a href="#0" id="hideAreaSlider" className="cd-panel-close glyphicon glyphicon-remove" onClick={this.hideAreaSlider}>Close</a>
                    </header>

                    <div style={{ width: '90%' }}>
                        <div className="cd-panel-content" style={{ padding: '20px 0% 20px' }}>
                            <div id="AreaSlider" className="col-sm-12 area_slider" >
                                <div style={{ width: '90%', paddingLeft: 20 }}>
                                    <form id="areaForm" className="form-horizontal">
                                        <div className="col-sm-12 padding-remove" >
                                            <div className="col-sm-12" >
                                                <div className="form-group">
                                                    <label className="control-label col-sm-5">Area:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" placeholder="Area" id="inp_area" />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-5" id="">Max. Marks:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" id="inp_maxmarks" />
                                                    </div>
                                                </div>

                                                <div className="form-group hide">
                                                    <label className="control-label col-sm-5" id="">Order:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" id="inp_order" />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-5" id="">Pass. Marks:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" id="inp_passmarks" />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="form-group" style={{ marginTop: 50 }}>
                                            <div className="col-sm-11 text-right pull-right">
                                                <button type="button" onClick={this.maintainArea} className="btn btn-info">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}
export default ReportCardTemplate;