// react or other global imports
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

// project related common imports

import lstModules from "common/json/lstModules.json";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import LoaderImage from "common/js/LoaderImage.jsx";
import { getCounselorDtlsSvc } from "./counselorSetupSvc.js";
import "../css/counselorSetup.css";


const CounselorSetup = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        getCounselorDtlsSvc(cbEnqDtlsResponse)
    }, []);

    function cbEnqDtlsResponse(data) {

    }
    const getWingId = (wingId) => {
        // console .log('wingId = ' + wingId)
    }
    return (
        <div className='mainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className='col-sm-4 col-centered form-horizontal formWrapper' style={{ padding: '2%', background: 'white', border: '1px solid #ddd', borderRadius: '4px', height: '40vh', marginTop: '1%' }}>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>


                <div className="row">
                    <label className="col-sm-7 control-label text-left">Auto assign</label>
                    <div className="form-group">
                        <input type="checkbox" className="col-sm-1" defaultChecked />
                    </div>
                </div>
                <div className="row">
                    <label className="col-sm-7 control-label text-left">Enquiries in a lot</label>
                    <div className="form-group col-sm-2">
                        <input type="text" className="form-control text-right" />

                    </div>
                </div>

                <div className="row">
                    <label className="col-sm-7 control-label text-left">Max. allocation per counsellor</label>
                    <div className=" form-group col-sm-2">
                        <input type="text" className="form-control text-right" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-offset-7 col-sm-2">
                        <div className="form-group ">
                            <button type="button" className="btn btn-success">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default CounselorSetup;