import React, { useEffect, useState } from "react";

import '../css/studentTest.css';


const TestAvatarDescription = (props) => {

    const { avatar, avatarDesc } = props;

    useEffect(() => {
        // console .log("TestAvatarDescription called");
    }, []);

    return (

        <div className="col-sm-10 col-centered padding-remove ">
            <div className="col-sm-12 text-left padding-remove" style={{ borderBottom:"1px solid blue"}}>
                <label className="avatar-label" style={{  fontFamily: "Butterfly Kids" }}>
                    {avatar}
                </label>
            </div>
            <div>
            </div>
            <div className="col-sm-12 padding-remove">
                <div className="avatar-description" style={{ fontFamily: "Butterfly Kids" }}>
                    {avatarDesc}
                </div>
            </div>
        </div>
    );
}
export default TestAvatarDescription;