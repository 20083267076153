import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");


function getDatesheetListSvc(pExamId, callback) {
    var obj = { status: "Success", lstDatesheet: [] }
    axios({
        url: httpURL + '/sms/performance/datesheet/list',
        method: "POST",
        params: { examId: pExamId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;

        if (response.data.SvcStatus === "Success") {
            obj.lstDatesheet = response.data.lstDatesheet;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstDatesheet = [];
        callback(obj);
    });
}

function viewDatesheetBySectionSvc(sectionId, examId, callback) {
    axios(
        {
            url: httpURL + "/sms/performance/datesheet/view",
            method: "POST",
            params: { 'examId': examId, "sectionId": sectionId },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }
    ).then((response) => {

        callback(response);

    }
    ).catch((error) => {
        console.error(error)
    });
}

function generateDatesheetSvc(examId, lstSecId, cb) {
    var formData = new FormData();
    var generateObj = {};
    generateObj.examId = examId;
    generateObj.lstSectionId = lstSecId
    formData.append('generateObj', JSON.stringify(generateObj));

    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/performance/datesheet/generate",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console .log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}

function publishDatesheetSvc(examId, lstSecId, cb) {
    var formData = new FormData();
    var publishObj = {};
    publishObj.examId = examId;
    publishObj.lstSectionId = lstSecId
    formData.append('publishObj', JSON.stringify(publishObj));

    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/performance/datesheet/publish",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console .log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}

function viewCombinedDatesheetBySectionSvc(lstSecId, examId, callback) {
    var formData = new FormData();
    var viewObj = {};
    viewObj.examId = examId;
    viewObj.lstSectionId = lstSecId
    formData.append('viewObj', JSON.stringify(viewObj));
    /* 
        viewObj.append('examId', examId);
        viewObj.append('lstSectionId', JSON.stringify(lstSecId));
     */
    axios(
        {
            url: httpURL + "/sms/performance/datesheet/combined_view",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }
    ).then((response) => {

        callback(response);

    }
    ).catch((error) => {
        console.error(error)
    });
}

export { getDatesheetListSvc, viewDatesheetBySectionSvc, generateDatesheetSvc, publishDatesheetSvc, viewCombinedDatesheetBySectionSvc }