import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import "../css/studentLeaves.css";
import { leaveReplySvc } from "./studentLeavesSvc";

const RejectLeave = (props) => {

    const [leaveId, setLeaveId] = useState(props.leaveObj.leaveId);
    const [rejectionRemarks, setRejectionRemarks] = useState("");
    const [classSection, setClassSection] = useState("");

    useEffect(() => {
        // console .log("rejectLeavePopup called props.leaveObj", props.leaveObj);
        if(props.leaveObj.cls && props.leaveObj.section){
            setClassSection(props.leaveObj.cls + "-" + props.leaveObj.section);
        } else {
            setClassSection(props.leaveObj.cls);
        }
    },[props.leaveObj]);

    const rejectLeave = () => {
        // console .log("rejectLeave called")
        props.setLoader("block");
        props.setOpacity(0.5);
        leaveReplySvc(leaveId, "R", rejectionRemarks, rejectLeaveReponse)
    }

    const rejectLeaveReponse = (data) => {
        // console .log("rejectLeaveResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            // console .log("successful response in rejectLeaveReponse");
            props.closePopup();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", padding: 0, top: "20%", left: "40%", backgroundColor: "#F8F8F8", width: 350, height: 450, zIndex: 1001, border: "1px solid #ccc" }}>
            <div className="col-sm-12 padding-remove" >
                <div className="col-sm-12 padding-remove">
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Reject Leave Application
                    </div>
                    <div className="col-sm-4 padding-remove" >
                        <button type="button"
                            title="Close Popup"
                            onClick={() => props.closePopup()}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>
                <form className="form-group">
                    <div className="formRow col-sm-12 ">
                        <label className="control-label col-sm-4 padding-remove " >Student Name: </label>
                        <div className="col-sm-6 padding-remove">
                            <input autoComplete="off" type="text" className="form-control" value={props.leaveObj.student} disabled={true} />
                        </div>
                    </div>
                    <div className="formRow col-sm-12 ">
                        <label className="control-label col-sm-4  padding-remove" >Class: </label>
                        <div className="col-sm-6 padding-remove ">
                            <input autoComplete="off" type="text" className="form-control" value={classSection} disabled={true} />
                        </div>
                    </div>
                    <div className="formRow col-sm-12 ">
                        <label className="control-label col-sm-4 padding-remove " >Leave From: </label>
                        <div className="col-sm-6 padding-remove ">
                            <input autoComplete="off" type="text" className="form-control" value={props.leaveObj.fromDt} disabled={true} />
                        </div>
                    </div>
                    <div className="formRow col-sm-12 ">
                        <label className="control-label col-sm-4 padding-remove " >Leave To: </label>
                        <div className="col-sm-6 padding-remove ">
                            <input autoComplete="off" type="text" className="form-control" value={props.leaveObj.toDt} disabled={true} />
                        </div>
                    </div>
                    <div className="formRow col-sm-12 ">
                        <label className="control-label col-sm-4 padding-remove" >Student Remarks<span style={{ color: 'red', marginLeft: 2 }}>*</span>:</label>
                        <div className="col-sm-8 padding-remove ">
                            <textarea
                                className='form-control'
                                value={props.leaveObj.studentRemarks}
                                maxLength='500'
                                rows='3'
                                disabled={true}
                            ></textarea>
                        </div>
                    </div>
                    <div className="formRow col-sm-12">
                        <label className="control-label col-sm-4 padding-remove" > Remarks <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-12 padding-remove">
                            <textarea
                                className='form-control'
                                value={rejectionRemarks}
                                onChange={(e) => setRejectionRemarks(e.target.value)}
                                placeholder="Reason for rejecting leave application"
                                maxLength='500'
                                rows='3'
                            ></textarea>
                        </div>
                    </div>

                    <div className="col-sm-12 padding-remove" >
                        <div className=" col-md-12 form-group text-center multiButtonWrap1" >
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => props.closePopup()} className="btn btn-success">Cancel</button>
                            </div>
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => rejectLeave()} className="btn btn-success">Reject</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RejectLeave;
