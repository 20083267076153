import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 8;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['transport'].url + ":" + lstModules['transport'].port;
// var httpURLStudent = lstModules['students'].url + ":" + lstModules['students'].port;
// var httpURLStaff = lstModules['staff'].url + ":" + lstModules['staff'].port;

/* function getClassListSvc(callback1) {
    axios({
        url: httpURL + '/sms/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        callback1(response.data);
    }).catch((error) => {
        callback1(error);
    });
}
function getSectionListSvc(callback2, pClassId) {
    axios({
        url: httpURLStaff + "/sms/cls/sec/list",
        method: "POST",
        params: { screenId: 8, classId: pClassId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        callback2(response.data);
    }).catch((error) => {
        callback2(error);
    });
}
 */
function getAttendanceSvc(dt, routeId, flg, cb) {
    var formData = new FormData();
    formData.append('attendanceDt',dt);
    formData.append('routeId', routeId)
    formData.append('flgMorEve', flg)
    axios({
        url: httpURL + "/sms/tpt/attendance/student/list",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        cb(response.data);
    }).catch((error) => {
        cb(error);
    });
}
function saveAttendanceSvc(dt, routeId, flg, lstTmpAttendance, cb) {
    var obj = { status: "Success" }
    var formData = new FormData();
    var attendanceObj = {
        attendanceDt: dt
        , routeId: routeId
        ,flgMorEve:flg
        , lstStudent: lstTmpAttendance
    }
    formData.append("attendanceObj", JSON.stringify(attendanceObj));
    axios({
        url: httpURL + "/sms/tpt/attendance/student/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }).catch((error) => {
        // console .log("Error Occured")
        obj.status = 'Exception';
        obj.message = 'Service failed ' + error;
        cb(obj)
    });
}
function informSvc(dt, studentId, cb) {
    var obj = { status: "Success" }
    axios({
        url: httpURL + "/sms/tpt/attendance/student/inform",
        method: "POST",
        params: {studentObj:JSON.stringify({attendanceDt:dt, studentId:studentId })},
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }).catch((error) => {
        // console .log("Error Occured")
        obj.status = 'Exception';
        obj.message = 'Service failed ' + error;
        cb(obj)
    });
}

export { saveAttendanceSvc, informSvc }
export {  getAttendanceSvc };