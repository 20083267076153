import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import 'common/js/tabs.css'
import Header from 'common/js/header/header'
import DatePicker from 'react-datepicker'
import { toast, ToastContainer } from 'react-toastify'

import {
  getClassListSvc,
  getResultListSvc,
  saveResultSvc,
  deleteResultSvc,
  getResultListSvc1
} from './resultSvc'
var httpURL = lstModules['main'].url + ':' + lstModules['main'].port

export default function ResultCtrl () {
  const [state, setState] = useState({
    loader: 'none',
    opacity: 1,
    tokenid: localStorage.getItem('tokenid')
  })
  const [resultId, setResultId] = useState(0)
  const [tabId, setTabId] = useState(1)
  const [studentName, setStudentName] = useState('')
  const [percent, setPercent] = useState('')
  const [yr, setYr] = useState(null)
  const [clsId, setClsId] = useState('')
  // const [imgPath, setImgPath] = useState('')
  const [imgFile, setImgFile] = useState('')
  const [imgUrl, setImgUrl] = useState(window.appURL + '/no-image.png')
  const [lstResult, setLstResult] = useState([])
  const [flgBoard, setFlgBoard] = useState(true)
  const [lstCls, setLstCls] = useState([])

  useEffect(() => {
    getLstClass(flgBoard)
    getResultList(flgBoard)
  }, [])

  function getLstClass (pFlgBoard) {
    showLoader()
    getClassListSvc(pFlgBoard, cbClsResponseChange)
  }

  function cbClsResponseChange (data) {
    // console .log("data in cbClsResponseChange", data)
    setLstCls(data)
    hideLoader()
  }
  function getResultList (pFlgBoard) {
    showLoader()
    getResultListSvc(pFlgBoard, cbResultListResponse)
  }
  function cbResultListResponse (data) {
    // console .log("data in cbResultListResponse", data)
    if (data.status === 'Success') {
      setLstResult(data.lstResult)
    } else {
      if (data.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.SvcMsg)
      }
    }
    hideLoader()
  }
  const colgrpResult = (
    <colgroup>
      <col width='13%'></col>
      <col width='12%'></col>
      <col width='43%'></col>
      <col width='15%'></col>
      <col width='17%'></col>
    </colgroup>
  )

  function setResultDtls (obj) {
    // setImgPath('banner/no-image.png')
    // console .log('obj in setResultDtls ', obj);
    setResultId(obj.resultId)
    setStudentName(obj.studentName)
    setClsId(obj.clsId)
    setYr(obj.yr)
    setPercent(obj.percent)
    // setImgPath(obj.imgPath)

    if (obj.imgPath === '-') {
      // viewResultImgSvc('banner/no-image.png', obj.resultId, cbGetResultImgSvc)
      onErrorResultImg();
    } else {
      viewResultImgSvc(obj.imgPath, obj.resultId, cbGetResultImgSvc)
    }
  }
  function viewResultImgSvc (pImgPath, pResultId, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', httpURL + '/sms/cms/img/view?imgPath=' + pImgPath, true)
    xhr.setRequestHeader('tokenid', state.tokenid)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (e) {
      // console .log("xhr in function in viewResultImgSvc", xhr);
      var arrayBufferView = new Uint8Array(this.response)
      var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(blob)
      // console .log("blob in function in viewResultImgSvc", blob)
      callback(imageUrl, pResultId, xhr.response)
    }
    xhr.send()
  }
  function cbGetResultImgSvc (url, pResultId, response) {
    // console .log("cbGetResultImgSvc called, url ", url);
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      setImgUrl(url)
      // console .log("image url setted", url);
    }
    hideLoader()
  }
  function saveResult () {
    var resultObj = {
      resultId: resultId,
      studentName: studentName,
      yr: yr,
      clsId: clsId,
      percent: percent
    }
    var formData = new FormData()
    formData.append('resultObj', JSON.stringify(resultObj))

    if (resultId) {
      //edit case
      let url = imgUrl

      toDataURL(url).then(async dataUrl => {
        // console .log('Here is Base64 Url', dataUrl)
        var fileData = dataURLtoFile(dataUrl, studentName + '.jpg')
        // console .log(fileData)
        formData.append('file', fileData)
        // await setImgFile(dataURLtoFile(dataUrl, studentName + '.jpg'))
      })
    } else {
      formData.append('file', imgFile)
    }
    saveResultSvc(formData, resultId, cbSaveResultResponse)
  }
  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
      )
  function dataURLtoFile (dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  function cbResultList1Response (data, pResultId) {
    // console .log(data)
    if (data.status === 'Success') {
      setLstResult(data.lstResult)
      var vLstResult = data.lstResult
      for (var i = 0; i < vLstResult.length; i++) {
        var obj = vLstResult[i]
        if (obj.resultId === pResultId) {
          setResultDtls(obj)
          break
        }
      }
    } else {
      if (data.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.SvcMsg)
      }
    }
    hideLoader()
  }

  function cbSaveResultResponse (data, pResultId) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      if (!pResultId) {
        pResultId = data.resultId
      }
      getResultListSvc1(flgBoard, pResultId, cbResultList1Response)
    } else {
      if (data.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.SvcMsg)
      }
    }
    hideLoader()
  }

  function setBlankResultDtls () {
    // console .log("add button clicked ");
    // console .log("imgUrl ", imgUrl);
    setResultDtls({
      resultId: 0,
      yr: '',
      percent: '',
      studentName: '',
      clsId: 0,
      cls: '',
      // imgPath: 'banner/no-image.png'
    })
    setImgFile('')
    setImgUrl(window.appURL + '/no-image.png')
    // console .log("imgUrl ", imgUrl);
  }
  function deleteResult (pResultId) {
    // console .log(pResultId)
    showLoader()
    deleteResultSvc(pResultId, cbDeleteResultResponse)
  }

  function cbDeleteResultResponse (data, pResultId) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getResultList(flgBoard)
    } else {
      if (data.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.SvcMsg)
      }
    }
  }
  const handleResultImgChange = (e, pResultId) => {
    // console .log("handleResultImgChange called" );
    // console .log("e.target.files[0].name", e.target.files[0].name)
    // console .log("'src:' + URL.createObjectURL(e.target.files[0])", 'src:' + URL.createObjectURL(e.target.files[0]))
    setImgFile(e.target.files[0])
    if (e.target.files && e.target.files[0]) {
      setImgUrl(URL.createObjectURL(e.target.files[0]))
    }
  }
  const handleResultImgClick = () => {
    document.getElementById('img_result').click()
  }

  function onErrorResultImg () {
    setImgUrl(window.appURL+'/no-image.png')
  }

  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0.5 })
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1 })
  }
  return (
    <div style={{ height: '100%' }}>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: 'none'
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div
        className='MainContainer'
        style={{ opacity: state.opacity, marginTop: 15 }}
      >
        <div className='col-sm-11 col-centered page' style={{ marginTop: 25 }}>
          <div className='pcss3t pcss3t-effect-scale pcss3t-theme-1'>
            <input
              type='radio'
              name='pcss3t'
              defaultChecked={true}
              id='tab1'
              className='tab-content-first'
              onClick={() => {
                setTabId(1)
                setFlgBoard(true)
                getLstClass(true)
                getResultList(true)
              }}
            />
            <label htmlFor='tab1' title='Board Results'>
              <i className='icon-file'></i>Board Results
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab2'
              className='tab-content-2'
              onClick={() => {
                setTabId(2)
                setFlgBoard(false)
                getLstClass(false)
                getResultList(false)
              }}
            />
            <label htmlFor='tab2' title='Class Results'>
              <i className='icon-file'></i>Class Results
            </label>
            <ul>
              <li className='tab-content tab-content-first typography'>
                {tabId == 1 && (
                  <div className='col-sm-12 padding-remove'>
                    <div className='col-sm-7 padding-remove'>
                      <table className='tableLayout1'>
                        {colgrpResult}
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Class</th>
                            <th>Student</th>
                            <th>Score</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </table>
                      <div
                        className=''
                        style={{ height: '300px', overflowY: 'scroll' }}
                      >
                        <table className='tableLayout'>
                          {colgrpResult}
                          <tbody>
                            {lstResult &&
                              lstResult.length > 0 &&
                              lstResult.map((resultObj, idx) => {
                                return (
                                  <tr
                                    obj={JSON.stringify(resultObj)}
                                    key={idx}
                                    onClick={() => setResultDtls(resultObj)}
                                    className={
                                      resultObj.resultId === resultId
                                        ? 'selected'
                                        : ''
                                    }
                                  >
                                    <td className='text-center'>
                                      {resultObj.yr}
                                    </td>
                                    <td className='text-left'>
                                      {resultObj.cls}
                                    </td>
                                    <td className='text-left'>
                                      {resultObj.studentName}
                                    </td>
                                    <td className='text-left'>
                                      {resultObj.percent}%
                                    </td>
                                    <td className='text-center'>
                                      <span
                                        className='glyphicon glyphicon-edit'
                                        onClick={e => setResultDtls(resultObj)}
                                      ></span>
                                      <span
                                        className='glyphicon glyphicon-remove'
                                        onClick={() =>
                                          deleteResult(resultObj.resultId)
                                        }
                                      ></span>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <span
                      className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                      style={{ fontSize: '30px', width: '4%' }}
                      onClick={e => {
                        setBlankResultDtls()
                      }}
                    ></span>
                    <div className='col-sm-4' style={{ width: '37%' }}>
                      <form
                        className='form form-horizontal'
                        style={{
                          width: '100%',
                          margin: '10px auto',
                          padding: 10,
                          background: '#fff',
                          border: '1px solid #ccc',
                          borderRadius: 10,
                          pointerEvents:
                            state.loader === 'none' ? 'auto' : 'none'
                        }}
                      >
                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Year
                          </label>
                          <div className='form-group col-md-9'>
                            <input
                              type='text'
                              defaultValue={yr}
                              className='form-control'
                              onChange={e => setYr(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Class
                          </label>
                          <div className='form-group col-md-4'>
                            <div className='col-sm-9 padding-remove'>
                              <select
                                className='form-control'
                                onChange={e => setClsId(e.target.value)}
                                value={clsId}
                              >
                                <option value={0}>Class</option>
                                {lstCls.map((cls, idx) => {
                                  return (
                                    <option key={idx} value={cls.clsId}>
                                      {cls.cls}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Student
                          </label>
                          <div className='form-group col-md-9'>
                            <input
                              type='text'
                              defaultValue={studentName}
                              className='form-control'
                              onChange={e => setStudentName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Score
                          </label>
                          <div className='form-group col-md-4'>
                            <input
                              type='text'
                              defaultValue={percent}
                              className='form-control'
                              onChange={e => setPercent(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-offset-3 col-sm-6 padding-remove'>
                            <input
                              type='file'
                              id={'img_result'}
                              name='img'
                              onChange={e => handleResultImgChange(e, resultId)}
                              className='hidden'
                              accept='image/*'
                            />
                            <img
                              src={imgUrl}
                              alt='img'
                              onClick={e => handleResultImgClick()}
                              style={{
                                width: '100%',
                                border: '1px solid #ddd'
                              }}
                              onError={() => onErrorResultImg()}
                            />
                          </div>
                          <div className='form-group col-md-12'>
                            <button
                              type='button'
                              className='btn btn-warning pull-right'
                              onClick={e => saveResult()}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </li>
              <li className='tab-content tab-content-2 typography'>
                {tabId == 2 && (
                  <div className='col-sm-12 padding-remove'>
                    <div className='col-sm-7 padding-remove'>
                      <table className='tableLayout1'>
                        {colgrpResult}
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Class</th>
                            <th>Student</th>
                            <th>Score</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </table>
                      <div
                        className=''
                        style={{ height: '300px', overflowY: 'scroll' }}
                      >
                        <table className='tableLayout'>
                          {colgrpResult}
                          <tbody>
                            {lstResult &&
                              lstResult.length > 0 &&
                              lstResult.map((resultObj, idx) => {
                                return (
                                  <tr
                                    obj={JSON.stringify(resultObj)}
                                    key={idx}
                                    onClick={() => setResultDtls(resultObj)}
                                    className={
                                      resultObj.resultId === resultId
                                        ? 'selected'
                                        : ''
                                    }
                                  >
                                    <td className='text-center'>
                                      {resultObj.yr}
                                    </td>
                                    <td className='text-left'>
                                      {resultObj.cls}
                                    </td>
                                    <td className='text-left'>
                                      {resultObj.studentName}
                                    </td>
                                    <td className='text-left'>
                                      {resultObj.percent}%
                                    </td>
                                    <td className='text-center'>
                                      <span
                                        className='glyphicon glyphicon-edit'
                                        onClick={e => setResultDtls(resultObj)}
                                      ></span>
                                      <span
                                        className='glyphicon glyphicon-remove'
                                        onClick={() =>
                                          deleteResult(resultObj.resultId)
                                        }
                                      ></span>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <span
                      className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                      style={{ fontSize: '30px', width: '4%' }}
                      onClick={e => {
                        setBlankResultDtls()
                      }}
                    ></span>
                    <div className='col-sm-4' style={{ width: '37%' }}>
                      <form
                        className='form form-horizontal'
                        style={{
                          width: '100%',
                          margin: '10px auto',
                          padding: 10,
                          background: '#fff',
                          border: '1px solid #ccc',
                          borderRadius: 10,
                          pointerEvents:
                            state.loader === 'none' ? 'auto' : 'none'
                        }}
                      >
                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Year
                          </label>
                          <div className='form-group col-md-9'>
                            <input
                              type='text'
                              defaultValue={yr}
                              className='form-control'
                              onChange={e => setYr(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Class
                          </label>
                          <div className='form-group col-md-4'>
                            <div className='col-sm-9 padding-remove'>
                              <select
                                className='form-control'
                                onChange={e => setClsId(e.target.value)}
                                value={clsId}
                              >
                                <option value={0}>Class</option>
                                {lstCls.map((cls, idx) => {
                                  return (
                                    <option key={idx} value={cls.clsId}>
                                      {cls.cls}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Student
                          </label>
                          <div className='form-group col-md-9'>
                            <input
                              type='text'
                              defaultValue={studentName}
                              className='form-control'
                              onChange={e => setStudentName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Score
                          </label>
                          <div className='form-group col-md-4'>
                            <input
                              type='text'
                              defaultValue={percent}
                              className='form-control'
                              onChange={e => setPercent(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-offset-3 col-sm-6 padding-remove'>
                            <input
                              type='file'
                              id={'img_result'}
                              name='img'
                              onChange={e => handleResultImgChange(e, resultId)}
                              className='hidden'
                              accept='image/*'
                            />
                            <img
                              src={imgUrl}
                              alt='img'
                              onClick={e => handleResultImgClick()}
                              style={{
                                width: '100%',
                                border: '1px solid #ddd'
                              }}
                              onError={() => onErrorResultImg()}
                            />
                          </div>
                          <div className='form-group col-md-12'>
                            <button
                              type='button'
                              className='btn btn-warning pull-right'
                              onClick={e => saveResult()}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
