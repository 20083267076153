import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import TimeTableHead from './timeTableHead';
import TimeTableBody from './timeTableBody';
import DetailsSlider from './detailsSlider';


var httpURL = '';
var queryString = require('querystring');
var screenId = 33;

var lstWeekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default class MainTimeTable extends Component {

    state = {
        lstTimeTable: [{}],
        lstExamDays: [],
        tokenid: localStorage.getItem("tokenid"),
        screenNameList: [],
        wingId: localStorage.getItem("wingId"),
        selectedTeacher: "",
        selectedSubject: "",
        selectedasset: "",
        selectedInvigilator: "",
        selectedEvaluator: "",
        selectedVerifier: "",
        link: "",
        notes: ""

        // lstHead         : ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],
    }

    constructor(props) {
        super(props);
        // console .log("maintimeTable");
    }

    componentDidMount() {
        // console .log("In mainTimeTable, scheduleId: " + this.props.examScheduleId + "sectionId: " + this.props.classSectionId);
        // console .log("mtt: " + this.props.lstExamPeriod)
        // this.getLstDays(this.props.lstExamDays);
        this.setState({ width2: $('.tableLayout').parent().width() - 17 });
        // console .log(this.props.lstExamPeriod);
        // this.props.lstExamDays.unshift({});
        // this.getLstPeriod(this.props.scheduleId);
    }
    showSlider = () => {
        $('body').addClass('openSlider');
        // console .log("cell",JSON.stringify(cell));
    }
    closeSlider = () => {
        $('body').removeClass('openSlider');
    }

    getFormData = ({ sub, tch, asset, invigilator, evaluator, verifier, link, notes }) => {
        this.setState({
            selectedTeacher: tch,
            selectedSubject: sub,
            selectedasset: asset,
            selectedInvigilator: invigilator,
            selectedEvaluator: evaluator,
            selectedVerifier: verifier,
            link: link,
            notes: notes
        });
        // console .log("state", this.state)
    }

    render() {
        httpURL = lstModules['examTT'].url + ":" + lstModules['examTT'].port;
        return (
            <div>
                <div className="slider halfWidthSlider" >
                    <DetailsSlider
                        removeClassFromBody={this.closeSlider}
                        lstTeacher={this.props.lstTeacher}
                        lstSubject={this.props.lstSubject}
                        lstInvigilator={this.props.lstTeacher}
                        lstVerifier={this.props.lstTeacher}
                        lstEvaluator={this.props.lstTeacher}
                        getFormData={this.getFormData}
                    />
                </div>
                <div className="col-xs-1">
                    <table>
                        <thead style={{ position: 'relative' }}>
                            <tr>
                                <th style={{ borderRight: '1px solid #000', height: 61.88, width: 60 }} className="">Start<br />Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.lstExamPeriod && this.props.lstExamPeriod.map((item, idx) => {
                                return <tr key={idx} id={idx} style={{ height: '80px' }}>
                                    {
                                     // console .log(item)
                                    }
                                    <td style={{ borderRight: '1px solid #000' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: '10px', fontFamily: 'fantasy', textAlign: 'center', width: 60 }}>{item.startTm}</div>
                                    </td>
                                </tr>
                            })}
                        </tbody>

                    </table>
                </div>
                {
                    <TimeTableBody
                        lstExamPeriod={this.props.lstExamPeriod}
                        tableId="timeTable"
                        tbodyId="timeTableBody"
                        bodyCls={"timeTableDiv col-xs-11"}
                        days={this.props.lstExamDays}
                        examScheduleId={this.props.examScheduleId}
                        classSectionId={this.props.classSectionId}
                        examDt={this.props.examDt}
                        alongWithStudy={this.props.alongWithStudy}
                        examId={this.props.examId}
                        headCls={"tableLayout timeTableSec1A "}
                        headWidth={60}
                        heads={this.props.lstExamDays}
                        showSlider={this.showSlider}
                    // sub={this.state.selectedSubject}
                    // tch={this.state.selectedTeacher}
                    // asset={this.state.selectedasset}
                    // invigilator={this.state.selectedInvigilator}
                    // evaluator={this.state.selectedEvaluator}
                    // verifier={this.state.selectedVerifier}
                    // link={this.state.link}
                    // notes={this.state.notes}
                    />
                }

            </div>
        )
    }
}
