import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['cms'].url + ':' + lstModules['cms'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getResultListSvc (pFlgBoard, callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/result/list',
    method: 'POST',
    params: { /* screenId: screenId, */ flgBoard: pFlgBoard },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstResult = response.data.lstResult
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function getResultListSvc1 (pFlgBoard, pResultId, callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/result/list',
    method: 'POST',
    params: { /* screenId: screenId, */ flgBoard: pFlgBoard },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstResult = response.data.lstResult
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj, pResultId)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj, 0)
    })
}
function saveResultSvc (formData, pResultId, callBack) {
  axios({
    url: httpURL + '/sms/cms/result/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data, pResultId)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteResultSvc (pResultId, callBack) {
  // console .log('pResultId = ' + pResultId)
  axios({
    url: httpURL + '/sms/cms/result/delete',
    method: 'POST',
    params: { resultId: pResultId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function getClassListSvc (pFlgBoard, callBack) {
  axios({
    url: httpURL + '/sms/cms/cls/list',
    method: 'POST',
    params: { flgBoard: pFlgBoard },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var ourData = Response.data
      if (ourData.SvcStatus == 'Success') {
        callBack(ourData.lstClass)
      } else {
        callBack([])
      }
    })
    .catch(error => {
      // console .log('error')
    })
}
export {
  getClassListSvc,
  getResultListSvc,
  getResultListSvc1,
  saveResultSvc,
  deleteResultSvc
}
