import React, { useState, useEffect } from 'react'
import ChkLbl from './ChkLbl'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
const RegistrationCategory = ({ admissionId }) => {
  const CATEGORY_SCHOLAR = 10
  const CATEGORY_SIBLING = 20
  const CATEGORY_MERIT = 30
  const CATEGORY_SCST = 40
  const CATEGORY_BACKWARD = 50
  const [flgScholar, setFlgScholar] = useState(false)
  const [flgSibling, setFlgSibling] = useState(false)
  const [flgMerit, setFlgMerit] = useState(false)
  const [flgScSt, setFlgScSt] = useState(false)
  const [flgBackward, setFlgBackward] = useState(false)
  const [loader, setLoader] = useState('none')
  useEffect(() => {
    setLoader('block')
    getCategorySvc(admissionId, cbCategoryResponse)
  }, [])

  function cbCategoryResponse (obj) {
    if (obj.SvcStatus === 'Success') {
      setFlgScholar(obj.category.flgScholar)
      setFlgSibling(obj.category.flgSibling)
      setFlgMerit(obj.category.flgMerit)
      setFlgScSt(obj.category.flgScSt)
      setFlgBackward(obj.category.flgBackward)
    } else {
      toast.error(obj.SvcMsg)
    }
    setLoader('none')
  }

  function getCategorySvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/category/dtls',
      method: 'POST',
      params: { admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  function saveCategory () {
    setLoader('block')
    var category = {
      //admissionId: admissionId,
      flgScholar: flgScholar,
      flgSibling: flgSibling,
      flgMerit: flgMerit,
      flgScSt: flgScSt,
      flgBackward: flgBackward
    }
    saveCategorySvc(category, cbSaveCategoryDtlsResponse)
  }
  function cbSaveCategoryDtlsResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function saveCategorySvc (category, callBack) {
    category = JSON.stringify(category)
    axios({
      url: httpURL + '/sms/admission/category/save',
      method: 'POST',
      params: { admissionId: admissionId, category: category },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  return (
    <>
      <div
        className='col-sm-10'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '0.5%',
          paddingBottom: '0.5%'
        }}
      >
        <div
          className='col-sm-12'
          style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: 'fixed',
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: '50%',
              top: '50%',
              display: loader
            }}
          >
            <img
              className='loderImg'
              src={require('common/images/loader.gif')}
              alt='Loader...'
            />
          </div>
          <ChkLbl
            key={'cat_' + CATEGORY_SCHOLAR}
            label='Scholar'
            dataValue={flgScholar}
            onChange={setFlgScholar}
          ></ChkLbl>
          <ChkLbl
            key={'cat_' + CATEGORY_SIBLING}
            label='Sibling'
            dataValue={flgSibling}
            onChange={setFlgSibling}
          ></ChkLbl>
          <ChkLbl
            key={'cat_' + CATEGORY_MERIT}
            label='Merit-cum-Means'
            dataValue={flgMerit}
            onChange={setFlgMerit}
          ></ChkLbl>
          <ChkLbl
            key={'cat_' + CATEGORY_SCST}
            label='SC/ST'
            dataValue={flgScSt}
            onChange={setFlgScSt}
          ></ChkLbl>
          <ChkLbl
            key={'cat_' + CATEGORY_BACKWARD}
            label='Backward'
            dataValue={flgBackward}
            onChange={setFlgBackward}
          ></ChkLbl>
        </div>
      </div>
      <div
        className='col-sm-2'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '0.5%',
          paddingBottom: '0.5%'
        }}
      >
        <button
          type='button'
          className='btn-info btn'
          onClick={e => saveCategory()}
        >
          Save
        </button>
      </div>
    </>
  )
}

export default RegistrationCategory
