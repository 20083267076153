
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import SliderType from './SliderType';
import { generatePasswordSvc, saveStaffAccessSvc } from './StaffSvc'
import '../css/StaffCtrl.css'
const StaffSystemAccess = (props) => {
    const [loader, setLoader] = useState('none')
    const [flgAccess, setflgAccess] = useState(!!props.flgSystemAccess)

    useEffect(() => {
        setflgAccess(!!props.flgSystemAccess)
        // console .log(!!props.flgSystemAccess)
    }, [])

    function generatePassword(userId) {
        setLoader('block')
        generatePasswordSvc(userId, cbGeneratePasswordResp)
    }

    function cbGeneratePasswordResp(data) {
        if (data.SvcStatus === 'Success') {
            // console .log('successfully generated password')
            toast.success('Mail sent to the registered mail Id of teacher')
            setLoader('none')
        }
        else {
            // console .log(data.SvcMsg)
            setLoader('none')
            toast.error(data.SvcMsg)
        }
    }
    function systemAccessSave(flgAccess) {
        saveStaffAccessSvc(props.selectedUserId, flgAccess, cbSaveAccessResp)
        setLoader('block')
    }
    function cbSaveAccessResp(data) {
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg)
            setLoader('none')
        }
        else {
            toast.error(data.SvcMsg)
            setLoader('none')
        }
    }
    return (
        <form className='form form-horizontal' style={{ width: '100%', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10, pointerEvents: loader === "none" ? "auto" : "none", overflowY: 'hidden' }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className='EnquiryHead col-sm-12 col-centered' style={{
                border: "1px solid #aaa", borderTopLeftRadius: 10,
                borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5, fontWeight: 'bold'
            }}><h4>System Access</h4></div>
            <div className='form-group col-md-12'>
                <div className="form-group col-md-4">
                    <label className="control-label col-sm-10">Allow System Access</label>
                    <div className='col-sm-2 padding-remove'>
                        <input type='checkbox' className="form-control" defaultValue={flgAccess} defaultChecked={!flgAccess ? 0 : 1} onChange={(e) => systemAccessSave(e.target.checked)} required />
                    </div>
                </div>
                <div className="form-group col-md-8">
                    <label className="sub-text col-sm-12 text-left">If not selected, user will not be allowed to log into system. They will continue receiving email communications though</label>
                </div>

            </div>
            <div className='form-group col-md-12'>
                <div className="form-group col-md-4 padding-remove">
                    <button type="button" className="btn btn-warning pull-center" onClick={(e) => generatePassword(props.selectedUserId)}>Generate Password</button>
                </div>
                <div className="form-group col-md-8 padding-remove">
                    <label className="sub-text col-sm-12 text-left">New password will be sent to the recipient inbox</label>
                </div>

            </div>
        </form>
    );
}
export default StaffSystemAccess;