import React,{useState, useEffect, useRef} from 'react';
import { toast, ToastContainer } from 'react-toastify'
import SAHeader from 'sa/header/js/saHeader';
import {getWebsiteSvc, saveWebsiteSvc, generateApiKeySvc} from './WebsiteApikeysSvc'

const WebsiteApikeysCtrl = () => {
    const [loader, setLoader] = useState('none')
    const [website, setWebsite] = useState('')
    const [apiKey, setApiKey] = useState('')
    const inputArea = useRef(null);
    const controller = new AbortController()
    const signal = controller.signal    
    
    useEffect(()=>{
        getWebsiteSvc(cbWebsiteResponse)
        return ()=>{
            controller.abort()
        }
    },[])


    function cbWebsiteResponse(data){
        // console .log(data)
        if(data.status === 'Success'){
            setWebsite(data.website)
            setApiKey(data.apiKey)
        }
    }

    function save(){
        saveWebsiteSvc(website, cbSaveWebsiteResponse)        
    }

    function cbSaveWebsiteResponse(data){
        if(data.status === 'Success'){
            toast.success(data.message)
        }
    }

    const copyToClipboard = str => {
        inputArea.current.select();
        document.execCommand('copy');
        inputArea.current.focus();
        toast.success('Copied!');
    };
    
    function generateApiKey(){
        generateApiKeySvc(cbApiKey)
    }

    function cbApiKey(data){
        // console .log(data)
        if(data.status === 'Success'){
            toast.success(data.message);
            setApiKey(data.apiKey);
        }
    }

    return (
    <div>
        <SAHeader/>
        <ToastContainer autoClose={3000}/>
        <div className="col-sm-7 col-centered">
            <form className='form form-horizontal' style={{
                width: '100%',
                margin: '25px auto',
                background: '#fff',
                border: '1px solid #ccc',
                borderRadius: 10,
                padding:5,
                pointerEvents: loader === 'none' ? 'auto' : 'none'
            }}>
                <div
                style={{
                position: 'fixed',
                zIndex: 10000,
                width: 100,
                height: 100,
                marginLeft: -50,
                marginTop: -50,
                left: '50%',
                top: '50%',
                display: loader
                }}
                >
                    <img
                        className='loderImg'
                        src={require('common/images/loader.gif')}
                        alt='Loader...'
                        />
                </div>
                <div className='row'>
                    <label className='control-label col-sm-2'>Website</label>
                    <div className=' form-group col-sm-10'>
                        <div className="col-sm-7 padding-remove">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="glyphicon glyphicon-globe"></i>
                                </span>
                                <input id="website" type="text" defaultValue={website} onChange={(e)=>setWebsite(e.target.value)} className="form-control" name="website" placeholder="Website"/>
                            </div>      
                        </div>      
                        <div className='col-sm-2'>
                            <button type="button" onClick={(e)=>save()} className="btn btn-info">Save</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <label className='control-label col-sm-2'>API Key</label>
                    <div className="form-group col-sm-10">
                        <div className='col-sm-9 padding-remove'>
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="glyphicon glyphicon-wrench"></i>
                                </span>
                                <input 
                                    ref={inputArea} 
                                    style={{background:'#000', color:'#fff'}} 
                                    id="apiKey" 
                                    type="text" 
                                    value={apiKey} 
                                    className="form-control" 
                                    name="apiKey" 
                                    placeholder="API Key" 
                                    readOnly/>
                            </div>      
                        </div>      
                        <span className="col-sm-1" style={{top:5}} onClick={(e)=>copyToClipboard(apiKey)}><i className="fa fa-copy" style={{fontSize:24}}></i></span>
                           
                        <div className='col-sm-2 padding-remove'>
                            <button type="button" onClick={(e)=>generateApiKey()} className="btn btn-info">Generate Key</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default WebsiteApikeysCtrl