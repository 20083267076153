import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import $ from "jquery";
import axios from 'axios';
import TableHead from 'common/js/tableHead';
import TableBody from './tableBody';

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;


export default class SubjectTable extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        var wingId = 1;
        var classSectionId = 1;
        var classId = 4;
        this.setState({ loader: 'block', opacity: 0.5 });
        // console .log("subjectTable props in lstSubject: " + this.props.lstSubject);
    }

    subjectDragHandler = (e) => {
        alert(e + "subject dragged" + e.target.id);
        // e.dataTransfer.setData("text",e.target.id);
    }

    // clickAlert=(e)=>{
    //     alert("I am working: "+e);
    // }

    dragStartHandler(e) {
        // console .log("dragStartHandler: " + $(e.target).attr('id') + ", " + $(e.target).text());
        e.dataTransfer.setData("text", $(e.target).text());
        e.dataTransfer.setData("id", $(e.target).attr('id'));
    }



    render() {
        return (
            <>
                <div className="subjectRefresh refreshButton">
                    <span className="glyphicon glyphicon-refresh" title="Refresh Subject" onClick={this.refreshSubject}></span>
                </div>
                <TableHead headCls={"tableLayout1 subjectWrap"}
                    heads={[
                        ["Subject Id", 0, 'no-display'],
                        ["", 20, ''],
                        ["Subject", 40, ''],
                        ["Time\nAlloted", 40, 'br'],
                        ["Syllabus\nCovered", 40, 'br'],
                        ["Remarks", 60, ''],
                    ]}
                />

                <div className="subjectTableDiv" style={{ height: 90, overflowY: "scroll", border: '1px solid #ccc' }}>
                    {
                        this.props.lstSubject
                        &&
                        this.props.lstSubject.length > 0
                        &&
                        <TableBody
                            bodyCls={"subjectTableDiv"}
                            tableId="subjectTable"
                            tbodyId="subjectTableBody"
                            colswidth={[20, 40, 40, 40, 60]}
                            lstSubject={this.props.lstSubject}
                            // onChildClick={this.props.showSlider}
                            onDragStarted={(e) => this.dragStartHandler(e)}
                        />
                    }
                </div>
            </>
        )
    }
}