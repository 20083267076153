import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import Duty from './duty'
import { getLstRoles, getLstPeriod, getLstAbsentTch, getLstSubs, getLstTch, getHeaderDates, setMarkAbsent, assignSubsTchDuty, removeSubsTchDuty, informAll } from './disruptionServices'
import SubsTchTbl from './subsTchTbl';
import TchSlider from './tchSlider';
import HeaderDtTab from './headerDtTab';
import ViewSlider from './viewSlider';
const DisruptionCtrl = () => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [sliderDisplayed, setSliderDisplayed] = useState(false)
    const [selDt, setSelDt] = useState('')
    const [selClsId, setSelClsId] = useState(0)
    const [selSecId, setSelSecId] = useState(0)
    const [selRoleId, setSelRoleId] = useState(0)
    const [selPeriodId, setSelPeriodId] = useState(0)
    const [selSubId, setSelSubId] = useState(0)
    const [selSubsTchId, setSelSubsTchId] = useState(0)
    const [selTchId, setSelTchId] = useState(0)
    const [lstPeriod, setLstPeriod] = useState([])
    const [lstAbsentTch, setLstAbsentTch] = useState([])
    const [lstSubsTch, setLstSubsTch] = useState([])
    const [lstTch, setLstTch] = useState([])
    const [completionPct, setCompletionPct] = useState({})
    const [state, setState] = useState(initialState);
    const [cellDtls, setCellDtls] = useState({});
    const [datesObj, setDatesObj] = useState({});
    const [lstRole, setlstRole] = useState([]);
    const [absentTchId, setAbsentTchId] = useState(0)
    const [flg, setFlg] = useState(false)
    useEffect(() => {
        setRoles();
        next(new Date());
    }, []);


    function next(dt) {
        dt = getDtDDMMYYYY(dt);
        showLoader();
        getHeaderDates(dt, handleDtResponse);
    }

    function getDtDDMMYYYY(dt) {
        var dd = dt.getDate();
        var mm = dt.getMonth() + 1;

        var yyyy = dt.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return (dd + '-' + mm + '-' + yyyy);
    }

    function prev() {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        next(startDate)
    }

    const rowClickInMain = (e) => {
        var tchId = JSON.parse(e.currentTarget.getAttribute("tch-id"));
        setSelTchId(tchId)
        // console .log("selSubsId=" + selSubsId + "tchId=" + tchId + ",selClsId=" + selClsId + ",selSecId=" + selSecId + ",selPeriodId=" + selPeriodId + ",selDt=" + selDt + ",absentTchId=" + absentTchId + ",selSubId=" + selSubId);
        setSubs(selDt, cellDtls)
        // console .log("selSubsTchId:"+selSubsTchId+", tchId:"+tchId+", selClsId:"+selClsId+", selSecId:"+selSecId+", selPeriodId:"+selPeriodId+", selDt:"+selDt+", absentTchId:"+absentTchId+", selSubId:"+selSubId)
        assignTchDuty(
            selSubsTchId
            , tchId
            , selClsId
            , selSecId
            , selPeriodId
            , selDt
            , absentTchId
            , selSubId)

    }

    function setRoles() {
        showLoader();
        getLstRoles(handlelstRoleResponse);
    }
    function showLoader() {
        setState({ ...state, loader: 'block', opacity: 0.5 });
    }
    function hideLoader() {
        setState({ ...state, loader: 'none', opacity: 1 });
    }
    function setPeriods(dt) {
        showLoader();
        getLstPeriod(dt, handlePeriodResponse);
    }

    function setAbsentTchs(dt) {
        showLoader();
        getLstAbsentTch(dt, handleAbsentTchResponse);
    }

    function setSubs(dt, cell) {
        showLoader();
        getLstSubs(dt, selRoleId, cell.clsId, cell.secId, cell.periodId, cell.subId, handleSubsResponse);
    }

    function setTchs(dt) {
        showLoader();
        getLstTch(dt, handleTchsResponse);
    }

    function markAbsent(tchId, absent) {
        showLoader();
        // console .log("selDt:" + selDt)
        setMarkAbsent(selDt, tchId, absent, handleMarkAbsentResponse)
    }

    function assignTchDuty(subsId, subsTchId, clsId, sectionId, periodId, dt, subjectId) {
        var obj = {
            subsId: !subsId?0:subsId,
            subsTchId: !subsTchId?0:subsTchId,
            clsId: clsId,
            sectionId: sectionId,
            periodId: periodId,
            dt: dt,
            subjectId: selSubId,
            absentTchId: absentTchId,


        };
        // console .log('obj=' + JSON.stringify(obj))
        showLoader();
        assignSubsTchDuty(obj, handleAssignDutyResponse);
    }

    function removeTchDuty(e, subsId,subsTchId, periodId, clsId, sectionId, remarks) {
        e.stopPropagation();
        e.preventDefault();
        showLoader();
        setSubs(selDt, cellDtls)
        removeSubsTchDuty(selDt,subsId, subsTchId, periodId, clsId, sectionId, remarks, handleRemoveDutyResponse);
    }

    function handlelstRoleResponse(data) {
        var obj = data;
        hideLoader();
        if (obj.status === 'Success') {
            setlstRole(obj.lstRole);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false
            } else {
                toast.error(obj.message)
            }
        } else {
            toast.error('Service failed' + obj);
        }
    }
    function handlePeriodResponse(data) {
        hideLoader();
        var obj = data;
        if (obj.status === 'Success') {
            setLstPeriod(obj.lstPeriod);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    function handleAbsentTchResponse(data) {
        hideLoader();
        var obj = data;
        if (obj.status === 'Success') {
            setLstAbsentTch(obj.lstAbsentTch);
            setCompletionPct(obj.completionPct);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    function handleSubsResponse(data) {
        hideLoader();
        var obj = data;
        if (obj.status === 'Success') {
            setLstSubsTch(obj.lstSubsTch);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    function handleTchsResponse(data) {
        hideLoader();
        var obj = data;
        if (obj.status === 'Success') {
            setLstTch(obj.lstTch);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    function handleMarkAbsentResponse(data) {
        hideLoader();
        if (data.status === 'Success') {
            toast.success(data.message);
            setTchs(selDt);
            setAbsentTchs(selDt);
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message)
            }
        } else {
            toast.error("Service failed " + data.message);
        }
    }

    function handleAssignDutyResponse(data) {
        hideLoader();
        if (data.status === 'Success') {
            toast.success(data.message);
            setAbsentTchs(selDt);
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(data.message);
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }
    function handleRemoveDutyResponse(data) {
        hideLoader();
        if (data.status === 'Success') {
            toast.success(data.message);
            setAbsentTchs(selDt);
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message)
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    function handleDtResponse(data) {
        hideLoader();
        var obj = data;
        if (obj.status === 'Success') {
            setDatesObj(obj.lstDates);
        } else {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
    }


    function handleEditClick(e, val) {
        e.preventDefault();
        if (val)
            showSlider();
        else
            closeSlider();
    }

    const showSlider = () => {
        setSliderDisplayed(true);
    };

    const closeSlider = () => {
        setSliderDisplayed(false);
    };

    function onInformAllClick() {
        informAll(selDt, handleInformAllResponse);
    }

    function handleInformAllResponse(data) {
        hideLoader();
        if (data.status === 'Success') {
            toast.success(data.message);
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    function onDtTabClicked(dt) {
        setSelDt(dt)
        setPeriods(dt);
        setAbsentTchs(dt);
        setTchs(dt);

    }
    async function onCellClicked(cell, absentTchId, clsId, sectionId, subId, subsTchId) {
        // console .log("absentTchId= " + absentTchId);
        // console .log("clsId= " + clsId);
        // console .log("sectionId= " + sectionId);
        // console .log("subId= " + subId);
        // console .log("subsId= " + subsId);

        await setSelClsId(clsId)
        await setSelSubId(subId)
        await setSelSecId(sectionId)
        await setSelSubsTchId(subsTchId);
        await setCellDtls(cell);
        await setAbsentTchId(absentTchId);
        setSubs(selDt, cell);

    }
    function setFlgView(params) {
        setFlg(params)
    }
    function setFlgClose() {
        setFlg(false)
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ marginTop: 15, opacity: state.opacity }}>
                <div className="col-md-12 col-centered wingsWrap clearfix">

                    <div style={{ height: 50, width: '50%' }}>
                        {datesObj && <HeaderDtTab

                            lstDates={datesObj}
                            next={next}
                            prev={prev}
                            onDtTabClicked={onDtTabClicked}
                            selDt={selDt} />}
                    </div>
                    <div className="col-sm-12 padding-remove" style={{ marginTop: '1%' }}>
                        <div className="col-sm-7 padding-remove"
                            style={{ width: '58%', height: '80vh' }}>
                            {
                                <Duty
                                    lstPeriod={lstPeriod}
                                    selTchId={selTchId}
                                    selPeriodId={selPeriodId}
                                    setSelPeriodId={setSelPeriodId}
                                    setSelTchId={setSelTchId}
                                    setCellDtls={setCellDtls}
                                    lstAbsentTch={lstAbsentTch}
                                    completionPct={completionPct}
                                    onCellClicked={onCellClicked}
                                    removeTchDuty={removeTchDuty}
                                    informAll={onInformAllClick}
                                    setFlgView={setFlgView}
                                />}
                        </div>
                        <div className='slider halfWidthSlider' style={{ right: 0, opacity: 1, display: flg === true ? 'block' : 'none', zIndex: 10000 }}>
                            <ViewSlider
                                selDt={selDt}
                                setFlgClose={setFlgClose}
                                flg={flg}
                            ></ViewSlider>
                        </div>
                        <div style={{ marginTop: '4.5%' }}>
                            <div className="col-sm-1 padding-remove" style={{ width: '3%', textAlign: 'center' }}>
                                <span className="glyphicon glyphicon-edit" style={{ cursor: 'pointer' }} onClick={(e) => handleEditClick(e, true)}></span>
                            </div>
                            <div className="col-sm-4 padding-remove" style={{ width: '39%', height: '80vh' }}>
                                {lstSubsTch && <SubsTchTbl
                                    lstSubsTch={lstSubsTch}
                                    rowClick={rowClickInMain}
                                    selTchId={selTchId}
                                />}
                            </div>
                        </div>
                    </div>
                    {sliderDisplayed && <Slider lstTch={lstTch} cboxClick={markAbsent} />}
                </div>
            </div>
        </div>
    )

    function Slider(props) {
        return (
            <div className="slider halfWidthSlider" style={{ opacity: sliderDisplayed ? 1 : 0, right: 0 }}>
                <TchSlider lstTch={props.lstTch} cboxClick={props.cboxClick} lstRole={lstRole} />
                <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ opacity: sliderDisplayed ? 1 : 0, right: '70%' }} onClick={(e) => handleEditClick(e, false)}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
            </div>
        )
    }



}
export default DisruptionCtrl;