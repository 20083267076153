import axios from "axios";
import lstModules from "common/json/lstModules.json";

var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;
var schoolId = 77; //localStorage.getItem("schoolId");
var tokenid = localStorage.getItem("tokenid");

const getLstAcademicYearSvc = (cb) => {
  var obj = { status: "Success", message: "" };
  obj.lstAcademicYear = [];
  // console .log(httpSchoolURL + "/schools/mst/acdyr/list");
  axios({
    url: httpSchoolURL + "/schools/mst/acdyr/list",
    method: "POST",
    params: { schoolId: schoolId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console .log(data);
      obj.status = data.SvcStatus;

      if (data.SvcStatus === "Success") {
        obj.lstAcademicYear = data.lstAcademicYear;
      } else {
        obj.message = data.SvcMsg;
      }

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
};
function getFeeInfoSvc(academicYearId, cb) {
  var obj = { status: "Success", message: "" };
  obj.lstFees = {};
  // console .log(httpSchoolURL + "/schools/admin/fees/dtls");
  axios({
    url: httpSchoolURL + "/schools/admin/fees/dtls",
    method: "POST",
    params: { schoolId: schoolId, acdYrId: academicYearId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console .log(data);
      obj.status = data.SvcStatus;

      if (data.SvcStatus === "Success") {
        obj.lstFees = data.lstFees;
      } else {
        obj.message = data.SvcMsg;
      }

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
}

function saveFeeInfoSvc(fees, cb) {
  var obj = { status: "Success", message: "" };
  axios({
    url: httpSchoolURL + "/schools/admin/fees/save",
    method: "POST",
    params: { fees: JSON.stringify(fees) },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      obj.status = data.SvcStatus;

      obj.message = data.SvcMsg;

      cb(obj);
    })
    .catch((error) => {
      console.error(error);
    });
}
export { getLstAcademicYearSvc, getFeeInfoSvc, saveFeeInfoSvc };
