import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getSubjectSvc, getStudentScoreSvc, saveStudentScoreSvc } from './StudentSubScoreSvc';

var lstTmpStudentScore = [], lstTmpSubject = [], lstTmpStudent = [], tmpMaxScore = 0;

const StudentSubScoreTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [submitBtnLoader, setSubmitBtnLoader] = useState("none");
    const [lstStudentScore, setLstStudentScore] = useState([]);
    const [lstStudentRank, setLstStudentRank] = useState([]);
    const [lstSubject, setLstSubject] = useState([]);
    const [lstStudentScoreFiltered, setLstStudentScoreFiltered] = useState([]);
    const [lstSvcStudentScore, setLstSvcStudentScore] = useState([]);
    const [examId, setExamId] = useState(null);
    const [sectionId, setSectionId] = useState(0);
    const [theKey, setTheKey] = useState(0);
    const [syllabusId, setSyllabusId] = useState(0)
    // const [flgSortMarks, setFlgSortMarks] = useState(0);


    useEffect(() => {
        // console .log("StudentSubScoreTable.js called")
        if (props.examId && props.sectionId) {
            setExamId(props.examId)
            setSectionId(props.sectionId)
            setLoader('block')
            getSubjectSvc(cbSubjectResponse, props.examId, props.sectionId, props.syllabusId);
            getStudentScoreSvc(props.examId, props.sectionId, props.syllabusId, cbStudentScoreResponse);
        } else {
            setLstStudentScore([]);
            setLstStudentScoreFiltered([]);
        }

    }, [props.examId, props.sectionId, props.syllabusId])

    function generateRandomNo() {
        // console .log("generateRandomNo called");
        var lRandNo = Math.floor(Math.random() * 999999);
        setTheKey(lRandNo)
    }
    async function cbSubjectResponse(data) {
        // console .log("cbSubjectResponse called, data", data);
        lstTmpSubject = []
        setLoader('none')
        setLstSubject([]);
        if (data.SvcStatus === "Success") {
            // console .log("data.lstSubject", data.lstSubject);
            await setLstSubject(data.lstSubject);
            lstTmpSubject = data.lstSubject;
            tmpMaxScore = 0;
            lstTmpSubject.forEach(function (obj, idx) {
                if (lstTmpSubject[idx].subType === "Scholastic") {
                    tmpMaxScore += props.maxScore;
                }
            });
            // console .log("tmpMaxScore", tmpMaxScore);


        } else {
            toast.error(data.SvcMsg)
        }
    }
    async function cbStudentScoreResponse(data) {
        // console .log("cbStudentScoreResponse called, data", data);
        setLoader('none')
        lstTmpStudentScore = [];
        setLstSvcStudentScore([])
        if (data.SvcStatus === "Success") {
            // console .log("data.lstStudentScore", data.lstStudentScore);
            await setLstStudentScore(data.lstStudentScore);
            await setLstStudentScoreFiltered(data.lstStudentScore);
            // obj.lstScore.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            lstTmpStudent = data.lstStudentScore;
            // console .log("calcSummary calling from cbStudentScoreResponse");
            calcSummary();
            generateRandomNo()
            //   await setLstStudentScore(lstTmpStudent)
            for (var j = 0; j < lstTmpSubject.length; j++) {
                var obj = { subId: lstTmpSubject[j].subId, lstScore: [] };
                for (var i = 0; i < lstTmpStudent.length; i++) {
                    obj.lstScore.push({
                        studentId: lstTmpStudent[i].studentId,
                        score: lstTmpStudent[i].lstScore[j]
                    })
                }
                lstTmpStudentScore.push(obj);

            }
            // console .log("lstTmpStudentScore", lstTmpStudentScore);
            setLstSvcStudentScore(lstTmpStudentScore)

        } else {
            toast.error(data.SvcMsg)
        }
    }

    function calcSummary() {
        // console .log("calcSummary called, lstTmpStudent", lstTmpStudent);
        // console .log("iterating through lstTmpStdent");
        lstTmpStudent.forEach(function (obj, idx) {
            // console .log("obj", obj);
            // obj.total = obj.lstScore.reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue), 0);
            if (tmpMaxScore === 0) {
                obj.total = 0;
                obj.pct = 0;
                obj.grade = "-";
            } else {
                obj.total = 0;
                obj.lstScore.forEach((mark, index) => {
                    if (lstTmpSubject[index] && lstTmpSubject[index].subType === "Scholastic") {
                        if (!isNaN(parseInt(mark))) {
                            obj.total += parseInt(mark);
                        }
                    }
                });
                // console .log("obj.total", obj.total, ", tmpMaxScore", tmpMaxScore)
                obj.pct = Math.round((obj.total * 100) / tmpMaxScore);
                // console .log("obj.pct",obj.pct)
                obj.grade = calcGrade(obj.pct)
                obj.pct = obj.pct + "%";
            }
            //  lstTmpSelClsId.push(obj.classId)
        })
        let newArray = lstTmpStudent.map(student => ({
            id: student.studentId,
            marks: student.total
        }));
        newArray.sort((a, b) => b.marks - a.marks);
        let rank = 1;
        let prevMarks = newArray[0].marks;
        newArray[0].rank = 1;
        for (let i = 1; i < newArray.length; i++) {
            if (newArray[i].marks === prevMarks) {
                newArray[i].rank = rank;
            } else {
                rank = i + 1;
                newArray[i].rank = rank;
            }
            prevMarks = newArray[i].marks;
        }
        setLstStudentRank(newArray);
        // console .log("lstTmpStudent after calculation in calcSummary", lstTmpStudent);
    }

    function getRankById(id) {
        const student = lstStudentRank.find(student => student.id === id);
        return student ? student.rank : "-";
    }

    function getOrdinalNumber(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const remainder10 = number % 10;
        const remainder100 = number % 100;
        const suffix = suffixes[(remainder10 < 4 && remainder10 > 0 && (remainder100 < 10 || remainder100 > 20)) ? remainder10 : 0];
        return number + suffix;
    }

    // const toggleMarksSorting = (flgSort) => {
    //     // console .log("toggleMarkSorting called flgSort", flgSort);
    //     // console .log("flgSortMarks before updating", flgSortMarks);
    //     setFlgSortMarks(flgSort);
    //     if (flgSort === 0) {
    //         sortByNamesAscending();
    //     }
    //     if (flgSort === 1) {
    //         sortByNamesDescending();
    //     }
    //     if (flgSort === 2) {
    //         sortMarksDescending();
    //     }
    //     if (flgSort === 3) {
    //         sortMarksAscending();
    //     }
    // }

    // const sortMarksAscending = () => {
    //     // console .log("sortMarkAscending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
    //     setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => parseInt(a.total) - parseInt(b.total)));
    // }

    // const sortMarksDescending = () => {
    //     // console .log("sortMarkDescending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
    //     setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => parseInt(b.total) - parseInt(a.total)));
    // }

    // const sortByNamesAscending = () => {
    //     // console .log("sortByNameAscending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
    //     setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => a.student.localeCompare(b.student)));
    // }

    // const sortByNamesDescending = () => {
    //     // console .log("sortByNamesDescending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
    //     setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => b.student.localeCompare(a.student)));
    // }




    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="4%"></col>
        <col width="6%"></col>
        <col width="10%"></col>
        {lstSubject.map((obj, idx) => { return <col key={idx} width={`${(100 - 45) / lstSubject.length}%`}></col> })}
        <col width="4%"></col>//total//%//grade//rank
        <col width="4%"></col>
        <col width="4%"></col>
        <col width="4%"></col>
    </colgroup>

    function calcGrade(grade) {
        // console .log("calcGrade called, grade", grade);
        grade = isNaN(grade) ? 0 : parseInt(grade);
        var strGrade = 'D';
        switch (true) {
            // If score is 90 or greater
            case grade > 90:
                // console .log(grade+", grade>90")
                strGrade = "A1";
                break;
            // If score is 80 or greater
            case grade > 80:
                // console .log(grade+", grade>80")
                strGrade = "A2";
                break;
            // If score is 70 or greater
            case grade > 70:
                // console .log(grade+", grade>70")
                strGrade = "B1";
                break;
            // If score is 60 or greater
            case grade > 60:
                // console .log(grade+", grade>60")
                strGrade = "B2";
                break;
            // If score is 60 or greater
            case grade > 50:
                // console .log(grade+", grade>50")
                strGrade = "C1";
                break;
            // Anything 59 or below is failing
            // If score is 60 or greater
            case grade > 40:
                // console .log(grade+", grade>40")    
                strGrade = "C2";
                break;
            // If score is 60 or greater
            case grade > 30:
                // console .log(grade+", grade>30")
                strGrade = "D";
                break;
            default:
                // console .log(grade+", default")
                strGrade = "F";
        }
        return strGrade;
    }

    function handleStudentScoreChange(e, pSubId, subType, pStudentId, pVal) {
        // console .log("handleStudentScoreChange called, pSubId", pSubId, ", pStudentId", pStudentId, ", pVal", pVal);
        // if (subType === "Scholastic") {
        //     pVal = parseInt(pVal);
        // }
        var idx = existsAt("subId", pSubId, lstTmpStudentScore);
        var studentIdx = existsAt("studentId", pStudentId, lstTmpStudentScore[idx].lstScore);
        if (idx > -1) {
            lstTmpStudentScore[idx].lstScore[studentIdx].score = pVal;
            lstTmpStudent[studentIdx].lstScore[idx] = pVal;
        }
        else
            lstTmpStudentScore[idx].lstScore.push({ studentId: pStudentId, score: pVal })

        // console .log("lstTmpStudentScore", lstTmpStudentScore);
        setLstSvcStudentScore(lstTmpStudentScore);
        lstTmpStudent[studentIdx].lstScore[idx] = pVal;
        // console .log("lstTmpStudent", lstTmpStudent);
        // console .log("calcSummary calling from handleStudentScoreChange");
        calcSummary();

    }

    function cbMarkStudentScore(data) {
        // console .log("cbMarkStudentScore called, data", data);
        setSubmitBtnLoader('none')
        if (data.status === "Success") {
            toast.success(data.message)
            props.generateRandNo()
            // console .log("calcSummary calling from cbMarkStudentScore");
            calcSummary();
        } else {
            toast.error(data.message)
        }
    }

    function existsAt(pKey, id, arr) {
        var idx = -1;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i][pKey] === id) {
                idx = i;
                break;
            }
        }

        return idx;
    }

    function saveScore(pSubId, e) {
        // console .log("saveScore called, pSubId", pSubId, ", pExamSectionSubId", pExamSectionSubId);
        e.preventDefault()
        //submitStudentScore(pSubId, pExamSectionSubId)
        submitStudentScore(pSubId)
    }
    //function submitStudentScore(pSubId, pExamSectionSubId) {
    function submitStudentScore(pSubId){
        // console .log("submitStudentScore, pSubId", pSubId, ", pExamSectionSubId", pExamSectionSubId);
        setSubmitBtnLoader('block')
        var idx = existsAt("subId", pSubId, lstTmpStudentScore);
        var data = lstTmpStudentScore[idx]
        data.examId = examId;
        data.sectionId = sectionId;
        //data.examSectionSubId = pExamSectionSubId;
        saveStudentScoreSvc(data, cbMarkStudentScore);
    }


    useEffect(() => {
        // console .log("useEffect in StudentSubScoreTable called");
        document.addEventListener("keydown", function (event) {

            if (event.keyCode === 13 || event.keyCode === 40 && event.target.nodeName === "INPUT") {//down
                var form = event.target.form;
                var index = Array.prototype.indexOf.call(form, event.target);
                form.elements[index + lstTmpSubject.length].focus();
                form.elements[index + lstTmpSubject.length].select();

            }
            if (event.keyCode === 37 && event.target.nodeName === "INPUT") {//left
                var form = event.target.form;
                var index = Array.prototype.indexOf.call(form, event.target);
                if (index != 0) {
                    form.elements[index - 1].focus();
                    form.elements[index - 1].select();
                } else {
                    form.elements[index].focus();
                    form.elements[index].select();

                }
            }
            if (event.keyCode === 38 && event.target.nodeName === "INPUT") {//up
                var form = event.target.form;
                var index = Array.prototype.indexOf.call(form, event.target);
                if (index != 0 && index - lstTmpSubject.length > -1) {
                    form.elements[index - lstTmpSubject.length].focus();
                    form.elements[index - lstTmpSubject.length].select();
                } else {
                    form.elements[index].focus();
                    form.elements[index].select();

                }
            }
            if (event.keyCode === 39 && event.target.nodeName === "INPUT") {//right
                var form = event.target.form;
                var index = Array.prototype.indexOf.call(form, event.target);

                form.elements[index + 1].focus();
                form.elements[index + 1].select();


            }
        });
    }, []);

    function handleKeyDown(event) {
        // console .log("handleKeyDown called, event", event);
        //onEnterPress
        if (event.keyCode === 13) {
            // let active = event.currentTarget;
            // let target = document.querySelector('[tabindex="' + (active.tabIndex + 1) + '"]');
            // target.focus();
            event.preventDefault();
            event.currentTarget.select();
        }
    }


    return (
        <form style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>
                            {"Student "}
                            {/* {flgSortMarks === 1 ?
                                <span title="edit" className="glyphicon glyphicon-triangle-top"
                                    onClick={() => toggleMarksSorting(0)}></span>
                                :
                                <span title="edit" className="glyphicon glyphicon-triangle-bottom"
                                    onClick={() => toggleMarksSorting(1)}></span>
                            } */}
                        </th>
                        <th>Roll No.</th>
                        <th>Enrollment No. </th>
                        <th>Father</th>
                        {lstSubject.map((obj, idx) => { return <th key={obj.subId}>{obj.sub}</th> })}
                        <th>
                            {"Total "}
                            {/* {flgSortMarks === 3 ?
                                <span title="edit" className="glyphicon glyphicon-triangle-bottom"
                                    onClick={() => toggleMarksSorting(2)}></span>
                                :
                                <span title="edit" className="glyphicon glyphicon-triangle-top"
                                    onClick={() => toggleMarksSorting(3)}></span>
                            } */}
                        </th>
                        <th>%</th>
                        <th>Grade</th>
                        <th>Rank</th>
                    </tr>
                </thead>
            </table>
            <div className="tableBodyBorder" style={{ minHeight: "15vh", maxHeight: '65vh', overflowY: 'scroll' }} >
                <table className='tableLayout'>
                    {colgrp}
                    <tbody key={theKey}>
                        {lstStudentScoreFiltered && lstStudentScoreFiltered ?
                            lstStudentScoreFiltered.map((obj, idx) => {
                                return <tr key={idx} id={obj.studentId}
                                    onClick={(e) => props.setStudentId(obj.studentId)} className={obj.studentId === props.studentId ? "selected" : ""}
                                >
                                    <td className="no-display">{obj.studentId}</td>
                                    <td className="text-left">{obj.student}</td>
                                    <td>{obj.rollNo}</td>
                                    <td className="text-center">{obj.enrollmentNo}</td>
                                    <td className="text-left">{obj.father}</td>
                                    {obj.lstScore.map((subobj, idx) => {
                                        return <td className="text-center" key={idx}>
                                            <input
                                                maxLength="5"
                                                style={{ margin: '0 auto', textTransform: 'uppercase', width: '98%', textAlign: 'center', color:(subobj == "AB" || subobj == "Ab" || subobj == "aB"  || subobj == "ab"  ) ? "red" :"" }}
                                                type="text"
                                                className="form-control"
                                                onClick={(e) => e.target.select()}
                                                defaultValue={subobj}
                                                onKeyDown={handleKeyDown}
                                                tabIndex={idx + 1}
                                                onChange={(e) => handleStudentScoreChange(e, lstSubject[idx].subId, lstTmpSubject[idx].subType, obj.studentId, e.target.value)}
                                            />
                                        </td>
                                    })}
                                    {/* <td className="text-center">
                                    <input onKeyDown={(e) => alphaOnly(e)} maxLength="1" style={{ margin: '0 auto', textTransform: 'uppercase', width: 25, textAlign: 'center' }} type="text" className="form-control" onClick={(e) => e.target.select()} defaultValue={obj.attendance} onChange={(e) => handleStudentScoreChange(e, obj.studentId, e.target.value)} /></td>
                                <td className="text-center">{obj.attendance === 'A' && obj.informedOn === '-' && obj.informedOn}</td> */}
                                    <td className="text-right">{lstTmpStudent && lstTmpStudent[idx] && lstTmpStudent[idx].total}</td>
                                    <td className="text-right">{lstTmpStudent && lstTmpStudent[idx] && lstTmpStudent[idx].pct}</td>
                                    <td className="text-center">{lstTmpStudent && lstTmpStudent[idx] && lstTmpStudent[idx].grade}</td>
                                    <td className="text-center">{lstTmpStudent && lstTmpStudent[idx] && lstTmpStudent[idx].grade !== "F" ? getRankById(obj.studentId) : "-"}</td>
                                </tr>
                            }) : null
                        }
                    </tbody>
                </table>
            </div>

            <div className="col-sm-12 form-group" style={{ pointerEvents: submitBtnLoader === "none" ? "auto" : "none" }}>
                <div className="pull-left" style={{ width: '29%', height: 50, display: 'table' }}></div>
                <div className="" style={{ width: '55%', display: 'table' }}>
                    {lstSubject.map((obj, idx) => {
                        return <div
                            sub={obj.sub}
                            style={{ display: 'table-cell', width: `${((54 / lstSubject.length) - 4) * 2}%` }}>
                            <button style={{ width: '88%' }} type="button" className="btn btn-info text-center" onClick={(e) => saveScore(obj.subId, e)}>Save</button></div>
                    })}
                </div>
            </div>
        </form>
    )
}

export default StudentSubScoreTable