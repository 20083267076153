import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import Header from 'common/js/header/header.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'common/css/master.css';
import { getLstStudentSummary, getLstMonth, getLstClsSection } from './summaryServices.js';

const StudentSummary = () => {
    const [state, setState] = useState({
        loader: 'none'
        , opacity: 1
    });
    const [defaultMonth, setDefaultMonth] = useState('');
    const [defaultClsSection, setDefaultClsSection] = useState(0)
    const [lstMonth, setLstMonth] = useState([]);
    const [lstClsSection, setLstClsSection] = useState([]);
    const [lstStudentSummary, setLstStudentSummary] = useState([]);
    const [totalExp, setTotalExp] = useState(0);
    const [totalRec, setTotalRec] = useState(0);
    const [totalDiff, setTotalDiff] = useState(0);
    const [totalFeeHead, setTotalFeeHead] = useState({})
    const [lstFeeHeads, setLstFeeHeads] = useState([]);
    const mthId = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const params = new URLSearchParams(window.location.search);
    useEffect(() => {
        const d = new Date();
        let month = d.getMonth();
        var mon = mthId[month]
        // console .log('get monId' + mon)
        var sec = params.get('clsSectionId');
        // console .log('mon = ' + mon + ", clsSectionId = " + sec);
        if (!!params.get("month")) {
            setDefaultMonth(params.get("month"));
            mon = params.get("month");
        } else {
            setDefaultMonth(mon);
        }
        setDefaultClsSection(sec);
        getMonth();
        getClsSection();
        if (sec && mon) {
            getStudentSummary(sec, mon);
        }

    }, []);
    function getClsSection() {
        getLstClsSection(handleClsSectionResponse);
        // getFakeLstClsSection()
    }
    function getFakeLstClsSection() {
        var lst = [{
            clsSectionId: 11
            , clsSection: 'V-A'
        }, {
            clsSectionId: 12
            , clsSection: 'V-B'
        }]
        setLstClsSection(lst);
    }
    function getMonth() {
        getLstMonth(handleLstMonthResponse);
        // setLstMonth(getFakeLstMonth());
    }

    function handleLstMonthResponse(data) {
        var obj = data;
        setState({ loader: 'none', opacity: 1 });
        if (obj.status == 'Success') {
            // console .log(obj)
            setLstMonth(obj.lstMonth);
        } else if (obj.status == 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }

    function getFakeLstMonth() {
        var lstMonth = [
            {
                "monthId": 10,
                "month": "January"
            },
            {
                "monthId": 12,
                "month": "February"
            },
            {
                "monthId": 1,
                "month": "March"
            },
            {
                "monthId": 2,
                "month": "April"
            },
            {
                "monthId": 3,
                "month": "May"
            },
            {
                "monthId": 4,
                "month": "June"
            },
            {
                "monthId": 5,
                "month": "July"
            },
            {
                "monthId": 6,
                "month": "August"
            },
            {
                "monthId": 7,
                "month": "September"
            },
            {
                "monthId": 8,
                "month": "October"
            },
            {
                "monthId": 9,
                "month": "November"
            },
            {
                "monthId": 10,
                "month": "December"
            }
        ];
        return lstMonth;
    }
    function handleClsSectionResponse(data) {
        var obj = data;
        setState({ loader: 'none', opacity: 1 });
        if (obj.status == 'Success') {
            // console .log(obj)
            setLstClsSection(obj.lstClsSection);
        } else if (obj.status == 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }
    function getStudentSummary(sectionId, mon) {
        if (sectionId && mon) {
            getLstStudentSummary(sectionId, mon, handleStudentSummaryResponse);
        }
        else if(!sectionId && !mon){
            toast.warn('Please Select class and Month')
        }
        else if(!sectionId){
            toast.warn('Please Select a class')
        }
        else {
            toast.warn('Please Select a Month')
        }
        //getFakeStudentSummary();
    }
    const getFakeStudentSummary = () => {
        var obj = {
            SvcStatus: 'Success'
            , lstStudentSummary: [
                {
                    studentId: 11
                    , student: 'Rahul'
                    , enrollmentNo: 18900475119
                    , exp: 25000000
                    , rec: 20000000
                    , diff: 50000000
                    , fee: {
                        Annual: 16000000, Transport: 2000000, Sports: 1000000
                    }

                }
                , {
                    studentId: 10
                    , student: 'Priya'
                    , enrollmentNo: 18900476119
                    , exp: 6000000
                    , rec: 5000000
                    , diff: 1000000
                    , fee: {
                        Annual: 16000000, Transport: 2000000, Sports: 1000000
                    }
                }
            ], totalExp: 31000000
            , totalRec: 25000000
            , totalDiff: 51000000
            , feeTotal: { Annual: 32000000, Transport: 4000000, Sports: 2000000 }
        };
        setLstStudentSummary(obj.lstStudentSummary);
        // console .log(obj.lstStudentSummary[0].fee)
        setLstFeeHeads(Object.keys(obj.lstStudentSummary[0].fee));
        setState({
            loader: 'none'
            , opacity: 1

        });
        setTotalExp(obj.totalExp);
        setTotalRec(obj.totalRec);
        setTotalDiff(obj.totalDiff);
        setTotalFeeHead(obj.feeTotal);

    }
    const handleMonthChange = async (val) => {
        // console .log("handleMonthChange() called: " + val)
        var mon = val;
        await setDefaultMonth(mon);
        // console .log(mon);
        if (mon == 'Month' || mon == '') {
            toast.warn("Please select the month");
            return;
        }

        if (defaultClsSection == 0) {
            toast.warn("Please select the class");
            return;
        }

        if (mon && defaultClsSection) {
            getStudentSummary(defaultClsSection, mon, handleStudentSummaryResponse);
        }
        else {
            if (mon) {
                toast.warn('Please Select Class')
            }
            else {
                toast.warn('Please Select a Month')
            }
        }
    }
    const handleClsChange = async (e) => {
        var sectionId = e.target.value;
        // var section = e.target.value;
        await setDefaultClsSection(sectionId);
        // console .log("handleClsChange() called")
        // console .log(sectionId);

        if (defaultMonth == 'Month') {
            toast.warn("Please select the month");
            return;
        }
        if (sectionId == 0 || sectionId === 'Class') {
            toast.warn("Please select the class");
            return;
        }
        if (defaultMonth) {
            getStudentSummary(sectionId, defaultMonth, handleStudentSummaryResponse);
        }
        else {
            toast.warn('Please Select a Month')
        }
        // getFakeStudentSummary();

    }
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        // console .log([day, mnth, date.getFullYear()].join("-"));
        return [day, mnth, date.getFullYear()].join("-");
    }
    function handleStudentSummaryResponse(data) {
        var obj = data;
        setState({ loader: 'none', opacity: 1 });
        if (obj.status == 'Success') {
            setLstStudentSummary(obj.lstStudentSummary);
            if (obj.lstStudentSummary.length !== 0)
                setLstFeeHeads(Object.keys(obj.lstStudentSummary[0].fee));
            else
                setLstFeeHeads([]);
            setTotalExp(obj.totalExp);
            setTotalRec(obj.totalRec);
            setTotalDiff(obj.totalDiff);
            setTotalFeeHead(obj.feeTotal);
        } else if (obj.status == 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }
    const navStudentSummary = (e, monthId, month) => {
        e.preventDefault();
        // localStorage.setItem("month", month);
        // localStorage.setItem("monthId", monthId);
        // window.location.href = "/classSummary";
    }
    const getWingId = (wingId) => {
        // console .log('wingId = ' + wingId)
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ display: state.loader, position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%' }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer " style={{ pointerEvents: state.opacity == 1 ? 'auto' : 'none' }}>
                <div className="col-md-12 col-centered">
                    <form className="form form-horizontal col-centered col-sm-7" style={{ width: '57%' }}>
                        <div className="row">
                            <h6 className="col-sm-5 padding-remove" style={{ width: "38%" }}>Student Summary for the month of</h6>
                            <div className="form-group col-sm-2 pull-left" >
                                <select className="form-control" defaultValue={defaultMonth !== "" && defaultMonth}
                                    onChange={(e) => handleMonthChange(e.target.value)}>
                                    <option>Month</option>
                                    {lstMonth && lstMonth.length !== 0 && lstMonth.map((obj, i) => (
                                        <option id={obj.monthId} value={obj.monthId} selected={defaultMonth == obj.monthId}>{obj.month}</option>
                                    ))}
                                </select>
                            </div>
                            <h6 className="col-sm-2 pull-left" style={{ width: "13%" }}>for class</h6>
                            <div className="form-group col-sm-2 pull-left" >
                                <select className="form-control" defaultValue={defaultClsSection !== 0 && defaultClsSection} onChange={(e) => handleClsChange(e)}>
                                    <option>Class</option>
                                    {lstClsSection && lstClsSection.length !== 0 && lstClsSection.map((obj, i) => (
                                        <option key={i} selected={defaultClsSection == obj.clsSectionId} value={obj.clsSectionId} id={obj.clsSectionId}>{obj.clsSection}</option>
                                    ))}
                                </select>
                            </div>

                        </div>
                    </form>
                    <div className="col-md-12">
                        <table className="tableLayout1" id='summaryTableHead'>
                            <colgroup>
                                <col width="40" />
                                <col width="40" />
                                <col width="40" />
                                <col width="40" />
                                <col width="40" />
                                {lstFeeHeads.map((obj, i) => (
                                    <col key={i} width="40" />
                                ))}
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Student</th>
                                    <th>Enrollment No.</th>
                                    <th>Expected</th>
                                    <th>Received</th>
                                    <th>Difference</th>
                                    {lstFeeHeads.map((obj, i) => (
                                        <th key={i}>{obj}</th>
                                    ))}

                                </tr>
                            </thead>
                        </table>
                        <div className="tableDiv">
                            <table id="summaryTable" className="tableLayout">
                                <colgroup>
                                    <col width="40" />
                                    <col width="40" />
                                    <col width="40" />
                                    <col width="40" />
                                    <col width="40" />
                                    {lstFeeHeads.map((obj, i) => (
                                        <col key={i} width="40" />
                                    ))}
                                </colgroup>
                                <tbody id="summaryTableBody">
                                    {lstStudentSummary && lstStudentSummary.map((obj, i) => (
                                        <tr key={i} id={obj.studentId}>
                                            <td className="text-center"
                                                onClick={(e) => navStudentSummary(e, obj.clsSectionId, obj.clsSection)}
                                            >{obj.student}</td>
                                            <td className="text-right">{obj.enrollmentNo}</td>
                                            <td className="text-right">{obj.exp && obj.exp.toLocaleString("en-IN")}</td>
                                            <td className="text-right">{obj.rec && obj.rec.toLocaleString("en-IN")}</td>
                                            <td className="text-right">{obj.diff && obj.diff.toLocaleString("en-IN")}</td>
                                            {lstFeeHeads && lstFeeHeads.map((feeObj, j) => (
                                                <td key={j} className="text-right">{obj.fee[feeObj] ? obj.fee[feeObj].toLocaleString("en-IN") : 0}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {<tr>
                                        <td style={{ fontWeight: 'bold' }} className="text-center">Total</td>
                                        <td style={{ fontWeight: 'bold' }} className="text-right"></td>
                                        <td style={{ fontWeight: 'bold' }} className="text-right">{totalExp && totalExp.toLocaleString("en-IN")}</td>
                                        <td style={{ fontWeight: 'bold' }} className="text-right">{totalRec && totalRec.toLocaleString("en-IN")}</td>
                                        <td style={{ fontWeight: 'bold' }} className="text-right">{totalDiff ? totalDiff.toLocaleString("en-IN") : (totalExp - totalRec).toLocaleString}</td>
                                        {lstFeeHeads && lstFeeHeads.map((feeHdObj, k) => (
                                            <td key={k} style={{ fontWeight: 'bold' }} className="text-right">{totalFeeHead[feeHdObj] && parseInt(totalFeeHead[feeHdObj]).toLocaleString("en-IN")}</td>
                                        ))}

                                    </tr>}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentSummary
