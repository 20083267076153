import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import $ from "jquery";
import axios from 'axios';
import TableHead from 'common/js/tableHead';
import TableBodyDtsheet from './tableBodyDtsheet';

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;


export default class DatesheetTable extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        var wingId = 1;
        var classSectionId = 1;
        var classId = 4;
        this.setState({ loader: 'block', opacity: 0.5 });
        // console .log("subjectTable props in lstSubject: "+this.props.lstSubject);
    }

    clickAlert = (e) => {
        alert("I am working: " + e);
    }


    render() {
        return (
            <>
                <div className="subjectRefresh refreshButton">
                    <span className="glyphicon glyphicon-refresh" title="Refresh Subject" onClick={this.refreshSubject}></span>
                </div>
                <TableHead headCls={"tableLayout1 subjectWrap"}
                    heads={[
                        ["Subject Id", 0, 'no-display'],
                        ["Date", 30, 'br'],
                        ["Time", 30, 'br'],
                        ["Subject", 40, 'br'],
                        ["Teacher", 40, 'br'],
                    ]}
                />

                <div className="datesheetTableDiv" style={{ height: 90, overflowY: "scroll", border: '1px solid #ccc' }}>

                    <TableBodyDtsheet
                        bodyCls={"subjectTableDiv"}
                        tableId="datesheetTable"
                        tbodyId="datesheetTableBody"
                        colswidth={[30, 30, 40, 40]}
                        lstDateSheet={this.props.lstDateSheet}
                    // onChildClick={this.clickAlert}
                    // onDragStarted={(e)=>this.dragStartHandler(e)}
                    />

                </div>
            </>
        )
    }
}