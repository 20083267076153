import axios from 'axios'
import lstModules from 'common/json/lstModules';
var sysopstokenid = localStorage.getItem('sysopstokenid');
let httpURLCommon = lstModules['main'].url + ":" + lstModules["main"].port;

function getLstModeSvc(callback) {
    var obj = { status: "Success", message: "" };
    axios(
        {
            // url: httpURLCommon + "/sms/mst/payment_mode/list",
            url: httpURLCommon + "/sms/sysops/mst/payment_mode/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': sysopstokenid },
            withCredentials: true
        }
    ).then(
        (response) => {
            // obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            obj.lstPaymentMode = response.data.lstPaymentMode;
            // console .log("response",response);
            callback(obj);
        }
    ).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    })

}
function settlePaymentSvc(settlement, cb) {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURLCommon + "/sms/sysops/settlement/settle",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        data: settlement,
        withCredentials: true,
    }).then(
        (response) => {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            cb(obj);
        }
    ).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    })


}
export { getLstModeSvc, settlePaymentSvc }