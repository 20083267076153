import React, { useState, useEffect } from "react";
import lstModules from "common/json/lstModules.json";
import axios from "axios";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";
import {
  getLstCity,
  getLstState,
  getLstLocality,
  getAddressSvc,
  getIDCardDtlsSvc,
  getReportCardDtlsSvc,
  getFeeBookDtlsSvc,
  getLogoSvc,
  getLatLongSvc,
  saveImgLogoSvc,
  saveIdCardSvc,
  saveReportCardSvc,
  saveAddressSvc,
  saveFeebookSvc,
  saveLatLongSvc,
  getEmailServerDtlsSvc,
  saveEmailServerDtlsSvc,
  saveParentAppDtlsSvc,
  getParentAppDtlsSvc
} from "./schoolProfileSvc.js";

var httpURL = lstModules["main"].url + ":" + lstModules["main"].port;
let queryString = require("querystring");

let screenId = 38;
var initSchool = {
  schoolId: 1,
  school: "Springfield Public School",
  loader: "none",
  opacity: 1,
};
export default function SchoolProfile() {
  const [cityId, setCityId] = useState(0);
  const [localityId, setLocalityId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [address1, setAddress1] = useState("");
  const [pin, setPin] = useState("");
  const [school, setSchool] = useState("");
  const [lstState, setLstState] = useState([]);
  const [lstCity, setLstCity] = useState([]);
  const [lstLocality, setLstLocality] = useState([]);
  const [lstBankState, setLstBankState] = useState([]);
  const [lstBankCity, setLstBankCity] = useState([]);
  const [lstBankLocality, setLstBankLocality] = useState([]);
  const [imgLogo, setImgLogo] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [mobileReportCard, setMobileReportCard] = useState("");
  const [addressReportCard, setAddressReportCard] = useState("");
  const [affiliationReportCard, setAffiliationReportCard] = useState("");
  const [bankCityId, setBankCityId] = useState(0);
  const [bankLocalityId, setBankLocalityId] = useState(0);
  const [bankStateId, setBankStateId] = useState(0);
  const [bankAddress1, setBankAddress1] = useState("");
  const [bankPin, setBankPin] = useState("");
  const [bank, setBank] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [reAccountNo, setReAccountNo] = useState("");
  const [accountName, setAccountName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [corpCode, setCorpCode] = useState("");
  const [latLong, setLatLong] = useState("");
  const [state, setState] = useState({
    wingId: "",
    loader: "none",
    opacity: 1,
    tokenid: localStorage.getItem("tokenid"),
  });
  const [schoolProfile, setSchoolProfile] = useState(initSchool);

  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isPasswordVisible, setIsPaswordVisible] = useState(false);

  const [flgSystemGenerated, setFlgSystemGenerated] = useState(true);
  const [defaultPwd, setDefaultPwd] = useState('');

  function getStateList() {
    showLoader();

    getLstState(handleLstStateResponse);
    getLogoSvc(handleLogoResponse);

    // setSchoolProfile(initSchool);
  }

  function handleLogoResponse(imageUrl) {
    var img = document.querySelector("#logo_img");
    img.src = imageUrl;
  }
  function showLoader() {
    setState({ ...state, loader: "block", opacity: 0.5 });
  }
  function hideLoader() {
    setState({ ...state, loader: "none", opacity: 1 });
  }

  async function handleLstStateResponse(data) {
    // console .log(data);
    var obj = data;
    getIDCardDtlsSvc(handleIDCardResponse);
    getReportCardDtlsSvc(handleReportCardResponse);
    getFeeBookDtlsSvc(handleFeebookResponse);
    getAddressSvc(cbAddressResponse);
    getLatLongSvc(cbLatLongResponse);
    hideLoader();
    if (obj.status === "Success") {
      setLstState(obj.lstState);
      setLstBankState(obj.lstState);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  useEffect(() => {
    getStateList();
    getEmailServerDtlsSvc(handleEmailServerDtlsResponse);
    getParentAppDtlsSvc(handleParentAppDtlsResponse);
  }, []);
  const cbLatLongResponse = (obj) => {
    hideLoader();
    if (obj.status === "Success") {
      setLatLong(obj.latLong);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  };
  const cbAddressResponse = (obj) => {
    hideLoader();
    if (obj.status === "Success") {
      setSchool(obj.addr.name);
      setAddress1(obj.addr.address.address1);
      setPin(obj.addr.address.pin);
      var pStateId = obj.addr.address.stateId;
      var pCityId = obj.addr.address.cityId;
      var pLocalityId = obj.addr.address.localityId;
      setStateId(pStateId);
      getLstCity(pStateId, handleLstCityResponse, pCityId);
      getLstLocality(pCityId, handleLocalityResponse, pLocalityId);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  };
  const handleIDCardResponse = (obj) => {
    hideLoader();
    if (obj.status === "Success") {
      setPhone(obj.idCard.phone);
      setWhatsapp(obj.idCard.whatsapp);
      setEmail(obj.idCard.email);
      setWebsite(obj.idCard.website);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  };

  const handleReportCardResponse = (obj) => {
    // console .log("handleReportCardResponse called, obj", obj);
    hideLoader();
    if (obj.status === "Success") {
      setMobileReportCard(obj.reportCard.phone);
      setAffiliationReportCard(obj.reportCard.affiliation);
      setAddressReportCard(obj.reportCard.address);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  };

  const handleFeebookResponse = (obj) => {
    hideLoader();
    if (obj.status === "Success") {
      setBank(obj.feebookObj.bankName);
      setBankAddress1(obj.feebookObj.bankAddress.address1);
      setBankPin(obj.feebookObj.bankAddress.pin);
      var pStateId = obj.feebookObj.bankAddress.stateId;
      var pCityId = obj.feebookObj.bankAddress.cityId;
      var pLocalityId = obj.feebookObj.bankAddress.localityId;
      setBankStateId(pStateId);
      getLstCity(pStateId, handleLstBankCityResponse, pCityId);
      getLstLocality(pCityId, handleBankLocalityResponse, pLocalityId);
      setAccountName(obj.feebookObj.actName);
      setAccountNo(obj.feebookObj.actNo);
      setCorpCode(obj.feebookObj.corpCode);
      setContactPerson(obj.feebookObj.contactPerson);
      setContactNo(obj.feebookObj.contactNo);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  };

  const changeLocality = (e) => {
    setLocalityId(e.target.value);
  };
  const changeBankLocality = (e) => {
    setBankLocalityId(e.target.value);
  };
  const changeCity = (e) => {
    var pCityId = e.target.value;
    setCityId(pCityId);
    getLstLocality(pCityId, handleLocalityResponse, 0);
  };
  const changeBankCity = (e) => {
    var pCityId = e.target.value;
    setBankCityId(pCityId);
    getLstLocality(pCityId, handleBankLocalityResponse, 0);
  };
  const changeState = (e) => {
    var pStateId = e.target.value;
    setStateId(pStateId);
    getLstCity(pStateId, handleLstCityResponse, 0);
  };
  const changeBankState = (e) => {
    var pStateId = e.target.value;
    setBankStateId(pStateId);
    getLstCity(pStateId, handleLstBankCityResponse, 0);
  };
  function handleLstCityResponse(data, pCityId) {
    var obj = data;
    hideLoader();
    if (obj.status === "Success") {
      setLstCity(obj.lstCity);
      setCityId(pCityId);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  async function handleLstBankCityResponse(data, pCityId) {
    var obj = data;
    hideLoader();
    if (obj.status === "Success") {
      await setLstBankCity(obj.lstCity);
      setBankCityId(pCityId);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  function handleLocalityResponse(data, pLocalityId) {
    var obj = data;
    if (data.status === "Success") {
      setLstLocality(obj.lstLocality);
      setLocalityId(pLocalityId);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  function handleBankLocalityResponse(data, pLocalityId) {
    var obj = data;
    if (data.status === "Success") {
      setLstBankLocality(obj.lstLocality);
      setBankLocalityId(pLocalityId);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  const handleParentAppDtlsResponse = (data) => {
    hideLoader();
    var obj = data;
    if (data.status === "Success") {
      setFlgSystemGenerated(obj.parentDtls.systemGeneratedPwd);
      setDefaultPwd(obj.parentDtls.defaultPwd);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  const handleEmailServerDtlsResponse = (data) => {
    hideLoader();
    var obj = data;
    if (data.status === "Success") {
      setHost(obj.mailDtls.host);
      setPort(obj.mailDtls.port);
      setMail(obj.mailDtls.email);
      setPassword(obj.mailDtls.password);
      setDisplayName(obj.mailDtls.displayName);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }

  function setProfile(profile) {
    setState({
      ...state,
      profileObj: profile,
      wingId: profile.wingId,
      wing: profile.wing,
    });
  }

  function getWingId(wingId) {
    // console .log("wingId" + wingId);
  }
  const onLogoChange = (e) => {
    // console .log(e.target.files[0].name);
    // console .log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setImgLogo(URL.createObjectURL(e.target.files[0]));
      showLoader();
      saveImgLogoSvc(e.target.files[0], cbSaveimgLogoResponse);
    }
  };
  function cbSaveimgLogoResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    hideLoader();
  }

  function handleLogoClick() {
    // console .log(document.getElementById("img_logo"));
    // document.getElementById("img_logo").dispatchEvent(new Event('click'));
    document.getElementById("img_logo").click();
  }
  function saveIdCardDtls(e) {
    e.preventDefault();
    var idCard = {
      phone: phone,
      email: email,
      website: website,
      whatsapp: whatsapp,
    };
    saveIdCardSvc(idCard, handleIdCardSaveResponse);
  }
  function handleIdCardSaveResponse(obj) {
    hideLoader();
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  function saveReportCardDtls(e) {
    e.preventDefault();
    var reportCard = {
      phone: mobileReportCard,
      address: addressReportCard,
      affiliation: affiliationReportCard,
    };
    saveReportCardSvc(reportCard, handleReportCardSaveResponse);
  }
  function handleReportCardSaveResponse(obj) {
    hideLoader();
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  function saveFeebookDtls(e) {
    e.preventDefault();
    if (reAccountNo !== accountNo) {
      toast.error("Account No should match");
      return;
    }
    var bankAddress = {
      cityId: bankCityId,
      stateId: bankStateId,
      pin: bankPin,
      localityId: bankLocalityId,
      address1: bankAddress1,
    };
    var feebookObj = {
      bankAddress: bankAddress,
      bankName: bank,
      actName: accountName,
      actNo: accountNo,
      corpCode: corpCode,
      contactPerson: contactPerson,
      contactNo: contactNo,
    };
    var formData = new FormData();
    formData.append("feebookObj", JSON.stringify(feebookObj));

    saveFeebookSvc(formData, handleSaveFeebookResponse);
  }

  const saveEmailServerDtls = () => {
    showLoader();
    var mailDtlsObj = {
      "host": host,
      "port": port,
      "email": mail,
      "password": password,
      "displayName": displayName,
    };
    // console .log("save button in email server details called(saveEmailServerDtls)");
    saveEmailServerDtlsSvc(mailDtlsObj, handleSaveEmailServerDtlsResponse);
  }

  function handleSaveFeebookResponse(obj) {
    hideLoader();
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  const handleSaveEmailServerDtlsResponse = (data) => {
    // console .log("obj return in handleBankLocalityResponse", data);
    var obj = data;
    hideLoader();
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }
  const onError = () => {
    setImgLogo(window.appURL + "/no-image.png");
  };
  function saveAddress(e) {
    e.preventDefault();
    var address = {
      stateId: stateId,
      cityId: cityId,
      localityId: localityId,
      address1: address1,
      pin: pin,
    };
    var addr = { name: school, address: address };
    // console .log(addr);
    saveAddressSvc(addr, handleIdCardSaveResponse);
  }
  function saveLatLong(e) {
    e.preventDefault();
    saveLatLongSvc(latLong, handleLatLongSaveResponse);
  }
  function handleLatLongSaveResponse(obj) {
    hideLoader();
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }

  const togglePassword = () => {
    setIsPaswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
  };

  const saveParentAppDtls = () => {
    showLoader();
    var parentAppObj = {
      "systemGeneratedPwd": flgSystemGenerated,
      "defaultPwd": defaultPwd
    };
    // console .log("save button in email server details called(saveEmailServerDtls)");
    saveParentAppDtlsSvc(parentAppObj, handleSaveParentAppDtlsResponse);
  }

  function handleSaveParentAppDtlsResponse(obj) {
    // console .log("handleSaveParentAppDtlsResponse called, obj", obj);
    hideLoader();
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }

  const handleFlgSystemGenerated = (flg) => {
    // console .log("handleFlgSystemGenerated called, flg", flg)
    setFlgSystemGenerated(flg);
    if (flg) {
      setDefaultPwd('');
    } else {
      setDefaultPwd('1234567890');
    }
  }


  return (
    <div style={{ height: "100%" }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: state.loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="mainContainer" style={{ opacity: state.opacity }}>
        <div className="col-sm-6 col-centered" >
          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
          >
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}
            >
              <h4>School Address</h4>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="name">
                Name
              </label>
              <div className="form-group col-sm-9">
                <input
                  className="form-control"
                  name="school"
                  defaultValue={school}
                  onChange={(e) => setSchool(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-offset-2 col-sm-3">
                <select
                  value={stateId}
                  onChange={(e) => changeState(e)}
                  className="form-control"
                  style={{ width: "100%" }}
                  id="stateCmb"
                  name="stateId"
                >
                  <option>State</option>

                  {lstState &&
                    lstState.map(function (obj, i) {
                      return (
                        <option key={i} value={obj.stateId}>
                          {obj.state}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-offset-2 col-sm-3">
                <select
                  value={cityId}
                  onChange={(e) => changeCity(e)}
                  className="form-control"
                  style={{ width: "100%" }}
                  id="cityCmb1"
                  name="cityId"
                >
                  <option value="0">City</option>
                  {lstCity &&
                    lstCity.map(function (obj, i) {
                      return (
                        <option key={i} value={obj.cityId}>
                          {obj.city}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-offset-2 col-sm-3">
                <select
                  value={localityId}
                  onChange={(e) => changeLocality(e)}
                  className="form-control"
                  style={{ width: "100%" }}
                  id="localityCmb"
                  name="localityId"
                >
                  <option value="0">Locality</option>
                  {lstLocality &&
                    lstLocality.map(function (obj, i) {
                      return (
                        <option key={i} value={obj.localityId}>
                          {obj.locality}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="pin">
                Pin
              </label>
              <div className="form-group col-sm-2">
                <input
                  className="form-control"
                  name="pin"
                  defaultValue={pin}
                  onChange={(e) => setPin(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="address1">
                Address
              </label>
              <div className="form-group col-sm-9">
                <input
                  className="form-control"
                  name="address1"
                  defaultValue={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  onClick={(e) => saveAddress(e)}
                  title="Save"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
          >
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}
            >
              <h4>Coordinates</h4>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="coordinates">
                Coordinates
              </label>
              <div className="form-group col-sm-9">
                <input
                  className="form-control"
                  name="coordinates"
                  value={latLong}
                  onChange={(e) => setLatLong(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  onClick={(e) => saveLatLong(e)}
                  title="Save"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
          >
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}
            >
              <h4>Logo</h4>
            </div>
            <div className="row" style={{ background: "transparent" }}>
              <label className="col-sm-2 control-label">Logo</label>
              <div
                style={{ padding: 0, display: "flex", width: 100, height: 100 }}
                className="form-group col-sm-3"
              >
                <input
                  docid="3"
                  type="file"
                  id="img_logo"
                  name="img"
                  onChange={onLogoChange}
                  className="hidden"
                  accept="image/*"
                />
                <img
                  style={{
                    display: "block",
                    width: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    border: 0,
                    objectFit: "fill",
                  }}
                  id="logo_img"
                  src={imgLogo}
                  alt="img"
                  onClick={(e) => handleLogoClick(e)}
                  onError={(e) => onError()}
                />
              </div>
              <label className="col-sm-12" style={{ fontSize: "1rem" }}>
                Logo should preferably be a square image
              </label>
            </div>
          </form>
          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}>
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}>
              <h4>Student ID Card Details</h4>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="phone">
                Phone
              </label>
              <div className="form-group col-sm-3">
                <input
                  className="form-control"
                  name="phone"
                  defaultValue={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="whatsapp">
                Whatsapp
              </label>
              <div className="form-group col-sm-3">
                <input
                  className="form-control"
                  name="whatsapp"
                  defaultValue={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="email">
                Email
              </label>
              <div className="form-group col-sm-5">
                <input
                  className="form-control"
                  name="email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="website">
                WebSite
              </label>
              <div className="form-group col-sm-5">
                <input
                  className="form-control"
                  name="website"
                  defaultValue={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  title="Save"
                  onClick={(e) => saveIdCardDtls(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}>
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}>
              <h4>Student Report Card Details</h4>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="phone">
                Phone No
              </label>
              <div className="form-group col-sm-3">
                <input
                  className="form-control"
                  name="phone no"
                  defaultValue={mobileReportCard}
                  onChange={(e) => setMobileReportCard(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="whatsapp">
                Affiliation No.
              </label>
              <div className="form-group col-sm-3">
                <input
                  className="form-control"
                  name="Affiliation"
                  defaultValue={affiliationReportCard}
                  onChange={(e) => setAffiliationReportCard(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" htmlFor="website">
                Address
              </label>
              <div className="form-group col-sm-10">
                <input
                  className="form-control"
                  name="report card address"
                  defaultValue={addressReportCard}
                  onChange={(e) => setAddressReportCard(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  title="Save"
                  onClick={(e) => saveReportCardDtls(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
          >
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}
            >
              <h4>Fee Book Details</h4>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="name">
                Bank Name
              </label>
              <div className="form-group col-sm-9">
                <input
                  className="form-control"
                  name="school"
                  defaultValue={bank}
                  onChange={(e) => setBank(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="state">
                State
              </label>
              <div className="form-group col-sm-3">
                <select
                  value={bankStateId}
                  onChange={(e) => changeBankState(e)}
                  className="form-control"
                  style={{ width: "100%" }}
                  id="stateCmb"
                  name="stateId"
                >
                  <option>State</option>

                  {lstState &&
                    lstState.map(function (obj, i) {
                      return (
                        <option key={i} value={obj.stateId}>
                          {obj.state}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="city">
                City
              </label>
              <div className="form-group col-sm-3">
                <select
                  value={bankCityId}
                  onChange={(e) => changeBankCity(e)}
                  className="form-control"
                  style={{ width: "100%" }}
                  id="cityCmb1"
                  name="cityId"
                >
                  <option value="0">City</option>
                  {lstBankCity &&
                    lstBankCity.map(function (obj, i) {
                      return (
                        <option key={i} value={obj.cityId}>
                          {obj.city}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="locality">
                Locality
              </label>
              <div className="form-group col-sm-4">
                <select
                  value={bankLocalityId}
                  onChange={(e) => changeBankLocality(e)}
                  className="form-control"
                  style={{ width: "100%" }}
                  id="localityCmb"
                  name="localityId"
                >
                  <option value="0">Locality</option>
                  {lstBankLocality &&
                    lstBankLocality.map(function (obj, i) {
                      return (
                        <option key={i} value={obj.localityId}>
                          {obj.locality}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="pin">
                Pin
              </label>
              <div className="form-group col-sm-2">
                <input
                  className="form-control"
                  name="pin"
                  defaultValue={pin}
                  onChange={(e) => setBankPin(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="address1">
                Address
              </label>
              <div className="form-group col-sm-9">
                <input
                  className="form-control"
                  name="address1"
                  defaultValue={bankAddress1}
                  onChange={(e) => setBankAddress1(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="acctNo">
                Account No.
              </label>
              <div className="form-group col-sm-3">
                <input
                  className="form-control"
                  name="acctNo"
                  defaultValue={accountNo}
                  onChange={(e) => setAccountNo(e.target.value)}
                />
              </div>
              <label
                className="control-label col-sm-3 padding"
                htmlFor="reAcctNo"
              >
                Re-Enter Account No.
              </label>
              <div className="form-group col-sm-3">
                <input
                  type="password"
                  onPaste={(e) => e.preventDefault()}
                  onDrop={(e) => e.preventDefault()}
                  autoComplete="off"
                  className="form-control"
                  name="reAcctNo"
                  defaultValue={reAccountNo}
                  onChange={(e) => setReAccountNo(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="accountName">
                Account Name
              </label>
              <div className="form-group col-sm-4 ">
                <input
                  className="form-control"
                  name="accountName"
                  defaultValue={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="corpCode">
                Corporate Code
              </label>
              <div className="form-group col-sm-4">
                <input
                  className="form-control"
                  name="corpCode"
                  defaultValue={corpCode}
                  onChange={(e) => setCorpCode(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="contactPerson">
                Contact Person
              </label>
              <div className="form-group col-sm-4">
                <input
                  className="form-control"
                  name="contactPerson"
                  defaultValue={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-3" htmlFor="contactNo">
                Contact No.
              </label>
              <div className="form-group col-sm-3">
                <input
                  className="form-control"
                  name="contactNo"
                  defaultValue={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  title="Save"
                  onClick={(e) => saveFeebookDtls(e)}
                >
                  {" "}
                  Save
                </button>
              </div>
            </div>
          </form>
          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
          >
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}
            >
              <h4>Email Server Details</h4>
            </div>
            <div className="row">
              <label className="control-label col-sm-2">
                Host
              </label>
              <div className="form-group col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  name="host"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                />
              </div>
              <label
                className="control-label col-sm-2 text-right"
                htmlFor="port"
              >
                Port
              </label>
              <div className="form-group col-sm-2">
                <input
                  type="text"
                  className="form-control"
                  name="port"
                  value={port}
                  onChange={(e) => setPort(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2" >
                Email
              </label>
              <div className="form-group col-sm-4">
                <input
                  type="email"
                  className="form-control"
                  name="mail"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2">
                Password
              </label>
              <div className="form-group col-sm-4">
                <span  >
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className="form-control"
                    name="mail"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <span
                    // style={{paddingRight:10}}
                    onClick={togglePassword}
                    className={isPasswordVisible ? "fa fa-fw fa-eye-slash field_icon" : "fa fa-fw fa-eye field_icon"}
                  ></span> */}
                </span>

              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-2">
                Display Name
              </label>
              <div className="form-group col-sm-5">
                <input
                  type="text"
                  className="form-control"
                  name="displayName"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  title="Save"
                  onClick={(e) => saveEmailServerDtls(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          <form
            className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
          >
            <div
              className="EnquiryHead col-centered"
              style={{
                fontWeight: "bold",
              }}
            >
              <h4>Parent App Details</h4>
            </div>
            <div className="row">
              <label className="col-sm-5 control-label text-left">System Generated Password</label>
              <div className="col-sm-1 form-group text-left">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={flgSystemGenerated}
                  onChange={(event) => { handleFlgSystemGenerated(event.target.checked) }}
                />
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-5 text-left">
                Password
              </label>
              <div className="form-group col-sm-4 text-left">
                <span  >
                  <input
                    type="text"
                    className="form-control"
                    name="pwd"
                    value={defaultPwd}
                    onChange={(e) => setDefaultPwd(e.target.value)}
                    disabled={flgSystemGenerated}
                  />
                </span>

              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-11">
                <button
                  className="pull-right btn btn-info"
                  title="Save"
                  onClick={(e) => saveParentAppDtls(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
