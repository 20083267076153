import axios from "axios";
import lstModules from "common/json/lstModules.json";

var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;
var schoolId = 77; //localStorage.getItem("schoolId");
var tokenid = localStorage.getItem("tokenid");

const getGroupListSvc = (cb) => {
  var obj = { status: "Success", message: "" };
  obj.lstGroup = [];
  // console .log(httpSchoolURL + "/schools/mst/facility/group/list");
  axios({
    url: httpSchoolURL + "/schools/mst/facility/group/list",
    method: "POST",
    params: { schoolId: schoolId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console .log(data);
      obj.status = data.SvcStatus;

      if (data.SvcStatus === "Success") {
        obj.lstGroup = data.lstFacilityGrp;
      } else {
        obj.message = data.SvcMsg;
      }

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
};

const getFacListSvc = (pGroupId, cb) => {
  var obj = { status: "Success", message: "" };
  obj.lstFacility = [];
  // console .log(httpSchoolURL + "/schools/mst/facility/list");
  axios({
    url: httpSchoolURL + "/schools/mst/facility/list",
    method: "POST",
    params: { schoolId: schoolId, facGroupId: pGroupId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console .log(data);
      obj.status = data.SvcStatus;

      if (data.SvcStatus === "Success") {
        obj.lstFacility = data.lstFacility;
      } else {
        obj.message = data.SvcMsg;
      }

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
};

function saveFacListSvc(pFac, cb) {
  var obj = { status: "Success", message: "" };
  var formData = new FormData();
  formData.append("facility", JSON.stringify(pFac));
  axios({
    url: httpSchoolURL + "/schools/admin/facility/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      obj.status = data.SvcStatus;

      obj.message = data.SvcMsg;

      cb(obj);
    })
    .catch((error) => {
      console.error(error);
    });
}
export { getGroupListSvc, getFacListSvc, saveFacListSvc };
