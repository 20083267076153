
import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, Text, View, Image, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styles, scoreSectionStyle } from './reportCardStyles.js';
import ScholasticSection from './schoSection.js';
import CoScholasticSection from './coSchoSection.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const ScoreSection = (props) => {
    const { scoreSection } = props;

    useEffect(async () => {
        // console .log("scoreSection.js called");

    }, [scoreSection]);


    return (
        <View style={scoreSectionStyle.container}>
            <View style={scoreSectionStyle.scho_section_header}>
                <Text style={scoreSectionStyle.section_header_text}>SCHOLASTIC AREAS</Text>
            </View>
            <View style={scoreSectionStyle.marks_section}>
                <View style={scoreSectionStyle.scho_section}>
                    {
                        !!scoreSection.schoScore ?
                            <ScholasticSection
                                schoScore={scoreSection.schoScore}
                            />
                            : null
                    }
                </View>
                <View style={scoreSectionStyle.coscho_section}>
                    <CoScholasticSection
                        coschoScore={scoreSection.coschoScore}
                    />
                </View>
            </View >
        </View >
    );
}

export { ScoreSection };