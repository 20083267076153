import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import Enquiry from './enquiry';
import { getLstWard } from './mainScreenServices.js';
// import { getLstWard, getLstEnquiry, getLstSchool, getTestSlotDtls, getIntSlotDtls, getAdmDtls, payRegFee } from './mainScreenServices'
import MenuTabList from './menuTabList.js';
import WardTabList from './wardTabList.js';
// import HeaderDtTab from './headerDtTab';
import '../css/mainScreen.css';

var lstWardTab = [
    { wardId: 1, ward: 'Neha', enrolmentNo: 19987652340 }
    , { wardId: 2, ward: 'Karishma', enrolmentNo: 29987652340 }
    , { wardId: 3, ward: 'Nima', enrolmentNo: 39987652340 }
    , { wardId: 4, ward: 'Rekha', enrolmentNo: 49987652340 }
    , { wardId: 5, ward: 'Jaya', enrolmentNo: 59987652340 }
    , { wardId: 6, ward: 'Sushma', enrolmentNo: 69987652340 }
];
var lstAllMenuTab = [
    { menuTabId: 1, menuTab: 'Enquiry' }
    , { menuTabId: 2, menuTab: 'Fee' }
    , { menuTabId: 3, menuTab: 'Events' }
    , { menuTabId: 4, menuTab: 'Diary' }
    , { menuTabId: 5, menuTab: 'Time Table' }
    , { menuTabId: 6, menuTab: 'Results' }
    , { menuTabId: 7, menuTab: 'Profile' }
];
const MainScreen = () => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [state, setState] = useState(initialState)
    const [relationId, setRelationId] = useState(10)
    const [enquiryExists, setEnquiryExists] = useState(true)
    // const [wardExists, setWardExists] = useState(localStorage.getItem("wardExists"))
    const [wardExists, setWardExists] = useState(false)
    const [defaultScreen, setDefaultScreen] = useState(0)
    const [lstWard, setLstWard] = useState(lstWardTab)
    const [lstMenuTab, setLstMenuTab] = useState(lstAllMenuTab)
    const [selWardId, setSelWardId] = useState(lstWardTab[0].wardId);
    const [selMenuTabId, setSelMenuTabId] = useState(lstMenuTab[0].menuTabId);
    useEffect(() => {
        setWard();
        if (relationId == 10) {
            if (wardExists) {
                setLstWard(lstWardTab);
                setLstMenuTab(lstAllMenuTab)
                // setSelWardId(lstWardTab[0].wardId)
                // getLstWard()
                if (!enquiryExists) {
                    //defaultScreen = "New Enquiry";
                } else {
                    //defaultScreen = "Enquiry List";
                }
            } else {
                //defaultScreen = "/";//slider-add ward
            }
        }

    }, []);
    function setWard() {
        // console .log("setWard()...")
        getLstWard(handleWardResponse);
    }
    function handleWardResponse(data) {
        var obj = data;
        if (obj.SvcStatus === 'Success') {
            setLstWard(obj.lstWard);
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }
    const getFakeLstWard = () => {
        var lstWardTab = [
            { wardId: 1, ward: 'Neha', enrolmentNo: 1 }
            , { wardId: 2, ward: 'Karishma', enrolmentNo: 2 }
            , { wardId: 3, ward: 'Nima', enrolmentNo: 3 }
            , { wardId: 4, ward: 'Rekha', enrolmentNo: 4 }
            , { wardId: 5, ward: 'Jaya', enrolmentNo: 5 }
            , { wardId: 6, ward: 'Sushma', enrolmentNo: 6 }

        ]
        return lstWardTab;
    }
    const getLstMenuTab = () => {
        var lstMenuTab = [
            { menuTabId: 1, menuTab: 'Enquiry' }
            , { menuTabId: 2, menuTab: 'Fee' }
            , { menuTabId: 3, menuTab: 'Events' }
            , { menuTabId: 4, menuTab: 'Diary' }
            , { menuTabId: 5, menuTab: 'Time Table' }
            , { menuTabId: 6, menuTab: 'Results' }
            , { menuTabId: 7, menuTab: 'Profile' }
        ]
        return lstMenuTab;
    }
    function onWardTabClick(wId) {
        setSelWardId(wId);
    }
    function onMenuTabClick(mId) {
        setSelMenuTabId(mId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ marginTop: 15, opacity: state.opacity, height: '86vh', border: '1px solid #bbbbbb', margin: '1%' }}>
                {/* <div className="" style={{ width: '100%' }}> */}
                {lstWard && lstWard.length !== 0 && <WardTabList lstWard={lstWard} selWardId={selWardId} onWardTabClick={onWardTabClick} />}
                {/* </div> */}
                {/* <div className="" style={{ width: '100%' }}> */}
                {lstMenuTab && lstMenuTab.length !== 0 && <MenuTabList
                    lstMenuTab={lstMenuTab} selMenuTabId={selMenuTabId} onMenuTabClick={onMenuTabClick} />}
                {/* </div> */}
                <div className="" style={{ width: '100%' }}>
                    {selMenuTabId === 1 && <span>Enquiry</span>}
                    {selMenuTabId === 2 && <span>Fee</span>}
                    {selMenuTabId === 3 && <span>Events</span>}
                    {selMenuTabId === 4 && <span>Diary</span>}
                    {selMenuTabId === 5 && <span>TimeTable</span>}
                    {selMenuTabId === 6 && <span>Results</span>}
                    {selMenuTabId === 7 && <span>Profile</span>}

                </div>
            </div>
        </div>
    )
}
export default MainScreen;