import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from 'common/js/header/header';

import IdCardSlider from 'sms/studentsNew/idCardPDF/IdCardSlider';
import StudentTable from './studentTable';
import { getICardSvc } from 'sms/studentsNew/idCardPDF/IdCardSliderSvc';
import { getStudentListSvc, getMstClassListSvc } from './studentsSvc';
import UploadStudent from './uploadStudents';
import ErrorTable from './errorTable';



const Students = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    const [lstClass, setLstClass] = useState([]);
    const [lstStudent, setLstStudent] = useState([]);
    const [lstErrors, setLstErrors] = useState([]);

    const [lstIdCard, setLstIdCard] = useState([]);

    const [schoolId, setSchoolId] = useState(0);
    const [studentId, setStudentId] = useState(0);
    const [sectionId, setSectionId] = useState(0);
    const [academicYearId, setAcademicYearId] = useState(0);

    const [lstSelStudentId, setLstSelStudentId] = useState([]);
    const [lstStudentId, setLstStudentId] = useState([]);
    const [studentCount, setStudentCount] = useState(0);

    const [flgShowError, setFlgShowError] = useState(false);
    const [isIdCardSliderVisible, setIsIdCardSliderVisible] = useState(false);
    const [tableKey, setTableKey] = useState(0);


    useEffect(() => {
        // console .log("Students component called");
        getMstClassList();
    }, [tableKey]);


    const getMstClassList = () => {
        setLoader("block");
        setOpacity(0.5);

        getMstClassListSvc((response) => {
            if (response.SvcStatus === "Success") {
                setLstClass(response.lstClass);
                getStudentList();
            } else {
                toast.error(response.SvcMsg);
                handleServiceError(response.SvcMsg);
            }
            setLoader("none");
            setOpacity(1);
        });
    };

    const getStudentList = () => {
        setLoader("block");
        setOpacity(0.5);

        getStudentListSvc((response) => {
            if (response.SvcStatus === "Success") {
                setLstStudent(response.lstStudents);
                setStudentCount(response.lstStudents.length);
            } else {
                toast.error(response.SvcMsg);
                handleServiceError(response.SvcMsg);
            }
            setLoader("none");
            setOpacity(1);
        });
    };

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const getIdCard = async () => {
        console .log("getIdCard called, lstSelStudentId", lstSelStudentId);
        if (lstSelStudentId) {
            if (lstSelStudentId.length === 0) {
                toast.error("please select some students first!");
                return;
            }
            await setLstStudentId(lstSelStudentId);
            getICardSvc(lstSelStudentId, idCardResponse);
        }
    };

    const idCardResponse = async (data) => {
        console .log('idCardResponse called, response', data);
        if (data.status === "Success") {
            await setLstIdCard(data.lstIdCard);
            openIdCardSlider();
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
    };

    const viewIdCard = async (sId) => {
        console .log("viewIdCard called, sId", sId);
        await setLstStudentId([sId]);
        getICardSvc([sId], idCardResponse);
    }

    const openIdCardSlider = () => {
        console .log("openIdCardSlider called");
        setIsIdCardSliderVisible(true);
    }

    const editStudent = (sId) => {
        // console .log("editStudent called, sId", sId);
        openStudentDetails(sId);
    }

    const addNewStudent = () => {
        // console .log("addNewStudent called");
        openStudentDetails(0);
    }

    const openStudentDetails =  (sId) => {
        // console .log("openStudentDetails called, sId");
        setStudentId(sId);
        const newPageUrl = "/studentDtlsNew?studentId=" + sId;
       window.open(newPageUrl, "");
    }

    const updateKey = () => {
        setTableKey(prevKey => prevKey + 1);
    }

    const closeSlider = () => {
        // console .log("closeSlider called");
        setIsIdCardSliderVisible(false);
    }


    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}
            >
            </div>

            <div className="col-md-11 col-xs-12 col-centered" style={{ marginTop: "15px" }}>
                <div className="form-group">
                    <div className="col-sm-8 padding-remove " >
                        <div className="col-sm-3 padding-remove  pull-right"  >
                            <div className="col-sm-8 padding-remove "  >
                                <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                    <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: 400 }}  >
                                        Students: {studentCount}
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-4 padding-remove">
                                <button
                                    type="button"
                                    title="Print ID Card"
                                    onClick={() => getIdCard()}
                                    className="btn btn-warning pull-right"
                                >
                                    Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12 padding-remove" style={{ marginTop: "10px" }} >
                    {
                        flgShowError ?
                            <div className="col-md-6 col-xs-12 padding-remove" >
                                <ErrorTable
                                    lstErrors={lstErrors}
                                />
                            </div>
                            :
                            <div className="col-md-10 col-xs-12 padding-remove" >
                                <StudentTable
                                    setLoader={setLoader}
                                    setOpacity={setOpacity}
                                    lstClass={lstClass}
                                    lstStudent={lstStudent}
                                    studentId={studentId}
                                    setStudentId={setStudentId}
                                    lstSelStudentId={lstSelStudentId}
                                    setLstSelStudentId={setLstSelStudentId}
                                    setStudentCount={setStudentCount}
                                    viewIdCard={viewIdCard}
                                    editStudent={editStudent}
                                    updateKey={updateKey}
                                />
                            </div>
                    }

                    <div className="col-md-2 col-xs-12 padding-remove" >
                        <UploadStudent
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            addNewStudent={addNewStudent}
                            flgShowError={flgShowError}
                            setFlgShowError={setFlgShowError}
                            setLstErrors={setLstErrors}
                        />
                    </div>
                </div>
            </div>
            {isIdCardSliderVisible && (
                <div className="slider1-60">
                    <IdCardSlider
                        lstIdCard={lstIdCard}
                        heading="View Id Card"
                        lstStudentId={lstStudentId}
                        closeSlider={closeSlider}
                    />
                </div>
            )}
        </div>
    );
}

export default Students;

