import React, { useState, useEffect, useRef } from 'react'
import ExamReportCardPDF from './examReportCardPDF.js';
import { getLogoSvc, getPrinciSignSvc, getExamReportSvc } from './examReportSvc.js';
// , getDocDtlsSvc
import { toast, ToastContainer } from 'react-toastify';
import lstModules from 'common/json/lstModules.json';
import NoImage from "images/no-image.png";




const ExamReportSlider = (props) => {

  const { heading, sectionId, examId, exam } = props;

  const [lstReportCard, setLstReportCard] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loader, setLoader] = useState("none");
  const [opacity, setOpacity] = useState(1);


  useEffect(() => {
    // console .log("ExamReportSlider Called heading ", props.heading, ", sectionId ", props.sectionId);
    
    getPrinciSignSvc(cbPrinciSign);
    getLogoSvc(cbLogoResponse);
    getTermReport();
  }, [props.sectionId, examId, exam]);

  function cbPrinciSign(imageUrl) {
    setImageUrl(imageUrl)
  }
  function cbLogoResponse(imageUrl) {
    // console .log("cbLogoResponse called, imageUrl", imageUrl);
    setLogoUrl(imageUrl)
  }

  const getTermReport = () => {
    setLoader("block");
    setOpacity(0.5);
    getExamReportSvc(examId, sectionId, TermReportResponse);
  }

  const TermReportResponse = (data) => {
    // console .log("TermReportResponse called, data", data);
    let obj = data;
    if (obj.status === 'Success') {
      setLstReportCard(obj.lstReportCardObj);
    }
    else {
      if (obj.message == 'You are not logged in') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      }
      else if (obj.message == 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }
    setLoader("none");
    setOpacity(1);
  }


  const closeSlider = () => {
    props.removeClassFromBody();
  }



  return (
    <div className='padding-remove'>
      <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div>
      <div className='col-sm-12 col-centered padding-remove' style={{ opacity: opacity }}>
        <div className='EnquiryHead col-sm-12 col-centered'
          style={{
            border: '1px solid #aaa',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: 'bold',
            textAlign: "center"
          }}>
          <h4>{heading}</h4>
        </div>
        <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
          {lstReportCard && lstReportCard.length ?
            <ExamReportCardPDF
              logoUrl={logoUrl}
              exam={exam}
              lstReportCard={lstReportCard}
            />
            : null
          }
        </div>
      </div >
      <button
        type='button'
        className='btn btn-info text-center '
        id='closeSlider'
        onClick={e => closeSlider()}>
        <span
          id='close'
          title='Close Slider'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
    </div>
  );
}

export default ExamReportSlider;