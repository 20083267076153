import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/items.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var httpURL = '';
var queryString = require('querystring');

var lstItems = [];
var lstSubItems = [];
var lstChildItems = [];
var tokenid = localStorage.getItem("tokenid");
var screenId = 12;

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            flg1: true,
            expiryDate: null,
            openingBalanceDate: null,
            tokenid: tokenid
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }
    handleChange(date) {
        this.setState({ expiryDate: date });
    }
    handleChange1(date) {
        this.setState({ openingBalanceDate: date });
    }

    componentDidMount = () => {
        this.getItemList();
        $('#childItemCmb').prepend($("<option> </option>").val(0).html('Sub Item'));
    }
    getAllSubitemList = () => {
        axios({
            url: httpURL + '/sms/subitem/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstChildItems = response.data.lstAllSubItem;
                this.fillSubItemCmb();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            } this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    getItemList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });

        axios({
            url: httpURL + '/sms/item/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstItems = response.data.lstItems;
                this.setItemTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            } this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillSubItemCmb() {
        $('#childItemCmb').empty();
        $('#childItemCmb').prepend($("<option> </option>").val(0).html('Sub Item'));
        for (var i = 0; i < lstChildItems.length; i++) {
            var itemId = lstChildItems[i].itemId;
            var item = lstChildItems[i].item;
            $('#childItemCmb').append($("<option> </option>").val(itemId).html(item));
        }
    }

    getSubItemList = (id) => {
        this.setState({ loader: 'block', opacity: 0.5 });

        axios({
            url: httpURL + '/sms/subitem/item/list',
            method: "POST",
            params: { 'itemId': id, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstSubItems = response.data.lstSubItem;
                this.setSubItemTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }
    setItemTable = () => {
        $('#itemTable tr').remove();
        var table = document.getElementById('itemTable');
        var tbody = document.getElementById('itemBodyTable');

        for (var i = 0; i < lstItems.length; i++) {
            var itemId = lstItems[i].itemId;
            var itemCode = lstItems[i].itemCode;
            var item = lstItems[i].item;
            var unit = lstItems[i].unit;
            var rate = lstItems[i].rate;
            var openingBalance = lstItems[i].openingBalance;
            var openingBalanceDate = lstItems[i].openingBalanceDate;
            var expiryDate = lstItems[i].expiryDate;
            if (expiryDate == null || expiryDate == "") {
                expiryDate = '-';
            }
            var minQty = lstItems[i].minQty;
            var hasSubItems = lstItems[i].hasSubItems ? 'Yes' : 'No';
            //need to chnge 


            var row = document.createElement('tr');
            row.id = itemId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setItemForm($(e.target).closest('tr').attr('id'));
                this.getAllSubitemList();

                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, itemId, 'no-display');
            this.addTD(row, itemCode, 'text-center');
            this.addTD(row, item, 'text-center');
            this.addTD(row, unit, 'text-right');
            this.addTD(row, rate, 'text-right');
            this.addTD(row, openingBalance, 'no-display');
            this.addTD(row, openingBalanceDate, 'no-display');
            this.addTD(row, expiryDate, 'text-center');
            this.addTD(row, minQty, 'no-display');
            this.addTD(row, hasSubItems, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }


    setItemForm = (itemId) => {

        var itemData = this.getItemData(itemId);
        $('#itemCode').val(itemData.itemCode);
        $('#item').val(itemData.item);
        $('#desc').val(itemData.desc);
        $('#unit').val(itemData.unit);
        $('#rate').val(itemData.rate);
        $('#openingBalance').val(itemData.openingBalance);
        $('#minQty').val(itemData.minQty);
        var hasSubItem = itemData.hasSubItems;

        if (hasSubItem == true) {
            this.getSubItemList(itemId);
            $('#hasSubItems').prop("checked", true);
            $('.subItemWrapper').slideDown();
        }
        else {
            $('#hasSubItems').prop("checked", false);
            $('.subItemWrapper').hide();
        }

        if (itemData.openingBalanceDate == "" || itemData.openingBalanceDate == "00-00-0000") {
            this.setState({
                openingBalanceDate: null,
            });
        }
        else {
            var date = itemData.openingBalanceDate;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var openingBalanceDate = new Date(setDate);
            this.setState({
                openingBalanceDate: openingBalanceDate,
            });
        }

        if (itemData.expiryDate == "" || itemData.expiryDate == "00-00-0000") {
            this.setState({
                expiryDate: null,
            });
        }
        else {
            var date = itemData.expiryDate;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var expiryDate = new Date(setDate);
            this.setState({
                expiryDate: expiryDate,
            });
        }






    }

    setSubItemTable = () => {
        $('#subItemsBodyTable tr').remove();
        var table = document.getElementById('subItemsTable');
        var tbody = document.getElementById('subItemsBodyTable');

        for (var i = 0; i < lstSubItems.length; i++) {
            var subItemId = lstSubItems[i].subItemId;
            var itemCode = lstSubItems[i].itemCode;
            var item = lstSubItems[i].item;
            var qty = lstSubItems[i].qty;
            var rate = lstSubItems[i].rate;


            var row = document.createElement('tr');
            row.id = subItemId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setSubItemForm($(e.target).closest('tr').attr('id'));
                $('body').addClass('showSubItemForm');
                this.setState({
                    flg1: false,
                });
            }

            this.addTD(row, subItemId, 'no-display');
            this.addTD(row, itemCode, 'text-center');
            this.addTD(row, item, 'text-center');
            this.addTD(row, qty, 'text-right');
            this.addTD(row, rate, 'text-right');

            tbody.append(row);
        }
        table.append(tbody);
    }


    setSubItemForm = (subItemId) => {
        var subItemData = this.getSubItemData(subItemId);

        $('#childItemCmb').val(subItemData.itemId);
        $('#qty').val(subItemData.qty);
    }
    // need to add
    getItemData(itemId) {
        for (var i = 0; i < lstItems.length; i++) {
            if (itemId == lstItems[i].itemId)
                return lstItems[i];
        }
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    getSubItemData(subItemId) {
        for (var i = 0; i < lstSubItems.length; i++) {
            if (subItemId == lstSubItems[i].subItemId)
                return lstSubItems[i];
        }
    }

    addItem = () => {
        var lstItem = {};
        var itemId = 7;
        var itemCode = $('#itemCode').val();
        var item = $('#item').val();
        var desc = $('#desc').val();
        var unit = $('#unit').val();
        var rate = $('#rate').val();
        var openingBalance = $('#openingBalance').val();
        var openingBalanceDate = $('#openingBalanceDate').val();
        var expiryDate = $('#expiryDate').val();
        var minQty = $('#minQty').val();
        var hasSubItems = $('#hasSubItems').is(':checked');


        if (itemCode == "") {
            toast.warning('Please Enter code');
            $('#itemCode').focus();
            return false;
        }
        if (item == "") {
            toast.warning('Please Enter item');
            $('#item').focus();
            return false;
        }
        if (unit == "") {
            toast.warning('Please Enter the units');
            $('#unit').focus();
            return false;
        }
        if (rate == "") {
            toast.warning('Please Enter rate');
            $('#rate').focus();
            return false;
        }
        if (openingBalance == "") {
            toast.warning('Please Enter opening balance');
            $('#openingBalance').focus();
            return false;
        }

        if (minQty == "") {
            toast.warning('Please Enter minimum quantity');
            $('#minQty').focus();
            return false;
        }

        if (this.state.flg) {
            itemId = 0
        }
        else {
            itemId = $('#itemBodyTable tr.selected').find('td:eq(0)').text();
        }



        lstItem = {
            'itemId': itemId, 'itemCode': itemCode, 'item': item, 'unit': unit, 'rate': rate,
            'openingBalance': openingBalance, 'openingBalanceDate': openingBalanceDate, 'expiryDate': expiryDate,
            'minQty': minQty, 'hasSubItems': hasSubItems,
        }


        axios({
            url: httpURL + '/sms/item/save',
            method: "POST",
            params: { 'items': lstItem, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {

            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getItemList();
                this.setItemTable();
                this.addItemType();


            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });


    }

    addSubItem = () => {
        var lstSubItem = {};
        var itemId = $('#itemBodyTable tr.selected').find('td:eq(0)').text();
        var subItemId = 0;
        var childItemId = $('#childItemCmb').find('option:selected').val();
        var qty = $('#qty').val();


        if (childItemId == 0) {
            toast.warning('Please Select Valid Subitem');
            $('#childItemCmb').focus();
            return false;
        }
        if (qty == "") {
            toast.warning('Please Enter sub item unit');
            $('#qty').focus();
            return false;
        }


        if (this.state.flg1) {
            subItemId = 0
        }
        else {
            subItemId = $('#subItemsBodyTable tr.selected').find('td:eq(0)').text();
        }

        lstSubItem = {
            'subItemId': subItemId, 'childItemId': childItemId, 'itemId': itemId, 'qty': qty,
        }

        axios({
            url: httpURL + '/sms/subitem/save',
            method: "POST",
            params: { 'subitem': lstSubItem, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getSubItemList(itemId);
                this.setSubItemTable();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }



    addItemType = () => {
        $('#itemAddForm').trigger('reset');
        $('#itemBodyTable tr').removeClass('selected');
        $('.subItemWrapper').hide();
        this.setState({
            expiryDate: null,
            openingBalanceDate: null
        });
    }
    addSubItemType = () => {
        $('#subItemAddForm').trigger('reset');
        $('#subItemsBodyTable tr').removeClass('selected');
        $('body').addClass('showSubItemForm');
    }
    cancelSubItem = () => {
        $('body').removeClass('showSubItemForm');
    }
    // showSubItemTable = () => {
    //     $('.subItemWrapper').slideToggle();

    // }


    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['purchase'].url + ":" + lstModules['purchase'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Items" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-7  col-xs-7 itemTypeWrap  padding-remove">
                            <table className="tableLayout1 itemType" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> itemId </th>
                                        <th style={{ width: 40 }}> Item code </th>
                                        <th style={{ width: 40 }}> Item </th>
                                        <th style={{ width: 40 }}> Unit </th>
                                        <th style={{ width: 40 }}> Rate </th>
                                        <th style={{ width: 40 }}> Expiry Date </th>
                                        <th style={{ width: 40 }}> Has sub items </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="itemTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="itemTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="itemBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 ">
                    <span id="addItem" title="Add Item Type" onClick={this.addItemType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
                <div className="col-md-4 col-xs-8 timingFormWrap" >
                    <form id="itemAddForm" className="form-horizontal formWrapper">
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Item code<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="itemCode" type="text" className="form-control " placeholder="Enter Item code" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Item<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="item" type="text" className="form-control " placeholder="Enter Item" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Description<span></span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="desc" type="text" className="form-control " placeholder="description" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Units<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="unit" type="text" className="form-control " placeholder="Enter units" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Rate<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="rate" type="text" className="form-control " placeholder="Enter Rate" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Opening Balance<span></span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="openingBalance" type="text" className="form-control " placeholder="Enter opening balance" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label col-sm-5" >Opening Balance Date<span></span></label>
                            <div className="col-sm-6 padding-remove">
                                <DatePicker id="openingBalanceDate"
                                    className='form-control'
                                    placeholderText="Enter opening balance Date"
                                    selected={this.state.openingBalanceDate}
                                    onChange={this.handleChange1}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label col-sm-5" >Expiry Date<span></span></label>
                            <div className="col-sm-6 padding-remove">
                                <DatePicker id="expiryDate"
                                    className='form-control'
                                    placeholderText="Enter expiry Date"
                                    selected={this.state.expiryDate}
                                    onChange={this.handleChange}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Minimum Quantity<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="minQty" type="text" className="form-control " placeholder="Enter minimum quantity" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Sub Item<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="hasSubItems" type="checkbox" onChange={this.showSubItemTable} />
                            </div>
                        </div>

                        <div className="form-group" >
                            <div className="col-sm-12 text-center userButtonWrap" >
                                <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addItem} className="btn btn-info">Save</button>
                            </div>
                        </div>
                    </form>
                    <div className="subItemWrapper">
                        <div className="col-md-12 col-centered">
                            <div className="col-md-11  col-xs-11  padding-remove">
                                <h3>Sub Item Code</h3>
                                <table className="tableLayout1" style={{ width: this.state.width }}>
                                    <thead>
                                        <tr>
                                            <th style={{ display: 'none' }}> sub Item Id </th>
                                            <th style={{ width: 40 }}> Item Code</th>
                                            <th style={{ width: 40 }}> Qty</th>
                                            <th style={{ width: 40 }}> Unit </th>
                                            <th style={{ width: 40 }}> Rate </th>

                                        </tr>
                                    </thead>
                                </table>
                                <div className="subItemsTableDiv" style={{ height: '150px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                    <table id="subItemsTable" className="tableLayout">
                                        <colgroup>
                                            <col width="40" />
                                            <col width="40" />
                                            <col width="40" />
                                            <col width="40" />
                                        </colgroup>
                                        <tbody id="subItemsBodyTable"></tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xs-1 ">
                                <span id="addSubItems" title="Add Subitems" onClick={this.addSubItemType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                            </div>
                            <div className="col-md-8 padding-remove subItemFormWrapper" >
                                <form id="subItemAddForm" className="form-horizontal formWrapper">

                                    <div className="form-group">
                                        <label className="control-label col-sm-5" >Sub Item<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <select autoComplete="off" id="childItemCmb" type="text" className="form-control " />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-sm-5" >Quantity<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <input autoComplete="off" id="qty" type="text" className="form-control " placeholder="Quantity" />
                                        </div>
                                    </div>
                                    <div className="form-group" >
                                        <div className="col-sm-12 text-center userButtonWrap" >
                                            <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addSubItem} className="btn btn-info">Save</button>
                                            <button type="button" title="Cancel" onClick={this.cancelSubItem} className="btn btn-info">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }

}
export default Items;