import React, { useState, useEffect } from "react";

import {
  GoogleMap,
  MarkerClusterer,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";

//import routes from "./routes3.json";

let directionsService;
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function createKey({ location }) {
  return location.lat + location.lng;
}

// var len = Math.floor(routes.length / 2);
// var defaultLocation =  {
//                                               lat: routes[len].location.lat,
//                                               lng: routes[len].location.lng,
//                                             };

const Map = ({routes}) => {
  const [state, setState] = useState({ directions: null, bounds: null });
  const [len, setLen] = useState(Math.floor(routes.length / 2))
  const [defaultLocation, setDefaultLocation] = useState( {
                                                lat: routes[len].location.lat,
                                                lng: routes[len].location.lng,
                                              });
  
  const google = window.google;
  // const [len, setLen] = useState(0);
  // const [defaultLocation, setDefaultLocation] = useState({});
  // const [routes, setRoutes] = useState([])
  
  // useEffect(() => {
  //   setRoutes(props.routes)
  // }, [props.routes]);
  
  const onMapLoad = (map) => {
    // console .log(routes.length)
    directionsService = new google.maps.DirectionsService();

    const routesCopy = routes.map((route) => {
      return {
        location: { lat: route.location.lat, lng: route.location.lng },
        stopover: true,
      };
    });
    const origin =
      routesCopy.length === 1
        ? new google.maps.LatLng(
            routesCopy[0].location.lat,
            routesCopy[0].location.lng
          )
        : routesCopy.shift().location;
    const destination =
      routesCopy.length === 1
        ? new google.maps.LatLng(
            routesCopy[0].location.lat,
            routesCopy[0].location.lng
          )
        : routesCopy.pop().location;
    //put all the remaining coordinates in waypoints after(pop and shift)
    const waypoints = routesCopy;
    // console .log(origin, destination, waypoints);
    //call getDirection function
    getDirection(origin, destination, waypoints);
  };

  //function that is calling the directions service
  const getDirection = (origin, destination, waypoints) => {
    //this will check if there is a waypoint meaning the array  has 3 or more coordinates
    waypoints.length >= 1
      ? directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            waypoints: waypoints,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              //changing the state of directions to the result of direction service
              setState({
                directions: result,
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        )
      : directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              //changing the state of directions to the result of direction service
              setState({
                directions: result,
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
  };

  return (
    <div>
      <GoogleMap
        center={defaultLocation}
        zoom={3}
        onLoad={(map) => onMapLoad(map)}
        mapContainerStyle={{ height: "100vh", width: "100%" }}
      >
        {state.directions !== null && (
          <DirectionsRenderer directions={state.directions} />
        )}
        <MarkerClusterer options={options}>
          {(clusterer) =>
            routes.map((location) => (
              <Marker
                key={createKey(location)}
                position={location}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
}

export default Map;
