import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 33;
//var sessionId = 3;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var httpURLTT = lstModules['timeTable'].url + ":" + lstModules['timeTable'].port;

function getClassList(callback1) {
  // console .log("getClassList");
  axios({
    url: httpURL + '/sms/cls/list',
    method: "POST",
    params: { screenId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    // console .log('data.SvcStatus=' + response.data.SvcStatus);
    callback1(response.data);
  }).catch((error) => {
    callback1(error);
  });
}

function getDaysDtls(callback) {
  // console .log("getDaysDtls");
  axios({
    url: httpURLTT + '/sms/tt/v1/tt/days/list',
    method: "POST",
    params: { screenId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    // console .log('data.SvcStatus=' + response.data.SvcStatus);
    callback(response.data);
  }).catch((error) => {
    callback(error)
  });
}

function getPeriodDtls(sessionId, classId, sectionId, callback) {
  var status = 'Success'
  var obj = { status: status };
  // var sessionId = 1;
  // console .log("sessionId " + sessionId);
  // console .log("getPeriodDtls");
  axios({
    url: httpURLTT + '/sms/tt/v1/tt/period/list',
    method: "POST",
    params: { sessionId, classId, sectionId, screenId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    if (response.data.SvcStatus === 'Success') {
      // console .log('data.SvcStatus=' + response.data.SvcStatus);
      obj.lstPeriod = response.data.lstPeriod;
      obj.message = response.data.SvcMsg;
      obj.status = 'Success';
      // console .log(obj);
      callback(obj)
    } else {
      obj.lstPeriod = [];
      obj.message = response.data.SvcMsg;
      obj.status = 'Failure';
      // console .log(obj);
      callback(obj)
    }
  }).catch((error) => {
    obj.status = 'Exception';
    obj.lstPeriod = []
    obj.message = error;
    callback(obj)
  });
}

function getCellDtls(handleCellResponse, rqCell) {
  // console .log(httpURL + '/sms/tt/v1/tt/cell/dtls')
  // console .log("getCellDtls");
  // console .log("rqcell" + JSON.stringify(rqCell)); //[object object]
  axios({
    url: httpURLTT + '/sms/tt/v1/tt/cell/dtls',
    method: "POST",
    params: { rqCell: JSON.stringify(rqCell), screenId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    if (response.data.SvcStatus === 'Success') {
      // console .log('data.SvcStatus=' + response.data.SvcStatus);
      handleCellResponse(response.data)
    }
  }).catch((error) => {
    handleCellResponse(error);
  });
}

function getSectionData(callback2, clsId) {
  // console .log("getSectionList");
  // console .log("CLSID" + clsId);
  axios({
    url: httpURLTT + "/sms/tt/v1/tt/section/list",
    method: "POST",
    params: { screenId, clsId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    // console .log('data.SvcStatus=' + response.data.SvcStatus);
    callback2(response.data, clsId);
  }).catch((error) => {
    callback2(error);
  });
}
function getSessionData(callback3, clsId) {
  // console .log("getSessionList");
  // console .log("CLSID for session " + clsId);
  axios({
    url: httpURLTT + "/sms/tt/v1/tt/session/list",
    method: "POST",
    params: { screenId, clsId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    // console .log('data.SvcStatus=' + response.data.SvcStatus);
    callback3(response.data,clsId);
  }).catch((error) => {
    callback3(error);
  });
}

function deleteCellDtls(cellId, subPosition, callback) {
  var status = 'Success'
  var obj = { status: status };
  var cellDtls = {
    cellId: cellId,
    subPosition: subPosition,
  }
  // console .log('deleteCell ' + cellId);
  if (window.confirm("Are you sure to delete the subject?") == true) {
    axios
      ({
        url: httpURLTT + "/sms/tt/v1/tt/cell/delete",
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
        params: { screenId: screenId, cellDtls: JSON.stringify(cellDtls) },
      })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          obj.SvcMsg = response.data.SvcMsg;
          obj.status = 'Success';
          cellDtls.dayId = 0;
          cellDtls.schedulePeriodId = 0;
          cellDtls.classSectionId = 0;
          delete cellDtls.subPosition;
          callback(obj, cellDtls, subPosition);
        } else {
          obj.status = 'Failure';
          obj.SvcMsg = response.data.SvcMsg;
          callback(obj)
        }
      })
      .catch((error) => {
        obj.status = 'Exception';
        obj.SvcMsg = error;
        callback(obj)
      });
  }
};

function getSubList(sessionId, classId, sectionId, handleToFunction) {
  // console .log('sessionId = ' + sessionId + ', classId = ' + classId + ', sectionId = ' + sectionId)
  axios({
    url: httpURLTT + "/sms/tt/v1/tt/cls/sub/list",
    method: "POST",
    params: { screenId, sessionId, classId, sectionId },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {

    handleToFunction(response.data)

  }).catch((error) => {
    handleToFunction(error)
  });
}

function saveCellDtls(cellObj, callback) {
  var status = 'Success'
  var obj = { status: status };
  // console .log('savedCell ' + JSON.stringify(cellObj));
  //if (window.confirm("Are you sure to save the subject?")) {
  axios
    ({
      url: httpURLTT + "/sms/tt/v1/tt/cell/save",
      method: "POST",
      headers: { 'tokenid': tokenid },
      withCredentials: true,
      params: { screenId: screenId, cellDtls: JSON.stringify(cellObj) },
    })
    .then((response) => {
      if (response.data.SvcStatus === "Success") {
        obj.SvcMsg = response.data.SvcMsg;
        obj.status = 'Success';
        obj.cellId = response.data.cellId;
        callback(obj, cellObj)
      } else {
        obj.status = 'Failure';
        obj.SvcMsg = response.data.SvcMsg;
        callback(obj, cellObj)
      }
    })
    .catch((error) => {
      obj.status = 'Exception';
      obj.SvcMsg = error;
      callback(obj)
    });
};
export { getClassList, getSubList, getCellDtls, getSessionData, getSectionData, getPeriodDtls, getDaysDtls, deleteCellDtls, saveCellDtls };
