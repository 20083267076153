import axios from 'axios'
import $ from 'jquery'
import { toast, ToastContainer } from "react-toastify"
import lstModules from 'common/json/lstModules.json';

var queryString = require('querystring');
var tokenid = localStorage.getItem("tokenid");
const httpURLSyllabus = lstModules['syllabus'].url + ':' + lstModules['syllabus'].port + "/sms/syllabus";
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;

export function uploadSyllabusExcel(formData, cb, ctx) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/syllabus/excel/upload',
        method: "POST",
        data: formData,
        headers: { tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            obj.errors = response.data.errors;
        }
        cb(obj, ctx)
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj, ctx)

    });
}

export const getTopicListSvc = (chapterId, cbTopicList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLSyllabus + '/chapter/topic/list',
        method: "POST",
        params: { "chapterId": chapterId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getTopicListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstTopics = response.data.lstTopics;
        }
        cbTopicList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbTopicList(obj);
    })
}


export const getLstSyllabusSubjectSvc = (syllabusId, cbLstSyllabusSubject) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLSyllabus + '/listSubject',
        method: "POST",
        params: { "syllabusId": syllabusId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getLstSyllabusSubjectSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstSyllabusSubject = response.data.lstSyllabusSubject;
        }
        cbLstSyllabusSubject(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstSyllabusSubject(obj);
    })
}


export const saveSubjectSvc = (syllabusSubject, cbSaveSubject) => {
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("syllabusSubject", JSON.stringify(syllabusSubject))
    axios({
        url: httpURLSyllabus + '/saveSubject',
        method: "POST",
        // params: { "syllabusSubject": JSON.stringify(syllabusSubject) },
        data:formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in saveSubjectSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveSubject(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveSubject(obj);
    })
}


// export { uploadSyllabusExcel }