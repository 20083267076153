import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['feedback'].url + ':' + lstModules['feedback'].port;
var tokenid = localStorage.getItem("tokenid");


function getStatusSummaryList(callback, pStatusId) {
    axios({
        url: httpURL + '/sms/feedback/status/summary',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    })
        .then((response) => {
            // console .log('data.SvcStatus=' + response.data.SvcStatus);
            callback(response.data, pStatusId);
        })
        .catch((error) => { callback(error); });
}

export { getStatusSummaryList }