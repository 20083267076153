import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { saveAttendanceSvc, informSvc, deleteAttendanceSvc } from './DailyAttendanceTableSvc.js'
import { getAttendanceSvc } from './DailyAttendanceSvc.js';

var lstTmpAttendance = []

const DailyAttendanceTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [submitBtnLoader, setSubmitBtnLoader] = useState("none");
    const [lstAttendance, setLstAttendance] = useState([]);
    const [lstSvcAttendance, setLstSvcAttendance] = useState([]);
    const [dtDdMmYyyy, setDtDdMmYyyy] = useState(null);
    const [sectionId, setSectionId] = useState(0);
    const [theKey, setTheKey] = useState(0);
    const [flgSortStudents, setFlgSortStudents] = useState(4);
    useEffect(() => {
        // console .log(props.dtDdMmYyyy)
        // console .log(props.sectionId)
        if (props.dtDdMmYyyy && props.sectionId) {
            setDtDdMmYyyy(props.dtDdMmYyyy)
            setSectionId(props.sectionId)
            setLoader('block')
            getAttendanceSvc(props.dtDdMmYyyy, props.sectionId, cbAttendanceResponse);
        } else {
            setLstAttendance([])
        }

    }, [props.dtDdMmYyyy, props.sectionId])
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setTheKey(lRandNo)
    }

    async function cbAttendanceResponse(data) {
        setLoader('none')
        lstTmpAttendance = []
        setLstSvcAttendance([])
        if (data.SvcStatus === "Success") {
            await setLstAttendance(data.lstAttendance)
            props.setStudentCount(data.lstAttendance.length);
            generateRandomNo()
            // console .log(lstAttendance);
            for (var i = 0; i < data.lstAttendance.length; i++) {
                lstTmpAttendance.push({
                    studentId: data.lstAttendance[i].studentId
                    , attendance: data.lstAttendance[i].attendance.toUpperCase()
                })
            }
            setLstSvcAttendance(lstTmpAttendance)
        } else {
            toast.error(data.SvcMsg)
        }
    }

    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="35%"></col>
        <col width="10%"></col>
        <col width="15%"></col>
        <col width="30%"></col>
    </colgroup>

    function handleAttendanceChange(e, pStudentId, pVal) {
        // console .log(pStudentId + ", val=" + pVal)
        // console .log(lstTmpAttendance)
        pVal = pVal.toUpperCase();
        var idx = existsAt(pStudentId, lstTmpAttendance)
        if (idx > -1)
            lstTmpAttendance[idx].attendance = pVal
        else
            lstTmpAttendance.push({ studentId: pStudentId, attendance: pVal })

        setLstSvcAttendance(lstTmpAttendance);


    }

    function cbMarkAttendance(data) {
        setSubmitBtnLoader('none')
        if (data.status === "Success") {
            toast.success(data.message)
            props.generateRandomNo()
        } else {
            toast.error(data.message)
        }
    }
    function existsAt(id, arr) {
        var idx = -1;
        // console .log(typeof id)
        for (var i = 0; i < arr.length; i++) {
            // console .log(typeof arr[i].studentId)
            if (arr[i].studentId === id) {
                idx = i;
                break;
            }
        }
        return idx;
    }

    function saveAtt(e) {
        e.preventDefault()
        // console .log('submit called')
        submitAttendance()
    }
    function submitAttendance() {
        setSubmitBtnLoader('block')
        saveAttendanceSvc(dtDdMmYyyy, sectionId, lstSvcAttendance, cbMarkAttendance);
    }

    function inform(e, pStudentId) {
        e.preventDefault();
        informSvc(dtDdMmYyyy, pStudentId, cbInformResponse);
    }

    function cbInformResponse(data) {
        if (data.status === "Success") {
            toast.success(data.message)
            getAttendanceSvc(dtDdMmYyyy, sectionId, cbAttendanceResponse);
        } else {
            toast.error(data.message)
        }
    }

    function alphaOnly(e) {
        var key = e.keyCode;
        //// console .log(key);
        //// console .log("if keys pressed are not A(65),P(80),L(76),Tab(9),F1(112),Enter(13), THEN select action")
        if (!(key === 65 || key === 80 || key === 97 || key === 112 || key === 76 || key === 102 || key === 9 || key === 13)) {
            e.preventDefault()
            e.currentTarget.select()
        }
    };

    async function markAllP() {
        var lstUpdAttendance = lstAttendance;
        lstTmpAttendance = [];
        for (var i = 0; i < lstAttendance.length; i++) {
            lstUpdAttendance[i].attendance = 'P';
            await lstTmpAttendance.push({
                studentId: lstAttendance[i].studentId
                , attendance: "P"
            })
        }
        setLstSvcAttendance(lstTmpAttendance)
        await setLstAttendance(lstUpdAttendance);
        generateRandomNo()
        /* // console .log(lstTmpAttendance)
        setSubmitBtnLoader('block')
        saveAttendanceSvc(dtDdMmYyyy, sectionId, lstTmpAttendance, cbMarkAttendance); */
    }

    const toggleSortStudents = (toggleId) => {
        // console .log("toggleSortStudents called, toggleId", toggleId);
        if (toggleId == 1) {
            //roll no descending
            // console .log("called to sort students by roll no descending")
            setFlgSortStudents(1);
            setLstAttendance(lstAttendance.sort((a, b) => parseInt(a.rollNo) - parseInt(b.rollNo)));
        }
        if (toggleId == 0) {
            //roll no ascending
            // console .log("called to sort students by roll no ascending")
            setFlgSortStudents(0);
            setLstAttendance(lstAttendance.sort((a, b) => parseInt(b.rollNo) - parseInt(a.rollNo)));
        }
        if (toggleId == 2) {
            //name ascending
            // console .log("called to sort students by name descending")
            setFlgSortStudents(2);
            setLstAttendance(lstAttendance.sort((a, b) => b.name.localeCompare(a.name)));
        }
        if (toggleId == 3) {
            //name descending
            setFlgSortStudents(3);
            // console .log("called to sort students by name ascending")
            setLstAttendance(lstAttendance.sort((a, b) => a.name.localeCompare(b.name)));
        }
    }

    const deleteAttendance = () => {
        // console .log("deleteAttendance called");
        setLoader('block')
        deleteAttendanceSvc(dtDdMmYyyy, sectionId, cbDeleteAttendance);
    }

    function cbDeleteAttendance(data) {
        setLoader('none')
        if (data.status === "Success") {
            toast.success(data.message)
            props.generateRandomNo()
        } else {
            toast.error(data.message)
        }
    }

    return (
        <div className="col-sm-12 padding-remove" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-sm-11 col-centered padding-remove" >
                <div className='col-sm-8 '>
                    <table className='tableLayout1'>
                        {colgrp}
                        <thead>
                            <tr >
                                <th>{"Roll No. "}
                                    {flgSortStudents !== 0 ? (
                                        <span
                                            title="edit"
                                            className="glyphicon glyphicon-triangle-top icon-btn"
                                            style={{ color: flgSortStudents === 1 ? "black" : "white" }}
                                            onClick={() => toggleSortStudents(0)}
                                        ></span>
                                    ) : (
                                        <span
                                            title="edit"
                                            className="glyphicon glyphicon-triangle-bottom icon-btn"
                                            style={{ color: flgSortStudents === 0 ? "black" : "white" }}
                                            onClick={() => toggleSortStudents(1)}
                                        ></span>
                                    )}
                                </th>
                                <th>{"Name  "}
                                    {flgSortStudents !== 3 ? (
                                        <span
                                            title="edit"
                                            className="glyphicon glyphicon-triangle-top icon-btn"
                                            style={{ color: flgSortStudents === 2 ? "black" : "white" }}
                                            onClick={() => toggleSortStudents(3)}
                                        ></span>
                                    ) : (
                                        <span
                                            title="edit"
                                            className="glyphicon glyphicon-triangle-bottom icon-btn"
                                            style={{ color: flgSortStudents === 3 ? "black" : "white" }}
                                            onClick={() => toggleSortStudents(2)}
                                        ></span>
                                    )}
                                </th>
                                <th>Gender</th>
                                <th>Attendance</th>
                                <th>Inform Parent</th>
                            </tr>
                        </thead>
                    </table>
                    <div className='tableBodyBorder' style={{ height: '550px', overflowY: 'scroll' }} >
                        <table className='tableLayout '>
                            {colgrp}
                            <tbody key={theKey}>
                                {lstAttendance.map((obj, idx) => {
                                    return <tr key={idx} id={obj.studentId}
                                        onClick={(e) => props.setStudentId(obj.studentId)}
                                        // style={{height:"15px"}}
                                        className={obj.studentId === props.studentId ? "selected" : ""}
                                    >
                                        <td className="no-display">{obj.studentId}</td>
                                        <td>{obj.rollNo}</td>
                                        <td className="text-left">{obj.name}</td>
                                        <td className="text-center">{obj.gender}</td>
                                        <td className="text-center"><input onKeyDown={(e) => alphaOnly(e)} maxLength="1" style={{ margin: '0 auto', textTransform: 'uppercase', width: 25, textAlign: 'center' }} type="text" className="form-control" onClick={(e) => e.target.select()} defaultValue={obj.attendance} onChange={(e) => handleAttendanceChange(e, obj.studentId, e.target.value)} /></td>
                                        <td className="text-center">{obj.attendance === 'A' && obj.informedOn === '-' ? <a href="#" onClick={(e) => { inform(e, obj.studentId) }}>Inform</a> : obj.informedOn}</td>

                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-2" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "580px" }}>
                    <div className="col-sm-8 form-group" style={{ height: "auto", pointerEvents: submitBtnLoader === "none" ? "auto" : "none" }}>
                        <button type="button" className="btn btn-info pull-right" onClick={() => markAllP()}>Mark All P</button>
                    </div>
                    <div className='col-sm-12 padding-remove' style={{ height: "50px" }}>
                        <div className="col-sm-8 form-group">
                            <div style={{ right: 0, position: 'absolute', zIndex: 10000, display: submitBtnLoader }}>
                                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                            </div>
                            <button type="button" className="btn btn-info pull-right" onClick={(e) => saveAtt(e)}>Submit</button>
                        </div>
                        <div className="col-sm-4 " style={{ height: "50px", justifyContent: "center" }} >
                            <a className="discountTd" style={{ height: "50px", justifyContent: "center", fontSize: 18 }} onClick={() => deleteAttendance()}>
                                Delete
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DailyAttendanceTable