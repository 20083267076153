import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import 'common/css/master.css'

import HeaderPtmClassSection from './HeaderPtmClassSection'
import { getClassList, getSectionData, getLstPtmAttendanceSvc, saveAttendanceSvc } from './PtmAttendanceSliderSvc';




var lstTmpStudent = []

const PtmAttendanceSlider = props => {


  const [ptmId, setPtmId] = useState(0);
  const [ptmStudentId, setPtmStudentId] = useState(0);

  const [studentId, setStudentId] = useState(0);
  const [sectionId, setSectionId] = useState(0);
  const [clsId, setClsId] = useState(0);
  const [state, setState] = useState({});
  const [lstCls, setLstCls] = useState([]);
  const [lstSection, setLstSection] = useState([]);

  const [lstStudent, setLstStudent] = useState([]);
  const [found, setFound] = useState([]);
  const [vLstStudent, setVLstStudent] = useState([]);

  const [rollNoFiltered, setRollNoFiltered] = useState("");
  const [nameFiltered, setNameFiltered] = useState("");
  const [attendanceKey, setAttendanceKey] = useState(0);


  useEffect(() => {
    // console .log("useEffect called in PtmAttanceSlider.js");
    setClass(handleClassResponse)
    if (props.flgSlider && props.ptmId != 0) {
      setPtmId(props.ptmId);
      setClsId(0)
      setSectionId(0)
      setLstStudent([]);
      setFound([]);
    }
  }, [props.flgSlider, props.ptmId, attendanceKey])

  function handleClassResponse(data) {
    hideLoader()
    var obj = data
    if (obj.status === 'Success') {
      setLstCls(obj.lstCls)
    } else if (obj.status === 'Failure') {
      setLstCls([])
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }

  const getLstPtmAttendance = (studPtmId, clasId, secId) => {
    // console .log("getLstPtmAttendance called, studePtmId", studPtmId, ", clasId", clasId, ", secId", secId);
    getLstPtmAttendanceSvc(cbStudentLstResponse, studPtmId, clasId, secId);
  }

  function cbStudentLstResponse(data) {
    if (data.SvcStatus === 'Success') {
      setLstStudent(data.lstStudent);
      setFound(data.lstStudent);
      // console .log("lstStudent set to in cbStudentLstResponse", data.lstStudent);
      var lLstStudent = data.lstStudent;
      const studentMap = new Map();

      for (var i = 0; i < lLstStudent.length; i++) {
        var obj = lLstStudent[i];
        studentMap.set(obj.ptmStudentId, {
          flgPresent: obj.flgPresent,
          remarks: obj.remarks
        });
      }

      setVLstStudent(studentMap);
      // console .log("setVLstStudent called");
    } else {
      toast.error(data.SvcMsg)
    }
  }

  async function cbStudentAttendanceResponse(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      // updateKey();
      getLstPtmAttendance(parseInt(ptmId), clsId, sectionId);
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function setClass() {
    showLoader()
    getClassList(handleClassResponse)
  }
  function showLoader() {
    setState({ ...state, loader: 'block', pointerEvents: 'auto' })
  }
  function hideLoader() {
    setState({ ...state, loader: 'none', pointerEvents: 'none' })
  }
  const updateFlgPresent = (ptmStudentId, newFlgPresent) => {
    setVLstStudent(prevStudents => {
      const updatedStudents = new Map(prevStudents);

      if (updatedStudents.has(ptmStudentId)) {
        const student = updatedStudents.get(ptmStudentId);
        updatedStudents.set(ptmStudentId, { ...student, flgPresent: newFlgPresent });
      }

      return updatedStudents; // Return the updated Map
    });
  };


  const updateRemarks = (ptmStudentId, newRemarks) => {
    setVLstStudent(prevStudents => {
      const updatedStudents = new Map(prevStudents);

      if (updatedStudents.has(ptmStudentId)) {
        const student = updatedStudents.get(ptmStudentId);
        updatedStudents.set(ptmStudentId, { ...student, remarks: newRemarks });
      }

      return updatedStudents; // Return the updated Map
    });
  };




  function changeSection(pSectionId) {
    // console .log('changeSection called, pSectionId ', pSectionId)
    setSectionId(pSectionId)
    // console .log("getLstPtmAttendance called from changeSection");
    getLstPtmAttendance(parseInt(ptmId), clsId, pSectionId);
  }


  const colgrp = (
    <colgroup>
      <col width='40' />
      <col width='60' />
      <col width='60' />
      <col width='40' />
      <col width='90' />
      <col width='40' />
    </colgroup>
  )
  function changeClass(pClsId, clsLabel) {
    setSectionId(0)
    // console .log('changeClass: ' + pClsId)
    /* if (sectionId) {
      getLstPtmAttendance(parseInt(props.ptmId), clsId, sectionId);
    } */
    setClsId(pClsId)
    setNameFiltered("");
    setRollNoFiltered("");
    setSection(pClsId, handleSectionResponse)
  }
  function setSection(handleSectionResponse, clsId) {
    // console .log('getSectionData() called..')
    getSectionData(handleSectionResponse, clsId)
  }

  function handleSectionResponse(data) {
    // console .log(data)
    var obj = data
    if (obj.status == 'Success') {
      setLstSection(obj.lstSection)
      if (obj.lstSection.length > 0) {
        setSectionId(obj.lstSection[0].sectionId);
        getLstPtmAttendance(parseInt(ptmId), obj.clsId, obj.lstSection[0].sectionId);

      }
    } else if (obj.status == 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed ' + obj.message)
    }
  }
  function saveAttendance(pIdx) {
    // console .log("saveAttendance called, pIdx", pIdx);
    // console .log("vLstStudent", vLstStudent);
    var attendance = vLstStudent.get(pIdx);
    attendance.ptmStudentId=pIdx;
    // console .log("attendance", attendance);
    saveAttendanceSvc(attendance, cbStudentAttendanceResponse)
  }



  const handleChangeNameFiltered = (e) => {
    const fName = e.target.value;
    setNameFiltered(fName);
    filterStudentsTable(fName, rollNoFiltered);
  }

  const handleChangeRollNoFiltered = (e) => {
    const fRoll = e.target.value;
    setRollNoFiltered(fRoll);
    filterStudentsTable(nameFiltered, fRoll);
  }

  const filterStudentsTable = (fName, fRoll) => {
    // console .log("fileteStudentsTable called, lstStudent", lstStudent);
    let filteredStudentList = [];
    filteredStudentList = lstStudent.filter(student =>
      student.name.toString().toLowerCase().includes(fName.toLowerCase()));

    filteredStudentList = filteredStudentList.filter(student =>
      student.rollNo.toString().toLowerCase().includes(fRoll.toLowerCase()));


    setFound(filteredStudentList);
  }

  const updateKey = () => {
    // console .log("updateKey called");
    setAttendanceKey(prevKey => prevKey + 1);
  }

  return (
    <div>
      <div className=''>
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: state.loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>

        <div className=''>
          {lstCls && lstCls.length !== 0 && (
            <HeaderPtmClassSection
              changeClass={changeClass}
              changeSection={changeSection}
              lstSection={lstSection}
              lstCls={lstCls}
              selSectionId={sectionId}
              classId={clsId}
            />
          )}
        </div>
        <form className='form form-horizontal'>
          <div className='col-sm-12'>
            <div className='col-sm-4 text-left no-display'>
              <div className=''>
                <input
                  readOnly
                  className='form-control-plain text-left'
                  style={{
                    border: 'none',
                    background: '#ffffff',
                    fontWeight: 'bold'
                  }}
                  value={ptmId}
                  disabled
                ></input>
              </div>
            </div>
          </div>

          <div className='col-sm-12'>
            <label className='col-sm-2 control-label text-left'>
              Objective:
            </label>
            <div className='form-group col-sm-3'>
              <input
                type='text'
                readOnly
                className='form-control'
                defaultValue={props.objective}
              />
            </div>
          </div>

          <div className='col-sm-12'>
            <label className='col-sm-2 control-label text-left'>Timings</label>
            <div className='form-group col-sm-3'>
              <input
                type='text'
                className='form-control'
                defaultValue={props.timings}
                readOnly
              />
            </div>
          </div>
        </form>

        <div className='col-sm-11'>
          <table className='tableLayout1'>
            {colgrp}
            <thead>
              <tr>
                <th className='padding-remove'>
                  <input
                    type='text'
                    value={rollNoFiltered}
                    onChange={(e) => handleChangeRollNoFiltered(e)}
                    className='form-control inp-search text-center'
                    placeholder='Roll No.'
                  />
                </th>
                <th className='padding-remove'>
                  <input
                    onChange={(e) => handleChangeNameFiltered(e)}
                    type='text'
                    value={nameFiltered}
                    className='form-control inp-search text-center'
                    placeholder='Student'
                  />
                </th>
                <th className='text-center'>Gender</th>
                <th className='text-center'>Present</th>
                <th className='text-center'>Remarks</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
          </table>
          <div className='tablebodyWrapper'>
            <table className='tableLayout'>
              {colgrp}
              <tbody>
                {found &&
                  found.length > 0 &&
                  found.map((obj, idx) => {
                    return (
                      <tr
                        key={idx}
                        id={obj.studentId}
                        obj={JSON.stringify(obj)}
                        className={
                          obj.ptmStudentId === ptmStudentId ? 'selected' : ''
                        }
                        onClick={async e => {
                          // console .log(vLstStudent[idx])
                          setPtmStudentId(obj.ptmStudentId);
                        }}
                      >
                        <td className='text-left'>{obj.rollNo}</td>
                        <td className='text-left'>{obj.name}</td>
                        <td className='text-center'>{obj.gender}</td>
                        <td className='text-center'>
                          <input
                            type='checkbox'
                            className='text-center '
                            checked={vLstStudent.get(obj.ptmStudentId)?.flgPresent}
                            // id={`custom-checkbox-${obj.studentId}`}
                            onChange={e => updateFlgPresent(obj.ptmStudentId, e.target.checked)}
                          />
                        </td>
                        <td className='text-center'>
                          <input
                            key={obj.ptmStudentId}
                            type='text'
                            className='form-control'
                            // id={`custom-remarks-${obj.studentId}`}
                            value={vLstStudent[obj.ptmStudentId]?.remarks}
                            onChange={e => updateRemarks(obj.ptmStudentId, e.target.value)}
                          />
                        </td>
                        <td className='text-center'>
                          <span
                            className='glyphicon glyphicon-floppy-disk'
                            onClick={e => saveAttendance(obj.ptmStudentId)}
                            title='Save Attendance'
                          ></span>
                          <span
                            className='glyphicon glyphicon-comment'
                            data={JSON.stringify(obj)}
                            title='Save Feedback'
                            onClick={
                              e =>
                                window.open(
                                  '/stufeedback?name=' +
                                  obj.name +
                                  '&ptmStudentId=' +
                                  obj.ptmStudentId +
                                  '&sectionId=' +
                                  sectionId,
                                  '_blank'
                                )
                              // openPopup(obj.studentId, obj.classSectionId, e)
                            }
                          ></span>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default PtmAttendanceSlider
