import React, { useEffect, useState } from "react";


const ChapterTable = (props) => {

    const [chapterId, setChapterId] = useState(0);

    useEffect(() => {
        // console .log("ChapterTable called");
    }, []);

    const handleRowClick = (pChapterId, pChapterCode, pChapterName, pPeriods) => {
        // console .log("handleRowClick called, chapterId", pChapterId);
        setChapterId(pChapterId);
        props.rowClick(pChapterId, pChapterCode, pChapterName, pPeriods);
    }

    return (
        <div>
            <table className="tableLayout1" id='customTable'>
                <thead>
                    <tr>
                        <th width="50">S.No.</th>
                        <th width="100">Chapter</th>
                        <th width="150">Name</th>
                        <th width="100">Periods</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDivMedium">
                <table id="mainTable" className='tableLayout'>
                    <tbody id="chapterTableBody">
                        {props.lstChapters && props.lstChapters.length !== 0 && props.lstChapters.map((chapter, index) => (
                            <tr
                                key={chapter.chapterId}
                                id={chapter.chapterId}
                                className={chapter.chapterId === chapterId ? "selected" : ""}
                                onClick={() => handleRowClick(chapter.chapterId, chapter.chapterCode, chapter.chapterName, chapter.periods)}
                            >
                                <td width="50" className="text-left">{index + 1}</td>
                                <td width="100" className="text-left">{chapter.chapterCode}</td>
                                <td width="150" className="text-left">{chapter.chapterName}</td>
                                <td width="100" className="text-left">{chapter.periods}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default ChapterTable;