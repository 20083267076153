import React, { useState, useEffect } from "react";
import LblTxt from "./LblTxt";
import axios from "axios";
import DropDown from "./DropDown";
import AnchorLbl from "./AnchorLbl";
import { toast, ToastContainer } from "react-toastify";
import lstModules from "common/json/lstModules.json";
import SliderType from "./sliderType";

var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;

const RegistrationParents = (props) => {
  const [father, setFather] = useState({});
  const [mother, setMother] = useState({});
  const [guardian, setGuardian] = useState({});
  const [lstState, setLstState] = useState([]);
  const [lstFatherCity, setLstFatherCity] = useState([]);
  const [lstFatherLocality, setLstFatherLocality] = useState([]);
  const [lstMotherCity, setLstMotherCity] = useState([]);
  const [lstMotherLocality, setLstMotherLocality] = useState([]);
  const [lstGuardianCity, setLstGuardianCity] = useState([]);
  const [lstGuardianLocality, setLstGuardianLocality] = useState([]);
  const [regsId, setRegsId] = useState(0);
  const [loader, setLoader] = useState("none");
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    if (props.flgSliderType === SliderType.SLIDER_REGISTER) {
      if (props.regsId) {
        setRegsId(props.regsId);
        setLoader("block");
        getStateListSvc(cbStateListResponse);
        getParentDtlsSvc(props.regsId, cbParentDtlsResponse);
      }
    }
    return () => {
      controller.abort();
    };
  }, [props.regsId, props.flgSliderType]);

  function cbParentDtlsResponse(obj) {
    if (obj.SvcStatus === "Success") {
      getFatherCityListSvc(cbFatherCityListResponse, obj.parent.father.stateId);
      getFatherLocalityListSvc(
        cbFatherLocalityListResponse,
        obj.parent.father.cityId
      );
      getMotherCityListSvc(cbMotherCityListResponse, obj.parent.mother.stateId);
      getMotherLocalityListSvc(
        cbMotherLocalityListResponse,
        obj.parent.mother.cityId
      );
      getGuardianCityListSvc(
        cbGuardianCityListResponse,
        obj.parent.guardian.stateId
      );
      getGuardianLocalityListSvc(
        cbGuardianLocalityListResponse,
        obj.parent.guardian.cityId
      );
      setFather(obj.parent.father);
      setMother(obj.parent.mother);
      setGuardian(obj.parent.guardian);
    } else {
      toast.error(obj.SvcMsg);
    }
    setLoader("none");
  }

  function getParentDtlsSvc(regsId, callBack) {
    axios({
      url: httpURL + "/sms/regs/parent/dtls",
      method: "POST",
      params: { regsId: regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getStateListSvc(callBack) {
    axios({
      url: httpURLCommon + "/sms/mst/state/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getFatherCityListSvc(callBack, pstateId) {
    axios({
      url: httpURLCommon + "/sms/mst/city/list",
      method: "POST",
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getFatherLocalityListSvc(callBack, pcityId) {
    axios({
      url: httpURLCommon + "/sms/mst/locality/list",
      method: "POST",
      params: { cityId: pcityId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getMotherCityListSvc(callBack, pstateId) {
    axios({
      url: httpURLCommon + "/sms/mst/city/list",
      method: "POST",
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getMotherLocalityListSvc(callBack, pcityId) {
    axios({
      url: httpURLCommon + "/sms/mst/locality/list",
      method: "POST",
      params: { cityId: pcityId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getGuardianCityListSvc(callBack, pstateId) {
    axios({
      url: httpURLCommon + "/sms/mst/city/list",
      method: "POST",
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getGuardianLocalityListSvc(callBack, pcityId) {
    axios({
      url: httpURLCommon + "/sms/mst/locality/list",
      method: "POST",
      params: { cityId: pcityId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function cbStateListResponse(pLstState) {
    if (pLstState.SvcStatus === "Success") {
      setLstState(pLstState.lstState);
    } else {
      toast.error(pLstState.SvcMsg);
    }
    setLoader("none");
  }
  function handleFatherStateChange(pStateId) {
    setFather({ ...father, stateId: "" + pStateId });
    getFatherCityListSvc(cbFatherCityListResponse, pStateId);
  }
  function handleFatherCityChange(pCityId) {
    // console .log(pCityId);
    // console .log({ ...father, cityId: pCityId });
    setFather({ ...father, cityId: "" + pCityId });
    getFatherLocalityListSvc(cbFatherLocalityListResponse, pCityId);
  }

  function handleFatherLocalityChange(pLocalityId) {
    // console .log(pLocalityId);
    setFather({ ...father, localityId: "" + pLocalityId });
  }
  function handleMotherStateChange(pStateId) {
    setMother({ ...mother, stateId: pStateId });
    getMotherCityListSvc(cbMotherCityListResponse, pStateId);
  }
  function handleMotherCityChange(pCityId) {
    setMother({ ...mother, cityId: pCityId });
    getMotherLocalityListSvc(cbMotherLocalityListResponse, pCityId);
  }
  function handleMotherLocalityChange(pLocalityId) {
    setMother({ ...mother, localityId: pLocalityId });
  }
  function handleGuardianStateChange(pStateId) {
    setGuardian({ ...guardian, stateId: pStateId });
    getGuardianCityListSvc(cbGuardianCityListResponse, pStateId);
  }
  function handleGuardianCityChange(pCityId) {
    setGuardian({ ...guardian, cityId: pCityId });
    getGuardianLocalityListSvc(cbGuardianLocalityListResponse, pCityId);
  }
  function handleGuardianLocalityChange(pLocalityId) {
    setGuardian({ ...guardian, localityId: pLocalityId });
  }

  function cbFatherCityListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstFatherCity(data.lstCity);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbFatherLocalityListResponse(data, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstFatherLocality(data.lstLocality);
      // setFather({ ...father, localityId: pLocalityId })
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbMotherCityListResponse(pLstCity) {
    if (pLstCity.SvcStatus === "Success") {
      setLstMotherCity(pLstCity.lstCity);
    } else {
      toast.error(pLstCity.SvcMsg);
    }
    setLoader("none");
  }

  function cbMotherLocalityListResponse(data, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstMotherLocality(data.lstLocality);
      // setMother({ ...mother, localityId: pLocalityId })
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbGuardianCityListResponse(pLstCity) {
    if (pLstCity.SvcStatus === "Success") {
      setLstGuardianCity(pLstCity.lstCity);
    } else {
      toast.error(pLstCity.SvcMsg);
    }
    setLoader("none");
  }
  function cbGuardianLocalityListResponse(data, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstGuardianLocality(data.lstLocality);
      // setGuardian({ ...guardian, localityId: pLocalityId })
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveParents() {
    var parent = {
      father: father,
      mother: mother,
      guardian: guardian,
    };
    setLoader("block");
    saveParentsSvc(parent, cbParentSaveResponse);
  }
  function cbParentSaveResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveParentsSvc(parent, callBack) {
    parent = JSON.stringify(parent);
    axios({
      url: httpURL + "/sms/regs/parent/save",
      method: "POST",
      params: { regsId: regsId, parent: parent },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        callBack({});
        console.error(error);
      });
  }
  function setFatherName(pFName) {
    setFather({ ...father, name: pFName });
  }

  function setFatherPhone(pVal) {
    setFather({ ...father, phoneNo: pVal });
  }

  function setFatherEmail(pVal) {
    setFather({ ...father, email: pVal });
  }

  function setFatherAddress1(pVal) {
    setFather({ ...father, address1: pVal });
  }

  function setFatherPin(pVal) {
    setFather({ ...father, pin: pVal });
  }
  function setMotherName(pFName) {
    setMother({ ...mother, name: pFName });
  }

  function setMotherPhone(pVal) {
    setMother({ ...mother, phoneNo: pVal });
  }

  function setMotherEmail(pVal) {
    setMother({ ...mother, email: pVal });
  }

  function setMotherAddress1(pVal) {
    setMother({ ...mother, address1: pVal });
  }

  function setMotherPin(pVal) {
    setMother({ ...mother, pin: pVal });
  }
  function setGuardianName(pFName) {
    setGuardian({ ...guardian, name: pFName });
  }

  function setGuardianPhone(pVal) {
    setGuardian({ ...guardian, phoneNo: pVal });
  }

  function setGuardianEmail(pVal) {
    setGuardian({ ...guardian, email: pVal });
  }

  function setGuardianAddress1(pVal) {
    setGuardian({ ...guardian, address1: pVal });
  }

  function setGuardianPin(pVal) {
    setGuardian({ ...guardian, pin: pVal });
  }
  return (
    <>
      <div
        className="col-sm-12"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "1%",
        }}
      >
        <div
          className="col-sm-12"
          style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: "fixed",
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: "50%",
              top: "50%",
              display: loader,
            }}
          >
            <img
              className="loderImg"
              src={require("common/images/loader.gif")}
              alt="Loader..."
            />
          </div>
          <div className="col-sm-3" style={{ background: "transparent" }}>
            <LblTxt
              label="Name:"
              dataType="text"
              dataValue={father.name}
              onChange={setFatherName}
            ></LblTxt>
            <LblTxt
              label="Phone No:"
              dataType="text"
              dataValue={father.phoneNo}
              onChange={setFatherPhone}
            ></LblTxt>
            <LblTxt
              label="Address1:"
              dataType="text"
              dataValue={father.address1}
              onChange={setFatherAddress1}
            ></LblTxt>

            <DropDown
              label="State:"
              onChangeDropDown={handleFatherStateChange}
              option1="State"
              lst={lstState}
              dataValue={"" + father.stateId}
              keyId="stateId"
              keyVal="state"
            ></DropDown>
            <DropDown
              label="City:"
              option1="City"
              onChangeDropDown={handleFatherCityChange}
              lst={lstFatherCity}
              dataValue={"" + father.cityId}
              keyId="cityId"
              keyVal="city"
            ></DropDown>
            <DropDown
              label="Locality:"
              option1="Locality"
              onChangeDropDown={handleFatherLocalityChange}
              lst={lstFatherLocality}
              dataValue={"" + father.localityId}
              keyId="localityId"
              keyVal="locality"
            ></DropDown>
            <LblTxt
              label="Pin:"
              dataType="text"
              dataValue={father.pin}
              onChange={setFatherPin}
            ></LblTxt>
            <LblTxt
              label="Email:"
              dataType="text"
              dataValue={father.email}
              onChange={setFatherEmail}
            ></LblTxt>
            <AnchorLbl
              dataValue={father.flgEmailVerify}
              anchorLabel="Reverify"
            ></AnchorLbl>
          </div>
          <div
            className="col-sm-offset-1 col-sm-3"
            style={{ background: "transparent" }}
          >
            <LblTxt
              label="Name:"
              dataType="text"
              dataValue={mother.name}
              onChange={setMotherName}
            ></LblTxt>
            <LblTxt
              label="Phone No:"
              dataType="text"
              dataValue={mother.phoneNo}
              onChange={setMotherPhone}
            ></LblTxt>
            <LblTxt
              label="Address1:"
              dataType="text"
              dataValue={mother.address1}
              onChange={setMotherAddress1}
            ></LblTxt>
            <DropDown
              label="State:"
              onChangeDropDown={handleMotherStateChange}
              option1="State"
              key={"ms_" + mother.stateId}
              lst={lstState}
              dataValue={mother.stateId}
              keyId="stateId"
              keyVal="state"
            ></DropDown>
            <DropDown
              label="City:"
              onChangeDropDown={handleMotherCityChange}
              option1="City"
              key={"mc_" + mother.stateId + "_mc_" + mother.cityId}
              lst={lstMotherCity}
              dataValue={mother.cityId}
              keyId="cityId"
              keyVal="city"
            ></DropDown>
            {/* <LblTxt label='State:' dataType='text' dataValue={mother.stateId}></LblTxt>
        <LblTxt label='City:' dataType='text' dataValue={mother.cityId}></LblTxt>
         */}
            <DropDown
              label="Locality:"
              option1="Locality"
              onChangeDropDown={handleMotherLocalityChange}
              key={"ml_" + mother.localityId}
              lst={lstMotherLocality}
              dataValue={mother.localityId}
              keyId="localityId"
              keyVal="locality"
            ></DropDown>
            <LblTxt
              label="Pin:"
              dataType="text"
              dataValue={mother.pin}
              onChange={setMotherPin}
            ></LblTxt>
            <LblTxt
              label="Email:"
              dataType="text"
              dataValue={mother.email}
              onChange={setMotherEmail}
            ></LblTxt>
            <AnchorLbl
              dataValue={mother.flgEmailVerify}
              anchorLabel="Reverify"
            ></AnchorLbl>
          </div>
          <div className="col-sm-offset-1 col-sm-3">
            <LblTxt
              label="Name:"
              dataType="text"
              dataValue={guardian.name}
              onChange={setGuardianName}
            ></LblTxt>
            <LblTxt
              label="Phone No:"
              dataType="text"
              dataValue={guardian.phoneNo}
              onChange={setGuardianPhone}
            ></LblTxt>
            <LblTxt
              label="Address1:"
              dataType="text"
              dataValue={guardian.address1}
              onChange={setGuardianAddress1}
            ></LblTxt>
            {/* <LblTxt
              label='Address2:'
              dataType='text'
              dataValue={guardian.address2}
            ></LblTxt> */}
            <DropDown
              label="State:"
              onChangeDropDown={handleGuardianStateChange}
              option1="State"
              lst={lstState}
              dataValue={guardian.stateId}
              keyId="stateId"
              keyVal="state"
            ></DropDown>
            <DropDown
              label="City:"
              onChangeDropDown={handleGuardianCityChange}
              option1="City"
              lst={lstGuardianCity}
              dataValue={guardian.cityId}
              keyId="cityId"
              keyVal="city"
            ></DropDown>
            {/* <LblTxt label='State:' dataType='text' dataValue={guardian.stateId}></LblTxt>
        <LblTxt label='City:' dataType='text' dataValue={guardian.cityId}></LblTxt>
         */}
            <DropDown
              label="Locality:"
              option1="Locality"
              onChangeDropDown={handleGuardianLocalityChange}
              lst={lstGuardianLocality}
              dataValue={guardian.localityId}
              keyId="localityId"
              keyVal="locality"
            ></DropDown>
            <LblTxt
              label="Pin:"
              dataType="text"
              dataValue={guardian.pin}
              onChange={setGuardianPin}
            ></LblTxt>
            <LblTxt
              label="Email:"
              dataType="text"
              dataValue={guardian.email}
              onChange={setGuardianEmail}
            ></LblTxt>
            <AnchorLbl
              dataValue={guardian.flgEmailVerify}
              anchorLabel="Reverify"
            ></AnchorLbl>
          </div>
        </div>
        <div className="col-sm-11">
          <button
            type="button"
            className="btn-info btn pull-right"
            onClick={(e) => saveParents()}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default RegistrationParents;
