import React, { useState, useEffect } from 'react';
import lstModules from 'common/json/lstModules.json';
import CampaignTable from './CampaignTable';
import CampaignForm from './CampaignForm';
import CampaignContent from './CampaignContent';
import '../css/campaign.css';
import Header from 'common/js/header/header'
import axios from 'axios';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var httpURL = lstModules['campaign'].url + ":" + lstModules['campaign'].port;
var queryString = require('querystring');
var screenId = 34;
var contentFile = "";
var lstCampaigns = [];
var lstContents = [];
var lstContentId = [];

export default function Campaign() {

    const [state, setState] = useState(
        {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: localStorage.getItem('tokenid'),
            lstCampaign: [],
            campaignId: 0,
            lstContent: [],
            checkedAll: false,
        });
    useEffect(() => {
        getTableList()
    }, [])

    function getTableList() {
        setState({ ...state, loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/campaign/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            // console .log(response.data)
            if (response.data.SvcStatus === 'Success') {
                setState({
                    ...state,
                    lstCampaign: response.data.lstCampaign,
                });
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            // setState({ ...state ,loader: 'none', opacity: 1 });
        }).catch((error) => {
            //  setState({ ...state ,loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    function saveCampaign({ campaign,
        remarks,
        startDt,
        endDt }) {
        var campaignObj = {};
        var campaignId = 0;

        if (state.flg) {
            campaignId = 0;
        }
        else {
            campaignId = state.campaignId;
            contentFile = null;
        }
        campaignObj = {
            campaignId,
            campaign,
            remarks,
            startDt,
            endDt
        }
        setState({ ...state, loader: 'block', opacity: 0.5 })
        axios.post(httpURL + '/sms/campaign/save',
            queryString.stringify({ 'campaign': JSON.stringify(campaignObj), screenId }), {
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                toast.success(response.data.SvcMsg);
                getTableList();
                addNewItem();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            setState({ ...state, loader: 'none', opacity: 1 });
        }).catch((e) => {
            setState({ ...state, loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });

    }
    function addNewItem() {
        $('#addForm').trigger('reset');
        $('#campaignTableBody tr').removeClass('selected');
        setState({
            ...state,
            flg: true,
            campaignId: 0
        });
    }

    function setForm(campaignId) {
        setState({
            ...state,
            flg: false,
            campaignId: campaignId,
        })
    }



    function deleteRow(campaignId) {
        var retVal = window.confirm("Do you want to Delete This Campaign ?");
        if (retVal == true) {
            setState({ ...state, loader: 'block', opacity: 0.5 });
            axios.post(httpURL + '/sms/campaign/delete',
                queryString.stringify({ campaignId, screenId }),
                {
                    headers: { tokenid: state.tokenid },
                    withCredentials: true
                }
            ).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    toast.success(response.data.SvcMsg);
                    getTableList();
                } else {
                    toast.error(response.data.SvcMsg);
                }
                setState({ ...state, loader: 'none', opacity: 1 });
            }).catch((e) => {
                setState({ ...state, loader: 'none', opacity: 1 });
                toast.error('Service failed: ' + e);
            });
            return true;
        } else {
            return false;
        }
    }
    function showContent(campaignId) {
        setState({
            ...state, loader: 'block', opacity: 0.5,
        });
        axios.post(httpURL + '/sms/campaign/listCampaignContent',
            queryString.stringify({ campaignId, screenId }),
            {
                headers: { tokenid: state.tokenid },
                withCredentials: true,
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                setState({
                    ...state, lstContent: response.data.lstCampaignContent,
                });
                lstContents = state.lstContent
                $('body').addClass('openSlider');
            } else {
                toast.error(response.data.SvcMsg);
            }
            setState({ ...state, loader: 'none', opacity: 1 });
        }).catch((e) => {
            setState({ ...state, loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    function saveCampaignContent() {
        lstContentId = [];
        var campaignContent = {};
        var campaignId = $('#campaignTableBody tr.selected').find("td:eq(0)").text();
        $('#campaignContentTableBody tr').each(function () {
            if ($(this).find($('input[type = checkbox]')).prop('checked') === true) {
                var contentId = $(this).find('td:eq(0)').text();
                lstContentId.push(contentId);
            }
        });

        if (lstContentId.length === 0) {
            toast.warning('Please Select Altleast one Content');
            return false;
        }
        campaignContent = {
            campaignId, lstContentId
        };

        setState({ ...state, loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/campaign/saveCampaignContent',
            queryString.stringify({ "campaignContent": JSON.stringify(campaignContent), screenId }),
            {
                headers: { tokenid: state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                getTableList();
                $('body').removeClass('openSlider');
            } else {
                toast.error(response.data.SvcMsg);
            }
            setState({ ...state, loader: 'none', opacity: 1 });
        }).catch((e) => {
            setState({ ...state, loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    function filterTable(campaign) {
        if (campaign !== "") {
            const filterCampaign = state.lstCampaign.filter(campaigns =>
                campaigns.campaign.toLowerCase().includes(campaign.toLowerCase()));
            setState({
                ...state, lstCampaign: filterCampaign
            })
        }
        else {
            setState({
                ...state, lstCampaign: lstCampaigns
            })
        }
    }
    function selectAll() {
        let { lstContent } = state;
        lstContent.forEach(group => {
            group.selected = state.checkedAll;
        });
        setState({ lstContent, checkedAll: state.checkedAll });
    };

    function checkedClick(event) {
        let target = event.target;
        let { lstContent, checkedAll } = state;
        if (Array.isArray(state.lstContent) &&
            target.id != null &&
            state.lstContent[target.id] != null) {
            //toggele the current checkbox
            lstContent[target.id].selected = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !lstContent.some(group => !group.selected);
            setState({ lstContent, checkedAll });
        }
    };
    function closeSlider() {
        $('body').removeClass('openSlider');
    }

    function getWingId(wingId) {
        // console .log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer Timing" style={{ opacity: state.opacity }}>
                <div className="col-md-11 col-centered">
                    <div className="col-xs-7 padding-remove">
                        <CampaignTable lstCampaign={state.lstCampaign}
                            setForm={setForm}
                            deleteRow={deleteRow}
                            filterTable={filterTable}
                            showContent={showContent} />
                    </div>
                    <div className="col-xs-1 ">
                        <span id="addIcon" title="Add" onClick={addNewItem} className="addIcon glyphicon glyphicon-plus-sign"></span>
                    </div>
                    <div className="col-xs-4 padding-remove">
                        <CampaignForm
                            campaignId={state.campaignId}
                            saveCampaign={saveCampaign}
                        />
                    </div>
                </div>
                <div className="col-xs-8 padding-remove slider halfWidthSlider" >
                    <div className="col-xs-8">
                        <CampaignContent
                            closeSlider={closeSlider}
                            lstContent={state.lstContent}
                            selectAll={selectAll}
                            checkedClick={checkedClick}
                            saveCampaignContent={saveCampaignContent}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

