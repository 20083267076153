import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { getLstSubjectSvc, deleteSubjectSvc } from 'sms/setup/js/SubjectTableSvc';

const SubjectTable = (props) => {

    const [lstSubject, setLstSubject] = useState([]);
    const [lstSubjectFiltered, setLstSubjectFiltered] = useState([]);
    const [filterSubject, setFilterSubject] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterEvaluation, setFilterEvaluation] = useState("");
    

    useEffect(() => {
        getLstSubjectSvc(cbLstSubjectResponse);
    }, [props.subjectTableKey])

    function cbLstSubjectResponse(data) {
        // console .log(data)
        if (data.status === 'Success') {
            setLstSubject(data.lstSubject);
            setLstSubjectFiltered(data.lstSubject);
        } else {
            toast.error(data.message)
        }
    }
    function deleteSubject(subId) {
        deleteSubjectSvc(subId, handleDeleteSubResponse)
    }
    function handleDeleteSubResponse(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)

        } else {
            toast.error(data.SvcMsg)
        }
    }

    const filterSubjects = (fSubject, fType, fEvaluation) => {
        let filteredLstSubject = [];

        if (lstSubject && lstSubject.length) {
            filteredLstSubject = lstSubject.filter(subject =>
                subject.sub.toLowerCase().includes(fSubject.toLowerCase()));

            filteredLstSubject = filteredLstSubject.filter(subject =>
                subject.subType.toLowerCase().includes(fType.toLowerCase()));

            filteredLstSubject = filteredLstSubject.filter(subject =>
                subject.evaluation.toLowerCase().includes(fEvaluation.toLowerCase()));

        }
        setLstSubjectFiltered(filteredLstSubject);
    }

    const handleFilterSubject = (fSubject) => {
        setFilterSubject(fSubject);
        filterSubjects(fSubject, filterType, filterEvaluation);
    }

    const handleFilterType = (fType) => {
        setFilterType(fType);
        filterSubjects(filterSubject, fType, filterEvaluation);
    }

    const handleFilterEvaluation = (fEval) => {
        setFilterEvaluation(fEval);
        filterSubjects(filterSubject, filterType, fEval);
    }


    const colgrp = <colgroup>
        <col width="65%"></col>
        <col width="35%"></col>
        <col width="50%"></col>
        <col width="30%"></col>
        <col width="35%"></col>
        <col width="35%"></col>
        <col width="35%"></col>
        <col width="35%"></col>
        <col width="50%"></col>
    </colgroup>

    return (
        <div>
            <table className="tableLayout1">
                {colgrp}
                <thead>
                    <tr>
                        <th>
                            <input
                                type="text"
                                className="form-control inp-search"
                                name="Subject"
                                defaultValue={filterSubject}
                                onChange={(e) => handleFilterSubject(e.target.value)}
                                placeholder="Subject" />
                        </th>
                        <th>Language</th>
                        <th>
                            <input
                                type="text"
                                className="form-control inp-search"
                                name="Subject Type"
                                value={filterType}
                                onChange={(e) => handleFilterType(e.target.value)}
                                placeholder="Type" />
                        </th>
                        <th>
                            <input
                                type="text"
                                className="form-control inp-search"
                                name="Evaluation Type"
                                value={filterEvaluation}
                                onChange={(e) => handleFilterEvaluation(e.target.value)}
                                placeholder="Evaluation" />
                        </th>
                        <th>Optional</th>
                        <th>Vocational</th>
                        <th>Split Allowed</th>
                        <th>Has Practical</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>

            <div className="tablebodyWrapper">
                <table className="tableLayout">
                    {colgrp}
                    <tbody>
                        {lstSubjectFiltered && lstSubjectFiltered.length ?
                            lstSubjectFiltered.map((value, key) => {
                                var selected = props.subjectId === value.subId;
                                return (
                                    < tr className={selected ? "selected" : ""} key={key} onClick={(e) => props.setSubjectId(value.subId)}>
                                        <td className='text-left'>{value.sub}</td>
                                        <td className='text-left'>{value.language}</td>
                                        <td className='text-left'>{value.subType}</td>
                                        <td className='text-center'>{value.evaluation}</td>
                                        <td className='text-center'>{value.flgOptional ? 'Yes' : ''}</td>
                                        <td className='text-center'>{value.flgVocational ? 'Yes' : ''}</td>
                                        <td className='text-center'>{value.flgSplitAllowed ? 'Yes' : ''}</td>
                                        <td className='text-center'>{value.flgHasPractical ? 'Yes' : ''}</td>
                                        <td className='text-center'>
                                            <span className="glyphicon glyphicon-edit" onClick={(e) => props.setSubjectData(value.subId, value)}></span>
                                            <span className="glyphicon glyphicon-remove" onClick={(e) => deleteSubject(value.subId)}></span></td>
                                    </tr>
                                );
                            }) : null
                        }
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default SubjectTable