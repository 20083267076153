import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify'
import {
  saveExamSvc,
  deleteExamSvc,
  getLstTypeSvc,
  getLstReportCardSvc
} from './ExamFormSvc'

const ExamForm = props => {
  const [formLoader, setFormLoader] = useState('none')
  const [examObj, setExamObj] = useState({})
  const [exam, setExam] = useState('');
  const [term, setTerm] = useState('');
  const [termId, setTermId] = useState(0);
  const [examId, setExamId] = useState(0);
  const [typeId, setTypeId] = useState(0);
  const [weight, setWeight] = useState('');
  const [examOrder, setExamOrder] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [passScore, setPassScore] = useState(0);
  const [fromDt, setFromDt] = useState(null);
  const [toDt, setToDt] = useState(null);
  const [reportCardId, setReportCardId] = useState(0)
  const [lstReportCard, setLstReportCard] = useState([
    { reportCardId: 10, reportCard: 'Standalone' },
    { reportCardId: 20, reportCard: 'Combined' }
  ])
  const [lstType, setLstType] = useState([
    { typeId: 10, type: 'Test' },
    { typeId: 20, type: 'Exam' }
  ])

  useEffect(() => {
    setTermId(props.termId)
    setTerm(props.term)
    setExamObj(props.examObj)
    setExamId(props.examObj.examId)
    setExam(props.examObj.exam)

    setExamOrder(props.examObj.examOrder)
    setWeight(props.examObj.weight)
    setMaxScore(parseInt(props.examObj.maxScore))
    setPassScore(parseInt(props.examObj.passScore))

    getLstTypeSvc(cbLstTypeResponse, props.examObj.typeId)
    getLstReportCardSvc(cbLstReportCardResponse, props.examObj.reportCardId)
  }, [props.termId, props.term, props.examObj])

  function handleChangeExam(pExam) {
    setExam(pExam)
  }

  function cbLstTypeResponse(data, pTypeId) {
    if (data.status === 'Success') {
      setLstType(data.lstType)
      setTypeId(pTypeId)
    } else {
      toast.error(data.message)
    }
  }

  function cbLstReportCardResponse(data, pReportCardId) {
    if (data.status === 'Success') {
      setLstReportCard(data.lstReportCard)
      setReportCardId(pReportCardId)
    } else {
      toast.error(data.message)
    }
  }

  function handleChangeType(pTypeId) {
    setTypeId(pTypeId)
  }

  function handleChangeWeight(pWeight) {
    setWeight(pWeight)
  }

  function handleChangeReportCard(pReportCardId) {
    setReportCardId(pReportCardId)
  }

  function handleChangeExamOrder(pExamOrder) {
    setExamOrder(pExamOrder)
  }

  function handleChangeMaxScore(pMaxScore) {
    setMaxScore(parseInt(pMaxScore))
  }

  function handleChangePassScore(pPassScore) {
    setPassScore(parseInt(pPassScore))
  }

  function saveExam() {
    props.setFormLoader('block')
    if (maxScore < passScore) {
      toast.warn('Pass Score cannot exceed Max. Score')
      return
    }
    if (exam === '') {
      toast.warn('Please enter exam')
      return
    }
    if (reportCardId === 0) {
      toast.warn('Please select Report Card')
      return
    }
    if (typeId === 0) {
      toast.warn('Please select Type')
      return
    }
    if (weight === 0 || weight === "") {
      toast.warn('Please enter weight')
      return
    }
    if (passScore === 0 || passScore === "") {
      toast.warn('Please enter pass score')
      return
    }
    if (maxScore === 0 || maxScore === "") {
      toast.warn('Please enter max. score')
      return
    }
    var vExamObj = {
      examId: examId,
      termId: termId,
      typeId: typeId,
      reportCardId: reportCardId,
      weight: weight,
      order: examOrder || 0,
      exam: exam,
      maxScore: maxScore,
      passScore: passScore
    }
    setExamObj(vExamObj)
    saveExamSvc(vExamObj, cbExamResponse)
  }

  function cbExamResponse(data, examObj) {
    props.setFormLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      var vExamId = examId;
      var vExamObj = examObj;
      if (!vExamId) {
        vExamId = data.examId
        setExamId(data.examId)
        // setExamId(0)
        vExamObj.examId = vExamId;
        setExamObj(vExamObj)
      }
      props.refreshTable(vExamId)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function deleteExam() {
    var confirmed = window.confirm('Are you sure to delete the exam?')
    if (confirmed) {
      props.setFormLoader('block')
      deleteExamSvc(examId, cbDeleteExamResponse)
      // console .log(props)
      props.refreshTable()
      props.clickExam({
        examId: undefined,
        exam: "",
        examOrder: "",
        weight: "",
        typeId: 0,
        reportCardId: 0,
        passScore: "",
        maxScore: "",
      });
    }
  }

  function cbDeleteExamResponse(data, examId) {
    props.setFormLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function cancelExam() {
    // console .log(props.examObj)
    if (examId === 0) {
      setExamObj({})
      setExamId(0)
      setExam('')
      setTypeId(0)
      setReportCardId(0)
      setExamOrder(0)
      setWeight(0)
      setMaxScore(0)
      setPassScore(0)
    } else {
      setExamObj(props.examObj)
      setExamId(props.examObj.examId)
      setExam(props.examObj.exam)
      setTypeId(props.examObj.typeId)
      setReportCardId(props.examObj.reportCardId)
      setExamOrder(props.examObj.examOrder)
      setWeight(props.examObj.weight)
      setMaxScore(props.examObj.maxScore)
      setPassScore(props.examObj.passScore)
    }
  }

  function dtToDMY(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [day, mnth, date.getFullYear()].join('-')
  }
  function dmyToDate(dateString) {
    if (!dateString || !/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return null;
    }
    const parts = dateString.split('-');
    const [day, month, year] = parts;
    return new Date(year, month - 1, day);
  }
  function handleFromDtChange(pDt) {
    // console .log("handleFromDtChange called, pDt", pDt, ", dtToDMY(pDt)", dtToDMY(pDt));
    setFromDt(dtToDMY(pDt))
  }
  function handleToDtChange(pDt) {
    // console .log("handleToDtChange called, pDt", pDt, ", dtToDMY(pDt)", dtToDMY(pDt));
    setToDt(dtToDMY(pDt));
  }

  return (
    <form id='examAddForm' className='form-horizontal formWrapper'>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Term</label>
        <div className='col-sm-6 padding-remove'>
          <input
            value={term}
            autoComplete='off'
            name='term'
            id='term'
            type='text'
            className='form-control '
            placeholder='Enter Term'
            readOnly
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>
          Exam<span style={{ color: 'red', marginLeft: 2 }}>*</span>
        </label>
        <div className='col-sm-6 padding-remove'>
          <input
            value={exam}
            autoComplete='off'
            name='exam'
            id='exam'
            type='text'
            className='form-control '
            placeholder='Enter Exam'
            onChange={e => handleChangeExam(e.target.value)}
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Type</label>
        <div className='col-sm-6 padding-remove'>
          <select
            className='form-control'
            value={typeId || 0}
            onChange={e => handleChangeType(e.target.value)}
          >
            <option value={0}>Type</option>
            {lstType.map((obj, idx) => {
              return (
                <option key={idx} value={obj.typeId}>
                  {obj.type}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Weight</label>
        <div className='col-sm-6 padding-remove'>
          <input
            value={weight}
            autoComplete='off'
            name='weight'
            id='weight'
            type='number'
            className='form-control text-right'
            // placeholder='Enter Weight'
            placeholder='0'
            onChange={e => handleChangeWeight(e.target.value)}
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Report Card</label>
        <div className='col-sm-6 padding-remove'>
          <select
            className='form-control'
            value={reportCardId || 0}
            onChange={e => handleChangeReportCard(e.target.value)}
          >
            <option value={0}>Report Card</option>
            {lstReportCard.map((obj, idx) => {
              return (
                <option key={idx} value={obj.reportCardId}>
                  {obj.reportCard}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Exam Order</label>
        <div className='col-sm-6 padding-remove'>
          <input
            value={examOrder}
            autoComplete='off'
            name='examOrder'
            id='examOrder'
            type='number'
            className='form-control text-right'
            // placeholder='Enter Exam Order'
            placeholder='0'
            onChange={e => handleChangeExamOrder(e.target.value)}
          />
        </div>
      </div>

      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Max. Score</label>
        <div className='col-sm-6 padding-remove'>
          <input
            value={maxScore}
            autoComplete='off'
            name='maxScore'
            id='maxScore'
            type='number'
            className='form-control text-right'
            // placeholder='Enter Max. Score'
            placeholder='0'
            onChange={e => handleChangeMaxScore(e.target.value)}
          />
        </div>
      </div>

      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>Pass Score</label>
        <div className='col-sm-6 padding-remove'>
          <input
            value={passScore}
            autoComplete='off'
            name='passScore'
            id='passScore'
            type='number'
            className='form-control text-right'
            // placeholder='Enter Pass Score'
            placeholder='0'
            onChange={e => handleChangePassScore(e.target.value)}
          />
        </div>
      </div>
      {/* <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>From Date</label>
        <div className='col-sm-3 padding-remove'>
          <DatePicker
            className="form-control text-center"
            placeholderText="From Date"
            selected={dmyToDate(fromDt)}
            dateFormat="dd-MM-yyyy"
            onChange={handleFromDtChange}
            autoComplete="off"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label col-sm-5 text-left'>To Date</label>
        <div className='col-sm-3 padding-remove'>
          <DatePicker
            className="form-control text-center"
            placeholderText="To Date"
            selected={dmyToDate(toDt)}
            dateFormat="dd-MM-yyyy"
            onChange={handleToDtChange}
            autoComplete="off"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        </div>
      </div> */}
      <div className='form-group'>
        <div
          className='col-sm-12 text-center userButtonWrap'
          style={{ marginTop: 20 }}
        >
          <button
            type='button'
            title='Save'
            onClick={() => saveExam()}
            className='btn btn-info'
          >
            Save
          </button>
          <button
            type='button'
            title='Cancel'
            onClick={() => cancelExam()}
            className='btn btn-info'
          >
            Cancel
          </button>
          <button
            type='button'
            title='Delete'
            onClick={() => deleteExam()}
            className='btn btn-danger deleteButton'
          >
            Delete
          </button>
        </div>
      </div>
    </form>
  )
}

export default ExamForm
