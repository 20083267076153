import React, { useState, useEffect } from 'react';
import 'jquery-ui';
import 'common/css/master.css';

const TTSubjectTblBody = (props) => {
    const [lstSub, setLstSub] = useState(props.lstSub)
    const [session, setSession] = useState(props.session);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [found, setFound] = useState([])
    const [subject, setSubject] = useState("")
    useEffect(() => {
        // console .log("lstSub: " + lstSub)
        setLstSub(props.lstSub)
        setSession(props.session);
        setSessionId(props.sessionId);
        setFound(props.lstSub)
    }, [props]);
    const rowClickInMain = (pSubId) => {
        props.setCellSubId(pSubId)
        props.setSelectedSubjectId(pSubId)
        props.setPosDayPeriod();
        // var subId = e.currentTarget.getAttribute("subId");
        // console .log(subId)
    }
    const filterSubject = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = found.filter((user) => {
                return user.sub.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFound(results);
        } else {
            setFound(lstSub);
            // If the text field is empty, show all users
        }
        setSubject(keyword);
    };
    return (
        <>
            <table className="tableLayout1" style={{ width: 200 }} key={sessionId}>
                <thead>
                    <tr>
                        <th style={{ display: 'none' }} rowSpan="2"> wings Id </th>
                        <th style={{ width: 20 + '%' }} rowSpan="2"> Select </th>
                        <th className="padding-remove" style={{ width: 35 + '%' }} rowSpan="2"><input onChange={filterSubject} type="text" value={subject} className="form-control inp-search" placeholder="Subject" /></th>
                        {/* <th style={{ width: 35 + '%' }} rowSpan="2"> Subject </th> */}
                        <th style={{ width: 35 + '%' }} rowSpan="2"> Type </th>
                        <th style={{ display: 'none' }} rowSpan="2"> canShare </th>
                        <th style={{ width: 80 + '%' }} colSpan="2"> Syllabus </th>
                    </tr>
                    <tr>
                        <th style={{ width: 35 + '%' }}> {session && session + '(%)'}</th>
                        <th style={{ width: 40 + '%' }}> Overall(%)</th>
                    </tr>
                </thead>
            </table>
            <div className="subjectTableDiv" style={{ height: '38em', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="subjectTable" className="tableLayout">
                    <colgroup>
                        <col width="20" />
                        <col width="35" />
                        <col width="40" />
                        <col width="35" />
                        <col width="40" />
                    </colgroup>
                    <tbody>{
                        found.map(obj => {
                            var isSelected = obj.subId === props.selectedSubjectId;
                            return <tr key={obj.subId} className={isSelected ? "selected" : ""} id={obj.subId}>
                                <td className="text-center">
                                    <span className="glyphicon glyphicon-triangle-left" sub-id={obj.subId} onClick={(e) => rowClickInMain(obj.subId)}></span>
                                </td>
                                <td className="text-left">{obj.sub}</td>
                                <td className="text-left">{obj.subType}</td>
                                <td style={{ textAlign: "center" }}>{obj.pct}</td>
                                <td style={{ textAlign: "center" }}>{obj.overallPct}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TTSubjectTblBody;