import React, { useState } from "react";
import { searchBookSvc } from "./issueBooksSvc";
import { toast } from "react-toastify";

export function BookSearchBar(props) {
  const [bookNo, setBookNo] = useState("");
  const [copyNo, setCopyNo] = useState("");

  function setBookCopyNo(pVal) {
    if (pVal.length > 5) {
      var tmpBookNo = pVal.substring(0, pVal.indexOf("-"));
      // console .log(tmpBookNo);
      setBookNo(tmpBookNo);
      var tmpCopyNo = pVal.substring(pVal.indexOf("-") + 1, pVal.length);
      // console .log(tmpCopyNo);
      setCopyNo(tmpCopyNo);
    } else {
      setBookNo(pVal);
    }
  }

  function searchBook() {
    if (`${bookNo}-${copyNo}`.length < 9) {
      toast.warn("Please enter 9 digit book No.");
      return;
    }
    props.setLoader("block");

    props.setBookObj({
      author: "",
      bookNo: "",
      borrower: "",
      borrowerType: "",
      copyNo: "",
      cost: 0,
      dueDt: "",
      issueRestriction: "-",
      name: "",
      publisher: "",
      securityDeposit: 0,
      staffIssuePeriod: 0,
      statusId: 0,
      studentIssuePeriod: 0,
      title: "",
    });
    props.setIssueDtls({
      bookNo: "",
      copyNo: "",
      bookCopyNo: "-",
      securityDeposit: 0,
      studentIssuePeriod: 0,
      staffIssuePeriod: 0,
      issueRestriction: "",
    });
    props.setBookNo("");
    searchBookSvc(bookNo, copyNo, searchBookCallback);
  }

  function searchBookCallback(data) {
    props.setIssueDtls({
      bookNo: "",
      copyNo: "",
      bookCopyNo: "-",
      securityDeposit: 0,
      studentIssuePeriod: 0,
      staffIssuePeriod: 0,
      issueRestriction: "",
    });

    props.setLoader("none");
    // console .log(data);
    if (data.SvcStatus === "Success") {
      // console .log(data.bookObj);
      props.setBookObj(data.bookObj);
      props.setIssueDtls({
        bookNo: data.bookObj.bookNo,
        copyNo: data.bookObj.copyNo,
        bookCopyNo: data.bookObj.bookNo + "-" + data.bookObj.copyNo,
        securityDeposit: data.bookObj.securityDeposit,
        studentIssuePeriod: data.bookObj.studentIssuePeriod,
        staffIssuePeriod: data.bookObj.staffIssuePeriod,
        issueRestriction: data.bookObj.issueRestriction,
      });
      props.setBookNo(`${data.bookObj.bookNo}-${data.bookObj.copyNo}`);
      toast.success(data.SvcMsg);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        // console .log("session expired");
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }

  return (
    <div>
      <div className="col-sm-12">
        <div className="form-group">
          <div className="col-sm-2 left-padding-remove">
            <input
              autoComplete="off"
              id="bookNo"
              defaultValue={
                bookNo ? (copyNo ? `${bookNo}-${copyNo}` : `${bookNo}`) : ``
              }
              type="text"
              onChange={(e) => setBookCopyNo(e.target.value)}
              className="form-control "
              placeholder="Book No."
            />
          </div>
          <button
            type="button"
            className="btn btn-info col-sm-2"
            onClick={() => searchBook()}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}
