import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import { buildingRoomListSvc } from "./classService";

var queryString = require('querystring');

const screenId = 37;


const BuildingSlider = (props) => {
    const [classData3, setClassData3] = useState(props.classData3)
    const [classSectionId, setClassSectionId] = useState(props.classData3.classSectionId)
    const [lstBuildings, setLstBuildings] = useState(props.lstBuildings)
    const [buildingId, setBuildingId] = useState(props.classData3.buildingId)
    const [roomId, setRoomId] = useState(props.classData3.roomId);
    const [lstRoom, setLstRoom] = useState([]);
    const [roomCapacity, setRoomCapacity] = useState(0);

    useEffect(() => {
        // console .log("Building Slider called, props", props);
        if (props.classData3.buildingId) {
            handleChangeBuilding(props.classData3.buildingId);
        }
    }, [props]);



    function saveBuilding() {
        props.saveBuilding(classData3)
    }

    const handleChangeBuilding = (bId) => {
        setRoomCapacity(0);
        setBuildingId(bId);
        getLstRoom(bId);
        updateBuildingName(bId);
        setClassData3({ ...classData3, buildingId: bId, });
    }

    const handleChangeRoomId = (rId) => {
        setRoomId(rId);
        updateRoomData(rId, lstRoom);
        setClassData3({ ...classData3, roomId: rId, });
    }

    const getLstRoom = (bId) => {
        // props.setLoader("block");
        // props.setOpacity(0.5);
        buildingRoomListSvc(bId, buildingRoomListResponse);
    }

    const updateBuildingName = (bId) => {
        const selecetedBuilding = props.lstBuildings.find(building => building.buildingId == bId);
        if (selecetedBuilding) {
            setClassData3({ ...classData3, buildingName: selecetedBuilding.buildingName });
        }
    }

    const updateRoomData = (rId, lstRm) => {
        // console .log("update room Id called");
        setRoomId(rId);
        const selectedRoom = lstRm.find(room => room.roomId == rId);
        // console .log("update room Id called, selected Room", selectedRoom);
        if (selectedRoom) {
            setClassData3({ ...classData3, roomId: selectedRoom.roomId, room: selectedRoom.room, capacity: selectedRoom.capacity, floor: selectedRoom.floor });
            setRoomCapacity(selectedRoom.capacity);
        }
    }

    const buildingRoomListResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            // console .log("status succes in buildingRoomListRsponse, obj.lstRoom", obj.lstRoom);
            setLstRoom(obj.lstRoom);
            if (props.classData3.roomId || roomId) {
                updateRoomData((props.classData3.roomId || roomId), obj.lstRoom);
            }
        }
        else {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                toast.error(obj.message);
                // console .log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        // props.setLoader("none");
        // props.setOpacity(1);
    }



    return (
        <>
            <form id="addForm" className="form-horizontal formWrapper clearfix">
                <div className="ab">
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Building<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-7 padding-remove">
                            <select autoComplete="off" name="buildingId" id="buildingCmb"
                                type="text" className="form-control "
                                value={buildingId}
                                onChange={(e) => handleChangeBuilding(e.target.value)}
                            >
                                <option value="0" >Building</option>
                                {props.lstBuildings && props.lstBuildings.length !== 0 && props.lstBuildings.map((obj) => (
                                    <option key={obj.buildingId} value={obj.buildingId} >{obj.buildingName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Room<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-7 padding-remove">
                            <select autoComplete="off" name="roomId" id="roomCmb"
                                type="text" className="form-control "
                                value={roomId}
                                onChange={(e) => handleChangeRoomId(e.target.value)}
                            >
                                <option value="0" >Room</option>
                                {lstRoom && lstRoom.map((obj) => (
                                    <option key={obj.roomId} value={obj.roomId} >{obj.room}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Capacity<span style={{ color: 'red', marginLeft: 1 }}>*</span> </label>
                        <div className="col-sm-7 padding-remove">
                            <   input autoComplete="off" name="maxStudent" id="maxStudent"
                                type="text" className="form-control "
                                value={roomCapacity}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 padding-remove paymentButtons">
                    <div className="form-group" >
                        <div className="ab1" >
                            <button type="button" style={{ marginTop: 20 }}
                                title="Save"
                                onClick={saveBuilding}
                                className="btn btn-info">Save</button>
                        </div>
                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={props.removeClassFromBody}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>

                </div>
            </form >
        </>

    )
}

export default BuildingSlider;