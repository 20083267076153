import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['recruitment'].url + ":" + lstModules['recruitment'].port;



function recruitmentExperienceLstSvc(cbTchLstResponse,tchId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/experience/list",
            method:             "POST",
            withCredentials:    true,
            params:{tchId:tchId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbTchLstResponse(response.data.lstExperience)
    })
    .catch((error)=>{
        // console .log( error )
    });

    
}
function recruitmentExperienceDtlsSvc(cbTchLstResponse,tchId,experienceId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/experience/dtls",
            method:             "POST",
            withCredentials:    true,
            params:{tchId:tchId,experienceId:experienceId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbTchLstResponse(response.data.experience)
    })
    .catch((error)=>{
        // console .log( error )
    });

    
}
function recruitmentExperienceSaveSvc(cbRecruitmentExperienceSaveResponse,experience,lstAttachment) {
    var formData =new FormData()
    formData.append("experience", JSON.stringify(experience));
    formData.append("lstAttachment",lstAttachment)
    axios(
        {   url:                httpURL + "/sms/recruitment/experience/save",
            method:             "POST",
            data:formData,
            withCredentials:    true,
            params:{},
           
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbRecruitmentExperienceSaveResponse(response.data)
    })
    .catch((error)=>{
        // console .log( error )
    });
}

function recruitmentExperienceAttachmentSaveSvc(cbrecruitmentEducationAttachmentSaveResponse,attachmentFile,staffAttachmentId,rowId,tchId) {
    var formData = new FormData();

    formData.append("attachmentFile",attachmentFile);
    var attachment ={"staffAttachmentId": staffAttachmentId,"rowId":rowId,"typeId":50,"tchId":tchId}
    // console .log(rowId)
    formData.append("attachment",JSON.stringify(attachment));
    


axios({
        url: httpURL + "/sms/recruitment/attachment/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            cbrecruitmentEducationAttachmentSaveResponse(response.data.SvcMsg);
           
        }
        else {
            // console .log(response.data.SvcMsg);
        }
        
    });

    
}
function recruitmentAttachmentDeleteSvc(cbRecruitmentAttachmentDeleteResponse,staffAttachmentId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/attachment/delete",
            method:             "POST",
            withCredentials:    true,
            params:{staffAttachmentId:staffAttachmentId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
        cbRecruitmentAttachmentDeleteResponse(response.data.SvcMsg)
    })
    .catch((error)=>{
        // console .log( error )
    });

    
}

    

function recruitmentExperienceDeleteSvc(cbEducationDeleteResponse,tchId,experienceId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/experience/delete",
            method:             "POST",
            withCredentials:    true,
            params:{tchId:tchId,experienceId:experienceId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbEducationDeleteResponse(response.data)
    })
    .catch((error)=>{
        // console .log( error )
    });

    
}






export { recruitmentExperienceLstSvc,recruitmentExperienceSaveSvc,recruitmentExperienceDtlsSvc,recruitmentExperienceDeleteSvc,recruitmentExperienceAttachmentSaveSvc,recruitmentAttachmentDeleteSvc}