import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import 'common/css/master.css'
import Header from 'common/js/header/header'
import ExamDateHeader from 'common/js/examDateHeader.js';
import ClsHeader from './clsHeader';

import { getClassListSvc, getSectionListSvc, remarksListSvc } from './examRemarksSvc';
import ExamRemarkTable from './examRemarksTable';
import SaveExamRemark from './saveExamRemark';


const ExamRemarks = () => {
    const [loader, setLoader] = useState('none');
    const [opacity, setOpacity] = useState(1);
    const [lstRemark, setLstRemark] = useState([]);
    const [examId, setExamId] = useState(0);
    const [exam, setExam] = useState("");
    const [sectionId, setSectionId] = useState(0);
    const [lstCls, setLstCls] = useState([]);
    const [lstSection, setLstSection] = useState([]);
    const [clsId, setClsId] = useState(0);
    const [lstSelect, setLstSelect] = useState([]);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        // console .log("examRemarks called");
        setClass();
    }, []);


    function setClass() {
        // console .log("setClassMethod called");
        getClassListSvc(cbClassResponse);
    }

    async function cbClassResponse(data) {
        // console .log("cbClassResponse called, data", data);
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            await setLstCls(obj.lstCls);
            // console .log("obj.lstCls", obj.lstCls);
            var tmpClassId = obj.lstCls[0].classId;
            changeClass(tmpClassId)
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function setSection(clsId) {
        // console .log("setSection called, clsId", clsId);
        getSectionListSvc(handleSectionResponse, clsId);
    }

    function handleSectionResponse(data) {
        // console .log("handleSectionResponse called, data", data);
        return new Promise((resolve) => {
            var obj = data;
            if (obj.SvcStatus == 'Success') {
                setLstSection(obj.lstClassSection);
                // console .log("obj.lstClassSEction", obj.lstClassSection);
                if (obj.lstClassSection && obj.lstClassSection.length) {
                    changeSection(obj.lstClassSection[0].sectionId, obj.lstClassSection[0].syllabusId)
                }
            } else if (obj.SvcStatus == 'Failure') {
                if (obj.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (obj.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(obj.SvcMsg);
                }
            } else {
                toast.error("Service failed " + obj.SvcMsg);
            }
            resolve();
        });
    }

    function changeExam(pExam) {
        // console .log("changeExam called", pExam);
        setExamId(pExam.examId);
        setExam(pExam.exam);
        if (sectionId && pExam.examId) {
            getStudentRemarksList(sectionId, pExam.examId);
        }
    }

    async function changeClass(pClsId) {
        // console .log("changeClass called, pClsId", pClsId);
        await setClsId(pClsId);
        await setLstSection([]);
        // console .log("changeClass: " + pClsId + ", sectionId::" + sectionId + ", sessionId::" + sessionId);
        setSection(pClsId);

        // pClsId !== 0 && sectionId !== 0 && setSubjectScoreTable(sessionId, pClsId, sectionId);
    }

    async function changeSection(pSectionId) {
        // console .log("changeSection called, pSectionId", pSectionId,", pSyllabusId",pSyllabusId);
        setSectionId(pSectionId);
        if (pSectionId && examId) {
            getStudentRemarksList(pSectionId, examId);
        }
    }

    const saveRemarks = () => {
        // console .log("saveRemarks called");
        setIsPopupVisible(true);
    }

    const closePopup = () => {
        setIsPopupVisible(false);
        if (sectionId && examId) {
            getStudentRemarksList(sectionId, examId);
        }
    }

    const updateKey = () => {
        setTableKey((prevKey) => prevKey + 1);
    }

    const getStudentRemarksList = (pSectionId, pExamId) => {
        // console .log("getStudentRemarksList called, sectionId", pSectionId, ", examId", pExamId);
        remarksListSvc(pSectionId, pExamId, studentRemarkListResponse);
        setLoader("block");
    }

    const studentRemarkListResponse = (data) => {
        let obj = data;
        // console .log("studentRemarkListResponse called obj.lstRemark", obj.lstRemark);
        if (obj.status === 'Success') {
            setLstRemark(obj.lstRemark);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () { window.location.href = "/"; }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = "/"; }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
    }

    return (
        <div className='container1'>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                <img
                    className='loderImg'
                    src={require('common/images/loader.gif')}
                    alt='Loader...'
                />
            </div>
            <div className='col-md-11 col-centered ' style={{ marginTop: 15 + 'px' }}>
                <div className="col-sm-12 col-centered padding-remove">
                    <div className="col-sm-6 padding-remove">
                        <ExamDateHeader
                            examClick={changeExam}
                            examId={examId}
                        />
                    </div>
                </div>
                <div className='col-md-12 padding-remove' >
                    <div className="col-sm-5 padding-remove">
                        <ClsHeader
                            changeClass={changeClass}
                            changeSection={changeSection}
                            lstSection={lstSection}
                            lstCls={lstCls}
                            classId={clsId}
                            selSectionId={sectionId}
                        />
                    </div>
                    <div className="col-sm-1 pull-right padding-remove">
                        <button type="button" style={{ width: "100%" }} className="btn btn-primary pull-right"
                            onClick={(e) => { e.stopPropagation(); saveRemarks(); }}>
                            Remarks
                        </button>
                    </div>
                </div>
                <div className='col-md-12 padding-remove' style={{ marginTop: "10px" }} >
                    <ExamRemarkTable
                        tableKey={tableKey}
                        sectionId={sectionId}
                        examId={examId}
                        lstRemark={lstRemark}
                        setLstRemark={setLstRemark}
                        lstSelect={lstSelect}
                        setLstSelect={setLstSelect}
                    />
                </div>
            </div>
            {isPopupVisible ?
                <SaveExamRemark
                    setLoader={setLoader}
                    examId={examId}
                    sectionId={sectionId}
                    lstSelect={lstSelect}
                    lstRemark={lstRemark}
                    setLstSelect={setLstSelect}
                    closePopup={closePopup}

                />
                : null
            }
        </div>
    )
}
export default ExamRemarks;