
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleFooter } from './examReportCardStyles.js';


const FooterSection = () => {
    return (
        <View style={styleFooter.block}>
            <View style={styleFooter.empty_row}>
            </View>
            <View style={styleFooter.row_labels}>
                <View style={styleFooter.colSign}>
                    <Text style={[styleFooter.sign_text, {}]}>CLASS TEACHER</Text>
                </View>
                <View style={styleFooter.colSign}>
                    <Text style={styleFooter.sign_text}>PARENT</Text>
                </View>
                <View style={styleFooter.colSign}>
                    <Text style={styleFooter.sign_text}>PRINCIPAL</Text>
                </View>
            </View>
        </View>
    );
}

export default FooterSection;