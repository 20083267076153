import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import 'common/css/master.css';
import "../css/feeHeads.css";
import '../css/fee.css'
import axios from 'axios';
import Header from 'common/js/header/header';
import { toast, ToastContainer } from 'react-toastify';
// import StudentFeePaymentTable from './studentFeePaymentTable';
import StudentFeePaymentTable from './studentFeePaymentTableNew.js';
import PaymentSlider from './paymentSlider';
import FeeDuePaymentSlider from './FeeDuePaymentSlider.js';

var httpURLCommon = '', httpURL = '';
var queryString = require('querystring');
var screenId = 37;
// var lstFeeDues = [];
// var lstFeeDuesTemp = [];

class feePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // lstFeeDue: [],
            // lstFeeDueStore: [],
            tokenid: localStorage.getItem('tokenid'),
            lstModes: [],
            flg: 0,
            feePaymentData: {},
            studentId: 0,
        }
        // this.getFeeDueStudentList.bind(this);
        /* window.addEventListener('storage', this.handleStorageChange);
        this.handleStorageChange = this.handleStorageChange.bind(this); */

    }
    componentDidMount() {
        // console .log("localStorage", localStorage);
        // this.getFeeDueStudentList();
        this.getModeList();
    }
    /* handleStorageChange = (e) => {

        if (e.target.key === "lstStudentId") {
            // console .log("key is lstStudentId");
            this.getFeeDueStudentList();
        }

    } */
    getModeList = () => {
        this.setState({ loader: 'block', opacity: 0.5 })
        axios.post(httpURLCommon + '/sms/mst/payment_mode/list',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                this.setState({
                    lstModes: response.data.lstPaymentMode,
                });
                // console .log('lstModes', this.state.lstModes);
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });
    }
    // getFeeDueStudentList = () => {
    //     this.setState({ loader: 'block', opacity: 0.5 });
    //     axios({
    //         url: httpURL + '/sms/payment/student/list',
    //         method: "POST",
    //         params: { 'screenId': screenId },
    //         headers: { 'tokenid': this.state.tokenid },
    //         withCredentials: true,
    //     }).then((response) => {
    //         if (response.data.SvcStatus === 'Success') {
    //             this.setState({ lstFeeDue: response.data.lstStudent });
    //             this.setState({ lstFeeDueStore: response.data.lstStudent });
    //             lstFeeDues = response.data.lstStudent;
    //             lstFeeDuesTemp = response.data.lstStudent;
    //         } else {
    //             toast.error(response.data.SvcMsg);
    //         }
    //         this.setState({ loader: 'none', opacity: 1 });
    //     }).catch((e) => {
    //         this.setState({ loader: 'none', opacity: 1 });
    //         toast.error('Service failed: ' + e);
    //     });

    // }

    saveFeePayment = (obj) => {
        let {
            studentId,
            dt,
            refNo,
            amt,
            modeId,
            remarks,
            lstStudentFeeDtls
        } = obj;
        let RQSavePayment = {
            studentId,
            dt,
            refNo,
            amt,
            modeId,
            remarks,
            lstStudentFeeDtls
        }

        this.setState({ loader: 'block', opacity: 0.5 })
        axios.post(httpURL + '/sms/payment/save',
            queryString.stringify({ "RQSavePayment": JSON.stringify(RQSavePayment), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                toast.success(response.data.SvcMsg);
                //this.getHistoryList();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });
    }

    // filterNameTable = (student) => {
    // console .log(student);
    //     // this.setState({
    //     //     filterName:student
    //     // })
    //     if (student !== "") {
    //         const filterName = this.state.lstFeeDueStore.filter(feeDue =>
    //             feeDue.student.toLowerCase().includes(student.toLowerCase()));
    //         this.setState({
    //             lstFeeDue: filterName
    //         })
    //     }
    //     else {
    //         this.setState({
    //             lstFeeDue: lstFeeDues
    //         })
    //     }
    // }
    // filterEnrollmentNoTable = (enrollmentNo) => {
    //     if (enrollmentNo !== "") {
    //         const filterName = this.state.lstFeeDueStore.filter(feeDue =>
    //             feeDue.enrollmentNo.toString().toLowerCase().includes(enrollmentNo.toLowerCase()));
    //         this.setState({
    //             lstFeeDue: filterName
    //         })
    //     }
    //     else {
    //         this.setState({
    //             lstFeeDue: lstFeeDues
    //         })
    //     }
    // }
    // filterClassTable = (cls) => {
    //     if (cls !== "") {
    //         const filterName = this.state.lstFeeDueStore.filter(feeDue =>
    //             feeDue.cls.toLowerCase().includes(cls.toLowerCase()));
    //         this.setState({
    //             lstFeeDue: filterName
    //         })
    //     }
    //     else {
    //         this.setState({
    //             lstFeeDue: lstFeeDues
    //         })
    //     }
    // }
    // filterFatherTable = (father) => {
    //     if (father !== "") {
    //         const filterName = this.state.lstFeeDueStore.filter(feeDue =>
    //             feeDue.fatherPhone.includes(father.toLowerCase()) ||
    //             feeDue.father.toLowerCase().includes(father.toLowerCase()));
    //         this.setState({
    //             lstFeeDue: filterName
    //         })
    //     }
    //     else {
    //         this.setState({
    //             lstFeeDue: lstFeeDues
    //         })
    //     }
    // }
    // filterMotherTable = (mother) => {
    //     if (mother !== "") {
    //         const filterName = this.state.lstFeeDueStore.filter(feeDue =>
    //             feeDue.motherPhone.includes(mother.toLowerCase()) ||
    //             feeDue.mother.toLowerCase().includes(mother.toLowerCase()));
    //         this.setState({
    //             lstFeeDue: filterName
    //         })
    //     }
    //     else {
    //         this.setState({
    //             lstFeeDue: lstFeeDues
    //         })
    //     }
    // }
    // filterGuardianTable = (guardian) => {
    //     if (guardian !== "") {
    //         const filterName = this.state.lstFeeDueStore.filter(feeDue =>
    //             feeDue.guardianPhone.includes(guardian.toLowerCase()) ||
    //             feeDue.guardian.toLowerCase().includes(guardian.toLowerCase()));
    //         this.setState({
    //             lstFeeDue: filterName
    //         })
    //     }
    //     else {
    //         this.setState({
    //             lstFeeDue: lstFeeDues
    //         })
    //     }
    // }

    selectedStudentData = (data) => {
        // console .log("data in selectedStudentData method in feePayment.js",data);
        // localStorage.setItem("lstStudentId", JSON.stringify(data));
        var newPageUrl = "/feePaymentDetails?lstStudentId=" + JSON.stringify(data);
        // route to new page by changing window.location
        // window.open(newPageUrl, "")
        window.open(newPageUrl, "");
    }


    setFlgSliderType = (num, data) => {

        this.setState({ flg: num })
        if (num === 1) {
            this.setState({ studentId: data })
        }
        else if (num === 2) {
            this.setState({ feePaymentData: data })
        }

    }

    closeSlider = async () => {
        this.setState({ flg: 0 })


    };

    getSlider = () => {
        return this.slider;
    }
    getFeeDueSlider = () => {
        return this.feeDueSlider;
    }
    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    setLoaderValue = (ldr) => {
        this.setState({loader:ldr});
      }
    
    setOpacityValue = (opct) => {
        this.setState({opacity:opct});
      }
    render() {
        httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
        httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
        return (
            <div>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer feeDueTable" >
                    <StudentFeePaymentTable
                        // lstFeeDue={this.state.lstFeeDue}
                        setFlgSliderType={this.setFlgSliderType}
                        // filterNameTable={this.filterNameTable}
                        selectedStudentData={this.selectedStudentData}
                    // filterClassTable={this.filterClassTable}
                    // filterFatherTable={this.filterFatherTable}
                    // filterMotherTable={this.filterMotherTable}
                    // filterGuardianTable={this.filterGuardianTable}
                    // filterEnrollmentNoTable={this.filterEnrollmentNoTable}
                    setLoader ={this.setLoaderValue}
                    setOpacity={this.setOpacityValue}
                    />
                    {this.state.studentId > 0 &&
                        <div className='slider halfWidthSlider' style={{ right: 0, opacity: 1, display: this.state.flg === 1 ? 'block' : 'none' }}>
                            <FeeDuePaymentSlider
                                studentId={this.state.studentId}
                                removeClassFromBody={this.closeSlider}
                            ></FeeDuePaymentSlider>
                        </div>
                    }

                    <div className='slider halfWidthSlider' style={{ right: 0, opacity: 1, display: this.state.flg === 2 ? 'block' : 'none' }}>
                        <PaymentSlider
                            key={this.state.feePaymentData.studentId}
                            lstModes={this.state.lstModes}
                            saveFeePayment={this.saveFeePayment}
                            studentId={this.state.feePaymentData.studentId}
                            removeClassFromBody={this.closeSlider}
                            addFeePayment={this.addFeePayment}
                        /></div>
                </div>

            </div>
        );
    }
}

export default feePayment;