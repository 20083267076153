import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

var httpURL = '';
var lstFeebook = [];
var rows = 0;

export default class feeBookView extends Component {
    state = {
        academicYr: this.props.academicYr
    }

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // console .log("feeBookView called ");
        // console .log("classSectionId", this.props.classSectionId);
        // console .log("classId", this.props.classId);
        // console .log("feeBookData", this.props.feeBookData);
        // console .log("rows", this.props.feeBookData.maxFeeHeadCount);
        // console .log("classSection", this.props.classSection);
        // console .log("studentName", this.props.studentName);
        // console .log("enrollmentNo", this.props.enrollmentNo);
    }


    removeClassFromBody = () => {
        this.props.closeSlider();
    }



    render() {

        httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
        lstFeebook = this.props.feeBookData.lstFeebook;
        rows = this.props.rows;
        if (rows <= 3) {
            rows = 3
        }
        else {
            rows = this.props.rows;
        }
        return (
            <div>
                {/* <div className="col-sm-6 padding-remove" style={{ marginLeft: '5%' }}> */}
                <div className="col-sm-12 head-bar padding-remove">
                    <ReactToPrint content={() => this.componentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (<div className="col-sm-offset-11 col-sm-1"><span onClick={handlePrint} className="valign-center col-sm-offset-11 col-sm-1 glyphicon glyphicon-print"></span></div>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
                <div className="col-sm-12 border border-secondary fee-book">
                    <div className="col-sm-12 fee-book-header">
                        <div className="col-sm-2">
                            <div><img width="60" src={require("common/images/kmsglogo.png")} alt="Logo" /></div>
                            <div className="col-sm-12 padding-remove">
                                {this.state.academicYr !== "undefined" && <label className="control-label">{this.state.academicYr}</label>}
                            </div>
                        </div>
                        <div className="col-sm-10 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4">Enrollment No.</label>
                                <div className="col-sm-4 input_val">
                                    <label className="control-label col-sm-12 text-left" style={{ fontWeight: 'normal' }}>{this.props.enrollmentNo}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-4">Name</label>
                                <div className="col-sm-4 input_val">
                                    <label className="control-label col-sm-12  text-left" style={{ fontWeight: 'normal' }}>{this.props.studentName}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-4">Class</label>
                                <div className="col-sm-4 input_val">
                                    <label className="control-label col-sm-12 text-left" style={{ fontWeight: 'normal' }}>
                                        {this.props.classSection}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="col-sm-12 padding-remove" style={{ height: 1, background: '#e4e4e4' }} />
                    <div className="col-sm-12 fee-book-body">
                        {lstFeebook.map(function (feeMnth, indx) {
                            var rowsCount = 0;
                            return <div className="brdr col-sm-6 padding-remove" key={feeMnth.month + '_' + indx}>
                                {createHeaderMnth(feeMnth.month)}

                                {feeMnth.lstFeehead.map(function (feeHead, idx) {
                                    rowsCount = idx + 1;
                                    return <div key={feeMnth.month + "_" + idx} className="col-sm-12 text-center padding-remove feehead">
                                        <div className="col-sm-12 padding-remove" key={feeMnth.month + '_' + feeHead.feeHeadId}>
                                            <span className="col-sm-6 text-left lbl">{feeHead.label}</span>
                                            <span className="col-sm-6 text-right amt">{feeHead.amount.toLocaleString("en-IN")}</span>
                                        </div>
                                    </div>

                                })}


                                {createEmptyRows(rows - rowsCount)}
                                {createRowForTotal(feeMnth.total)}
                            </div>
                        })}
                    </div>
                </div>
                {<div className="form-group" >
                    <div className="col-sm-8 text-right" >
                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.removeClassFromBody}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </div>}
            </div>
            // </div>
        )
    }
}


const createRowForTotal = (total) => {
    return <div className="top-brdr col-sm-12 text-center padding-remove">
        <label className="col-sm-6 control-label text-left lbl">Total</label>
        <label className="col-sm-6 control-label text-right amt">{total.toLocaleString('en-IN')}</label>
    </div>
}
const createHeaderMnth = (mnth) => {
    return <div className="col-sm-12 mth padding-remove"><span className="col-sm-12 control-label text-center headmnth">{mnth}</span></div>
}
const createEmptyRows = (rowCnt) => {
    // console .log("rowCnt=" + rowCnt);
    if (rowCnt <= 0) {
        return;
    }
    else {
        var result = [];
        for (let i = 0; i < rowCnt; i++) {
            result.push(<div className="col-sm-12 text-center padding-remove feehead" key={i}>
                <div className="col-sm-12 padding-remove">
                    <span className="col-sm-6 text-left lbl"></span>
                    <span className="col-sm-6 text-right amt"></span>
                </div>
            </div>);
        }
        return result;
    }

}