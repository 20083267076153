import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';

import "../css/examSyllabus.css";
import { getExamListSvc } from "./examSyllabusSvc";

const ExamTabs = (props) => {

    const { examId, handleExamChange, setLoader, setOpacity } = props;

    const [lstExam, setLstExam] = useState([]);

    useEffect(() => {
        // console .log("ExamTabs called");
        getExamList();
    }, []);


    const getExamList = () => {
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        getExamListSvc(examListResponse);
    }



    const examListResponse = (data) => {
        // console .log("examListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstExam(obj.lstExam);
            if(obj.lstExam && obj.lstExam.length){
                handleExamChange(obj.lstExam[0].examId);
            }
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
    }

    return (
        <div className="col-sm-12 padding-remove">
            {lstExam && lstExam.length ?
                lstExam.map((obj) => {
                    return <div
                        className={obj.examId === examId ? "selected exam-row" : "exam-row"}
                        onClick={() => handleExamChange(obj.examId)}
                        key={obj.examId}
                        style={{ width: `calc(100% / ${lstExam.length})` }}
                    >
                        <div className="exam-box">
                            <div className="text-center">
                                {obj.exam}
                            </div>
                            <div className="text-center">
                                {obj.startDt + " | " + obj.endDt}
                            </div>
                        </div>

                    </div>
                }) : null
            }
        </div >
    );
}

export default ExamTabs;