import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';




const ChapterForm = (props) => {

    const [chapterId, setChapterId] = useState(0);
    const [chapterCode, setChapterCode] = useState("");
    const [chapterName, setChapterName] = useState("");
    const [chapterPeriods, setChapterPeriods] = useState(0);

    useEffect(() => {
        // console .log("ChapterForm Component (useEffect) called, props", props);
        setChapterId(props.chapterData.chapterId);
        setChapterCode(props.chapterData.chapterCode);
        setChapterName(props.chapterData.chapterName);
        // setChapterExam(props.chapterData.chapterExam);
        // setLstPartOf(props.chapterData.lstPartOf);
        setChapterPeriods(props.chapterData.periods);
    }, [props.formKey]);

    const handleSubmit = (e) => {
        // console .log("handleSubmit method called");
        // console .log(" chapterCode in handleSubmit", chapterCode);
        // console .log(" chapterName in handleSubmit", chapterName);
        // console .log(" chapterPeriods in handleSubmit", chapterPeriods);
        e.preventDefault();
        if (chapterCode === "") {
            toast.warning("Please Enter Valid Code");
            return false;
        }
        if (chapterName === "") {
            toast.warning("Please Enter Chapter Name");
            return false;
        }
        if (chapterPeriods === 0) {
            toast.warning("Please Enter Period");
            return false;
        }
        props.saveChapter(chapterId, chapterCode, chapterName, chapterPeriods);
        props.clearForm();
    }

    // const handleCheckboxChange = async (event) => {
    //     event.persist()
    //     if (chapterExam === undefined) {
    //         await setChapterExam([]);
    //     }
    //     let newArray = [...chapterExam, event.target.id];
    //     if (chapterExam.includes(event.target.id)) {
    //         newArray = newArray.filter(day => day !== event.target.id);
    //     }
    //     setChapterExam(newArray);
    // };

    const handleChapterCodeChange = (event) => {
        event.preventDefault();
        // console .log("handleChapterCodeChange called with value:", event.target.value);
        // console .log("Current chapterCode value:", chapterCode);
        setChapterCode(event.target.value);
    }

    const handleChapterNameChange = (event) => {
        event.preventDefault();
        // console .log("handleChapterNameChange called with value:", event.target.value);
        // console .log("Current chapterName value:", chapterName);
        setChapterName(event.target.value);
    }

    const handleChapterPeriodsChange = (event) => {
        event.preventDefault();
        // console .log("handleChapterPeriodsChange called with value:", event.target.value);
        // console .log("Current chapterPeriods value:", chapterPeriods);
        setChapterPeriods(event.target.value);
    }



    return (
        <div>
            <form id="chapterForm" className="form-horizontal formWrapper">
                <div className="form-group" >
                    <label className="control-label col-sm-5" >Chapter Code<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input
                            autoComplete="off"
                            id="chapterCode"
                            name="chapterCode"
                            type="text"
                            className="form-control"
                            placeholder="Chapter Code"
                            value={chapterCode}
                            onChange={(e) => handleChapterCodeChange(e)}
                        />
                    </div>
                </div>
                <div className="form-group" >
                    <label className="control-label col-sm-5" >Chapter Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input
                            autoComplete="off"
                            id="chapterName"
                            name="Chapter Name"
                            type="text"
                            className="form-control"
                            placeholder="Chapter Name"
                            value={chapterName}
                            onChange={(e) => handleChapterNameChange(e)}
                        />
                    </div>
                </div>
                <div className="form-group" >
                    <label className="control-label col-sm-5" >Periods<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input
                            autoComplete="off"
                            id="chapterPeriods"
                            name="Periods"
                            type="number"
                            className="form-control"
                            placeholder="Periods"
                            value={chapterPeriods ? chapterPeriods : ""}
                            onChange={(e) => handleChapterPeriodsChange(e)}
                        />
                    </div>
                </div>
                {/* <div className="form-group">
                    <label className="control-label col-sm-5" >Part of<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <ul style={{ listStyleType: 'None' }}>
                            <li>
                                <input autoComplete="off" id="1" type="checkbox" defaultChecked={lstPartOf && lstPartOf.length && lstPartOf.includes(1)} onChange={handleCheckboxChange} />
                                <label className="form-check-label" htmlFor="UT1">UT-1</label>
                            </li>
                            <li>
                                <input autoComplete="off" id="2" type="checkbox" defaultChecked={lstPartOf && lstPartOf.length && lstPartOf.includes(2)} onChange={handleCheckboxChange} />
                                <label className="form-check-label" htmlFor="UT2">UT-2</label>
                            </li>
                            <li>
                                <input autoComplete="off" id="3" type="checkbox" defaultChecked={lstPartOf && lstPartOf.length && lstPartOf.includes(3)} onChange={handleCheckboxChange} />
                                <label className="form-check-label " htmlFor="HY">HY</label>
                            </li>
                        </ul>
                    </div>
                </div> */}


                <div className="form-group" >
                    <div className="col-sm-6 pull-right userButtonWrap" >
                        <button type="submit" onClick={(e) => handleSubmit(e)} style={{ marginTop: 10 }} title="Save" className="btn btn-info">Save</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default ChapterForm;