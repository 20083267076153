import React, { useEffect, useState } from 'react';
import Header from 'common/js/header/header';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';


import { getExamListSvc } from './classExamSyllabusSvc';


const ExamHeader = (props) => {
    const { examId, setExamId, setLoader, setOpacity, setExamLength } = props;

    const [lstExam, setLstExam] = useState([]);

    useEffect(() => {
        // console .log("ExamHeader called");
        getExamList();
    }, [examId]);


    const getExamList = () => {
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        getExamListSvc(examListResponse);
    }



    const examListResponse = (data) => {
        // console .log("examListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            // const initialExam = {
            //     "examId": 0,
            //     "exam": "All Exams",
            //     "startDt": "",
            //     "endDt": ""
            // };
            // obj.lstExam.unshift(initialExam);
            setLstExam(obj.lstExam);
            setExamLength(obj.lstExam.length);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
    }


    return (
        <div className="col-sm-12 padding-remove">
            <>
                <div
                    className={examId === 0 ? "selected exam-row-all" : "exam-row-all"}
                    onClick={() => setExamId(0)}
                    style={{ width: `calc(100% / ${lstExam.length + 1})` }}
                >
                    <div className="exam-box">
                        <div className="text-center">
                            {"All"}
                        </div>
                        <div className="text-center">
                            {"Exams"}
                        </div>
                    </div>

                </div>
            {lstExam && lstExam.length ?
                lstExam.map((obj) => {
                    return <div
                        className={obj.examId === examId ? "selected exam-row" : "exam-row"}
                        onClick={() => setExamId(obj.examId)}
                        key={obj.examId}
                        style={{ width: `calc(100% / ${lstExam.length + 1})` }}
                    >
                        <div className="exam-box">
                            <div className="text-center">
                                {obj.exam}
                            </div>
                            <div className="text-center">
                                {obj.startDt }{(obj.startDt && obj.endDt) ? " | " : " "}{ obj.endDt}
                            </div>
                        </div>

                    </div>
                }) : null
            }
            </>
        </div >
    );

}

export default ExamHeader;