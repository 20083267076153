import lstModules from 'common/json/lstModules.json';
import axios from 'axios';

let queryString = require('querystring');
const tokenid = localStorage.getItem('tokenid');
const httpURLUserLogin = lstModules['userlogin'].url + ':' + lstModules['userlogin'].port;
const httpURLLogin = lstModules['login'].url + ':' + lstModules['login'].port;
const httpURLWings = lstModules['wings'].url + ':' + lstModules['wings'].port;
const httpURLAcdYr = lstModules['academicYear'].url + ':' + lstModules['academicYear'].port;



export const getSchoolListSvc = (cbSchoolList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLWings + '/sms/mst/user/school/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: {},
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstSchool = response.data.lstSchool;
        cbSchoolList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSchoolList(obj);
    })
}


export const getRoleListSvc = (schoolId, cbRoleListList) => {
    // console .log("getRoleListSvc called, url", httpURLWings + '/sms/mst/user/school/role/list');
    const obj = { status: "", message: "" };
    axios({
        url: httpURLWings + '/sms/mst/user/school/role/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'schoolId': schoolId },
        withCredentials: true,
    }).then((response) => {
        // console .log("response", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if(response.data.SvcStatus === "Success"){
            obj.roleId = response.data.primaryRoleId;
            obj.role = response.data.primaryRole;
            obj.academicYearId = response.data.acdYrId;
            obj.wingId = response.data.wingId;
            obj.lstSecondaryRole = response.data.lstSecondaryRole;
        }
        // console .log("response.data.acdYrId in getRoleListSvc", response.data.acdYrId);

        cbRoleListList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbRoleListList(obj);
    })
}


export const getWingsListSvc = (cbWingsList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLWings + '/sms/wings/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'screenId': 0 },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstWings = response.data.lstWings;
        cbWingsList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbWingsList(obj);
    })
}


export const getAcademicYearListSvc = (cbAcademicYearList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLAcdYr + '/sms/acdyr/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'screenId': 0 },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstAcademicYear = response.data.lstAcademicYear;
        cbAcademicYearList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbAcademicYearList(obj);
    })
}


export const logoutSvc = (cbLogout) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLUserLogin + '/cooledsys/user/logout',
        method: "POST",
        headers: {},
        params: { 'tokenId': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbLogout(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLogout(obj);
    })
}


export const changePasswordSvc = (oldPsw, newPsw, cbChangePassword) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLUserLogin + '/cooledsys/user/changepassword',
        method: "POST",
        headers: { tokenid: tokenid },
        params: {
            email: localStorage.getItem('email'),
            oldPassword: oldPsw,
            newPassword: newPsw
        },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbChangePassword(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbChangePassword(obj);
    })
}


export const changeSchoolSvc = (schoolId, cbchangeSchool) => {
    const obj = { status: "", message: "", "sId": schoolId };
    axios({
        url: httpURLWings + '/sms/mst/defaultSchoolId/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'schoolId': schoolId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbchangeSchool(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbchangeSchool(obj);
    })
}


export const changeRoleSvc = (roleId, cbchangeRole) => {
    const obj = { status: "", message: "", "rId": roleId };
    axios({
        url: httpURLWings + '/sms/mst/defaultRoleId/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'roleId': roleId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbchangeRole(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbchangeRole(obj);
    })
}


export const changeWingSvc = (wingId, cbchangeWing) => {
    const obj = { status: "", message: "", "wId": wingId };
    axios({
        url: httpURLWings + '/sms/mst/defaultWing/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'wingId': wingId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbchangeWing(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbchangeWing(obj);
    })
}


export const changeAcademicYearSvc = (acdYrId, cbchangeAcademicYear) => {
    const obj = { status: "", message: "", "acdYrId": acdYrId };
    axios({
        url: httpURLAcdYr + '/sms/mst/defaultAcdyr/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'acdYrId': acdYrId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbchangeAcademicYear(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbchangeAcademicYear(obj);
    })
}

