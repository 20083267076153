import '../css/EnquiryCtrl.css'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { getRegsFeeDtlsSvc } from './RegsFeeSliderSvc'
import { getHeadListSvc, getModeList } from './AdmissionSvc'
import { associateFeeHeadSvc } from './AdmissionSvc'
import SliderType from './sliderType'

const RegistrationFeeSlider = props => {
  const [loader, setLoader] = useState('none')
  const [admissionId, setAdmissionId] = useState(props.admissionId)
  const [oneTimeHeadId, setOneTimeHeadId] = useState(0)
  const [oneTimeHead, setOneTimeHead] = useState('')
  const [lstOneTimeHead, setLstOneTimeHead] = useState([])
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [cls, setCls] = useState('')
  const [amount, setAmount] = useState(0)
  const [paymentContact, setPaymentContact] = useState('')
  const [paymentEmail, setPaymentEmail] = useState('')
  const [paymentDt, setPaymentDt] = useState(new Date())
  const [modeId, setModeId] = useState('')
  const [referenceNo, setReferenceNo] = useState('')
  const [flgSms, setFlgSms] = useState(false)
  const [flgEmail, setFlgEmail] = useState(false)
  const [flgWhatsapp, setFlgWhatsapp] = useState(false)
  const [lstPaymentMode, setLstPaymentMode] = useState([])
  function cbRegFeeResponse (data) {
    if (data.SvcStatus === 'Success') {
      // console .log('Fetching success')
      // console .log(data)
      setOneTimeHeadId(data.dtls.oneTimeHeadId)
      setCls(data.dtls.cls)
      setName(data.dtls.name)
      setContact(data.dtls.contact)
      setPaymentContact(data.dtls.paymentContact)
      setPaymentEmail(data.dtls.paymentEmail)
      var payDtParts = data.dtls.paymentDt.split('-')
      var payDtObj = new Date(payDtParts[2], payDtParts[1] - 1, payDtParts[0])

      setPaymentDt(payDtObj)
      setFlgSms(data.dtls.flgSms)
      setFlgEmail(data.dtls.flgEmail)
      setFlgWhatsapp(data.dtls.flgWhatsapp)
      setModeId(data.dtls.modeId)
      setReferenceNo(data.dtls.referenceNo)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function cbSaveRegFee (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      var p2 = new Promise(function (resolve, reject) {
        resolve(props.setSelectedEnquiryId(data.admissionId))
      })

      p2.then(function () {
        props.closeSlider()
      })
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  useEffect(() => {
    setLoader('block')

    var promise = new Promise((resolve, reject) => {
      if (
        props.flgSliderType === SliderType.SLIDER_REGISTER_FEE &&
        props.admissionId
      ) {
        getRegsFeeDtlsSvc(cbRegFeeResponse, props.admissionId)
        // getModeList(cbGetModeResponse)
        setAdmissionId(props.admissionId)
        setOneTimeHeadId(props.oneTimeHeadId)
        setOneTimeHead(props.oneTimeHead)
        setAmount(props.amount)
      }
    })
  }, [props.admissionId, props.flgSliderType, props.oneTimeHeadId])
  function cbGetModeResponse (data) {
    setLstPaymentMode(data)
  }
  function handleOneTimeHeadChange (param) {
    setOneTimeHeadId(param)
    var amt = 0
    for (var i = 0; i < lstOneTimeHead.length; i++) {
      var obj = lstOneTimeHead[i]

      if (obj.headId == param) {
        amt = obj.amount
        break
      }
      setAmount(amt)
    }
  }
  function saveRegFee () {
    var vLstAdmissionId = []
    vLstAdmissionId.push(admissionId)
    associateFeeHeadSvc(vLstAdmissionId, oneTimeHeadId, cbSaveRegFee)
  }
  function convert (str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    // console .log([day, mnth, date.getFullYear()].join('-'))
    return [day, mnth, date.getFullYear()].join('-')
  }
  function handleModeChange (pVal) {
    setModeId(pVal)
  }
  function handleChange (date) {
    setPaymentDt(date)
  }
  function handleEmailCBoxChange (e) {
    setFlgEmail(e.target.checked)
  }
  function handleSmsCBoxChange (e) {
    setFlgSms(e.target.checked)
  }
  function handleWappCBoxChange (e) {
    setFlgWhatsapp(e.target.checked)
  }
  return (
    <>
      <form
        className='form form-horizontal'
        style={{
          width: '100%',
          margin: '0 auto',
          background: '#fff',
          border: '1px solid #ccc',
          borderRadius: 10,
          pointerEvents: loader === 'none' ? 'auto' : 'none'
        }}
      >
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>

        <div
          className='EnquiryHead col-sm-12 col-centered'
          style={{
            border: '1px solid #aaa',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: 'bold'
          }}
        >
          <h4>Registration Fee</h4>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>Name</label>
          <div className=' padding-remove col-md-6'>
            <input
              type='text'
              className='form-control'
              defaultValue={name}
              readOnly
            />
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>Contact</label>
          <div className=' padding-remove col-md-3'>
            <input
              type='text'
              className='form-control'
              value={contact}
              readOnly
            />
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>Class</label>
          <div className=' padding-remove col-md-2'>
            <input type='text' className='form-control' value={cls} readOnly />
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>
            Registration Fee
          </label>
          <span
            style={{
              cursor: 'pointer',
              width: '8.5%'
            }}
            className='padding-remove col-sm-1 glyphicon glyphicon-new-window addIcon'
            onClick={props.openOneTimeHeadPopup}
          ></span>
          <div className=' padding-remove col-md-2'>
            {/* <select
            className='form-control'
            onChange={e => handleOneTimeHeadChange(e.target.value)}
            value={oneTimeHeadId}
          >
            <option value={0}>Registration Fee</option>
            {lstOneTimeHead &&
              lstOneTimeHead.length > 0 &&
              lstOneTimeHead.map((obj, idx) => {
                return (
                  <option key={idx} value={obj.headId}>
                    {obj.head}
                  </option>
                )
              })}
          </select> */}
            <input
              type='text'
              className='form-control text-right'
              value={amount}
              readOnly
            />
          </div>
        </div>

        <div className='form-group col-md-12 padding-remove'>
          <button
            type='button'
            className='btn btn-info pull-right'
            onClick={e => saveRegFee()}
          >
            Save
          </button>
        </div>
      </form>
      <hr />
      <form>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>
            Contact No.
          </label>
          <div className=' padding-remove col-md-3'>
            <input
              type='text'
              className='form-control'
              value={paymentContact}
              readOnly
            />
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>Email</label>
          <div className=' padding-remove col-md-4'>
            <input
              type='text'
              className='form-control'
              value={paymentEmail}
              readOnly
            />
          </div>
        </div>
      </form>
      <hr />
      <form>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>
            Payment Date
          </label>
          <div className='form-group col-sm-2 padding-remove'>
            <DatePicker
              id='inp_paymentDt'
              className='form-control text-center'
              placeholderText='Payment Date'
              selected={paymentDt}
              dateFormat='dd-MM-yyyy'
              onChange={handleChange}
              autoComplete='off'
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
              maxDate={new Date()}
            />
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>
            Payment Mode
          </label>
          <div className=' padding-remove col-md-4'>
            <select
              className='form-control'
              onChange={e => handleModeChange(e.target.value)}
              value={oneTimeHeadId}
            >
              <option value={0}>Payment Mode</option>
              {lstPaymentMode &&
                lstPaymentMode.length > 0 &&
                lstPaymentMode.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.modeId}>
                      {obj.mode}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>
            Reference No.
          </label>
          <div className=' padding-remove col-md-2'>
            <input
              type='text'
              className='form-control'
              value={referenceNo}
              readOnly
            />
          </div>
        </div>
        <div className='form-group col-md-12 padding-remove'>
          <label className='control-label text-right col-md-3'>
            Communication
          </label>
          <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
            <div className='col-sm-3 multiButtonWrap'>
              <label>SMS</label>
              <input
                type='checkbox'
                defaultChecked={flgSms}
                onClick={e => handleSmsCBoxChange(e)}
              />
            </div>
            <div className='col-sm-3 multiButtonWrap'>
              <label>E-Mail</label>
              <input
                type='checkbox'
                defaultChecked={flgEmail}
                onClick={e => handleEmailCBoxChange(e)}
              />
            </div>
            <div className='col-sm-3 multiButtonWrap'>
              <label>WhatsApp</label>
              <input
                type='checkbox'
                defaultChecked={flgWhatsapp}
                onClick={e => handleWappCBoxChange(e)}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export default RegistrationFeeSlider
