import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { saveProcessPayroll } from './PayrollMonthProcessSvc';
const PayrollMonthProcessSlider = (props) => {
    const [payrollMonthId, setPayrollMonthId] = useState(0)
    const [loader, setLoader] = useState("none")
    const [lstMonth, setLstMonth] = useState([])
    const [flgOverride, setFlgOverride] = useState("")
    var tempLst = []
    function ProcessPayroll() {
        setLoader("block")
        saveProcessPayroll(payrollMonthId, flgOverride, cbProcessPayroll)
    }
    function cbProcessPayroll(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.closeSlider()
        } else {
            toast.error(data.SvcMsg)
        }
    }
    useEffect(() => {
        if (props.flgSlider == true) {
            //getMonthList();

            for (var i = 2019; i < 2030; i++) {
                for (var j = 0; j < 12; j++) {
                    var k = j + 1;
                    var mm = (k < 10) ? '0' + k : k;
                    var yyyy_mm = i + '-' + mm;
                    tempLst.push(yyyy_mm)
                    // console .log(yyyy_mm)
                }
            }
            if (tempLst !== []) {
                // console .log(tempLst)
                setLstMonth(tempLst)
            }
        }
    }, [props.flgSlider])
    // function getMonthList(){
    //     setLoader("block")
    //     getMonthListSvc(cbMonthList)
    // }
    // function cbMonthList(data) {
    //     if (data.SvcStatus === "Success") {
    //         setLstMonth(data.lstMonth)
    //     }
    //     else {
    //         setLstMonth([]);
    //         toast.error(data.SvcMsg)
    //     }
    //     setLoader('none');
    // }
    function handleMonthIdChange(param) {
        setPayrollMonthId(param)
    }
    function handleOverrideChange(param) {
        setFlgOverride(param)
    }
    return (
        <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: '100%', width: '100%', margin: '0px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Month</label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleMonthIdChange(e.target.value)} value={payrollMonthId}>
                            <option value={0}>Month</option>
                            {lstMonth.map((month, idx) => {
                                return <option key={idx} value={month}>{month}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Override</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='checkbox' className="form-control" defaultValue={flgOverride} defaultChecked={!flgOverride ? 0 : 1} onChange={(e) => handleOverrideChange(e.target.checked)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="button" className="btn btn-info btn-space" onClick={(e) => ProcessPayroll()}>Process Payroll</button>
            </div>
        </form>
    );
};
export default PayrollMonthProcessSlider;