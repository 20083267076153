import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import { getPtmListSvc, cancelPtmSvc, deletePtmSvc } from './PtmTableSvc.js'

const PtmTable = props => {
  const [lstPtm, setLstPtm] = useState([])
  const [ptmId, setPtmId] = useState(0)
  useEffect(() => {
    // console .log("PTM table called");
    getPtmListSvc(cbPtmListResponse)
  }, [props.key])

  function cbPtmListResponse (data) {
    if (data.SvcStatus === 'Success') {
      setLstPtm(data.lstPtm)
    } else {
      toast.error(data.SvcStatus)
    }
  }
  function deletePtm (pPtmId) {
    setPtmId(ptmId);
    var r = window.confirm('Are you sure to delete the PTM')
    if (!r) {
      return false
    }
    deletePtmSvc(pPtmId, cbResponse)
  }
  function cancelPtm (pPtmId) {
    setPtmId(ptmId);
    var r = window.confirm('Are you sure to cancel the PTM')
    if (!r) {
      return false
    }
    cancelPtmSvc(pPtmId, cbResponse)
  }

  function cbResponse (data) {
    // console .log("cbResponse called in PtmTable , data",data);
    if (data.SvcStatus === 'Success') {
      // console .log("data.SvcStatus === 'Success'");
      toast.success(data.SvcMsg)
      props.refreshTable();
    } else {
      // console .log("condition not true data.SvcStatus === 'Success' ");
      toast.error(data.SvcMsg)
    }
  }
  return (
    <div>
      <div className='col-sm-11' style={{ margin: '1% auto' }}>
        <table className='tableLayout1'>
          <thead>
            <tr>
              <th style={{ display: 'none' }}> Ptm Id </th>
              <th style={{ width: 30 }} className='text-center'>
                Date
              </th>
              <th style={{ width: 40 }} className='text-center'>
                Objective
              </th>
              <th style={{ width: 60 }} className='text-center'>
                Timings
              </th>
              <th style={{ width: 30 }} className='text-center'>
                SMS
              </th>
              <th style={{ width: 30 }} className='text-center'>
                WhatsApp
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Email
              </th>
              <th style={{ width: 30 }} className='text-center'>
                IVR
              </th>
              <th style={{ width: 90 }} className='text-center'>
                Action
              </th>
            </tr>
          </thead>
        </table>
        <div
          className='tableBodyWrapper'
          style={{
            height: '500px',
            overflowY: 'scroll',
            border: '1px solid #ccc'
          }}
        >
          <table id='ptmTable' className='tableLayout'>
            <colgroup>
              <col width='30' />
              <col width='40' />
              <col width='60' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='90' />
            </colgroup>
            <tbody>
              {lstPtm.map((obj, idx) => {
                return (
                  <tr
                    onClick={e => {
                      setPtmId(obj.ptmId)
                      props.setPtmData(obj.ptmId, obj.objective, obj.timings)
                    }}
                    className={obj.ptmId === ptmId ? 'selected' : ''}
                    key={idx}
                  >
                    <td className='no-display'>{obj.ptmId}</td>
                    <td className='text-center'>{obj.ptmDt}</td>
                    <td className='text-left'>{obj.objective}</td>
                    <td className='text-center'>{obj.timings}</td>
                    <td className='text-center'>{obj.flgSms ? 'Yes' : '-'}</td>
                    <td className='text-center'>
                      {obj.flgWhatsapp ? 'Yes' : '-'}
                    </td>
                    <td className='text-center'>
                      {obj.flgEmail ? 'Yes' : '-'}
                    </td>
                    <td className='text-center'>{obj.flgIvr ? 'Yes' : '-'}</td>
                    <td className='text-center'>
                      <label
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        className='text-primary'
                        onClick={e => props.openSlider(10, obj.ptmId)}
                      >
                        Edit
                      </label>
                      {' | '}
                      <label
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={e => props.openSlider(20, obj.ptmId)}
                        className='text-primary'
                      >
                        Attendance
                      </label>
                      {' | '}
                      <label
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={e => deletePtm(obj.ptmId)}
                        className='text-primary'
                      >
                        Delete
                      </label>
                      {' | '}
                      <label
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={e => cancelPtm(obj.ptmId)}
                        className='text-primary'
                      >
                        Cancel
                      </label>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default PtmTable
