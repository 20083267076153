import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import { getLstMonth, getClassListSvc, getSectionListSvc } from './MonthlyAttendanceSvc';
import DatePicker from 'react-datepicker';
import MonthlyAttendanceTable from './MonthlyAttendanceTable';

const MonthlyAttendanceCtrl = () => {
    const [loader, setLoader] = useState('none')
    const [monthId, setMonthId] = useState(0);
    const [presentPct, setPresentPct] = useState('')
    const [lstMonth, setLstMonth] = useState([]);
    const [lstCls, setLstCls] = useState([]);
    const [clsId, setClsId] = useState(null);
    const [lstSection, setLstSection] = useState([]);
    const [selSectionId, setSelSectionId] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [randNo, setRandNo] = useState(0);
    const mthId = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    useEffect(() => {
        const d = new Date();
        let month = d.getMonth();
        var mon = mthId[month]
        getMonth();
        setClass();
        setMonthId(mon);
    }, []);

    function getMonth() {
        getLstMonth(handleLstMonthResponse);
        // setLstMonth(getFakeLstMonth());
    }
    const handleMonthChange = (month) => {
        // console .log("handleMonthChange() called, parameter passed - month", month);
        if (month === 'Month') {
            return;
        }
        setMonthId(month)
        // console .log(month);
    }
    function handleLstMonthResponse(data) {
        var obj = data;

        if (obj.status == 'Success') {
            // console .log(obj)
            setLstMonth(obj.lstMonth);
        } else if (obj.status == 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message)
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    }

    function setClass() {
        getClassListSvc(handleClassResponse);
    }

    function handleClassResponse(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstCls(obj.lstCls);
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function handleClsChange(e) {
        // console .log("handleClassChange() called, parameter passed vClsId = e.target.value", e.target.value);

        var vClsId = e.target.value;
        setClsId(vClsId)
        setSelSectionId(0)
        setSection(vClsId);
        generateRandomNo();
    }

    function setSection(clsId) {
        getSectionListSvc(handleSectionResponse, clsId);
    }

    function handleSectionResponse(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstSection(obj.lstClassSection);
            // console .log("obj in handle SectionResponse", obj);
            // console .log("obj.lstClassSection", obj.lstClassSection);
            if(obj.lstClassSection && obj.lstClassSection.length> 0){
                changeSection(obj.lstClassSection[0].sectionId);
            }
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function changeSection(pSectionId) {
        setSelSectionId(pSectionId);
        generateRandomNo()
    }


    const getWingId = (wingId) => {
        // console .log('wingId = ' + wingId)
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className="MainContainer" >
                <div className="col-sm-12" style={{ height: '14vh' }}>
                    <div className="row">
                        <div className="col-sm-2" style={{ left: '4%', paddingRight: 0 }}>
                            <label className="col-sm-4 text-left padding-remove"> Month</label>
                            <div className="form-group col-sm-6 padding-remove" >
                                <select className="form-control" value={monthId !== "" && monthId} onChange={(e) => handleMonthChange(e.target.value)}>
                                    <option id={0}>Month</option>
                                    {lstMonth && lstMonth.length !== 0 && lstMonth.map((obj, i) => (
                                        <option key={obj.monthId} id={obj.monthId} value={obj.monthId}>{obj.month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-2" style={{ left: '4%', paddingRight: 0 }}>
                            <label className="col-sm-4 text-left padding-remove"> Class</label>
                            <div className="form-group col-sm-6 padding-remove">
                                <select autoComplete="off" name="classId" id="classesCmb"
                                    type="text" className="form-control"
                                    onChange={handleClsChange}
                                >
                                    <option value="0" >Class</option>
                                    {lstCls.map((obj) => (
                                        <option value={obj.classId}
                                            key={obj.classId} id={obj.classId} >{obj.classLabel}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-8 padding">
                            <div className="tab form-group padding">
                                {lstSection.map((obj) => {
                                    var isSelected = obj.sectionId === selSectionId;
                                    return <div style={{
                                        height: 34, display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} className={isSelected ? "sellected" : ""} onClick={(e) => changeSection(obj.sectionId)} key={obj.sectionId}>
                                        <span hidden>{obj.sectionId}</span>
                                        <span>{obj.section}</span>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="pull-right padding-remove col-sm-12" style={{ marginLeft: '2%', border: '1px solid rgb(180, 180, 180)' }}>
                                <label className="col-offset-1 col-sm-7 control-label padding-remove" style={{ color: 'rgb(255, 255, 255)', background: 'rgb(204, 204, 204)' }}>Presence:</label>
                                <label className="col-sm-5 control-label padding-remove pull-right">{presentPct}%</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12" style={{ height: '32vh' }}>
                    <MonthlyAttendanceTable
                        studentId={studentId}
                        sectionId={selSectionId}
                        monthId={monthId}
                        setStudentId={setStudentId}
                        key={randNo}
                        setPresentPct={setPresentPct}
                    />
                </div>
            </div>
        </div>
    )
}

export default MonthlyAttendanceCtrl