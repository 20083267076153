import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  saveCommunicationSvc,
  getClassListSvc,
  getWingListSvc,
  getSectionListSvc,
  getStudentListSvc,
  getPlaceholderListSvc,
  getCommunicationDtlsSvc,
  getLevelListSvc,
  getModuleListSvc,
  communicationGroupListSvc,
} from "./commSvc";
import { toast, ToastContainer } from "react-toastify";
import LEVELS from "../json/lstJson.json";

const CommunicationSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [levelId, setLevelId] = useState(0);
  const [state, setState] = useState({ commDt: null, dueDt: null });
  const [communicationId, setCommunicationId] = useState(props.communicationId);
  const [templateId, setTemplateId] = useState(0);
  const [placeholder, setPlaceholder] = useState("");
  const [lstWing, setLstWing] = useState([]);
  const [lstStudent, setLstStudent] = useState([]);
  const [studentTable, setStudentTable] = useState([]);
  const [lstCls, setLstCls] = useState([]);
  const [lstSection, setLstSection] = useState([]);
  const [flgAppNotification, setFlgAppNotification] = useState(false);
  const [flgEmail, setFlgEmail] = useState(false);
  const [flgIvr, setFlgIvr] = useState(false);
  const [flgSms, setFlgSms] = useState(false);
  const [flgWhatsapp, setFlgWhatsapp] = useState(false);
  const [title, setTitle] = useState("");
  const [template, setTemplate] = useState("");
  const [lstPlaceholder, setLstPlaceholder] = useState([]);
  const [lstLevel, setLstLevel] = useState([]);
  const [lstModule, setLstModule] = useState([]);
  const [moduleId, setModuleId] = useState(0);
  const [lstComGroup, setLstComGroup] = useState([]);
  const [comGroupId, setComGroupId] = useState(0);
  const [communicationObj, setCommunicationObj] = useState(
    props.communicationObj
  );
  const [receiverId, setReceiverId] = useState(0);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    // console .log([day, mnth, date.getFullYear()].join("-"));
    return [day, mnth, date.getFullYear()].join("-");
  }

  function handleModuleChange(pModuleId) {
    setModuleId(pModuleId);
  }
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getPlaceholderListSvc(cbPlaceholderListResponse);
    getLevelListSvc(cbLevelListResponse);
    getModuleListSvc(cbModuleListResponse);
    setCommunicationId(props.communicationId);
    if (props.communicationId) {
      getCommunicationDtlsSvc(
        props.communicationId,
        cbCommunicationDtlsResponse
      );
    }
    var today = new Date();
    var vDt = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (!props.communicationId) setState({ commDt: vDt });

    getLstClass();
    return () => {
      controller.abort();
    };
  }, [props.communicationId]);

  function cbLevelListResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setLstLevel(data.lstLevel);
    } else if (obj.SvcStatus === "Failure") {
      setLstLevel([]);
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function cbModuleListResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setLstModule(data.lstModule);
    } else if (obj.SvcStatus === "Failure") {
      setLstModule([]);
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function cbCommunicationDtlsResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setCommunicationDtls(data.commDtls);
    } else if (obj.SvcStatus === "Failure") {
      setCommunicationDtls({});
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function setCommunicationDtls(obj) {
    setTitle(obj.title);
    var pLevelId = obj.levelId;
    setLevelId(obj.levelId);
    setModuleId(obj.moduleId);
    getLevelData(obj.levelId);
    setTemplateId(obj.templateId);
    setTemplate(obj.content);
    props.setTemplate(obj.content);
    setFlgAppNotification(obj.flgAppNotification);
    setFlgWhatsapp(obj.flgWhatsapp);
    setFlgSms(obj.flgText);
    setFlgEmail(obj.flgEmail);
    setFlgIvr(obj.flgIvr);

    var today = new Date(obj.commDt.split("-").reverse().join());
    setState({ ...state, commDt: today });

    if (pLevelId === LEVELS.LEVEL_SCHOOL) {
      // console .log("school selected");
    }
    if (pLevelId === LEVELS.LEVEL_WING) {
      // console .log("wing selected");
      getWingListSvc(cbWingListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_CLASS) {
      // console .log("class selected");
      getClassListSvc(cbClsListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_SECTION) {
      // console .log("section selected");
      getSectionListSvc(cbSectionListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_STUDENT) {
      // console .log("student selected");
      getStudentListSvc(cbStudentListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_GROUP) {
        // console .log("group level selected");
      getGroupList();
    }
    setReceiverId(obj.receiverId);
  }
  function getLstClass() {
    // setLoader('block')
    getClassListSvc(cbClsListResponse);
  }

  function getLevelData(pLevelId) {
    // console .log("getLevelData() called.. " + pLevelId);
    // console .log(typeof pLevelId);
    // console .log(typeof LEVELS.LEVEL_WING);
    if (pLevelId === LEVELS.LEVEL_SCHOOL) {
      // console .log("school selected");
    }
    if (pLevelId === LEVELS.LEVEL_WING) {
      // console .log("wing selected");
      getWingListSvc(cbWingListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_CLASS) {
      // console .log("class selected");
      getClassListSvc(cbClsListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_SECTION) {
      // console .log("section selected");
      getSectionListSvc(cbSectionListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_STUDENT) {
      // console .log("student selected");
      getStudentListSvc(cbStudentListResponse);
    }
    if (pLevelId === LEVELS.LEVEL_GROUP) {
        // console .log("group level selected");
      getGroupList();
    }
    /* case LEVELS.LEVEL_STAFF:{
        getStaffListSvc(cbStaffListResponse)
        break
      } */
  }
  function cbPlaceholderListResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setLstPlaceholder(data.lstPlaceHolder);
    } else if (obj.SvcStatus === "Failure") {
      setLstPlaceholder([]);
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function cbWingListResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setLstWing(data.lstWing);
    } else if (obj.SvcStatus === "Failure") {
      setLstWing([]);
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function cbClsListResponse(data) {
    var obj = data;
    if (obj.status === "Success") {
      setLstCls(data.lstClass);
    } else if (obj.status === "Failure") {
      setLstCls([]);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function cbSectionListResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setLstSection(data.lstSection);
    } else if (obj.SvcStatus === "Failure") {
      setLstSection([]);
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function cbStudentListResponse(data) {
    var obj = data;
    if (obj.SvcStatus === "Success") {
      setLstStudent(data.lstStudent);
      setStudentTable(data.lstStudent);
    } else if (obj.SvcStatus === "Failure") {
      setLstStudent([]);
      setStudentTable([]);
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  
  

  const getGroupList = () => {
    // console .log("getGroupList called");
    setLoader('block');
    communicationGroupListSvc(communicationGroupListResponse);
}

const communicationGroupListResponse = (data) => {
    // console .log("communicationGroupListResponse called, data", data);
    let obj = data;
    if (obj.status === 'Success') {
        setLstComGroup(obj.lstCommGroup);
    }
    else {
        if (obj.message == 'You are not logged in') {
            setTimeout(function () {
                window.location.href = "/";
            }, 2000);
            return false;
        }
        else if (obj.message == 'Your Session is expired, login again') {
            setTimeout(function () {
                window.location.href = "/";
            }, 2000);
            return false;
        } else {
            toast.error(obj.message);
        }
    }
    setLoader('none');
}

  function handleCommDtChange(param) {
    setState({ ...state, commDt: param });
  }

  function saveCommunication() {
    let pCommunicationObj = {
      commId: props.communicationId,
      templateId: templateId,
      levelId: levelId,
      moduleId: moduleId,
      content: template,
      flgAppNotification: flgAppNotification,
      flgWhatsapp: flgWhatsapp,
      flgText: flgSms,
      flgEmail: flgEmail,
      flgIvr: flgIvr,
      title: title,
      commDt: convert(state.commDt),
      receiverId: receiverId,
    };
    setLoader("block");
    saveCommunicationSvc(pCommunicationObj, cbSaveCommunicationResponse);
  }
  function cbSaveCommunicationResponse(data, pCommId) {
    setLoader("none");
    var obj = data;
    if (obj.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      setCommunicationId(pCommId);
      props.getCommunicationList();
      props.setCommunicationId(pCommId);
	props.closeSlider();
    } else if (obj.SvcStatus === "Failure") {
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  
 

  const colgrpWing = (
    <colgroup>
      <col width="25%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );
  const colgrpGroup = (
    <colgroup>
      <col width="50%"></col>
      <col width="15%"></col>
      <col width="35%"></col>
    </colgroup>
  );
  const colgrpStudent = (
    <colgroup>
      <col width="25%"></col>
      <col width="30%"></col>
      <col width="10%"></col>
      <col width="16%"></col>
    </colgroup>
  );
  const colgrpClass = (
    <colgroup>
      <col width="10%"></col>
    </colgroup>
  );
  const colgrpSection = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );

  const filterStudentTable = (fName) => {
    let filterStudentTable = [];
    filterStudentTable = lstStudent.filter(student =>
      student.student.toString().toLowerCase().includes(fName.toLowerCase()));
    setStudentTable(filterStudentTable);
  }


  return (
    <>
      <form
        className="form form-horizontal"
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "calc(100% - 20px)",
          width: "100%",
          margin: "10px auto",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          onClick={props.closeSlider}
          style={{ right: "90%" }}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: loader,
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
        <h4>Communication</h4>
        <div className="row">
          <div className="col-sm-6 padding-remove">
            <div className="row no-display" style={{ width: "100%" }}>
              <div className="form-group col-md-6">
                <label className="control-label col-sm-5">
                  Communication Id
                </label>
                <div className="col-sm-6 padding-remove">
                  <input
                    type="text"
                    defaultValue={communicationId}
                    className="form-control"
                    readOnly
                  ></input>
                </div>
              </div>
            </div>

            <div className="col-sm-12" style={{ width: "100%" }}>
              <label
                className="control-label col-sm-2"
                style={{ width: "20%" }}
              >
                Level
              </label>

              <div className="form-group col-sm-10">
                <div
                  className="col-sm-12"
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {lstLevel &&
                    lstLevel.length > 0 &&
                    lstLevel.map((obj, idx) => {
                      return (
                        <label
                          key={"radio_" + idx}
                          className="radio-inline"
                          style={{ width: "16%" }}
                        >
                          <input
                            type="radio"
                            name="level"
                            defaultValue={"" + obj.levelId}
                            checked={levelId == obj.levelId}
                            onChange={(e) => {
                              var lvlId = obj.levelId;
                              // console .log("lvlId=" + lvlId);
                              setLevelId(lvlId);
                              setReceiverId(0);
                              getLevelData(lvlId);
                            }}
                          ></input>
                          {obj.level}
                        </label>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-offset-3 col-sm-8">
              {levelId === LEVELS.LEVEL_SCHOOL && <></>}
              {levelId == LEVELS.LEVEL_WING && (
                <>
                  <table className="tableLayout1">
                    {colgrpWing}
                    <thead>
                      <tr>
                        <th>Wing</th>
                        <th>From Class</th>
                        <th>To Class</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="tablebodyWrapper tableBodyBorder">
                    <table className="tableLayout">
                      {colgrpWing}
                      <tbody>
                        {lstWing.length > 0 &&
                          lstWing.map((obj, idx) => {
                            return (
                              <tr
                                key={"wing_" + idx}
                                className={
                                  levelId == 20 && obj.wingId == receiverId
                                    ? "selected"
                                    : ""
                                }
                                onClick={(e) => setReceiverId(obj.wingId)}
                              >
                                <td>{obj.wing}</td>
                                <td>{obj.fromCls}</td>
                                <td>{obj.toCls}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {levelId == LEVELS.LEVEL_CLASS && (
                <div className="col-sm-12">
                  <div className="col-sm-6 padding-remove">
                    <table className="tableLayout1">
                      {colgrpClass}
                      <thead>
                        <tr>
                          <th>Class</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="tablebodyWrapper tableBodyBorder">
                      <table className="tableLayout">
                        {colgrpClass}
                        <tbody>
                          {lstCls.length > 0 &&
                            lstCls.map((obj, idx) => {
                              return (
                                <tr
                                  className={
                                    levelId == 30 && obj.clsId == receiverId
                                      ? "selected"
                                      : ""
                                  }
                                  key={"cls_" + idx}
                                  onClick={(e) => setReceiverId(obj.clsId)}
                                >
                                  <td>{obj.cls}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {levelId == LEVELS.LEVEL_STUDENT && (
                <>
                  <table className="tableLayout1">
                    {colgrpStudent}
                    <thead>
                      <tr>
                        <th>Enrollment No.</th>
                        <th>
                          <input type="text" className="form-control inp-search"
                            onKeyUp={(e) => { filterStudentTable(e.target.value) }}
                            placeholder="Name"
                          />
                        </th>
                        <th>Class</th>
                        <th>Section</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="tablebodyWrapper tableBodyBorder">
                    <table className="tableLayout">
                      {colgrpStudent}
                      <tbody>
                        {studentTable.length > 0 &&
                          studentTable.map((obj, idx) => {
                            return (
                              <tr
                                key={"student_" + idx}
                                id={"student_" + obj.studentId}
                                className={
                                  levelId == 50 && obj.studentId == receiverId
                                    ? "selected"
                                    : ""
                                }
                                onClick={(e) => setReceiverId(obj.studentId)}
                              >
                                <td>{obj.enrollmentNo}</td>
                                <td>{obj.student}</td>
                                <td>{obj.cls}</td>
                                <td>{obj.section}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {levelId == LEVELS.LEVEL_SECTION && (
                <>
                  <table className="tableLayout1">
                    {colgrpSection}
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Class Teacher</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="tablebodyWrapper tableBodyBorder">
                    <table className="tableLayout">
                      {colgrpSection}
                      <tbody>
                        {lstSection.length > 0 &&
                          lstSection.map((obj, idx) => {
                            return (
                              <tr
                                key={"sec_" + idx}
                                id={"clssec_" + obj.clsSectionId}
                                className={
                                  levelId == 40 &&
                                    obj.clsSectionId == receiverId
                                    ? "selected"
                                    : ""
                                }
                                onClick={(e) => setReceiverId(obj.clsSectionId)}
                              >
                                <td>{obj.cls}</td>
                                <td>{obj.section}</td>
                                <td>{obj.clsTeacher}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {levelId == LEVELS.LEVEL_GROUP && (
                <>
                  <table className="tableLayout1">
                    {colgrpGroup}
                    <thead>
                      <tr>
                        <th>Group</th>
                        <th>Students</th>
                        <th>Created On</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="tablebodyWrapper tableBodyBorder">
                    <table className="tableLayout">
                      {colgrpGroup}
                      <tbody>
                        {lstComGroup.length > 0 &&
                          lstComGroup.map((grp) => {
                            return (
                              <tr
                                key={grp.commGroupId}
                                className={
                                  levelId == 60 &&
                                    grp.commGroupId == receiverId
                                    ? "selected"
                                    : ""
                                }
                                onClick={(e) => setReceiverId(grp.commGroupId)}
                              >
                                <td>{grp.commGroupName}</td>
                                <td>{grp.studentsCount}</td>
                                <td>{grp.createdDttm}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="col-sm-12" style={{ width: "100%" }}>
              <label
                className="control-label col-sm-2"
                style={{ width: "20%" }}
              >
                Date
              </label>
              <div className="form-group col-md-10">
                <div className="col-sm-4">
                  <DatePicker
                    id="inp_commDate"
                    className="form-control text-center"
                    placeholderText="Comm. Date"
                    selected={state.commDt}
                    dateFormat="dd-MM-yyyy"
                    onChange={handleCommDtChange}
                    autoComplete="off"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12" style={{ width: "100%" }}>
              <label
                className="control-label col-sm-2"
                style={{ width: "20%" }}
              >
                Channel
              </label>

              <div className="form-group col-sm-10">
                <div className="col-sm-12">
                  <div className="col-sm-2 multiButtonWrap padding-remove">
                    <label className="control-label">App</label>
                    <input
                      type="checkbox"
                      checked={flgAppNotification}
                      onChange={(e) => setFlgAppNotification(e.target.checked)}
                    />
                  </div>
                  <div className="col-sm-2 multiButtonWrap padding-remove">
                    <label className="control-label">E-Mail</label>
                    <input
                      type="checkbox"
                      checked={flgEmail}
                      onChange={(e) => setFlgEmail(e.target.checked)}
                    />
                  </div>
                  {/* <div className='col-sm-2 multiButtonWrap'>
                  <label className='control-label'>IVR</label>
                  <input
                    defaultChecked={flgIvr}
                    type='checkbox'
                    onClick={e => setFlgIvr(e.target.checked)}
                  />
                </div> */}
                  <div className="col-sm-2 multiButtonWrap padding-remove">
                    <label className="control-label">SMS</label>
                    <input
                      type="checkbox"
                      checked={flgSms}
                      onChange={(e) => setFlgSms(e.target.checked)}
                    />
                  </div>
                  <div className="col-sm-2 multiButtonWrap padding-remove">
                    <label className="control-label">WhatsApp</label>
                    <input
                      type="checkbox"
                      checked={flgWhatsapp}
                      onChange={(e) => setFlgWhatsapp(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 padding-remove">
            <div className="col-sm-12">
              <label
                className="control-label col-sm-2"
                style={{ width: "20%" }}
              >Module</label>
              <div className="form-group col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => handleModuleChange(e.target.value)}
                  value={moduleId}
                >
                  <option value={0}>Module</option>
                  {lstModule.map((item, idx) => {
                    return (
                      <option key={idx} value={item.moduleId}>
                        {item.module}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <label
                className="control-label col-sm-2"
                style={{ width: "20%" }}
              >
                Title
              </label>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                />
              </div>
            </div>
            <div className="col-sm-12" style={{ width: "100%" }}>
              <label
                className="control-label col-sm-2"
                style={{ width: "20%" }}
              >
                Template
              </label>

              <div className="form-group col-sm-10">
                <div className="col-sm-8 padding-remove">
                  <textarea
                    id="content"
                    value={template}
                    className="form-control"
                    rows="10"
                    onChange={(e) => {
                      setTemplate(e.target.value);
                      props.setTemplate(e.target.value);
                    }}
                    disabled={!!communicationId}
                  ></textarea>
                </div>
                <div className="col-sm-4 padding-remove">
                  <table className="tableLayout1">
                    <colgroup>
                      <col width="150" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Placeholder</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="">
                    <table className="tableLayout">
                      <colgroup>
                        <col width="150" />
                      </colgroup>
                      <tbody>
                        {lstPlaceholder &&
                          lstPlaceholder.length > 0 &&
                          lstPlaceholder.map((obj, idx) => {
                            return (
                              <tr
                                key={"placeholder_" + idx}
                                onClick={(e) => {
                                  setPlaceholder(obj);
                                  var curPos =
                                    document.getElementById(
                                      "content"
                                    ).selectionStart;
                                  setTemplate(
                                    template.slice(0, curPos) +
                                    obj +
                                    " " +
                                    template.slice(curPos)
                                  );
                                }}
                              >
                                <td>{obj}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div
                className="form-group col-md-10"
                style={{
                  marginLeft: "18%",
                  paddingBottom: "1px",
                }}
              >
                <div
                  className="col-sm-8 padding-remove"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-info pull-left"
                    onClick={(e) => saveCommunication()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-info pull-left"
                    onClick={(e) => {
                      document.querySelector("body").classList.add("showPopup");
                      props.setFlgPopup(true);
                    }}
                  >
                    View Sample
                  </button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default CommunicationSlider;
