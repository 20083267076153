import React, { useEffect, useState, Fragment } from 'react';
import { Document, Page, PDFViewer, View, Text, Image, Font } from '@react-pdf/renderer';


import { pageStyle, otpPDFStyle, textStyle, headerStyle } from './styleStudentOtpPdf';


Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});

const StudentOtpPdf = (props) => {
    const { testId, test, lstOtp } = props;

    useEffect(() => {
        // console .log("useEffect in StudentOtpPdf called, test", test, ", lstOtp", lstOtp);
    }, [lstOtp]);

    const students = [];
    function formatNumber(number) {
        // Convert the number to a string and split it into an array of characters
        const numberArray = number.toString().split('');
        // Join the array elements with a space
        return numberArray.join(' ');
    }

    const getStudentRow = (pStd1, pStd2, pStd3, pStd4, pStd5, isFirstRow) => {
        // console .log("getStudentRow called, pStd1", pStd1, ", pStd2", pStd2, ", pStd3", pStd3, ", pStd4", pStd4, ", isFirstRow", isFirstRow);
        return (
            <View style={isFirstRow ? otpPDFStyle.firstOtpRow : otpPDFStyle.otpRow}>
                <View style={otpPDFStyle.studentCell}>
                    <View key={pStd1.studentId} style={otpPDFStyle.column}>
                        <View style={otpPDFStyle.studentCellColumn}>
                            <Text style={textStyle.textLeftVerySmall}>{pStd1.student}</Text>
                        </View>
                        <View style={otpPDFStyle.studentCellColumn}>
                            <Text style={textStyle.textLeftVerySmall}>{pStd1.enrollmentNo}</Text>
                        </View>
                        <View style={otpPDFStyle.studentCellColumn}>
                            <Text style={textStyle.textLeftSmall}>{"OTP: " + formatNumber(pStd1.otp)}</Text>
                        </View>
                    </View>
                </View>
                <View style={otpPDFStyle.studentCell}>
                    {
                        !!pStd2 ?
                            <View key={pStd2.studentId} style={otpPDFStyle.column}>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd2.student}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd2.enrollmentNo}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftSmall}>{"OTP: " + formatNumber(pStd2.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={otpPDFStyle.studentCell}>
                    {
                        !!pStd3 ?
                            <View key={pStd3.studentId} style={otpPDFStyle.column}>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd3.student}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd3.enrollmentNo}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftSmall}>{"OTP: " + formatNumber(pStd3.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={otpPDFStyle.studentCell}>
                    {
                        !!pStd4 ?
                            <View key={pStd4.studentId} style={otpPDFStyle.column}>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd4.student}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd4.enrollmentNo}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftSmall}>{"OTP: " + formatNumber(pStd4.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
                <View style={otpPDFStyle.studentCell}>
                    {
                        !!pStd5 ?
                            <View key={pStd5.studentId} style={otpPDFStyle.column}>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd5.student}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftVerySmall}>{pStd5.enrollmentNo}</Text>
                                </View>
                                <View style={otpPDFStyle.studentCellColumn}>
                                    <Text style={textStyle.textLeftSmall}>{"OTP: " + formatNumber(pStd5.otp)}</Text>
                                </View>
                            </View>
                            : null
                    }
                </View>
            </View>
        )
    }

    for (let i = 0; i < lstOtp.length; i = i + 5) {
        const studentRow = getStudentRow(lstOtp[i], lstOtp[i + 1], lstOtp[i + 2], lstOtp[i + 3], lstOtp[i + 4], i === 0);
        students.push(studentRow);
    }

    return (
        <div className='col-sm-12 padding-remove' style={{ marginTop: "1vh" }}>
            <PDFViewer style={{ width: "100%", height: "98vh", overflowY: "scroll" }}>
                <Document >
                    <Page style={pageStyle.page} wrap>
                        <View style={pageStyle.fullPage}>
                            <View style={pageStyle.testHeader} >
                                <View style={headerStyle.column}>
                                    <View style={headerStyle.row}>
                                        <View style={headerStyle.viewLeft}>
                                            <Text style={textStyle.textCenterMediumBold}>{"List of OTP"}</Text>
                                        </View>
                                    </View>
                                    <View style={headerStyle.row}>
                                        <View style={headerStyle.leftSection}>
                                            <View style={headerStyle.viewLeft}>
                                                <Text style={textStyle.textLeftMediumBold}>
                                                    {test}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={headerStyle.rightSection}>
                                            <View style={headerStyle.viewRight}>
                                                <Text style={textStyle.textRightMediumBold}>{"Test Id: " + testId}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={pageStyle.horizontalBar2} fixed></View>
                            <View style={pageStyle.pageContent}>
                                {students.map((student, index) => (
                                    <Fragment key={index} >
                                        {student}
                                    </Fragment>
                                ))}
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    );
}

export default StudentOtpPdf;