import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { studentDiscountListSvc } from "./feeDiscountSvc";
import { set } from "lodash";


const FeeDiscountTable = (props) => {

    const { setLoader, setOpacity, setStudentCount, setDiscountSum } = props;

    const [studentFilter, setStudentFilter] = useState("");
    const [enrollmentNoFilter, setEnrollmentNoFilter] = useState("");
    const [clsSecFilter, setClsSecFilter] = useState("");
    const [lstDiscount, setLstDiscount] = useState([]);
    const [lstDiscounts, setLstDiscounts] = useState([]);
    

    useEffect(() => {
        getLstDisocunt();
    }, []);

    const getLstDisocunt = () => {
        setLoader("block");
        setOpacity(0.5);
        studentDiscountListSvc(lstDiscountResponse);
    }

    const lstDiscountResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            setLstDiscounts(obj.lstDiscount);
            setLstDiscount(obj.lstDiscount);
            updateDiscountSummary(obj.lstDiscount);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const updateDiscountSummary = (lstDisc) => {
        // console .log("updateDiscountSummary in feeDiscountTable");
        setStudentCount(lstDisc ? lstDisc.length : 0);
        let ttlDiscount = 0;
        {
            lstDisc &&
                lstDisc.map((student) => {
                    // console .log("student (row) in map function", student);
                    ttlDiscount = ttlDiscount + student.discount;
                })
        }
        setDiscountSum(ttlDiscount);
    }

    const filterFeeDiscountList = (fEnrollNo, fName, fClassSection) => {
        let filterFeeDiscountList = [];
        filterFeeDiscountList = lstDiscounts.filter(student =>
            student.enrollmentNo.toString().toLowerCase().includes(fEnrollNo.toLowerCase()));

        filterFeeDiscountList = filterFeeDiscountList.filter(student =>
            (student.cls + "-" + student.section).toString().toLowerCase().includes(fClassSection.toLowerCase()));

        filterFeeDiscountList = filterFeeDiscountList.filter(student =>
            student.student.toString().toLowerCase().includes(fName.toLowerCase()));

        setLstDiscount(filterFeeDiscountList);
        // updateDiscountSummary(filterFeeDiscountList);
    }

    const handleFilterEnrollmentNo = (e) => {
        setEnrollmentNoFilter(e.target.value);
        filterFeeDiscountList(e.target.value, studentFilter, clsSecFilter);
    }

    const handleFilterName = (e) => {
        setStudentFilter(e.target.value);
        filterFeeDiscountList(enrollmentNoFilter, e.target.value, clsSecFilter);
    }

    const handleFilterClassSection = (e) => {
        setClsSecFilter(e.target.value);
        filterFeeDiscountList(enrollmentNoFilter, studentFilter, e.target.value);
    }

    return (
        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th className="no-display" style={{ width: '100px' }}>
                            StudentId
                        </th>
                        <th style={{ width: '200px' }}>
                            <input type="text" className="form-control inp-search text-left"
                                value={studentFilter}
                                onChange={(e) => handleFilterName(e)}
                                name="name"
                                id="nameFilter"
                                placeholder="Name" />
                        </th>
                        <th style={{ width: '150px' }}>
                            <input type="text" className="form-control inp-search text-left"
                                name="enrollmentNo"
                                id="enrollmentNoFilter"
                                value={enrollmentNoFilter}
                                onChange={(e) => handleFilterEnrollmentNo(e)}
                                placeholder="Enrollment No." />
                        </th>
                        <th style={{ width: '150px' }}>
                            <input type="text" className="form-control inp-search"
                                value={clsSecFilter}
                                onChange={(e) => handleFilterClassSection(e)}
                                name="cls"
                                id="clsFilter"
                                placeholder="Class" />
                        </th>
                        <th className="text-center" style={{ width: '150px' }}>
                            Total Fees
                        </th>
                        <th className="text-center" style={{ width: '150px' }}>
                            Discount
                        </th>
                        <th className="text-center" style={{ width: '150px' }}>
                            Net Fees
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv" style={{ height: "500px" }}>
                <table className='tableLayout'>
                    <tbody id="feeDiscountTable">
                        {lstDiscount &&
                            lstDiscount.map((student) => (
                                <tr key={student.studentId} >
                                    <td style={{ width: 100 }} className='no-display'>{student.studentId}</td>
                                    <td style={{ width: 200 }} className='text-left'>{student.student}</td>
                                    <td style={{ width: 150 }} className='text-left'>{student.enrollmentNo}</td>
                                    <td style={{ width: 150 }} className='text-center'>{student.cls + "-" + student.section}</td>
                                    <td style={{ width: 150 }} className='text-center'>{student.fees.toLocaleString('en-IN')}</td>
                                    <td style={{ width: 150 }} className='text-center'>{student.discount.toLocaleString('en-IN')}</td>
                                    <td style={{ width: 150 }} className='text-center'>{student.netFees.toLocaleString('en-IN')}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FeeDiscountTable;