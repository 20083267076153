import React from 'react';


function TableBodyTch({ bodyCls, tableId, tbodyId, colswidth, lstTeacher, onChildClick, onDragStarted}) {  
    // console .log("lstSubject = "+JSON.stringify(lstTeacher));
    
    return (
          <div >
                <table id={tableId} className="tableLayout">
                <colgroup>
                {colswidth.map((width,idx)=>{
                    return  <col key={idx} width={width} ></col>
                })}
                </colgroup>
                <tbody id={tbodyId}> 
                {lstTeacher.map((item,index)=>{   
                    
                    return <tr key={index}>
                        <td>{(index+1)}</td>
                        <td id={item.teacherId} draggable="true" onClick={onChildClick} onDragStart={onDragStarted}>{item.teacher}</td>
                        <td>{item.remarks}</td>
                    </tr>
                })}
                </tbody>
                </table>
          </div>
    );
}
export default TableBodyTch;