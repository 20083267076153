import React, { useEffect, useState } from 'react'
import { getLstTermSvc } from './TermHeaderSvc';
import { toast, ToastContainer } from 'react-toastify';


const TermHeader = (props) => {
    const [termId, setTermId] = useState(0)
    const [lstTerm, setLstTerm] = useState([{ termId: 1, term: 'Ist Term' }, { termId: 2, term: 'IInd Term' }])

    useEffect(() => {
        getLstTermSvc(cbLstTermResponse);
    }, [])

    function cbLstTermResponse(data) {
        if (data.status === "Success") {
            setLstTerm(data.lstTerm)
            setTermId(data.lstTerm[0].termId)
            document.getElementById('term_' + data.lstTerm[0].termId).click()
        } else {
            toast.error(data.message)
        }
    }

    function termClick(pTermId, pTerm) {
        props.termClick(pTermId, pTerm);
    }

    return (
        <div className="row" style={{ boxShadow: '#ccc 0px 1px 4px 0px', borderWidth: '1px 0px', borderStyle: 'solid', height: '50px', borderColor: '#ccc', padding: '5px 20px', height: 'auto' }}>
            <div className="tab">
                {lstTerm && lstTerm.map((obj, idx) => {
                     // console .log(obj.termId) 
                    var isSelected = obj.termId === props.termId;

                    return <div id={'term_' + obj.termId} className={isSelected ? "sellected" : ""} onClick={(e) => termClick(obj.termId, obj.term)} key={obj.termId}>

                        <span hidden>{obj.termId}</span>
                        <span>{obj.term}</span>

                    </div>
                })}
            </div>
        </div >
    )
}

export default TermHeader