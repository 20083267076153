import React, { useState, useEffect, useRef } from 'react'
import ReportCardPDF from './reportCardPDF.js';
import { getProgressReportSvc, getLogoSvc, getPrinciSignSvc, getValidationDtlsSvc } from './progressReportSvc.js';
// , getDocDtlsSvc
import { toast, ToastContainer } from 'react-toastify';



const ProgressReportSlider = (props) => {

  const { heading, sectionId, syllabusId } = props;

  const [lstReportCard, setLstReportCard] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [flgValidated, setFlgValidated] = useState(false);
  const [teacherSignUrl, setTeacherSignUrl] = useState("");
  const [loader, setLoader] = useState("none");
  const [opacity, setOpacity] = useState(1);


  useEffect(() => {
    // console .log("progressReportSlider Called heading ", props.heading, ", sectionId ", props.sectionId);
    getPrinciSignSvc(cbPrinciSign);
    getLogoSvc(cbLogoResponse);
    getProgressReport();
  }, [props.sectionId, syllabusId]);

  function cbPrinciSign(imageUrl) {
    setImageUrl(imageUrl)
  }
  function cbLogoResponse(imageUrl) {
    // console .log("cbLogoResponse called, imageUrl", imageUrl);
    setLogoUrl(imageUrl)
  }

  const getProgressReport = () => {
    setLoader("block");
    setOpacity(0.5);
    getProgressReportSvc(props.sectionId, syllabusId, progressReportResponse);
  }

  const progressReportResponse = (data) => {
    // console .log("progressReportResponse called, data", data);
    let obj = data;
    if (obj.status === 'Success') {
      setLstReportCard(obj.lstReportCardObj);
    }
    else {
      if (obj.message == 'You are not logged in') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      }
      else if (obj.message == 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }
    setLoader("none");
    setOpacity(1);
  }

  const getValidationDtls = (nSectionId, nExamId) => {
    // console .log("getValidationDtls called, nSectionId", nSectionId);
    // console .log("nExamId", nExamId);
    setLoader("block");
    setOpacity(0.5);
    getValidationDtlsSvc(nSectionId, nExamId, validationDtlsResponse);
  }

  const validationDtlsResponse = (data) => {
    // console .log("validationDtlsResponse called, data", data);
    let obj = data;
    if (obj.status === 'Success') {
      // console .log("obj.examScoreValidationDtls", obj.examScoreValidationDtls);
      setFlgValidated(obj.examScoreValidationDtls.validatedById !== 0);
    }
    else {
      handleSessionErrors(obj.message);
    }
    setLoader("none");
    setOpacity(1);
  }

  const handleSessionErrors = (message) => {
    if (message === 'You are not logged in') {
      toast.error('You are not logged in. Redirecting to login page...');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
      return true;
    } else if (message === 'Your Session is expired, login again') {
      toast.error('Your session has expired. Redirecting to login page...');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
      return true;
    } else {
      toast.error(message);
      return false;
    }
  };
  const closeSlider = () => {
    props.removeClassFromBody();
  }



  return (
    <div className='padding-remove'>
      <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div>
      <div className='col-sm-12 col-centered padding-remove' style={{ opacity: opacity }}>
        <div className='EnquiryHead col-sm-12 col-centered'
          style={{
            border: '1px solid #aaa',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: 'bold',
            textAlign: "center"
          }}>
          <h4>{heading}</h4>
        </div>
        <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
          {lstReportCard && lstReportCard.length ?
            <ReportCardPDF
              logoUrl={logoUrl}
              lstReportCard={lstReportCard}
              teacherSignUrl={teacherSignUrl}
              imageUrl={imageUrl}
              flgValidated={flgValidated}
            />
            : null
          }
        </div>
      </div >
      <button
        type='button'
        className='btn btn-info text-center '
        id='closeSlider'
        onClick={e => closeSlider()}>
        <span
          id='close'
          title='Close Slider'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
    </div>
  );
}

export default ProgressReportSlider;