import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import HeaderSession from 'common/js/headerSession';
import { getLstRole, getLstSession, getLstTTtchSummary, previewPdf, sendTimeTable } from './tchWiseServices';
import TchWiseSummaryTbl from './tchWiseSummaryTbl';
import TchWiseSlider from './tchswiseslider';
import ViewSlider from './viewslider1';

const TchWiseCtrl = () => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [sliderKey, setSliderKey] = useState(0);
    const [selSessionId, setSelSessionId] = useState(0)
    const [state, setState] = useState(initialState);
    const [lstSession, setLstSession] = useState([]);
    const [lstRole, setLstRole] = useState([]);
    const [lstTTtchSummary, setLstTTtchSummary] = useState([]);
    const [sliderDisplayed, setSliderDisplayed] = useState(false)
    const [tch, setTch] = useState('');
    const [roleId, setRoleId] = useState(0);
    const [randNo, setRandNo] = useState(0);
    const [tchId, setTchId] = useState(0);

    useEffect(() => {
        setSessions();
        setRoles();
    }, []);


    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    }

    function setRoles() {
        getLstRole(handleLstRoleResponse);
    }
    function handleLstRoleResponse(data) {
        var obj = data;
        if (obj.status === 'Success') {
            // console .log(obj.lstRole)
            setLstRole(obj.lstRole);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            console.error("Service failed " + obj.message);
        }
    }
    function setSessions() {
        getLstSession(handleLstSessionResponse);
    }
    function handleLstSessionResponse(data) {
        // console .log(data)
        var obj = data;
        if (obj.status === 'Success') {
            // console .log(obj.lstSession)
            setLstSession(obj.lstSession);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            console.error("Service failed " + obj.message);
        }
    }


    function setTTtchSummary(sessionId) {
        getLstTTtchSummary(sessionId, handleLstTTtchSummaryResponse);
    }

    function handleLstTTtchSummaryResponse(data) {
        var obj = data;
        if (obj.status === 'Success') {
            setLstTTtchSummary(obj.lstTTtchSummary);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }

    const clickSessionTab = (e, sessionId) => {
        for (let sibling of e.currentTarget.parentNode.children) {
            if (sibling !== e.target) sibling.classList.remove('active');
        }
        e.currentTarget.classList.add('active');
        setSelSessionId(sessionId);
        setTTtchSummary(sessionId);
        setTch('')
        document.getElementById('teacherFilter').value = '';
        setRoleId(0)

    }

    async function handleEditClick(e, val, id) {
        // console .log(val);
        // console .log(id)
        e.preventDefault();
        if (val) {
           await setTchId(id);
           await setSliderDisplayed(true);
            // setSliderKey(Math.round(Math.random()*999999));
        }
        else
            closeSlider();
    }

    function handlePreviewPdfResponse(data) {
        var obj = data;

        if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        showSlider();
        // console .log(data.data);
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        // console .log(iframe);
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);

    }
    const showSlider = () => {
        setSliderDisplayed(true);
    };

    const closeSlider = () => {
        generateRandomNo()
        setSliderDisplayed(false);
    };

    function sendTT(forced, tchArr) {
        // console .log("sendTT called, sessionId = " + selSessionId + ", forced = " + forced + ", tchArr = " + tchArr);
        sendTimeTable(selSessionId, forced, tchArr, handleSendTTResponse);
    }
    function handleSendTTResponse(data) {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ marginTop: 15, opacity: state.opacity }}>
                <div className={lstSession.length > 4 ? "col-sm-11 col-centered padding-remove" : "col-sm-6 col-centered padding-remove"} >
                    {lstSession.length !== 0 && <HeaderSession lstSession={lstSession} clickSessionTab={clickSessionTab} />}
                    {/* <h1>HeaderSession to be placed here</h1> */}
                </div>
                <div className="col-sm-7 col-centered padding-remove">
                    {lstRole && lstRole.length !== 0 && <TchWiseSummaryTbl key={randNo} roleId={roleId} lstRole={lstRole} lstTTtchSummary={lstTTtchSummary} handleEditClick={handleEditClick} send={sendTT} tch={tch} setTch={setTch} />}
                    {tchId && <div className='slider halfWidthSlider' style={{ right: 0, opacity: 1, display: sliderDisplayed === true ? 'block' : 'none', zIndex: 10000 }}>
                        <ViewSlider
                            setFlgClose={()=>setSliderDisplayed(false)}
                            flg={sliderDisplayed}
                            tchId={tchId}
                            sessionId={selSessionId}
                        ></ViewSlider>
                </div>}
                </div>

            </div>

        </div >

    )
    function Slider(props) {
        return (
            <div className="slider halfWidthSlider" style={{ opacity: sliderDisplayed ? 1 : 0, right: 0 }}>
                 <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                    <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="viewer" style={{ width: '100%', height: '100%' }} />
                </div>
                <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ opacity: sliderDisplayed ? 1 : 0, right: '70%' }} onClick={(e) => handleEditClick(e, false)}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
            </div>);

    }
}

export default TchWiseCtrl