import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import SliderType from "./sliderType";
import lstModules from "common/json/lstModules.json";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
const PIXSZ = 8;

const RegistrationSiblings = (props) => {
  const [lstSibling, setLstSibling] = useState([]);
  const [regsId, setRegsId] = useState(0);
  const [loader, setLoader] = useState("none");
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    // console .log(props);
    if (props.flgSliderType === SliderType.SLIDER_REGISTER) {
      if (props.regsId) {
        setLoader("block");
        setRegsId(props.regsId);
        getSiblingListSvc(props.regsId, cbSiblingListResponse);
      }
    }
    return () => {
      controller.abort();
    };
  }, [props.regsId, props.flgSliderType]);

  function getLstSibling() {
    getSiblingListSvc(props.regsId, cbSiblingListResponse);
  }

  function cbSiblingListResponse(data) {
    if (data.SvcStatus === "Success") {
      // console .log("sibling::" + JSON.stringify(data));
      setLstSibling(data.lstSiblingDtls);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
    setLoader("none");
  }

  function getSiblingListSvc(regsId, callBack) {
    // console .log("regsId = " + regsId);
    axios({
      url: httpURL + "/sms/regs/sibling/dtls",
      method: "POST",
      params: { regsId: regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;

        if (data.lstSiblingDtls !== undefined) callBack(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deleteSibling(pSiblingId, cb) {
    var r = window.confirm("Are you sure to delete the sibling?");
    if (r) {
      axios({
        url: httpURL + "/sms/regs/sibling/delete",
        method: "POST",
        params: { siblingId: pSiblingId },
        headers: { tokenid: tokenid },
        withCredentials: true,
      })
        .then((Response) => {
          var data = Response.data;
          cb(data);
        })
        .catch((error) => {
          // console .log("error" + JSON.stringify(error));
        });
    }
  }

  function cbDeleteSibling(data) {
    if (data.SvcStatus === "Success") {
      // console .log("sibling::" + data);
      toast.success(data.SvcMsg);
      getLstSibling();
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
    setLoader("none");
  }

  const hdr = [
    { label: "Name", sz: 30 },
    { label: "DOB", sz: 12 },
    { label: "Gender", sz: 10 },
    { label: "Class", sz: 10 },
    { label: "School", sz: 40 },
    { label: "Action", sz: 15 },
  ];

  function ColGrp({ items }) {
    return items.map((data) => (
      <col key={data.label} width={data.sz * PIXSZ}></col>
    ));
  }

  return (
    <>
      <div
        className="col-sm-12"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "1%",
        }}
      >
        <div className="col-sm-8">
          <div
            className="col-sm-12"
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
          >
            <ToastContainer autoClose={3000} hideProgressBar />

            <div
              style={{
                position: "fixed",
                zIndex: 10000,
                width: 100,
                height: 100,
                marginLeft: -50,
                marginTop: -50,
                left: "50%",
                top: "50%",
                display: loader,
              }}
            >
              <img
                className="loderImg"
                src={require("common/images/loader.gif")}
                alt="Loader..."
              />
            </div>
            <div>
              <table>
                <colgroup>
                  <ColGrp items={hdr} />
                </colgroup>
                <thead>
                  <tr>
                    {hdr.map((data, idx) => {
                      return (
                        <th key={idx} width={data.sz * PIXSZ}>
                          {data.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {lstSibling.map((data) => {
                    return (
                      <tr id={data.siblingId} key={data.siblingId}>
                        <td>{data.name}</td>
                        <td>{data.dob}</td>
                        <td>{data.gender === "M" ? "Male" : "Female"}</td>
                        <td>{data.cls}</td>
                        <td>{data.school}</td>
                        <td className="text-center">
                          <span
                            className="glyphicon glyphicon-edit"
                            onClick={(e) => {
                              props.setPopup(true);
                              props.setSiblingId(data.siblingId);
                              props.setSibling(data.name);
                              props.setClsId(data.clsId);
                              props.setSchool(data.school);
                              props.setGender(data.gender);
                              var tmpDob = data.dob.split("-");
                              tmpDob = new Date(tmpDob.reverse().join());
                              props.setDob(tmpDob);
                              document
                                .querySelector("body")
                                .classList.add("showPopup");
                            }}
                          ></span>
                          <span
                            className="glyphicon glyphicon-trash"
                            onClick={(e) =>
                              deleteSibling(data.siblingId, cbDeleteSibling)
                            }
                          ></span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xs-1">
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
            onClick={() => {
              props.setPopup(true);
              props.setSiblingId(0);
              // props.setRegsId(regsId);
              document.querySelector("body").classList.add("showPopup");
            }}
          ></span>
        </div>
      </div>
    </>
  );
};

export default RegistrationSiblings;
