import React, { useState, useEffect } from "react";

const TptAttedanceTable = (props) => {
  const [loader, setLoader] = useState("none");

  const [uniqueId, setUniqueId] = useState('');
  const [lstRoute, setLstRoute] = useState([]);

  useEffect(() => {
    // console .log(props)
    setLstRoute(props.lstRoute);
  }, [props.lstRoute]);

  useEffect(() => {
    // console .log(props)
    setUniqueId(props.uniqueId);
  }, [props.uniqueId]);

  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="15%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );
  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Date</th>
            <th>Route</th>
            <th>Total Students</th>
            <th>Present</th>
            <th>Absent</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstRoute.map((obj, idx) => {
              return (
                <tr
                  key={idx+1}
                  onClick={(e) => props.setUniqueId(idx+1)}
                  className={idx+1 === props.uniqueId ? "selected" : ""}
                >
                  <td className="no-display">{idx+1 }</td>
                  <td>{obj.attendanceDt}</td>
                  <td className="text-left">{obj.routeNo}</td>
                  <td className="text-right">{obj.studentCount}</td>
                  <td className="text-right">{obj.present}</td>
                  <td className="text-right">{obj.absent}</td>
                  <td className="text-center">
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        props.setData(idx, obj);
                        props.showSlider();
                      }}
                      className="glyphicon glyphicon-edit"
                    ></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TptAttedanceTable;
