import axios from "axios";
import lstModules from "common/json/lstModules.json";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
function getSlotListSvc(callBack) {
  // console .log("getSlotListSvc called");
  axios({
    url: httpURL + "/sms/slot/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}

function getStaffListSvc(callBack) {
  axios({
    url: httpURLCommon + "/sms/mst/staff/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function getClassListSvc(callBack) {
  axios({
    url: httpURLCommon + "/sms/mst/cls/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}

function saveSlotSvc(callBack, slotObj) {
  axios({
    url: httpURL + "/sms/slot/save",
    method: "POST",
    params: { slot: JSON.stringify(slotObj) },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      if (data.slotId === undefined) {
        data.slotId = slotObj.slotId;
      }
      callBack(data, slotObj);
    })
    .catch((error) => {
      console.error(error);
    });
}
function deleteSlotSvc(slotId, callBack) {
  axios({
    url: httpURL + "/sms/slot/delete",
    method: "POST",
    params: { slotId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function informInvigilatorSvc(callBack, slotId, invigilator) {
  axios({
    url: httpURL + "/sms/slot/inform_invigilator",
    method: "POST",
    params: { slotId: slotId, invigilatorNo: invigilator },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      data.slotId = slotId;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export { getClassListSvc };
export { getStaffListSvc };
export { getSlotListSvc };
export { informInvigilatorSvc };
export { deleteSlotSvc };
export { saveSlotSvc };
