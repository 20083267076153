import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import 'common/css/header.css';
import 'common/bootstrap/css/bootstrap.min.css';
import { toast } from 'react-toastify';

import MenuItems from "common/json/menuItems.json";
import Menu from "./menu.js";
import SettingPopup from "./settingPopup";
import Logout from './logout';

import { ChangePass } from "./changePass";

import { getRoleListSvc } from "./headerSvc";

const Header = () => {

    const [wingId, setWingId] = useState(parseInt(localStorage.getItem('wingId')) || 0);
    const [academicYearId, setAcademicYearId] = useState(localStorage.getItem('academicYearId') || 0);
    const [schoolId, setSchoolId] = useState(parseInt(localStorage.getItem('schoolId')) || 0);
    const [lstRole, setLstRole] = useState([]);
    const [lstSecondaryRole, setLstSecondaryRole] = useState([]);
    const [roleId, setRoleId] = useState(localStorage.getItem('roleId') || 0);
    const [role, setRole] = useState("");
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [activePage, setActivePage] = useState("");

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSettingOpen, setIsSettingOpen] = useState(false);
    const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);
    const [isChangePassOpen, setIsChangePassOpen] = useState(false);
    const [lstMenuItems, setLstMenuItems] = useState(MenuItems.menuItems);



    useEffect(() => {
        getRoleList();
        getScreenName();
    }, []);

    const getScreenName = () => {
        const currentPath = window.location.pathname
        // Use forEach instead of map when you're not creating a new array
        lstMenuItems &&
            lstMenuItems.forEach((menuItem) => {
                if (menuItem.menuUrl === currentPath) {
                    setActivePage(menuItem.menuTitle);
                }
                if (menuItem.submenu) {
                    for (const submenuItem of menuItem.submenu) {
                        if (submenuItem.subMenuUrl === currentPath) {
                            setActivePage(submenuItem.subMenuTitle);
                        }
                    }
                }
            });
    };


    const getRoleList = () => {
        // console .log("getRoleList called");
        setLoader("block");
        setOpacity(0.5);
        getRoleListSvc(schoolId, roleListResponse);
    }

    const roleListResponse = (data) => {
        // console .log("roleListResponse called");
        var obj = data;
        // console .log("obj",obj);

        if (obj.status === 'Success') {
            // console .log("obj.status is success and obj.academicYearId",obj.academicYearId);
            setRoleId(obj.roleId);
            setRole(obj.role);
            setAcademicYearId(obj.academicYearId);
            setWingId(obj.wingId);
            setLstSecondaryRole(obj.lstSecondaryRole);

            // console .log('academicYearId', obj.academicYearId);
            // console .log('wingId', obj.wingId);
            // console .log('roleId', obj.roleId);
            // console .log('role', obj.role);
            // console .log('lstSecondaryRole', obj.lstSecondaryRole);
            // console .log("obj.lstRole", obj.lstRole);

            var roleList = obj.lstSecondaryRole;
            // console .log("roleList", roleList);
            var primaryRoleData = {
                roleId: obj.roleId,
                role: obj.role,
            };
            roleList.unshift(primaryRoleData);

            setLstRole(roleList);
            // console .log("primaryRoleData", primaryRoleData);
            // console .log("roleList", roleList);

            localStorage.setItem('roleId', obj.roleId);
            localStorage.setItem('academicYearId', obj.academicYearId);
            localStorage.setItem('wingId', obj.wingId);
        }
        else {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const toggleMenu = () => {
        setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    }

    const toggleNotification = () => {
        setIsSettingOpen(false);
        setIsChangePassOpen(false);
        setIsNotificationPopupOpen((prevIsNotificationPopupOpen) => !prevIsNotificationPopupOpen);
    }

    const toggleSetting = () => {
        // console .log('toggleSetting() called...');
        setIsNotificationPopupOpen(false);
        setIsChangePassOpen(false);
        setIsSettingOpen((prevIsSettingOpen) => !prevIsSettingOpen);
    }

    const changePass = () => {
        // console .log('changePass() called...');
        setIsSettingOpen(false);
        setIsNotificationPopupOpen(false);
        setIsChangePassOpen((prevIsChangePassOpen) => !prevIsChangePassOpen);
    }

    const closePopups = () => {
        setIsSettingOpen(false);
        setIsNotificationPopupOpen(false);
        setIsChangePassOpen(false);
    }

   

    return (
        <>
            <div className={`header ${isMenuOpen ? "openMenu" : ""}`} style={{ padding: '5px' }}>
                <div className="container-fluid" style={{ margin: 0 }}>
                    {/* <Online>Only shown when you're online</Online> */}
                    {/* <div className="internetConnectivity">
                        <Offline> Please Check Your Connectivity !</Offline>
                    </div> */}
                    <div className="row">
                        <div className=" col col-sm-1 hamberger" style={{ width: '4%', ...(!isMenuOpen ? { cursor: "pointer", fontSize: "30px" } : { left: "200px", position: "fixed", top: 0, zIndex: 100000, transition: "all 0.3s ease-in-out", }), }}>
                            <i className="fa fa-bars " onClick={(e) => { toggleMenu(); e.preventDefault(); }}></i>
                        </div>
                        {/* <div className="col col-sm-1 hamberger openMenu" style={{ width: '4%', left: "200px", position: "fixed", top: 0, zIndex: 100000, transition: "all 0.3s ease-in-out;" }}>
                            <i className="fa fa-bars" onClick={(e) => { toggleMenu(); e.preventDefault(); }}></i>
                        </div> */}
                        <div className="col col-sm-3 mostFrequentlyUsed">
                            {/* {screenNameList.map((item, index) => (
                                <div key={index}>
                                    <div className="top3Links" title="Most Used Links">
                                        {item.top3ScreensId.map((screensId, i) => (
                                            <span key={i} id={screensId} onClick={this.topPage}>
                                                {this.getScreenNames(screensId).toUpperCase()}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ))} */}
                        </div>
                        <div className="col col-sm-2 headerWingWrap pull-right" style={{ width: '18%', }}>
                            <i
                                className="col-sm-1 fa fa-bell"
                                title="Notification"
                                onClick={() => toggleNotification()}></i>
                            <i className="col-sm-1 fa fa-cog" title="Setting" onClick={() => toggleSetting()}></i>
                            <i
                                className="col-sm-1 fa fa-key"
                                title="Change Password"
                                onClick={() => changePass()}></i>
                            <Logout
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                            />
                        </div>
                        <div className="col col-sm-offset-3 col-sm-3 pageHeader" style={{ width: '18%' }}>
                            <div className="pageName">{activePage}</div>
                        </div>
                        {/* <div className="col col-sm-3 searchBar">
                            <select className="col col-sm-4 classSelect" id="classCmb" onChange={this.changeClass} />
                            <input type="text" placeholder="Search" className=" col col-sm-8 search" /><i className="fa fa-search searchIcon"> </i>
                        </div> */}
                        {/* <div
                            ref={this.wrapperRef}
                            className='col col-sm-1 profile pull-right'
                            style={{ width: '5%' }}
                        ></div> */}
                    </div>

                </div>
                <Menu/>
            </div>
            {isChangePassOpen &&
                <div className=" col-sm-4 col-centered pass padding-remove" style={{ display: 'block' }} >
                    <ChangePass
                        closePass={closePopups}
                        setOpacity={setOpacity}
                        setLoader={setLoader}
                    />
                </div>
            }
            {isSettingOpen &&
                <div className=" col-sm-4 col-centered settingWrap" >
                    <SettingPopup
                        setOpacity={setOpacity}
                        setLoader={setLoader}
                        lstRoles={lstRole}
                        closePopups={closePopups}
                    />
                </div>
            }
        </>
    );
}

export default Header;
