import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import { getLstSectionSvc } from './PromoteCtrlSvc';
import PromotionTable from './PromotionTable';
import { stepButtonClasses } from '@mui/material';
import ManualPromotionSlider from './ManualPromotionSlider';
import { setConstantValue } from 'typescript';


const PromoteCtrl = () => {
    const [sectionObj, setSectionObj] = useState({})
    const [randNo, setRandNo] = useState(0);
    const [selIdx, setSelIdx] = useState(-1);
    //state for slider
    const [srandNo, setSrandNo] = useState(0);
    const [flgSlider, setFlgSlider] = useState(false)
    const [fromCls, setFromCls] = useState('')
    const [toClsId, setToClsId] = useState(0)
    const [toCls, setToCls] = useState('')
    const [fromClsId, setFromClsId] = useState(0)
    const [fromAcdYrId, setFromAcdYrId] = useState(0)
    const [toAcdYrId, setToAcdYrId] = useState(0)
    const [fromAcdYr, setFromAcdYr] = useState('')
    const [toAcdYr, setToAcdYr] = useState('')
    const [available, setAvailable] = useState(0)
    const [total, setTotal] = useState(0)
    const [lstToSection, setLstToSection] = useState([])
    const params = new URLSearchParams(window.location.search);
    useEffect(() => {
        async function fetchData() {
            // const cFromAcdYrId = await localStorage.getItem("fromAcdYrId");
            // const cToAcdYrId = await localStorage.getItem("toAcdYrId");
            // const cFromAcdYr = await localStorage.getItem("fromAcdYr");
            // const cToAcdYr = await localStorage.getItem("toAcdYr");
            const cFromAcdYrId = params.get("fromAcdYrId");
            const cToAcdYrId = params.get("toAcdYrId");
            const cFromAcdYr = params.get("fromAcdYr");
            const cToAcdYr = params.get("toAcdYr");
            // console .log(cFromAcdYrId)
            // console .log(cToAcdYrId)
            await setFromAcdYrId(cFromAcdYrId)
            await setFromAcdYr(cFromAcdYr)
            await setToAcdYrId(cToAcdYrId)
            await setToAcdYr(cToAcdYr)
        }
        fetchData();

    }, [])

    /********* functions for table **********/
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    }

    /********* end for table **********/

    /********* function for header **********/
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }

    /********* end for header **********/

    /********* functions for slider **********/
    function openSlider(e) {
        e.stopPropagation();
        setFlgSlider(true)
    }

    async function setSlider(obj, idx, flgOpenSlider) {
        // console .log(obj)
        // console .log(idx)
        // console .log(flgOpenSlider)
        setFromClsId(obj.fromClsId)
        setFromCls(obj.fromCls)
        setToClsId(obj.toClsId)
        setToCls(obj.toCls)
        setLstToSection(obj.lstToSection)
        setAvailable(obj.toClsAvailable)
        setTotal(obj.toClsTotal);
        /* setToCls(obj.toCls) */
        setSelIdx(idx)

        await setFlgSlider(flgOpenSlider)
    }

    function generateSRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setSrandNo(lRandNo)
    }

    function closeSlider() {
        generateSRandomNo()
        setFlgSlider(false)
    }

    /********* end for slider **********/
    const getLocalFromToAcdYr = <span>{fromAcdYrId + ", " + fromAcdYr + ", " + toAcdYrId + ", " + toAcdYr}</span>


    return (

        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="MainContainer" >
                <div className="col-md-11 col-centered clearfix">
                    <div className="col-md-12">
                        <div className="col-md-6  col-xs-6">
                            < PromotionTable
                                key={randNo}
                                fromAcdYr={fromAcdYr}
                                fromAcdYrId={fromAcdYrId}
                                toAcdYr={toAcdYr}
                                toAcdYrId={toAcdYrId}
                                openSlider={openSlider}
                                onSetSlider={setSlider}
                                selIdx={selIdx}
                                generateRandomNo={generateRandomNo} />
                        </div>


                        <div className="slider halfWidthSlider" style={{ right: 0, opacity: 1, display: flgSlider === true ? 'block' : 'none', zIndex: 10000 }}>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-info text-center btnCloseDiv"
                                    onClick={(e) => closeSlider()}>
                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                            <ManualPromotionSlider
                                key={srandNo}
                                fromClsId={fromClsId}
                                fromCls={fromCls}
                                toClsId={toClsId}
                                toCls={toCls}
                                lstToSection={lstToSection}
                                generateRandomNo={generateRandomNo}
                                closeSlider={closeSlider}
                                total={total}
                                available={available}
                                fromAcdYrId={fromAcdYrId}
                                toAcdYrId={toAcdYrId}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PromoteCtrl