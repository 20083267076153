import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import Header from "common/js/header/header.js";
import "react-datepicker/dist/react-datepicker.css";
import "common/css/master.css";
import { getLstClassSummary, getLstMonth } from "./summaryServices.js";

const ClassSummary = () => {
  const [state, setState] = useState({
    loader: "none",
    opacity: 1,
    dt: new Date(),
    dispDt: convert(new Date()),
  });
  const [defaultMonth, setDefaultMonth] = useState("");
  const [mnth, setMnth] = useState("");
  const [lstMonth, setLstMonth] = useState([]);
  const [lstClassSummary, setLstClassSummary] = useState([]);
  const [totalExp, setTotalExp] = useState(0);
  const [totalRec, setTotalRec] = useState(0);
  const [totalDiff, setTotalDiff] = useState(0);
  const [totalFeeHead, setTotalFeeHead] = useState({});
  const [lstFeeHeads, setLstFeeHeads] = useState([]);
  const mthId = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    const d = new Date();
    let month = d.getMonth();
    var mon = mthId[month];
    getMonth();
    setDefaultMonth(mon);
    setMnth(mon);
    if (!!params.get("monthId")) {
      setDefaultMonth(params.get("monthId"));
      setMnth(params.get("monthId"));
      mon = params.get("monthId");
    }
    getClsSummary(mon);
  }, []);
  function getMonth() {
    getLstMonth(handleLstMonthResponse);
    // setLstMonth(getFakeLstMonth());
  }
  function handleLstMonthResponse(data) {
    var obj = data;
    setState({ loader: "none", opacity: 1 });
    if (obj.status == "Success") {
      // console .log(obj);
      setLstMonth(obj.lstMonth);
    } else if (obj.status == "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj.message);
    }
  }
  function getFakeLstMonth() {
    var lstmonth = [
      {
        monthId: 11,
        month: "January",
      },
      {
        monthId: 12,
        month: "February",
      },
      {
        monthId: 1,
        month: "March",
      },
      {
        monthId: 2,
        month: "April",
      },
      {
        monthId: 3,
        month: "May",
      },
      {
        monthId: 4,
        month: "June",
      },
      {
        monthId: 5,
        month: "July",
      },
      {
        monthId: 6,
        month: "August",
      },
      {
        monthId: 7,
        month: "September",
      },
      {
        monthId: 8,
        month: "October",
      },
      {
        monthId: 9,
        month: "November",
      },
      {
        monthId: 10,
        month: "December",
      },
    ];
    return lstMonth;
  }
  function getClsSummary(monthId) {
    if (monthId) {
      getLstClassSummary(monthId, handleClsSummaryResponse);
    } else {
      toast.warn("Please Select a Month");
    }

    // getFakeClsSummary();
  }
  const getFakeClsSummary = () => {
    var obj = {
      SvcStatus: "Success",
      lstClassSummary: [
        {
          clsSectionId: 11,
          clsSection: "V-A",
          exp: 25000000,
          rec: 20000000,
          diff: 50000000,
          fee: {
            Annual: 16000000,
            Transport: 2000000,
            Sports: 1000000,
          },
        },
        {
          clsSectionId: 10,
          clsSection: "V-B",
          exp: 6000000,
          rec: 5000000,
          diff: 1000000,
          fee: {
            Annual: 16000000,
            Transport: 2000000,
            Sports: 1000000,
          },
        },
      ],
      totalExp: 31000000,
      totalRec: 25000000,
      totalDiff: 51000000,
      feeTotal: { Annual: 32000000, Transport: 4000000, Sports: 2000000 },
    };
    setLstClassSummary(obj.lstClassSummary);
    // console .log(obj.lstClassSummary[0].fee);
    setLstFeeHeads(Object.keys(obj.lstClassSummary[0].fee));
    setState({
      dt: new Date(),
      dispDt: convert(new Date()),
      loader: "none",
      opacity: 1,
    });
    setTotalExp(obj.totalExp);
    setTotalRec(obj.totalRec);
    setTotalDiff(obj.totalDiff);
    setTotalFeeHead(obj.feeTotal);
  };
  const handleMonthChange = (month) => {
    if (month === "Month") {
      return;
    }

    setMnth(month);
    // console .log("handleDtChange() called");
    // console .log(month);
    getClsSummary(month);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    // console .log([day, mnth, date.getFullYear()].join("-"));
    return [day, mnth, date.getFullYear()].join("-");
  }

  function handleClsSummaryResponse(data) {
    var obj = data;
    setState({ loader: "none", opacity: 1 });
    if (obj.status == "Success") {
      setLstClassSummary(obj.lstClassSummary);
      setLstFeeHeads(Object.keys(obj.lstClassSummary[0].fee));
      setTotalExp(obj.totalExp);
      setTotalRec(obj.totalRec);
      setTotalDiff(obj.totalDiff);
      setTotalFeeHead(obj.feeTotal);
    } else if (obj.status == "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj.message);
    }
  }
  const navStudentSummary = (e, clsSectionId, clsSection) => {
    e.preventDefault();
    // localStorage.setItem('clsSectionId', clsSectionId)
    // localStorage.setItem('clsSection', clsSection)
    let newUrl =
      "/studentSummary?clsSectionId=" +
      clsSectionId +
      "&clsSection=" +
      clsSection + "&month=" + mnth;
    window.location.href = newUrl;
  };
  const getWingId = (wingId) => {
    // console .log("wingId = " + wingId);
  };
  return (
    <div style={{ height: "100%" }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          display: state.loader,
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div
        className="mainContainer "
        style={{ pointerEvents: state.opacity == 1 ? "auto" : "none" }}
      >
        <div className="col-md-12 col-centered">
          <div className="col-centered col-sm-4">
            <h6>
              Class Summary for the month of
              <div
                className="col-sm-4 pull-right padding-remove"
                style={{ top: -7 }}
              >
                <select
                  className="form-control"
                  defaultValue={defaultMonth !== "" && defaultMonth}
                  onChange={(e) => handleMonthChange(e.target.value)}
                >
                  <option>Month</option>
                  {lstMonth &&
                    lstMonth.length !== 0 &&
                    lstMonth.map((obj, i) => (
                      <option
                        id={obj.monthId}
                        value={obj.monthId}
                        selected={defaultMonth == obj.monthId}
                      >
                        {obj.month}
                      </option>
                    ))}
                </select>
              </div>
            </h6>
          </div>
          <div className="col-md-12">
            <table className="tableLayout1" id="summaryTableHead">
              <colgroup>
                <col width="40" />
                <col width="40" />
                <col width="40" />
                <col width="40" />
                {lstFeeHeads && lstFeeHeads.map((obj, i) => (
                  <col key={i} width="40" />
                ))}
              </colgroup>
              <thead>
                <tr>
                  <th>Class</th>
                  <th>Expected</th>
                  <th>Received</th>
                  <th>Difference</th>
                  {lstFeeHeads && lstFeeHeads.map((obj, i) => (
                    <th key={i}>{obj}</th>
                  ))}
                </tr>
              </thead>
            </table>
            <div className="tableDiv">
              <table id="summaryTable" className="tableLayout">
                <colgroup>
                  <col width="40" />
                  <col width="40" />
                  <col width="40" />
                  <col width="40" />
                  {lstFeeHeads.map((obj, i) => (
                    <col key={i} width="40" />
                  ))}
                </colgroup>
                <tbody id="summaryTableBody">
                  {lstClassSummary &&
                    lstClassSummary.length > 0 ?
                    lstClassSummary.map((obj, i) => (
                      <tr key={i} id={obj.monthId}>
                        <td
                          className="text-center"
                          onClick={(e) =>
                            navStudentSummary(
                              e,
                              obj.clsSectionId,
                              obj.clsSection
                            )
                          }
                        >
                          <a>{obj.clsSection}</a>
                        </td>
                        <td className="text-right">
                          {obj.exp && obj.exp.toLocaleString("en-IN")}
                        </td>
                        <td className="text-right">
                          {obj.rec && obj.rec.toLocaleString("en-IN")}
                        </td>
                        <td className="text-right">
                          {obj.diff && obj.diff.toLocaleString("en-IN")}
                        </td>
                        {lstFeeHeads && lstFeeHeads.length > 0 ? lstFeeHeads.map((feeObj, j) => (
                          <td key={j} className="text-right">
                            {obj.fee[feeObj] && obj.fee[feeObj].toLocaleString("en-IN")}
                          </td>
                        )) : null
                        }
                      </tr>
                    )) : null
                  }
                  {
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Total
                      </td>
                      <td style={{ fontWeight: "bold" }} className="text-right">
                        {totalExp && totalExp.toLocaleString("en-IN")}
                      </td>
                      <td style={{ fontWeight: "bold" }} className="text-right">
                        {totalRec && totalRec.toLocaleString("en-IN")}
                      </td>
                      <td style={{ fontWeight: "bold" }} className="text-right">
                        {totalDiff && totalDiff.toLocaleString("en-IN")}
                      </td>
                      {lstFeeHeads && lstFeeHeads.map((feeHdObj, k) => (
                        <td
                          key={k}
                          style={{ fontWeight: "bold" }}
                          className="text-right"
                        >
                          {parseInt(totalFeeHead[feeHdObj]).toLocaleString(
                            "en-IN"
                          )}
                        </td>
                      ))}
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassSummary;
