import React, { useEffect, useState } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleSchoScore, } from './examReportCardStyles.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const ScholasticSection = (props) => {
    const { lstSchoScore, exam, examSchoSummary } = props;


    useEffect(() => {
        // console .log("scoreSection.js called, lstSchoScore", lstSchoScore);

    }, [lstSchoScore]);

    return (
        <View style={styleSchoScore.container}>
            <View style={styleSchoScore.score_table}>
                <View style={[styleSchoScore.score_head, { height: "40px" }]}>
                    <View style={[styleSchoScore.exam_row, { flex: 1 }]}>
                        <View style={styleSchoScore.subject_col}>
                            <View style={[styleSchoScore.top_left_cell, { flex: 1 }]}>
                                <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                    SUBJECTS
                                </Text>
                            </View>
                        </View>
                        <View style={[styleSchoScore.top_cell, { flex: 1 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {exam}(<Text style={{ textTransform: "capitalize" }}>{examSchoSummary.totalMax + ' Marks'}</Text>)
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={[styleSchoScore.score_rows, { }]}>
                    {lstSchoScore.map((subject, idx) => (
                        <View key={idx} style={[styleSchoScore.score_row, { height: "20px" }]}>
                            <View style={styleSchoScore.subject_col}>
                                <View style={[styleSchoScore.left_cell, { flex: 1 }]}>
                                    <Text style={styleSchoScore.text_subject_label}>
                                        {subject.subject}<Text style={{ textTransform: "capitalize" }}></Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styleSchoScore.mark_col}>
                                <View style={[styleSchoScore.inner_cell, { flex: 1 }]}>
                                    <Text style={[styleSchoScore.text_center, styleSchoScore.text_value]}>{subject.score}</Text>
                                </View>
                            </View>
                        </View>
                    ))}
                </View >
            </View >
        </View >
    );
}

export default ScholasticSection;
