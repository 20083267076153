import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
var httpURLEnquiry =
  lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var tokenid = localStorage.getItem('tokenid')

function getStudentListSvc (callBack) {
  axios({
    url: httpURLEnquiry + '/sms/enquiry/onetimefee/student/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console .log('One Time Head List Catch error')
    })
}
export { getStudentListSvc }
