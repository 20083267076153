import React, { useEffect, useState } from "react";
import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import PeriodForm from "./periodForm";
import PeriodTable from "./periodTable";


const httpURL = lstModules['academicSchedule'].url + ":" + lstModules['academicSchedule'].port;
const queryString = require('querystring');
const screenId = 32;
const lstPeriodType = [];
const tokenid = localStorage.getItem('tokenid');

const SchedulePeriodForm = (props) => {
    const { closeSlider, acdSchId, updateKey, setLoader, setOpacity } = props;

    // const [lstSchedulePeriod, setLstSchedulePeriod] = useState([]);
    const [academicScheduleId, setAcademicScheduleId] = useState(acdSchId);
    // const [flgPeriod, setFlgPeriod] = useState(true);
    const [startTime, setStartTime] = useState("");
    const [period, setPeriod] = useState(0);
    const [isPeriodFormOpen, setIsPeriodFormOpen] = useState(false);
    const [schedulePeriodData, setSchedulePeriodData] = useState({});
    const [periodTableKey, setPeriodTableKey] = useState(0);

    // useEffect(() => {
    // console .log("UseEffect called in periodform.jsx");
    // console .log("academicScheduleId ", acdSchId);
    // }, []);

    
    const updatePeriodTableKey = () => {
        setPeriodTableKey(prevKey => prevKey + 1);
    }

    const addPeriod = () => {
        setPeriodForm(0, "", "", "", "", false);
        openPopup();
    }

    const openPopup = () => {
        setIsPeriodFormOpen(true);
    }

    const closePopup = () => {
        setIsPeriodFormOpen(false);
    }

    const setPeriodForm = (schDlPeriodId, prd, strtTm, periodTp, pduration, flgAtnd) => {
        // console .log("setPeriodForm called");
        // console .log("schDlPeriodId:", schDlPeriodId);
        // console .log("prd:", prd);
        // console .log("strtTm:", strtTm);
        // console .log("periodTp:", periodTp);
        // console .log("pduration:", pduration);
        // console .log("flgAtnd:", flgAtnd);
        const schdlPeriodData = {
            academicScheduleId:acdSchId,
            schedulePeriodId: schDlPeriodId,
            period: prd,
            startTime: strtTm,
            periodType: periodTp,
            duration: pduration,
            flgAttendance: flgAtnd,
        }
        setSchedulePeriodData(schdlPeriodData);
    }

    const editPeriodForm = (schDlPeriodId, prd, strtTm, periodTp, pduration, flgAtnd) => {
        setPeriodForm(schDlPeriodId, prd, strtTm, periodTp, pduration, flgAtnd);
        openPopup();
    }



    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
            </div>

            <div className="col-xs-12 col-centered padding-remove " style={{ height: '100vh', width: '100%', background: '#fff', border: '1px solid #ccc' }}>
                <form className="col-xs-12 form-horizontal formWrapper clearfix" style={{ width: '80%', borderRadius: 10, marginLeft: "10%", marginTop: "40px" }}>
                    <div className="form-group col-md-8 padding-remove">
                        <label className="control-label col-sm-6" >Start Time </label>
                        <div className="col-sm-6 padding-remove startTimeCmb">
                            <input autoComplete="off" type="text" className="form-control " value={startTime} onChange={(e) => setStartTime(e.target.value)} placeholder="Start Time" disabled />
                        </div>
                    </div>
                    <div className="form-group col-md-8 padding-remove">
                        <label className="control-label col-sm-6 " > Periods</label>
                        <div className="col-sm-6 padding-remove">
                            <input autoComplete="off" type="text" className="form-control " value={period} onChange={(e) => setPeriod(e.target.value)} placeholder="Number Of Periods" disabled />
                        </div>
                    </div>
                </form>
                <div className="col-sm-12 padding-remove periodTableWrap" style={{ width: '80%', borderRadius: 10, marginLeft: "10%", marginTop: "20px" }}>
                    <div className="col-sm-11 padding-remove">
                        <PeriodTable
                            academicScheduleId={academicScheduleId}
                            editPeriodForm={editPeriodForm}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            key={periodTableKey}
                        />
                    </div>
                    <div className="col-xs-1 addPeriods">
                        <span id="addPeriods" title="Add Period" onClick={() => addPeriod()} className="addIcon glyphicon glyphicon-plus-sign"></span>
                    </div>
                    {isPeriodFormOpen &&
                        <PeriodForm
                            schedulePeriodData={schedulePeriodData}
                            closePopup={closePopup}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            updateKey={updatePeriodTableKey}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default SchedulePeriodForm;
