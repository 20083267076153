import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";


import ChapterForm from "./chapterForm";
import ChapterTable from "./chapterTable";
import { getChapterSvc, saveChapterSvc } from "./chapterSvc";


const Chapter = (props) => {

    const [lstChapters, setLstChapters] = useState();
    const [chapterId, setChapterId] = useState(0);
    const [sybSubId, setSybSubId] = useState(0);
    const [subjectId, setSubjectId] = useState(0);
    const [chapterData, setChapterData] = useState({});
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(0);
    const [formKey, setFormKey] = useState(0);

    useEffect(() => {
        // console .log("Chapter component called, props", props);
        setSubjectId(props.subjectId);
        setSybSubId(props.sybSubId);
        // setLstChapters(props.lstChapters);
        getChapterList(props.sybSubId);
    }, [props.subjectId, props.sybSubId]);

    const getChapterList = (sybSubId) => {
        // console .log("getChaperList called, sybSubId", sybSubId);
        setLoader("block");
        setOpacity(0.5);
        getChapterSvc(sybSubId, chapterListResponse);
    }

    const chapterListResponse = (data) => {
        // console .log("chapterListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstChapters(obj.lstChapters);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const saveChapter = (pChapterId, pChapterCode, pChapterName, pChapterPeriods) => {
        // console .log("addChapters method called in chapter.js");
        // console .log("pChapterId", pChapterId);
        // console .log("pChapterCode", pChapterCode);
        // console .log("pchapterName", pChapterName);
        // console .log("pChapterPeriods", pChapterPeriods);
        let chapterObj = {};
        chapterObj = {
            'chapterId': pChapterId,
            'subjectId': subjectId,
            "sybSubId":sybSubId,
            "syllabusId":props.syllabusId,
            'chapterCode': pChapterCode,
            'chapterName': pChapterName,
            'periods': pChapterPeriods,
            // 'lstPartOf': pChapterExam,
        }
        // console .log("chapterObj object", chapterObj);
        setLoader("block");
        setOpacity(0.5);
        saveChapterSvc(chapterObj, saveChapterResponse);
    }


    const saveChapterResponse = (data) => {
        // console .log("saveChapterResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            getChapterList(sybSubId);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const clearForm = async () => {
        // console .log("clearForm called");
        await setChapterId(0);
        await setChapterData({ "chapterId": 0, "chapterCode": '', "chapterName": '', "subjectId": 0, "periods": 0 });
        setFormKey((prevKey) => prevKey + 1);
    }

    const rowClick = async (pChapterId, pChapterCode, pChapterName, pPeriods) => {
        // console .log("rowClick method called, pChapterId", pChapterId);
        // console .log("clearForm called");
        await setChapterId(pChapterId);
        await setChapterData({ "chapterId": pChapterId, "chapterCode": pChapterCode, "chapterName": pChapterName, "periods": pPeriods });
        setFormKey((prevKey) => prevKey + 1);
        // props.getTopicList(pChapterId);
    }

    // const getChapterById = (chapId) => {
    //     if (lstChapters && lstChapters.length) {
    //         for (var i = 0; i < lstChapters.length; i++) {
    //             if (lstChapters[i].chapterId === chapId) {
    //                 return lstChapters[i];
    //             }
    //         }
    //     }
    //     const selChapter = {
    //         chapterId: 0,
    //         chapterCode: '',
    //         chapterName: '',
    //         // lstPartOf: [],
    //         subjectId: 0,
    //         periods: 0
    //     };
    //     return selChapter;
    // }

    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove ">
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => props.closeSlider()} style={{ right: "80%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>

            </div>
            <div className="col-md-12 col-xs-12 col-centered" style={{ width: '100%', padding:"10px 0 0 0", background: '#fff', }}>
                <div className="col-md-6">
                    <ChapterTable
                        key={sybSubId}
                        lstChapters={lstChapters}
                        rowClick={rowClick} />
                </div>
                <div className="col-md-12 col-xs-1 ">
                    <span id="addReadyId" title="Add" onClick={() => clearForm()} className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
                <div className="col-md-5">
                    {chapterData ?
                        <ChapterForm
                            key={chapterId}
                            formKey={formKey}
                            saveChapter={saveChapter}
                            chapterData={chapterData}
                            clearForm={clearForm}
                        />
                        : null
                    }
                </div>

            </div>
        </div>
    );
}
export default Chapter;