import React, { useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";

import { uploadExcelSvc } from './studentsSvc';
import AcademicYearPopup from 'common/login/js/academicYearPopup';


const UploadStudent = (props) => {
    const { setLoader, setOpacity, addNewStudent, flgShowError, setFlgShowError, updateKey, setLstErrors } = props;

    const [academicYearId, setAcademicYearId] = localStorage.getItem("academicYearId");
    // const schoolId = localStorage.getItem("schoolId");

    useEffect(() => {
        // console .log("UploadStudent component called");
    }, []);

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            uploadFile(file);
        } else {
            setLoader("none");
            setOpacity(1);
        }
    };

    const uploadFile = (file) => {
        setLoader("block");
        setOpacity(0.5);

        const formData = new FormData();
        formData.append("studentExcelFile", file);
        formData.append("acdYrId", academicYearId);

        uploadExcelSvc(formData, (response) => {
            // console .log("response in uploading student excel file", response);
            if (response.SvcStatus === "Success") {
                toast.success(response.SvcMsg);
                showStudents();
                updateKey();
            } else {
                toast.error(response.SvcMsg);
                handleServiceError(response.SvcMsg);
                if (response.errors && response.errors.length > 0) {
                    setLstErrors(response.errors);
                    showError();
                }
            }
            setLoader("none");
            setOpacity(1);
        });
    };

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const showError = () => {
        // console .log("showError called");
        setFlgShowError(true);
    }

    const showStudents = () => {
        // console .log("showStudents called");
        setFlgShowError(false);
    }

    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove">
            <div className="col-sm-3 col-xs-3">
                <span
                    id="addStudent"
                    title="Add Student"
                    className="addIcon glyphicon glyphicon-plus-sign"
                    onClick={addNewStudent}
                ></span>
            </div>
            <div className="col-sm-9 col-xs-9" style={{ display: "flex", flexDirection: "column", gap: "5px", border: "1px solid #ccc", marginTop: "10px" }}>
                <div className="form-group">
                        <AcademicYearPopup
                            academicYearId={academicYearId}
                            setAcademicYearId={setAcademicYearId}
                        />
                    </div>
                <div className="form-group ">
                    <input
                        type="file"
                        id="excelUpload"
                        className="hidden"
                        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFileChange}
                    />
                    <button
                        id="uploadeExcel"
                        title="Upload Excel"
                        type="button"
                        onClick={() => document.getElementById('excelUpload').click()}
                        className="btn btn-warning"
                    >
                        Upload Student
                    </button>
                </div>
                <div className="form-group">
                    <button
                        type="button"
                        id="btn_showerror"
                        title={!flgShowError ? "View Errors" : "View Students"}
                        onClick={() => { flgShowError ? showStudents() : showError() }}
                        className="btn btn-info"
                    >
                        {!flgShowError ? "View Errors" : "View Students"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UploadStudent;
