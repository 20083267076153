import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import DashboardSummary from "./DashboardSummary";
import DashboardTable from "./DashboardTable";

// project related common imports

import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import ExamDateHeader from './examDateHeader';

const ExamDashboardCtrl = () => {
    const [loader, setLoader] = useState("none");
    const [examId, setExamId] = useState(0);
    const [keyTable, setKeyTable] = useState(0);
    const [keySummary, setKeySummary] = useState(0);
    const [randNo, setRandNo] = useState(0);
    const [selStatusId, setSelStatusId] = useState([])
    const [selSectionId, setSelSectionId] = useState(0)

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setKeyTable(lRandNo)
    }

    function refreshExamSchedule(pExamId) {
        setExamId(pExamId)
        generateRandomNo()

    }

    function clickStatus(pStatusId) {
        // console .log(pStatusId)
        setSelStatusId(pStatusId)
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" style={{ marginTop: '15px', opacity: '1' }}>
                <div className="col-sm-12 col-centered ">
                    <div className="col-centered col-sm-7 padding-remove">
                        <ExamDateHeader examClick={refreshExamSchedule} examId={examId} />
                    </div>
                    <div className="col-sm-12 padding-remove hidden" style={{ height: "20%", boxShadow: "rgb(204 204 204) 8px 2px 4px 0px", borderWidth: "1px 0px", borderStyle: "solid", borderColor: "rgb(204, 204, 204)", padding: "5px 20px", border: "none", pointerEvents: loader === "none" ? "auto" : "none" }}>
                        <div className="col-sm-12 div_summary">
                            <DashboardSummary key={keySummary} clickStatus={clickStatus} selStatusId={selStatusId} examId={examId} />
                        </div>
                    </div>
                    <div className="col-sm-9 col-centered" style={{ top: 10, height: '100%' }}>
                        <DashboardTable
                            key={keyTable}
                            setSectionId={setSelSectionId}
                            sectionId={selSectionId}
                            selStatusId={selStatusId}
                            examId={examId}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamDashboardCtrl