import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");

function getLstTchDutySvc(pExamId, callback) {
    var obj = { status: "Success", lstTchDuty: [] }
    axios({
        url: httpURL + '/sms/performance/tchduty/list',
        method: "POST",
        params: { examId: pExamId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;

        if (response.data.SvcStatus === "Success") {
            obj.lstTchDuty = response.data.lstTchDuty;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstTchDuty = [];
        callback(obj);
    });
}

function generateTchDatesheetSvc(examId, lstTchId, cb) {
    var formData = new FormData();
    var generateObj = {};
    generateObj.examId = examId;
    generateObj.lstTchId = lstTchId
    formData.append('generateObj', JSON.stringify(generateObj));

    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/performance/tchduty/generate",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console .log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}

function viewDutyByTchSvc(tchId, examId, callback) {
    var formData = new FormData();
    formData.append('examId', examId);
    formData.append('tchId', tchId);

    axios(
        {
            url: httpURL + "/sms/performance/tchduty/view",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }
    ).then((response) => {

        callback(response);

    }
    ).catch((error) => {
        console.error(error)
    });
}

export { getLstTchDutySvc, viewDutyByTchSvc, generateTchDatesheetSvc }