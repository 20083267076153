import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['payroll'].url + ":" + lstModules['payroll'].port;

function getEmployeeListSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/employee/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Test List Catch error")
    });
}
function getEmployeeDtlsSvc(pStaffId, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/employee/dtls",
            method: 'POST',
            params: { staffId: pStaffId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Test List Catch error")
    });
}
function saveEmployeeDtls(pEmpDtlsObj, callBack) {
    var formData = new FormData();
    formData.append("empDtls", JSON.stringify(pEmpDtlsObj));
    axios({
        url: httpURL + "/sms/payroll/employee/dtls/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        console.error(error) 
    });
}
function getBankDtlsSvc(pStaffId, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/employee/bank_dtls",
            method: 'POST',
            params: { staffId: pStaffId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Test List Catch error")
    });
}
function saveBankDtlsSvc(pBankDtls, callBack) {
    var formData = new FormData();
    formData.append("bankDtls", JSON.stringify(pBankDtls));
    axios({
        url: httpURL + "/sms/payroll/employee/bank/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => {
        console.error(error) 
    });
}
function getAllowanceListSvc(pStaffId, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/employee/allowance/list",
            //url: "",
            method: 'POST',
            params: { staffId: pStaffId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Test List Catch error")
    });
}
function saveAllowanceSvc(pempAllowance, callBack) {
    var formData = new FormData();
    formData.append("empAllowance", JSON.stringify(pempAllowance));
    axios({
        url: httpURL + "/sms/payroll/employee/allowance/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        console.error(error) 
    });
}
function getDeductionListsvc(pStaffId, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/employee/deduction/list",
            //url: "",
            method: 'POST',
            params: { staffId: pStaffId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
         // console .log("Test List Catch error")
    });
}
function saveDeductionSvc(pempDeduction, callBack) {
    var formData = new FormData();
    formData.append("empDeduction", JSON.stringify(pempDeduction));
    axios({
        url: httpURL + "/sms/payroll/employee/deduction/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => {
        console.error(error) 
    });
}
function getGradeListSvc(callBack){
    axios(
        {
            url: httpURL + "/sms/grade/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getStaffTypeList(callBack) {
    axios(
        {
            url: httpURL + "/sms/staff_type/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
export {getGradeListSvc,getStaffTypeList, getEmployeeListSvc, getEmployeeDtlsSvc, saveEmployeeDtls, getBankDtlsSvc, saveBankDtlsSvc, getAllowanceListSvc, getDeductionListsvc, saveAllowanceSvc, saveDeductionSvc };