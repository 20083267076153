import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import {
  saveOneTimeHeadSvc,
  getClassListSvc,
  getLstClsSvc
} from './oneTimeHeadSvc'
import { toast, ToastContainer } from 'react-toastify'

const OneTimeHeadSlider = props => {
  const [loader, setLoader] = useState('none')
  const [oneTimeHeadId, setOneTimeHeadId] = useState(props.oneTimeHeadId)
  const [oneTimeHead, setOneTimeHead] = useState('')
  const [oneTimeHeadObj, setOneTimeHeadObj] = useState(props.oneTimeHeadObj)
  const [amt, setAmt] = useState([])
  const [lstSelClsId, setLstSelClsId] = useState([])
  const [lstCls, setLstCls] = useState([])
  const [selClsId, setSelClsId] = useState(0)

  useEffect(() => {
    // console .log(props)
    setOneTimeHeadId(props.oneTimeHeadId)
    setOneTimeHeadObj(props.oneTimeHeadObj)
    setOneTimeHead(props.oneTimeHeadObj.head)
    setAmt(props.oneTimeHeadObj.amount)
    setLstCls(props.oneTimeHeadObj.lstClass)
    var tmpLstCls = props.oneTimeHeadObj.lstClass
    if (tmpLstCls) {
      var lstTmpSelClsId = []
      tmpLstCls.forEach(function (obj) {
        if (obj.selected) lstTmpSelClsId.push(obj.classId)
      })
      setLstSelClsId(lstTmpSelClsId)
    }
    if (props.oneTimeHeadId == 0) {
      getLstClass()
    }
  }, [props.oneTimeHeadId, props.oneTimeHeadObj])

  function getLstClass () {
    // setLoader('block')
    getClassListSvc(cbClsResponseChange)
  }
  function cbClsResponseChange (data) {
    if (data.status === 'Success') {
      setLstCls(data.lstClass)
    } else {
      toast.error(data.message)
    }
  }

  function handleOneTimeHeadChange (param) {
    setOneTimeHead(param)
  }

  async function handleLstClsIdChange (pId, pChecked) {
    var tmpLstCls = lstSelClsId
    pId = parseInt(pId)
    pChecked = !!pChecked
    // console .log( 'checked = ' +  pChecked +  '::selClsId = ' +  pId +  '::lstSelClsId = ' +  lstSelClsId  )
    if (pChecked && !lstSelClsId.includes(pId)) {
      // console .log('push case')
      tmpLstCls.push(parseInt(pId))
      setLstSelClsId(tmpLstCls)
    } else {
      // console .log('remove case')
      tmpLstCls.splice(tmpLstCls.indexOf(pId), 1)
      setLstSelClsId(tmpLstCls)
    }
    // console .log(tmpLstCls)
    setLstSelClsId(tmpLstCls)
  }
  function saveOneTimeHead () {
    let pOneTimeHeadObj = {
      headId: props.oneTimeHeadId,
      head: oneTimeHead,
      amount: amt,
      lstClass: lstSelClsId
    }
    setLoader('block')
    saveOneTimeHeadSvc(pOneTimeHeadObj, cbSaveOneTimeHeadResponse)
  }
  function cbSaveOneTimeHeadResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      if (props.oneTimeHeadId == 0) {
        props.setOneTimeHeadId(data.headId)
      }
      props.getOneTimeHeadList()
      props.closeSlider()
    } else {
      toast.error(data.SvcMsg)
    }
  }
  const colgrp = (
    <colgroup>
      <col width='10%'></col>
      <col width='20%'></col>
    </colgroup>
  )

  return (
    <>
      <form
        className='form form-horizontal'
        style={{
          pointerEvents: loader === 'none' ? 'auto' : 'none',
          height: 'auto',
          width: '90%',
          margin: '10px auto',
          background: '#fff',
          border: '1px solid #ccc',
          borderRadius: 10
        }}
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          onClick={props.closeSlider}
          style={{ right: '50%' }}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <div className='form-group' style={{ width: '100%' }}>
          <label className='control-label col-sm-3' style={{ width: '30%' }}>
            Fee Type
          </label>

          <div className='form-group col-sm-5'>
            <div className='col-sm-12 padding-remove'>
              <input
                type='text'
                defaultValue={oneTimeHead}
                className='form-control'
                onChange={e => handleOneTimeHeadChange(e.target.value)}
                maxLength='100'
              />
            </div>
          </div>
        </div>
        <div className='form-group' style={{ width: '100%' }}>
          <label className='control-label col-sm-3' style={{ width: '30%' }}>
            Amount
          </label>

          <div className='form-group col-sm-6'>
            <div className='col-sm-3 padding-remove'>
              <input
                type='text'
                defaultValue={amt}
                className='form-control text-right'
                onChange={e => setAmt(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='col-sm-12' style={{ width: '35%' }}>
          <table className='tableLayout1'>
            {colgrp}
            <thead>
              <tr>
                <th>Select</th>
                <th>Class</th>
              </tr>
            </thead>
          </table>
          <div className='tablebodyWrapper'>
            <table className='tableLayout'>
              {colgrp}
              <tbody>
                {lstCls &&
                  lstCls.length > 0 &&
                  lstCls.map((obj, idx) => {
                    return (
                      <tr
                        key={idx}
                        onClick={e => setSelClsId(obj.clsId)}
                        className={obj.clsId === selClsId ? 'selected' : ''}
                      >
                        <td className='no-display'>{obj.clsId}</td>
                        <td className='text-left'>
                          <input
                            id={obj.clsId}
                            type='checkbox'
                            defaultChecked={obj.selected}
                            onChange={e =>
                              handleLstClsIdChange(
                                e.target.id,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td className='text-left'>{obj.cls}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='row' style={{ width: '100%', paddingBottom: '1px' }}>
          <div className='col-md-offset-7 col-md-1 padding-remove'>
            <button
              type='button'
              className='btn btn-info pull-right'
              onClick={e => saveOneTimeHead()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default OneTimeHeadSlider
