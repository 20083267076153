import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
var queryString = require('querystring');
var screenId = 37;
const SEND_EMAIL = true;
const tokenid=localStorage.getItem('tokenid')
export default class paymentForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paymentDate: null,
            refNo: "",
            paidAmount: "",
            modeId: 0,
            paidById: 0,
            paidByNm: "",
            paidByPhone: "",
            remarks: "",
            flgMail: false,
            paymentId:0,

        }
    }

    componentDidUpdate = (prevProps)=> {
        // Typical usage (don't forget to compare props):
        if (this.props.paymentId !== prevProps.paymentId) {
          this.setState({paymentId:this.props.paymentId});
          // console .log('componentdidupdate earl::'+this.props.paymentId)
        // console .log('componentdidupdate prev::'+this.prevProps.paymentId)
        }
      }
    handleChangeDate = (date) => {
        this.setState({ paymentDate: date, });
    }
    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    sleep=(ms)=> {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      
      
    savePrint=async (e)=>{
        
        var check=await this.save(e)
        // console .log('check+'+check)
        if(check == false){
            return false;
        }
        this.setState({ loader: 'block', opacity: 1 });
        this.sleep(2000).then(()=>{
        // this.setState({paymentId:localStorage.getItem('paymentId')})
        // console .log('paymentId::'+localStorage.getItem('paymentId'))
        axios.post(httpURL+'/sms/payment/slip',
        queryString.stringify({paymentId:this.state.paymentId, screenId }), {
        headers: { 'tokenid':tokenid },
        withCredentials: true,
        responseType: 'arraybuffer',
    }).then((response) => {
            this.props.pdfObject(response)
            this.setState({ loader: 'none', opacity: 1 });
    }).catch((e) => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error("Service failed " + e);
    });

        })

    }

    save = async (e) => {
        this.setState({ flgMail: await e.target.name === "btn_save_email" })
        let { refNo,
            modeId,
            paidByNm,
            paidByPhone,
            remarks,
            flgMail } = this.state;
        let paidById = $('input[name=paidBy]:checked').val();
        let paidAmount = parseInt($('.totalRupees').text());
        let paymentDt = $('#paymentDate').val();
        let paymentType = 10;

        if (parseInt(paidAmount) === 0) {
            toast.warning('Amount Should not be Zero');
            return false;
        }
        if (modeId === 0) {
            toast.warning('Please Select Mode');
            $('#modeId').focus();
            return false;
        }
        if (paymentDt === "") {
            toast.warning('Please Enter Date');
            $('#paymentDate').focus();
            return false;
        }
        if (refNo === 0) {
            toast.warning('Please Enter Reference Number');
            $('#refNo').focus();
            return false;
        }
        if (paidById === undefined) {
            toast.warning('Please Select Paid By');
            return false;
        }
        let obj = {
            refNo,
            paidAmount,
            paymentDt,
            modeId,
            paidByNm,
            paidByPhone,
            remarks,
            paidById,
            paymentType,
            flgMail
        }
        
        await this.props.saveObject(obj);
    }

    render() {
        return (
            <>
                <form id="addForm" className="form-horizontal formWrapper clearfix">
                    <div className="col-sm-12 padding-remove">
                        <h6 className="col-sm-2 text-center paymentTextHeader">Payment</h6>
                        <div className="col-sm-10 totalPaymentSection">
                            <div>Total</div>
                            <div className="totalRupees">{this.props.total}</div>
                            <div className="totalRupeesInWord">{this.props.totalInWord}<span style={{ marginLeft: 5 }}>Only</span></div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Mode<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <select autoComplete="off" name="modeId" id="modeId"
                                    type="text" className="form-control "
                                    value={this.state.modeId}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="0" >Mode</option>
                                    {this.props.lstModes.map((mode) => (
                                        <option key={mode.modeId} value={mode.modeId} >{mode.mode}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <DatePicker id="paymentDate"
                                    className='form-control'
                                    placeholderText="DD-MM-YYYY"
                                    selected={this.state.paymentDate}
                                    onChange={this.handleChangeDate}
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    maxDate={new Date()}
                                    autoComplete='off'
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Ref. No.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <input autoComplete="off" name="refNo" id="refNo"
                                    type="text" className="form-control "
                                    value={this.state.refNo}
                                    onChange={this.handleInputChange}
                                    placeholder="Enter Ref. No." />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Remarks</label>
                            <div className="col-sm-7 padding-remove">
                                <textarea autoComplete="off" name="remarks"
                                    value={this.state.remarks}
                                    onChange={this.handleInputChange}
                                    id="remarks" type="text" className="form-control "
                                    placeholder="Enter Remarks" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5 ">
                        <h6 className="paidByHeader">Paid By</h6>
                        <div className="col-sm-6 padding-remove">
                            {/* <div className="col-sm-12 radioButtonsWrap">
                                <input type="radio" id="paidByStudent" name="paidBy" value="10" />
                                <label htmlFor="Student">Student</label>
                            </div> */}
                            <div className="col-sm-12 radioButtonsWrap">
                                <input type="radio" id="paidByFather" name="paidBy" value="20" />
                                <label htmlFor="Father">Father</label>
                            </div>
                            <div className="col-sm-12 radioButtonsWrap">
                                <input type="radio" id="paidByMother" name="paidBy" value="30" />
                                <label htmlFor="Mother">Mother</label>
                            </div>
                            <div className="col-sm-12 radioButtonsWrap">
                                <input type="radio" id="paidByGuardian" name="paidBy" value="40" />
                                <label htmlFor="Guardian">Guardian</label>
                            </div>
                            {/* <div className="col-sm-12 radioButtonsWrap">
                                <input type="radio" id="paidByDonors" name="paidBy" value="50" />
                                <label htmlFor="Donors">Donors</label>
                            </div> */}
                            {/* <div className="col-sm-12 radioButtonsWrap">
                                <input type="radio" id="paidByOthers" name="paidBy" value="60" />
                                <label htmlFor="Others">Others</label>
                            </div> */}
                        </div>
                        <div className="col-sm-5 ">
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <input type="text" id="paidByNm"
                                    name="paidByNm" placeholder="Name"
                                    className="form-control"
                                    value={this.state.paidByNm}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div>
                                <input type="text" id="paidByPhone"
                                    name="paidByPhone" placeholder="Phone Number"
                                    className="form-control"
                                    value={this.state.paidByPhone}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 padding-remove paymentButtons">
                        <div className="form-group" >
                            <div className="col-sm-12 text-center" >
                                <button type="button" style={{ marginTop: 20 }}
                                    name="btn_save"
                                    title="Save"
                                    onClick={this.save}
                                    className="btn btn-info">Save</button>
                            </div>
                        </div>
                        <div className="form-group" >
                            <div className="col-sm-12 text-center" >
                                <button type="button" style={{ marginTop: 20 }} onClick={this.savePrint} title="Save & Print" className="btn btn-info">Save & Print</button>
                            </div>
                        </div>
                        <div className="form-group" >
                            <div className="col-sm-12 text-center" >
                                <button type="button" style={{ marginTop: 20 }} title="Save & Email" className="btn btn-info"
                                    name="btn_save_email" onClick={this.save}>Save & Email</button>
                            </div>
                        </div>
                    </div>
                </form >
            </>

        )
    }
}
