import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text } from '@react-pdf/renderer';

import { pageStyle, syllabusSectionStyle, textStyle } from "./syllabusPdfStyle.js";
import HeaderSection from './headerSection.js';

const ExamSyllabusPDF = (props) => {
    const [headerSection, setHeaderSection] = useState(props.headerSection);
    const [lstExam, setLstExam] = useState(props.lstExam);
    const [className, setClassName] = useState(props.className);

    useEffect(() => {
        // console .log("ExamSyllabusPDF called, headerSection", headerSection, ", lstExam", lstExam, ", className", className);
    }, [headerSection, lstExam]);

    let examIdx = 0;
    const pages = [];
    let subjectIdx = 0;
    let chapterIdx = 0;
    let rowIdx = 0;
    const subjectRows = 3;
    const chapterRows = 1;
    const MAX_ROWS = 60;
    const MAX_COLUMNS = 2;

    const addPage = (examName, syllabusColumnLeft, syllabusColumnRight) => {
        pages.push(
            <Page key={pages.length} size="A4" style={pageStyle.page}>
                <View style={pageStyle.page_border}>
                    <View style={pageStyle.header_section}>
                        <HeaderSection
                            headerSection={headerSection}
                            examName={examName}
                            className={className}
                        />
                    </View>
                    <View style={pageStyle.syllabus_section}>
                        <View style={syllabusSectionStyle.syllabusContainer}>
                            <View style={syllabusSectionStyle.syllabusColLeft}>
                                {syllabusColumnLeft}
                            </View>
                            <View style={syllabusSectionStyle.syllabusColRight}>
                                {syllabusColumnRight}
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        );
    }

    while (examIdx < lstExam.length) {
        const examName = lstExam[examIdx].exam;
        let syllabusColumnLeft = [];
        let syllabusColumnRight = [];
        rowIdx = 0;
        let columnIdx = 0;

        const exam = lstExam[examIdx];

        while (subjectIdx < exam.lstSubject.length) {
            const subject = exam.lstSubject[subjectIdx];
            const subjectRow = (
                <View style={syllabusSectionStyle.subjectNameRow} key={`subject-${examIdx}-${subjectIdx}`}>
                    <Text style={textStyle.subjectNameText}>
                        {subject.subject}
                    </Text>
                </View>
            );

            if (columnIdx === 0 && rowIdx < MAX_ROWS) {
                syllabusColumnLeft.push(subjectRow);
            } else if (columnIdx === 1 && rowIdx < MAX_ROWS) {
                syllabusColumnRight.push(subjectRow);
            }

            rowIdx += subjectRows;
            if (rowIdx >= MAX_ROWS) {
                rowIdx = 0;
                columnIdx++;
                if (columnIdx === MAX_COLUMNS) {
                    addPage(examName, syllabusColumnLeft, syllabusColumnRight);
                    syllabusColumnLeft = [];
                    syllabusColumnRight = [];
                    columnIdx = 0;
                }
            }

            chapterIdx = 0;
            while (chapterIdx < subject.lstChapter.length) {
                const chapter = subject.lstChapter[chapterIdx];
                const chapterRow = (
                    <View style={syllabusSectionStyle.chapterRow} key={`chapter-${examIdx}-${subjectIdx}-${chapterIdx}`}>
                        <Text style={textStyle.chapterNameText}>
                            {chapter.book != "" && chapter.book != "-" ? `${chapter.book} - ${chapter.chapter}` : chapter.chapter}
                        </Text>
                    </View>
                );

                if (columnIdx === 0 && rowIdx < MAX_ROWS) {
                    syllabusColumnLeft.push(chapterRow);
                } else if (columnIdx === 1 && rowIdx < MAX_ROWS) {
                    syllabusColumnRight.push(chapterRow);
                }

                rowIdx += chapterRows;
                if (rowIdx >= MAX_ROWS) {
                    rowIdx = 0;
                    columnIdx++;
                    if (columnIdx === MAX_COLUMNS) {
                        addPage(examName, syllabusColumnLeft, syllabusColumnRight);
                        syllabusColumnLeft = [];
                        syllabusColumnRight = [];
                        columnIdx = 0;
                    }
                }
                chapterIdx++;
            }
            const chapterRow = (
                <View style={syllabusSectionStyle.chapterRow} key={`chapter-${examIdx}-${subjectIdx}-${chapterIdx}`}>
                    <Text style={textStyle.chapterNameText}>
                    </Text>
                </View>
            );

            if (columnIdx === 0 && rowIdx < MAX_ROWS) {
                syllabusColumnLeft.push(chapterRow);
            } else if (columnIdx === 1 && rowIdx < MAX_ROWS) {
                syllabusColumnRight.push(chapterRow);
            }

            rowIdx += chapterRows;
            subjectIdx++;
        }

        if (syllabusColumnLeft.length > 0 || syllabusColumnRight.length > 0) {
            addPage(examName, syllabusColumnLeft, syllabusColumnRight);
        }

        subjectIdx = 0;
        examIdx++;
    }

    return (
        <div className='col-sm-12 padding-remove' style={{ marginTop: "1vh" }}>
            <PDFViewer style={{ width: "100%", height: "98vh", overflowY: "scroll" }}>
                <Document>{pages}</Document>
            </PDFViewer>
        </div>
    );
}

export default ExamSyllabusPDF;
