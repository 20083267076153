import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import { saveCategorySvc } from "./studentCategorySvc";



const StudentCategory = (props) => {
    const { setLoader, setOpacity, studentId, categoryDtls, flgDisabled } = props;

    const [flgScholar, setFlgScholar] = useState(false);
    const [flgSibling, setFlgSibling] = useState(false);
    const [flgMeritCumMeans, setFlgMeritCumMeans] = useState(false);
    const [flgScst, setFlgScst] = useState(false);
    const [flgBackward, setFlgBackward] = useState(false);

    useEffect(() => {
        // console .log("StudentCategory called, studentId");
        setFlgScholar(categoryDtls.scholar)
        setFlgSibling(categoryDtls.sibling)
        setFlgMeritCumMeans(categoryDtls.meritCumMeans)
        setFlgScst(categoryDtls.scst)
        setFlgBackward(categoryDtls.backward)
    }, [studentId, flgDisabled, categoryDtls]);

    const savecategory = (e) => {
        e.stopPropagation();
        // console .log("saveCategory called");
        const category = {
            "studentId": studentId,
            "scholar": flgScholar,
            "sibling": flgSibling,
            "scst": flgScst,
            "backward": flgBackward,
            "meritMeans": flgMeritCumMeans
        }
        setLoader("block");
        setOpacity(0.5);
        saveCategorySvc(category, saveCategoryResponse);
    }
    
    const saveCategoryResponse = (data) => {
        // console .log("saveCategoryResponse called");
        const obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }



    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };


    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <form id="categoryForm" className="col-sm-12 form-horizontal">
                <div className="col-sm-12 section1">
                    <div className="">
                        <label className="control-label col-sm-9">
                            Category
                        </label>
                    </div>
                </div>
                <div className="col-sm-12 shadowShow  padding-remove">
                    <div
                        className="col-sm-12 form-group categoryWrap"
                        style={{ paddingLeft: 70 + "px" }}
                    >
                        <div className="col-sm-2 checkbox-inline">
                            <input
                                type="checkbox"
                                checked={flgScholar}
                                onChange={(e) => setFlgScholar(e.target.checked)}
                                className="form-check-input"
                                id="scholar"
                                disabled={flgDisabled}
                            />
                            <label
                                htmlFor="scholar"
                                style={{ paddingLeft: 5 + "px" }}
                            >
                                {" "}
                                Scholar{" "}
                            </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                            <input
                                type="checkbox"
                                checked={flgSibling}
                                onChange={(e) => setFlgSibling(e.target.checked)}
                                className="form-check-input"
                                id="sibling"
                                disabled={flgDisabled}
                            />
                            <label
                                htmlFor="sibling"
                                style={{ paddingLeft: 5 + "px" }}
                            >
                                {" "}
                                Sibling{" "}
                            </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                            <input
                                type="checkbox"
                                checked={flgMeritCumMeans}
                                onChange={(e) => setFlgMeritCumMeans(e.target.checked)}
                                className="form-check-input"
                                id="meritMeans"
                                disabled={flgDisabled}
                            />
                            <label
                                htmlFor="meritMeans"
                                style={{ paddingLeft: 5 + "px" }}
                            >
                                {" "}
                                Merit-cum-Means{" "}
                            </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                            <input
                                type="checkbox"
                                checked={flgScst}
                                onChange={(e) => setFlgScst(e.target.checked)}
                                className="form-check-input"
                                id="scSt"
                                disabled={flgDisabled}
                            />
                            <label
                                htmlFor="scSt"
                                style={{ paddingLeft: 5 + "px" }}
                            >
                                {" "}
                                SC/ST{" "}
                            </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                            <input
                                type="checkbox"
                                checked={flgBackward}
                                onChange={(e) => setFlgBackward(e.target.checked)}
                                className="form-check-input"
                                id="backward"
                                disabled={flgDisabled}
                            />
                            <label
                                htmlFor="backward"
                                style={{ paddingLeft: 5 + "px" }}
                            >
                                Backward
                            </label>
                        </div>
                        <div className="col-sm-1 text-right">
                            <button
                                type="button"
                                onClick={(e) => savecategory(e)}
                                className="btn btn-info"
                                disabled={flgDisabled}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default StudentCategory;