import React, { useState, useEffect } from 'react'
import LblTxt from './LblTxt'
import axios from 'axios'
import DropDown from './DropDown'
import AnchorLbl from './AnchorLbl'
import { toast, ToastContainer } from 'react-toastify'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

const RegistrationParents = ({ admissionId }) => {
  const [father, setFather] = useState({})
  const [mother, setMother] = useState({})
  const [guardian, setGuardian] = useState({})
  const [lstState, setLstState] = useState([])
  const [lstFatherCity, setLstFatherCity] = useState([])
  const [lstMotherCity, setLstMotherCity] = useState([])
  const [lstGuardianCity, setLstGuardianCity] = useState([])
  const [loader, setLoader] = useState('none')
  function cbParentDtlsResponse (obj) {
    if (obj.SvcStatus === 'Success') {
      getFatherCityListSvc(cbFatherCityListResponse, obj.parent.father.stateId)
      getMotherCityListSvc(cbMotherCityListResponse, obj.parent.mother.stateId)
      getGuardianCityListSvc(
        cbGuardianCityListResponse,
        obj.parent.guardian.stateId
      )
      setFather(obj.parent.father)
      setMother(obj.parent.mother)
      setGuardian(obj.parent.guardian)
    } else {
      toast.error(obj.SvcMsg)
    }
    setLoader('none')
  }

  function getParentDtlsSvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/parent/dtls',
      method: 'POST',
      params: { admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  useEffect(() => {
    var promise = new Promise((resolve, reject) => {
      setLoader('block')
      getStateListSvc(cbStateListResponse)
    })
  }, [])
  function getStateListSvc (callBack) {
    axios({
      url: httpURLCommon + '/sms/mst/state/list',
      method: 'POST',
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }

  function getFatherCityListSvc (callBack, pstateId) {
    axios({
      url: httpURLCommon + '/sms/mst/city/list',
      method: 'POST',
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  function getMotherCityListSvc (callBack, pstateId) {
    axios({
      url: httpURLCommon + '/sms/mst/city/list',
      method: 'POST',
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  function getGuardianCityListSvc (callBack, pstateId) {
    axios({
      url: httpURLCommon + '/sms/mst/city/list',
      method: 'POST',
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  function cbStateListResponse (pLstState) {
    if (pLstState.SvcStatus === 'Success') {
      setLstState(pLstState.lstState)
      getParentDtlsSvc(admissionId, cbParentDtlsResponse)
    } else {
      toast.error(pLstState.SvcMsg)
    }
    setLoader('none')
  }
  function handleFatherStateChange (pStateId) {
    setFather({ ...father, stateId: pStateId })
    getFatherCityListSvc(cbFatherCityListResponse, pStateId)
  }
  function handleFatherCityChange (pCityId, pLocalityId) {
    setFather({ ...father, cityId: pCityId })
    // getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId)
  }
  function handleMotherStateChange (pStateId) {
    setMother({ ...mother, stateId: pStateId })
    getMotherCityListSvc(cbMotherCityListResponse, pStateId)
  }
  function handleMotherCityChange (pCityId, pLocalityId) {
    setMother({ ...mother, cityId: pCityId })
    // getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId)
  }
  function handleGuardianStateChange (pStateId) {
    setGuardian({ ...guardian, stateId: pStateId })
    getGuardianCityListSvc(cbGuardianCityListResponse, pStateId)
  }
  function handleGuardianCityChange (pCityId, pLocalityId) {
    setGuardian({ ...guardian, cityId: pCityId })
    // getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId)
  }

  function cbFatherCityListResponse (pLstCity) {
    if (pLstCity.SvcStatus === 'Success') {
      setLstFatherCity(pLstCity.lstCity)
    } else {
      toast.error(pLstCity.SvcMsg)
    }
    setLoader('none')
  }
  function cbMotherCityListResponse (pLstCity) {
    if (pLstCity.SvcStatus === 'Success') {
      setLstMotherCity(pLstCity.lstCity)
    } else {
      toast.error(pLstCity.SvcMsg)
    }
    setLoader('none')
  }
  function cbGuardianCityListResponse (pLstCity) {
    if (pLstCity.SvcStatus === 'Success') {
      setLstGuardianCity(pLstCity.lstCity)
    } else {
      toast.error(pLstCity.SvcMsg)
    }
    setLoader('none')
  }
  function saveParents () {
    var parent = {
      father: father,
      mother: mother,
      guardian: guardian
    }
    setLoader('block')
    saveParentsSvc(parent, cbParentSaveResponse)
  }
  function cbParentSaveResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function saveParentsSvc (parent, callBack) {
    parent = JSON.stringify(parent)
    axios({
      url: httpURL + '/sms/admission/parent/save',
      method: 'POST',
      params: { admissionId: admissionId, parent: parent },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console .log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console .log('error')
      })
  }
  return (
    <>
      <div
        className='col-sm-12'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '1%'
        }}
      >
        <div
          className='col-sm-12'
          style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: 'fixed',
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: '50%',
              top: '50%',
              display: loader
            }}
          >
            <img
              className='loderImg'
              src={require('common/images/loader.gif')}
              alt='Loader...'
            />
          </div>
          <div className='col-sm-3' style={{ background: 'transparent' }}>
            <LblTxt
              label='Name:'
              dataType='text'
              dataValue={father.name}
            ></LblTxt>
            <LblTxt
              label='Phone No:'
              dataType='text'
              dataValue={father.phoneNo}
            ></LblTxt>
            <LblTxt
              label='Address1:'
              dataType='text'
              dataValue={father.address1}
            ></LblTxt>
            <DropDown
              label='State:'
              onChangeDropDown={handleFatherStateChange}
              option1='State'
              key={'fs_' + father.stateId}
              lst={lstState}
              dataValue={father.stateId}
              keyId='stateId'
              keyVal='state'
            ></DropDown>
            <DropDown
              label='City:'
              onChangeDropDown={handleFatherCityChange}
              option1='City'
              key={'fs_' + father.stateId + '_fc_' + father.cityId}
              lst={lstFatherCity}
              dataValue={father.cityId}
              keyId='cityId'
              keyVal='city'
            ></DropDown>

            {/* <LblTxt label='State:' dataType='text' dataValue={father.stateId}></LblTxt>
        <LblTxt label='City:' dataType='text' dataValue={father.cityId}></LblTxt> */}
            <LblTxt
              label='Pin:'
              dataType='text'
              dataValue={father.pin}
            ></LblTxt>
            <LblTxt
              label='Email:'
              dataType='text'
              dataValue={father.email}
            ></LblTxt>
            <AnchorLbl
              dataValue={father.flgEmailVerify}
              anchorLabel='Reverify'
            ></AnchorLbl>
          </div>
          <div
            className='col-sm-offset-1 col-sm-3'
            style={{ background: 'transparent' }}
          >
            <LblTxt
              label='Name:'
              dataType='text'
              dataValue={mother.name}
            ></LblTxt>
            <LblTxt
              label='Phone No:'
              dataType='text'
              dataValue={mother.phoneNo}
            ></LblTxt>
            <LblTxt
              label='Address1:'
              dataType='text'
              dataValue={mother.address1}
            ></LblTxt>
            <DropDown
              label='State:'
              onChangeDropDown={handleMotherStateChange}
              option1='State'
              key={'ms_' + mother.stateId}
              lst={lstState}
              dataValue={mother.stateId}
              keyId='stateId'
              keyVal='state'
            ></DropDown>
            <DropDown
              label='City:'
              onChangeDropDown={handleMotherCityChange}
              option1='City'
              key={'ms_' + mother.stateId + '_mc_' + mother.cityId}
              lst={lstMotherCity}
              dataValue={mother.cityId}
              keyId='cityId'
              keyVal='city'
            ></DropDown>
            {/* <LblTxt label='State:' dataType='text' dataValue={mother.stateId}></LblTxt>
        <LblTxt label='City:' dataType='text' dataValue={mother.cityId}></LblTxt>
         */}
            <LblTxt
              label='Pin:'
              dataType='text'
              dataValue={mother.pin}
            ></LblTxt>
            <LblTxt
              label='Email:'
              dataType='text'
              dataValue={mother.email}
            ></LblTxt>
            <AnchorLbl
              dataValue={mother.flgEmailVerify}
              anchorLabel='Reverify'
            ></AnchorLbl>
          </div>
          <div className='col-sm-offset-1 col-sm-3'>
            <LblTxt
              label='Name:'
              dataType='text'
              dataValue={guardian.name}
            ></LblTxt>
            <LblTxt
              label='Phone No:'
              dataType='text'
              dataValue={guardian.phoneNo}
            ></LblTxt>
            <LblTxt
              label='Address1:'
              dataType='text'
              dataValue={guardian.address1}
            ></LblTxt>

            <DropDown
              label='State:'
              onChangeDropDown={handleGuardianStateChange}
              option1='State'
              key={'gs_' + guardian.stateId}
              lst={lstState}
              dataValue={guardian.stateId}
              keyId='stateId'
              keyVal='state'
            ></DropDown>
            <DropDown
              label='City:'
              onChangeDropDown={handleGuardianCityChange}
              option1='City'
              key={'gs_' + guardian.stateId + '_gc_' + guardian.cityId}
              lst={lstGuardianCity}
              dataValue={guardian.cityId}
              keyId='cityId'
              keyVal='city'
            ></DropDown>
            {/* <LblTxt label='State:' dataType='text' dataValue={guardian.stateId}></LblTxt>
        <LblTxt label='City:' dataType='text' dataValue={guardian.cityId}></LblTxt>
         */}
            <LblTxt
              label='Pin:'
              dataType='text'
              dataValue={guardian.pin}
            ></LblTxt>
            <LblTxt
              label='Email:'
              dataType='text'
              dataValue={guardian.email}
            ></LblTxt>
            <AnchorLbl
              dataValue={guardian.flgEmailVerify}
              anchorLabel='Reverify'
            ></AnchorLbl>
          </div>
        </div>
        <div className='col-sm-11'>
          <button
            type='button'
            className='btn-info btn pull-right'
            onClick={e => saveParents()}
          >
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default RegistrationParents
