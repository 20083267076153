import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL =  lstModules['transport'].url + ':' + lstModules['transport'].port + '/sms/tpt/payment';
const tokenid = localStorage.getItem('tokenid');


export const getLstStudentSvc = (cbLstStudent) => {
    // console .log("getLstStudentSvc called, url", httpURL + '/list/student');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/list/student',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in lstExamSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstStudent = response.data.lstStudent;
        }
        cbLstStudent(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstStudent(obj);
    })
}


export const saveMonthPaymentSvc = (monthPaymentObj, cbMonthPayment) => {
    // console .log("getLstStudentSvc called, url", httpURL + '/save/month_payment , monthPaymentObj', monthPaymentObj);
    const formData = new FormData();
    formData.append("monthPaymentObj", JSON.stringify(monthPaymentObj));
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/save/month_payment',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console .log("response in lstExamSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbMonthPayment(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbMonthPayment(obj);
    })
}
