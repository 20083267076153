import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import 'common/css/master.css'
import '../css/transport.css'
import lstModules from 'common/json/lstModules.json'
import Header from 'common/js/header/header.js';

var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var initLstRoute = [{
    "routeId": 1,
    "vehicleType": "Mini Bus",
    "lstRouteStop": [{ stopId: 1, stopNo: 1, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "500" }
    ]
}, {
    "routeId": 2,
    "vehicleType": "Rickshaw",
    "lstRouteStop": [{ stopId: 1, stopNo: 1, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "500" }
        , { stopId: 2, stopNo: 2, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "1000" }
    ]
}, {
    "routeId": 3,
    "vehicleType": "Van",
    "lstRouteStop": [{ stopId: 1, stopNo: 1, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "500" }
        , { stopId: 2, stopNo: 2, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "1000" }
        , { stopId: 3, stopNo: 3, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-14", studentsCount: "800" }
    ]
}, {
    "routeId": 4,
    "vehicleType": "Rickshaw",
    "lstRouteStop": [{ stopId: 2, stopNo: 2, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "1000" }
    ]
}, {
    "routeId": 5,
    "vehicleType": "Medium Bus",
    "lstRouteStop": [{ stopId: 2, stopNo: 2, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-10", studentsCount: "1000" }
        , { stopId: 3, stopNo: 3, range: "C3KM", schoolDistance: 4, city: "Agra", locality: "Bundla", address: "C-14", studentsCount: "800" }
    ]
}];
const RouteStopReport = () => {
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="20%"></col>
        <col width="20%"></col>
        <col width="20%"></col>
        <col width="10%"></col>

    </colgroup>
    const [loader, setLoader] = useState("none")
    const [lstRoute, setLstRoute] = useState([])
    const [disabled, setDisabled] = useState(true)

    const [btnText, setBtnText] = useState("Expand All")
    useEffect(() => {
        // setLstRoute(initLstRoute)
        getReport();
    }, [])

    const getReport = () => {
        setLoader('block')

        axios(
            {
                url: httpURL + '/sms/tpt/report/route/stops',
                method: "POST",
                params: {},
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === "Success") {
                setLstRoute(response.data.lstRoute);
                setLoader('none')
                if (response.data.lstRoute.length !== 0) {
                    setDisabled(false)
                }

            }
            else {
                toast.error(response.data.SvcMsg)
                if (response.data.SvcMsg == 'You are not logged in') {
                    window.location.href = 'localhost:3000';
                    return false;
                }
                setLoader('none')
            }
        }).catch((e) => {
            toast.error("Service failed " + e);
        });
    }
    function toggleRows(e) {
        var rowId = $(e.target).closest('tr').attr('id').split('-')[1];
        $('.stop-' + rowId).toggle();
    }

    const expandAll = () => {
        if (btnText == 'Expand All') {
            $(".toggleRow").show();
            setBtnText('Collapse All');
        } else {
            $(".toggleRow").hide();
            setBtnText('Expand All');
        }
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />


            <div className="container-fluid">
                <div className="col-sm-12 col-centered">
                    <div className="row col-sm-8 col-centered">

                        <div className="pull-right">
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-warning xls-button"
                                table="routeStopTable"
                                filename="route-stop-report"
                                sheet="Route-Stop-Report"
                                buttonText="Export"
                            />
                        </div>
                    </div>
                    <div className="row col-sm-8 col-centered">
                        <button type="button" onClick={() => expandAll()} disabled={disabled} className="btn btn-warning btn-xs pull-left">{btnText}</button>

                        <table id="tableHeadReport" className="tableLayout1" >
                            {colgrp}
                            <thead>
                                <tr>
                                    <th style={{ display: 'none' }}>Stop Id</th>
                                    <th>Stop</th>
                                    <th>Range</th>
                                    <th>School Distance</th>
                                    <th>City</th>
                                    <th>Locality</th>
                                    <th>Address</th>
                                    <th>Students Count</th>
                                </tr>
                            </thead>
                        </table>
                        <div className="tablebodyWrapper">
                            <table id="routeStopTable" className="tableLayout">
                                {colgrp}
                                <tbody id="routeStopTableBody">
                                    <tr className="table-head" >
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>Stop</td>
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>Range</td>
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>School Distance</td>
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>City</td>
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>Locality</td>
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>Address</td>
                                        <td width="100" position="absolute" align="center" style={{ display: 'none', background: 'rgb(74 134 232)', color: '#fff' }}>Students Count</td>
                                    </tr>
                                    {lstRoute.length !== 0 && lstRoute.map((objRoute, idx1) => {
                                        var loopLstRouteStop = objRoute.lstRouteStop;
                                        var loopRouteId = objRoute.routeId;
                                        return <><tr onClick={(e) => toggleRows(e)} style={{ backgroundColor: 'rgb(230, 227, 224)', width: 180, display:'table' }} key={idx1} id={"route-" + objRoute.routeId} className="row-head">
                                            <div className="text-left" width="100" position="absolute" align="left" style={{ marginLeft: 4 }}>{"Route No. " + objRoute.routeNo + " - " + objRoute.vehicleType} </div>

                                        </tr>
                                            <RouteStop lstRouteStop={loopLstRouteStop} routeId={loopRouteId} />
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export const RouteStop = (props) => {
    return (
        <>
            {props.lstRouteStop.map((objStop, idx2) => {
                return <tr key={idx2} className={"stop-" + props.routeId + " toggleRow"} style={{ backgroundColor: 'rgb(251, 251, 251)', display: 'none' }}>
                    <td className="text-left" width="100" position="absolute" align="center">{objStop.stopDesc}</td>
                    <td className="text-left" width="100" position="absolute" >{objStop.stopRange}</td>
                    <td className="text-center" width="100" position="absolute" align="center">{objStop.schoolDist}</td>
                    
                    <td className="text-center" width="100" position="absolute" >{objStop.city}</td>
                    <td className="text-center" width="100" position="absolute" >{objStop.locality}</td>
                    <td className="text-center" width="100" position="absolute" >{objStop.address}</td>
                    <td className="text-center expandedRowTd" width="100" position="absolute" align="left" >{objStop.studentsCount}</td>
                </tr>
            })
            }
        </>
    )
}

export default RouteStopReport