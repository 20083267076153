import moment from 'moment';
import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

export default function FeeForm(props) {
    const [feeDtls, setFeeDtls] = useState(props.feeDtls);
    const [lstPaymentMode, setLstPaymentMode] = useState(props.lstPaymentMode);
    
    useEffect(() => {
        setLstPaymentMode(props.lstPaymentMode);
        setFeeDtls(props.feeDtls); 
    })
    
    const handleChange = (e) => {
        e.preventDefault();
        // console .log("nm="+e.target.name);
        setFeeDtls({...feeDtls,[e.target.name]:e.target.value});
    }

    const handleDateChange=(date)=>{
        // console .log(feeDtls)
        const dt = date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear()
        setFeeDtls({...feeDtls,feesPaidOn: dt});    
        // console .log({...feeDtls,feesPaidOn: dt});
    }

    function addFee(){
        let regFees = {};
        
        if (feeDtls.fees === "") {
            // toast.warning("Please Enter Fees");
            document.getElementById('fees').focus()
            return false
        }
        if (feeDtls.feesPaidOn === "") {
            // toast.warning("Please Enter Fee Paid Date");
            document.getElementById('feesPaidOn').focus()
            return false
        }
        if (feeDtls.referenceNo === "") {
            // toast.warning("Please Enter Reference Number");
            document.getElementById('referenceNo').focus()
            return false
        }

        regFees = {
            registrationFeesId:0,
            studentId:feeDtls.studentId,
            classAppliedId:feeDtls.classAppliedId,
            fees:feeDtls.fees,
            paymentModeId:feeDtls.paymentModeId,
            feesPaidOn:feeDtls.feesPaidOn,
            referenceNo:feeDtls.referenceNo,
        }

        props.saveFees(regFees)
    }

    function getDateForCalendar(strDt){
        if ( strDt === undefined )
            return null;
        var parts=strDt.split('-')
        var d = parts[0];
        var m = parts[1]-1;
        var y = parts[2];
       // console .log('getDateForCalendar='+ strDt);
        return new Date(y,m,d);
    }

    return (
    <div className="col-sm-8 col-xs-8 col-centered padding-remove feeFormWrap" >
        <h4>Fees</h4>
        <form id="feeAddForm" className="form-horizontal formWrapper">
            <div className="twoCol">
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-6" > Class Applied For<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input autoComplete="off" id="classAppliedFor" name="classApplied" defaultValue={feeDtls.classAppliedId} type="text" className="form-control" disabled />
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-6" > Registration Fees<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input autoComplete="off" id="registrationFees" defaultValue={feeDtls.fees} type="text" className="form-control " placeholder="Registration Fees" onChange={handleChange}/>
                    </div>
                </div>
            </div>
            <div className="twoCol">
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-6" >Fees Paid On<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        { <DatePicker
                            id="feesPaidOn"
                            className='form-control'
                            placeholderText="Date"
                            selected={getDateForCalendar(feeDtls.feesPaidOn)}
                            name="feesPaidOn"
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            dropdownMode="select"
                            autoComplete='off'
//                            showMonthDropdown
//                            showYearDropdown
//                            adjustDateOnChange
                            />}
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-6" >Payment Mode<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <select className="form-control" name="paymentModeId" value={feeDtls.paymentModeId} style={{ width: '100%' }} id="paymentModeCmb" onChange={handleChange}>
                            <option id={0} value={0}>Payment Mode</option>
                                    {lstPaymentMode.map((obj, idx) => {
                                        return <option id={obj.modeId} key={idx} value={obj.modeId}>{obj.mode}</option>
                                    })}

                        </select>
                    </div>
                </div>
            </div>
            <div className="twoCol">
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-6" > Reference No.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input autoComplete="off" id="referenceNo" name="referenceNo" type="text" defaultValue={feeDtls.referenceNo} className="form-control " placeholder="Reference Number" onChange={handleChange}/>
                    </div>
                </div>
            </div>
            <div className="form-group" >
                <div className="col-sm-12 text-center userButtonWrap" >
                    <button type="button" style={{ marginTop: 20 }} title="Save" onClick={props.addFee} className="btn btn-info">Save</button>
                </div>
            </div>
        </form>
    </div>
    )
}