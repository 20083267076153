import React, { Component } from 'react';

import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import 'common/css/master.css';
import '../css/registration.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderScreen from 'common/js/calendarScreen.js';

var httpURL = '';
var queryString = require('querystring');

var lstScheduleTest = [];
let testNameField = "";
let lstSlotsTime = [];
let lstTeacher = [];
var screenId = 17;

var tokenid = localStorage.getItem('tokenid');
var slotType = localStorage.getItem('slotType');

class testSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: tokenid,
            evaluationDate: null,
            testName: '',
            completedOn: '10-07-2020',
            maxScore: '100',
            passScore: '40',
            date: '',
            classId: 4,
            slotType: slotType,
            testDate: null,


        }
        this.handleChange = this.handleChange.bind(this);
        this.dateFetchFunction = this.dateFetchFunction.bind(this);
    }

    handleChange(date) {
        this.setState({
            evaluationDate: date,
        });
    }

    componentDidMount = () => {
        $('#filterStatusCmb').prepend($("<option> </option>").val(0).html('Status'));
        $('#assignInvigilatorCmb').prepend($("<option> </option>").val(0).html(' Assign Invigilator'));
        $('#assignEvaluatorCmb').prepend($("<option> </option>").val(0).html(' Assign Evaluator'));

        let wingId = 3;
        let classId = 6;
        let studentId = 1;

        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/teacher/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstTeacher = response.data.lstTeacher;
                this.fillAssignInvigilatorCmb();
                this.fillAssignEvaluatorCmb();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        this.getTestScheduleList(wingId, classId, studentId);


    }
    fillAssignInvigilatorCmb() {
        $('#assignInvigilatorCmb').empty();
        $('#assignInvigilatorCmb').prepend($("<option> </option>").val(0).html('Assign Invigilator'));
        for (let i = 0; i < lstTeacher.length; i++) {
            let teacherId = lstTeacher[i].teacherId;
            let teacher = lstTeacher[i].teacher;
            // console .log(teacherId + teacher);
            $('#assignInvigilatorCmb').append($("<option> </option>").val(teacherId).html(teacher));
        }
    }
    fillAssignEvaluatorCmb() {
        $('#assignEvaluatorCmb').empty();
        $('#assignEvaluatorCmb').prepend($("<option> </option>").val(0).html('Assign Evaluator'));
        for (let i = 0; i < lstTeacher.length; i++) {
            let teacherId = lstTeacher[i].teacherId;
            let teacher = lstTeacher[i].teacher;
            // console .log(teacherId + teacher);
            $('#assignEvaluatorCmb').append($("<option> </option>").val(teacherId).html(teacher));
        }
    }
    getTestScheduleList = (wingId, classId, studentId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/reg/sch/test/list',
            queryString.stringify({ wingId, classId, studentId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstScheduleTest = response.data.lstScheduleTest;
                this.setTestScheduleTable();
                // console .log(lstScheduleTest);

            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    setTestScheduleForms = (scheduleTestId) => {
        var testData = this.getTestData(scheduleTestId);

        var date = new Date(testData.testDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
        this.setState({
            testDate: date,
        });


        $('#testDate').val(testData.testDate);
        $('#assignInvigilatorCmb').val(testData.invigilatorId);
        $('#assignEvaluatorCmb').val(testData.evaluatorId);

        $('#remarks').val(testData.remarks);
        $('#invigilator').val(testData.invigilator);
        $('#maxMarks').val(testData.maxMarks);
        $('#passMarks').val(testData.passMarks);
        $('#testStatus').val(testData.status);
        $('#scoreObtained').val(testData.scoreObtained);

        if (testData.result === "Pass") {
            $('#result').val(1);
        }
        else if (testData.result === "Fail") {
            $('#result').val(2);
        }


        if (testData.evaluationDate === "00-00-0000" || testData.evaluationDate === "-") {
            this.setState({
                evaluationDate: "",
            });
        }
        else {
            var date = testData.evaluationDate;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var evaluationDate = new Date(setDate);
            this.setState({
                evaluationDate: evaluationDate,
            });
        }

    }

    getTestData = (scheduleTestId) => {
        for (var i = 0; i < lstScheduleTest.length; i++) {
            if (parseInt(scheduleTestId) === lstScheduleTest[i].scheduleTestId)
                return lstScheduleTest[i];
        }
    }


    setTestScheduleTable = () => {
        $('#testScheduleTableBody tr').remove();
        var table = document.getElementById('testScheduleTable');
        var tbody = document.getElementById('testScheduleTableBody');
        var filterStatus = $('#filterStatusCmb').find('option:selected').text();


        for (var i = 0; i < lstScheduleTest.length; i++) {
            var scheduleTestId = lstScheduleTest[i].scheduleTestId;
            var regsTestId = lstScheduleTest[i].regsTestId;
            var testName = lstScheduleTest[i].testName;
            var testDate = lstScheduleTest[i].testDate;
            var evaluationDate = lstScheduleTest[i].evaluationDate;
            var mandatory = lstScheduleTest[i].mandatory;
            var result = lstScheduleTest[i].result;
            var status = lstScheduleTest[i].status;




            var row = document.createElement('tr');
            row.id = scheduleTestId;

            this.addTD(row, scheduleTestId, 'no-display');
            this.addTD(row, regsTestId, 'no-display');
            this.addTD(row, testName, 'text-center');
            this.addTD(row, testDate, 'text-center');
            this.addTD(row, evaluationDate, 'text-center');
            this.addTD(row, mandatory, 'text-center');
            this.addTD(row, result, 'text-center');
            this.addTD(row, status, 'text-center');
            this.addTDAction(row, 'glyphicon glyphicon-option-vertical', 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    addTDAction = (row, val, classname) => {
        var cell = document.createElement('td');
        var span = document.createElement('span');
        cell.className = classname;
        span.className = val;
        span.style.position = 'relative';
        span.style.cursor = 'pointer';

        var ul = document.createElement('ul');
        ul.className = 'ulStyle1';

        var li1 = document.createElement('li');
        var li2 = document.createElement('li');

        li1.innerHTML = 'Schedule';
        li2.innerHTML = 'Evaluate';

        li1.onclick = () => {
            $('body').addClass('openSlider');
            $('.scheduleWrap').show();
            $('.evaluateWrap').hide();
            let scheduleTestId = $('#testScheduleTableBody tr.selected').find('td:eq(0)').text();
            this.setTestScheduleForms(scheduleTestId);
            var date = $('#testDate').val();
            this.getSlotTimeList(date);
            this.setState({ date: date })

        };

        li2.onclick = () => {
            $('body').addClass('openSlider');
            $('.scheduleWrap').hide();
            $('.evaluateWrap').show();
            let scheduleTestId = $('#testScheduleTableBody tr.selected').find('td:eq(0)').text();
            this.setTestScheduleForms(scheduleTestId);
        }


        ul.append(li1);
        ul.append(li2);

        span.onclick = (e) => {
            $(ul).toggle();
            $(e.target.closest('tr')).siblings().find('span ul').hide();
            $(e.target.closest('tr')).addClass('selected').siblings().removeClass('selected');
            testNameField = $('#testScheduleTableBody tr.selected').find('td:eq(2)').text();
            this.setState({
                testName: testNameField
            })
        };

        span.append(ul);
        cell.append(span);
        row.append(cell);
    }



    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    assignInvigilator = () => {
        let scheduleTestId = $('#testScheduleTableBody tr.selected').find('td:eq(0)').text();
        let invigilatorId = $('#assignInvigilatorCmb').find('option:selected').val();

        if (parseInt(invigilatorId) === 0) {
            toast.warning('Please Select Invigilator');
            $('#assignInvigilatorCmb').focus();
            return false;
        }


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/reg/sch/test/assign/invig',
            queryString.stringify({ scheduleTestId, invigilatorId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);

            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }
    assignEvaluator = () => {
        let scheduleTestId = $('#testScheduleTableBody tr.selected').find('td:eq(0)').text();
        let evaluatorId = $('#assignEvaluatorCmb').find('option:selected').val();

        if (parseInt(evaluatorId) === 0) {
            toast.warning('Please Select Evalautor');
            $('#assignEvaluatorCmb').focus();
            return false;
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/reg/sch/test/assign/eval',
            queryString.stringify({ scheduleTestId, evaluatorId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);

            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }




    closeSlider = () => {
        $('body').removeClass('openSlider');
    }


    dateFetchFunction = (date) => {
        this.setState({ date: date })

        // console .log("Test :- " + date);
        this.getSlotTimeList(date);
    }

    getSlotTimeList = (date) => {

        let classId = this.state.classId;

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/reg/sch/test/slots/time',
            queryString.stringify({ 'date': date, 'classId': classId, 'slotType': slotType, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstSlotsTime = response.data.lstSlotsTime;
                // console .log(lstSlotsTime);
                this.setSlotTimeTable();

            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }

            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    setSlotTimeTable = () => {
        $('#slotTimeTableBody tr').remove();
        var table = document.getElementById('slotTimeTable');
        var tbody = document.getElementById('slotTimeTableBody');


        for (var i = 0; i < lstSlotsTime.length; i++) {
            var regsSlotTimeId = lstSlotsTime[i].regsSlotTimeId;
            var startTm = lstSlotsTime[i].startTm;
            var endTm = lstSlotsTime[i].endTm;
            var seatsAvailable = lstSlotsTime[i].seatsAvailable;

            var row = document.createElement('tr');
            row.id = regsSlotTimeId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                // this.setAmissionTestSlotForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, regsSlotTimeId, 'no-display');
            this.addTD(row, startTm, 'text-center');
            this.addTD(row, endTm, 'text-center');
            this.addTD(row, seatsAvailable, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }


    addTestName = () => {
        let schedule = {};
        let scheduleTestId = $('#testScheduleTableBody tr.selected').find('td:eq(0)').text();
        let regsTestId = $('#testScheduleTableBody tr.selected').find('td:eq(1)').text();
        let invigilatorId = $('#assignInvigilatorCmb').find('option:selected').val();
        let evaluatorId = $('#assignEvaluatorCmb').find('option:selected').val();
        let testDate = this.state.date;
        let slotTimeId = $('#slotTimeTableBody tr.selected').find('td:eq(0)').text();


        if (testDate === "") {
            toast.warning('Please Select Date');
            return false;
        }

        if (slotTimeId === parseInt(0)) {
            toast.warning('Please Select Slot');
            return false;
        }


        schedule = {
            scheduleTestId,
            regsTestId,
            invigilatorId,
            evaluatorId,
            testDate,
            slotTimeId,
        }
        this.setState({ loader: 'block', opacity: 0.5 });

        axios.post(httpURL + '/sms/reg/sch/test/save?studentId=1',
            queryString.stringify({ 'schedule': JSON.stringify(schedule), screenId }),
            {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    addEvaluation = () => {
        let evaluation = {};
        let scheduleTestId = $('#testScheduleTableBody tr.selected').find('td:eq(0)').text();
        let evaluationDate = $('#evaluationDate').val();
        let remarks = $('#remarks').val();
        let result = $('#result').val();
        let scoreObtained = $('#scoreObtained').val();


        if (evaluationDate === "") {
            toast.warning('Please Fill Evalution Date');
            $('#evaluationDate').focus();

            return false;
        }

        if (scoreObtained === "") {
            toast.warning('Please Fill Marks Obtained');
            $('#scoreObtained').focus();
            return false;
        }


        evaluation = {
            scheduleTestId,
            evaluationDate,
            remarks,
            result,
            scoreObtained,
        }
        this.setState({ loader: 'block', opacity: 0.5 });

        axios.post(httpURL + '/sms/reg/sch/test/evaluate',
            queryString.stringify({ 'evaluation': JSON.stringify(evaluation), screenId }),
            {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }





    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['registration'].url + ":" + lstModules['registration'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer" style={{ opacity: this.state.opacity }}>
                    <div className=" testSchedule clearfix" >
                        <div className="col-md-11 col-centered">
                            <div className="col-sm-11  col-xs-11 enquiryWrap  padding-remove">
                                <table className="tableLayout1 enquiry" style={{ width: this.state.width }}>
                                    <thead>
                                        <tr>
                                            <th style={{ display: 'none' }}> testSchedule Id </th>
                                            <th style={{ width: 30 }}>Test </th>
                                            <th style={{ width: 30 }}>Test Date </th>
                                            <th style={{ width: 30 }}> Evaluation Date</th>
                                            <th style={{ width: 30 }}> Mandatory </th>
                                            <th style={{ width: 40 }}> Result</th>
                                            <th style={{ width: 40 }}>
                                                <div style={{ float: 'left', width: '100%' }}>
                                                    <select type="text" onChange={this.setTestScheduleTable} className="form-control inp-search" id="filterStatusCmb"> </select>
                                                </div>
                                            </th>
                                            <th style={{ width: 30 }}> Action</th>

                                        </tr>
                                    </thead>
                                </table>
                                <div className="testScheduleTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                    <table id="testScheduleTable" className="tableLayout">
                                        <colgroup>
                                            <col width="30" />
                                            <col width="30" />
                                            <col width="30" />
                                            <col width="30" />
                                            <col width="40" />
                                            <col width="40" />
                                            <col width="30" />
                                        </colgroup>
                                        <tbody id="testScheduleTableBody"></tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-5 col-xs-8 padding-remove slider fullWidthSlider" >
                                <div className="col-xs-11 col-centered padding-remove scheduleWrap clearfix" >
                                    <form id="testNameAddForm" className="form-horizontal formWrapper clearfix">
                                        <div className="col-sm-6">
                                            <div className="form-group col-md-12">
                                                <label className="control-label col-sm-6" >Test Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="testName" defaultValue={this.state.testName} type="text" className="form-control " placeholder="Test Name" readOnly />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label className="control-label col-sm-6" >Scheduled Test Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="testDate" type="text" className="form-control " placeholder="Scheduled Test Date" readOnly />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label className="control-label col-sm-6" >Calender<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <CalenderScreen
                                                        //changeDate={this.handleDateChange}
                                                        dateFromChild={this.dateFetchFunction}
                                                        classId={this.state.classId}
                                                        slotType={this.state.slotType}
                                                        defaultDate={this.state.testDate}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="evaluationPanel">
                                                <h5 className="text-center">Your Selected {this.state.date} has Slot Available</h5>
                                                <div className="col-xs-10 col-centered">
                                                    <table className="tableLayout1" style={{ width: this.state.width }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ display: 'none' }}> Fee Id </th>
                                                                <th style={{ width: 30 }}> Start Time </th>
                                                                <th style={{ width: 30 }}> End Time </th>
                                                                <th style={{ width: 30 }}> Seat Available</th>

                                                            </tr>
                                                        </thead>
                                                    </table>

                                                    <div className="slotTimeTableDiv" style={{ height: '100px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                                        <table id="slotTimeTable" className="tableLayout">
                                                            <colgroup>
                                                                <col width="30" />
                                                                <col width="30" />
                                                                <col width="30" />
                                                            </colgroup>
                                                            <tbody id="slotTimeTableBody"></tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group" >
                                                <div className="col-sm-11 text-right" >
                                                    <button type="button" style={{ marginTop: 20, marginRight: 3 }} title="Save" onClick={this.addTestName} className="btn btn-info">Save</button>
                                                </div>
                                            </div>
                                            <div className="form-group" >
                                                <div className="col-sm-12 text-center assignButtonWrap" >
                                                    <div className="assignWrap">
                                                        <div className="form-group col-md-9">
                                                            <label className="control-label col-sm-6" >Assign Invigilator<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                            <div className="col-sm-6 padding-remove">
                                                                <select autoComplete="off" id="assignInvigilatorCmb" className="form-control " />
                                                            </div>
                                                        </div>
                                                        <button type="button" style={{ marginTop: 5 }} title="Save" onClick={this.assignInvigilator} className="btn btn-info">Save</button>
                                                    </div>
                                                    <div className="assignWrap">
                                                        <div className="form-group col-md-9">
                                                            <label className="control-label col-sm-6" >Assign Evaluator<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                            <div className="col-sm-6 padding-remove">
                                                                <select autoComplete="off" id="assignEvaluatorCmb" className="form-control " />
                                                            </div>
                                                        </div>
                                                        <button type="button" style={{ marginTop: 5 }} title="Save" onClick={this.assignEvaluator} className="btn btn-info">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-xs-8 col-centered padding-remove evaluateWrap clearfix" >
                                    <h4>Evaluation</h4>
                                    <form id="evaluateAddForm" className="form-horizontal formWrapper clearfix">
                                        <div className="col-xs-12" >
                                            <div className=" form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Test: </label>
                                                <div className="col-sm-7 ">
                                                    <input id="testsName" className="form-control " defaultValue={this.state.testName} readOnly />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label className=" col-sm-4 padding-remove control-label" >Invigilator: </label>
                                                <div className="col-sm-7 ">
                                                    <input id="invigilator" className="form-control " readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12" >
                                            <div className="form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Test Status: </label>
                                                <div className="col-sm-7 "><input id="testStatus" className="form-control " readOnly /></div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Completed On: </label>
                                                {/* <input id="evaluationDate" className="form-control " defaultValue={this.state.completedOn} /> */}
                                                <div className="col-sm-7 "> <DatePicker id="evaluationDate"
                                                    className='form-control '
                                                    placeholderText=" Start Date"
                                                    selected={this.state.evaluationDate}
                                                    onChange={this.handleChange}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 marksWrapper" >
                                            <div className="form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Max. Score: </label>
                                                <div className="col-sm-7 "><input id="maxMarks" className="form-control " readOnly /></div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Passing Score: </label>
                                                <div className="col-sm-7 "> <input id="passMarks" className="form-control " readOnly /></div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12" >
                                            <div className="form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Score Obtained: </label>
                                                <div className="col-sm-7 "> <input id="scoreObtained" className="form-control" placeholder="Score Obtained" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12" >
                                            <div className="form-group col-sm-6">
                                                <label className="col-sm-4 padding-remove control-label" >Result: </label>
                                                <div className="col-sm-7 ">
                                                    <select className="form-control " id="resultCmb">
                                                        <option val={0}>Pass</option>
                                                        <option val={1}>Fail</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12" >
                                            <div className="form-group col-sm-11">
                                                <label className="col-sm-2 padding-remove control-label" >Remarks: </label>
                                                <div className="col-sm-9 ">
                                                    <textarea className=" form-control " id="remarks" placeholder="Remarks" />
                                                </div>
                                            </div>
                                            <button type="button" style={{ marginTop: 25 }} title="Save" onClick={this.addEvaluation} className="btn btn-info">Save</button>
                                        </div>
                                    </form>
                                </div>
                                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.closeSlider}>
                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default testSchedule;