import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
var tokenid = localStorage.getItem('tokenid')
function getOneTimeHeadListSvc (callBack) {
  axios({
    url: httpURL + '/sms/onetimehead/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console .log('One Time Head List Catch error')
    })
}
function saveOneTimeHeadSvc (pOneTimeHeadObj, callBack) {
  var formData = new FormData()
  formData.append('headObj', JSON.stringify(pOneTimeHeadObj))
  axios({
    url: httpURL + '/sms/onetimehead/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      callBack(data)
    })
    .catch(error => {
      // console .log('Error Occured')
    })
}
function deleteOneTimeHeadSvc (pOneTimeHeadId, callBack) {
  axios({
    url: httpURL + '/sms/onetimehead/delete',
    method: 'POST',
    params: { headId: pOneTimeHeadId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console .log('error')
    })
}
function getOneTimeHeadDtlsSvc (pOneTimeHeadId, cb) {
  axios({
    url: httpURL + '/sms/onetimehead/dtls',
    method: 'POST',
    params: { headId: pOneTimeHeadId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cb(data)
    })
    .catch(error => {
      // console .log('OneTimeHead Dtls Catch error')
    })
}
function getClassListSvc (callback1) {
  // console .log('getClassListSvc')
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/mst/cls/list',
    method: 'POST',
    params: { screenId: 0 },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      // console .log('data.SvcStatus=' + response.data.SvcStatus)
      obj.message = response.data.SvcMsg
      obj.status = 'Success'
      obj.lstClass = response.data.lstClass
      // console .log(obj)
      callback1(obj)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback1(obj)
    })
}

export {
  getOneTimeHeadListSvc,
  saveOneTimeHeadSvc,
  deleteOneTimeHeadSvc,
  getOneTimeHeadDtlsSvc,
  getClassListSvc
}
