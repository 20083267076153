import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header.js';
import axios from 'axios';
import StudentDuesTable from './StudentDuesTable.jsx';
import $, { param } from 'jquery'
import '../css/fee.css'
import 'common/css/master.css';
import "../css/feeHeads.css";
import PaymentReceipt from './PaymentReceipt.js'
import PaymentForm from './paymentFormOld.jsx';
import PaymentHistory from './paymentHistory.jsx';
import MonthlyStudentFeeDtls from './monthlyStudentFeeDtls.jsx'
import lstModules from 'common/json/lstModules.json';
import DeletePayment from './deletePaymentPopup.js';

var convertRupeesIntoWords = require('convert-rupees-into-words');
var httpURL = '', httpURLCommon = '';
var queryString = require('querystring');
var screenId = 37;
var lstMonthlyPaid = [];
var lstStudentDtls = [];
const params = new URLSearchParams(window.location.search);
export default class FeePaymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flg: false,
            loader: 'none',
            opacity: 1,
            // flg: true,
            tokenid: localStorage.getItem('tokenid'),
            // lstStudentId: JSON.parse(localStorage.getItem('lstStudentId')),
            lstStudentId: JSON.parse(params.get('lstStudentId')),
            // lstStudentId : [1,2],
            lstBillDtls: [],
            count: 0,
            total: 0,
            totalInWord: "Zero",
            lstMonthlyStudentFeeDtls: [],
            month: "Jan",
            lstModes: [],
            lstPayment: [],
            lstBillBreakdown: [],
            monthId: 0,
            pdfObj: '',
            paymentId: null,
            // lstStudentDtls: [],
            isPopupVisible: false,
            deletePaymentDt:"",
            deletePaymentAmt:0,
        }
    }

    componentDidMount = () => {
        this.getFeeDueDtlsList();
        // this.showFeeDueDtls(1, "jan");
        this.getModeList();
        this.getHistoryList();
        
    }

    getFeeDueDtlsList = () => {
        if (this.state.lstStudentId !== null) {
            this.setState({ loader: 'block', opacity: 0.5 })
            axios.post(httpURL + '/sms/payment/feedue/list',
                queryString.stringify({ 'lstStudentId': JSON.stringify(this.state.lstStudentId), screenId }), {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === "Success") {
                    var lst = response.data.lstBillDtls;
                    // console .log('lstBillDtls::' + JSON.stringify(response.data.lstBillDtls))
                    // for (var i = 0; i < lst.length; i++) {
                    //     var lstFeeDueDtls = lst[i].lstFeeDueDtls;
                    //     // for (var j = 0; j < lstFeeDueDtls.length; j++) {
                    //     //     var lstMonthlyStudentFeeDtls = lstFeeDueDtls[j].lstMonthlyStudentFeeDtls;
                    //     //     for (var k = 0; k < lstMonthlyStudentFeeDtls.length; k++) {
                    //     //         lstMonthlyStudentFeeDtls[k].selected = true;
                    //     //         lstMonthlyStudentFeeDtls[k].amt = lstMonthlyStudentFeeDtls[k].feeHeadAmtDue;
                    //     //     }
                    //     // }
                    // }
                    this.setState({
                        lstBillDtls: response.data.lstBillDtls,
                        count: response.data.lstBillDtls.length
                    })

                }
                else {
                    toast.error(response.data.SvcMsg);
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((e) => {
                this.setState({ loader: 'none', opacity: 1 })
                toast.error("Service failed " + e);
            });
        }
        else {
            setTimeout(function () { window.location.href = ('/feePayment') }, 1000);
        }
    }

    getHistoryList = () => {
        if (this.state.lstStudentId !== null) {
            this.setState({ loader: 'block', opacity: 0.5 })
            axios.post(httpURL + '/sms/payment/history/list',
                queryString.stringify({ 'lstStudentId': JSON.stringify(this.state.lstStudentId), screenId }), {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === "Success") {
                    this.setState({
                        lstPayment: response.data.lstPayment,
                    })
                }
                else {
                    toast.error(response.data.SvcMsg);
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((e) => {
                this.setState({ loader: 'none', opacity: 1 })
                toast.error("Service failed " + e);
            });
        }
        else {
            setTimeout(function () { window.location.href = ('/feePayment') }, 1000);
        }
    }
    getModeList = () => {
        this.setState({ loader: 'block', opacity: 0.5 })
        axios.post(httpURLCommon + '/sms/mst/payment_mode/list',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                this.setState({
                    lstModes: response.data.lstPaymentMode,
                });
                // console .log('lstModes', this.state.lstModes);
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });
    }
    calculateTotal = (number) => {
        // console .log(number);
        let totalAmount = convertRupeesIntoWords(number);
        // console .log(totalAmount);

        this.setState({
            total: number,
            totalInWord: totalAmount
        })
    }
    getBillBreakdownSvc = (pBillNo) => {
        this.setState({ loader: 'block', opacity: 0.5 })
        axios.post(httpURL + '/sms/payment/feedue/bill_dtls',
            queryString.stringify({ screenId, 'billNo': pBillNo }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                this.setState({
                    lstBillBreakdown: response.data.lstBillDtls,
                    billNo: pBillNo
                });
                // console .log('lstBillBreakdown', response.data.lstBillDtls);
            }
            else {
                toast.error(response.data.SvcMsg);
                this.closePopup();
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
            this.closePopup();
        });
    }
    showFeeDueDtls = (studentId, pBillNo) => {

        if (!document.getElementById(`payCbx_` + studentId + `_` + pBillNo).checked) {
            toast.warn("Please select the bill first");
            return;
        }
        $('body').addClass('showPopup');
        // lst.map(obj => ({ ...obj, selected: true }))
        // console .log("shoFDDtls... billNo = " + billNo + ", studentId = " + studentId + ", lst = " + JSON.stringify(lst))
        this.setState({
            /* billNo: pBillNo
            , */ studentId: studentId

        })
        this.getBillBreakdownSvc(pBillNo);
        /* axios.post(httpURL + '/sms/payment/month/fee/dtls',
            queryString.stringify({ studentId, month, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                $('body').addClass('showPopup');
                var lstMonthlyStudentFeeDtls = response.data.lstMonthlyStudentFeeDtls;
                for (var k = 0; k < lstMonthlyStudentFeeDtls.length; k++) {
                    lstMonthlyStudentFeeDtls[k].selected = true;
                    lstMonthlyStudentFeeDtls[k].amt = lstMonthlyStudentFeeDtls[k].feeHeadAmtDue;
                }
                this.setState({
                    lstMonthlyStudentFeeDtls: lstMonthlyStudentFeeDtls
                    , month: month
                    , studentId: studentId
                })

            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        }); */
    }
    /* for (var i = 0; i < this.state.lstBillDtls.length; i++) {
                    var obj = this.state.lstBillDtls[i];
                    if (obj.studentId == studentId) {
                        for (var j = 0; j < obj.lstFeeDueDtls.length; j++) {
                            var objFeeDue = obj.lstFeeDueDtls[i];
                            if (objFeeDue.month == month) {
                                obj.lstFeeDueDtls[i].lstMonthlyStudentFeeDtls = response.data.lstMonthlyStudentFeeDtls;
                                break;
                            }
                        }
                        break;
                    }
                } */
    closePopup = () => {
        $('body').removeClass("showPopup");
    }
    pdfObject = async (obj) => {
        var data = obj;
        this.setState({ pdfObj: obj.data })
        // console .log('objj::' + JSON.stringify(obj))
        let blob = new Blob([obj.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
        this.flgSlider(true)
    }
    saveObject = async (obj) => {

        if (this.state.count === 1) {
            await this.getTable1Data();
            await this.saveFeeDue(obj);
        }
        if (this.state.count === 2) {
            await this.getTable1Data();
            await this.getTable2Data();
            await this.saveFeeDue(obj);

        }
        if (this.state.count === 3) {
            await this.getTable1Data();
            await this.getTable2Data();
            await this.getTable3Data();
            await this.saveFeeDue(obj);
        }
    }

    saveFeeDue = (obj, callBack) => {
        let {
            refNo,
            paidAmount,
            paymentDt,
            modeId,
            paidByNm,
            paidByPhone,
            remarks,
            paidById,
            flgMail
        } = obj;
        let RQSavePayment = {
            refNo,
            paidAmount,
            paymentDt,
            modeId,
            paidByNm,
            paidByPhone,
            remarks,
            paidById,
            flgMail,
            lstStudentDtls
        }

        this.setState({ loader: 'block', opacity: 0.5 })
        axios.post(httpURL + '/sms/payment/save',
            queryString.stringify({ "payment": JSON.stringify(RQSavePayment), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                toast.success(response.data.SvcMsg);
                this.getHistoryList();
                // console .log('response.data.paymentId' + response.data.paymentId)

                this.setState({ paymentId: response.data.paymentId })
                // localStorage.setItem('paymentId', response.data.paymentId)
                // localStorage.setItem('lstStudentId', []);
                // setTimeout(function () {
                //     window.close();
                // }, 2000);
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });
    }
    getPaymentId = (num) => {
        // console .log('in getPaymentId::' + this.state.paymentId)
        return this.state.paymentId;
    }
    // payment: {"refNo":"2345","paidAmount":20,"modeId":"20","paidByNm":"Mr Dad","paidByPhone":"744774447744","remarks":"","paidById":"20","flgMail":false,"lstStudentDtls":[{"studentId":8,"billNo":18,"billAmount":28050}]}
    getTable1Data = () => {
        lstMonthlyPaid = [];
        var objFeeHead = {}
        let studentId = 0;
        $('.feeDueTableBody0').find('tr').each(function (index, value) {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                studentId = $(this).find('td:eq(0)').text();
                var billNo = $(this).find('td:eq(1)').text();
                var amt = $(this).find('td:eq(5) input').val();
                var bAmt = $(this).find('td:eq(3)').text();
                // console .log(amt)
                // var month = $(this).find('td:eq(1)').text();
                // var lstFH = JSON.parse($(this).attr("lstmonthlystudentfeedtls"));
                // var lstFeeHead = [];
                // for (var i = 0; i < lstFH.length; i++) {

                //     var tmpObj = lstFH[i];
                //     if (!!tmpObj.selected) {
                //         var objFeeHead = { feeHeadId: tmpObj.feeHeadId, amt: tmpObj.amt }
                //         lstFeeHead.push(objFeeHead);
                //     }
                // }
                var objFeeHead = { studentId: parseInt(studentId), billNo: parseInt(billNo), billAmount: parseInt(amt) }
                lstMonthlyPaid.push(objFeeHead);
                // console .log('objFeeHead'+JSON.stringify(objFeeHead))
                if (parseInt(studentId) !== 0) {
                    // console .log('objfeehead2'+JSON.stringify(lstMonthlyPaid))
                    const sDtls = lstStudentDtls.find(studDtls => studDtls.studentId === objFeeHead.studentId && studDtls.billNo === objFeeHead.billNo);
                    if (!sDtls) {
                        lstStudentDtls.push(objFeeHead)
                    }
                }
            }
        });
        // var lstStudentFeeDtlsObj = { studentId, lstMonthlyPaid };
        // if (parseInt(studentId) !== 0) {
        // console .log('objfeehead2'+JSON.stringify(lstMonthlyPaid))
        //     lstStudentDtls=lstMonthlyPaid
        // }
    }
    getTable2Data = () => {
        lstMonthlyPaid = [];
        var objFeeHead = {}
        let studentId = 0;
        $('.feeDueTableBody1').find('tr').each(function (index, value) {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                studentId = $(this).find('td:eq(0)').text();
                var billNo = $(this).find('td:eq(1)').text();
                var amt = $(this).find('td:eq(5) input').val();
                // console .log(amt)
                // var month = $(this).find('td:eq(1)').text();
                // var lstFH = JSON.parse($(this).attr("lstmonthlystudentfeedtls"));
                // var lstFeeHead = [];
                // for (var i = 0; i < lstFH.length; i++) {

                //     var tmpObj = lstFH[i];
                //     if (!!tmpObj.selected) {
                //         var objFeeHead = { feeHeadId: tmpObj.feeHeadId, amt: tmpObj.amt }
                //         lstFeeHead.push(objFeeHead);
                //     }
                // }
                var objFeeHead = { studentId: studentId, billNo: billNo, billAmount: amt }
                lstMonthlyPaid.push(objFeeHead);
                // console .log('objFeeHead'+JSON.stringify(objFeeHead))
                if (parseInt(studentId) !== 0) {
                    // console .log('objfeehead2'+JSON.stringify(lstMonthlyPaid))
                    const sDtls = lstStudentDtls.find(studDtls => studDtls.studentId === objFeeHead.studentId && studDtls.billNo === objFeeHead.billNo);
                    if (!sDtls) {
                        lstStudentDtls.push(objFeeHead)
                    }
                }
            }
        });
        // var lstStudentFeeDtlsObj = { studentId, lstMonthlyPaid };
        // if (parseInt(studentId) !== 0) {
        // console .log('objfeehead2'+JSON.stringify(lstMonthlyPaid))
        //     lstStudentDtls=lstMonthlyPaid
        // }
    }
    getTable3Data = () => {
        lstMonthlyPaid = [];
        var objFeeHead = {}
        let studentId = 0;
        $('.feeDueTableBody2').find('tr').each(function (index, value) {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                studentId = $(this).find('td:eq(0)').text();
                var billNo = $(this).find('td:eq(1)').text();
                var amt = $(this).find('td:eq(5) input').val();
                // console .log(amt)
                // var month = $(this).find('td:eq(1)').text();
                // var lstFH = JSON.parse($(this).attr("lstmonthlystudentfeedtls"));
                // var lstFeeHead = [];
                // for (var i = 0; i < lstFH.length; i++) {

                //     var tmpObj = lstFH[i];
                //     if (!!tmpObj.selected) {
                //         var objFeeHead = { feeHeadId: tmpObj.feeHeadId, amt: tmpObj.amt }
                //         lstFeeHead.push(objFeeHead);
                //     }
                // }

                var objFeeHead = { studentId: parseInt(studentId), billNo: parseInt(billNo), billAmount: parseInt(amt) }
                lstMonthlyPaid.push(objFeeHead);
                // console .log('objFeeHead'+JSON.stringify(objFeeHead))
                if (parseInt(studentId) !== 0) {
                    // console .log('objfeehead2'+JSON.stringify(lstMonthlyPaid))
                    const sDtls = lstStudentDtls.find(studDtls => studDtls.studentId === objFeeHead.studentId && studDtls.billNo === objFeeHead.billNo);
                    if (!sDtls) {
                        lstStudentDtls.push(objFeeHead)
                    }
                }
            }
        });
        // var lstStudentFeeDtlsObj = { studentId, lstMonthlyPaid };
        // if (parseInt(studentId) !== 0) {
        // console .log('objfeehead2'+JSON.stringify(lstMonthlyPaid))
        //     lstStudentDtls=lstMonthlyPaid
        // }
    }
    updatePayment = (tPayment, studentId, month, lst) => {
        // console .log("tPayment:" + tPayment + "  student Id:" + studentId + "  month" + month + '  lst:' + JSON.stringify(lst))
        const lstData = this.state.lstBillDtls;
        // console .log('paymentInput_' + studentId + '_' + month);
        document.getElementById('paymentInput_' + studentId + '_' + month).value = tPayment;

    }
    handlePayment = (id) => {
        let payment = $('#paymentInput_' + id).val();
        // console .log("payment::" + payment)
        // console .log('id::' + id)

        if (payment < 0 || payment === 0 || payment === '') {
            $('#payCbx_' + id).prop('checked', false)
            // console .log($('#payCbx_' + id))
        }
        else {
            $('#payCbx_' + id).prop('checked', true)
        }
        this.totalPayment(id);
    }
    totalPayment = (id) => {
        var _index = id.indexOf('_')
        var total = 0;
        let suffixId = id.substr(0, _index);
        let tableId = '#feeDueTableBody' + suffixId;
        // console .log('tableId = ' + tableId);
        $(tableId).find('tr').each(function (index, value) {
            // console .log("cbox = " + $(this).find($('input[type=checkbox]')).prop('checked') === true);
            if ($(this).find($('input[type=checkbox]')).prop('checked') === true) {
                var amt = parseInt($(this).find('td:eq(5) input').val());
                var payment = isNaN(amt) ? 0 : amt;
                total = total + parseInt(payment)
                // console .log('payment = ' + payment + ', total=' + total);
            }
        });
        $('#dueFeepayment' + suffixId).html(total);

        this.calcTotal();
    }
    calcTotal = () => {
        let totalPayment = 0;
        if (this.props.count === 1) {
            let amt1 = parseInt($(".dueFeepayment0").text());
            let payment1 = isNaN(amt1) ? 0 : amt1;
            // console .log(payment1);
            this.calculateTotal(payment1);
        }
        else if (this.props.count === 2) {
            let amt1 = parseInt($(".dueFeepayment0").text());
            let payment1 = isNaN(amt1) ? 0 : amt1;
            let amt2 = parseInt($(".dueFeepayment1").text());
            let payment2 = isNaN(amt2) ? 0 : amt2;
            totalPayment = parseInt(payment1) + parseInt(payment2);
            // console .log(totalPayment);
            this.calculateTotal(totalPayment);

        }
        else {
            let amt1 = parseInt($(".dueFeepayment0").text());
            let payment1 = isNaN(amt1) ? 0 : amt1;
            let amt2 = parseInt($(".dueFeepayment1").text());
            let payment2 = isNaN(amt2) ? 0 : amt2;
            let amt3 = parseInt($(".dueFeepayment2").text());
            let payment3 = isNaN(amt3) ? 0 : amt3;
            totalPayment = parseInt(payment1) + parseInt(payment2) + parseInt(payment3);
            // console .log(totalPayment);
            this.calculateTotal(totalPayment);

        }

    }
    flgSlider = async (val) => {
        // console .log('val:' + val)
        await this.setState({ flg: val })
        // console .log('flg state::' + this.state.flg)
    }
    closeSlider = () => {
        this.setState({ flg: 0 })
    }

    generateRandomNo = () => {
        return Math.floor(Math.random() * 999999);
    }

    deletePayment = (pId, pDt, pAmt) => {
        // console .log("deletePayment called, pId", pId);
        this.setState({ paymentId: pId, deletePaymentAmt:pAmt, deletePaymentDt:pDt });
        this.openPopup();
    }

    openPopup = () => {
        this.setState({ isPopupVisible: true });
    }
    closePopup = () => {
        this.setState({ isPopupVisible: false });
    }

    setLoader = (ldr) => {
        this.setState({ loader: ldr })
    }

    setOpacity = (opct) => {
        this.setState({ opacity: opct })
    }
    
    reloadFeeDueHistory = () => {
        this.getFeeDueDtlsList();
        this.getHistoryList();
    }

    render() {
        httpURL = lstModules['fees'].url + ':' + lstModules['fees'].port;
        httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer feeHeads" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 padding-remove col-centered clearfix">
                        {this.state.lstBillDtls.map((feeDues, i) => (
                            <StudentDuesTable
                                key={feeDues.studentId}
                                handlePayment={this.handlePayment}
                                feeDues={feeDues}
                                calculateTotal={this.calculateTotal}
                                count={this.state.count}
                                paymentid={i}
                                // lstMonthlyStudentFeeDtls={feeDues.lstMonthlyStudentFeeDtls}
                                showFeeDueDtls={this.showFeeDueDtls}
                            />
                        ))}
                    </div>
                    {this.state.lstBillBreakdown.length ?
                        <div className="popup feeHeadPopup1">
                            <MonthlyStudentFeeDtls
                                key={this.state.studentId + '_' + this.state.billNo}
                                studentId={this.state.studentId}
                                lstMonthlyStudentFeeDtls={this.state.lstBillBreakdown}
                                month={this.state.billNo}
                                closePopup={this.closePopup}
                                updatePayment={this.updatePayment}
                                handlePayment={this.handlePayment}
                            />
                        </div> : null}
                    <div className="col-sm-11 col-centered clearfix">
                        <PaymentForm
                            total={this.state.total}
                            totalInWord={this.state.totalInWord}
                            lstModes={this.state.lstModes}
                            saveObject={this.saveObject}
                            flgSlider={this.flgSlider}
                            paymentId={this.state.paymentId}
                            pdfObject={this.pdfObject}
                            getPaymentId={this.getPaymentId}
                        />
                    </div>

                    <div className="col-sm-11 col-centered clearfix"
                        style={{ marginTop: 15, marginBottom: 15 }}>
                        {/* {this.state.lstPayment.length ?
                            <PaymentHistory
                                lstPayment={this.state.lstPayment}

                            />
                            : null} */}
                        <PaymentHistory
                            lstPayment={this.state.lstPayment}
                            flgSlider={this.flgSlider}
                            pdfObject={this.pdfObject}
                            deletePayment={this.deletePayment}

                        />
                    </div>
                    <div className="slider halfWidthSlider" style={{ right: 0, opacity: 1, display: this.state.flg === true ? 'block' : 'none', zIndex: 10000 }}>
                        <PaymentReceipt
                            key={this.generateRandomNo}
                            flg={this.state.flg}
                            pdfObj={this.state.pdfObj}
                            closeSlider={this.closeSlider}
                        />
                    </div>
                </div>
                {this.state.isPopupVisible ?
                    <DeletePayment
                        paymentId={this.state.paymentId}
                        paymentDt={this.state.deletePaymentDt}
                        paymentAmt={this.state.deletePaymentAmt}
                        closePopup={this.closePopup}
                        setLoader={this.setLoader}
                        setOpacity={this.setOpacity}
                        refreshPage={this.reloadFeeDueHistory}
                    />
                    : null
                }
            </div>
        )
    }
}