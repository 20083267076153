import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text, Font, Image, StyleSheet } from '@react-pdf/renderer';

import { pageStyle, leaderboardPdfStyle, textStyle } from './styleStudentOtpPdf';
import StaffOTPQR from './staffOTPQR';
import LeaderBoardImage from "../images/leaderboard.png";
import LogoImage from "../images/cooledsys_logo.jpg";

import NaanumGothicRegular from "common/fonts/Nanum_Gothic/NanumGothic-Regular.ttf";
import NaanumGothicBold from "common/fonts/Nanum_Gothic/NanumGothic-Bold.ttf";

Font.register({
    family: 'Calibri',
    fonts: [
        { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf", format: 'truetype' },
        { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold", format: 'truetype' }
    ]
});

Font.register({
    family: 'Nanum Gothic',
    fonts: [
        { src: NaanumGothicRegular, fontWeight: 'normal' },
        { src: NaanumGothicBold, fontWeight: 'bold' }
    ]
});

const LeaderboardPDF = (props) => {
    const [schoolId, setSchoolId] = useState(0);
    const { testId, test, lstOtp } = props;

    useEffect(() => {
        // console .log("leaderboardPDF called");
        setSchoolId(parseInt(localStorage.getItem("schoolId")));
        // console .log("lstOtp in LeaderboardPDF", lstOtp);
    }, [lstOtp]);

    const pages = [];
    
    function formatNumber(number) {
        // Convert the number to a string and split it into an array of characters
        const numberArray = number.toString().split('');
        // Join the array elements with a space
        return numberArray.join(' ');
    }

    for (let i = 0; i < lstOtp.length; i++) {
        pages.push(
            <Page size="A4" key={i}>
                <View style={leaderboardPdfStyle.page}>
                    <View style={leaderboardPdfStyle.header}>
                        <View style={leaderboardPdfStyle.pageHeading}>
                            <View style={leaderboardPdfStyle.pageHeadingText}>
                                <Text style={textStyle.whiteHeading}>
                                    Weekly Test Automation
                                </Text>
                            </View>
                        </View>
                        <View style={leaderboardPdfStyle.qrSection}>
                            <View >
                                <StaffOTPQR
                                    schoolId={schoolId}
                                    testId={testId}
                                    otp={lstOtp[i].otp}
                                    qrSize={140}
                                />
                            </View>
                            <View style={{padding:"0 10px"}}>
                                <Text style={styles.textOtpCenterBold}>{ formatNumber(lstOtp[i].otp)}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={leaderboardPdfStyle.pageContent}>
                        <View style={leaderboardPdfStyle.leaderboardImageSection}>
                            <View style={leaderboardPdfStyle.leaderboardImageDiv}>
                                <Image
                                    style={leaderboardPdfStyle.leaderboardImage}
                                    src={LeaderBoardImage}
                                />
                            </View>
                        </View>
                        <View style={leaderboardPdfStyle.pointsSection}>
                            <View style={leaderboardPdfStyle.pointsRow}>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• </Text>
                                        <Text style={styles.textCenterBold}>Computer</Text>
                                        <Text style={styles.textCenterNormal}> or printouts</Text>
                                    </View>
                                </View>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• </Text>
                                        <Text style={styles.textCenterBold}>Integrated</Text>
                                        <Text style={styles.textCenterNormal}> with syllabus</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={leaderboardPdfStyle.pointsRow}>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• Manual </Text>
                                        <Text style={styles.textCenterBold}>or interactive</Text>
                                    </View>
                                </View>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• Teacher </Text>
                                        <Text style={styles.textCenterBold}>not overloaded</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={leaderboardPdfStyle.pointsRow}>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• </Text>
                                        <Text style={styles.textCenterBold}>Batch</Text>
                                        <Text style={styles.textCenterNormal}> or complete class</Text>
                                    </View>
                                </View>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• Automated </Text>
                                        <Text style={styles.textCenterBold}>Evaluation</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={leaderboardPdfStyle.pointsRow}>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• </Text>
                                        <Text style={styles.textCenterBold}>Fun Mode</Text>
                                        <Text style={styles.textCenterNormal}> for students</Text>
                                    </View>
                                </View>
                                <View style={leaderboardPdfStyle.pointsColumn}>
                                    <View style={[leaderboardPdfStyle.pointsCell, { flexDirection: 'row' }]}>
                                        <Text style={styles.textCenterNormal}>• Questions </Text>
                                        <Text style={styles.textCenterBold}>already fed</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={leaderboardPdfStyle.horizontalBar}></View>
                        <View style={leaderboardPdfStyle.logoImageSection}>
                            <View style={leaderboardPdfStyle.logoImageDiv}>
                                <Image
                                    style={leaderboardPdfStyle.logoImage}
                                    src={LogoImage}
                                />
                            </View>
                        </View>
                        <View style={leaderboardPdfStyle.horizontalBar}></View>
                        <View style={leaderboardPdfStyle.footerSection}>
                            <View style={leaderboardPdfStyle.pointsCell}>
                                <Text style={textStyle.footerPoint}>
                                    The most advanced and affordable school software
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        );
    }

    return (
        <div className='col-sm-12 padding-remove' style={{ marginTop: "1vh" }}>
            <PDFViewer style={{ width: "100%", height: "98vh", overflowY: "scroll" }}>
                <Document>{pages}</Document>
            </PDFViewer>
        </div>
    );
};

export default LeaderboardPDF;



const styles = StyleSheet.create({

    textCenterBold: {
        fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: 20,
        color: 'blue',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textOtpCenterBold: {
        fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: 23,
        // color: 'darkgray',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textCenterNormal: {
        fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: 20,
        color: 'blue',
        // borderWidth: 1,
        // borderColor: "#000",
    },
});