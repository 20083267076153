import React, { useEffect, useState } from 'react'
import { getStudentInfoSvc } from "./WTestSvc.js";

var lstTmpStudentId = []
var tmpStudentData = {}
const WTestStudentRow = (props) => {
    const [studentInfo, setStudentInfo] = useState({})
    const [loader, setLoader] = useState('none')

    useEffect(() => {
        lstTmpStudentId = props.lstTmpStudentId;
        // console .log(props)
        tmpStudentData = props.obj;
        setStudentInfo(props.obj);
        const interval = setInterval(() => {
            if (tmpStudentData.joinedDttm == null) {
                getStudentInfo(props.obj.studentId);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    function handleCboxChange(e, obj, status) {
        e.stopPropagation()
        if (status) {
            lstTmpStudentId.push(obj.studentId)
        }
        else {
            var idx = existsIn(obj.studentId, lstTmpStudentId);
            lstTmpStudentId.splice(idx, 1)
        }
        props.setLstStudentId(lstTmpStudentId)
    }

    function existsIn(pStudentId, arr) {
        var idx = -1;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === pStudentId) {
                idx = i;
                break;
            }
        }
        return idx;
    }

    function getStudentInfo(pStudentId) {
        getStudentInfoSvc(props.testId, pStudentId, cbGetStudentInfo)
    }
    function cbGetStudentInfo(data) {
        if (data.SvcStatus === "Success") {
            setStudentInfo({ ...props.obj, ...data.studentInfo });
            tmpStudentData = { ...props.obj, ...data.studentInfo }
        } else {
            setStudentInfo({})
        }
        setLoader("none");
    }
    return (
        <>
            <tr
                key={'sId_' + studentInfo.studentId}
                id={studentInfo.studentId}
                onClick={e => props.setStudentId(studentInfo.studentId)}
                className={studentInfo.studentId === props.studentId ? 'selected' : ''}>
                <td>{studentInfo.studentId}</td>
                <td className='text-left'>{studentInfo.student}</td>
                <td className='text-center'>{studentInfo.enrollmentNo}</td>
                <td className='text-center'>{studentInfo.otp}</td>
                <td className='text-center'>{studentInfo.otpGenerationDttm}</td>
                <td className='text-center'>{studentInfo.joinedDttm}</td>
                <td className='text-center'>{studentInfo.crypticNm}</td>
                <td onClick={(e) => { e.stopPropagation(); e.target.firstChild.click() }}>
                    <input key={'trs_' + studentInfo.studentId}
                        id={'stud_' + studentInfo.studentId}
                        onClick={(e) => handleCboxChange(e, studentInfo, e.target.checked)}
                        type="checkbox"
                        defaultChecked={false}
                    />
                </td>

            </tr>
        </>
    )
}

export default WTestStudentRow