import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import { getAttendanceSummarySvc, getLstMstRouteSvc } from "./tptAttendanceApi";
import Loader from "common/js/Loader";
import "../css/tabs.css";
import TptAttendanceTable from "./tptAttendanceTable";
import DailyTptAttendanceTable from "./dailyTptAttendanceTable";

const TransportAttendanceCtrl = () => {
  const [state, setState] = useState({ fromDt: null, toDt: null });
  const [lstAttendanceRoute, setLstAttendanceRoute] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [displayLoader1, setDisplayLoader1] = useState("none");
  const [displayLoader2, setDisplayLoader2] = useState("none");
  const [flgMorEve, setFlgMorEve] = useState(false);
  const [uniqueId, setUniqueId] = useState(-1);
  const [sliderDisplayed, setSliderDisplayed] = useState(false);
  const [randNo, setRandNo] = useState(0);
  const [dt, setDt] = useState(null);
  const [routeId, setRouteId] = useState(0);

  useEffect(() => {
    var endDt = new Date();
    var stDt = new Date();
    stDt.setDate(stDt.getDate() - 60);
    setState({
      fromDt: new Date(stDt),
      toDt: new Date(endDt),
    });
    var from = convert(new Date(stDt));
    var to = convert(new Date(endDt));
    getData(from, to, false);
  }, []);

  function handleChange(date) {
    setState({
      ...state,
      fromDt: date,
    });
  }
  function handleChange1(date) {
    setState({
      ...state,
      toDt: date,
    });
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    // console .log([day, mnth, date.getFullYear()].join("-"));
    return [day, mnth, date.getFullYear()].join("-");
  }

  const getWingId = (wingId) => {
    // console .log("wingId = " + wingId);
  };

  function getAttendanceSummary() {
    var from = convert(state.fromDt);
    var to = convert(state.toDt);
    getData(from, to, false);
  }

  function getData(from, to, flg) {
    setDisplayLoader1("block");
    setDisplayLoader2("block");

    getAttendanceSummarySvc(from, to, flg, cbAttendanceResponse);
  }

  function cbAttendanceResponse(data) {
    setDisplayLoader1("none");
    setDisplayLoader2("none");
    if (data.status === "Success") {
      setLstAttendanceRoute(data.lstRoute);
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  }

  const toggleTab = (index) => {
    setToggleState(index);
    if (index === 1) {
      setFlgMorEve(false);
      setDisplayLoader1("block");
    } else if (index === 2) {
      setFlgMorEve(true);
      setDisplayLoader2("block");
    }
    var from = convert(state.fromDt);
    var to = convert(state.toDt);
    getData(from, to, index === 2);
  };

  const showSlider = () => {
    setSliderDisplayed(true);
  };

  const closeSlider = () => {
    generateRandomNo();
    setSliderDisplayed(false);
    var from = convert(state.fromDt);
    var to = convert(state.toDt);
    getData(from, to, flgMorEve);
  };

  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console .log(lRandNo);
    setRandNo(lRandNo);
  }

  async function setData(idx, obj) {
    // console .log("setData called");
    // console .log(idx);
    // console .log(obj);
    setUniqueId(idx);
    await setDt(obj.attendanceDt);
    setRouteId(obj.routeId);
  }
  return (
    <div
      className="MainContainer"
      style={{
        pointerEvents:
          displayLoader1 === "none" || displayLoader2 === "none"
            ? "auto"
            : "none",
      }}
    >
      <Header wingFromChild={getWingId} />

      <ToastContainer autoClose={3000} hideProgressBar />

      <div className="col-sm-5 col-centered" style={{ marginTop: "2%" }}>
        <div
          className="col-sm-12"
          style={{
            border: "1px solid #ccc",
            borderRadius: 5,
            padding: 4,
          }}
        >
          <div className="col-sm-4" style={{ width: "40%" }}>
            <label
              className="col-sm-6 text-left padding-remove"
              style={{ top: 5 }}
            >
              From Date
            </label>
            <div className="form-group col-sm-6 padding-remove">
              <DatePicker
                id="inp_toDate"
                className="form-control text-center"
                placeholderText="From Date"
                selected={state.fromDt}
                dateFormat="dd-MM-yyyy"
                onChange={handleChange}
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
              />
            </div>
          </div>

          <div className="col-sm-4" style={{ width: "38%" }}>
            <label
              className="text-left col-sm-5 padding-remove"
              style={{ top: 5 }}
            >
              To Date
            </label>
            <div className="form-group col-sm-7 padding-remove">
              <DatePicker
                id="inp_toDate"
                className="form-control text-center"
                placeholderText="To Date"
                selected={state.toDt}
                onChange={handleChange1}
                dateFormat="dd-MM-yyyy"
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div
            className="col-sm-2 form-group pull-right"
            style={{ width: "18%" }}
          >
            <button
              type="button"
              className="btn btn-info pull-right"
              onClick={(e) => getAttendanceSummary()}
            >
              Get Data
            </button>
          </div>
        </div>
      </div>
      <div className="col-sm-7 col-centered" style={{ top: 25 }}>
        <div className="col-sm-11">
          <div className="tabs-container">
            <div className="bloc-tabs">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(1)}
              >
                Morning
              </button>
              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(2)}
              >
                Evening
              </button>
            </div>
            <div className="content-tabs">
              <div
                className={
                  toggleState === 1
                    ? "body-content active-body-content"
                    : "body-content"
                }
              >
                <Loader loader={displayLoader1} position="absolute" />
                <TptAttendanceTable key={uniqueId}
                  setUniqueId={setUniqueId}
                  uniqueId={uniqueId}
                  lstRoute={lstAttendanceRoute}
                  showSlider={showSlider}
                  setData={setData}
                />
              </div>

              <div
                className={
                  toggleState === 2
                    ? "body-content active-body-content"
                    : "body-content"
                }
              >
                <Loader loader={displayLoader2} position="absolute" />
                <TptAttendanceTable
                   setUniqueId={setUniqueId}
                   uniqueId={uniqueId}
                   lstRoute={lstAttendanceRoute}
                   showSlider={showSlider}
                   setData={setData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1  col-xs-1">
          <span
            className="addIcon glyphicon glyphicon-plus-sign"
            onClick={() => {
              setData(0, { attendanceDt: convert(new Date()), routeId: 0 });
              showSlider();
            }}
          ></span>
        </div>
      </div>
      <div className="col-sm-7 col-centered padding-remove">
        {sliderDisplayed && (
          <Slider
            routeId={routeId}
            dt={dt}
            flgMorEve={flgMorEve}
            closeSlider={closeSlider}
          />
        )}
      </div>
    </div>
  );

  function Slider(props) {
    const [attendanceDt, setAttendanceDt] = useState(null);
    const [lstMstRoute, setLstMstRoute] = useState([]);
    const [routeId, setRouteId] = useState(0);
    const [studentId, setStudentId] = useState(0);
    const [flgMorEve, setFlgMorEve] = useState(false);

    useEffect(() => {
      // console .log(props);
      var dt = props.dt.split("-");
      dt = dt.reverse().join();
      // console .log("dt2 = " + dt);
      dt = new Date(dt);
      setAttendanceDt(dt);

      getLstMstRouteSvc(cbLstMstRoute);
      setFlgMorEve(props.flgMorEve);
      setRouteId(props.routeId);
    }, [props.dt, props.routeId, props.flgMorEve]);

    function cbLstMstRoute(data) {
      if (data.status === "Success") {
        setLstMstRoute(data.lstRoute);
      } else {
        toast.error(data.message);
      }
    }
    return (
      <div
        className="slider halfWidthSlider"
        style={{ opacity: sliderDisplayed ? 1 : 0, right: 0 }}
      >
        <form>
          <div className="col-sm-12">
            <label className="col-sm-3 control-label">
              Date<span style={{ color: "red", marginLeft: 2 }}>*</span>
            </label>
            <div className="form-group">
              <div className="col-sm-2 padding-remove">
                <DatePicker
                  id="attendanceDt"
                  className="form-control"
                  placeholderText="Enter Attendance Date"
                  selected={attendanceDt}
                  onChange={setAttendanceDt}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <label className="control-label col-sm-3">
              Route<span style={{ color: "red", marginLeft: 2 }}>*</span>
            </label>
            <div className="form-group">
              <div className="col-sm-2 padding-remove">
                <select
                  onChange={(e) => setRouteId(e.target.value)}
                  className="form-control"
                  style={{ width: "100%" }}
                  value={routeId}
                  id="cmb_routes"
                >
                  <option>Routes</option>
                  {lstMstRoute.map(function (obj, i) {
                    return (
                      <option key={"r_" + i} value={obj.routeId}>
                        {obj.routeNo}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-10" style={{ height: "32vh" }}>
            <DailyTptAttendanceTable
              studentId={studentId}
              routeId={routeId}
              dtDdMmYyyy={attendanceDt}
              setStudentId={setStudentId}
              generateRandomNo={generateRandomNo}
              key={randNo}
              flgMorEve={flgMorEve}
              closeSlider={props.closeSlider}
            />
          </div>
        </form>
        <button
          type="button"
          className="btn btn-info text-center "
          id="closeSlider"
          onClick={props.closeSlider}
          style={{ opacity: sliderDisplayed ? 1 : 0, right: "70%" }}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
      </div>
    );
  }
};

export default TransportAttendanceCtrl;
