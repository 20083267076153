import React, { useState, useEffect } from "react";
import LblTxt from "./LblTxt";
import DropDown from "./DropDown";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import SliderType from "./sliderType";
import lstModules from "common/json/lstModules.json";

var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;

const RegistrationHistory = (props) => {
  const [regsId, setRegsId] = useState(0);
  const [studentHistory, setStudentHistory] = useState({
    board: "",
    lastPct: "",
    lastSchool: "",
    lastStateId: "0",
    lastCityId: "0",
    lastClsId: "0",
  });
  const [medicalHistory, setMedicalHistory] = useState({
    height: "",
    weight: "",
    bloodGroup: "",
    disease: "",
    ears: "",
    eyes: "",
    allergicTo: "",
  });
  const [lstCls, setLstCls] = useState([]);
  const [lstState, setLstState] = useState([]);
  const [lstCity, setLstCity] = useState([]);
  const [lstLocality, setLstLocality] = useState([]);
  const [loader, setLoader] = useState("none");
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    if (
      props.flgSliderType === SliderType.SLIDER_REGISTER &&
      props.regsId !== 0
    ) {
      getClsListSvc(cbClsListResponse);
      getStateListSvc(cbStateListResponse);
      if (props.regsId) {
        getStudentHistoryDtlsSvc(props.regsId, cbStudentHistoryResponse);
        setLoader("block");
        getMedicalHistoryDtlsSvc(props.regsId, cbMedicalHistoryResponse);
        setRegsId(props.regsId);
      }
    }
    return () => {
      controller.abort();
    };
  }, [props.regsId, props.flgSliderType]);

  function cbStudentHistoryResponse(obj) {
    if (obj.SvcStatus === "Success") {
      getCityListSvc(cbCityListResponse, obj.studentHistory.lastStateId);
      setStudentHistory(obj.studentHistory);
    } else {
      toast.error(obj.SvcMsg);
    }
    setLoader("none");
  }
  function cbMedicalHistoryResponse(obj) {
    if (obj.SvcStatus === "Success") {
      setMedicalHistory(obj.medicalHistory);
    } else {
      toast.error(obj.SvcMsg);
    }
    setLoader("none");
  }

  function getStudentHistoryDtlsSvc(regsId, callBack) {
    axios({
      url: httpURL + "/sms/regs/history/dtls",
      method: "POST",
      params: { regsId: regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getMedicalHistoryDtlsSvc(regsId, callBack) {
    axios({
      url: httpURL + "/sms/regs/medical/dtls",
      method: "POST",
      params: { regsId: regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log("Medical History:" + Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getClsListSvc(callBack) {
    axios({
      url: httpURLCommon + "/sms/mst/cls/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;
        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getStateListSvc(callBack) {
    axios({
      url: httpURLCommon + "/sms/mst/state/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCityListSvc(callBack, pstateId) {
    axios({
      url: httpURLCommon + "/sms/mst/city/list",
      method: "POST",
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getLocalityListSvc(callBack, pcityId) {
    axios({
      url: httpURLCommon + "/sms/mst/locality/list",
      method: "POST",
      params: { cityId: pcityId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function cbClsListResponse(data) {
    // console .log(data);
    if (data.SvcStatus === "Success") {
      setLstCls(data.lstClass);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbStateListResponse(data) {
    // console .log(data);
    if (data.SvcStatus === "Success") {
      setLstState(data.lstState);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  function cbCityListResponse(data) {
    // console .log(data);
    if (data.SvcStatus === "Success") {
      setLstCity(data.lstCity);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbLocalityListResponse(data) {
    // console .log(data);
    if (data.SvcStatus === "Success") {
      setLstLocality(data.lstLocality);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function handleStateChange(pStateId) {
    // console .log(pStateId);
    // console .log(typeof pStateId);
    getCityListSvc(cbCityListResponse, pStateId);
    // console .log({ ...studentHistory, lastStateId: pStateId });
    setStudentHistory({ ...studentHistory, lastStateId: pStateId });
  }
  function handleCityChange(pCityId, pLocalityId) {
    // console .log({ ...studentHistory, lastCityId: pCityId });
    setStudentHistory({ ...studentHistory, lastCityId: pCityId });
    // getLocalityListSvc(cbLocalityListResponse, pCityId)
  }
  function saveHistory() {
    var history = {
      lastSchool: studentHistory.lastSchool,
      lastClsId: studentHistory.lastClsId,
      lastStateId: studentHistory.lastStateId,
      lastCityId: studentHistory.lastCityId,
      lastPct: studentHistory.lastPct,
      board: studentHistory.board,
    };
    setLoader("block");
    saveHistorySvc(history, cbSaveHistoryResponse);
  }
  function cbSaveHistoryResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveHistorySvc(history, callBack) {
    history = JSON.stringify(history);
    axios({
      url: httpURL + "/sms/regs/history/save",
      method: "POST",
      params: { regsId: regsId, studentHistory: history },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function saveMedicalHistory() {
    var medHistory = {
      height: medicalHistory.height,
      weight: medicalHistory.weight,
      bloodGroup: medicalHistory.bloodGroup,
      disease: medicalHistory.disease,
      eyes: medicalHistory.eyes,
      ears: medicalHistory.ears,
      allergicTo: medicalHistory.allergicTo,
    };
    setLoader("block");
    saveMedicalHistorySvc(medHistory, cbSaveMedicalHistoryResponse);
  }
  function cbSaveMedicalHistoryResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveMedicalHistorySvc(medHistory, callBack) {
    medHistory = JSON.stringify(medHistory);
    axios({
      url: httpURL + "/sms/regs/medical/save",
      method: "POST",
      params: { regsId: regsId, medicalHistory: medHistory },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // console .log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function setAllergicTo(pVal) {
    setMedicalHistory({ ...medicalHistory, allergicTo: pVal });
  }
  function setHeight(pVal) {
    setMedicalHistory({ ...medicalHistory, height: pVal });
  }
  function setWeight(pVal) {
    setMedicalHistory({ ...medicalHistory, weight: pVal });
  }
  function setDisease(pVal) {
    setMedicalHistory({ ...medicalHistory, disease: pVal });
  }
  function setEyes(pVal) {
    setMedicalHistory({ ...medicalHistory, eyes: pVal });
  }
  function setEars(pVal) {
    setMedicalHistory({ ...medicalHistory, ears: pVal });
  }
  function setLastSchool(pVal) {
    setStudentHistory({ ...studentHistory, lastSchool: pVal });
  }
  function setLastClsId(pVal) {
    setStudentHistory({ ...studentHistory, lastClsId: pVal });
  }
  function setBloodGrp(pVal) {
    setMedicalHistory({ ...medicalHistory, bloodGroup: pVal });
  }
  function setBoard(pVal) {
    setStudentHistory({ ...studentHistory, board: pVal });
  }
  function setResult(pVal) {
    setStudentHistory({ ...studentHistory, lastPct: pVal });
  }
  return (
    <>
      <div
        className="col-sm-12"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "1%",
        }}
      >
        <div
          className="col-sm-12"
          style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: "fixed",
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: "50%",
              top: "50%",
              display: loader,
            }}
          >
            <img
              className="loderImg"
              src={require("common/images/loader.gif")}
              alt="Loader..."
            />
          </div>
          <div className="col-sm-5" style={{ background: "transparent" }}>
            <LblTxt
              label="Previous School:"
              dataType="text"
              dataValue={studentHistory.lastSchool}
              onChange={setLastSchool}
            ></LblTxt>

            <DropDown
              label="Previous Class:"
              onChangeDropDown={setLastClsId}
              option1="Class"
              lst={lstCls}
              dataValue={"" + studentHistory.lastClsId}
              key={"sh_cls_" + studentHistory.lastClsId}
              keyId="clsId"
              keyVal="cls"
            ></DropDown>

            <DropDown
              label="State:"
              onChangeDropDown={handleStateChange}
              option1="State"
              lst={lstState}
              dataValue={"" + studentHistory.lastStateId}
              key={"sh_state_" + studentHistory.lastStateId}
              keyId="stateId"
              keyVal="state"
            ></DropDown>
            <DropDown
              label="City:"
              onChangeDropDown={handleCityChange}
              option1="City"
              lst={lstCity}
              dataValue={"" + studentHistory.lastCityId}
              key={"sh_city_" + studentHistory.lastCityId}
              keyId="cityId"
              keyVal="city"
            ></DropDown>
            <LblTxt
              label="Board:"
              dataType="text"
              dataValue={studentHistory.board}
              onChange={setBoard}
            ></LblTxt>
            <LblTxt
              label="Result:"
              dataType="text"
              dataValue={studentHistory.lastPct}
              onChange={setResult}
            ></LblTxt>
          </div>
          {!!medicalHistory && (
            <div
              className="col-sm-offset-1 col-sm-5"
              style={{ background: "transparent" }}
            >
              <LblTxt
                label="Height:"
                dataType="text"
                dataValue={medicalHistory.height}
                onChange={setHeight}
              ></LblTxt>
              <LblTxt
                label="Weight:"
                dataType="text"
                dataValue={medicalHistory.weight}
                onChange={setWeight}
              ></LblTxt>
              <LblTxt
                label="Blood Group:"
                dataType="text"
                dataValue={medicalHistory.bloodGroup}
                onChange={setBloodGrp}
              ></LblTxt>
              <LblTxt
                label="Disease:"
                dataType="text"
                dataValue={medicalHistory.disease}
                onChange={setDisease}
              ></LblTxt>
              <LblTxt
                label="Eyes:"
                dataType="text"
                dataValue={medicalHistory.eyes}
                onChange={setEyes}
              ></LblTxt>
              <LblTxt
                label="Ears:"
                dataType="text"
                dataValue={medicalHistory.ears}
                onChange={setEars}
              ></LblTxt>
              <LblTxt
                label="Allergic To:"
                dataType="text"
                dataValue={medicalHistory.allergicTo}
                onChange={setAllergicTo}
              ></LblTxt>
            </div>
          )}
          <div className="col-sm-12">
            <div className="col-sm-5">
              <button
                type="button"
                className="btn-info btn pull-right"
                onClick={(e) => saveHistory()}
              >
                Save
              </button>
            </div>
            <div className="col-sm-offset-1 col-sm-5 padding-remove">
              <button
                type="button"
                className="btn-info btn pull-right"
                onClick={(e) => saveMedicalHistory()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationHistory;
