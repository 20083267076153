import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import "../css/student.css";
import { deleteSiblingSvc, getSiblingsListSvc } from "./studentSiblingSvc";
import StudentSiblingTable from "./studentSiblingTable";
import StudentSiblingForm from "./studentSiblingPopup";


const StudentSiblingDetails = (props) => {
    const { setLoader, setOpacity, studentId, lstClass, flgDisabled } = props;

    const [siblingId, setSiblingId] = useState(0);
    const [lstSiblings, setLstSiblings] = useState([]);
    const [siblingTableKey, setSiblingTableKey] = useState(0);
    const [siblingObj, setSiblingObj] = useState({});
    const [isSiblingFormVisible, setIsSiblingFormVisible] = useState(false);

    useEffect(() => {
        // console .log("StudentSiblingDetails called, studentId", studentId);
        if (!flgDisabled) {
            getSiblingList(studentId);
        }
    }, [studentId, flgDisabled, siblingTableKey]);

    const getSiblingList = (sId) => {
        // console .log("getSiblingList called, sId", sId);
        setLoader("block");
        setOpacity(0.5);
        getSiblingsListSvc(sId, siblingListResponse);
    }

    const siblingListResponse = (response) => {
        // console .log("siblingListResponse called, response", response);
        if (response.status === 'Success') {
            setLstSiblings(response.lstSiblings);
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const addSibling = async () => {
        if(flgDisabled){
            return;
        }
        // console .log("addSibling called");
        await setSiblingId(0);
        setIsSiblingFormVisible(true);
    }

    const editSibling = async (pSibling) => {
        // console .log("editSibling called, pSibling", pSibling);
        await setSiblingObj(pSibling);
        await setSiblingId(pSibling.siblingId);
        setIsSiblingFormVisible(true);
    }

    const closePopup = () => {
        setIsSiblingFormVisible(false);
        setSiblingId(0);
        setSiblingObj({});
    }

    const deleteSibling = (pSiblingId) => {
        // console .log("deleteSibling called, pSiblingId", pSiblingId);
        deleteSiblingSvc(pSiblingId, updateSiblingResponse);
    }

    const updateSiblingResponse = (response) => {
        // console .log("updateSiblingResponse called, response", response);
        if (response.status === 'Success') {
            toast.success(response.message);
            updateKey();
            closePopup();
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const updateKey = () => {
        setSiblingTableKey(prevKey => prevKey + 1);
    }

    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <form
                id="siblingAddForm"
                className="col-sm-12 form-horizontal"
            >
                <div className="col-sm-12 section1">
                    <div className="">
                        <label className="control-label col-sm-4">
                            Siblings
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-10 col-xs-12 col-centered padding-remove" style={{ top: "10px" }} >
                        <div className="col-sm-10 col-xs-10 padding-remove" >
                            <StudentSiblingTable
                                siblingId={siblingId}
                                setSiblingId={setSiblingId}
                                lstSiblings={lstSiblings}
                                editSibling={editSibling}
                                deleteSibling={deleteSibling}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-2">
                            <span
                                id="addSibling"
                                title="Add Sibling"
                                onClick={() => addSibling()}
                                className="addIcon glyphicon glyphicon-plus-sign"
                            ></span>
                        </div>
                    </div>
                </div>
            </form >
            {
                isSiblingFormVisible ?
                    <StudentSiblingForm
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                        studentId={studentId}
                        siblingId={siblingId}
                        siblingObj={siblingObj}
                        closePopup={closePopup}
                        lstCls={lstClass}
                        updateSiblingResponse={updateSiblingResponse}
                    />
                    : null
            }
        </div >
    );
}

export default StudentSiblingDetails;
