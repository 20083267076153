import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import 'common/js/tabs.css'
import Header from 'common/js/header/header'
import DatePicker from 'react-datepicker'
import { toast, ToastContainer } from 'react-toastify'
import { getBannerListSvc, saveBannerSvc, deleteBannerSvc } from './BannerSvc'
import {
  getVisionTextSvc,
  saveVisionTextSvc,
  saveVisionFileSvc
} from './VisionSvc'
import {
  getAwardListSvc,
  saveAwardSvc,
  deleteAwardSvc,
  getRecognitionListSvc,
  saveRecognitionSvc,
  deleteRecognitionSvc
} from './awardSvc'
import {
  getTestimonialTextListSvc,
  saveTestimonialTextSvc,
  deleteTestimonialTextSvc,
  getTestimonialVideoListSvc,
  saveTestimonialVideoSvc,
  deleteTestimonialVideoSvc
} from './testimonialSvc'
var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
let queryString = require('querystring')
var vLstBannerPath = []

export default function WebsiteCtrl () {
  const [state, setState] = useState({
    wingId: '',
    loader: 'none',
    opacity: 1,
    tokenid: localStorage.getItem('tokenid'),
    setupId: 0,
    setupObj: ''
  })
  const [visionText, setVisionText] = useState('')
  const [tabId, setTabId] = useState(1)
  const [visionFile, setVisionFile] = useState(null)
  const [visionPath, setVisionPath] = useState('')
  const [visionURL, setVisionURL] = useState('')
  const [wsId, setWsId] = useState(0);
  const [lstBanner, setLstBanner] = useState([
    { bannerId: 0, bannerPath: 'banner/no-image.png' },
    { bannerId: 0, bannerPath: 'banner/no-image.png' },
    { bannerId: 0, bannerPath: 'banner/no-image.png' }
  ])
  const [lstAward, setLstAward] = useState([])
  const [student, setStudent] = useState('')
  const [cls, setCls] = useState('')
  const [awardText, setAwardText] = useState('')
  const [awardId, setAwardId] = useState(0)
  const [awardDt, setAwardDt] = useState(null)
  const [lstRecognition, setLstRecognition] = useState([])
  const [yr, setYr] = useState('')
  const [recognitionBy, setRecognitionBy] = useState('')
  const [recognitionText, setRecognitionText] = useState('')
  const [recognitionId, setRecognitionId] = useState(0)
  const [recognitionDt, setRecognitionDt] = useState(null)
  const [lstBannerPath, setLstBannerPath] = useState([])
  const [awardUrl, setAwardUrl] = useState('')
  const [awardPath, setAwardPath] = useState('')
  const [awardFile, setAwardFile] = useState('')

  const [testimonialTabId, setTestimonialTabId] = useState(1)
  const [lstTestimonialText, setLstTestimonialText] = useState([])
  const [testimonialText, setTestimonialText] = useState('')
  const [testimonialTextId, setTestimonialTextId] = useState(0)
  const [testimonialBy, setTestimonialBy] = useState('')
  const [testimonialTextDt, setTestimonialTextDt] = useState(null)
  const [relationWithStudent, setRelationWithStudent] = useState('')
  const [lstTestimonialVideo, setLstTestimonialVideo] = useState([])
  const [testimonialVideoPath, setTestimonialVideoPath] = useState('')
  const [testimonialVideoFile, setTestimonialVideoFile] = useState('')
  const [testimonialVideoUrl, setTestimonialVideoUrl] = useState('')
  const [testimonialVideoDt, setTestimonialVideoDt] = useState(null)
  const [testimonialVideoId, setTestimonialVideoId] = useState(0)
  const [testimonialVideoBy, setTestimonialVideoBy] = useState('')

  const [mutedVideo, setMutedVideo] = useState(true)

  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0.5 })
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1 })
  }
  useEffect(() => {
    var dt = new Date()
    setAwardDt(dt)
    getBannerList()
    getVisionDtls()
    getAwardList()
    getRecognitionList()
    getTestimonialTextList()
  }, [])
  function getBannerList () {
    getBannerListSvc(cbBannerListResponse)
  }
  function getVisionDtls () {
    getVisionTextSvc(cbGetVisionTextResponse)
  }
  function getAwardList () {
    getAwardListSvc(cbAwardListResponse)
  }
  function getRecognitionList () {
    getRecognitionListSvc(cbRecognitionListResponse)
  }
  function getTestimonialTextList () {
    getTestimonialTextListSvc(cbTestimonialTextListResponse)
  }
  function getTestimonialVideoList () {
    getTestimonialVideoListSvc(cbTestimonialVideoListResponse)
  }
  function cbTestimonialTextListResponse (data) {
    if (data.status === 'Success') {
      setLstTestimonialText(data.lstTestimonialText)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function cbTestimonialVideoListResponse (data) {
    if (data.status === 'Success') {
      setLstTestimonialVideo(data.lstTestimonialVideo)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function cbBannerListResponse (data) {
    if (data.status === 'Success') {
      var vLstBanner = data.lstBanner
      setLstBanner(vLstBanner)
      setLstBannerPath([])
      vLstBannerPath = []
      for (var i = 0; i < vLstBanner.length; i++) {
        viewBannerSvc(i, vLstBanner[i].bannerPath, cbGetBannerSvc)
      }
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function cbAwardListResponse (data) {
    if (data.status === 'Success') {
      setLstAward(data.lstAward)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function cbGetBannerSvc (pos, url, response) {
    // console .log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      vLstBannerPath[pos] = url
      setLstBannerPath(vLstBannerPath)
    }
    hideLoader()
  }

  function viewBannerSvc (pos, pBannerPath, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', httpURL + '/sms/cms/img/view?imgPath=' + pBannerPath, true)
    xhr.setRequestHeader('tokenid', state.tokenid)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (e) {
      // console .log(xhr)
      var arrayBufferView = new Uint8Array(this.response)
      var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(blob)
      // console .log(blob)
      callback(pos, imageUrl, xhr.response)
    }
    xhr.send()
  }

  const handlePhotoChange = (e, pBannerId, pIdx) => {
    // console .log(e.target.files[0].name)
    // console .log('src:' + URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      // vLstBannerPath[pos] = URL.createObjectURL(e.target.files[0])
      showLoader()
      saveBannerSvc(e.target.files[0], pBannerId, cbSaveBannerResponse)
    }
  }
  const handlePhotoClick = (e, pBannerId) => {
    document.getElementById('img_banner' + pBannerId).click()
  }

  function cbSaveBannerResponse (data, pBannerId) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getBannerListSvc(cbBannerListResponse)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function onErrorBannerImg (pos) {
    // console .log('onError BannerImg :: pos=' + pos)
    vLstBannerPath[pos] = window.appURL + '/no-image.png'
    setLstBannerPath(vLstBannerPath)
  }

  function cbGetVisionTextResponse (data, pos) {
    if (data.SvcStatus === 'Failure') {
      toast.error(data.SvcMsg)
    } else {
      setVisionText(data.visionObj.visionText)
      setVisionPath(data.visionObj.visionPath)
      setWsId(data.visionObj.wsId);
      // console .log("data.visionObj.wsId",data.visionObj.wsId);
      viewImgSvc(data.visionObj.visionPath, cbGetVisionImgResponse)
    }
    hideLoader()
  }
  function viewImgSvc (pPath, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', httpURL + '/sms/cms/img/view?imgPath=' + pPath, true)
    xhr.setRequestHeader('tokenid', state.tokenid)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (e) {
      // console .log(xhr)
      var arrayBufferView = new Uint8Array(this.response)
      var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(blob)
      // console .log(blob)
      callback(imageUrl, xhr.response)
    }
    xhr.send()
  }

  function cbGetVisionImgResponse (url, response) {
    // console .log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      setVisionURL(url)
    }
    hideLoader()
  }
  function onErrorVision (pos) {
    setVisionURL(window.appURL + '/no-image.png')
  }

  const handleVisionImgChange = e => {
    // console .log(e.target.files[0].name)
    // console .log('src:' + URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      // setVisionURL(URL.createObjectURL(e.target.files[0]))
      setVisionFile(e.target.files[0])
      saveVisionFileSvc(wsId, e.target.files[0], cbSaveVisionResponse)
    }
  }
  const handleVisionClick = (e, pos) => {
    document.getElementById('img_vision').click()
  }
  function saveVisionText () {
    saveVisionTextSvc(wsId, visionText, cbSaveVisionResponse)
  }

  function cbSaveVisionResponse (data, pos) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getVisionTextSvc(cbGetVisionTextResponse)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }

  function deleteBanner (pBannerId) {
    // console .log(pBannerId)
    showLoader()
    deleteBannerSvc(pBannerId, cbDeleteBannerResponse)
  }

  function cbDeleteBannerResponse (data) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getBannerListSvc(cbBannerListResponse)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  const colgrpAward = (
    <colgroup>
      <col width='15%'></col>
      <col width='20%'></col>
      <col width='10%'></col>
      <col width='38%'></col>
      <col width='17%'></col>
    </colgroup>
  )
  function setBlankAwardDtls () {
    var dt = new Date()
    var tmpDd = dt.getDate()
    var dd = tmpDd < 9 ? '0' + tmpDd : tmpDd
    var mon = dt.getMonth() + 1
    var mm = mon < 10 ? '0' + mon : mon
    var ddmmyyyy = dd + '-' + mm + '-' + dt.getFullYear()
    setAwardDtls({
      awardId: 0,
      student: '',
      cls: '',
      awardText: '',
      awardDt: ddmmyyyy
    })
  }
  function setAwardDtls (obj) {
    setAwardText(obj.awardText)
    setStudent(obj.student)
    setAwardId(obj.awardId)
    setCls(obj.cls)
    viewImgSvc(obj.awardPath, cbGetAwardImgResponse)
    var dt = obj.awardDt
    var dtparts = dt.split('-')
    var vDt = new Date(dtparts[2], dtparts[1] - 1, dtparts[0])
    setAwardDt(vDt)
  }
  function cbGetAwardImgResponse (url, response) {
    // console .log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      setAwardUrl(url)
    }
    hideLoader()
  }

  const handleAwardImgChange = (e, pAwardId) => {
    // console .log(e.target.files[0].name)
    // console .log('src:' + URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      setAwardUrl(URL.createObjectURL(e.target.files[0]))
      setAwardFile(e.target.files[0])
    }
  }
  const handleAwardImgClick = pAwardId => {
    document.getElementById('img_award' + pAwardId).click()
  }
  function onErrorAwardImg () {
    // console .log('onError AwardImg')

    setAwardUrl(window.appURL + '/no-image.png')
  }

  function saveAward () {
    var awardObj = {
      awardId: awardId,
      student: student,
      cls: cls,
      awardText: awardText,
      awardDt: convert(awardDt)
    }
    var formData = new FormData()
    formData.append('awardObj', JSON.stringify(awardObj))
    formData.append('file', awardFile)
    saveAwardSvc(formData, cbSaveAwardResponse)
  }
  function cbSaveAwardResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getAwardListSvc(cbAwardListResponse)
      setBlankAwardDtls()
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function deleteAward (pAwardId) {
    // console .log(pAwardId)
    showLoader()
    deleteAwardSvc(pAwardId, cbDeleteAwardResponse)
  }

  function cbDeleteAwardResponse (data) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getAwardListSvc(cbAwardListResponse)
      setBlankAwardDtls()
    } else {
      toast.error(data.SvcMsg)
    }
  }
  const colgrpRecognition = (
    <colgroup>
      <col width='15%'></col>
      <col width='12%'></col>
      <col width='40%'></col>
      <col width='17%'></col>
      <col width='16%'></col>
    </colgroup>
  )

  function cbRecognitionListResponse (data) {
    if (data.status === 'Success') {
      setLstRecognition(data.lstRecognition)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function setBlankRecognitionDtls () {
    var dt = new Date()
    var tmpDd = dt.getDate()
    var dd = tmpDd < 9 ? '0' + tmpDd : tmpDd
    var mon = dt.getMonth() + 1
    var mm = mon < 10 ? '0' + mon : mon
    var ddmmyyyy = dd + '-' + mm + '-' + dt.getFullYear()
    setRecognitionDtls({
      recognitionId: 0,
      yr: '',
      recognitionBy: '',
      recognitionText: '',
      recognitionDt: ddmmyyyy
    })
  }
  function setRecognitionDtls (obj) {
    setRecognitionText(obj.recognitionText)
    setYr(obj.yr)
    setRecognitionId(obj.recognitionId)
    setRecognitionBy(obj.recognitionBy)
    var dt = obj.recognitionDt
    var dtparts = dt.split('-')
    var vDt = new Date(dtparts[2], dtparts[1] - 1, dtparts[0])
    setRecognitionDt(vDt)
  }

  function saveRecognition () {
    var recognitionObj = {
      recognitionId: recognitionId,
      yr: yr,
      recognitionBy: recognitionBy,
      recognitionText: recognitionText,
      recognitionDt: convert(recognitionDt)
    }
    var formData = new FormData()
    formData.append('recognitionObj', JSON.stringify(recognitionObj))
    saveRecognitionSvc(formData, cbSaveRecognitionResponse)
  }
  function cbSaveRecognitionResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getRecognitionListSvc(cbRecognitionListResponse)
      setBlankRecognitionDtls()
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }

  function deleteRecognition (pRecognitionId) {
    // console .log(pRecognitionId)
    showLoader()
    deleteRecognitionSvc(pRecognitionId, cbDeleteRecognitionResponse)
  }

  function cbDeleteRecognitionResponse (data) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getRecognitionListSvc(cbRecognitionListResponse)
      setBlankRecognitionDtls()
    } else {
      toast.error(data.SvcMsg)
    }
  }

  const colgrpText = (
    <colgroup>
      <col width='20%'></col>
      <col width='25%'></col>
      <col width='35%'></col>
      <col width='20%'></col>
    </colgroup>
  )

  function setTestimonialTextDtls (obj) {
    // console .log('obj = ' + JSON.stringify(obj))
    setTestimonialText(obj.testimonialText)
    setRelationWithStudent(obj.relationWithStudent)
    setTestimonialTextId(obj.testimonialTextId)
    setTestimonialBy(obj.testimonialBy)

    var dt = obj.testimonialTextDt
    var dtparts = dt.split('-')
    var vDt = new Date(dtparts[2], dtparts[1] - 1, dtparts[0])
    setTestimonialTextDt(vDt)
  }

  function saveTestimonialText () {
    var testimonialTextObj = {
      testimonialTextId: testimonialTextId,
      relationWithStudent: relationWithStudent,
      testimonialBy: testimonialBy,
      testimonialText: testimonialText,
      testimonialTextDt: convert(testimonialTextDt)
    }
    var formData = new FormData()
    formData.append('testimonialTextObj', JSON.stringify(testimonialTextObj))
    saveTestimonialTextSvc(formData, cbSaveTestimonialTextResponse)
  }
  function cbSaveTestimonialTextResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getTestimonialTextListSvc(cbTestimonialTextListResponse)
      setBlankTestimonialTextDtls()
      // setAwardDtls(lstAward[0])
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function setBlankTestimonialTextDtls () {
    var dt = new Date()
    var tmpDd = dt.getDate()
    var dd = tmpDd < 9 ? '0' + tmpDd : tmpDd
    var mon = dt.getMonth() + 1
    var mm = mon < 10 ? '0' + mon : mon
    var ddmmyyyy = dd + '-' + mm + '-' + dt.getFullYear()
    setTestimonialTextDtls({
      testimonialTextId: 0,
      testimonialBy: '',
      testimonialText: '',
      relationWithStudent: '',
      testimonialTextDt: ddmmyyyy
    })
  }
  function deleteTestimonialText (pTestimonialTextId) {
    // console .log(pTestimonialTextId)
    showLoader()
    deleteTestimonialTextSvc(
      pTestimonialTextId,
      cbDeleteTestimonialTextResponse
    )
  }

  function cbDeleteTestimonialTextResponse (data) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getTestimonialTextList()
      setBlankTestimonialTextDtls()
    } else {
      toast.error(data.SvcMsg)
    }
  }

  const colgrpTestimonialVideo = (
    <colgroup>
      <col width='18%'></col>
      <col width='25%'></col>
      <col width='40%'></col>
      <col width='17%'></col>
    </colgroup>
  )
  function setBlankTestimonialVideoDtls () {
    var dt = new Date()
    var tmpDd = dt.getDate()
    var dd = tmpDd < 9 ? '0' + tmpDd : tmpDd
    var mon = dt.getMonth() + 1
    var mm = mon < 10 ? '0' + mon : mon
    var ddmmyyyy = dd + '-' + mm + '-' + dt.getFullYear()
    setTestimonialVideoDtls({
      testimonialVideoId: 0,
      relationWithStudent: '',
      testimonialBy: '',
      testimonialVideoDt: ddmmyyyy,
      testimonialVideoPath: ''
    })
    setTestimonialVideoUrl('')
  }
  function setTestimonialVideoDtls (obj) {
    // console .log(obj)
    setTestimonialVideoBy(obj.testimonialBy)
    setRelationWithStudent(obj.relationWithStudent)
    setTestimonialVideoId(obj.testimonialVideoId)
    setTestimonialVideoPath(obj.testimonialVideoPath)

    var dt = obj.testimonialVideoDt
    var dtparts = dt.split('-')
    var vDt = new Date(dtparts[2], dtparts[1] - 1, dtparts[0])
    setTestimonialVideoDt(vDt)
    viewVideoSvc(obj.testimonialVideoPath, cbGetTestimonialVideoResponse)
  }

  function viewVideoSvc (pVideoPath, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open(
      'GET',
      httpURL + '/sms/cms/video/view?videoPath=' + pVideoPath,
      true
    )
    xhr.setRequestHeader('tokenid', state.tokenid)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (e) {
      // console .log(xhr)
      var arrayBufferView = new Uint8Array(this.response)
      var blob = new Blob([arrayBufferView], { type: 'video/mp4' })
      var urlCreator = window.URL || window.webkitURL
      var videoUrl = urlCreator.createObjectURL(blob)
      // console .log(blob)
      callback(videoUrl, xhr.response)
    }
    xhr.send()
  }
  function cbGetTestimonialVideoResponse (url, response) {
    // console .log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      setTestimonialVideoUrl(url)
    }
    hideLoader()
  }

  const handleTestimonialVideoChange = (e, pTestimonialVideoId) => {
    // console .log(e.target.files[0].name)
    // console .log('src:' + URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      // vLstBannerPath[pos] = URL.createObjectURL(e.target.files[0])
      setTestimonialVideoUrl(URL.createObjectURL(e.target.files[0]))
      setTestimonialVideoFile(e.target.files[0])
    }
  }
  function saveTestimonialVideo () {
    var testimonialVideoObj = {
      testimonialVideoId: testimonialVideoId,
      relationWithStudent: relationWithStudent,
      testimonialBy: testimonialVideoBy,
      testimonialVideoDt: convert(testimonialVideoDt)
    }
    var formData = new FormData()
    formData.append('testimonialVideoObj', JSON.stringify(testimonialVideoObj))
    formData.append('file', testimonialVideoFile)
    saveTestimonialVideoSvc(formData, cbSaveTestimonialVideoResponse)
  }

  function cbSaveTestimonialVideoResponse (data) {
    if (data.SvcStatus === 'Success') {
      setBlankTestimonialVideoDtls()
      toast.success(data.SvcMsg)
      getTestimonialVideoList()
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  const handleTestimonialVideoClick = () => {
    document.getElementById('vdo_testimonial').click()
  }

  function deleteTestimonialVideo (pTestimonialVideoId) {
    // console .log(pTestimonialVideoId)
    showLoader()
    deleteTestimonialVideoSvc(
      pTestimonialVideoId,
      cbDeleteTestimonialVideoResponse
    )
  }

  function cbDeleteTestimonialVideoResponse (data) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getTestimonialVideoList()
      setBlankTestimonialVideoDtls()
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function convert (str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    // console .log([day, mnth, date.getFullYear()].join('-'))
    return [day, mnth, date.getFullYear()].join('-')
  }

  return (
    <div style={{ height: '100%' }}>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: 'none'
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div className='MainContainer' style={{ opacity: state.opacity }}>
        <div className='col-md-12 col-centered wingsWrap clearfix'></div>
        <div className='col-sm-11 col-centered page' style={{ marginTop: 25 }}>
          <div className='pcss3t pcss3t-effect-scale pcss3t-theme-1'>
            <input
              type='radio'
              name='pcss3t'
              defaultChecked={true}
              id='tab1'
              className='tab-content-first'
              onClick={() => getBannerList()}
            />
            <label
              htmlFor='tab1'
              title='Banner'
              onClick={() => getBannerList()}
            >
              <i className='icon-picture'></i>Banner
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab2'
              className='tab-content-2'
              onClick={() => getVisionDtls()}
            />
            <label
              htmlFor='tab2'
              title='Vision'
              onClick={() => getVisionDtls()}
            >
              <i className='icon-vision'></i>Vision
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab3'
              className='tab-content-3'
              onClick={() => getAwardList()}
            />
            <label
              htmlFor='tab3'
              title='Awards and Recognitions'
              onClick={() => getAwardList()}
            >
              <i className='icon-gift'></i>Award/Recognition
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab4'
              className='tab-content-4'
            />
            <label htmlFor='tab4' title='What Parents think about us'>
              <i className='icon-parent-testimonial'></i>Parent Testimonial
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab5'
              className='tab-content-5'
            />
            <label htmlFor='tab5' title='Events'>
              <i className='icon-event'></i>Events
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab6'
              className='tab-content-6'
            />
            <label htmlFor='tab6' title='Admissions'>
              <i className='icon-admission'></i>Admissions
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab7'
              className='tab-content-last'
            />
            <label htmlFor='tab7' title='Faq'>
              <i className='icon-info'></i>Faq
            </label>
            <ul>
              <li className='tab-content tab-content-first typography'>
                {/* <h1>Banners</h1> */}
                {lstBanner && lstBanner.length < 3 && (
                  <div
                    className='col-sm-3'
                    style={{ background: '#ddd', width: 160 }}
                  >
                    <div className='col-sm-12 padding-remove'>
                      <input
                        docid={'doc_' + 0}
                        type='file'
                        id={'img_banner' + 0}
                        name='img'
                        onChange={e => handlePhotoChange(e, 0, 0)}
                        className='hidden'
                        accept='image/*'
                      />

                      <span
                        className='col-centered col-sm-1 glyphicon glyphicon-plus padding-remove'
                        style={{
                          fontSize: '30px',
                          width: '4%',
                          justifyContent: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          height: 90
                        }}
                        onClick={e => handlePhotoClick(e, 0)}
                      ></span>
                    </div>
                  </div>
                )}
                {lstBanner &&
                  lstBanner.length > 0 &&
                  lstBanner.map((bannerObj, idx) => {
                    return (
                      <div className='col-sm-3' key={'banner_' + idx}>
                        <div
                          className='col-sm-12 banner padding-remove'
                          style={{
                            background: '#fff',
                            boxSizing: 'border-box',
                            width: 'auto'
                          }}
                        >
                          <input
                            docid={'doc_' + (idx + 1)}
                            type='file'
                            id={'img_banner' + (idx + 1)}
                            name='img'
                            onChange={e =>
                              handlePhotoChange(e, bannerObj.bannerId, idx)
                            }
                            className='hidden'
                            accept='image/*'
                          />
                          <img
                            src={lstBannerPath[idx]}
                            alt='img'
                            onClick={e =>
                              handlePhotoClick(e, bannerObj.bannerId)
                            }
                            style={{ width: '100%', border: '1px solid #ddd' }}
                            onError={() => onErrorBannerImg(idx)}
                          />
                          <div
                            className='col-sm-12'
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              width: '100%',
                              height: 20,
                              opacity: '0.7',
                              background: '#eee'
                            }}
                          >
                            <div className='pull-right'>
                              <span
                                key={'del_' + idx}
                                id={'del_' + idx}
                                className='glyphicon glyphicon-remove'
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  top: 0,
                                  fontSize: 20,
                                  color: '#aaa',
                                  cursor: 'pointer'
                                }}
                                onClick={e => {
                                  deleteBanner(bannerObj.bannerId)
                                }}
                              ></span>
                              {/* <span
                                className='glyphicon glyphicon-eye-open'
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  top: 0,
                                  fontSize: 20,
                                  color: '#aaa',
                                  cursor: 'pointer'
                                }}
                                onClick={e => viewBanner(idx, lstBannerPath[idx])}
                              ></span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </li>

              <li className='tab-content tab-content-2 typography'>
                {/* <h1>Vision</h1> */}
                <div className='col-sm-12'>
                  <form
                    className='form form-horizontal'
                    style={{
                      width: '100%',
                      margin: '10px auto',
                      padding: 10,
                      background: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: 10,
                      pointerEvents: state.loader === 'none' ? 'auto' : 'none'
                    }}
                  >
                    <div className='row'>
                      <label className='control-label col-md-2 text-left'>
                        Vision:{' '}
                      </label>
                      <div className='form-group col-md-10'>
                        <textarea
                          maxLength={500}
                          rows={6}
                          defaultValue={visionText}
                          className='form-control'
                          onChange={e => setVisionText(e.target.value)}
                        ></textarea>
                      </div>
                      <div className='form-group col-md-12'>
                        <button
                          type='button'
                          className='btn btn-warning pull-right'
                          onClick={e => saveVisionText()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className='col-sm-12'>
                  <form
                    className=' form form-horizontal'
                    style={{
                      width: '100%',
                      margin: '10px auto',
                      padding: 10,
                      background: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: 10,
                      pointerEvents: state.loader === 'none' ? 'auto' : 'none'
                    }}
                  >
                    <div className='row'>
                      <label className='control-label col-md-2 text-left'>
                        Image:{' '}
                      </label>
                      <div
                        className='form-group col-md-4 banner'
                        style={{
                          background: '#fff',
                          boxSizing: 'border-box',
                          width: 'auto'
                        }}
                      >
                        <input
                          docid='vision'
                          type='file'
                          id='img_vision'
                          name='img'
                          onChange={e => handleVisionImgChange(e)}
                          className='hidden'
                          accept='image/*'
                        />
                        <img
                          src={visionURL}
                          alt='img'
                          onClick={e => handleVisionClick(e)}
                          height='160'
                          width='160'
                          style={{ width: 160, border: '1px solid #ddd' }}
                          onError={() => onErrorVision()}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </li>

              <li className='tab-content tab-content-3 typography'>
                <div
                  className='row'
                  /* style={{
                    boxShadow: 'rgb(204, 204, 204) 0px 1px 4px 0px',
                    borderWidth: '1px 0px',
                    borderStyle: 'solid',
                    height: 'auto',
                    borderColor: 'rgb(204, 204, 204)',
                    padding: '5px 20px'
                  }} */
                >
                  <div className='tab' style={{ margin: '10px auto' }}>
                    <div
                      id='tabName1'
                      className={tabId == 1 ? 'seltab' : ''}
                      style={{ pointerEvents: 'auto', opacity: 1 }}
                      onClick={e => {
                        setTabId(1)
                        getAwardList()
                      }}
                    >
                      <label
                        htmlFor='tabName1'
                        title='Award'
                        onClick={e => {
                          setTabId(1)
                          getAwardList()
                        }}
                      >
                        <i className='icon-gift' style={{ padding: 15 }}></i>
                        Award
                      </label>
                    </div>
                    <div
                      id='tabName2'
                      className={tabId == 2 ? 'seltab' : ''}
                      style={{ pointerEvents: 'auto', opacity: 1 }}
                      onClick={e => {
                        setTabId(2)
                        getRecognitionList()
                      }}
                    >
                      <label
                        htmlFor='tabName2'
                        title='Recognition'
                        onClick={e => {
                          setTabId(2)
                          getRecognitionList()
                        }}
                      >
                        <i
                          className='icon-certificate'
                          style={{ padding: 15 }}
                        ></i>
                        Recognition
                      </label>
                    </div>
                  </div>
                  {tabId == 1 && (
                    <div className='col-sm-12 padding-remove'>
                      <div className='col-sm-7 padding-remove'>
                        <table className='tableLayout1'>
                          {colgrpAward}
                          <thead>
                            <tr>
                              <th>Award Dt</th>
                              <th>Student</th>
                              <th>Class</th>
                              <th>Award</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                        </table>
                        <div
                          className=''
                          style={{ height: '300px', overflowY: 'scroll' }}
                        >
                          <table className='tableLayout'>
                            {colgrpAward}
                            <tbody>
                              {lstAward &&
                                lstAward.length > 0 &&
                                lstAward.map((awardObj, idx) => {
                                  return (
                                    <tr
                                      key={idx}
                                      onClick={() => setAwardDtls(awardObj)}
                                      className={
                                        awardObj.awardId === awardId
                                          ? 'selected'
                                          : ''
                                      }
                                    >
                                      <td className='text-center'>
                                        {awardObj.awardDt}
                                      </td>
                                      <td className='text-left'>
                                        {awardObj.student}
                                      </td>
                                      <td className='text-left'>
                                        {awardObj.cls}
                                      </td>
                                      <td className='text-left'>
                                        {awardObj.awardText}
                                      </td>
                                      <td className='text-center'>
                                        <span
                                          className='glyphicon glyphicon-edit'
                                          onClick={e => setAwardDtls(awardObj)}
                                        ></span>
                                        <span
                                          className='glyphicon glyphicon-remove'
                                          onClick={() =>
                                            deleteAward(awardObj.awardId)
                                          }
                                        ></span>
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <span
                        className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                        style={{ fontSize: '30px', width: '4%' }}
                        onClick={e => {
                          setBlankAwardDtls()
                        }}
                      ></span>
                      <div className='col-sm-4' style={{ width: '37%' }}>
                        <form
                          className='form form-horizontal'
                          style={{
                            width: '100%',
                            margin: '10px auto',
                            padding: 10,
                            background: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: 10,
                            pointerEvents:
                              state.loader === 'none' ? 'auto' : 'none'
                          }}
                        >
                          <div className='row'>
                            <label className='control-label col-md-3 text-left'>
                              Student
                            </label>
                            <div className='form-group col-md-9'>
                              <input
                                type='text'
                                defaultValue={student}
                                className='form-control'
                                onChange={e => setStudent(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-3 text-left'>
                              Class
                            </label>
                            <div className='form-group col-md-4'>
                              <input
                                type='text'
                                defaultValue={cls}
                                className='form-control'
                                onChange={e => setCls(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-3 text-left'>
                              Award
                            </label>
                            <div className='form-group col-md-9'>
                              <textarea
                                rows='6'
                                value={awardText}
                                className='form-control'
                                onChange={e => setAwardText(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-3 text-left'>
                              Date:
                            </label>
                            <div className='form-group col-md-9'>
                              <div className='col-md-6 padding-remove'>
                                <DatePicker
                                  id='inp_date'
                                  className='form-control text-center'
                                  placeholderText='Date'
                                  selected={awardDt}
                                  onChange={setAwardDt}
                                  dateFormat='dd-MM-yyyy'
                                  autoComplete='off'
                                  showYearDropdown
                                  showMonthDropdown
                                  dropdownMode='select'
                                />
                              </div>
                            </div>

                            <div className='col-md-9 pull-right padding-remove'>
                              Content will be displayed in chronological order
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-offset-3 col-sm-6 padding-remove'>
                              <input
                                type='file'
                                id={'img_award' + awardId}
                                name='img'
                                onChange={e => handleAwardImgChange(e, awardId)}
                                className='hidden'
                                accept='image/*'
                              />
                              <img
                                src={awardUrl}
                                alt='img'
                                onClick={e => handleAwardImgClick(awardId)}
                                width='160'
                                style={{ width: 160, border: '1px solid #ddd' }}
                                onError={() => onErrorAwardImg()}
                              />
                            </div>
                            <div className='form-group col-md-12'>
                              <button
                                type='button'
                                className='btn btn-warning pull-right'
                                onClick={e => saveAward()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {tabId == 2 && (
                    <div className='col-sm-12 padding-remove'>
                      <div className='col-sm-7 padding-remove'>
                        <table className='tableLayout1'>
                          {colgrpRecognition}
                          <thead>
                            <tr>
                              <th>Recognition Dt</th>
                              <th>Year</th>
                              <th>Recognition</th>
                              <th>Recognized By</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                        </table>
                        <div
                          className=''
                          style={{ height: '300px', overflowY: 'scroll' }}
                        >
                          <table className='tableLayout'>
                            {colgrpRecognition}
                            <tbody>
                              {lstRecognition &&
                                lstRecognition.length > 0 &&
                                lstRecognition.map((recognitionObj, idx) => {
                                  return (
                                    <tr
                                      key={idx}
                                      id={
                                        'recog_' + recognitionObj.recognitionId
                                      }
                                      onClick={() =>
                                        setRecognitionDtls(recognitionObj)
                                      }
                                      className={
                                        recognitionObj.recognitionId ===
                                        recognitionId
                                          ? 'selected'
                                          : ''
                                      }
                                    >
                                      <td className='text-center'>
                                        {recognitionObj.recognitionDt}
                                      </td>
                                      <td className='text-left'>
                                        {recognitionObj.yr}
                                      </td>
                                      <td className='text-left'>
                                        {recognitionObj.recognitionText}
                                      </td>
                                      <td className='text-left'>
                                        {recognitionObj.recognitionBy}
                                      </td>
                                      <td className='text-center'>
                                        <span
                                          className='glyphicon glyphicon-edit'
                                          onClick={e =>
                                            setRecognitionDtls(recognitionObj)
                                          }
                                        ></span>
                                        <span
                                          className='glyphicon glyphicon-remove'
                                          onClick={() =>
                                            deleteRecognition(
                                              recognitionObj.recognitionId
                                            )
                                          }
                                        ></span>
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <span
                        className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                        style={{ fontSize: '30px', width: '4%' }}
                        onClick={e => {
                          setBlankRecognitionDtls()
                        }}
                      ></span>
                      <div className='col-sm-4' style={{ width: '37%' }}>
                        <form
                          className='form form-horizontal'
                          style={{
                            width: '100%',
                            margin: '10px auto',
                            padding: 10,
                            background: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: 10,
                            pointerEvents:
                              state.loader === 'none' ? 'auto' : 'none'
                          }}
                        >
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Year:
                            </label>
                            <div className='form-group col-md-4'>
                              <input
                                type='text'
                                defaultValue={yr}
                                className='form-control'
                                onChange={e => setYr(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Recognition By:
                            </label>
                            <div className='form-group col-md-8'>
                              <input
                                type='text'
                                defaultValue={recognitionBy}
                                className='form-control'
                                onChange={e => setRecognitionBy(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Recognition:
                            </label>
                            <div className='form-group col-md-8'>
                              <textarea
                                rows='6'
                                value={recognitionText}
                                className='form-control'
                                onChange={e =>
                                  setRecognitionText(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Date:
                            </label>
                            <div className='form-group col-md-6'>
                              <DatePicker
                                id='inp_date'
                                className='form-control text-center'
                                placeholderText='Date'
                                selected={recognitionDt}
                                onChange={setRecognitionDt}
                                dateFormat='dd-MM-yyyy'
                                autoComplete='off'
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                              />
                            </div>
                            <div className='col-sm-8 pull-right padding-remove'>
                              Content will be displayed in chronological order
                            </div>
                            <div className='form-group col-md-12'>
                              <button
                                type='button'
                                className='btn btn-warning pull-right'
                                onClick={e => saveRecognition()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </li>

              <li className='tab-content tab-content-4 typography'>
                <div
                  className='row'
                  /* style={{
                    boxShadow: 'rgb(204, 204, 204) 0px 1px 4px 0px',
                    borderWidth: '1px 0px',
                    borderStyle: 'solid',
                    height: 'auto',
                    borderColor: 'rgb(204, 204, 204)',
                    padding: '5px 20px'
                  }} */
                >
                  <div className='tab' style={{ margin: '10px auto' }}>
                    <div
                      id='ttabName1'
                      className={testimonialTabId == 1 ? 'seltab' : ''}
                      style={{ pointerEvents: 'auto', opacity: 1 }}
                      onClick={e => {
                        setTestimonialTabId(1)
                        getTestimonialTextList()
                      }}
                    >
                      <label
                        htmlFor='ttabName1'
                        title='Text'
                        onClick={e => {
                          setTestimonialTabId(1)
                          getTestimonialTextList()
                        }}
                      >
                        <i className='icon-file' style={{ padding: 15 }}></i>
                        Text
                      </label>
                    </div>
                    <div
                      id='ttabName2'
                      className={testimonialTabId == 2 ? 'seltab' : ''}
                      style={{ pointerEvents: 'auto', opacity: 1 }}
                      onClick={e => {
                        setTestimonialTabId(2)
                        getTestimonialVideoList()
                      }}
                    >
                      <label
                        htmlFor='ttabName2'
                        title='Video'
                        onClick={e => {
                          setTestimonialTabId(2)
                          getTestimonialVideoList()
                        }}
                      >
                        <i
                          className='icon-facetime-video'
                          style={{ padding: 15 }}
                        ></i>
                        Video
                      </label>
                    </div>
                  </div>
                  {testimonialTabId == 1 && (
                    <div className='col-sm-12 padding-remove'>
                      <div className='col-sm-6 padding-remove'>
                        <table className='tableLayout1'>
                          {colgrpText}
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Testimonial By</th>
                              <th>Relation with Student</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                        </table>
                        <div
                          className=''
                          style={{ height: '300px', overflowY: 'scroll' }}
                        >
                          <table className='tableLayout'>
                            {colgrpText}
                            <tbody>
                              {lstTestimonialText &&
                                lstTestimonialText.length > 0 &&
                                lstTestimonialText.map(
                                  (testimonialTextObj, idx) => {
                                    return (
                                      <tr
                                        key={idx}
                                        onClick={() =>
                                          setTestimonialTextDtls(
                                            testimonialTextObj
                                          )
                                        }
                                        className={
                                          testimonialTextObj.testimonialTextId ===
                                          testimonialTextId
                                            ? 'selected'
                                            : ''
                                        }
                                      >
                                        <td className='text-center'>
                                          {testimonialTextObj.testimonialTextDt}
                                        </td>
                                        <td className='text-left'>
                                          {testimonialTextObj.testimonialBy}
                                        </td>

                                        <td className='text-left'>
                                          {
                                            testimonialTextObj.relationWithStudent
                                          }
                                        </td>
                                        <td className='text-center'>
                                          <span
                                            className='glyphicon glyphicon-edit'
                                            onClick={e =>
                                              setTestimonialTextDtls(
                                                testimonialTextObj
                                              )
                                            }
                                          ></span>
                                          <span
                                            className='glyphicon glyphicon-remove'
                                            onClick={() =>
                                              deleteTestimonialText(
                                                testimonialTextObj.testimonialTextId
                                              )
                                            }
                                          ></span>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <span
                        className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                        style={{ fontSize: '30px', width: '4%' }}
                        onClick={e => {
                          setBlankTestimonialTextDtls()
                        }}
                      ></span>
                      <div className='col-sm-6' style={{ width: '46%' }}>
                        <form
                          className='form form-horizontal'
                          style={{
                            width: '100%',
                            margin: '10px auto',
                            padding: 10,
                            background: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: 10,
                            pointerEvents:
                              state.loader === 'none' ? 'auto' : 'none'
                          }}
                        >
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Testimonial By:
                            </label>
                            <div className='form-group col-md-8'>
                              <input
                                type='text'
                                value={testimonialBy}
                                className='form-control'
                                onChange={e => setTestimonialBy(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Relation with student:
                            </label>
                            <div className='form-group col-md-8'>
                              <input
                                type='text'
                                value={relationWithStudent}
                                className='form-control'
                                onChange={e =>
                                  setRelationWithStudent(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Testimonial:
                            </label>
                            <div className='form-group col-md-8'>
                              <textarea
                                rows='6'
                                value={testimonialText}
                                className='form-control'
                                onChange={e =>
                                  setTestimonialText(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4 text-left'>
                              Date:
                            </label>
                            <div className='form-group col-md-6'>
                              <DatePicker
                                id='inp_date'
                                className='form-control text-center'
                                placeholderText='Date'
                                selected={testimonialTextDt}
                                onChange={setTestimonialTextDt}
                                dateFormat='dd-MM-yyyy'
                                autoComplete='off'
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                              />
                            </div>
                            <div className='col-sm-8 pull-right padding-remove'>
                              Content will be displayed in chronological order
                            </div>
                            <div className='form-group col-md-12'>
                              <button
                                type='button'
                                className='btn btn-warning pull-right'
                                onClick={e => saveTestimonialText()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {testimonialTabId == 2 && (
                    <div className='col-sm-12 padding-remove'>
                      <div className='col-sm-6 padding-remove'>
                        <table className='tableLayout1'>
                          {colgrpTestimonialVideo}
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Testimonial By</th>
                              <th>Relation with Student</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                        </table>
                        <div
                          className=''
                          style={{ height: '300px', overflowY: 'scroll' }}
                        >
                          <table className='tableLayout'>
                            {colgrpTestimonialVideo}
                            <tbody>
                              {lstTestimonialVideo &&
                                lstTestimonialVideo.length > 0 &&
                                lstTestimonialVideo.map(
                                  (testimonialVideoObj, idx) => {
                                    return (
                                      <tr
                                        key={idx}
                                        id={
                                          'tVideoObj_' +
                                          testimonialVideoObj.testimonialVideoId
                                        }
                                        onClick={() =>
                                          setTestimonialVideoDtls(
                                            testimonialVideoObj
                                          )
                                        }
                                        className={
                                          testimonialVideoObj.testimonialVideoId ===
                                          testimonialVideoId
                                            ? 'selected'
                                            : ''
                                        }
                                      >
                                        <td className='text-center'>
                                          {
                                            testimonialVideoObj.testimonialVideoDt
                                          }
                                        </td>
                                        <td className='text-left'>
                                          {testimonialVideoObj.testimonialBy}
                                        </td>
                                        <td className='text-left'>
                                          {
                                            testimonialVideoObj.relationWithStudent
                                          }
                                        </td>
                                        <td className='text-center'>
                                          <span
                                            className='glyphicon glyphicon-edit'
                                            onClick={e =>
                                              setTestimonialVideoDtls(
                                                testimonialVideoObj
                                              )
                                            }
                                          ></span>
                                          <span
                                            className='glyphicon glyphicon-remove'
                                            onClick={() =>
                                              deleteTestimonialVideo(
                                                testimonialVideoObj.testimonialVideoId
                                              )
                                            }
                                          ></span>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <span
                        className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                        style={{ fontSize: '30px', width: '4%' }}
                        onClick={e => {
                          setBlankTestimonialVideoDtls()
                        }}
                      ></span>
                      <div className='col-sm-5' style={{ width: '46%' }}>
                        <form
                          className='form form-horizontal'
                          style={{
                            width: '100%',
                            margin: '10px auto',
                            padding: 10,
                            background: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: 10,
                            pointerEvents:
                              state.loader === 'none' ? 'auto' : 'none'
                          }}
                        >
                          <div className='row'>
                            <label className='control-label col-md-4'>
                              Testimonial By:
                            </label>
                            <div className='form-group col-md-8'>
                              <input
                                type='text'
                                value={testimonialVideoBy}
                                className='form-control'
                                onChange={e =>
                                  setTestimonialVideoBy(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4'>
                              Relation with Student:
                            </label>
                            <div className='form-group col-md-8'>
                              <input
                                type='text'
                                value={relationWithStudent}
                                className='form-control'
                                onChange={e =>
                                  setRelationWithStudent(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-offset-1 col-md-8 pull-right'>
                              <video
                                src={testimonialVideoUrl}
                                id={'myVideo' + testimonialVideoId}
                                muted={mutedVideo}
                                autoPlay={true}
                                loop={false}
                                type={'video/mp4'}
                                className='col-md-12 pull-right'
                                controls
                              />
                              <div className='form-group'>
                                <div className='col-sm-12 text-right'>
                                  <button
                                    type='button'
                                    onClick={e => setMutedVideo(true)}
                                    className={
                                      mutedVideo
                                        ? 'btn btn-warning active'
                                        : 'btn btn-warning'
                                    }
                                  >
                                    Mute
                                  </button>
                                  <button
                                    type='button'
                                    onClick={e => setMutedVideo(false)}
                                    className={
                                      !mutedVideo
                                        ? 'btn btn-warning active'
                                        : 'btn btn-warning'
                                    }
                                  >
                                    Unmute
                                  </button>
                                </div>
                              </div>
                              <button
                                type='button'
                                className='btn btn-info'
                                onClick={e => handleTestimonialVideoClick()}
                              >
                                Browse
                              </button>
                              <input
                                docid='testimonialvdo'
                                type='file'
                                id={'vdo_testimonial'}
                                name='vdo_testimonial'
                                onChange={e =>
                                  handleTestimonialVideoChange(
                                    e,
                                    testimonialVideoId
                                  )
                                }
                                className='btn btn-info form-control-file hidden'
                                accept='video/*'
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <label className='control-label col-md-4'>
                              Date:
                            </label>
                            <div className='form-group col-md-6'>
                              <DatePicker
                                id='inp_date'
                                className='form-control text-center'
                                placeholderText='Date'
                                selected={testimonialVideoDt}
                                onChange={setTestimonialVideoDt}
                                dateFormat='dd-MM-yyyy'
                                autoComplete='off'
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                              />
                            </div>
                            <div className='col-sm-6 pull-right padding-remove'>
                              Content will be displayed in chronological order
                            </div>
                            <div className='form-group col-md-12'>
                              <button
                                type='button'
                                className='btn btn-warning pull-right'
                                onClick={e => saveTestimonialVideo()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </li>

              <li className='tab-content tab-content-5 typography'>
                <div className='typography'>
                  <h1>Isaac Newton5</h1>
                  <p>
                    English physicist and mathematician who is widely regarded
                    as one of the most influential scientists of all time and as
                    a key figure in the scientific revolution. His book
                    Philosophiæ Naturalis Principia Mathematica ("Mathematical
                    Principles of Natural Philosophy"), first published in 1687,
                    laid the foundations for most of classical mechanics. Newton
                    also made seminal contributions to optics and shares credit
                    with Gottfried Leibniz for the invention of the
                    infinitesimal calculus.
                  </p>
                  <p>
                    Newton's Principia formulated the laws of motion and
                    universal gravitation that dominated scientists' view of the
                    physical universe for the next three centuries. It also
                    demonstrated that the motion of objects on the Earth and
                    that of celestial bodies could be described by the same
                    principles. By deriving Kepler's laws of planetary motion
                    from his mathematical description of gravity, Newton removed
                    the last doubts about the validity of the heliocentric model
                    of the cosmos.
                  </p>
                  <p className='text-right'>
                    <em>
                      Find out more about Isaac Newton from{' '}
                      <a
                        href='http://en.wikipedia.org/wiki/Isaac_Newton'
                        target='_blank'
                      >
                        Wikipedia
                      </a>
                      .
                    </em>
                  </p>
                </div>
              </li>
              <li className='tab-content tab-content-6 typography'>
                <div className='typography'>
                  <h1>Isaac Newton6</h1>
                  <p>
                    English physicist and mathematician who is widely regarded
                    as one of the most influential scientists of all time and as
                    a key figure in the scientific revolution. His book
                    Philosophiæ Naturalis Principia Mathematica ("Mathematical
                    Principles of Natural Philosophy"), first published in 1687,
                    laid the foundations for most of classical mechanics. Newton
                    also made seminal contributions to optics and shares credit
                    with Gottfried Leibniz for the invention of the
                    infinitesimal calculus.
                  </p>
                  <p>
                    Newton's Principia formulated the laws of motion and
                    universal gravitation that dominated scientists' view of the
                    physical universe for the next three centuries. It also
                    demonstrated that the motion of objects on the Earth and
                    that of celestial bodies could be described by the same
                    principles. By deriving Kepler's laws of planetary motion
                    from his mathematical description of gravity, Newton removed
                    the last doubts about the validity of the heliocentric model
                    of the cosmos.
                  </p>
                  <p className='text-right'>
                    <em>
                      Find out more about Isaac Newton from{' '}
                      <a
                        href='http://en.wikipedia.org/wiki/Isaac_Newton'
                        target='_blank'
                      >
                        Wikipedia
                      </a>
                      .
                    </em>
                  </p>
                </div>
              </li>
              <li className='tab-content tab-content-last typography'>
                <div className='typography'>
                  <h1>Isaac Newton Last</h1>
                  <p>
                    English physicist and mathematician who is widely regarded
                    as one of the most influential scientists of all time and as
                    a key figure in the scientific revolution. His book
                    Philosophiæ Naturalis Principia Mathematica ("Mathematical
                    Principles of Natural Philosophy"), first published in 1687,
                    laid the foundations for most of classical mechanics. Newton
                    also made seminal contributions to optics and shares credit
                    with Gottfried Leibniz for the invention of the
                    infinitesimal calculus.
                  </p>
                  <p>
                    Newton's Principia formulated the laws of motion and
                    universal gravitation that dominated scientists' view of the
                    physical universe for the next three centuries. It also
                    demonstrated that the motion of objects on the Earth and
                    that of celestial bodies could be described by the same
                    principles. By deriving Kepler's laws of planetary motion
                    from his mathematical description of gravity, Newton removed
                    the last doubts about the validity of the heliocentric model
                    of the cosmos.
                  </p>
                  <p className='text-right'>
                    <em>
                      Find out more about Isaac Newton from{' '}
                      <a
                        href='http://en.wikipedia.org/wiki/Isaac_Newton'
                        target='_blank'
                      >
                        Wikipedia
                      </a>
                      .
                    </em>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
