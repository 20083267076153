import React, { useEffect, useState } from 'react'

import "common/css/master.css";

const TcTable = (props) => {
    const { certificateId, setStudentId, setCertificateId, editCertificate, lstCertificate, printCertificate, deleteCertificate, setTcCount } = props;

    const [lstCertificateFiltered, setLstCertificateFiltered] = useState([]);
    const [studentFiltered, setStudentFiltered] = useState([]);
    const [classSectionFiltered, setClassSectionFiltered] = useState([]);
    const [statusFiltered, setStatusFiltered] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const mapStatus = {
        10: 'PENDING',
        20: 'APPROVED',
        30: 'REJECTED',
        40: 'COMPLETED'
    };

    const colgrp = <colgroup>
        <col width="40"></col>
        <col width="100"></col>
        <col width="60"></col>
        <col width="80"></col>
        <col width="90"></col>
        <col width="50"></col>
        <col width="50"></col>
        <col width="50"></col>
        <col width="60"></col>
        <col width="100"></col>
    </colgroup>

    useEffect(() => {
        //// console .log("TcTable called");
        setLstCertificateFiltered(lstCertificate);
        setStudentFiltered("");
        setClassSectionFiltered("");
        setTcCount(lstCertificate.length);
    }, [lstCertificate]);

    const filterStatus = (fStatus) => {
        console.log("filterStatus called, fStatus", fStatus);
        setStatusFiltered(fStatus);
        filterCertificateTable(studentFiltered, classSectionFiltered, fStatus);
    }

    const filterStudentName = (fStudent) => {
        //// console .log("filterStudentName called, fStudent", fStudent);
        setStudentFiltered(fStudent);
        filterCertificateTable(fStudent, classSectionFiltered, statusFiltered);
    }

    const filterClassSection = (fClassSection) => {
        //// console .log('filterClassSection called, fClassSection', fClassSection);
        setClassSectionFiltered(fClassSection);
        filterCertificateTable(studentFiltered, fClassSection, statusFiltered);
    }

    const filterCertificateTable = (fStudent, fClassSection, fStatus) => {
        console.log(`filterCertificateTable called with fStudent: ${fStudent}, fClassSection: ${fClassSection}, fStatus: ${fStatus}`);

        let filteredCertificateTable = [];

        // Filter by student name
        filteredCertificateTable = lstCertificate.filter(student =>
            student.student.toString().toLowerCase().includes(fStudent.toString().toLowerCase())
        );

        // Filter by class section
        filteredCertificateTable = filteredCertificateTable.filter(student =>
            student.clsSection === null ||
            student.clsSection === undefined ||
            student.clsSection.toLowerCase().includes(fClassSection.toString().toLowerCase())
        );

        // Filter by certificate status, converting fStatus to a number for comparison
        if (!!fStatus && fStatus !== 0 && fStatus !== "0"){
            filteredCertificateTable = filteredCertificateTable.filter(student =>
                student.certStatusId === null ||
                student.certStatusId === undefined ||
                student.certStatusId === Number(fStatus) // Ensure fStatus is compared as a number
            );
        }

        setLstCertificateFiltered(filteredCertificateTable);
    }

    const handleCertificateChange = (pCertId, pStudId) => {
        setCertificateId(pCertId);
        setStudentId(pStudId);
    }

    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Student Id
                            </th>
                            <th >
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={studentFiltered}
                                    onChange={(e) => filterStudentName(e.target.value)}
                                    name="student name"
                                    placeholder="Student" />
                            </th>
                            <th >
                                Enrollment No
                            </th>
                            <th >
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={classSectionFiltered}
                                    onChange={(e) => filterClassSection(e.target.value)}
                                    name="Class Section"
                                    placeholder="Class" />
                            </th>
                            <th >
                                Father
                            </th>
                            <th >
                                Request Date
                            </th>
                            <th >
                                Approved Date
                            </th>
                            <th >
                                PrintDate
                            </th>
                            <th >
                                <select
                                    autoComplete="off"
                                    name="status"
                                    type="text"
                                    className="form-control inp-search text-centered"
                                    value={statusFiltered}
                                    onChange={(e) => filterStatus(e.target.value)}
                                >
                                    <option value="0">Status</option>
                                    {Object.entries(mapStatus).map(([key, value]) => (
                                        <option key={key} value={key}>
                                            {value}
                                        </option>
                                    ))}
                                </select>

                            </th>
                            <th >
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "70vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="tcBodyTable" >
                            {lstCertificateFiltered &&
                                lstCertificateFiltered.map((student, i) => (
                                    <tr key={student.certificateId}
                                        onClick={() => handleCertificateChange(student.certificateId, student.studentId)}
                                        className={student.certificateId === certificateId ? "selected" : ''}>
                                        <td className="text-center">{student.studentId}</td>
                                        <td className="text-left">{student.student}</td>
                                        <td className="text-left">{student.enrollmentNo}</td>
                                        <td className="text-left">{student.clsSection}</td>
                                        <td className="text-left">{student.father}</td>
                                        <td className="text-center">{student.reqDt}</td>
                                        <td className="text-center">{student.approveDt}</td>
                                        <td className="text-center">{student.printDt}</td>
                                        <td className="text-center">{mapStatus[student.certStatusId]}</td>
                                        <td className="text-center" >
                                            <span
                                                onClick={() => editCertificate(student.certificateId, student.studentId)}
                                                title="Edit"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Edit
                                            </span>
                                            <span
                                                onClick={() => deleteCertificate(student.studentId)}
                                                title="Delete"
                                                style={{ textDecoration: "underline", pointerEvents: !deleteDisabled ? "" : "none", cursor: !deleteDisabled ? "pointer" : "not-allowed", color: "blue", margin: "5px" }}
                                            >
                                                Delete
                                            </span>
                                            <span
                                                onClick={() => printCertificate(student.studentId)}
                                                title="Print"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Print
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TcTable;