import React, { useEffect, useState } from 'react';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import TTSubjectTblBody from './ttSubjectTblBody';
import { getSubList } from './ttSubServices';
var queryString = require('querystring')

// var classId = 4;
// var sessionId = 1;
// var sectionId = 29;

const TTSubjectTbl = (props) => {
    const [lstSub, setLstSub] = useState(props.lstSub)
    const [classId, setClassId] = useState(props.classId);
    const [sectionId, setSectionId] = useState(props.sectionId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [session, setSession] = useState(props.selSession);
    const [selectedSubjectId, setSelectedSubjectId] = useState(0)
    useEffect(() => {
        // setSubjectTable(handleSubjectTableResponse, sessionId, classId, sectionId);
        setClassId(props.classId);
        setSectionId(props.sectionId);
        setSessionId(props.sessionId);
        setSession(props.selSession);
        setLstSub(props.lstSub);
    }, []);

    function handleSubjectTableResponse(data) {
        var obj = data;
        if (obj.SvcStatus === 'Success') {
            setLstSub(obj.lstSub);
        } else if (obj.status === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }
    /* function setSubjectTable(handleSubjectTableResponse, sessionId, classId, sectionId) {
        getSubList(handleSubjectTableResponse, sessionId, classId, sectionId);
    } */
    const rowClickInMain = (pSubId) => {
        props.setCellSubId(pSubId)
        setSelectedSubjectId(pSubId)
        props.setPosDayPeriod();
        // var subId = e.currentTarget.getAttribute("subId");
        // console .log(subId)
    }
    // return (
    //     <div style={{ height: '100%' }}>
    //         <div className="col-md-11 col-centered SubjectWrap clearfix" >
    //             {session && <TTSubjectTblBody
    //                 sessionId={sessionId}
    //                 session={session}
    //                 lstSub={lstSub}
    //                 rowClick={rowClickInMain}
    //                 selSubId={lstSub.SubId}
    //                 selectedSubjectId={selectedSubjectId} />}
    //         </div>
    //     </div>
    // );
}

export default TTSubjectTbl;