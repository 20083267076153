import React, { useState, useEffect, useRef } from 'react'
import ExamReportCardPDF from './examReportCardPDF.js';
import { getLogoSvc, getPrinciSignSvc, getExamReportSvc } from './examReportSvc.js';
// , getDocDtlsSvc
import { toast, ToastContainer } from 'react-toastify';
import lstModules from 'common/json/lstModules.json';
import NoImage from "images/no-image.png";





const ExamReportSlider = (props) => {

  const { heading, sectionId, examId, exam, syllabusId, flgValidated } = props;

  const [reportCard, setReportCard] = useState({});
  const [logoUrl, setLogoUrl] = useState('');
  // const [imageUrl, setImageUrl] = useState("");
  const [loader, setLoader] = useState("none");
  const [opacity, setOpacity] = useState(1);
  const [principalSignUrl, setPrincipalSignUrl] = useState("");
  const [teacherSignUrl, setTeacherSignUrl] = useState("");
  // const [flgTeacherSignUpdated, setFlgTeaherSignUpdated] = useState(false);


  useEffect(() => {
    // console .log("ExamReportSlider Called heading ", props.heading, ", sectionId ", props.sectionId);
    // console .log("teacherSignUrl", props.teacherSignUrl);

    setTeacherSignUrl(props.teacherSignUrl);
    getPrinciSignSvc(cbPrinciSign);
    getLogoSvc(cbLogoResponse);
    getTermReport(syllabusId);
    return () => {
      setTeacherSignUrl("");
      setReportCard({});
    };
  }, [props.sectionId, examId, exam, props.teacherSignUrl, syllabusId]);

  function cbPrinciSign(imageUrl) {
    setPrincipalSignUrl(imageUrl);
  }
  function cbLogoResponse(imageUrl) {
    // console .log("cbLogoResponse called, imageUrl", imageUrl);
    setLogoUrl(imageUrl)
  }

  const getTermReport = (pSyllabusId) => {
    setLoader("block");
    setOpacity(0.5);
    getExamReportSvc(examId, sectionId, pSyllabusId, TermReportResponse);
  }

  const TermReportResponse = async (data) => {
    // console .log("TermReportResponse called, data", data);
    let obj = data;
    if (obj.status === 'Success') {
      setReportCard(obj.lstReportCardObj);
    } else {
      setReportCard({});
      if (obj.message === 'You are not logged in' || obj.message === 'Your Session is expired, login again') {
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }

    setLoader("none");
    setOpacity(1);
  };


  const closeSlider = () => {
    props.removeClassFromBody();
  }



  return (
    <div className='padding-remove'>
      
      <div className='col-sm-12 col-centered padding-remove' style={{ opacity: opacity }}>
      <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "70%" }}>
        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
      </button>
        <div 
        className='EnquiryHead col-sm-12 col-centered'
          style={{
            border: '1px solid #aaa',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: 'bold',
            textAlign: "center"
          }}
          >
          <h4>{heading}</h4>
        </div>
        <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
          {reportCard && reportCard.lstStudent && reportCard.lstStudent.length ?
            <ExamReportCardPDF
              key={reportCard}
              logoUrl={logoUrl}
              exam={exam}
              // lstReportCard={lstReportCard}
              reportCard={reportCard}
              principalSignUrl={principalSignUrl}
              teacherSignUrl={teacherSignUrl}
              flgValidated={flgValidated}
            />
            : null
          }
        </div>
      </div >
    </div>
  );
}

export default ExamReportSlider;