import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';
// import '../css/EnquiryCtrl.css';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import FunnelSlider from './FunnelSlider'
import { getLstDataSvc, getLstSummarySvc } from './EnquiryDashboardSvc.js'

const EnquiryDashboard = () => {
    const [loader, setLoader] = useState('none')
    const [selEnqId, setSelEnqId] = useState(0);
    const [state, setState] = useState({ fromDt: null, toDt: null });
    const [lstData, setLstData] = useState([]);
    const [openFnlSlider, setOpenFnlSlider] = useState(true);
    const [flgSlider, setFlgSlider] = useState(true);
    /* const vFilterCount = [{
        "status": "Total",
        "statusId": 0,
        "count": 100
    }, {
        "status": "Enquiry",
        "statusId": 10,
        "count": 12
    }, {
        "status": "Assigned",
        "statusId": 20,
        "count": 16
    }, {
        "status": "FollowUp",
        "statusId": 30,
        "count": 16
    }, {
        "status": "Regs.",
        "statusId": 40,
        "count": 11
    }, {
        "status": "Regs. Fee",
        "statusId": 50,
        "count": 11
    }, {
        "status": "Test",
        "statusId": 60,
        "count": 14
    }, {
        "status": "Interview",
        "statusId": 70,
        "count": 8
    }, {
        "status": "Admn",
        "statusId": 80,
        "count": 12
    }]; */
    const [filterCount, setFilterCount] = useState([])
    const [funnelFilterCount, setFunnelFilterCount] = useState([])
    const data = [{
        "enquiry": "Rick Banck",
        "contactNo": "918-753-8458",
        "email": "zsimione0@irs.gov",
        "enquiryDt": "9/9/2021",
        "class": "V",
        "counselor": "Zaria Simione",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 40,
        "assignStatusId": 20,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 40,
        "testStatusId": 30,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "7/18/2021",
        "followUpDt": "11/19/2021",
        "regDt": "4/3/2021",
        "regFeeDt": "10/13/2021",
        "testDt": "7/15/2021",
        "interviewDt": "3/15/2021",
        "admnDt": "3/20/2021"
    }, {
        "enquiry": "Delphinia Shires",
        "contactNo": "497-764-1614",
        "email": "mkunkler1@cbslocal.com",
        "enquiryDt": "1/28/2022",
        "class": "IX",
        "counselor": "Melamie Kunkler",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 20,
        "assignStatusId": 10,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "12/15/2021",
        "followUpDt": "3/13/2021",
        "regDt": "3/14/2021",
        "regFeeDt": "8/1/2021",
        "testDt": "7/6/2021",
        "interviewDt": "9/6/2021",
        "admnDt": "8/12/2021"
    }, {
        "enquiry": "Rubia MacTerlagh",
        "contactNo": "625-518-0526",
        "email": "vbruce2@tmall.com",
        "enquiryDt": "7/31/2021",
        "class": "Nursery",
        "counselor": "Vaughn Bruce",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 30,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 40,
        "regFeeStatusId": 30,
        "testStatusId": 40,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "6/27/2021",
        "followUpDt": "6/1/2021",
        "regDt": "1/30/2022",
        "regFeeDt": "9/18/2021",
        "testDt": "5/22/2021",
        "interviewDt": "8/12/2021",
        "admnDt": "10/7/2021"
    }, {
        "enquiry": "Thom St. Queintain",
        "contactNo": "838-349-1337",
        "email": "agarvey3@npr.org",
        "enquiryDt": "5/15/2021",
        "class": "IX",
        "counselor": "Adele Garvey",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 30,
        "admnStatusId": 40,
        "assignDt": "2/25/2021",
        "followUpDt": "3/29/2021",
        "regDt": "5/20/2021",
        "regFeeDt": "9/24/2021",
        "testDt": "3/30/2021",
        "interviewDt": "5/23/2021",
        "admnDt": "6/3/2021"
    }, {
        "enquiry": "Damien Ogilvie",
        "contactNo": "855-990-5472",
        "email": "rjereatt4@ezinearticles.com",
        "enquiryDt": "12/17/2021",
        "class": "Nursery",
        "counselor": "Rem Jereatt",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 30,
        "regStatusId": 40,
        "regFeeStatusId": 40,
        "testStatusId": 30,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "12/13/2021",
        "followUpDt": "11/6/2021",
        "regDt": "7/11/2021",
        "regFeeDt": "6/6/2021",
        "testDt": "2/24/2021",
        "interviewDt": "9/17/2021",
        "admnDt": "10/19/2021"
    }, {
        "enquiry": "Dillie Shilliday",
        "contactNo": "914-258-3791",
        "email": "amcglew5@bluehost.com",
        "enquiryDt": "11/5/2021",
        "class": "VIII",
        "counselor": "Alfy McGlew",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "9/9/2021",
        "followUpDt": "10/6/2021",
        "regDt": "10/28/2021",
        "regFeeDt": "9/11/2021",
        "testDt": "9/26/2021",
        "interviewDt": "1/16/2022",
        "admnDt": "1/18/2022"
    }, {
        "enquiry": "Tiffi Coathup",
        "contactNo": "358-690-1648",
        "email": "rcrop6@cbslocal.com",
        "enquiryDt": "5/5/2021",
        "class": "KG",
        "counselor": "Rudie Crop",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 20,
        "assignStatusId": 10,
        "followupStatusId": 10,
        "regStatusId": 40,
        "regFeeStatusId": 30,
        "testStatusId": 30,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "10/15/2021",
        "followUpDt": "12/5/2021",
        "regDt": "6/20/2021",
        "regFeeDt": "12/9/2021",
        "testDt": "1/17/2022",
        "interviewDt": "7/27/2021",
        "admnDt": "1/13/2022"
    }, {
        "enquiry": "Wendie Ochterlony",
        "contactNo": "210-437-4808",
        "email": "dspalls7@scribd.com",
        "enquiryDt": "11/8/2021",
        "class": "II",
        "counselor": "Delly Spalls",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 40,
        "interviewStatusId": 10,
        "admnStatusId": 10,
        "assignDt": "8/23/2021",
        "followUpDt": "7/20/2021",
        "regDt": "3/23/2021",
        "regFeeDt": "5/2/2021",
        "testDt": "12/7/2021",
        "interviewDt": "8/11/2021",
        "admnDt": "2/19/2021"
    }, {
        "enquiry": "Averyl Musslewhite",
        "contactNo": "581-195-2032",
        "email": "nswaile8@weebly.com",
        "enquiryDt": "1/11/2022",
        "class": "III",
        "counselor": "Nevin Swaile",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 20,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 30,
        "testStatusId": 30,
        "interviewStatusId": 40,
        "admnStatusId": 20,
        "assignDt": "9/23/2021",
        "followUpDt": "1/10/2022",
        "regDt": "3/26/2021",
        "regFeeDt": "10/30/2021",
        "testDt": "12/1/2021",
        "interviewDt": "12/24/2021",
        "admnDt": "11/16/2021"
    }, {
        "enquiry": "Egor Axon",
        "contactNo": "173-716-1981",
        "email": "rainger9@bbc.co.uk",
        "enquiryDt": "1/24/2022",
        "class": "XI",
        "counselor": "Rawley Ainger",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 40,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "10/15/2021",
        "followUpDt": "7/1/2021",
        "regDt": "4/30/2021",
        "regFeeDt": "9/7/2021",
        "testDt": "3/16/2021",
        "interviewDt": "5/13/2021",
        "admnDt": "9/21/2021"
    }, {
        "enquiry": "Dunc Dine-Hart",
        "contactNo": "818-181-6063",
        "email": "lfleya@loc.gov",
        "enquiryDt": "10/5/2021",
        "class": "IX",
        "counselor": "Lorant Fley",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 30,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "11/18/2021",
        "followUpDt": "7/31/2021",
        "regDt": "10/9/2021",
        "regFeeDt": "9/23/2021",
        "testDt": "9/7/2021",
        "interviewDt": "2/9/2021",
        "admnDt": "10/25/2021"
    }, {
        "enquiry": "Madeleine Sellwood",
        "contactNo": "504-147-2849",
        "email": "rfarrimondb@t.co",
        "enquiryDt": "6/12/2021",
        "class": "V",
        "counselor": "Reginald Farrimond",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 10,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 10,
        "testStatusId": 30,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "11/10/2021",
        "followUpDt": "10/31/2021",
        "regDt": "3/9/2021",
        "regFeeDt": "8/15/2021",
        "testDt": "5/12/2021",
        "interviewDt": "4/16/2021",
        "admnDt": "5/11/2021"
    }, {
        "enquiry": "Halley Kindle",
        "contactNo": "601-697-7738",
        "email": "bphilpsc@nymag.com",
        "enquiryDt": "9/15/2021",
        "class": "VIII",
        "counselor": "Bancroft Philps",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 30,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "1/3/2022",
        "followUpDt": "8/13/2021",
        "regDt": "6/23/2021",
        "regFeeDt": "7/8/2021",
        "testDt": "2/28/2021",
        "interviewDt": "2/20/2021",
        "admnDt": "7/13/2021"
    }, {
        "enquiry": "Thomasin Pedro",
        "contactNo": "513-192-7025",
        "email": "aletteressed@vinaora.com",
        "enquiryDt": "1/29/2022",
        "class": "KG",
        "counselor": "Asa Letteresse",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 40,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "3/10/2021",
        "followUpDt": "11/19/2021",
        "regDt": "10/25/2021",
        "regFeeDt": "1/2/2022",
        "testDt": "2/16/2021",
        "interviewDt": "5/6/2021",
        "admnDt": "2/2/2022"
    }, {
        "enquiry": "Verney Scriver",
        "contactNo": "758-117-5310",
        "email": "fdysone@freewebs.com",
        "enquiryDt": "7/13/2021",
        "class": "X",
        "counselor": "Ferdinand Dyson",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "8/28/2021",
        "followUpDt": "1/28/2022",
        "regDt": "9/29/2021",
        "regFeeDt": "9/7/2021",
        "testDt": "11/15/2021",
        "interviewDt": "3/27/2021",
        "admnDt": "1/12/2022"
    }, {
        "enquiry": "Slade Wardlow",
        "contactNo": "859-166-1145",
        "email": "ctrankf@opera.com",
        "enquiryDt": "8/14/2021",
        "class": "III",
        "counselor": "Chryste Trank",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "9/16/2021",
        "followUpDt": "11/25/2021",
        "regDt": "2/26/2021",
        "regFeeDt": "9/20/2021",
        "testDt": "2/20/2021",
        "interviewDt": "3/5/2021",
        "admnDt": "2/11/2021"
    }, {
        "enquiry": "Gina Benbough",
        "contactNo": "959-755-9679",
        "email": "dantognellig@gov.uk",
        "enquiryDt": "10/5/2021",
        "class": "II",
        "counselor": "Dewie Antognelli",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 30,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 10,
        "testStatusId": 20,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "7/21/2021",
        "followUpDt": "11/4/2021",
        "regDt": "12/2/2021",
        "regFeeDt": "8/7/2021",
        "testDt": "3/19/2021",
        "interviewDt": "6/6/2021",
        "admnDt": "3/25/2021"
    }, {
        "enquiry": "Karissa Machent",
        "contactNo": "746-441-5460",
        "email": "gbraimeh@who.int",
        "enquiryDt": "12/22/2021",
        "class": "Nursery",
        "counselor": "Gideon Braime",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 30,
        "interviewStatusId": 30,
        "admnStatusId": 40,
        "assignDt": "11/22/2021",
        "followUpDt": "2/4/2022",
        "regDt": "4/14/2021",
        "regFeeDt": "2/13/2021",
        "testDt": "6/10/2021",
        "interviewDt": "6/6/2021",
        "admnDt": "10/17/2021"
    }, {
        "enquiry": "Dulcea Tiery",
        "contactNo": "334-486-8951",
        "email": "mpayi@springer.com",
        "enquiryDt": "1/16/2022",
        "class": "IX",
        "counselor": "Mack Pay",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 40,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "3/1/2021",
        "followUpDt": "5/4/2021",
        "regDt": "11/3/2021",
        "regFeeDt": "3/31/2021",
        "testDt": "7/28/2021",
        "interviewDt": "12/18/2021",
        "admnDt": "7/14/2021"
    }, {
        "enquiry": "Josey Scoggin",
        "contactNo": "550-555-6690",
        "email": "ebaumerj@dyndns.org",
        "enquiryDt": "11/11/2021",
        "class": "IX",
        "counselor": "Elsinore Baumer",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "6/24/2021",
        "followUpDt": "7/27/2021",
        "regDt": "10/19/2021",
        "regFeeDt": "8/20/2021",
        "testDt": "1/23/2022",
        "interviewDt": "8/4/2021",
        "admnDt": "5/2/2021"
    }, {
        "enquiry": "Gill Heugh",
        "contactNo": "564-323-2684",
        "email": "smaplek@dedecms.com",
        "enquiryDt": "6/14/2021",
        "class": "III",
        "counselor": "Sterling Maple",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 10,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 30,
        "assignDt": "11/19/2021",
        "followUpDt": "11/7/2021",
        "regDt": "1/27/2022",
        "regFeeDt": "6/21/2021",
        "testDt": "7/15/2021",
        "interviewDt": "5/27/2021",
        "admnDt": "11/27/2021"
    }, {
        "enquiry": "Ynez Holburn",
        "contactNo": "961-898-3620",
        "email": "rkaufmanl@gravatar.com",
        "enquiryDt": "3/22/2021",
        "class": "I",
        "counselor": "Rosetta Kaufman",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 30,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 30,
        "assignDt": "7/18/2021",
        "followUpDt": "7/24/2021",
        "regDt": "11/6/2021",
        "regFeeDt": "7/26/2021",
        "testDt": "7/6/2021",
        "interviewDt": "8/14/2021",
        "admnDt": "9/12/2021"
    }, {
        "enquiry": "Wyatan Haddleton",
        "contactNo": "732-942-6195",
        "email": "sbuddingm@feedburner.com",
        "enquiryDt": "12/26/2021",
        "class": "II",
        "counselor": "Stevena Budding",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 30,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "10/30/2021",
        "followUpDt": "1/26/2022",
        "regDt": "2/21/2021",
        "regFeeDt": "6/30/2021",
        "testDt": "10/11/2021",
        "interviewDt": "5/8/2021",
        "admnDt": "8/17/2021"
    }, {
        "enquiry": "Karlis Keddie",
        "contactNo": "545-149-6209",
        "email": "nmcilhattonn@paypal.com",
        "enquiryDt": "7/26/2021",
        "class": "II",
        "counselor": "Novelia McIlhatton",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "11/19/2021",
        "followUpDt": "7/27/2021",
        "regDt": "6/2/2021",
        "regFeeDt": "9/6/2021",
        "testDt": "9/30/2021",
        "interviewDt": "8/18/2021",
        "admnDt": "3/9/2021"
    }, {
        "enquiry": "Tiebold Normanton",
        "contactNo": "924-787-5147",
        "email": "mplluo@parallels.com",
        "enquiryDt": "2/23/2021",
        "class": "KG",
        "counselor": "Marylynne Pllu",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 40,
        "assignStatusId": 10,
        "followupStatusId": 40,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 40,
        "admnStatusId": 20,
        "assignDt": "8/30/2021",
        "followUpDt": "3/27/2021",
        "regDt": "1/23/2022",
        "regFeeDt": "3/3/2021",
        "testDt": "9/26/2021",
        "interviewDt": "5/11/2021",
        "admnDt": "9/24/2021"
    }, {
        "enquiry": "Reggie Cruickshanks",
        "contactNo": "316-651-2463",
        "email": "acrimpp@gov.uk",
        "enquiryDt": "5/30/2021",
        "class": "III",
        "counselor": "Armin Crimp",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 30,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 10,
        "assignDt": "4/9/2021",
        "followUpDt": "12/9/2021",
        "regDt": "8/1/2021",
        "regFeeDt": "12/31/2021",
        "testDt": "11/29/2021",
        "interviewDt": "7/21/2021",
        "admnDt": "3/22/2021"
    }, {
        "enquiry": "Orson Weildish",
        "contactNo": "840-882-2884",
        "email": "ccroftsq@g.co",
        "enquiryDt": "10/3/2021",
        "class": "XII",
        "counselor": "Corney Crofts",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 20,
        "assignStatusId": 20,
        "followupStatusId": 20,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "4/18/2021",
        "followUpDt": "10/4/2021",
        "regDt": "7/6/2021",
        "regFeeDt": "2/6/2022",
        "testDt": "6/26/2021",
        "interviewDt": "10/22/2021",
        "admnDt": "3/3/2021"
    }, {
        "enquiry": "Michele Ahrendsen",
        "contactNo": "993-157-0614",
        "email": "kdir@nationalgeographic.com",
        "enquiryDt": "9/11/2021",
        "class": "VII",
        "counselor": "Kacy Di Domenico",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 30,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 30,
        "assignDt": "8/28/2021",
        "followUpDt": "6/14/2021",
        "regDt": "12/14/2021",
        "regFeeDt": "12/20/2021",
        "testDt": "8/24/2021",
        "interviewDt": "4/12/2021",
        "admnDt": "7/1/2021"
    }, {
        "enquiry": "Elsey Kettow",
        "contactNo": "358-728-8679",
        "email": "czappels@soundcloud.com",
        "enquiryDt": "10/1/2021",
        "class": "III",
        "counselor": "Cherye Zappel",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 10,
        "assignStatusId": 40,
        "followupStatusId": 30,
        "regStatusId": 40,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "9/3/2021",
        "followUpDt": "1/14/2022",
        "regDt": "5/14/2021",
        "regFeeDt": "7/2/2021",
        "testDt": "2/13/2021",
        "interviewDt": "6/30/2021",
        "admnDt": "12/26/2021"
    }, {
        "enquiry": "Vern Gurry",
        "contactNo": "171-352-3599",
        "email": "mclimancet@apple.com",
        "enquiryDt": "3/31/2021",
        "class": "VIII",
        "counselor": "Martha Climance",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 40,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 40,
        "testStatusId": 10,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "11/19/2021",
        "followUpDt": "12/31/2021",
        "regDt": "3/13/2021",
        "regFeeDt": "5/11/2021",
        "testDt": "2/6/2022",
        "interviewDt": "2/21/2021",
        "admnDt": "1/30/2022"
    }, {
        "enquiry": "Klarrisa Broke",
        "contactNo": "286-120-0657",
        "email": "bstanningu@pcworld.com",
        "enquiryDt": "4/23/2021",
        "class": "III",
        "counselor": "Bonni Stanning",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 10,
        "assignStatusId": 20,
        "followupStatusId": 40,
        "regStatusId": 30,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "2/9/2021",
        "followUpDt": "4/26/2021",
        "regDt": "7/26/2021",
        "regFeeDt": "10/23/2021",
        "testDt": "10/18/2021",
        "interviewDt": "1/27/2022",
        "admnDt": "12/15/2021"
    }, {
        "enquiry": "Sarene Burless",
        "contactNo": "533-435-1937",
        "email": "kbogiev@vinaora.com",
        "enquiryDt": "2/11/2021",
        "class": "IX",
        "counselor": "Kimball Bogie",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 20,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 10,
        "assignDt": "12/8/2021",
        "followUpDt": "8/22/2021",
        "regDt": "11/12/2021",
        "regFeeDt": "5/30/2021",
        "testDt": "6/20/2021",
        "interviewDt": "8/1/2021",
        "admnDt": "12/7/2021"
    }, {
        "enquiry": "Coralie Spellecy",
        "contactNo": "587-927-7847",
        "email": "asilvertonw@reverbnation.com",
        "enquiryDt": "7/1/2021",
        "class": "XII",
        "counselor": "Adolph Silverton",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "7/15/2021",
        "followUpDt": "1/28/2022",
        "regDt": "11/13/2021",
        "regFeeDt": "5/3/2021",
        "testDt": "3/9/2021",
        "interviewDt": "8/4/2021",
        "admnDt": "9/1/2021"
    }, {
        "enquiry": "Jennifer McOwen",
        "contactNo": "830-434-5327",
        "email": "ntregustx@blog.com",
        "enquiryDt": "1/24/2022",
        "class": "VII",
        "counselor": "Neil Tregust",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 10,
        "testStatusId": 30,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "8/2/2021",
        "followUpDt": "7/3/2021",
        "regDt": "3/11/2021",
        "regFeeDt": "7/17/2021",
        "testDt": "5/15/2021",
        "interviewDt": "11/3/2021",
        "admnDt": "12/14/2021"
    }, {
        "enquiry": "Edith Netherwood",
        "contactNo": "104-769-9648",
        "email": "ljoey@g.co",
        "enquiryDt": "4/13/2021",
        "class": "KG",
        "counselor": "Lacee Joe",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 30,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "1/18/2022",
        "followUpDt": "4/4/2021",
        "regDt": "2/24/2021",
        "regFeeDt": "3/24/2021",
        "testDt": "12/28/2021",
        "interviewDt": "4/8/2021",
        "admnDt": "4/7/2021"
    }, {
        "enquiry": "Rolfe Musprat",
        "contactNo": "666-462-4182",
        "email": "kviegasz@hud.gov",
        "enquiryDt": "5/28/2021",
        "class": "VI",
        "counselor": "Kandy Viegas",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 10,
        "assignStatusId": 20,
        "followupStatusId": 30,
        "regStatusId": 20,
        "regFeeStatusId": 30,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "9/2/2021",
        "followUpDt": "6/24/2021",
        "regDt": "8/11/2021",
        "regFeeDt": "5/26/2021",
        "testDt": "11/12/2021",
        "interviewDt": "3/21/2021",
        "admnDt": "6/24/2021"
    }, {
        "enquiry": "Jennie Doree",
        "contactNo": "893-802-1790",
        "email": "whuffy10@aol.com",
        "enquiryDt": "7/11/2021",
        "class": "X",
        "counselor": "Winston Huffy",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "1/2/2022",
        "followUpDt": "8/18/2021",
        "regDt": "12/25/2021",
        "regFeeDt": "4/20/2021",
        "testDt": "2/5/2022",
        "interviewDt": "8/20/2021",
        "admnDt": "6/14/2021"
    }, {
        "enquiry": "Fancy Keyhoe",
        "contactNo": "463-654-0106",
        "email": "lrenshaw11@sun.com",
        "enquiryDt": "7/2/2021",
        "class": "XII",
        "counselor": "Leanor Renshaw",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 10,
        "assignStatusId": 20,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 40,
        "testStatusId": 30,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "11/6/2021",
        "followUpDt": "12/2/2021",
        "regDt": "5/15/2021",
        "regFeeDt": "8/7/2021",
        "testDt": "4/15/2021",
        "interviewDt": "11/20/2021",
        "admnDt": "5/21/2021"
    }, {
        "enquiry": "Pippo Littlecote",
        "contactNo": "925-750-8559",
        "email": "atidbold12@mapy.cz",
        "enquiryDt": "7/4/2021",
        "class": "VI",
        "counselor": "Ailey Tidbold",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 30,
        "testStatusId": 10,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "5/19/2021",
        "followUpDt": "2/5/2022",
        "regDt": "5/3/2021",
        "regFeeDt": "1/15/2022",
        "testDt": "8/2/2021",
        "interviewDt": "11/1/2021",
        "admnDt": "5/24/2021"
    }, {
        "enquiry": "Clementina Bhatia",
        "contactNo": "373-444-9271",
        "email": "sbolver13@google.com.hk",
        "enquiryDt": "8/26/2021",
        "class": "XII",
        "counselor": "Sheridan Bolver",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 40,
        "assignStatusId": 20,
        "followupStatusId": 40,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 10,
        "assignDt": "4/29/2021",
        "followUpDt": "3/30/2021",
        "regDt": "7/6/2021",
        "regFeeDt": "5/5/2021",
        "testDt": "8/5/2021",
        "interviewDt": "2/12/2021",
        "admnDt": "2/19/2021"
    }, {
        "enquiry": "Stephie Boddymead",
        "contactNo": "653-231-0260",
        "email": "qtassaker14@qq.com",
        "enquiryDt": "3/7/2021",
        "class": "V",
        "counselor": "Quill Tassaker",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 30,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "5/15/2021",
        "followUpDt": "4/19/2021",
        "regDt": "9/24/2021",
        "regFeeDt": "9/15/2021",
        "testDt": "3/30/2021",
        "interviewDt": "7/7/2021",
        "admnDt": "9/8/2021"
    }, {
        "enquiry": "Catharine Foskin",
        "contactNo": "820-942-3791",
        "email": "bchastel15@youtu.be",
        "enquiryDt": "12/13/2021",
        "class": "VII",
        "counselor": "Broderick Chastel",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "1/21/2022",
        "followUpDt": "3/16/2021",
        "regDt": "3/15/2021",
        "regFeeDt": "10/15/2021",
        "testDt": "3/9/2021",
        "interviewDt": "10/31/2021",
        "admnDt": "2/18/2021"
    }, {
        "enquiry": "Mariette Warlaw",
        "contactNo": "819-706-2882",
        "email": "phillhouse16@scribd.com",
        "enquiryDt": "11/17/2021",
        "class": "I",
        "counselor": "Pate Hillhouse",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 40,
        "assignStatusId": 10,
        "followupStatusId": 40,
        "regStatusId": 10,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "6/29/2021",
        "followUpDt": "10/10/2021",
        "regDt": "8/1/2021",
        "regFeeDt": "3/18/2021",
        "testDt": "12/25/2021",
        "interviewDt": "7/10/2021",
        "admnDt": "4/25/2021"
    }, {
        "enquiry": "Robyn Kilrow",
        "contactNo": "683-361-8049",
        "email": "lmityushkin17@360.cn",
        "enquiryDt": "5/4/2021",
        "class": "V",
        "counselor": "Lewes Mityushkin",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 20,
        "assignStatusId": 30,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "12/7/2021",
        "followUpDt": "10/15/2021",
        "regDt": "12/29/2021",
        "regFeeDt": "2/12/2021",
        "testDt": "2/11/2021",
        "interviewDt": "1/3/2022",
        "admnDt": "7/31/2021"
    }, {
        "enquiry": "Patton MacAllaster",
        "contactNo": "540-950-8586",
        "email": "jgolden18@goo.gl",
        "enquiryDt": "4/7/2021",
        "class": "V",
        "counselor": "Janey Golden of Ireland",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 40,
        "assignStatusId": 10,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "8/7/2021",
        "followUpDt": "11/11/2021",
        "regDt": "12/30/2021",
        "regFeeDt": "2/1/2022",
        "testDt": "5/5/2021",
        "interviewDt": "2/20/2021",
        "admnDt": "7/28/2021"
    }, {
        "enquiry": "Bron Richardeau",
        "contactNo": "950-764-7290",
        "email": "sarnoll19@drupal.org",
        "enquiryDt": "11/1/2021",
        "class": "III",
        "counselor": "Sarita Arnoll",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 40,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "10/18/2021",
        "followUpDt": "4/16/2021",
        "regDt": "8/27/2021",
        "regFeeDt": "6/9/2021",
        "testDt": "2/22/2021",
        "interviewDt": "2/10/2021",
        "admnDt": "10/10/2021"
    }, {
        "enquiry": "Pascal Labitt",
        "contactNo": "963-363-9646",
        "email": "sboothroyd1a@cafepress.com",
        "enquiryDt": "1/18/2022",
        "class": "KG",
        "counselor": "Sharl Boothroyd",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 40,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "12/24/2021",
        "followUpDt": "7/5/2021",
        "regDt": "11/28/2021",
        "regFeeDt": "5/5/2021",
        "testDt": "8/25/2021",
        "interviewDt": "5/12/2021",
        "admnDt": "9/6/2021"
    }, {
        "enquiry": "Alanson Amorine",
        "contactNo": "175-974-6482",
        "email": "ddaspar1b@cmu.edu",
        "enquiryDt": "3/13/2021",
        "class": "XI",
        "counselor": "Dareen Daspar",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "6/8/2021",
        "followUpDt": "1/23/2022",
        "regDt": "8/17/2021",
        "regFeeDt": "3/31/2021",
        "testDt": "1/2/2022",
        "interviewDt": "2/5/2022",
        "admnDt": "9/30/2021"
    }, {
        "enquiry": "Ninon Muttock",
        "contactNo": "539-348-4201",
        "email": "ogander1c@businesswire.com",
        "enquiryDt": "2/6/2022",
        "class": "VII",
        "counselor": "Obediah Gander",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 30,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "12/3/2021",
        "followUpDt": "5/12/2021",
        "regDt": "12/24/2021",
        "regFeeDt": "4/5/2021",
        "testDt": "2/22/2021",
        "interviewDt": "1/28/2022",
        "admnDt": "7/11/2021"
    }, {
        "enquiry": "Dora Aberkirder",
        "contactNo": "903-265-4163",
        "email": "abraban1d@columbia.edu",
        "enquiryDt": "11/7/2021",
        "class": "I",
        "counselor": "Any Braban",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 40,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "1/29/2022",
        "followUpDt": "9/28/2021",
        "regDt": "8/11/2021",
        "regFeeDt": "4/24/2021",
        "testDt": "10/8/2021",
        "interviewDt": "3/9/2021",
        "admnDt": "10/16/2021"
    }, {
        "enquiry": "Amalie Lorant",
        "contactNo": "728-984-1686",
        "email": "jferroni1e@nps.gov",
        "enquiryDt": "10/24/2021",
        "class": "I",
        "counselor": "Jessa Ferroni",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 10,
        "assignDt": "2/5/2022",
        "followUpDt": "10/5/2021",
        "regDt": "12/24/2021",
        "regFeeDt": "5/21/2021",
        "testDt": "7/4/2021",
        "interviewDt": "6/24/2021",
        "admnDt": "5/7/2021"
    }, {
        "enquiry": "Bent Bauldrey",
        "contactNo": "153-365-1500",
        "email": "eladlow1f@bloomberg.com",
        "enquiryDt": "3/22/2021",
        "class": "IV",
        "counselor": "Elladine Ladlow",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 20,
        "assignStatusId": 40,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 30,
        "testStatusId": 30,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "5/9/2021",
        "followUpDt": "1/24/2022",
        "regDt": "9/25/2021",
        "regFeeDt": "3/17/2021",
        "testDt": "8/20/2021",
        "interviewDt": "6/29/2021",
        "admnDt": "2/3/2022"
    }, {
        "enquiry": "Garrick Neasham",
        "contactNo": "742-299-2120",
        "email": "bkelk1g@examiner.com",
        "enquiryDt": "12/30/2021",
        "class": "II",
        "counselor": "Balduin Kelk",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 40,
        "interviewStatusId": 10,
        "admnStatusId": 10,
        "assignDt": "12/16/2021",
        "followUpDt": "12/28/2021",
        "regDt": "3/7/2021",
        "regFeeDt": "9/9/2021",
        "testDt": "3/2/2021",
        "interviewDt": "9/9/2021",
        "admnDt": "3/19/2021"
    }, {
        "enquiry": "Land Cancelier",
        "contactNo": "166-579-7765",
        "email": "dfrounks1h@digg.com",
        "enquiryDt": "2/8/2021",
        "class": "XI",
        "counselor": "Dalli Frounks",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 40,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "3/13/2021",
        "followUpDt": "2/22/2021",
        "regDt": "5/19/2021",
        "regFeeDt": "12/14/2021",
        "testDt": "2/23/2021",
        "interviewDt": "2/7/2021",
        "admnDt": "12/28/2021"
    }, {
        "enquiry": "Brett Aishford",
        "contactNo": "151-618-4281",
        "email": "dkeston1i@utexas.edu",
        "enquiryDt": "7/19/2021",
        "class": "XII",
        "counselor": "Dov Keston",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 30,
        "testStatusId": 30,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "3/19/2021",
        "followUpDt": "2/27/2021",
        "regDt": "1/13/2022",
        "regFeeDt": "10/27/2021",
        "testDt": "4/24/2021",
        "interviewDt": "7/12/2021",
        "admnDt": "4/16/2021"
    }, {
        "enquiry": "Lief MacCaughan",
        "contactNo": "295-998-5519",
        "email": "nuglow1j@uol.com.br",
        "enquiryDt": "11/25/2021",
        "class": "III",
        "counselor": "Ned Uglow",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 20,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 40,
        "assignDt": "9/3/2021",
        "followUpDt": "1/1/2022",
        "regDt": "5/24/2021",
        "regFeeDt": "12/14/2021",
        "testDt": "2/14/2021",
        "interviewDt": "3/27/2021",
        "admnDt": "11/24/2021"
    }, {
        "enquiry": "Kevyn Broadhurst",
        "contactNo": "498-657-0001",
        "email": "flarroway1k@g.co",
        "enquiryDt": "10/16/2021",
        "class": "II",
        "counselor": "Fionnula Larroway",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 20,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 10,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "2/5/2022",
        "followUpDt": "3/18/2021",
        "regDt": "10/27/2021",
        "regFeeDt": "9/7/2021",
        "testDt": "3/20/2021",
        "interviewDt": "3/28/2021",
        "admnDt": "9/25/2021"
    }, {
        "enquiry": "Celinka Phoenix",
        "contactNo": "901-330-4087",
        "email": "mhowerd1l@chicagotribune.com",
        "enquiryDt": "12/28/2021",
        "class": "IX",
        "counselor": "Merissa Howerd",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 30,
        "interviewStatusId": 10,
        "admnStatusId": 30,
        "assignDt": "12/16/2021",
        "followUpDt": "7/17/2021",
        "regDt": "6/1/2021",
        "regFeeDt": "3/13/2021",
        "testDt": "10/21/2021",
        "interviewDt": "4/9/2021",
        "admnDt": "2/24/2021"
    }, {
        "enquiry": "Joeann Emilien",
        "contactNo": "503-279-9596",
        "email": "bbogeys1m@geocities.com",
        "enquiryDt": "10/10/2021",
        "class": "VI",
        "counselor": "Benn Bogeys",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 40,
        "assignStatusId": 10,
        "followupStatusId": 40,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "1/19/2022",
        "followUpDt": "10/31/2021",
        "regDt": "7/1/2021",
        "regFeeDt": "11/13/2021",
        "testDt": "3/20/2021",
        "interviewDt": "2/18/2021",
        "admnDt": "6/9/2021"
    }, {
        "enquiry": "Benedick Rome",
        "contactNo": "827-389-9569",
        "email": "rwescott1n@bbc.co.uk",
        "enquiryDt": "3/24/2021",
        "class": "VIII",
        "counselor": "Rosette Wescott",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 10,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 40,
        "assignDt": "9/23/2021",
        "followUpDt": "7/12/2021",
        "regDt": "3/17/2021",
        "regFeeDt": "2/23/2021",
        "testDt": "7/14/2021",
        "interviewDt": "3/4/2021",
        "admnDt": "7/8/2021"
    }, {
        "enquiry": "Cissiee Sanper",
        "contactNo": "938-295-1996",
        "email": "snabbs1o@admin.ch",
        "enquiryDt": "10/28/2021",
        "class": "Nursery",
        "counselor": "Shurlock Nabbs",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 20,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 20,
        "admnStatusId": 40,
        "assignDt": "5/15/2021",
        "followUpDt": "7/24/2021",
        "regDt": "11/22/2021",
        "regFeeDt": "2/24/2021",
        "testDt": "2/15/2021",
        "interviewDt": "8/9/2021",
        "admnDt": "10/11/2021"
    }, {
        "enquiry": "Fania Olfert",
        "contactNo": "938-406-6901",
        "email": "mgarmans1p@mediafire.com",
        "enquiryDt": "12/24/2021",
        "class": "Nursery",
        "counselor": "Moore Garmans",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 40,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "6/21/2021",
        "followUpDt": "6/15/2021",
        "regDt": "9/16/2021",
        "regFeeDt": "6/8/2021",
        "testDt": "4/19/2021",
        "interviewDt": "2/14/2021",
        "admnDt": "10/9/2021"
    }, {
        "enquiry": "Findley Lidell",
        "contactNo": "829-575-5861",
        "email": "jenticknap1q@hugedomains.com",
        "enquiryDt": "12/11/2021",
        "class": "Nursery",
        "counselor": "Jammie Enticknap",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 20,
        "admnStatusId": 40,
        "assignDt": "10/10/2021",
        "followUpDt": "3/17/2021",
        "regDt": "2/26/2021",
        "regFeeDt": "4/21/2021",
        "testDt": "6/19/2021",
        "interviewDt": "10/7/2021",
        "admnDt": "3/26/2021"
    }, {
        "enquiry": "Elmer Gerrels",
        "contactNo": "433-270-1480",
        "email": "lkillock1r@google.ca",
        "enquiryDt": "5/26/2021",
        "class": "VI",
        "counselor": "Laurie Killock",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "6/25/2021",
        "followUpDt": "5/11/2021",
        "regDt": "8/7/2021",
        "regFeeDt": "12/24/2021",
        "testDt": "9/22/2021",
        "interviewDt": "12/2/2021",
        "admnDt": "3/31/2021"
    }, {
        "enquiry": "Trudy Mountlow",
        "contactNo": "224-300-1276",
        "email": "wdevons1s@trellian.com",
        "enquiryDt": "6/7/2021",
        "class": "X",
        "counselor": "Wood Devons",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "2/11/2021",
        "followUpDt": "1/28/2022",
        "regDt": "8/13/2021",
        "regFeeDt": "3/3/2021",
        "testDt": "5/17/2021",
        "interviewDt": "11/7/2021",
        "admnDt": "10/8/2021"
    }, {
        "enquiry": "Cornie Dudden",
        "contactNo": "206-374-2807",
        "email": "acasburn1t@free.fr",
        "enquiryDt": "4/13/2021",
        "class": "IV",
        "counselor": "Andriette Casburn",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "4/11/2021",
        "followUpDt": "12/14/2021",
        "regDt": "2/28/2021",
        "regFeeDt": "5/7/2021",
        "testDt": "11/16/2021",
        "interviewDt": "11/11/2021",
        "admnDt": "6/17/2021"
    }, {
        "enquiry": "Moe Livett",
        "contactNo": "330-880-1294",
        "email": "mmartt1u@zimbio.com",
        "enquiryDt": "2/1/2022",
        "class": "XII",
        "counselor": "Marget Martt",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 20,
        "assignDt": "5/6/2021",
        "followUpDt": "2/11/2021",
        "regDt": "5/16/2021",
        "regFeeDt": "8/7/2021",
        "testDt": "8/20/2021",
        "interviewDt": "2/28/2021",
        "admnDt": "10/29/2021"
    }, {
        "enquiry": "Rozanna Farrans",
        "contactNo": "605-170-6299",
        "email": "kkuhlmey1v@blinklist.com",
        "enquiryDt": "5/30/2021",
        "class": "XI",
        "counselor": "Korry Kuhlmey",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 20,
        "assignStatusId": 20,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "4/1/2021",
        "followUpDt": "7/26/2021",
        "regDt": "8/27/2021",
        "regFeeDt": "11/24/2021",
        "testDt": "11/7/2021",
        "interviewDt": "8/30/2021",
        "admnDt": "1/23/2022"
    }, {
        "enquiry": "Corenda Mowsdale",
        "contactNo": "284-614-6389",
        "email": "bbannester1w@addthis.com",
        "enquiryDt": "5/14/2021",
        "class": "III",
        "counselor": "Baldwin Bannester",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "7/22/2021",
        "followUpDt": "1/18/2022",
        "regDt": "4/4/2021",
        "regFeeDt": "3/23/2021",
        "testDt": "11/14/2021",
        "interviewDt": "5/31/2021",
        "admnDt": "11/15/2021"
    }, {
        "enquiry": "Quent Blackater",
        "contactNo": "544-265-5803",
        "email": "jgoode1x@canalblog.com",
        "enquiryDt": "4/25/2021",
        "class": "Nursery",
        "counselor": "Jessi Goode",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 30,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "12/14/2021",
        "followUpDt": "12/25/2021",
        "regDt": "8/19/2021",
        "regFeeDt": "7/22/2021",
        "testDt": "10/9/2021",
        "interviewDt": "5/16/2021",
        "admnDt": "11/23/2021"
    }, {
        "enquiry": "Rod Aylett",
        "contactNo": "743-810-2333",
        "email": "clowne1y@scribd.com",
        "enquiryDt": "4/25/2021",
        "class": "KG",
        "counselor": "Caitrin Lowne",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 30,
        "assignStatusId": 30,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 40,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 30,
        "assignDt": "9/20/2021",
        "followUpDt": "12/7/2021",
        "regDt": "7/13/2021",
        "regFeeDt": "1/6/2022",
        "testDt": "6/10/2021",
        "interviewDt": "6/28/2021",
        "admnDt": "7/25/2021"
    }, {
        "enquiry": "Tani holmes",
        "contactNo": "507-693-3198",
        "email": "tadnett1z@japanpost.jp",
        "enquiryDt": "6/28/2021",
        "class": "II",
        "counselor": "Teodorico Adnett",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 10,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 40,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "9/20/2021",
        "followUpDt": "6/10/2021",
        "regDt": "1/25/2022",
        "regFeeDt": "5/24/2021",
        "testDt": "11/6/2021",
        "interviewDt": "11/10/2021",
        "admnDt": "6/17/2021"
    }, {
        "enquiry": "Lexi Georgi",
        "contactNo": "534-402-8196",
        "email": "sromera20@wordpress.com",
        "enquiryDt": "10/8/2021",
        "class": "III",
        "counselor": "Shani Romera",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 30,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 30,
        "regFeeStatusId": 30,
        "testStatusId": 30,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "10/21/2021",
        "followUpDt": "2/8/2021",
        "regDt": "9/26/2021",
        "regFeeDt": "7/13/2021",
        "testDt": "10/6/2021",
        "interviewDt": "3/10/2021",
        "admnDt": "8/21/2021"
    }, {
        "enquiry": "Shermy Verbruggen",
        "contactNo": "372-570-2148",
        "email": "tclearley21@stumbleupon.com",
        "enquiryDt": "2/15/2021",
        "class": "VIII",
        "counselor": "Thorndike Clearley",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 40,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 30,
        "admnStatusId": 30,
        "assignDt": "3/5/2021",
        "followUpDt": "8/26/2021",
        "regDt": "12/11/2021",
        "regFeeDt": "4/2/2021",
        "testDt": "3/20/2021",
        "interviewDt": "5/28/2021",
        "admnDt": "8/22/2021"
    }, {
        "enquiry": "Emmott Foker",
        "contactNo": "953-726-6996",
        "email": "esparling22@mapy.cz",
        "enquiryDt": "9/11/2021",
        "class": "I",
        "counselor": "Elyssa Sparling",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 10,
        "assignDt": "10/14/2021",
        "followUpDt": "3/11/2021",
        "regDt": "7/21/2021",
        "regFeeDt": "1/16/2022",
        "testDt": "6/6/2021",
        "interviewDt": "9/13/2021",
        "admnDt": "11/21/2021"
    }, {
        "enquiry": "Rory Ennals",
        "contactNo": "967-586-2817",
        "email": "gkeuning23@1und1.de",
        "enquiryDt": "12/21/2021",
        "class": "II",
        "counselor": "Godwin Keuning",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 10,
        "assignStatusId": 20,
        "followupStatusId": 30,
        "regStatusId": 10,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "1/23/2022",
        "followUpDt": "10/21/2021",
        "regDt": "4/28/2021",
        "regFeeDt": "8/29/2021",
        "testDt": "2/9/2021",
        "interviewDt": "12/30/2021",
        "admnDt": "3/24/2021"
    }, {
        "enquiry": "Dall MacCrann",
        "contactNo": "485-556-7556",
        "email": "dmcilhagga24@phpbb.com",
        "enquiryDt": "7/1/2021",
        "class": "IX",
        "counselor": "Dougie McIlhagga",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 30,
        "assignStatusId": 20,
        "followupStatusId": 30,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 20,
        "assignDt": "8/14/2021",
        "followUpDt": "12/6/2021",
        "regDt": "5/20/2021",
        "regFeeDt": "5/8/2021",
        "testDt": "1/23/2022",
        "interviewDt": "7/14/2021",
        "admnDt": "12/16/2021"
    }, {
        "enquiry": "Kirbie Skeeles",
        "contactNo": "642-956-2567",
        "email": "dfelgate25@reference.com",
        "enquiryDt": "6/13/2021",
        "class": "Nursery",
        "counselor": "Davida Felgate",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 20,
        "assignStatusId": 30,
        "followupStatusId": 40,
        "regStatusId": 20,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "8/3/2021",
        "followUpDt": "2/24/2021",
        "regDt": "12/19/2021",
        "regFeeDt": "7/2/2021",
        "testDt": "5/13/2021",
        "interviewDt": "5/28/2021",
        "admnDt": "10/22/2021"
    }, {
        "enquiry": "Hedvig Yakobovicz",
        "contactNo": "755-760-1808",
        "email": "mboays26@domainmarket.com",
        "enquiryDt": "10/8/2021",
        "class": "XII",
        "counselor": "Matthiew Boays",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 10,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 10,
        "testStatusId": 40,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "7/21/2021",
        "followUpDt": "8/26/2021",
        "regDt": "4/24/2021",
        "regFeeDt": "7/20/2021",
        "testDt": "7/7/2021",
        "interviewDt": "2/20/2021",
        "admnDt": "7/27/2021"
    }, {
        "enquiry": "Lise Bamlett",
        "contactNo": "597-525-3514",
        "email": "jpieters27@facebook.com",
        "enquiryDt": "2/11/2021",
        "class": "I",
        "counselor": "Jazmin Pieters",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 20,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 40,
        "interviewStatusId": 10,
        "admnStatusId": 10,
        "assignDt": "7/3/2021",
        "followUpDt": "11/14/2021",
        "regDt": "7/13/2021",
        "regFeeDt": "5/7/2021",
        "testDt": "11/15/2021",
        "interviewDt": "8/23/2021",
        "admnDt": "1/5/2022"
    }, {
        "enquiry": "Luther Gurnay",
        "contactNo": "336-970-5274",
        "email": "cdanihel28@people.com.cn",
        "enquiryDt": "9/21/2021",
        "class": "KG",
        "counselor": "Christy Danihel",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 40,
        "assignStatusId": 20,
        "followupStatusId": 30,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 30,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "8/1/2021",
        "followUpDt": "9/10/2021",
        "regDt": "8/3/2021",
        "regFeeDt": "11/1/2021",
        "testDt": "5/28/2021",
        "interviewDt": "12/31/2021",
        "admnDt": "4/17/2021"
    }, {
        "enquiry": "Ailsun Foran",
        "contactNo": "647-455-1921",
        "email": "maylward29@mail.ru",
        "enquiryDt": "3/24/2021",
        "class": "IV",
        "counselor": "Marjorie Aylward",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 10,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "5/14/2021",
        "followUpDt": "1/8/2022",
        "regDt": "9/28/2021",
        "regFeeDt": "9/23/2021",
        "testDt": "3/20/2021",
        "interviewDt": "10/4/2021",
        "admnDt": "5/1/2021"
    }, {
        "enquiry": "Denna Staten",
        "contactNo": "628-436-7434",
        "email": "tmessage2a@latimes.com",
        "enquiryDt": "11/15/2021",
        "class": "XII",
        "counselor": "Tristam Message",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 20,
        "assignStatusId": 20,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "7/11/2021",
        "followUpDt": "5/24/2021",
        "regDt": "10/12/2021",
        "regFeeDt": "4/16/2021",
        "testDt": "1/14/2022",
        "interviewDt": "10/4/2021",
        "admnDt": "9/24/2021"
    }, {
        "enquiry": "Jehu Enevoldsen",
        "contactNo": "593-548-1217",
        "email": "mhenrych2b@technorati.com",
        "enquiryDt": "3/31/2021",
        "class": "IX",
        "counselor": "Marnia Henrych",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 40,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 30,
        "interviewStatusId": 40,
        "admnStatusId": 30,
        "assignDt": "3/26/2021",
        "followUpDt": "4/23/2021",
        "regDt": "6/16/2021",
        "regFeeDt": "3/20/2021",
        "testDt": "11/7/2021",
        "interviewDt": "10/3/2021",
        "admnDt": "7/20/2021"
    }, {
        "enquiry": "Wini Berrington",
        "contactNo": "499-310-1836",
        "email": "caxup2c@stumbleupon.com",
        "enquiryDt": "11/9/2021",
        "class": "IX",
        "counselor": "Clevey Axup",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 20,
        "assignStatusId": 40,
        "followupStatusId": 40,
        "regStatusId": 40,
        "regFeeStatusId": 40,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 40,
        "assignDt": "5/31/2021",
        "followUpDt": "4/11/2021",
        "regDt": "9/7/2021",
        "regFeeDt": "11/9/2021",
        "testDt": "1/27/2022",
        "interviewDt": "12/16/2021",
        "admnDt": "4/24/2021"
    }, {
        "enquiry": "Ikey Senn",
        "contactNo": "222-518-6017",
        "email": "dlawdham2d@weebly.com",
        "enquiryDt": "9/27/2021",
        "class": "V",
        "counselor": "Dinah Lawdham",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 30,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 10,
        "admnStatusId": 30,
        "assignDt": "10/21/2021",
        "followUpDt": "4/23/2021",
        "regDt": "5/24/2021",
        "regFeeDt": "11/3/2021",
        "testDt": "8/16/2021",
        "interviewDt": "4/14/2021",
        "admnDt": "3/24/2021"
    }, {
        "enquiry": "Marlo Biddleston",
        "contactNo": "337-173-6199",
        "email": "rdunkersley2e@bloglines.com",
        "enquiryDt": "4/1/2021",
        "class": "V",
        "counselor": "Rosaleen Dunkersley",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 30,
        "assignStatusId": 10,
        "followupStatusId": 40,
        "regStatusId": 10,
        "regFeeStatusId": 20,
        "testStatusId": 10,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "5/3/2021",
        "followUpDt": "8/7/2021",
        "regDt": "2/1/2022",
        "regFeeDt": "12/24/2021",
        "testDt": "3/15/2021",
        "interviewDt": "8/18/2021",
        "admnDt": "8/9/2021"
    }, {
        "enquiry": "Alon Durtnel",
        "contactNo": "632-622-5688",
        "email": "econdict2f@cargocollective.com",
        "enquiryDt": "11/28/2021",
        "class": "III",
        "counselor": "Emmanuel Condict",
        "status": "Admission",
        "statusId": 80,
        "enquiryStatusId": 40,
        "assignStatusId": 10,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 40,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 30,
        "assignDt": "11/20/2021",
        "followUpDt": "8/23/2021",
        "regDt": "12/20/2021",
        "regFeeDt": "5/24/2021",
        "testDt": "8/24/2021",
        "interviewDt": "8/5/2021",
        "admnDt": "10/28/2021"
    }, {
        "enquiry": "Laurens Earnshaw",
        "contactNo": "589-945-0820",
        "email": "adorkens2g@blinklist.com",
        "enquiryDt": "8/31/2021",
        "class": "I",
        "counselor": "Amery Dorkens",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 20,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 30,
        "regFeeStatusId": 40,
        "testStatusId": 10,
        "interviewStatusId": 20,
        "admnStatusId": 40,
        "assignDt": "5/21/2021",
        "followUpDt": "7/28/2021",
        "regDt": "11/3/2021",
        "regFeeDt": "10/2/2021",
        "testDt": "12/14/2021",
        "interviewDt": "8/3/2021",
        "admnDt": "2/4/2022"
    }, {
        "enquiry": "Moyra Curphey",
        "contactNo": "383-950-4214",
        "email": "awillett2h@eepurl.com",
        "enquiryDt": "2/20/2021",
        "class": "KG",
        "counselor": "Althea Willett",
        "status": "Enquiry",
        "statusId": 10,
        "enquiryStatusId": 30,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "2/8/2021",
        "followUpDt": "11/27/2021",
        "regDt": "5/20/2021",
        "regFeeDt": "1/4/2022",
        "testDt": "10/3/2021",
        "interviewDt": "7/31/2021",
        "admnDt": "8/8/2021"
    }, {
        "enquiry": "Drugi Gladman",
        "contactNo": "414-735-4419",
        "email": "fbragger2i@devhub.com",
        "enquiryDt": "12/16/2021",
        "class": "XII",
        "counselor": "Florida Bragger",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 40,
        "assignDt": "10/29/2021",
        "followUpDt": "4/4/2021",
        "regDt": "10/29/2021",
        "regFeeDt": "5/16/2021",
        "testDt": "12/8/2021",
        "interviewDt": "4/2/2021",
        "admnDt": "11/5/2021"
    }, {
        "enquiry": "Carlita Kelmere",
        "contactNo": "127-494-9996",
        "email": "bmee2j@unblog.fr",
        "enquiryDt": "8/24/2021",
        "class": "V",
        "counselor": "Baxter Mee",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 30,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 20,
        "regFeeStatusId": 40,
        "testStatusId": 40,
        "interviewStatusId": 40,
        "admnStatusId": 40,
        "assignDt": "5/19/2021",
        "followUpDt": "9/15/2021",
        "regDt": "11/11/2021",
        "regFeeDt": "6/30/2021",
        "testDt": "2/15/2021",
        "interviewDt": "12/13/2021",
        "admnDt": "8/24/2021"
    }, {
        "enquiry": "Brigham Faithfull",
        "contactNo": "162-997-3802",
        "email": "cgrigolashvill2k@woothemes.com",
        "enquiryDt": "9/11/2021",
        "class": "II",
        "counselor": "Cherey Grigolashvill",
        "status": "Assigned",
        "statusId": 20,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 10,
        "testStatusId": 40,
        "interviewStatusId": 10,
        "admnStatusId": 10,
        "assignDt": "9/11/2021",
        "followUpDt": "7/7/2021",
        "regDt": "8/26/2021",
        "regFeeDt": "1/10/2022",
        "testDt": "12/22/2021",
        "interviewDt": "3/15/2021",
        "admnDt": "9/24/2021"
    }, {
        "enquiry": "Tandi Mc Grath",
        "contactNo": "460-487-8916",
        "email": "ksmurfit2l@ox.ac.uk",
        "enquiryDt": "3/1/2021",
        "class": "VI",
        "counselor": "Kenny Smurfit",
        "status": "Regs. Fee",
        "statusId": 50,
        "enquiryStatusId": 40,
        "assignStatusId": 30,
        "followupStatusId": 40,
        "regStatusId": 10,
        "regFeeStatusId": 20,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 40,
        "assignDt": "12/30/2021",
        "followUpDt": "4/12/2021",
        "regDt": "2/20/2021",
        "regFeeDt": "2/12/2021",
        "testDt": "1/6/2022",
        "interviewDt": "8/25/2021",
        "admnDt": "12/22/2021"
    }, {
        "enquiry": "Link Willishire",
        "contactNo": "594-573-8003",
        "email": "epaik2m@who.int",
        "enquiryDt": "12/14/2021",
        "class": "II",
        "counselor": "Elaina Paik",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 40,
        "assignStatusId": 40,
        "followupStatusId": 10,
        "regStatusId": 10,
        "regFeeStatusId": 10,
        "testStatusId": 10,
        "interviewStatusId": 30,
        "admnStatusId": 20,
        "assignDt": "7/17/2021",
        "followUpDt": "1/19/2022",
        "regDt": "3/3/2021",
        "regFeeDt": "2/17/2021",
        "testDt": "11/11/2021",
        "interviewDt": "11/25/2021",
        "admnDt": "3/25/2021"
    }, {
        "enquiry": "Kelcy Brooksbie",
        "contactNo": "983-600-8349",
        "email": "stunnick2n@gnu.org",
        "enquiryDt": "11/1/2021",
        "class": "KG",
        "counselor": "Shelly Tunnick",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 20,
        "assignStatusId": 20,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 10,
        "testStatusId": 30,
        "interviewStatusId": 40,
        "admnStatusId": 20,
        "assignDt": "7/22/2021",
        "followUpDt": "1/18/2022",
        "regDt": "1/4/2022",
        "regFeeDt": "4/19/2021",
        "testDt": "9/29/2021",
        "interviewDt": "10/25/2021",
        "admnDt": "4/2/2021"
    }, {
        "enquiry": "Dido Wetter",
        "contactNo": "603-849-3060",
        "email": "ktarpey2o@google.ca",
        "enquiryDt": "7/22/2021",
        "class": "V",
        "counselor": "Kain Tarpey",
        "status": "Interview",
        "statusId": 70,
        "enquiryStatusId": 10,
        "assignStatusId": 30,
        "followupStatusId": 20,
        "regStatusId": 40,
        "regFeeStatusId": 30,
        "testStatusId": 10,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "12/7/2021",
        "followUpDt": "7/29/2021",
        "regDt": "3/30/2021",
        "regFeeDt": "5/18/2021",
        "testDt": "2/10/2021",
        "interviewDt": "4/12/2021",
        "admnDt": "12/16/2021"
    }, {
        "enquiry": "Maiga Reay",
        "contactNo": "553-405-6828",
        "email": "shartin2p@va.gov",
        "enquiryDt": "1/27/2022",
        "class": "Nursery",
        "counselor": "Shawnee Hartin",
        "status": "FollowUp",
        "statusId": 30,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 40,
        "testStatusId": 10,
        "interviewStatusId": 10,
        "admnStatusId": 20,
        "assignDt": "5/10/2021",
        "followUpDt": "12/5/2021",
        "regDt": "10/29/2021",
        "regFeeDt": "5/25/2021",
        "testDt": "9/2/2021",
        "interviewDt": "5/12/2021",
        "admnDt": "6/1/2021"
    }, {
        "enquiry": "Birgitta Gusney",
        "contactNo": "387-354-2319",
        "email": "tdwane2q@state.tx.us",
        "enquiryDt": "1/26/2022",
        "class": "VIII",
        "counselor": "Terrijo Dwane",
        "status": "Test",
        "statusId": 60,
        "enquiryStatusId": 10,
        "assignStatusId": 10,
        "followupStatusId": 30,
        "regStatusId": 30,
        "regFeeStatusId": 20,
        "testStatusId": 20,
        "interviewStatusId": 20,
        "admnStatusId": 10,
        "assignDt": "6/4/2021",
        "followUpDt": "7/17/2021",
        "regDt": "5/14/2021",
        "regFeeDt": "3/7/2021",
        "testDt": "10/6/2021",
        "interviewDt": "5/19/2021",
        "admnDt": "3/1/2021"
    }, {
        "enquiry": "Stillman Keysel",
        "contactNo": "366-524-7173",
        "email": "marnaldo2r@people.com.cn",
        "enquiryDt": "7/19/2021",
        "class": "VII",
        "counselor": "Micah Arnaldo",
        "status": "Regs.",
        "statusId": 40,
        "enquiryStatusId": 20,
        "assignStatusId": 10,
        "followupStatusId": 20,
        "regStatusId": 10,
        "regFeeStatusId": 30,
        "testStatusId": 40,
        "interviewStatusId": 30,
        "admnStatusId": 10,
        "assignDt": "8/22/2021",
        "followUpDt": "1/6/2022",
        "regDt": "4/20/2021",
        "regFeeDt": "12/4/2021",
        "testDt": "10/21/2021",
        "interviewDt": "3/30/2021",
        "admnDt": "2/4/2022"
    }];

    const [status, setStatus] = useState(0);
    const [enquiry, setEnquiry] = useState("")
    const [contact, setContact] = useState("")
    const [foundEnquiry, setFoundEnquiry] = useState([]);
    function getData() {
        // console .log("getData()" + convert(state.fromDt) + "::" + convert(state.toDt))
        var from = convert(state.fromDt);
        var to = convert(state.toDt);
        setLoader('block');
        getLstDataSvc(cbGetLstDataSvcResponse, from, to);
        getLstSummarySvc(cbGetLstSummarySvcResponse, from, to);
    }
    function cbGetLstDataSvcResponse(data) {
        if (data.SvcStatus === "Success") {
            setLstData(data.lstData)
            setFoundEnquiry(data.lstData)
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function cbGetLstSummarySvcResponse(data) {
        if (data.SvcStatus === "Success") {
            setFunnelFilterCount(data.lstData);
            var lFilterCount = data.lstData;
            // console .log(data.lstData)
            var total = 0;
            lFilterCount.forEach(object => {
                object.selected = false;
                object.ctr = 0;
                total += object.count;
            });
            // console .log(lFilterCount);
            // lFilterCount.unshift({ selected: true, ctr: 1, count: total, statusId: 0, status: 'Total' })
            setFilterCount(lFilterCount);
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }

    useEffect(() => {
        var endDt = new Date();
        var stDt = new Date();
        stDt.setMonth(stDt.getMonth() - 3);
        setState({
            fromDt: new Date(stDt),
            toDt: new Date(endDt),
        })
        var from = convert(new Date(stDt));
        var to = convert(new Date(endDt));
        setLoader('block');
        getLstDataSvc(cbGetLstDataSvcResponse, from, to);
        getLstSummarySvc(cbGetLstSummarySvcResponse, from, to)

    }, [])
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        // console .log([day, mnth, date.getFullYear()].join("-"));
        return [day, mnth, date.getFullYear()].join("-");
    }
    const filterDataByStatus = (pStatusId) => {
        setStatus(parseInt(pStatusId))
        var lFilterCount = filterCount;
        var flgFilterRemove = false;
        for (var i = 0; i < lFilterCount.length; i++) {
            if (lFilterCount[i].statusId === pStatusId) {
                lFilterCount[i].ctr += 1;
                lFilterCount[i].selected = lFilterCount[i].ctr % 2 !== 0;
                flgFilterRemove = !lFilterCount[i].selected;
            } else {
                lFilterCount[i].selected = false;
                lFilterCount[i].ctr = 0;
            }
        }
        if (flgFilterRemove) {
            setStatus(0)
            lFilterCount[0].selected = true;
            lFilterCount[0].ctr = 1;
        }
        setFilterCount(lFilterCount);
        setFunnelFilterCount(lFilterCount);
    }
    const filterEnquiry = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundEnquiry.filter((user) => {
                return user.name.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundEnquiry(results);
        } else {
            setFoundEnquiry(lstData);
            // If the text field is empty, show all users
        }

        setEnquiry(keyword);
    };
    const filterContactNo = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundEnquiry.filter((user) => {
                return user.contactNo.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundEnquiry(results);
        } else {
            setFoundEnquiry(lstData);
            // If the text field is empty, show all users
        }

        setContact(keyword);
    };
    const colgrp = <colgroup>
        <col width="6%"></col>
        <col width="6%"></col>
        <col width="11%"></col>
        <col width="6%"></col>
        <col width="5%"></col>
        <col width="6%"></col>
        <col width="6%"></col>
        <col width="5%"></col>
        <col width="5%"></col>
        <col width="5%"></col>
        <col width="5%"></col>
        <col width="5%"></col>
        <col width="5%"></col>
        <col width="5%"></col>
        {/* <col width="5%"></col> */}
    </colgroup>
    function refreshScreen() {
        setSlider();
    }
    function setSlider() {
        setOpenFnlSlider(!openFnlSlider)
    }
    function getImage(pStatusId) {
        //obj.admnStatusId === 10 ? appURL+"/images/red.png"
        /* lightgray_circle.jpg
        lightorange_circle.png
        lightgreen_circle.png
        light_red_circle.png */
        if (pStatusId === 10)
            return window.appURL + "/lightgray_circle.png";
        if (pStatusId === 20)
            return window.appURL + "/lightorange_circle.png";
        if (pStatusId === 30)
            return window.appURL + "/lightgreen_circle.png";
        if (pStatusId === 40)
            return window.appURL + "/light_red_circle.png";
    }

    function handleChange(date) {
        setState({
            ...state,
            fromDt: date,
        });
    }
    function handleChange1(date) {
        setState({
            ...state,
            toDt: date
        });

    }
    const getWingId = (wingId) => {
        // console .log('wingId = ' + wingId)
    }
    return <div className="MainContainer" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
        <Header wingFromChild={getWingId} />

        <ToastContainer autoClose={3000} hideProgressBar />
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>


        <div className="icon-open-funnel-slider" onClick={(e) => setSlider()}>
            {!openFnlSlider && <span style={{ fontSize: '14', cursor: 'pointer' }} className="glyphicon glyphicon-chevron-right"></span>}
            {openFnlSlider && <span style={{ fontSize: '14', cursor: 'pointer' }} className="glyphicon glyphicon-chevron-left"></span>}
        </div>
        <div className="col-sm-12" style={{ margin: '1% 0' }}>

            <div className="col-sm-4 padding-remove">
                <div className="col-sm-5" style={{ left: '4%', paddingRight: 0, width: '38%' }}>
                    <label className="col-sm-3 text-left padding-remove">From Date</label>
                    <div className="form-group col-sm-8 padding-remove">
                        <DatePicker id="inp_toDate"
                            className='form-control text-center'
                            placeholderText="From Date"
                            selected={state.fromDt}
                            dateFormat="dd-MM-yyyy"
                            onChange={handleChange}
                            autoComplete='off'
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>

                <div className="col-sm-5" style={{ width: '40%' }}>
                    <label className="text-left col-sm-3 padding-remove">To Date</label>
                    <div className="form-group col-sm-8 padding-remove">
                        <DatePicker id="inp_toDate"
                            className='form-control text-center'
                            placeholderText="To Date"
                            selected={state.toDt}
                            onChange={handleChange1}
                            dateFormat="dd-MM-yyyy"
                            autoComplete='off'
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>
                <div className="col-sm-2 form-group" style={{ width: '18%' }}>
                    <button type="button" className="btn btn-info pull-right" onClick={(e) => getData()}>Get Data</button>
                </div>

            </div>
            <div className="col-sm-8 padding-remove" style={{ border: '1px solid #ddd', borderRadius: 4 }}>
                {/* <div key={0} id={100} className={"col-sm-1 padding-remove"} style={{ fontSize: '3.2rem', width: '9.6%', height: 'auto', border: '1px solid transparent', borderRadius: 4, cursor: 'pointer' }} onClick={(e) => filterDataByStatus(0)}>
                    <span></span>
                    <div>147</div>
                </div> */}
                {/* <span><h4 onClick={(e) => filterDataByStatus(0)}>147</h4></span> */}
                {filterCount.map((obj, idx) => {
                    return idx !== 0 && idx !== filterCount.length - 1 ? <div key={idx} id={obj.statusId} className={status === obj.statusId && obj.selected ? "col-sm-1 selected padding-remove" : "col-sm-1 padding-remove"} style={{ margin: '0.25%', width: '10.7%', height: 'auto', border: '1px solid #ddd', borderRadius: 4, background: 'transparent' }} onClick={(e) => filterDataByStatus(obj.statusId)}>
                        <span>{obj.status}</span>
                        <div>{obj.count}</div>

                    </div> : <div key={idx} id={obj.statusId} className={"col-sm-1 padding-remove"}
                        style={{ fontSize: '3.2rem', width: '10.7%', height: 'auto', border: '1px solid transparent', borderRadius: 4, color: status === obj.statusId && obj.selected ? 'darkblue' : '', fontWeight: status === obj.statusId && obj.selected ? 'bold' : '' }}
                        onClick={(e) => filterDataByStatus(obj.statusId)}>
                        <span></span>
                        <div>{obj.count}</div>

                    </div>

                })}
                {/* <h4 id={obj.statusId} className={status === obj.statusId ? "control-label selected padding-remove" : "control-label   padding-remove"} onClick={(e) => filterDataByStatus(obj.statusId)}>{obj.count}</h4> */}
                {/* <div id="10" className={status === 10 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(10)}>
                    <span>Enquiry</span>
                    <div>7</div>
                </div>
                <div id="20" className={status === 20 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(20)}>
                    <span>Assigned</span>
                    <div>12</div></div>
                <div id="30" className={status === 30 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(30)}>
                    <span>FollowUp</span>
                    <div>36</div></div>
                <div id="40" className={status === 40 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(40)}>
                    <span>Regs.</span>
                    <div>28</div>
                </div>
                <div id="50" className={status === 50 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(50)}>
                    <span>Regs. Fee</span>
                    <div>18</div>
                </div>
                <div id="60" className={status === 60 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(60)}>
                    <span>Test</span>
                    <div>12</div>
                </div>
                <div id="70" className={status === 70 ? "col-sm-1 selected" : "col-sm-1"} style={{ width: '12%', height: 'auto', border: '1px solid #ddd', borderRadius: 4 }} onClick={(e) => filterDataByStatus(70)}>
                    <span>Interview</span>
                    <div>6</div>
                </div> */}
                {/* <div key={80} id={80} className={status === 80 ? "col-sm-1 padding-remove" : "col-sm-1 padding-remove"} style={{ fontSize: '3.2rem', width: '9.6%', height: 'auto', border: '1px solid transparent', borderRadius: 4 }} onClick={(e) => filterDataByStatus(80)}>
                    <span></span>
                    <div>12</div>
                </div> */}
                {/* 
                <p id="80" className={status === 80 ? "control-label selected" : "control-label"} onClick={(e) => filterDataByStatus(80)}>12</p> */}
            </div>
        </div>
        <div className="col-sm-12">
            <table className="tableLayout1">
                {colgrp}
                <thead>
                    <tr>
                        <th style={{ width: 75 }}>
                            <input onChange={filterEnquiry} type="text" value={enquiry} className="form-control inp-search" placeholder="Enquiry" />
                        </th>
                        <th style={{ width: 75 }}>
                            <input onChange={filterContactNo} type="text" value={contact} className="form-control inp-search" placeholder="Contact No." />
                        </th>
                        <th className="text-left">Email</th>
                        <th>Enquiry Date</th>
                        <th>Class</th>
                        <th className="text-left">Counsellor</th>
                        <th className="text-left">Status</th>
                        <th>Enquiry</th>
                        <th>Assigned</th>
                        <th>FollowUp</th>
                        <th>Regs.</th>
                        <th>Regs. Fee</th>
                        <th>Test</th>
                        <th>Interview</th>

                    </tr>
                </thead>
            </table>
            <div className="tablebodyWrapper">
                <table className="tableLayout">
                    {colgrp}
                    <tbody className="">

                        {foundEnquiry.map((obj, idx) => {
                            return <tr onClick={(e) => { setSelEnqId(idx) }} className={selEnqId === idx ? 'selected' : ''}
                                style={{
                                    display: (status == 0) ? "table-row" :
                                        status === obj.statusId && filterCount[(status / 10) - 1].selected ? "table-row" : "none"
                                }} key={idx} title={
                                    'Enquiry Date : ' + !obj.enquiryDt ? '-' : obj.enquiryDt + '\u000a'
                                        + 'Assign Date : ' + !obj.assignDt ? '-' : obj.assignDt + '\u000a'
                                            + 'FollowUp Date : ' + !obj.followUpDt ? '-' : obj.followUpDt + '\u000a'
                                                + 'Regs. Date : ' + !obj.regDt ? '-' : obj.regDt + '\u000a'
                                                    + 'Regs. Fee Date : ' + !obj.regFeeDt ? '-' : obj.regFeeDt + '\u000a'
                                                        + 'Test Date : ' + !obj.testDt ? '-' : obj.testDt + '\u000a'
                                                            + 'Interview Date : ' + !obj.interviewDt ? '-' : obj.interviewDt + '\u000a'
                                                                + 'Admission Date : ' + !obj.admnDt ? '-' : obj.admnDt + '\u000a'}

                            >

                                <td className="text-left">{obj.name}</td>
                                <td>{obj.contactNo}</td>
                                <td className="text-left">{obj.email}</td>
                                <td>{obj.enquiryDt}</td>
                                <td>{obj.cls}</td>
                                <td className="text-left">{obj.staff}</td>
                                <td className="text-left" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{obj.status}</td>
                                <td><span><img src={getImage(obj.flgEnquiry)} height="20px" alt="" /></span></td>
                                <td><span><img src={getImage(obj.flgAssign)} height="20px" alt="" /></span></td>
                                <td><span><img src={getImage(obj.flgFollowUp)} height="20px" alt="" /></span></td>
                                <td><span><img src={getImage(obj.flgRegistration)} height="20px" alt="" /></span></td>
                                <td><span><img src={getImage(obj.flgRegistrationFee)} height="20px" alt="" /></span></td>
                                <td><span><img src={getImage(obj.flgTest)} height="20px" alt="" /></span></td>
                                <td><span><img src={getImage(obj.flgInterview)} height="20px" alt="" /></span></td>
                                {/* <td><span><img src={getImage(obj.admnStatusId)} height="20px" alt="" /></span></td> */}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <div style={{ height: 400, width: '26%', display: openFnlSlider ? 'block' : 'none', boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 0, 0, 0.5)', background: 'rgb(221,221,221,0.6', top: '26%', padding: '1% 1% 0 1%' }} className='funnelSlider'>
            {/* <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ left: '26%', top: '26%' }} onClick={refreshScreen}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button> */}
            <FunnelSlider
                closeSlider={refreshScreen}
                data={funnelFilterCount}
                flgSlider={flgSlider}
                status={status}
                filterDataByStatus={filterDataByStatus}
            />
        </div>

    </div >;
};

export default EnquiryDashboard;
