import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/timing.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import TimePicker from 'react-time-picker';
import moment from 'moment';


var httpURL = '';
var queryString = require('querystring');
var lstTiming = [];
var lstTimingPeriod = [];
var lstPeriodType = [];
var tokenid = localStorage.getItem("tokenid");
var screenId = 1;
var lstHour = [];
var lstMinutes = [];
var lstAMPM = [];



class Timing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            periodflg: true,
            fromDt: null,
            effectiveDt: null,
            time: '10:00',
            periodStartTm: '',
            tokenid: tokenid,

        }
        this.handleChange1 = this.handleChange1.bind(this);
    }
    onChange = time => this.setState({ time })

    handleChange1(date) {
        this.setState({
            effectiveDt: date,
        });
    }

    componentDidMount = () => {
        this.getTimingList();
        $('.deleteButton').hide();


        axios({
            url: httpURL + '/sms/a/type/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstPeriodType = response.data.lstPeriodType;
                this.fillPeriodTypeCmb();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
        // lstPeriodType = [{ 'periodTypeName': 'Assembly' },
        // { 'periodTypeName': 'Regular' },
        // { 'periodTypeName': 'Lunch' }];

        lstHour = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
        ];
        lstMinutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
        lstAMPM = ["AM", 'PM'];
        this.fillMinutesCmb();
        this.fillAMPMCmb();
        this.fillHourCmb();
    }

    fillHourCmb() {
        $('#hourCmb').empty();
        $('#hourCmb').prepend($("<option> </option>").val(0).html('HH'));
        for (var i = 0; i < lstHour.length; i++) {
            var hour = lstHour[i];
            $('#hourCmb').append($("<option> </option>").val(hour).html(hour));
        }

    }
    fillMinutesCmb() {
        $('#minuteCmb').empty();
        $('#minuteCmb').prepend($("<option> </option>").val(0).html('MM'));
        for (var i = 0; i < lstMinutes.length; i++) {
            var minute = lstMinutes[i];
            $('#minuteCmb').append($("<option> </option>").val(minute).html(minute));
        }

    }
    fillAMPMCmb() {
        $('#ampmCmb').empty();
        for (var i = 0; i < lstAMPM.length; i++) {
            var ampm = lstAMPM[i];
            $('#ampmCmb').append($("<option> </option>").val(ampm).html(ampm));
        }

    }

    fillPeriodTypeCmb() {
        $('#periodTypeCmb').empty();
        $('#periodTypeCmb').prepend($("<option> </option>").val(0).html('Period Type'));
        for (var i = 0; i < lstPeriodType.length; i++) {
            var periodType = lstPeriodType[i].periodType;
            $('#periodTypeCmb').append($("<option> </option>").val(periodType).html(periodType));
        }
    }
    getTimingList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/timing/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstTiming = response.data.lstTiming;
                this.setTimingTable();
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }


    setTimingTable = () => {
        $('#timingTableBody tr').remove();
        var table = document.getElementById('timingTable');
        var tbody = document.getElementById('timingTableBody');

        for (var i = 0; i < lstTiming.length; i++) {
            var timingId = lstTiming[i].timingId;
            var effectiveDt = lstTiming[i].effectiveDt;
            var startTm = lstTiming[i].startTm;
            var endTm = lstTiming[i].endTm;
            var periods = lstTiming[i].periods;
            // var wing = lstTiming[i].wing;
            // var wingId = lstTiming[i].wingId;





            var row = document.createElement('tr');
            row.id = timingId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setTimingForm($(e.target).closest('tr').attr('id'));
                this.getTimingPeriodList($(e.target).closest('tr').attr('id'));
                this.setPeriodTable();
                var startTime = $('#timingTableBody tr.selected').find('td:eq(2)').text();
                this.setState({
                    flg: false,
                    periodStartTm: startTime,
                });
                $('#periodStartTime').val(this.state.periodStartTm);
            }

            this.addTD(row, timingId, 'no-display');
            this.addTD(row, effectiveDt, 'text-center');
            this.addTD(row, startTm, 'text-center');
            this.addTD(row, endTm, 'text-center');
            this.addTD(row, periods, 'text-center');



            tbody.append(row);
        }
        table.append(tbody);
    }

    setTimingForm = (timingId) => {
        var timingData = this.getTimingData(timingId);

        var hour = timingData.startTm.substring(0, 2);
        var minute = timingData.startTm.substring(3, 5);
        var ampm = timingData.startTm.substring(6, 8);
        $('#hourCmb').val(hour);
        $('#minuteCmb').val(minute);
        $('#ampmCmb').val(ampm);
        $('#noOfPeriods').val(timingData.periods);

        var date = timingData.effectiveDt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var effectiveDt = new Date(setDate);
        this.setState({
            effectiveDt: effectiveDt,
        });


    }

    setPeriodForm = (timingId) => {
        var periodData = this.getPeriodData(timingId);
        // console .log(periodData);
        $('#period').val(periodData.period);
        $('#periodStartTime').val(periodData.startTm);
        $('#periodTypeCmb').val(periodData.periodType);
        $('#duration').val(periodData.duration);



    }

    getTimingData(timingId) {
        for (var i = 0; i < lstTiming.length; i++) {
            // console .log(lstTiming.length);
            if (parseInt(timingId) === lstTiming[i].timingId) {
                return lstTiming[i];

            }

        }
    }

    getPeriodData(timingPeriodId) {
        for (var i = 0; i < lstTimingPeriod.length; i++) {
            // console .log(lstTimingPeriod.length);
            if (parseInt(timingPeriodId) === lstTimingPeriod[i].timingPeriodId) {
                return lstTimingPeriod[i];

            }

        }
    }


    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addTiming = () => {
        var timingArr = {};
        var wingId = $('#wingCmb').find('option:selected').val();
        var timingId = $('#timingTableBody tr.selected').find('tq:eq(0)').text();
        var effectiveDt = $('#effectiveDt').val();
        var hour = $('#hourCmb').find('option:selected').text();
        var minute = $('#minuteCmb').find('option:selected').text();
        var ampm = $('#ampmCmb').find('option:selected').val();

        var startTm = hour + ":" + minute + ampm;
        var periods = $('#noOfPeriods').val();


        if (effectiveDt === "") {
            toast.warning('Please Select Date');
            $('#effectiveDt').focus();
            return false;
        }

        if (hour === "HH" || minute === 'MM') {
            toast.warning('Please Select Hour and Minute');
            $('#hourCmb').focus();
            return false;
        }
        if (periods === "") {
            toast.warning('Please Enter Number of period');
            $('#noOfPeriods').focus();
            return false;
        }

        if (this.state.flg) {
            timingId = 0;
        } else {
            timingId = $('#timingTableBody tr.selected').find('td:eq(0)').text();
        }

        timingArr = {
            'timingId': timingId, 'wingId': wingId, 'periods': periods, 'effectiveDt': effectiveDt, 'startTm': startTm,
        }
        this.setState({ loader: 'block', opacity: 0.5 });


        axios({
            url: httpURL + '/sms/mst/timing/save',
            method: "POST",
            params: { 'timing': JSON.stringify(timingArr), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getTimingList();
                this.setTimingTable();
                this.addTimingReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });

    }

    addTimingReady = () => {
        $('#timingAddForm').trigger('reset');
        $('#timingTableBody tr.selected').removeClass('selected');
        $('.deleteButton').hide();

        lstTimingPeriod = [];
        this.setPeriodTable();

        this.setState({
            effectiveDt: null,
            flg: true,
        });
    }





    getTimingPeriodList = (timingId) => {
        lstTimingPeriod = [];
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/timing/period/list',
            method: "POST",
            params: { 'timingId': timingId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstTimingPeriod = response.data.lstTimingPeriod;
                this.setPeriodTable();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    addPeriod = () => {
        var period = $('#period').val();
        var startTm = $('#periodStartTime').val();
        var timingPeriodId = 0;
        var timingId = $('#timingTableBody tr.selected').find('td:eq(0)').text();
        var periodType = $('#periodTypeCmb').find('option:selected').text();
        var duration = $('#duration').val();
        var timingPeriod = {};
        var maxPeriods = $('#timingTableBody tr.selected').find('td:eq(4)').text();;
        var adjustTimings = $('#adjustTimings').prop('checked') === true ? 1 : 0;
        // var tm = moment.utc(startTm, 'hh:mm').add(duration, 'minutes').format('hh:mm');
        // this.setState({
        //     periodStartTm: tm,
        // });

        if (period === "") {
            toast.warning('Please Enter Period');
            $('#period').focus();
            return false;
        }
        if (periodType === "Period Type") {
            toast.warning('Please Select Valid Period Type');
            $('#periodTypeCmb').focus();
            return false;
        }
        if (duration === "") {
            toast.warning('Please Enter Duration');
            $('#duration').focus();
            return false;
        }

        if (this.state.periodflg) {
            timingPeriodId = 0;
        } else {
            timingPeriodId = $('#periodTableBody tr.selected').find('td:eq(0)').text();
        }
        timingPeriod = { timingPeriodId, timingId, 'period': period, 'startTm': startTm, 'periodType': periodType, 'duration': duration, adjustTimings };


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/mst/timing/period/save',
            queryString.stringify({ 'timingPeriod': JSON.stringify(timingPeriod), screenId, maxPeriods }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getTimingPeriodList(timingId);
                this.cancelPeriod();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });



    }

    setPeriodTable = () => {
        $('#periodTableBody tr').remove();
        var table = document.getElementById('periodTable');
        var tbody = document.getElementById('periodTableBody');

        for (var i = 0; i < lstTimingPeriod.length; i++) {
            var timingPeriodId = lstTimingPeriod[i].timingPeriodId;
            var period = lstTimingPeriod[i].period;
            var periodType = lstTimingPeriod[i].periodType;
            var startTm = lstTimingPeriod[i].startTm;
            var duration = lstTimingPeriod[i].duration;

            var row = document.createElement('tr');
            row.id = timingPeriodId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setPeriodForm($(e.target).closest('tr').attr('id'));
                $('body').addClass('showPopup');
                $('.deleteButton').show();
                this.setState({
                    periodflg: false,
                })
            }

            this.addTD(row, timingPeriodId, 'no-display');
            this.addTD(row, period, 'text-center');
            this.addTD(row, startTm, 'text-center');
            this.addTD(row, periodType, 'text-center');
            this.addTD(row, duration, 'text-center');




            tbody.append(row);
        }
        table.append(tbody);
    }


    addPeriodReady = () => {
        $('#periodAddForm').trigger('reset');
        $('body').addClass('showPopup');
        $('#periodTableBody tr.selected').removeClass('selected');
        var tm = "";

        $('#periodTableBody tr').each(function (index, value) {
            var startTm = $(this).find('td:eq(2)').text();
            var duration = $(this).find('td:eq(4)').text();
            tm = moment.utc(startTm, 'hh:mm a').add(duration, 'minutes').format('hh:mm a');

        });
        if (tm === "") {
            var startTime = $('#timingTableBody tr.selected').find('td:eq(2)').text();
            $('#periodStartTime').val(startTime);
        }
        else {
            $('#periodStartTime').val(tm);
        }

        $('.deleteButton').hide();
        this.setState({
            periodflg: true,
        })

    }
    cancelPeriod = () => {
        $('body').removeClass('showPopup');
    }

    deletePeriod = () => {
        var retVal = window.confirm("Do you want to Delete this Period   ?");
        if (retVal === true) {
            this.deletePeriodConfirm();
            return true;
        } else {
            return false;
        }
    }

    deletePeriodConfirm = () => {
        var timingPeriodId = $('#periodTableBody tr.selected').find('td:eq(0)').text();
        var timingId = $('#timingTableBody tr.selected').find('td:eq(0)').text();
        var adjustTimings = $('#adjustTimings').prop('checked') === true ? 1 : 0;
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/mst/timing/period/delete',
            queryString.stringify({ 'timingPeriodId': timingPeriodId, screenId, adjustTimings }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getTimingPeriodList(timingId);
                this.cancelPeriod();
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }





    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['timings'].url + ":" + lstModules['timings'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Timing" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-7  col-xs-6 timingTableWrap  padding-remove">
                            <table className="tableLayout1 timingTable" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Timing Id </th>
                                        <th style={{ width: 40 }}>Effective  Date </th>
                                        <th style={{ width: 40 }}> Start Time </th>
                                        <th style={{ width: 40 }}> End Time </th>
                                        <th style={{ width: 40 }}> Period </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="timeTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="timingTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="timingTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addTimings" title="Add Timing" onClick={this.addTimingReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-4 col-xs-4 padding-remove timingFormWrap" >
                            <form id="timingAddForm" className="form-horizontal formWrapper">
                                {/* <div className="form-group col-md-6 padding-remove">
                                    <label className="control-label col-sm-6 padding-remove" > From Class <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="fromClass" type="text" className="form-control " placeholder="From Class" />
                                    </div>
                                </div>
                                <div className="form-group col-md-6 padding-remove">
                                    <label className="control-label col-sm-6 padding-remove" > To Class <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="noOfPeriods" type="text" className="form-control " placeholder="To Class" />
                                    </div>
                                </div> */}
                                <div className="form-group col-md-11 padding-remove">
                                    <label className="control-label col-sm-6" > Effective Date <span style={{ color: 'red', marginLeft: 2 }}>* </span>  </label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="effectiveDt"
                                            className='form-control'
                                            placeholderText="Date"
                                            selected={this.state.effectiveDt}
                                            onChange={this.handleChange1}
                                            dateFormat="dd-MM-yyyy"
                                            autoComplete='off'
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            dropdownMode="select"
                                        // minDate={new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-11 padding-remove">
                                    <label className="control-label col-sm-6" >Start Time <span style={{ color: 'red', marginLeft: 2 }}>* </span>  </label>
                                    <div className="col-sm-6 padding-remove startTimeCmb">
                                        <select className="col-sm-4 form-control" id="hourCmb"></select>
                                        <select className="col-sm-4 form-control" id="minuteCmb"></select>
                                        <select className="col-sm-4 form-control" id="ampmCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group col-md-11 padding-remove">
                                    <label className="control-label col-sm-6 " > Periods <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="noOfPeriods" type="text" className="form-control " placeholder="Number Of Periods" />
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginTop: 10 }}>
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" title="Save" onClick={this.addTiming} className="btn btn-info saveTiming">Save</button>
                                    </div>
                                </div>
                            </form>
                            <div className="col-sm-12 padding-remove periodTableWrap">
                                <div className="col-sm-11 padding-remove">
                                    <table className="tableLayout1 periodTable" style={{ width: this.state.width }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: 40 }}> Period</th>
                                                <th style={{ width: 40 }}> Start Time </th>
                                                <th style={{ width: 40 }}> Type</th>
                                                <th style={{ width: 40 }}> Duration(Min)</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="periodTableDiv" style={{ height: '200px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                        <table id="periodTable" className="tableLayout">
                                            <colgroup>
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                            </colgroup>
                                            <tbody id="periodTableBody"></tbody>
                                        </table>
                                    </div>
                                    {/* <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" title="Save" onClick={this.addPeriodTiming} className="btn btn-info">Save</button>
                                    </div> */}
                                </div>
                                <div className="col-xs-1 addPeriods">
                                    <span id="addPeriods" title="Add Period" onClick={this.addPeriodReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 col-xs-8 padding-remove popup" >
                            <form id="periodAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" > Period <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="period" type="text" className="form-control " placeholder="Period" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" > Start Time <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="periodStartTime" type="text" className="form-control " placeholder="Start Time" />

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" > Period Type</label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changePeriodType} className="form-control" style={{ width: '100%' }} id="periodTypeCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Duration<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="duration" type="text" className="form-control " placeholder="Duration" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Adjust Timings<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div>
                                        <input autoComplete="off" id="adjustTimings" type="checkbox" className="form-control " />
                                    </div>
                                </div>


                                <div className="form-group" style={{ marginTop: 10 }}>
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" title="Save" onClick={this.addPeriod} className="btn btn-info">Save</button>
                                        <button type="button" title="Cancel" onClick={this.cancelPeriod} className="btn btn-info">Cancel</button>
                                        <button type="button" title="Delete" onClick={this.deletePeriod} className="btn btn-danger deleteButton">Delete</button>
                                    </div>
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>

        );
    }



}
export default Timing;