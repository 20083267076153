import React, { useState, useEffect, useMemo } from 'react'
import '../css/EnquiryCtrl.css'
import 'common/css/master.css'
import SliderType from './sliderType'
import { getTestListSvc } from './EvaluateTestSvc'
import { toast, ToastContainer } from 'react-toastify'

const EvaluateTestTable = props => {
  const [lstTest, setLstTest] = useState([])
  const [lstTestSlotId, setLstTestSlotId] = useState([])
  const [lstSelTestId, setLstSelTestId] = useState([])
  const [testSlotId, setTestSlotId] = useState(0)
  const [loader, setLoader] = useState(props.loader)

  function cbTestListResponse (data) {
    if (data.SvcStatus === 'Success') {
      // console .log('Test list fetching success')
      setLstTest(data.lstTest)
      setLoader('none')
    } else {
      toast.error(data.SvcMsg)
      setLoader('none')
    }
  }
  useEffect(() => {
    setLoader('block')
    // console .log('EvaluateTestTable' + props.clsId)
    if (
      props.admissionId &&
      props.clsId &&
      props.flgSliderType === SliderType.SLIDER_TEST_RESULT
    )
      getTestListSvc(cbTestListResponse, props.admissionId, props.clsId)
  }, [props.admissionId, props.clsId, props.flgSliderType])

  function selectLstTest (testId, testSlotId, status) {
    // console .log('Clicked on checkbox::' + testId + status)
    var lstTestSlotIdTemp = []
    if (status && !lstTestSlotId.includes(testSlotId)) {
      // lstTestSlotIdTemp = lstTestSlotId;
      lstTestSlotIdTemp.push(testSlotId)
      setLstTestSlotId(lstTestSlotIdTemp)
      // console .log('testSlotId array  ' + lstTestSlotIdTemp)
    } else {
      // lstTestSlotIdTemp = lstTestSlotId;
      lstTestSlotIdTemp.splice(lstTestSlotIdTemp.indexOf(testSlotId), 1)
      setLstTestSlotId(lstTestSlotIdTemp)
      // console .log('Selected Test Slot Id ' + lstTestSlotIdTemp)
    }

    var lstTempArr = []
    if (status && !lstSelTestId.includes(testId)) {
      lstTempArr.push(testId)
      setLstSelTestId(lstTempArr)
      // console .log('checked  ' + lstTempArr)
    } else {
      lstTempArr.splice(lstTempArr.indexOf(testId), 1)
      setLstSelTestId(lstTempArr)
      // console .log('now checked ' + lstTempArr)
    }
    var p2 = new Promise(function (resolve, reject) {
      resolve(returnSelTestId())
    })

    p2.then(function () {
      returnSelTestSlotId()
    })
  }
  function returnSelTestId () {
    // console .log('setSelectedTestId triggered in TestTable::' + lstSelTestId)
    props.setSelectedTestId(lstSelTestId)
  }

  function returnSelTestSlotId () {
    // console .log('setSelectedTestSlotId triggered in TestTable')
    props.setSelectedTestSlotId(lstTestSlotId)
  }

  function selectTestSlot (param) {
    // console .log('TestSlotId ' + param)
    setTestSlotId(param)
    props.setTestSlotId(param)
  }
  const colgrp = props.allowMultiSelect ? (
    <colgroup>
      <col width='8%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='8%'></col>
      <col width='5%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='5%'></col>
      <col width='8%'></col>
      <col width='5%'></col>
    </colgroup>
  ) : (
    <colgroup>
      <col width='8%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='8%'></col>
      <col width='5%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='5%'></col>
      <col width='8%'></col>
    </colgroup>
  )
  return (
    <div>
      <div
        className='col-sm-12'
        style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
      >
        <ToastContainer autoClose={3000} hideProgressBar />

        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>

        <table className='tableLayout1'>
          <thead>
            <tr>
              <th>Test List</th>
            </tr>
          </thead>
        </table>
        <table className='tableLayout1'>
          {colgrp}
          <thead>
            <tr>
              <th>Test</th>
              <th>Passing Score</th>
              <th>Max Score</th>
              <th>Duration (Mins)</th>
              <th>Mandatory</th>
              <th>Test Date</th>
              <th>Slot Id</th>
              <th>Candidate Informed On</th>
              <th>Evaluator</th>
              <th>Evaluator Informed On</th>
              <th>Result</th>
              <th>Status</th>
              <th
                style={{
                  display: props.allowMultiSelect ? 'table-cell' : 'none'
                }}
              >
                Select
              </th>
            </tr>
          </thead>
        </table>
        <div className='tablebodyWrapper'>
          <table className='tableLayout'>
            {colgrp}
            <tbody>
              {lstTest &&
                lstTest.length !== 0 &&
                lstTest.map((test, idx) => {
                  return (
                    <tr
                      key={test.testSlotId}
                      onClick={e => selectTestSlot(test.testSlotId)}
                      className={
                        test.testSlotId === testSlotId ? 'selected' : ''
                      }
                    >
                      <td>{test.test}</td>
                      <td>{test.passScore}</td>
                      <td>{test.maxScore}</td>
                      <td>{test.duration}</td>
                      <td>{test.mandatory ? 'Yes' : '-'}</td>
                      <td>{test.slotDt}</td>
                      <td>{test.slotId > 0 ? test.slotId : '-'}</td>
                      <td>{test.candInformDt}</td>
                      <td>{test.evaluator}</td>
                      <td>{test.evalInformDt}</td>
                      <td>{test.result}</td>
                      <td>{test.status}</td>
                      <td
                        style={{
                          display: props.allowMultiSelect
                            ? 'table-cell'
                            : 'none'
                        }}
                      >
                        <input
                          type='checkbox'
                          onClick={e =>
                            selectLstTest(
                              test.testId,
                              test.testSlotId,
                              e.target.checked
                            )
                          }
                          /* onClick={(e) =>
                                          selectLstTestSlotId(test.testSlotId, e.target.checked)
                                        } */
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default EvaluateTestTable
