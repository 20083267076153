import React, { Component, useEffect, useState } from "react";
import LoaderImage from "common/js/LoaderImage";
function ViewBillPdf(props) {
  return (
    <div>
      <div>
        {/* <button
          type="button"
          className="btn btn-info text-center "
          id="closeSlider"
          style={{ right: "71%", opacity: 1 }}
          onClick={props.closeSlider}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button> */}
      </div>
      <div className="pdf" style={{ width: "100%", height: "96vh" }}>
        <iframe
          src={require("common/images/loader.gif")}
          title="Outstanding Fee"
          id="viewer"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
}
export default ViewBillPdf;
