import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

// project related common imports

import lstModules from 'common/json/lstModules.json';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import TermHeader from './termHeader.js';
import ExamTable from './examTable.js';
import ExamForm from './examForm.js';
import 'sms/tt/header/css/SectionList.css'

const ExamCtrl = () => {
    const [tableLoader, setTableLoader] = useState('none')
    const [formLoader, setFormLoader] = useState('none')
    const [termId, setTermId] = useState(0);
    const [examId, setExamId] = useState(0);
    const [examObj, setExamObj] = useState({});
    const [term, setTerm] = useState('');
    const [randNo, setRandNo] = useState(0)
    //  const [flgSlider, setFlgSlider] = useState(false)

    function termClick(pTermId, pTerm) {
        setTermId(pTermId);
        setTerm(pTerm);
        setExamObj({});
        // console .log('term=' + pTerm)
    }

    function clickExam(pExamObj) {
        setExamObj(pExamObj);
        setExamId(pExamObj.examId)
        // console .log('ctrl::' + JSON.stringify(pExamObj))
        //        setFlgSlider(true)
    }
    function generateRandomNo(pExamId) {
        setExamId(pExamId)
        setRandNo(Math.floor(Math.random() * 999999))

    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="MainContainer" >
                <div className="col-md-12 col-centered wingsWrap clearfix">
                    <TermHeader termId={termId} termClick={termClick} />
                </div>
                <div className="col-md-11 col-centered clearfix" style={{ marginTop: '2%' }}>
                    <div className="col-md-12 padding-remove">
                        <div className="col-md-8 col-xs-12 padding-remove">
                            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: tableLoader }}>
                                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                            </div>
                            <ExamTable key={randNo} termId={termId} setExamId={setExamId} clickExam={clickExam} examId={examId}/>
                        </div>

                        <div className="col-md-4 col-xs-6" >
                            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: formLoader }}>
                                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                            </div>
                            <ExamForm setFormLoader={setFormLoader} termId={termId} term={term} examObj={examObj} refreshTable={generateRandomNo} clickExam={clickExam}/>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default ExamCtrl