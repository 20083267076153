import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['payrollSetup'].url + ":" + lstModules['payrollSetup'].port;
function getPayrollMonthProcessListSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/month/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
function saveProcessPayroll(pMonthId, pflgOverride, callBack) {

    axios({
        url: httpURL + "/sms/payroll/month/process",
        method: "POST",
        params: { yrMonth: pMonthId, flgOverride: pflgOverride },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        console.error(error) 
    });
}
function getPayrollView(pYrMonth, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/month/salary_register",
            method: 'POST',
            params: { yrMonth: pYrMonth },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data);
        }
    ).catch((error) => { 
        console.error(error) 
    });
}
function getStaffSalarySlip(pYrMonth, pstaffId, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/month/salary_slip/view",
            method: 'POST',
            params: { yrMonth: pYrMonth, staffId: pstaffId },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer'
        }
    ).then(
        (Response) => {
            // console .log(Response)
            var data = Response
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Catch error")
    });
}
function getAllowanceData(callback) {
    axios(
        {
            url: httpURL + "/sms/allowance/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callback(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getDeductionData(callback) {
    axios(
        {
            url: httpURL + "/sms/deduction/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callback(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getSalaryRegisterExcel(pYrMonth, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/month/salary_register/excel",
            method: 'POST',
            params: { yrMonth: pYrMonth },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer'
        }
    ).then(
        (Response) => {
            // console .log(Response)
            var data = Response
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Catch error")
    });
}
function getBankTransferExcel(pYrMonth, pChequeNo, pLstStaffTypeId, callBack) {
    var formData = new FormData();
    formData.append("yrMonth",pYrMonth)
    formData.append("chequeNo",pChequeNo)
    formData.append("lstStaffTypeId",JSON.stringify(pLstStaffTypeId))
    axios(
        {
            url: httpURL + "/sms/payroll/month/bank_tfr/excel",
            method: 'POST',
            data:formData,
            //params: { yrMonth:pYrMonth,chequeNo:pChequeNo,lstStaffTypeId:JSON.stringify(pLstStaffTypeId)},
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer'
        }
    ).then(
        (Response) => {
            // console .log(Response)
            var data = Response
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Catch error")
    });
}
function getStaffTypeList(callBack) {
    axios(
        {
            url: httpURL + "/sms/staff_type/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getSalaryRegisterSlip(pYrMonth, callBack) {
    axios(
        {
            url: httpURL + "/sms/payroll/month/salary_register/pdf",
            method: 'POST',
            params: { yrMonth: pYrMonth},
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer'
        }
    ).then(
        (Response) => {
            // console .log(Response)
            var data = Response
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Catch error")
    });
}
function getBankTransferSlip(pYrMonth, pChequeNo, pLstStaffTypeId, callBack) {
    var formData = new FormData();
    formData.append("yrMonth",pYrMonth)
    formData.append("chequeNo",pChequeNo)
    formData.append("lstStaffTypeId",JSON.stringify(pLstStaffTypeId))
    axios(
        {
            //url: httpURL + "/sms/payroll/month/bank_tfr/pdf",
            url:"",
            method: 'POST',
            data:formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer'
        }
    ).then(
        (Response) => {
            // console .log(Response)
            var data = Response
            callBack(data)
        }
    ).catch((error) => {
        // console .log("Catch error")
    });
}
export { getPayrollMonthProcessListSvc, saveProcessPayroll, getPayrollView, getStaffSalarySlip, getAllowanceData };
export { getDeductionData, getSalaryRegisterExcel, getBankTransferExcel, getStaffTypeList,getSalaryRegisterSlip,getBankTransferSlip };