import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getDashboardSummaryList } from './DashboardSummarySvc'
import "../css/dashboardSummary.css";

var arr = []
var tmpSelLstStatusId = []

const DashboardSummary = (props) => {
    const [lstSummary, setLstSummary] = useState([])

    useEffect(() => {
        // console .log(props.selStatusId)
        getDashboardSummaryList(props.examId, cbDashboardSummaryResponse)
    }, [props])

    function cbDashboardSummaryResponse(obj) {
        if (obj.status === "Success") {
            for (var i = 0; i < obj.lstSummary.length; i++) {
                arr[i] = 0;
                tmpSelLstStatusId = []
            }
            setLstSummary(obj.lstSummary)
        } else if (obj.status === 'Failure') {

            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {

            toast.error("Service failed " + obj.message);
        }
    }

    function clickStatus(pIndx, pStatusId) {
        arr[pIndx] = arr[pIndx] + 1;
        if (arr[pIndx] % 2 === 0) {
            tmpSelLstStatusId.splice(tmpSelLstStatusId.indexOf(pStatusId), 1)
            props.clickStatus('')
            return;
        }
        else {
            if (!tmpSelLstStatusId.includes(pStatusId))
                tmpSelLstStatusId.push(pStatusId)

        }
        // console .log(pStatusId);
        props.clickStatus(pStatusId)
    }

    return (
        <>
            {lstSummary.map((obj, idx) => {

                var isSelected = obj.statusId === props.selStatusId;
                return <div key={obj.statusId} className={"col-sm-4 padding-remove"} style={{ marginTop: 0 }} onClick={() => clickStatus(idx, obj.statusId)}>
                    <div className="circle_status_summary col-sm-12 col-centered" style={{ background: isSelected ? '#4a86e8' : '', color: isSelected ? '#fff' : '' }}>
                        <div className="div_status_count col-sm-12 col-centered" >{obj.count}</div>
                    </div>
                    <div className="text-center">{obj.status}</div>
                </div>
            })}
        </>
    )
}

export default DashboardSummary