import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';

import { getClassListSvc, sectionListSvc, testScheduleDtlsSvc, saveTestScheduleSvc } from "./WTestScheduleSvc";


const TestScheduleFormSlider = (props) => {

    const { testScheduleId, testBatchId, setTestScheduleId, lstEvaluationMode, lstTestMode, updateKey, closeSlider } = props;

    const [loader, setLoader] = useState("none")
    const [testScheduleDtls, setTestScheduleDtls] = useState({});
    const [test, setTest] = useState("");
    const [fromDt, setFromDt] = useState(null);
    const [toDt, setToDt] = useState(null);
    const [dayId, setDayId] = useState(0);
    const [noOfQus, setNoOfQus] = useState(0);
    const [testModeId, setTestModeId] = useState(0);
    const [evaluationModeId, setEvaluationModeId] = useState(0);
    const [noOfBatches, setNoOfBatches] = useState(0);
    const [batchNo, setBatchNo] = useState(0);
    const [seatAllocationId, setSeatAllocationId] = useState(0);
    const [testDuration, setTestDuration] = useState("");
    const [lstCls, setLstCls] = useState([]);
    const [clsId, setClsId] = useState(0);
    const [lstSection, setLstSection] = useState([]);
    const [sectionId, setSectionId] = useState(0);
    const [separateAnsSheet, setSeperateAnsSheet] = useState(false);

    const [startHour, setStartHour] = useState("");
    const [startMinute, setStartMinute] = useState("");
    const [startAMPM, setStartAMPM] = useState("AM");
    const [endHour, setEndHour] = useState("");
    const [endMinute, setEndMinute] = useState("");
    const [endAMPM, setEndAMPM] = useState("AM");

    const [flgDurationVisible, setFlgDurationVisible] = useState(false);
    const [flgEvaluationModeEditable, setFlgEvaluationModeEditable] = useState(false);
    const [flgTimeEditable, setFlgTimeEditable] = useState(false);
    const [leaderboardCount, setLeaderboardCount] = useState(3);

    const lstSeatAllocation = [
        { seatAllocationId: 10, seatAllocation: "Round Robin" },
        { seatAllocationId: 20, seatAllocation: "Random" },
        { seatAllocationId: 30, seatAllocation: "Roll No. wise" }
    ];

    const daysOfWeek = [
        { dayId: 1, day: 'Monday' },
        { dayId: 2, day: 'Tuesday' },
        { dayId: 3, day: 'Wednesday' },
        { dayId: 4, day: 'Thursday' },
        { dayId: 5, day: 'Friday' },
        { dayId: 6, day: 'Saturday' },
        { dayId: 7, day: 'Sunday' }
    ];

    const lstHour = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const lstMinutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
    const lstAMPM = ["AM", 'PM'];


    useEffect(() => {
        setTestScheduleId(testScheduleId);
        getLstClass();
        if (!!testScheduleId && testBatchId) {
            getScheduleDtls(testScheduleId, testBatchId);
        }
    }, [testScheduleId, testBatchId]);

    function handleFromDtChange(pDt) {
        // console .log("handleFromDtChange called, pDt", pDt, ", dtToDMY(pDt)", dtToDMY(pDt));
        setFromDt(dtToDMY(pDt))
    }
    function handleToDtChange(pDt) {
        // console .log("handleToDtChange called, pDt", pDt, ", dtToDMY(pDt)", dtToDMY(pDt));
        setToDt(dtToDMY(pDt));
    }
    function dtToDMY(str) {
        var date = new Date(str),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2)
        return [day, mnth, date.getFullYear()].join('-')
    }
    function dmyToDate(dateString) {
        if (!dateString || !/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
            return null;
        }
        const parts = dateString.split('-');
        const [day, month, year] = parts;
        return new Date(year, month - 1, day);
    }

    const getScheduleDtls = (pTestScheduleId, pTestBatchId) => {
        // console .log("getScheduleDtls called, pTestScheduleId", pTestScheduleId, ", pTestBatchId", pTestBatchId);
        setLoader("block");
        testScheduleDtlsSvc(pTestScheduleId, testBatchId, scheduleDtlsResponse);
    }

    const scheduleDtlsResponse = (data) => {
        // console .log("scheduleDtlsResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setTestScheduleDtls(obj.testScheduleDtls);
            setFormData(obj.testScheduleDtls);
            // console .log("obj.testScheduleDtls", obj.testScheduleDtls);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
    }

    function getLstClass() {
        setLoader("block");
        getClassListSvc(cbClsResponse);
    }

    function cbClsResponse(data) {
        // console .log("cbClsResponse called, data", data);
        const obj = data
        if (obj.status === 'Success') {
            setLstCls(obj.lstClass);
            if (obj.getLstClass && obj.getLstClass.length && !!clsId) {
                getSectionList(clsId);
            }
        } else if (obj.status === 'Failure') {
            setLstCls([])
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error('Service failed ' + obj)
        }
        setLoader("none");
    }

    function handleTestChange(param) {
        setTest(param);
    }

    function handleDayIdChange(param) {
        setDayId(param);
    }

    function saveTestSchedule() {
        // console .log("saveTestSchedule called");
        const startTm = (startHour && startMinute ? startHour + ":" : "") + (startHour && startMinute ? startMinute + " " : "") + (startHour && startMinute ? startAMPM : "");
        const endTm = (endHour && endMinute ? endHour + ":" : "") + (endHour && endMinute ? endMinute + " " : "") + (endHour && endMinute ? endAMPM : "");

        // Validation functions
        const validateString = (value, fieldName) => {
            // console .log("validateString called, value", value, ", fieldName", fieldName);
            if (!value || value.trim() === '') {
                return false;
            }
            return true;
        };

        const validateNumber = (value, fieldName) => {
            if (!value || isNaN(value)) {
                return false;
            }
            return true;
        };

        const validateTimeFormat = (time, fieldName) => {
            const timeRegex = /^(0?[1-9]|1[012]):([0-5]\d)\s([APap][mM])$/;
            if (!timeRegex.test(time)) {
                return false;
            }
            return true;
        };

        // Validate parameters
        if (!validateNumber(clsId, "Class ID")) {
            toast.error("Please select class!");
            return;
        }
        if (!validateNumber(sectionId, "Section ID")) {
            toast.error("Please select section!");
            return;
        }
        if (!validateString(test, "Test")) {
            toast.error("Test name is required.");
            return;
        }
        if (!validateString(fromDt, "From Date")) {
            toast.error("From Date is required.");
            return;
        }
        if (!validateString(toDt, "To Date")) {
            toast.error("To Date is required.");
            return;
        }
        if (!validateNumber(dayId, "Day")) {
            toast.error("Please select day!.");
            return;
        }
        if (!validateNumber(noOfQus, "Number of Questions")) {
            toast.error("Please enter number of questions!");
            return;
        }
        if (!validateNumber(testModeId, "Test Mode")) {
            toast.error("Please choose Test Mode!");
            return;
        }
        if (!validateNumber(evaluationModeId, "Evaluation Mode")) {
            toast.error("Please choose Evaluation Mode!");
            return;
        }
        if (!validateNumber(seatAllocationId, "Seat Allocation")) {
            toast.error("Please choose Seat Allocation!");
            return;
        }
        if (flgDurationVisible && !validateNumber(testDuration, "Duration in Minutes")) {
            toast.error("Please enter test duration in minutes!");
            return;
        }
        if (!validateNumber(noOfBatches, "Number of Batches")) {
            toast.error("Number of Batches must be a valid number.");
            return;
        }
        if (flgTimeEditable && !validateTimeFormat(startTm, "Start Time")) {
            toast.error("Please select all fields in Start Time");
            return;
        }
        if (flgTimeEditable && !validateTimeFormat(endTm, "End Time")) {
            toast.error("Please select all fields in End Time");
            return;
        }

        // If all validations pass, proceed to save
        const objTestSchedule = {
            testScheduleId: testScheduleDtls.testScheduleId,
            sectionId: sectionId,
            clsId: clsId,
            test: test,
            fromDt: fromDt,
            toDt: toDt,
            day: dayId,
            noOfQus: noOfQus,
            durationMins: testDuration,
            testMode: testModeId,
            evaluationMode: evaluationModeId,
            noOfBatch: noOfBatches,
            seatAllocation: seatAllocationId,
            startTm: startTm,
            endTm: endTm,
            separateAnsSheet:separateAnsSheet,
            leaderboardCount:leaderboardCount
        };

        // console .log("objTestSchedule", objTestSchedule);
        setLoader("block");
        saveTestScheduleSvc(objTestSchedule, cbSaveTestScheduleResponse);
    }




    function cbSaveTestScheduleResponse(data) {
        // console .log("cbSaveTestScheduleResponse, data", data);
        const obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            updateKey();
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error('Service failed ' + obj)
        }
        setLoader("none");
    }

    const getSectionList = (pClsId) => {
        setLoader("block");
        sectionListSvc(pClsId, sectionListResponse);
    }

    const sectionListResponse = (data) => {
        // console .log("sectionListResponse called, data", data);
        const obj = data
        if (obj.status === 'Success') {
            setLstSection(obj.lstSection);
            if (obj.lstSection.length > 0 && !sectionId) {
                handleSectionChange(obj.lstSection[0].sectionId);
            }
        } else if (obj.status === 'Failure') {
            setLstSection([])
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error('Service failed ' + obj)
        }
        setLoader("none");
    }

    const handleClassChange = async (pClsId) => {
        console .log("handleClassChange called, pClsId", pClsId);
        // console .log("testScheduleId", testScheduleId);
        await setLstSection([]);
        await setSectionId(0);
        await setClsId(pClsId);
        getSectionList(pClsId);
    }

    const handleSectionChange = async (pSectionId) => {
        // console .log("handleSectionChange called, pSectionId", pSectionId);
        await setSectionId(pSectionId);
    }

    const setFormData = (pTestScheduleDtls) => {
        // console .log("setFormData called, pTestScheduleDtls", pTestScheduleDtls);
        handleClassChange(pTestScheduleDtls.clsId);
        setSectionId(pTestScheduleDtls.sectionId);
        setTest(pTestScheduleDtls.test);
        setFromDt(pTestScheduleDtls.fromDt);
        setToDt(pTestScheduleDtls.toDt);
        setDayId(pTestScheduleDtls.day);
        setNoOfQus(pTestScheduleDtls.noOfQus);
        setTestModeId(pTestScheduleDtls.testMode);
        setEvaluationModeId(pTestScheduleDtls.evaluationMode);
        setSeatAllocationId(pTestScheduleDtls.seatAllocation);
        setNoOfBatches(pTestScheduleDtls.noOfBatch);
        setSeperateAnsSheet(pTestScheduleDtls.separateAnsSheet);
        setLeaderboardCount(pTestScheduleDtls.leaderboardCount);

        const startTime = pTestScheduleDtls.startTm.split(/[: ]/);
        setStartHour(startTime[0]);
        setStartMinute(startTime[1]);
        setStartAMPM(startTime[2]);

        // Set the values for end time
        const endTime = pTestScheduleDtls.endTm.split(/[: ]/);
        setEndHour(endTime[0]);
        setEndMinute(endTime[1]);
        setEndAMPM(endTime[2]);

        setTestDuration(pTestScheduleDtls.durationMins);
        setBatchNo(pTestScheduleDtls.batchNo);
        // setTestBatchId(pTestScheduleDtls.testBatchId);
        // setTestScheduleId(pTestScheduleDtls.testScheduleId);
    }

    const handleTestModeChange = async (pTestModeId) => {
        // console .log("handleTestModeChange called, pTestModeId", pTestModeId);
        if (pTestModeId == 10) {
            await setFlgDurationVisible(true);
            await setFlgEvaluationModeEditable(false);
            await setEvaluationModeId(10);
        } else if (pTestModeId == 20) {
            await setFlgDurationVisible(false);
            await setFlgEvaluationModeEditable(false);
            await setEvaluationModeId(20);
        } else {
            await setFlgDurationVisible(true);
            await setFlgEvaluationModeEditable(false);
            await setEvaluationModeId(20);
        }
        await setTestModeId(pTestModeId);
    }

    const handleNoOfBatches = async (pNoOfBatches) => {
        // console .log("handleNoOfBatches called, pNoOfBatches", pNoOfBatches);
        setNoOfBatches(pNoOfBatches);
        if (pNoOfBatches == 1) {
            setFlgTimeEditable(true);
        } else {
            setFlgTimeEditable(false);
        }
    }


    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "50%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >
                <>
                    <div className='row no-display' style={{ width: '100%' }}>
                        <div className="form-group col-md-6">
                            <label className="control-label col-sm-5">Test Schedule Id</label>
                            <div className='col-sm-6 padding-remove'>
                                <input type="text" defaultValue={testScheduleId} className="form-control" readOnly></input>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>Class</label>
                            <div className="form-group col-sm-8">
                                <div className='col-sm-12 padding-remove'>
                                    <div className='col-sm-4 padding-remove'>
                                        <select
                                            autoComplete='off'
                                            name='classId'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={clsId}
                                            onChange={(e) => handleClassChange(e.target.value)}
                                            disabled={testScheduleId !== 0}
                                        >
                                            <option value='0'>Class</option>
                                            {lstCls && lstCls.length ?
                                                lstCls.map(obj => (
                                                    <option value={obj.clsId} key={obj.clsId} id={obj.clsId}>
                                                        {obj.cls}
                                                    </option>
                                                ))
                                                : null
                                            }
                                        </select>
                                    </div>
                                    <div className='tab'>
                                        {lstSection && lstSection.length ?
                                            lstSection.map(obj => {
                                                return (
                                                    <div
                                                        className={obj.sectionId === sectionId ? 'selected' : ''}
                                                        onClick={() => handleSectionChange(obj.sectionId)}
                                                        key={obj.sectionId}
                                                        // style={{
                                                        //     cursor: testScheduleId !== 0 ? 'pointer' : 'none',
                                                        //     pointerEvents: testScheduleId !== 0 ? 'auto' : 'none'
                                                        // }}
                                                    >
                                                        <span hidden>{obj.sectionId}</span>
                                                        <span>{obj.section}</span>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>Test</label>
                            <div className="form-group col-sm-9">
                                <div className='col-sm-8 padding-remove'>
                                    <input type="text" defaultValue={test} onChange={(e) => handleTestChange(e.target.value)} className="form-control"></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row' style={{ width: '100%' }}>
                        <div className="form-group col-md-5 padding-remove">
                            <label className="control-label col-sm-2 " style={{ width: '55%' }}>From Date</label>
                            {/* <label className="control-label col-sm-1 padding-remove"></label> */}
                            {/* <div className='col-sm-1 padding-remove'></div> */}
                            <div className='col-sm-5 padding-remove'>
                                <DatePicker
                                    className="form-control text-center"
                                    placeholderText="From Date"
                                    selected={dmyToDate(fromDt)}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={handleFromDtChange}
                                    autoComplete="off"
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                />
                            </div>

                        </div>
                        <div className="form-group col-md-5" >
                            <label className="control-label col-sm-2 " style={{ width: '40%' }}>To Date</label>
                            <div className='col-sm-5 padding-remove'>
                                <DatePicker
                                    className="form-control text-center"
                                    placeholderText="To Date"
                                    selected={dmyToDate(toDt)}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={handleToDtChange}
                                    autoComplete="off"
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                />

                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>Day</label>
                            <div className="form-group col-sm-3">
                                <div className='col-sm-12 padding-remove'>
                                    <select
                                        autoComplete='off'
                                        name='dayId'
                                        className='form-control '
                                        style={{ height: 30 }}
                                        value={dayId}
                                        onChange={(e) => handleDayIdChange(e.target.value)}
                                    >
                                        <option value='0'>Day</option>
                                        {
                                            daysOfWeek.map(obj => (
                                                <option value={obj.dayId} key={obj.dayId} id={obj.dayId}>
                                                    {obj.day}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>Questions</label>
                            <div className="form-group col-sm-8">
                                <div className='col-sm-4 padding-remove'>
                                    <input
                                        type="number"
                                        defaultValue={!!noOfQus ? noOfQus : ""}
                                        onChange={(e) => setNoOfQus(e.target.value)}
                                        className="form-control"
                                        placeholder="Between 1 and 30"
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%', paddingTop: "10px" }}>Test Mode</label>
                            <div className='col-sm-8 form-group'>
                                {lstTestMode.map(mode => (
                                    <label className="radio-inline" style={{ margin: "0 20px 0 0" }} key={mode.testModeId}>
                                        <input
                                            type="radio"
                                            name="Test Mode"
                                            value={mode.testModeId}
                                            checked={testModeId === mode.testModeId}
                                            onChange={(e) => handleTestModeChange(parseInt(e.target.value))}
                                        />
                                        {mode.testMode}</label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%', paddingTop: "10px" }}>Evaluation</label>
                            <div className='col-sm-8 form-group'>
                                {lstEvaluationMode.map(eMode => (
                                    <label className="radio-inline" style={{ margin: "0 20px 0 0" }} key={eMode.testModeId}>
                                        <input
                                            type="radio"
                                            name="Evaluation Mode"
                                            value={eMode.evaluationModeId}
                                            checked={evaluationModeId === eMode.evaluationModeId}
                                            onChange={(e) => setEvaluationModeId(parseInt(e.target.value))}
                                            disabled={!flgEvaluationModeEditable}
                                        />
                                        {eMode.evaluationMode}</label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%', paddingTop: "10px" }}>Seats Allocation</label>
                            <div className='col-sm-8 form-group'>
                                {lstSeatAllocation.map(allocation => (
                                    <label className="radio-inline" style={{ margin: "0 20px 0 0" }} key={allocation.seatAllocationId}>
                                        <input
                                            type="radio"
                                            name="Seat Allocation"
                                            value={allocation.seatAllocationId}
                                            checked={seatAllocationId === allocation.seatAllocationId}
                                            onChange={(e) => setSeatAllocationId(parseInt(e.target.value))}
                                            disabled={testScheduleId !== 0}
                                        />
                                        {allocation.seatAllocation}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%', paddingTop: "10px" }}>Top Rankers</label>
                            <div className='col-sm-8 form-group'>
                                    <label className="radio-inline" style={{ margin: "0 20px 0 0" }} >
                                        <input
                                            type="radio"
                                            name="3 Top Rankers"
                                            value={3}
                                            checked={leaderboardCount === 3}
                                            onChange={(e) => setLeaderboardCount(3)}
                                        />
                                        3</label>
                                    <label className="radio-inline" style={{ margin: "0 20px 0 0" }} >
                                        <input
                                            type="radio"
                                            name="5 Top Rankers"
                                            value={5}
                                            checked={leaderboardCount === 5}
                                            onChange={(e) => setLeaderboardCount(5)}
                                        />
                                        5</label>
                            </div>
                        </div>
                    </div> 
                    {
                        flgDurationVisible ?
                            <div className='row' style={{ width: '100%' }}>
                                <div className='form-group' style={{ width: '100%' }}>
                                    <label className="control-label  col-sm-2" style={{ width: '23%' }}>Seperate Answer Sheet</label>
                                    <div className="form-group col-sm-8">
                                        <div className='col-sm-4 padding-remove'>
                                            <input
                                                type='checkbox'
                                                checked={separateAnsSheet}
                                                onChange={e => setSeperateAnsSheet(e.target.checked)}
                                                className='formControl '
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        flgDurationVisible ?
                            <div className='row' style={{ width: '100%' }}>
                                <div className='form-group' style={{ width: '100%' }}>
                                    <label className="control-label  col-sm-2" style={{ width: '23%' }}>Duration</label>
                                    <div className="form-group col-sm-8">
                                        <div className='col-sm-4 padding-remove'>
                                            <input
                                                type="number"
                                                defaultValue={testDuration ? testDuration : ""}
                                                onChange={(e) => setTestDuration(e.target.value)}
                                                className="form-control"
                                                placeholder="in minutes"
                                                disabled={testScheduleId !== 0}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>No. of Batches</label>
                            <div className="form-group col-sm-8">
                                <div className='col-sm-4 padding-remove'>
                                    <input
                                        type="number"
                                        defaultValue={noOfBatches ? noOfBatches : ""}
                                        onChange={(e) => handleNoOfBatches(e.target.value)}
                                        className="form-control"
                                        placeholder="Between 1 and 5"
                                        disabled={testScheduleId !== 0}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>Start Time</label>
                            <div className="form-group col-sm-8 ">
                                <div className="col-sm-8 padding-remove">
                                    <div className='col-sm-4 padding-remove'>
                                        <select
                                            autoComplete='off'
                                            name='Start Hour'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={startHour}
                                            onChange={(e) => setStartHour(e.target.value)}
                                            disabled={!flgTimeEditable || testScheduleId !== 0}
                                        >
                                            <option value=''>Hour</option>
                                            {
                                                lstHour.map((startHour) => (
                                                    <option value={startHour} key={startHour} id={startHour}>
                                                        {startHour}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-sm-5 padding-remove '>
                                        <select
                                            autoComplete='off'
                                            name='Start Minute'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={startMinute}
                                            onChange={(e) => setStartMinute(e.target.value)}
                                            disabled={!flgTimeEditable || testScheduleId !== 0}
                                        >
                                            <option value=''>Minutes</option>
                                            {
                                                lstMinutes.map((startMinute) => (
                                                    <option value={startMinute} key={startMinute} id={startMinute}>
                                                        {startMinute}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-sm-3 padding-remove'>
                                        <select
                                            autoComplete='off'
                                            name='Start AMPM'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={startAMPM}
                                            onChange={(e) => setStartAMPM(e.target.value)}
                                            disabled={!flgTimeEditable || testScheduleId !== 0}
                                        >
                                            {
                                                lstAMPM.map((startAMPM) => (
                                                    <option value={startAMPM} key={startAMPM} id={startAMPM}>
                                                        {startAMPM}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='form-group' style={{ width: '100%' }}>
                            <label className="control-label col-sm-2" style={{ width: '23%' }}>End Time</label>
                            <div className="form-group col-sm-8 ">
                                <div className="col-sm-8 padding-remove">
                                    <div className='col-sm-4 padding-remove'>
                                        <select
                                            autoComplete='off'
                                            name='End Hour'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={endHour}
                                            onChange={(e) => setEndHour(e.target.value)}
                                            disabled={!flgTimeEditable || testScheduleId !== 0}
                                        >
                                            <option value=''>Hour</option>
                                            {
                                                lstHour.map((endHour) => (
                                                    <option value={endHour} key={endHour} id={endHour}>
                                                        {endHour}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-sm-5 padding-remove '>
                                        <select
                                            autoComplete='off'
                                            name='End Minute'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={endMinute}
                                            onChange={(e) => setEndMinute(e.target.value)}
                                            disabled={!flgTimeEditable || testScheduleId !== 0}
                                        >
                                            <option value=''>Minutes</option>
                                            {
                                                lstMinutes.map((endMinute) => (
                                                    <option value={endMinute} key={endMinute} id={endMinute}>
                                                        {endMinute}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-sm-3 padding-remove'>
                                        <select
                                            autoComplete='off'
                                            name='End AMPM'
                                            className='form-control '
                                            style={{ height: 30 }}
                                            value={endAMPM}
                                            onChange={(e) => setEndAMPM(e.target.value)}
                                            disabled={!flgTimeEditable || testScheduleId !== 0}
                                        >
                                            {
                                                lstAMPM.map((endAMPM) => (
                                                    <option value={endAMPM} key={endAMPM} id={endAMPM}>
                                                        {endAMPM}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%', paddingBottom: "10px" }}>
                        <div className="col-md-offset-10 col-md-1 padding-remove">
                            <button type="button" className="btn btn-info pull-right" onClick={(e) => saveTestSchedule()}>Save</button>
                        </div>
                    </div>
                </>
            </form >
        </>
    )
}
export default TestScheduleFormSlider;