import React, { Component } from 'react'
import { toast } from 'react-toastify';

export default class topicForm extends Component {
    state = {
        topicCode: this.props.topicData.topicCode,
        topicName: this.props.topicData.topicName
    }

    handleSubmit = (event) => {
        event.preventDefault();
        var topicCode = this.state.topicCode;
        var topicName = this.state.topicName;
        // var
        if (topicCode === "") {
            toast.warning("Please Enter Valid Code");
            return false;
        }
        if (topicName === "") {
            toast.warning("Please Enter Topic Name");
            return false;
        }
        var objToSave = {
            topicCode,
            topicName
        }
        this.props.addTopics(objToSave);
        // console .log(this.state)
        this.setAllState();

    }

    setAllState = () => {
        if (this.props.topicId === 0) {
            this.setState({
                topicCode: "",
                topicName: ""
            })
        }
    }
    inputOnChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        return (
            <div>
                <form id="topicForm" onSubmit={this.handleSubmit} className="form-horizontal formWrapper">
                    <div className="form-group" >
                        <label className="control-label col-sm-5" >Topic Code<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <input autoComplete="off" id="topicrCode" name="topicCode"
                                type="text" className="form-control " placeholder="Topic Code"
                                value={this.state.topicCode} onChange={this.inputOnChange}
                            />
                        </div>
                    </div>
                    <div className="form-group" >
                        <label className="control-label col-sm-5" >Topic Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <input autoComplete="off" id="topicName" name="topicName"
                                type="text" className="form-control " placeholder="Topic Name"
                                value={this.state.topicName} onChange={this.inputOnChange}
                            />
                        </div>
                    </div>
                    <div className="form-group" >
                        <div className="col-sm-6 pull-right userButtonWrap" >
                            <button type="submit" style={{ marginTop: 20 }} title="Save" className="btn btn-info">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
