import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import '../css/studentTest.css';;



const TestAvatar = (props) => {

    const { selAvatarId, handleChangeAvatar, mapAvatar, avatarPath, setLoader, setOpacity } = props;


    useEffect(() => {
        // console .log("TestAvatar called, selAvatarId", selAvatarId);
    }, [selAvatarId]);


    return (
        <div >
            <div className="avtar-container">
                <div className="avatarGrid">
                    {[...mapAvatar.entries()].map(([key, avatar], index) => (
                        <div className="avatarBox">
                            <div key={avatar.avatarId} className={selAvatarId === avatar.avatarId ? "avatarItemSelected" : "avatarItem"}>
                                <div
                                    className={selAvatarId === avatar.avatarId ? "avatarImage-selected" : "avatarImage"}
                                    // style={{
                                    //     border: selAvatarId === avatar.avatarId ? "1px solid blue" : "1px solid #ccc",
                                    //     boxShadow: selAvatarId === avatar.avatarId ? "0 0 10px rgba(0, 0, 255, 0.5)" : ""
                                    // }}
                                    onClick={() => handleChangeAvatar(avatar)} >
                                    <img src={avatarPath + "/" + avatar.avatarId + ".png"} alt={avatar} />
                                    {/* <img src={"http://192.168.1.25:8280/sms/student/docs/view?imgPath=D://projects//sms//be//sms_files/avatars" + "/" + avatar.avatarId + ".png"} alt={avatar} /> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default TestAvatar;