import axios from "axios";
import lstModules from "common/json/lstModules.json";

var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;
var schoolId = 77; //localStorage.getItem("schoolId");
var tokenid = localStorage.getItem("tokenid");

const getFacilityListSvc = (cb) => {
  var obj = { status: "Success", message: "" };
  obj.lstFacility = [];
  // console .log(httpSchoolURL + "/schools/admin/speciality/dtls");
  axios({
    url: httpSchoolURL + "/schools/admin/speciality/dtls",
    method: "POST",
    params: { schoolId: schoolId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console .log(data);
      obj.status = data.SvcStatus;

      if (data.SvcStatus === "Success") {
        obj.lstFacility = data.lstFacility;
      } else {
        obj.message = data.SvcMsg;
      }

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
};

function saveSpecialtyListSvc(pFac, cb) {
    var obj = { status: "Success", message: "" };
    var formData = new FormData();
    formData.append("facility", JSON.stringify(pFac));
    axios({
      url: httpSchoolURL + "/schools/admin/facility/save",
      method: "POST",
      data: formData,
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        obj.status = data.SvcStatus;
  
        obj.message = data.SvcMsg;
  
        cb(obj);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
export { getFacilityListSvc, saveSpecialtyListSvc };
