import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import { getLstAcdYrSvc, calcDueSvc, createClsSecSvc } from './NewAcademicYrCtrlSvc';

const NewAcademicYrCtrl = () => {
    const [loader, setLoader] = useState('none')
    const [lstAcademicYear, setLstAcademicYear] = useState(0);
    const [fromYrId, setFromYrId] = useState(0)
    const [toYrId, setToYrId] = useState(0)
    const [fromYr, setFromYr] = useState('')
    const [toYr, setToYr] = useState('')


    /* function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    } */
    useEffect(() => {
        getLstAcdYrSvc(cbLstAcdYrResponse)
    }, [])

    function cbLstAcdYrResponse(data) {
        if (data.status === "Success") {
            setLstAcademicYear(data.lstAcademicYear)
        } else {
            if (data.message === "You are not logged in") {
                window.location = "/";
            }

            // setError(data.SvcMsg);
            // hideLoader()
        }

    }
    async function changeFromAcdYr(e) {
        e.persist()
        var idx = e.nativeEvent.target.selectedIndex;
        await setFromYrId(e.target.value)
        await setFromYr(e.nativeEvent.target[idx].text)
    }
    async function changeToAcdYr(e) {
        e.persist()
        await setToYrId(e.target.value)
        var idx = e.nativeEvent.target.selectedIndex;
        await setToYr(e.nativeEvent.target[idx].text)
    }
    function calcDue() {
        if (chkValidations()) {
        }
        calcDueSvc(fromYrId, toYrId, cbResponse)
    }
    function chkValidations() {
        if (fromYrId === 0) {
            toast.error("Please select Previous Academic Year");
            return false;
        }
        if (toYrId === 0) {
            toast.error("Please select New Academic Year");
            return false;
        }
        return true;
    }
    function cbResponse(data) {
        if (data.status === "Success") {
            toast.success(data.message)
        } else {
            toast.error(data.message)
        }
    }
    function createClsSec() {
        if (chkValidations()) {
            createClsSecSvc(fromYrId, toYrId, cbResponse)
        }
    }
    async function promoteStudents() {
        // console .log(fromYrId)
        // console .log(toYrId)
        // console .log(fromYr)
        // console .log(toYr)
        if (chkValidations()) {
            // await localStorage.setItem('fromAcdYrId', fromYrId)
            // await localStorage.setItem('toAcdYrId', toYrId)
            // await localStorage.setItem('fromAcdYr', fromYr)
            // await localStorage.setItem('toAcdYr', toYr)

            let newUrl = "/promote?fromAcdYrId=" + fromYrId + "&toAcdYrId=" + toYrId + "&fromAcdYr=" +fromYr+ "&toAcdYr=" +toYr;
            window.location.href = newUrl;
        }
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>


            <div className="col-sm-6 col-centered" style={{ marginTop: '2%' }}>

                <div className="col-sm-12" style={{
                    border: '1px solid #ccc',
                    borderRadius: 5,
                    padding: 4
                }}>
                    <div className="col-sm-10 col-centered" style={{ height: '5em', width: '70%' }}>
                        <div className="col-sm-6">
                            <label className="col-sm-12 text-left padding-remove" style={{ top: 5 }}>Previous Academic Year</label>
                            <div className="form-group col-sm-8 padding-remove">
                                <select onChange={(e) => changeFromAcdYr(e)} value={fromYrId} className="form-control" style={{ width: '100%' }} id="cmbFromAcdYr">
                                    <option value={0}>Select</option>
                                    {lstAcademicYear && lstAcademicYear.map((obj) => {
                                        return <option key={'f_' + obj.academicYearId} text={obj.academicYear} value={obj.academicYearId}>{obj.academicYear}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <label className="text-left col-sm-12 padding-remove" style={{ top: 5 }}>New Academic Year</label>
                            <div className="form-group col-sm-8 padding-remove">
                                <select onChange={(e) => changeToAcdYr(e)} value={toYrId} className="form-control" style={{ width: '100%' }} id="cmbToAcdYr">
                                    <option value={0}>Select</option>
                                    {lstAcademicYear && lstAcademicYear.map((obj) => {
                                        return <option key={'t_' + obj.academicYearId} text={obj.academicYear} value={obj.academicYearId}>{obj.academicYear}</option>
                                    })}
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-sm-6 col-centered" style={{ marginTop: '7%' }}>
                <div className="tableLayout1" style={{ display: 'table' }}>
                    <div className="col-sm-12"
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <div style={{ padding: 5 }}>
                            <button className="btn btn-info" style={{ width: 150 }} onClick={() => calcDue()}>Calculate Dues</button>
                        </div>
                        <div style={{ fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif', fontSize: '13px', marginLeft: '2%' }}>
                            <span>
                                It will summarize the dues of previous year and update as opening dues for the new academic year
                            </span>
                        </div>
                    </div>

                    <div className="col-sm-12"
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <div style={{ padding: 5 }}>
                            <button className="btn btn-info" style={{ width: 150 }} onClick={() => createClsSec()}>Create Sections</button>
                        </div>
                        <div style={{ fontSize: '13px', marginLeft: '2%', fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif' }}>
                            <span>
                                It will create new sections based on sections from the previous year. Class Teacher, maximum students, etc data will be copied. Students and Timetable will not be copied
                            </span>
                        </div>
                    </div>

                    <div className="col-sm-12"
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <div style={{ padding: 5 }}>
                            <button className="btn btn-info" style={{ width: 150 }} onClick={(e) => promoteStudents()}>Promote Students</button>
                        </div>
                        <div style={{ fontSize: '13px', marginLeft: '2%', fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif' }}>
                            <span>
                                It will open up a new screen where you can promote whole class or individual students from previous sections to new sections
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewAcademicYrCtrl