import React, { useState, useEffect } from 'react';
import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
import $ from "jquery";
import 'common/css/master.css';
import Header from 'common/js/header/header';
import { toast, ToastContainer } from 'react-toastify';

import FeeDueTable from './feeDueTable';
import FeeDueSlider from './feeDueSlider';

var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
let queryString = require('querystring');


var lstMonthlydata = [];
let screenId = 37;
let lstdt = [
    { "id": 1, "date": '1' },
    { "id": 2, "date": '2' },
    { "id": 3, "date": '3' },
    { "id": 4, "date": '4' },
    { "id": 5, "date": '5' },
    { "id": 6, "date": '6' },
    { "id": 7, "date": '7' },
    { "id": 8, "date": '8' },
    { "id": 9, "date": '9' },
    { "id": 10, "date": '10' },
    { "id": 11, "date": '11' },
    { "id": 12, "date": '12' },
    { "id": 13, "date": '13' },
    { "id": 14, "date": '14' },
    { "id": 15, "date": '15' },
    { "id": 16, "date": '16' },
    { "id": 17, "date": '17' },
    { "id": 18, "date": '18' },
    { "id": 19, "date": '19' },
    { "id": 20, "date": '20' },
    { "id": 21, "date": '21' },
    { "id": 22, "date": '22' },
    { "id": 23, "date": '23' },
    { "id": 24, "date": '24' },
    { "id": 25, "date": '25' },
    { "id": 26, "date": '26' },
    { "id": 27, "date": '27' },
    { "id": 28, "date": '28' },
    { "id": 99, "date": 'Last Day of month' },
]
export default function FeeDue() {

    const [state, setState] = useState(
        {
            wingId: '',
            loader: 'none',
            opacity: 1,
            flg: true,
            feeDueId: 0,
            tokenid: localStorage.getItem('tokenid'),
            lstData: [],
            feeDueObj: {}
        });

    useEffect(() => {
        getDueList();
    }, [])

    /* const deleteFeeDues = (wingId) => {
        // console .log(wingId);
        axios
            .post(
                httpURL + "/sms/fee/monthly_dates/delete",
                queryString.stringify({ screenId: screenId, wingId: wingId }),
                {
                    headers: { tokenid: state.tokenid },
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (response.data.SvcStatus === "Success") {
                    toast.success(response.data.SvcMsg);
                    getDueList();
                } else {
                    toast.error(response.data.SvcMsg);
                }
                setState({ loader: "none", opacity: 1 });
            })
            .catch((e) => {
                setState({ loader: "none", opacity: 1 });
                toast.error("Service failed: " + e);
            });
    }; */

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ opacity: state.opacity }}>
                <FeeDueTable
                    showSlider={showSlider}
                    lstMonthlydata={state.lstData}
                    // deleteFeeDues={deleteFeeDues}
                    setForm={setForm}
                />
                <div className="slider halfWidthSlider">
                    <FeeDueSlider
                        key={state.wingId}
                        removeClassFromBody={closeSlider}
                        saveFormData={saveFormData}
                        wingId={state.wingId}
                        wing={state.wing}
                        feeDueObj={state.feeDueObj}
                        lstMonthlydata={state.lstData}
                        lstdt={lstdt}
                    />
                </div>
            </div>
        </div>
    )

    function getDueList() {
        setState({ ...state, loader: 'block', opacity: 0.5 });

        axios.post(httpURL + '/sms/fee/monthly_dates/list',
            queryString.stringify({ 'screenId': screenId }), {
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                // console .log(response.data.lstMonthlydata)
                setState({ ...state, lstData: response.data.lstMonthlydata });
                // console .log(state.lstData)
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            // setState({...state, loader: 'none', opacity: 1 });
        }).catch((error) => {
            // setState({...state, loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    function setForm(feedue) {
        setState({
            ...state,
            feeDueObj: feedue,
            wingId: feedue.wingId,
            wing: feedue.wing

        });
    }

    function saveFormData({ wingId, feeDue, reminder1, reminder2, overdue1, overdue2, wing,flgEmail,flgSms,flgWhatsapp,flgAutoCall }) {
        var monthlyDate = {};
        monthlyDate = {
            'wingId': wingId, 'wing': wing, 'feeDue': feeDue, 'reminder1': reminder1, 'reminder2': reminder2,
            'overDueNotice1': overdue1, 'overDueNotice2': overdue2,'flgEmail':flgEmail,'flgSms':flgSms,'flgWhatsapp':flgWhatsapp,'flgAutoCall':flgAutoCall
        }
        // console .log(monthlyDate);

        setState({ ...state, loader: 'block', opacity: 0.5 });

        axios({
            url: httpURL + '/sms/fee/monthly_dates/save',
            method: "POST",
            params: { 'screenId': screenId, 'monthlyDate': JSON.stringify(monthlyDate) },
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                getDueList();
                closeSlider();

            } else {
                toast.error(response.data.SvcMsg);
            }
            setState({ ...state, loader: 'none', opacity: 1 });
        }).catch((e) => {
            setState({ ...state, loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    function closeSlider() {
        $('body').removeClass('openSlider');
    }
    function showSlider() {
        $('.slider:eq(0)').show();
        $('body').addClass('openSlider');
    }
    function getWingId(wingId) {
        // console .log("wingId" + wingId);
    }




}

