import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import SliderType from "./SliderType";
import {
  getRoleListSvc,
  getSecondaryRoleListSvc,
  savePrimaryRoleSvc,
  saveSecondaryRoleSvc,
} from "./StaffSvc";


const RoleSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [primaryRoleId, setPrimaryRoleId] = useState(props.primaryRoleId);
  const [secondaryRoleId, setSecondaryRoleId] = useState(0);
  const [lstSecondaryRole, setLstSecondaryRole] = useState([]);
  const [lstSecondaryRoleFiltered, setLstSecondaryRoleFiltered] = useState([]);
  const [lstRole, setLstRole] = useState([]);
  const [lstSecondaryRoleId, setLstSecondaryRoleId] = useState([]);
  const [roleFiltered, setRoleFiltered] = useState("");

  useEffect(() => {
    if (props.flgSliderType == SliderType.SLIDER_ROLES) {
      getRoleList();
      getSecondaryRoleList();
    }
  }, [props.flgSliderType]);

  function handlePrimaryRoleChange(param) {
    setPrimaryRoleId(param);
  }
  function getRoleList() {
    setLoader("block");
    getRoleListSvc(cbRoleListResponse);
  }
  function cbRoleListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstRole(data.lstRole);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function savePrimaryRole() {
    setLoader("block");
    savePrimaryRoleSvc(props.userId, primaryRoleId, cbSavePrimaryRoleResponse);
  }
  function cbSavePrimaryRoleResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      //props.generateRandomNo()
      props.closeSlider();
      setLoader("none");
    } else {
      toast.error(data.SvcMsg);
      setLoader("none");
    }
  }

  function handleCBoxChange(rolId) {
    if (lstSecondaryRoleId.includes(rolId)) {
      const updatedLstSecondaryRoleId = lstSecondaryRoleId.filter((id) => id !== rolId);
      setLstSecondaryRoleId(updatedLstSecondaryRoleId);
    } else {
      setLstSecondaryRoleId([...lstSecondaryRoleId, rolId]);
    }
  }

  function getSecondaryRoleList() {
    setLoader("block");
    getSecondaryRoleListSvc(props.userId, cbSecondaryRoleListResponse);
  }

  function cbSecondaryRoleListResponse(data) {
    if (data.SvcStatus === "Success") {
      // console .log("data.lstSecondaryRole", data.lstSecondaryRole);
      const tempSelected = getTempSelectedSecRole(data.lstSecondaryRole);
      // console .log("tempSelected", tempSelected);
      setLstSecondaryRoleId(tempSelected);
      // const tempLstSecondaryRole = sortSelected(data.lstSecondaryRole, tempSelected);
      // console .log("tempLstSecondaryRole", tempLstSecondaryRole);
      setLstSecondaryRole(data.lstSecondaryRole);
      setLstSecondaryRoleFiltered(data.lstSecondaryRole);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }


  function getTempSelectedSecRole(pLstSecondaryRole) {
    return pLstSecondaryRole
      .filter(obj => obj.selected)
      .map(obj => obj.roleId);
  }

  function saveSecondaryRole() {
    setLoader("block");
    // console .log("Sec RoleId" + lstSecondaryRoleId);
    // console .log("typeOfLstSceondaryRoleId ::" + typeof lstSecondaryRoleId);
    saveSecondaryRoleSvc(
      props.userId,
      lstSecondaryRoleId,
      cbSaveSecondaryRoleResponse
    );
  }
  function cbSaveSecondaryRoleResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      //props.generateRandomNo()
      props.closeSlider();
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  function sortSelected(pLstSecondaryRole, pLstSelected) {
    let tempLstSecondaryRole = [...pLstSecondaryRole]; // Create a copy of the original array
    tempLstSecondaryRole.sort((a, b) => {
      const aSelected = pLstSelected.includes(a.roleId);
      const bSelected = pLstSelected.includes(b.roleId);

      if (aSelected && bSelected) {
        return 0;
      } else if (aSelected) {
        return -1;
      } else {
        return 1;
      }
    });
    return tempLstSecondaryRole;
  }

  const filterSecondaryRole = (fRole) => {
    // console .log("filterSecondaryRole called, fRole", fRole);
    // console .log("lstSecondaryRole", lstSecondaryRole);
    // console .log("lstSecondaryRoleId", lstSecondaryRoleId);
    setRoleFiltered(fRole);
    let tempFiltered = [];
    tempFiltered = lstSecondaryRole.filter((role) =>
      role.role.toLowerCase().includes(fRole.toLowerCase())
      || lstSecondaryRoleId.includes(role.roleId)
    );

    // console .log("tempFiltered in filterSecondaryRole", tempFiltered);
    tempFiltered = sortSelected(tempFiltered, lstSecondaryRoleId);
    // console .log("tempFiltered in filterSecondaryRole", tempFiltered);
    setLstSecondaryRoleFiltered(tempFiltered);
  }


  const getCheckboxValue = (rolId) => {
    // console .log("getCheckboxValue called for rolId", rolId, ", returned", lstSecondaryRoleId.includes(rolId));
    return lstSecondaryRoleId.includes(rolId);
  }


  const colgrp = <colgroup>
    <col width="75%"></col>
    <col width="25%"></col>
  </colgroup>


  return (
    <form
      className="form form-horizontal"
      style={{
        width: "100%",
        margin: "0 auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
        pointerEvents: loader === "none" ? "auto" : "none",
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>

      <div
        className="EnquiryHead col-sm-12 col-centered"
        style={{
          border: "1px solid #aaa",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          fontWeight: "bold",
        }}
      >
        <h4>Roles</h4>
      </div>
      <div className="row" style={{ width: "100%", marginTop: "15px" }}>
        <div className="form-group col-md-7" style={{ marginRight: "4px" }}>
          <div className="col-sm-4 padding-remove">
            <label className="control-label col-sm-10 pull-right text-left">
              Primary Role<span className="red-color">*</span>
            </label>
          </div>
          <div className="col-sm-5 padding-remove">
            <select
              className="form-control"
              onChange={(e) => handlePrimaryRoleChange(e.target.value)}
              value={primaryRoleId}
            >
              <option value={0}>Primary Role</option>
              {lstRole.map((role, idx) => {
                return (
                  <option key={role.roleId} value={role.roleId}>
                    {role.role}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group col-md-3" style={{ textAlign: "initial" }}>
          <button
            type="button"
            className="btn btn-info "
            onClick={(e) => savePrimaryRole()}
          >
            Save
          </button>
        </div>
      </div>
      <div className="row" style={{ width: "100%", marginTop: "30px" }}>
        <div className="form-group col-md-7" style={{ marginRight: "4px" }}>
          <div className="col-sm-4 padding-remove">
            <label className="control-label col-sm-10 pull-right text-left">Secondary Role</label>
          </div>
          <div className="col-sm-8 padding-remove">
            <table className="tableLayout1">
              {colgrp}
              <thead>
                <tr>
                  <th>
                    <input
                      type="text"
                      className="form-control inp-search text-left"
                      value={roleFiltered}
                      onChange={(e) => filterSecondaryRole(e.target.value)}
                      name="Role"
                      placeholder="Role"
                    />
                  </th>
                  <th>Select</th>
                </tr>
              </thead>
            </table>
            <div className=" tablebodyWrapper" style={{ height: "55vh" }}>
              <table className="tableLayout">
                {colgrp}
                <tbody>
                  {lstSecondaryRoleFiltered &&
                    lstSecondaryRoleFiltered.length !== 0 &&
                    lstSecondaryRoleFiltered.map((role, idx) => {
                      return (
                        <tr key={idx} >
                          <td style={{ textAlign: "left" }}>{role.role}</td>
                          <td >
                            {
                              primaryRoleId !== role.roleId ?
                                <input type="checkbox"
                                  className="form-check-input"
                                  checked={getCheckboxValue(role.roleId)}
                                  onChange={() => handleCBoxChange(role.roleId)}
                                  onClick={(e) => { e.stopPropagation(); }}
                                  disabled={false}
                                />
                                :
                                <input type="checkbox"
                                  className="form-check-input"
                                  checked={primaryRoleId === role.roleId}
                                  onChange={() => handleCBoxChange(role.roleId)}
                                  onClick={(e) => { e.stopPropagation(); }}
                                  disabled={true}
                                />
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="form-group col-md-3" style={{ textAlign: "initial" }}>
          <button
            type="button"
            className="btn btn-info "
            onClick={(e) => saveSecondaryRole()}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
export default RoleSlider;
