import React, { useEffect } from 'react'
import { getLstStudentSvc, savePromotionSvc } from './PromoteSliderSvc.js'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

var lstTmpStudent = []
const ManualPromotionSlider = (props) => {
    const [lstToSection, setLstToSection] = useState([])
    const [lstStudent, setLstStudent] = useState([])
    const [fromClsId, setFromClsId] = useState(0)
    const [toClsId, setToClsId] = useState(0)
    const [toSectionId, setToSectionId] = useState(0)
    const [toCls, setToCls] = useState('')
    const [selectedIdx, setSelectedIdx] = useState('')
    const [fromCls, setFromCls] = useState([])
    const [tchKeyword, setTchKeyword] = useState('')
    const [dynamicWidth, setDynamicWidth] = useState('calc(100% / ' + (props.lstToSection.length + 1) + ')');

    useEffect(() => {
        // console .log(props)
        setLstToSection(props.lstToSection)
        setDynamicWidth('calc(100% / ' + (props.lstToSection.length + 1) + ')');

        setFromClsId(props.fromClsId)
        setFromCls(props.fromCls)
        setToClsId(props.toClsId)
        setToCls(props.toCls)
        if (props.fromClsId !== 0)
            getLstStudentSvc(props.fromAcdYrId, props.fromClsId, cbResponse)
    }, [props])

    function cbResponse(data) {
        if (data.status === "Success") {
            setLstStudent(data.lstStudent);
            toast.success(data.message)
        } else {
            toast.error(data.message)
        }
    }

    /* function saveAllocation() {
        var state = {};
        state.clsId = props.clsId;
        state.sectionId = props.sectionId
        state.subId = props.subId;
        state.allocationId = props.allocationId;

        saveAllocationSvc(props.examId, expHrs, allocatedToId, [state], cbsaveAllocationResponse)
    }

    const filterName = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterClass(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterSec = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterSection(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterSub = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterSubject(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterSubType = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterSubjectType(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

    function cbsaveAllocationResponse(data) {
        // console .log(data)
        if (data.status === "Success") {
            toast.success(data.message)
        }
        else {
            toast.error(data.message)
        }
        props.closeSlider()
    } */

    function rowClick(staffId) {
        setSelectedIdx(staffId)
    }

    const selectAll = (e) => {
        var list_cbox = document.querySelectorAll('#studentTableBody td input[type=checkbox]');
        list_cbox.forEach((cbox) => { if (cbox.checked !== e.target.checked) cbox.click(); })
    }

    const colgrp = <colgroup>
        <col width="20%" />
        <col width="20%" />
        <col width="20%" />
        <col width="20%" />
        <col width="20%" />
    </colgroup>

    function handleCboxChange(e, obj, status) {
        e.stopPropagation()
        if (status) {
            lstTmpStudent.push(obj.studentId)
        }
        else {
            var idx = existsIn(obj.studentId, lstTmpStudent);
            lstTmpStudent.splice(idx, 1)
        }
    }

    function existsIn(studentId, arr) {
        var idx = -1;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].studentId === studentId) {
                idx = i;
                break;
            }
        }
        return idx;
    }

    function savePromotion() {
        if (lstTmpStudent.length === 0) {
            toast.warn("Please select atleast one row");
            return;
        }
        if (!toSectionId || toSectionId == 0) {
            toast.warn("Please select the section");
            return;
        }
        // console .log(lstTmpStudent)
        var promoteObj = {};
        promoteObj.fromAcdYrId = props.fromAcdYrId;
        promoteObj.fromClsId = fromClsId;
        promoteObj.toAcdYrId = props.toAcdYrId;
        promoteObj.toSectionId = toSectionId;
        // console .log(promoteObj)
        savePromotionSvc(promoteObj, lstTmpStudent, cbSavePromotionResponse)
    }

    function cbSavePromotionResponse(obj) {
        if (obj.status === "Success") {
            toast.success(obj.message)
            props.closeSlider();
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    async function setSecId(secId) {
        // console .log('parent clicked::' + typeof secId + "::" + typeof toSectionId)
        await setToSectionId(secId)
    }
    return (
        <div className='border border-1  border m-1 mb-0 b '>
            <form className="col-sm-12 padding-remove">
                <div className='col-sm-2 padding-remove' style={{ marginTop: '2%' }}>
                    <label className="control-label col-sm-9 text-left">From Class</label>
                    <div className="form-group col-md-3">
                        <input readOnly className="form-control-plain" style={{ border: 'none', background: '#ffffff', fontWeight: 'bold' }} value={props.fromCls} disabled></input>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <label className="control-label col-sm-12">Select section from here where you want to promote students:</label>
                    <div className='col-sm-12' >

                        <div className="wrapper-box" style={{ height: '10vh', boxShadow: 'rgba(64, 60, 67, 0.16) 0px 2px 5px 1px', width: 'auto', marginBottom: 10 }}>
                            <div
                                className="box-block" style={{ width: dynamicWidth, cursor: 'auto' }} >
                                <div className="div-block">
                                    <span>{toCls}</span>
                                    <span className="">{props.available + "/" + props.total}</span></div>
                            </div>

                            {lstToSection.map((obj) => (<div key={"s_" + obj.sectionId}
                                className={toSectionId === obj.sectionId ? "box-block selected" : "box-block"}
                                style={{ width: dynamicWidth, border: 'none', cursor: 'pointer' }}
                                onClick={() => setSecId(obj.sectionId)}>
                                <div className="div-block" onClick={(e) => { e.stopPropagation(); setSecId(obj.sectionId) }}>
                                    <span>{obj.section}</span>
                                    <span className="">{obj.available + "/" + obj.maxAllowed}</span></div>
                            </div>))}
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <button type="button" className="btn btn-info pull-right" onClick={() => savePromotion()} >Promote</button>
                    </div>
                </div>
            </form >

            <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12" >
                <table className="tableLayout1">
                    {colgrp}
                    <thead>
                        <tr>
                            {/* <th><input className="form-control inp-search" placeholder="Teacher" onChange={(e) => filterTch(e)} onKeyUp={(e) => filterTch(e)} /></th> */}
                            <th >Enrollment No</th>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Gender</th>
                            <th>Select<br />
                                <div>
                                    <input onChange={(e) => selectAll(e)} type="checkbox" id="cbxSelectAll" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className='tablebodyWrapper'>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="studentTableBody">
                            {lstStudent.map((obj, idx) => (
                                <tr className={obj.studentId === selectedIdx ? 'selected' : ''} key={idx} onClick={(event) => rowClick(obj.studentId)} >
                                    <td className='text-left'>{obj.enrollmentNo}</td>
                                    <td>{obj.name}</td>
                                    <td >{obj.gender}</td>
                                    <td >{obj.section}</td>
                                    <td onClick={(e) => { e.stopPropagation(); e.target.firstChild.click() }}>
                                        <input key={obj.enrollmentNo} id={obj.enrollmentNo} onClick={(e) => handleCboxChange(e, obj, e.target.checked)}
                                            type="checkbox" defaultChecked={false}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="form form-horizontal" >
                <div className="col-md-6 col-md-offset-1" style={{ border: '1px solid #ccc', paddingTop: 10 }}>
                    <div className="form-group">
                        <div className='col-sm-8 padding-remove'>
                            <label className="control-label col-sm-4">Expected Time<span className="red-color">*</span></label>
                            <div className='col-sm-4 padding-remove'>
                                <input type='text' className="form-control" onChange={(event) => setExpHrs(event.target.value)} ></input>
                            </div>

                            <div className="col-sm-3">
                                <button type="button" className="btn btn-info " id="assignCounsellor" onClick={saveAllocation} >Assign</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    )
}

export default ManualPromotionSlider