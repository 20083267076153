
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header.js';
import 'common/css/master.css';

import { getClassList, getSubList, getSectionData, getSessionData, getCellDtls, getPeriodDtls, getDaysDtls, deleteCellDtls, saveCellDtls } from './ttSubServices.js';
// import TimeTableHead from 'sms/tt/header/js/ttHead.js';
// import SubjectHead from 'sms/tt/subject/js/ttSubjectTbl';
import TTSubHead from './ttSubHead.js';
import TTSubBody from './ttSubBody-wip.js';
import TTSubjectTbl from 'sms/tt/subject/js/ttSubjectTbl.js';
var MAX_SUB = 4;
const SubjectCtrl = () => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [subTBodyKey, setSubTBodyKey] = useState(0);
    const [ttBodyKey, setTTBodyKey] = useState(0);
    const [cellDtls, setCellDtls] = useState({});
    const [state, setState] = useState(initialState);
    const [classId, setClassId] = useState(0);
    const [lstSub, setLstSub] = useState([])
    const [lstPeriod, setLstPeriod] = useState([])
    const [lstDay, setLstDay] = useState({})
    const [periodId, setPeriodId] = useState(cellDtls.periodId);
    const [dayId, setDayId] = useState(0);
    const [subId, setSubId] = useState(0);
    const [clsId, setClsId] = useState(0);
    const [lstCls, setLstCls] = useState([]);
    const [lstSection, setLstSection] = useState([]);
    const [lstSession, setLstSession] = useState([]);
    const [selSession, setSelSession] = useState('');
    const [cell, setCell] = useState({});
    const [selCellId, setSelCellId] = useState(0)
    const [sectionId, setSectionId] = useState(0);
    const [sessionId, setSessionId] = useState(0);
    const [headKey, setHeadKey]=useState(0);
    //const [session, setSession] = useState('');
    const [selSubId, setSelSubId] = useState(0);
    const [selCellDtls, setSelCellDtls] = useState({});
    const [selDayId, setSelDayId] = useState(0);
    const [selPeriodId, setSelPeriodId] = useState(0);
    const [subPositionId, setSubPositionId] = useState(0);
    const [randNo, setRandNo] = useState(0);
    const [flgSuccess, setFlgSuccess] = useState(true);
    const params = new URLSearchParams(window.location.search);
    function generateRandNo() {
        return Math.floor(Math.random() * 999999);

    }
    useEffect(() => {
        // setPeriod(sessionId);
        setDay()
        //setCellDtlsData();
        setClass(handleClassResponse);

        //setDay(handleDaysResponse);
        // setClsId(clsId);
        // setSectionId(sectionId);
        // setSessionId(sessionId);
        // console .log(clsId + sectionId + sessionId)
        if (params.get("clsId")) {
            setClassId(params.get("clsId"));
            if (params.get("sectionId")) {
            // console .log("sectionId get using params",params.get("sectionId"));
              setSectionId(params.get("sectionId"));
              setSection(params.get("clsId"));
              if (params.get("sessionId")) {
                setSessionId(params.get("sessionId"));
                setSession(params.get("clsId"));

                setPeriod(params.get("sessionId"), params.get("pClassId"), params.get("pSectionId"));
              }
            }
          }
    }, []);

    // var rqCell = {
    //     schedulePeriodId: 1,
    //     classSectionId: 1,
    //     dayId: 1,
    //     cellId: 0,
    // };

    function setClass() {
        getClassList(handleClassResponse);
    }

    async function handleClassResponse(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstCls(obj.lstCls);
            if(obj.lstCls.length!=0){
                var vClsId = obj.lstCls[0].classId;
                await setState({ classId: vClsId });
                await setClassId(vClsId);
                setSession(vClsId);
                setSection(vClsId);
                /* const allResults = Promise.all([setSession(vClsId),setSection(vClsId)])
                    .then(([result1, result4]) => {
                        return [result1, result4];
                    }) */
                
            }
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function setPeriod(sessionId, pClassId, pSectionId) {
        getPeriodDtls(sessionId, pClassId, pSectionId, handlePeriodResponse);

    }
    function setDay() {
        getDaysDtls(handleDayResponse);
    }
    function handleDayResponse(data) {
        var obj = data;
        // console .log("data" + JSON.stringify(obj.lstDay))
        if (obj.SvcStatus === 'Success') {
            setLstDay(obj.lstDay);
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function handlePeriodResponse(data) {
        var obj = data;
        // console .log("data" + JSON.stringify(obj.lstPeriod))
        if (obj.status === 'Success') {
            setLstPeriod(obj.lstPeriod);
            setTTBodyKey(generateRandNo)
        } else if (obj.status === 'Failure') {
            setLstPeriod([])
            setTTBodyKey(generateRandNo)
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }

    function setSection(clasId) {
        getSectionData(handleSectionResponse, clasId);
    }

    async function handleSectionResponse(data, vClassId) {
        // return new Promise((resolve) => {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstSection(obj.lstSection);
            if(obj.lstSection.length!=0){
                var vSectionId = obj.lstSection[0].sectionId;
                await setState({ sectionId: vSectionId });
                await setSectionId(vSectionId);
                // console .log("setPeriod() from handleSectionResponse()"+sessionId+"-"+vClassId+"-"+vSectionId);
                vClassId !== 0 && vSectionId !== 0  && sessionId != 0 && setPeriod(sessionId, vClassId, vSectionId);
                // console .log("setSubjectTable() from handleSectionResponse()"+sessionId+"-"+vClassId+"-"+vSectionId);
                sessionId !== 0 && vClassId!=0 && vSectionId !== 0 && setSubjectTable(sessionId, vClassId, vSectionId);
            }
           
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
        // resolve();
    // });
    }

    function setSession(clasId) {
        getSessionData(handleSessionResponse, clasId);
    }

    async function handleSessionResponse(data, vClsId) {
        var obj = data;
        if (obj.SvcStatus === 'Success') {
            await setLstSession(obj.lstSession);
            if(obj.lstSession.length!=0){
                var vSessionId=obj.lstSession[0].sessionId;
                await setSessionId(vSessionId);
                // console .log("setSubjectTable() from handleSessionResponse()"+vSessionId+"-"+vClsId+"-"+sectionId);
                // vSessionId !== 0 && vClsId!=0 && sectionId !== 0 && setSubjectTable(vSessionId, vClsId, sectionId);
                // console .log("setPeriod() from handleSessionResponse()"+vSessionId+"-"+vClsId+"-"+sectionId);
                // vSessionId !== 0 && sectionId !== 0 && vClsId!=0 && setPeriod(vSessionId, vClsId, sectionId);
            }
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    // function setDay(handleDaysResponse) {
    //     getLstDay(handleDaysResponse);
    // }

    // function handleDaysResponse(data) {
    //     var obj = data;
    //     if (obj.SvcStatus == 'Success') {
    //         setLstDay(obj.lstDay);
    //     } else if (obj.SvcStatus == 'Failure') {
    //         if (obj.SvcMsg === 'You are not logged in') {
    //             toast.error(obj.SvcMsg);
    //             setTimeout(function () { window.location.href = ('/') }, 2000);
    //             return false;
    //         }
    //         else if (obj.SvcMsg === 'Your Session is expired, login again') {
    //             toast.error(obj.SvcMsg);
    //             setTimeout(function () { window.location.href = ('/') }, 2000);
    //             return false;
    //         }
    //     } else {
    //         toast.error("Service failed " + obj.SvcMsg);
    //     }
    // }

    // function setCellDtlsData() {
    // console .log(rqCell);
    //     getCellDtls(handleCellResponse, rqCell);
    // }

    function setSubjectTable(sessionId, clsId, sectionId) {
        getSubList(sessionId, clsId, sectionId, handleToFunction);
        // setTTBodyKey(generateRandNo)
        // setSubTBodyKey(generateRandNo)
    }

    async function handleToFunction(data) {
        var obj = data;
        if (obj.SvcStatus === 'Success') {
            // console .log(obj.lstSub);
            await setLstSub(obj.lstSub);
            await setTTBodyKey(generateRandNo);
            await setSubTBodyKey(generateRandNo);
        } else if (obj.status === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    async function changeClass(pClsId) {
        let wingId = 1;
        let screenId = 33;
        await setState({ classId: pClsId,sectionId: 0  });
        await setClassId(pClsId);
        await setLstSection([]);
       
        await setSectionId(0);
        
        // console .log("changeClass: " + pClsId + ", sectionId::" + sectionId + ", sessionId::" + sessionId);
        setSection(pClsId);
        setSession(pClsId);
        
        // sessionId !== 0 && sectionId !== 0  && sessionId !== 0 && setPeriod(sessionId, pClsId, sectionId);
    }

    async function changeSection(pSectionId) {
        let wingId = 1;
        let screenId = 33;
        await setState({ sectionId: pSectionId });
        setSectionId(pSectionId);
        // console .log("changeSection: " + pSectionId + ", clsId::" + classId + ", sessId::" + sessionId);
        // sessionId !== 0 && classId !== 0 && setSubjectTable(sessionId, classId, pSectionId);
        // sessionId !== 0 && sectionId !== 0  && sessionId !== 0 && setPeriod(sessionId, classId, pSectionId);
    }

    async function changeSession(pSessionId, session) {
        let wingId = 1;
        let screenId = 33;

        setDay();
        classId !== 0 && sectionId !== 0 && setSubjectTable(pSessionId, classId, sectionId);
        if (classId && sectionId) {
            setPeriod(pSessionId, classId, sectionId);
        }
        // console .log('session = ' + session);
        // console .log("changeSession::" + pSessionId + "sectionId::" + sectionId + ", clsId::" + classId)
        setSessionId(pSessionId);
        await setState({ sessionId: pSessionId });
        setSelSession(session);


    }
    function handleCellResponse(data) {
        var obj = data;
        // console .log("data" + JSON.stringify(data))
        if (obj.SvcStatus === 'Success') {
            setCellDtls(obj.cellDtls);
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                toast.error(obj.SvcMsg);
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                toast.error(obj.SvcMsg);
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }
    function handleSaveResponse(data, cell) {
        var obj = data;
        // console .log("data" + JSON.stringify(data))
        if (obj.status === 'Success') {
            setFlgSuccess(true)
            toast.success(obj.SvcMsg);
            // console .log("319::" + cell.cellId)
            setSelCellId(obj.cellId)
            setState({ ...state, cellDtls: obj.cellDtls });
            if (cellDtls.cellId === 0) {
                cellDtls.cellId = data.cellId;
            }
            setState({ ...state, selCellDtls: obj.cellDtls })
            //setSelCellDtls(obj.cellDtls)
            //setState({ ...state, cellDtls: obj.cellDtls });
        }
        else if (obj.status === 'Failure') {

            setFlgSuccess(false);
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(data.SvcMsg);
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    /* function saveSelCellDtls(selSubId, selPeriodId, selDayId) {
        // console .log(selSubId);
        // console .log('save service is working!');
        // console .log(state.cellObj);
        saveCellDtls(handleSaveResponse, state.cellObj);
    } */

    function getWingId(wingId) {
        // console .log("wingId" + wingId);
    }
    function setDayPeriodId(pCellId, pDayId, pPeriodId, pSubPos) {
        setSelCellId(pCellId)
        setSelDayId(pDayId)
        setSelPeriodId(pPeriodId)
        setSubPositionId(pSubPos)
    }
    function setCellSubId(pSubId) {
        // console .log("subPosId=" + subPositionId)
        var subPos;
        if (flgSuccess) {
            subPos = (subPositionId + 1) > MAX_SUB ? MAX_SUB : (subPositionId + 1)
        } else {
            subPos = (subPositionId) > MAX_SUB ? MAX_SUB : (subPositionId)
        }
        // console .log("subPos=" + subPos)
        setSelSubId(pSubId)
        var lCellId = (document.querySelector('.selected-cell').closest("td").firstChild.getAttribute('cell-id'))
        // console .log("selCellId = " + selCellId)
        var cellObj = {
            dayId: selDayId
            , schedulePeriodId: selPeriodId
            , cellId: lCellId
            , subPosition: subPos
            , subId: pSubId
            , classSectionId: sectionId
        };
        // setState({
        //     ...state, cellObj: cellObj
        // })
        // console .log("cellobj" + JSON.stringify(cellObj))
        saveCellDtls(cellObj, handleSaveResponse);
    }
    function setPosDayPeriod() {
        var pos = parseInt(document.querySelector('.selected-cell').closest("td").firstChild.getAttribute('pos'))
        // console .log("pos=" + pos)
        setState({ ...state, subPosition: pos + 1 });
        setSubPositionId(pos + 1)
        // console .log(document.querySelector('.selected-cell').closest("td").firstChild.getAttribute('cell-id'))

    }
    return (
        <div className="MainContainer">
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-md-12 col-centered wingsWrap clearfix">
                {/* <TimeTableHead
                    setClassId={setClassId}
                    setSectionId={setSectionId}
                    setSessionId={setSessionId}
                    setSession={setSession}

                /> */}
                <TTSubHead
                    key={headKey}
                    changeClass={changeClass}
                    changeSection={changeSection}
                    changeSession={changeSession}
                    lstSession={lstSession}
                    lstSection={lstSection}
                    lstCls={lstCls}
                    selSessionId={sessionId}
                    classId={classId}
                    selSectionId={sectionId}
                    setSession={setSession}
                />
            </div>
            <div className='col-sm-12 padding-remove' style={{ marginTop: '1%', height: '38em' }}>
                <div className="col-sm-12 padding-remove" >
                    {
                    lstPeriod &&
                     lstPeriod.length !== 0 &&
                     lstDay && Object.keys(lstDay).length !== 0 && ttBodyKey && subTBodyKey
                        && 
                        <TTSubBody
                            key={ttBodyKey}
                            lstPeriod={lstPeriod}
                            classId={classId}
                            classSectionId={sectionId}
                            sessionId={sessionId} 
                            selSubId={selSubId}
                            setSelCellDtls={setSelCellDtls}
                            cellDtls={state.cellDtls}
                            selCellId={selCellId}
                            //removeSub={removeSub}
                            setDayPeriodId={setDayPeriodId}
                            selDayId={selDayId}
                            setSubPositionId={setSubPositionId}
                            selPeriodId={selPeriodId}
                            lstDay={lstDay}
                            sectionId={sectionId}
                            setCellSubId={setCellSubId}
                            selSession={selSession}
                            setPeriod={setPeriod}
                            selCellDtls={selCellDtls}
                            setPosDayPeriod={setPosDayPeriod}
                            lstSub={lstSub}
                            subTBodyKey={subTBodyKey}
                        />}
                </div>
            </div>
        </div >

    )
}
export default SubjectCtrl;