import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import Header from 'common/js/header/header'
import { toast, ToastContainer } from 'react-toastify'
import { getEventListSvc, saveEventSvc } from "./eventSvc";
import EventTable from './eventTable.js';
import EventSlider from './eventSlider.js';
import ImageSlider from './imageSlider.js';
// import { getClassListSvc, saveLstClsLabelSvc } from './ClassSetupCtrlSvc'

var httpURL = lstModules['main'].url + ':' + lstModules['main'].port

let queryString = require('querystring')

const EventCtrl = () => {
  const [loader, setLoader] = useState("none")
  const [eventObj, setEventObj] = useState({})
  const [eventId, setEventId] = useState(0)
  const [lstEvent, setLstEvent] = useState([])
  const [randNo, setRandNo] = useState(0)
  const [flgSlider, setFlgSlider] = useState(0);
  const [galleryPath, setGalleryPath]=useState(undefined)
  var lstTmpImg = []
    const [newLstImg,setNewLstImg] = useState(null)
  useEffect(() => {
    setLoader("block");
    getEventList()
  }, [])

  function getEventList() {
    getEventListSvc(cbEventListResponse)
  }
  async function cbEventListResponse(data) {
    // console .log("cbEventListResponse called");
    if (data.SvcStatus === "Success") {
      // console .log(data.galleryPath)
      setLstEvent(data.lstEvent)
      await setGalleryPath(data.galleryPath);
      toast.success(data.SvcMsg)
    } else {
      setLstEvent([])
      toast.error(data.SvcMsg)
    }
    setLoader("none");
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console .log(lRandNo)
    setRandNo(lRandNo)
  }

  async function openEventSlider(pEventId, pEventObj) {
    // console .log("pEventId=" + pEventId + ", pEventObj=" + JSON.stringify(pEventObj))
    await setEventId(pEventId)
    setEventObj(pEventObj)
    setFlgSlider(10)
  }
  function closeSlider() {
    setFlgSlider(0)
    setEventObj({})
    setEventId(0)
    getEventListSvc(cbEventListResponse)
  }
  async function openImageSlider(pEventId, pEventObj) {
    // console .log("pEventId=" + pEventId + ", pEventObj=" + JSON.stringify(pEventObj))
    await setEventId(pEventId)
    setEventObj(pEventObj);
    setLstTmpImg(pEventObj.lstImg);
    setFlgSlider(20)
  }
  async function setLstTmpImg(lstImg) {
    for (var i = 0; i < lstImg.length; i++) {
        var found = false;
        var objImg = lstImg[i];
        if (objImg.portrait) {
            objImg.ctr = 1;
        } else {
            objImg.ctr = 2;
        }
        lstImg[i] = objImg;
        // console .log(objImg)
        // console .log("newlist len = " + lstTmpImg.length)

        var j = 0;
        for (j = 0; j < lstTmpImg.length; j++) {

            var oCtr = parseInt(objImg.ctr + lstTmpImg[j].ctr);
            if (oCtr < 4) {
                found = true;
                lstTmpImg[j].ctr = oCtr; 
                lstTmpImg[j].lstImg.push(objImg);
                // console .log("j=" + j + " - " + JSON.stringify(lstTmpImg[j]) + " - " + parseInt(oCtr))
                break;
            }
        }
        if (!found) {
            // console .log("!found for: " + JSON.stringify(objImg))
            var objTmpImg = {};
            if (objImg.portrait) {
                objImg.ctr = 1;
            } else {
                objImg.ctr = 2;
            }
            objTmpImg = { ctr: objImg.ctr, lstImg: [objImg] }
            lstTmpImg.push(objTmpImg)
        }
    }
    // console .log(lstTmpImg)
    await setNewLstImg(lstTmpImg)
}

  return (
    <div style={{ height: '100%' }}>
      <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div >
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='MainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none", marginTop: 30 }}>
        <div className="col-sm-5 col-centered" style={{ height: '100%' }}>
          <div className="col-sm-11">
            <EventTable
              setEventId={setEventId}
              eventId={eventId}
              openEventSlider={openEventSlider}
              openImageSlider={openImageSlider}
              // deleteTask={deleteTask}
              // completeTask={completeTask}
              lstEvent={lstEvent} />
          </div>
          <div className="col-xs-1" onClick={(e) => openEventSlider(0, {})}>
            <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
          </div>
        </div>
        {flgSlider == 10 ?
          <div className="TestSlider"
            style={{ display: flgSlider == 10 ? 'block' : 'none', width: "50%" }}>
            <EventSlider
              key={eventId}
              eventId={eventId}
              closeSlider={closeSlider}
              eventObj={eventObj}
              getEventList={getEventList}
            />
          </div>
          : (flgSlider == 20 && galleryPath!==undefined ?
            <div className="TestSlider"
              style={{ display: flgSlider == 20 ? 'block' : 'none', width: "50%" }}><ImageSlider
                key={'img_' + eventId}
                eventId={eventId}
                closeSlider={closeSlider}
                eventObj={eventObj}
                lstImg={newLstImg}
                getEventList={getEventList} 
                galleryPath={galleryPath}/></div>
            : null)
        }
      </div>
    </div>
  )
}

export default EventCtrl