import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import '../css/payrollSetupCtrl.css'
import Header from 'common/js/header/header.js';
import EmployeeTable from "./employeeTable";
import EmployeeSlider from "./employeeSlider";
function EmployeeCtrl() {
    const [loader, setLoader] = useState("none");
    const [flgSlider, setFlgSlider] = useState(false)
    const [randNo, setRandNo] = useState(0);
    const [staffId, setStaffId] = useState(0)
    const [staffObj, setStaffObj] = useState({})
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    }
    function openSlider() {
        setFlgSlider(true)
    }
    function closeSlider() {
        setFlgSlider(false);
        setStaffObj({})
        setStaffId(0)
        generateRandomNo()
    }
    const getWingId = (wingId) => {
        // console .log('wingId = ' + wingId)
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="TestTblWrapper">
                <div className="TestTbl">
                    <EmployeeTable
                        key={randNo}
                        openSlider={openSlider}
                        closeSlider={closeSlider}
                        setStaffId={setStaffId}
                        setStaffObj={setStaffObj}
                    />
                </div>
                <div className="TestSlider" style={{ display: flgSlider ? 'block' : 'none' }}>
                    <EmployeeSlider
                        key={staffId}
                        closeSlider={closeSlider}
                        openSlider={openSlider}
                        staffObj={staffObj}
                        staffId={staffId}
                        setRandNo={setRandNo}
                    />
                </div>
            </div>
        </div>
    )
};
export default EmployeeCtrl;