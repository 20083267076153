import React, { useState, useEffect, useRef } from 'react'
import PrintPage from './printPage.js';
import { getICardSvc, getLogoSvc, getPrinciSignSvc, getDocDtlsSvc } from './IdCardSliderSvc.js';
import { toInteger } from 'lodash';
import lstModules from 'common/json/lstModules.json';
import NoImage from "images/no-image.png";
// import LoaderImage from "common/js/LoaderImage.jsx";

const httpURLStudent = lstModules['students'].url + ':' + lstModules['students'].port
const IdCardSlider = (props) => {

  const { lstIdCard, heading, lstStudentId, removeClassFromBody } = props;

  // const [loader, setLoader] = useState("none");
  const [baseColor, setBaseColor] = useState("");
  const [selTpt, setSelTpt] = useState(false);
  const [selGroup, setSelGroup] = useState(false);
  const [reissueFee, setReissueFee] = useState('300');
  const [groupIDCardBy, setGroupIdCardBy] = useState("");
  const [selPageType, setSelPageType] = useState("");
  const [groupPrintId, setGroupPrintId] = useState(30);
  const [isSelectGroupPrintOpen, setisSelectGroupPrintOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [totalCols, setTotalCols] = useState(0);
  const [totalCopies, setTotalCopies] = useState(0);
  const [lstIdCardDtls, setLstIdCardDtls] = useState(lstIdCard);
  const [pageOrientaion, setPageOrientation] = useState("");
  const [pdfKey, setPdfKey] = useState(0);
  const [isPDFVisible, setIsPDFVisible] = useState(false);
  const [isViewPDFButtonVisible, setIsViewPDFButtonVisible] = useState(false);

  const lstGroupBy = [
    "Id Card together",
    "Front/Back together",
  ];


  const lstPageSizesInches = [
    { pageType: "Letter", width: 8.50, height: 11.00, cols: 2, rows: 2, copies: 4, landscapeRows: 1, landScapeCols: 3, totalLandscapeCopies: 3 },
    { pageType: "Legal", width: 8.50, height: 14.00, cols: 2, rows: 2, copies: 4, landscapeRows: 1, landScapeCols: 4, totalLandscapeCopies: 4 },
    { pageType: "A0", width: 33.11, height: 46.81, cols: 10, rows: 9, copies: 90, landscapeRows: 6, landScapeCols: 15, totalLandscapeCopies: 90 },
    { pageType: "A1", width: 23.39, height: 33.11, cols: 7, rows: 6, copies: 42, landscapeRows: 4, landScapeCols: 10, totalLandscapeCopies: 40 },
    { pageType: "A2", width: 16.54, height: 23.39, cols: 5, rows: 4, copies: 20, landscapeRows: 3, landScapeCols: 7, totalLandscapeCopies: 21 },
    { pageType: "A3", width: 11.69, height: 16.54, cols: 3, rows: 3, copies: 9, landscapeRows: 2, landScapeCols: 5, totalLandscapeCopies: 10 },
    { pageType: "A4", width: 8.27, height: 11.69, cols: 2, rows: 2, copies: 4, landscapeRows: 1, landScapeCols: 3, totalLandscapeCopies: 3 },
    { pageType: "A5", width: 5.83, height: 8.27, cols: 1, rows: 1, copies: 1, landscapeRows: 1, landScapeCols: 2, totalLandscapeCopies: 2 },
    { pageType: "B0", width: 39.37, height: 55.67, cols: 12, rows: 11, copies: 132, landscapeRows: 8, landScapeCols: 18, totalLandscapeCopies: 144 },
    { pageType: "B1", width: 27.83, height: 39.37, cols: 9, rows: 8, copies: 72, landscapeRows: 5, landScapeCols: 12, totalLandscapeCopies: 60 },
    { pageType: "B2", width: 19.69, height: 27.83, cols: 6, rows: 5, copies: 30, landscapeRows: 4, landScapeCols: 9, totalLandscapeCopies: 36 },
    { pageType: "B3", width: 13.89, height: 19.69, cols: 4, rows: 4, copies: 16, landscapeRows: 2, landScapeCols: 6, totalLandscapeCopies: 12 },
    { pageType: "B4", width: 9.33, height: 13.89, cols: 3, rows: 2, copies: 6, landscapeRows: 1, landScapeCols: 4, totalLandscapeCopies: 4 },
    { pageType: "B5", width: 6.94, height: 9.83, cols: 2, rows: 2, copies: 4, landscapeRows: 1, landScapeCols: 3, totalLandscapeCopies: 3 },
    { pageType: "C0", width: 36.46, height: 51.50, cols: 11, rows: 10, copies: 110, landscapeRows: 7, landScapeCols: 16, totalLandscapeCopies: 112 },
    { pageType: "C1", width: 25.51, height: 36.46, cols: 8, rows: 7, copies: 56, landscapeRows: 5, landScapeCols: 11, totalLandscapeCopies: 55 },
    { pageType: "C2", width: 18.03, height: 25.51, cols: 5, rows: 5, copies: 25, landscapeRows: 3, landScapeCols: 8, totalLandscapeCopies: 24 },
    { pageType: "C3", width: 12.91, height: 18.03, cols: 4, rows: 3, copies: 12, landscapeRows: 2, landScapeCols: 5, totalLandscapeCopies: 10 },
    { pageType: "C4", width: 9.03, height: 12.91, cols: 2, rows: 2, copies: 4, landscapeRows: 1, landScapeCols: 4, totalLandscapeCopies: 4 },
    { pageType: "C5", width: 6.42, height: 9.03, cols: 2, rows: 1, copies: 2, landscapeRows: 1, landScapeCols: 2, totalLandscapeCopies: 2 },
  ];

  const colorOptions = [
    { value: '', label: 'None' },
    { value: 'red', label: 'Red' },
    { value: 'blue', label: 'Blue' },
    { value: 'green', label: 'Green' },
    { value: 'orange', label: 'Orange' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'pink', label: 'Pink' },
    { value: 'lightblue', label: 'Light Blue' },
    { value: '#FF671F', label: 'Safron' },
    { value: 'chocolate', label: 'Chocklate' },
    { value: 'darkgray', label: 'Dark Gray' },
    { value: 'gold', label: 'gold' },
    { value: 'goldenrod', label: 'Golden Rod' },
    { value: 'lime', label: 'Lime' },
    { value: 'skyblue', label: 'Sky Blue' },
    { value: 'whitesmoke', label: 'White Smoke' },
    { value: 'yellowgreen', label: 'Yellow Green' },
    // { value: 'aliceblue', label: 'AliceBlue' },
    // { value: 'aquamarine', label: 'Aquamarine' },
    // { value: 'cyan', label: 'Cyan' },
    // { value: 'darkgrey', label: 'Dark Grey' },
    // { value: 'violet', label: 'Violet' },
    // { value: 'ghostwhite', label: 'Ghost White' },
    // { value: 'slateblue', label: 'Slate Blue' },
  ];
  const lstPageOrientation = [
    { value: 'portrait', label: 'Portrait' },
    { value: 'landscape', label: 'Landscape' },
  ];

  useEffect(() => {
    // console .log("id card slider old called, props received");
    // console .log("lstIdCard", lstIdCard);
    // console .log("heading", heading);
    // console .log("lstStudentId", lstStudentId)
    setDefaultPageOrientation();
    setDefaultPageType();
    setDefaultGroupBy();
    setBaseColor("orange");
    setImageUrls();
    getPrinciSignSvc(cbPrinciSign);
    getLogoSvc(cbLogoResponse);
    const delay = (lstStudentId.length / 50) * 1000;
    setTimeout(() => {
      setIsViewPDFButtonVisible(true);
    }, delay);
  }, [lstIdCard, lstStudentId]);

  function cbPrinciSign(imageUrl) {
    // console .log("cbPrinciSign called, imageUrl", imageUrl);
    setImageUrl(imageUrl)
  }
  function cbLogoResponse(imageUrl) {
    // console .log("cbLogoResponse called, imageUrl", imageUrl);
    setLogoUrl(imageUrl)
  }

  const setDefaultPageOrientation = () => {
    setPageOrientation("portrait");
  }

  const setDefaultGroupBy = () => {
    handleSelectGroupBy("Id Card together");
  }

  const setDefaultPageType = () => {
    handleSelectPageType("A4", "portrait");
  }


  const closeSlider = () => {
    removeClassFromBody();
  }


  const setRowColCopies = (pTypeName, pOrientation) => {
    // console .log("setRowColCopies called in idCardSliderCopy, lstPageSizesInches", lstPageSizesInches);
    if (lstPageSizesInches) {
      const pType = lstPageSizesInches.find(type => type.pageType == pTypeName);
      // console .log("pType", pType);
      if (pType) {
        if (pOrientation === "portrait") {
          setTotalRows(pType.rows);
          setTotalCols(pType.cols);
          setTotalCopies(pType.copies);
        } else {
          setTotalRows(pType.landscapeRows);
          setTotalCols(pType.landScapeCols);
          setTotalCopies(pType.totalLandscapeCopies);
        }
      }
    }
  }

  // const getRowCopies = (pTypeName) => {
  //   if (lstPageSizesInches) {
  //     const pType = lstPageSizesInches.find(type => type.pageType == pTypeName);
  //     if (pType) {
  //       return pType.rows;
  //     }
  //   }
  // }

  // const getColCopies = (pTypeName) => {
  //   if (lstPageSizesInches) {
  //     const pType = lstPageSizesInches.find(type => type.pageType == pTypeName);
  //     if (pType) {
  //       return pType.cols;
  //     }
  //   }
  // }

  // const getTotalCopies = (pTypeName) => {
  //   if (lstPageSizesInches) {
  //     const pType = lstPageSizesInches.find(type => type.pageType == pTypeName);
  //     if (pType) {
  //       return pType.copies;
  //     }
  //   }
  // }

  const setImageUrls = () => {
    for (var i = 0; i < lstStudentId.length; i++) {
      getImageUrls(lstStudentId[i]);
    }
  }

  const getImageUrls = (stdId) => {
    getDocDtlsSvc(stdId, docDtlsResponse);
  }

  const docDtlsResponse = (data) => {
    var obj = data;
    // console .log("obj in docDtlsResponse",obj);
    const idx = lstStudentId.indexOf(obj.stdId);
    var docDtls = obj.data.lstDocDtls;

    // let imgUrl = window.appURL + '/no-image.png';
    let imgUrl = NoImage;
    const photoUrl = docDtls.find(doc => doc.typeId == 50);
    // console .log("photoUrl",photoUrl);
    if (photoUrl) {
      imgUrl = httpURLStudent + "/sms/student/docs/view?imgPath=" + photoUrl.imgPath;
      // console .log("httpUrlStudent", httpURLStudent);
      // console .log("/sms/student/docs/view?imgPath=");
      // console .log("photoUrl.imgPath", photoUrl.imgPath);
      // console .log("imgUrl", imgUrl);
    }

    lstIdCardDtls[idx].picURL = imgUrl;
    // console .log("lstIdCardDtls[idx]",lstIdCardDtls[idx], " idx",idx);
  }

  const handleReissueFeeChange = (e) => {
    setReissueFee(e.target.value);
    hidePDF();
    updateKey();
  }

  const handleSelTptChange = (e) => {
    setSelTpt(e.target.checked);
    hidePDF();
    updateKey();
  }

  const handleSelGroupChange = (e) => {
    setSelGroup(e.target.checked);
    hidePDF();
    updateKey();
  }

  const handleGroupPrintIdChange = (e) => {
    setGroupPrintId(e);
    hidePDF();
    updateKey();
  }

  const handleBaseColourChange = (e) => {
    setBaseColor(e.target.value);
    hidePDF();
    hidePDF();
    updateKey();
  }

  const handleSelectGroupBy = (grpBy) => {
    setGroupIdCardBy(grpBy);
    if (grpBy === "Id Card together") {
      setGroupPrintId(30);
      setisSelectGroupPrintOpen(false);
    }
    else {
      setGroupPrintId(10);
      setisSelectGroupPrintOpen(true);
    }
    hidePDF();
    updateKey();
  }

  const handlePageOrientation = (pOrientation) => {
    handleSelectPageType(selPageType || "A4", pOrientation);
    setPageOrientation(pOrientation);
    hidePDF();
    updateKey();
  }

  const handleSelectPageType = (pType, pOrientation) => {
    // console .log("pageType Selected", pType);
    // console .log("pageType befor change", selPageType);
    setRowColCopies(pType, pOrientation);
    setSelPageType(pType);
    hidePDF();
    updateKey();
  }

  // const handleClick = (e) => {
  //   e.stopPropagation();
  //   const delay = (lstStudentId.length / 50) * 1000;
  //   setLoader("block");
  //   setTimeout(() => {
  //     viewPDF();
  //     setLoader("none");
  //   }, delay);
  // };

  const viewPDF = (e) => {
    e.stopPropagation();
    updateKey();
    setIsPDFVisible(true);
  }

  const hidePDF = () => {
    setPdfKey(0);
    setIsPDFVisible(false);
  }

  const updateKey = () => {
    setPdfKey((prevKey) => prevKey + 1);
  }

  return (
    <div className='padding-remove'>
      {/* <div style={{ position: "absolute", zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: "50%", top: "50%", display: loader, }}>
        <LoaderImage />
      </div> */}
      <div className='col-sm-12 col-centered padding-remove' >
        <div
          className='EnquiryHead col-sm-12 col-centered'
          style={{
            border: '1px solid #aaa',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: 'bold'
          }}>
          <h4>{heading}</h4>
        </div>
        <div className='col-sm-12 padding-remove' style={{ marginTop: '1%' }}>
          <div className='col-sm-6 padding-remove'>
            <label className='col-sm-6 control-label'>Backgorund Colour</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={baseColor}
                onChange={(e) => handleBaseColourChange(e)}
              >
                <option value="">Backgorund Color</option>
                {colorOptions.map((colorOption) => (
                  <option key={colorOption.value} value={colorOption.value}>
                    {colorOption.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-sm-6 padding-remove '>
            <label className='col-sm-6 control-label'>Re-Issue Fees</label>
            <div className='form-group col-sm-4 padding-remove'>
              <input
                type='text'
                className='form-control'
                defaultValue={reissueFee}
                onChange={e => handleReissueFeeChange(e)}
              ></input>
            </div>
          </div>
        </div>
        <div className='col-sm-12 padding-remove'>
          <div className='col-sm-6 padding-remove'>
            <label className='col-sm-6 control-label'>Transport Details</label>
            <div className='form-group'>
              <input
                type='checkbox'
                defaultChecked={selTpt}
                onClick={e => handleSelTptChange(e)}>
              </input>
            </div>
          </div>
          <div className='col-sm-6 padding-remove'>
            <label className='col-sm-6 control-label'>Group Details</label>
            <div className='form-group'>
              <input
                type='checkbox'
                defaultChecked={selGroup}
                onClick={e => handleSelGroupChange(e)}
              ></input>
            </div>
          </div>
        </div>
        <div className='col-sm-12 padding-remove'>
          <div className='col-sm-6 padding-remove'>
            <label className='col-sm-6 control-label'>Page orientation</label>
            <div className='form-group col-sm-4 padding-remove'>
              <select
                className="form-control"
                value={pageOrientaion}
                onChange={(e) => handlePageOrientation(e.target.value)}
              // disabled
              >
                <option value="">Page orientation</option>
                {lstPageOrientation.map((orientation) => (
                  <option key={orientation.value} value={orientation.value}>
                    {orientation.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-sm-6 padding-remove'>
            <label className='col-sm-6 control-label'>Page Type</label>
            <div className='form-group col-sm-4 padding-remove'>
              <select
                className="form-control"
                value={selPageType}
                onChange={(e) => handleSelectPageType(e.target.value, pageOrientaion)}
              // disabled
              >
                <option value="">Page Type</option>
                {lstPageSizesInches.map((pageType) => (
                  <option key={pageType.pageType} value={pageType.pageType}>
                    {pageType.pageType} - {(pageOrientaion === "portrait") ? pageType.copies : pageType.totalLandscapeCopies} sides per page
                  </option>
                ))}
              </select>
            </div>
          </div>

        </div>
        <div className='col-sm-12 padding-remove'>
          <div className='col-sm-6 padding-remove'>
            <label className='col-sm-6 control-label'>Group ID Card</label>
            <div className='form-group col-sm-4 padding-remove'>
              <select
                className="form-control"
                value={groupIDCardBy}
                onChange={(e) => handleSelectGroupBy(e.target.value)}
              // disabled
              >
                <option value="">Group By</option>
                {lstGroupBy.map((groupBy, index) => (
                  <option key={index} value={groupBy}>
                    {groupBy}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isSelectGroupPrintOpen &&
            <div className='col-sm-6 padding-remove '>
              <label className="control-label col-sm-4" > Select To Print:</label>
              <div className="col-sm-6 padding-remove form-group" style={{ display: 'flex', justifyContent: "space-around" }}>
                <input id="frontPrint" type="radio" checked={groupPrintId === 10} onChange={() => handleGroupPrintIdChange(10)} value={groupPrintId} />
                <label htmlFor="school">Front Page</label>
                <input id="backPrint" type="radio" checked={groupPrintId === 20} onChange={() => handleGroupPrintIdChange(20)} value={groupPrintId} />
                <label htmlFor="wing">Back Page</label>
              </div>
            </div>
          }
        </div>
        {
          isViewPDFButtonVisible ?
            <div className='col-sm-12 padding-remove'>
              <div className='form-group col-sm-5 padding-remove'>
                <button
                  type="button"
                  className="btn btn-success pull-right"
                  style={{ width: "175px" }}
                  onClick={(e) => viewPDF(e)}
                >
                  View PDF
                </button>
              </div>
            </div>
            : null
        }
      </div >

      {
        isPDFVisible ?
          <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
            <PrintPage
              pdfKey={pdfKey}
              lstIdCard={lstIdCardDtls}
              selGroup={selGroup}
              selTpt={selTpt}
              baseColor={baseColor}
              reissueFee={reissueFee}
              selPageType={selPageType}
              groupPrintId={groupPrintId}
              lstStudentId={lstStudentId}
              logoUrl={logoUrl}
              imageUrl={imageUrl}
              totalRows={totalRows}
              totalCols={totalCols}
              totalCopies={totalCopies}
              pageOrientaion={pageOrientaion}
              updateKey={updateKey}
            />
          </div>
          : null
      }
      <button
        type='button'
        className='btn btn-info text-center '
        id='closeSlider'
        onClick={e => closeSlider()}
      >
        <span
          id='close'
          title='Close Slider'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
    </div >
  );
}

export default IdCardSlider;