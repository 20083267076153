import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";


import "common/css/master.css";
import { dtToDMY, dmyToDate } from 'common/utils/dateUtil';

import { saveCertificateSvc } from './tcSvc';



const TcForm = (props) => {
    const { certificateId, setCertificateId, studentId, studentName, clsSection, setEditLoader, setEditOpacity, openCertificatePDf, studentDtlsObj, lstApprovalAuthority, flgPrinted } = props;

    const [reqestedDt, setRequestedDt] = useState(null);
    const [approvedBy, setApprovedBy] = useState(0);
    const [approvedDt, setApprovedDt] = useState(null);
    const [notes, setNotes] = useState("");
    const [internalRemarks, setInternalRemarks] = useState("");
    const [printDt, setPrintDt] = useState("");
    const [certStatusId, setCertStatusId] = useState(0);
    const [nationality, setNationality] = useState("");
    const [religion, setReligion] = useState("");
    const [educationBoard, setEducationBoard] = useState("");
    const [flgElegiblePromotion, setFlgElegiblePromotion] = useState(false);
    const [flgFeesCleared, setFlgFeesCleared] = useState(false);
    const [lastClass, setLastClass] = useState("");
    const [pendingFees, setPendingFees] = useState("");
    const [otherDues, setOtherDues] = useState("");
    const [generalConduct, setGeneralConduct] = useState("");
    const [reasonTc, setReasonTc] = useState("");
    const [attendance, setAttendance] = useState("");

    useEffect(() => {
        //// console .log("TcForm called");
        if (!!certificateId && studentDtlsObj) {
            setApprovedBy(studentDtlsObj.approveBy);
            setApprovedDt(dmyToDate(studentDtlsObj.approveDt));
            setRequestedDt(dmyToDate(studentDtlsObj.reqDt));
            setInternalRemarks(studentDtlsObj.remarksInternal);
            setNotes(studentDtlsObj.remarksPrint);
            setPrintDt(studentDtlsObj.printDt);
            setCertStatusId(studentDtlsObj.certStatusId);
            setNationality(studentDtlsObj.nationality);
            setReligion(studentDtlsObj.religion);
            setEducationBoard(studentDtlsObj.educationBoard);
            setLastClass(studentDtlsObj.lastClass);
            setFlgElegiblePromotion(studentDtlsObj.promoteToNextCls);
            setFlgFeesCleared(studentDtlsObj.feeCleared);
            setPendingFees(studentDtlsObj.feeClearedRemarks);
            setOtherDues(studentDtlsObj.otherDues);
            setAttendance(studentDtlsObj.attendance);
            setGeneralConduct(studentDtlsObj.generalConduct);
            setReasonTc(studentDtlsObj.reasonForTc);
        } else {
            setApprovedBy(0);
            setApprovedDt(null);
            setRequestedDt(null);
            setInternalRemarks("");
            setNotes("");
            setPrintDt(null);
            setCertStatusId(0);
            setNationality("");
            setReligion("");
            setEducationBoard("");
            setLastClass("");
            setFlgElegiblePromotion(false);
            setFlgFeesCleared(false);
            setPendingFees("");
            setOtherDues("");
            setAttendance("");
            setGeneralConduct("");
            setReasonTc("");
        }
    }, [studentId, certificateId, studentDtlsObj]);

    const saveCertificate = (nPrintDt) => {
        const reqDt = dtToDMY(reqestedDt);
        const approveDt = dtToDMY(approvedDt) || null;
        //// console .log("saveCertificate called");
        // if (!certificateId || certificateId <= 0) {
        //     // toast.warn('Certificate ID is required and must be a positive integer.');
        //     return;
        // }

        if (!studentId || studentId <= 0) {
            toast.warn('Student ID is required and must be a positive integer.');
            return;
        }

        if (!reqDt || !validateDateFormat(reqDt)) {
            toast.warn('Request Date must be in dd-mm-yyyy format.');
            return;
        }

        // Validate conditional fields
        if (approvedBy && (!approveDt || !validateDateFormat(approveDt))) {
            toast.warn('Approved Date is required and must be in dd-mm-yyyy format when Approved By is filled.');
            return;
        }

        const tcCertObj = {
            certificateId,
            "studentId": studentId,
            reqDt,
            "approveBy": approvedBy,
            "approveDt": approvedBy ? approveDt : null,
            "remarksPrint": notes,
            "remarksInternal": internalRemarks,
            nationality,
            religion,
            educationBoard,
            lastClass,
            "promoteToNextCls": flgElegiblePromotion,
            "feeCleared": flgFeesCleared,
            "feeClearedRemarks": pendingFees,
            otherDues,
            attendance,
            generalConduct,
            "reasonForTc": reasonTc,
        }
        console.log("tcCertObj", tcCertObj);

        setEditLoader("block");
        setEditOpacity(0.5);
        if (nPrintDt) {
            tcCertObj.printDt = nPrintDt;
            saveCertificateSvc(tcCertObj, savePrintCertificateResponse);
        } else {
            saveCertificateSvc(tcCertObj, saveCertificateResponse);
        }
    }

    const validateDateFormat = (dateString) => {
        const regex = /^\d{2}-\d{2}-\d{4}$/; // dd-mm-yyyy
        return regex.test(dateString);
    };

    const saveCertificateResponse = (data) => {
        //// console .log("saveCertificateResponse called, data", data);
        if (data.status === "Success") {
            if (!certificateId) {
                setCertificateId(data.certificateId);
            }
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }
    const savePrintCertificateResponse = (data) => {
        // console .log("saveCertificateResponse called, data", data);
        if (data.status === "Success") {
            setCertificateId(data.certificateId);
            openCertificatePDf(certificateId);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }

    const savePrintCertificate = () => {
        // console .log("savePrintCertificate called");
        if (!flgPrinted) {
            let dt = new Date();
            dt = dtToDMY(dt);
            saveCertificate(dt);
        } else {
            openCertificatePDf(certificateId);
        }

    }

    const deleteCertificate = () => {
        //// console .log("deleteCertificate called");
    }

    const handleApprovedByChange = (pApprovedBy) => {
        //// console .log("handleApprovedChange called, pApprovedBy", pApprovedBy);
        //// console .log("lstApprovalAuthority", lstApprovalAuthority);
        setApprovedBy(pApprovedBy);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };



    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <div className="col-sm-12 col-md-12 padding-remove"
                style={{ width: '95%', margin: '0 0 0 5%', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className="col-sm-12 col-md-12 padding-remove" style={{ margin: "10px 0 0 0" }}>
                    <div className="col-sm-8 col-md-8 ">
                        <label className="control-label col-md-6 padding-remove text-left">
                            {studentName ? studentName : ""}
                        </label>
                        <label className="control-label col-md-6 padding-remove text-left">
                            {clsSection ? clsSection : ""}
                        </label>
                    </div>
                </div>
                <form
                    id="certificateAddForm"
                    className='form form-horizontal'
                    style={{ height: "80vh", overflowX: 'hidden', padding: "20px 20px", }}
                >
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Nationality:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={20}
                                    value={nationality ? nationality : ""}
                                    onChange={(e) => setNationality(e.target.value)}
                                    placeholder='Nationality'
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Religion:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={20}
                                    value={religion ? religion : ""}
                                    onChange={(e) => setReligion(e.target.value)}
                                    placeholder='Religion'
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Education Board:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={20}
                                    value={educationBoard ? educationBoard : ""}
                                    onChange={(e) => setEducationBoard(e.target.value)}
                                    placeholder='Education Board'
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Last Class:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={10}
                                    value={lastClass ? lastClass : ""}
                                    onChange={(e) => setLastClass(e.target.value)}
                                    placeholder='Last Class'
                                ></input>
                            </div>
                        </div>
                        {/* </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}> */}
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-6 padding-remove">
                                Promotion to Next Class:
                            </label>
                            <div className='col-sm-2 '>
                                <input type="checkbox"
                                    className="form-control"
                                    checked={flgElegiblePromotion}
                                    onChange={(e) => setFlgElegiblePromotion(e.target.checked)}
                                    onClick={(e) => { e.stopPropagation(); }}
                                    name='Eligible for promotion to next Class'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Fee Cleared:
                            </label>
                            <div className='col-sm-2 '>
                                <input type="checkbox"
                                    className="form-control"
                                    checked={flgFeesCleared}
                                    onChange={(e) => setFlgFeesCleared(e.target.checked)}
                                    onClick={(e) => { e.stopPropagation(); }}
                                    name='Fee Cleared'
                                />
                            </div>
                        </div>
                        {
                            !flgFeesCleared ?
                                <div className="form-group col-md-12 padding-remove">
                                    <label className="control-label col-md-4 padding-remove">
                                        Pending Fees:
                                    </label>
                                    <div className='col-sm-8 '>
                                        <textarea
                                            className="form-control"
                                            rows="4" // Adjust this number to set the number of visible lines
                                            maxLength={100}
                                            placeholder='Pending Fees'
                                            value={pendingFees ? pendingFees : ""}
                                            onChange={(e) => setPendingFees(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                : null
                        }
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Other Fee Dues:
                            </label>
                            <div className='col-sm-8 '>
                                <textarea
                                    className="form-control"
                                    rows="4" // Adjust this number to set the number of visible lines
                                    maxLength={100}
                                    value={otherDues ? otherDues : ""}
                                    onChange={(e) => setOtherDues(e.target.value)}
                                    placeholder='Other Fee Dues'
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Attendance:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={10}
                                    value={attendance ? attendance : ""}
                                    onChange={(e) => setAttendance(e.target.value)}
                                    placeholder='Attendance'
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                General Conduct:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={20}
                                    value={generalConduct ? generalConduct : ""}
                                    onChange={(e) => setGeneralConduct(e.target.value)}
                                    placeholder='General Conduct'
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Reason For Tc:
                            </label>
                            <div className='col-sm-7 '>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={20}
                                    value={reasonTc ? reasonTc : ""}
                                    onChange={(e) => setReasonTc(e.target.value)}
                                    placeholder='Reason For Tc'
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Notes:
                            </label>
                            <div className="col-sm-8 ">
                                <textarea
                                    className="form-control"
                                    rows="8"
                                    maxLength={200}
                                    placeholder="Notes"
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Internal Remarks:
                            </label>
                            <div className="col-sm-8 ">
                                <textarea
                                    className="form-control"
                                    rows="8" // Adjust this number to set the number of visible lines
                                    placeholder="Internal Remarks"
                                    maxLength={200}
                                    value={internalRemarks}
                                    onChange={(e) => setInternalRemarks(e.target.value)}
                                ></textarea>

                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Request Date:
                            </label>
                            <div className="col-sm-6 ">
                                <DatePicker
                                    className="form-control"
                                    placeholderText="Request Date"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    selected={reqestedDt}
                                    onChange={(date) => setRequestedDt(date)}
                                    autoComplete="off"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Approved By:
                            </label>
                            <div className="col-sm-6 ">
                                <select autoComplete="off" name="Approved by"
                                    type="text" className="form-control "
                                    value={approvedBy}
                                    onChange={(e) => handleApprovedByChange(e.target.value)}
                                >
                                    <option value="0" >Approved By</option>
                                    {lstApprovalAuthority && lstApprovalAuthority.length ?
                                        lstApprovalAuthority.map((obj) => (
                                            <option key={obj.userId} value={obj.userId} >{obj.userName}</option>
                                        )) : null
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            approvedBy != 0 ?
                                <div className="form-group col-md-12 padding-remove">
                                    <label className="control-label col-md-4 padding-remove">
                                        Apporved Date:
                                    </label>
                                    <div className="col-sm-6 ">
                                        <DatePicker
                                            className="form-control"
                                            placeholderText="Approved Date"
                                            dateFormat="dd-MM-yyyy"
                                            dropdownMode="select"
                                            selected={approvedDt}
                                            onChange={(date) => setApprovedDt(date)}
                                            autoComplete="off"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                        />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className='col-sm-6  pull-right padding-remove' style={{ height: "50px" }}>
                        <div className="col-sm-6 col-md-6  padding-remove">
                            <button type="button" className="btn btn-info " onClick={() => saveCertificate(printDt)} disabled={flgPrinted}>Submit</button>
                        </div>
                        <div className="col-sm-6 col-md-6  padding-remove">
                            <button type="button" className="btn btn-info " onClick={(e) => savePrintCertificate(e)} disabled={!certificateId}>Print</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default TcForm;


