import React, { useState, useEffect } from "react";
import "../css/feedback.css";
import { getAreaListSvc, getChnlListSvc, getFeedbackfromBE, saveReplySvc } from './FeedbackSliderSvc'
import { toast } from 'react-toastify';


const FeedbackSlider = (props) => {
    const [caseId, setCaseId] = useState("");
    const [areaId, setAreaId] = useState("");
    const [type, setType] = useState("");
    const [subject, setSubject] = useState("");
    const [teacher, setTeacher] = useState("");
    const [description, setDescription] = useState("");
    const [flgUrgent, setFlgUrgent] = useState("");
    const [status, setStatus] = useState("");
    const [lstArea, setLstArea] = useState([]);
    const [lstCorrespondence, setLstCorrespondence] = useState([]);
    const [reply, setReply] = useState('');
    const [flgDivAddReply, setFlgAddReply] = useState(false);
    const [channelId, setChannelId] = useState('');
    const [lstChnl, setLstChnl] = useState([]);
    const [chatId, setChatId] = useState(0);

    useEffect(() => {
        setCaseId(props.caseNo)
        setStatus(props.status)
        getAreaListSvc(cbAreaListResponse)
        getChnlListSvc(cbChnlListResponse)
        if (props.caseNo !== 0) {
            getFeedbackfromBE(cbFeedbackDtlsResponse, props.caseNo);
        }
    }, [props.caseNo])

    function cbAreaListResponse(data) {
        if (data.SvcStatus === "Success") {
            setLstArea(data.lstArea)
        } else {
            toast.error(data.SvcStatus)
        }
    }

    function cbChnlListResponse(data) {
        if (data.SvcStatus === "Success") {
            setLstChnl(data.lstChannel)
        } else {
            toast.error(data.SvcStatus)
        }
    }


    function cbFeedbackDtlsResponse(result) {
        // console .log(result)
        if (result.status === "Success") {
            setAreaId(result.feedbackDtls.areaId);
            setType(result.feedbackDtls.type);
            setSubject(result.feedbackDtls.subject);
            setDescription(result.feedbackDtls.description);
            setFlgUrgent(result.feedbackDtls.flgUrgent);
            setStatus(result.feedbackDtls.status);
            setTeacher(result.feedbackDtls.teacher);
            setLstCorrespondence(result.feedbackDtls.lstCorrespondence);
            setChannelId(result.feedbackDtls.channelId);
        } else {

            toast.error(result.message)
        }
    }

    function saveReply() {
        var obj = {};
        obj.caseNo = caseId
        obj.dtls = reply
        // state.chatId = chatId
        obj.channelId = channelId
        saveReplySvc(cbReplyResponse, obj)
    }

    function cbReplyResponse(data) {
        if (data.status === "Success") {
            toast.success(data.message)
            props.closeSlider(caseId)

        }
        else { toast.error(data.message) }
    }

    function handleClick() {
        setFlgAddReply(true)
    }

    function rowClick(e) {
        var id = e.chatId
        setChatId(e.chatId)
    }

    return (
        <>
            <div className="col-sm-12" style={{ overflow: 'hidden' }}>
                <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold'
                }}><h4>Feedback Details</h4></div>
                <div className="form form-horizontal formwrapper col-sm-12 feedbackReply" style={{ marginTop: 0, paddingTop: 0 }}>
                    <div className="col-sm-12">
                        <div className="col-sm-6 padding-remove">
                            <label className="control-label col-sm-4 text-left"><strong>Case No.</strong></label>
                            <div className="form-group col-sm-4 text-left">
                                <label className="control-label"><strong>{caseId}</strong></label>
                            </div>
                        </div>

                        <div className="col-sm-6 padding-remove">
                            <label className="control-label col-sm-2 text-left"><strong>Status</strong></label>
                            <div className="form-group col-sm-4 text-left">
                                <label className="control-label" style={{ textTransform: 'uppercase' }}><strong>{status}</strong></label>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left"><strong>Subject </strong></label>
                        <div className="form-group col-sm-10 text-left">
                            <input type="text" readOnly className="form-control" defaultValue={subject} onChange={(event) => setSubject(event.target.value)} />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left"><strong>Description </strong></label>
                        <div className="form-group col-sm-10 text-left">
                            <textarea rows="6" readOnly style={{ resize: "none" }} className="form-control" defaultValue={description} onChange={(event) => setDescription(event.target.value)} />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left"><strong>Urgent </strong></label>
                        <div className="col-sm-1 padding-remove text-left">
                            <input disabled className="form-check-input" type="checkbox" checked={flgUrgent} onChange={(event) => setFlgUrgent(event.target.value)} />
                        </div>
                    </div>


                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left"><strong>Type</strong> </label>
                        <div className="form-group col-sm-2 text-left">
                            <input type="text" readOnly className="form-control" defaultValue={type} onChange={(event) => setType(event.target.value)} />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left"><strong>Area</strong> </label>
                        <div className="form-group col-sm-2 text-left">
                            <select className="form-control" value={areaId} onChange={() => getAreaListSvc()}>
                                <option value={0}>Area</option>
                                {lstArea.map((obj, idx) => {
                                    return (
                                        <option key={idx} value={obj.areaId}>
                                            {obj.area}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <label className="col-sm-2 control-label text-left"><strong>Teacher</strong> </label>
                        <div className="form-group col-sm-2 text-left">
                            <input type="text" className="form-control" readOnly defaultValue={teacher} onChange={(event) => setTeacher(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12" style={{ overflow: 'hidden' }}>
                <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold'
                }}><h4>Correspondence</h4></div>
                {lstCorrespondence.length && <div className="form form-horizontal formwrapper col-sm-12 feedbackReply" style={{ overflowY: 'scroll', height: '400px' }}>
                    {lstCorrespondence.map((feed, idx) => (
                        <div className="row" style={{
                            background: feed.flgAdmin ? '#ffc107' : 'grey',
                            overflow: 'hidden'
                        }} key={idx} onMouseOver={(event) => rowClick(feed)}>
                            <div className="col-sm-12" style={{ background: 'white', marginLeft: '2%' }}>
                                <div className="col-sm-12" >
                                    <label className="col-sm-2 control-label text-left">
                                        Date
                                    </label>

                                    <div className="col-sm-3 form-group">
                                        <input type="text" readOnly className="form-control" value={feed.dt} />
                                    </div>
                                    <div className="col-sm-7 form-group text-right">
                                        {!feed.flgAdmin && Array.from(Array(feed.useful), (e, i) => {
                                            return <span key={i} style={{ color: '#ffc107', fontSize: 24 }} className="glyphicon glyphicon-star"></span>
                                        })}
                                        {!feed.flgAdmin && Array.from(Array(5 - feed.useful), (e, i) => {
                                            return <span key={i} style={{ fontSize: 24 }} className="glyphicon glyphicon-star-empty"></span>
                                        })}
                                    </div>
                                </div>


                                <div className="col-sm-12">
                                    <label className="col-sm-2 control-label text-left">
                                        Details
                                    </label>
                                    <div className="form-group col-sm-10">
                                        <textarea className="form-control" readOnly rows="5" name="text" value={feed.dtls} />
                                    </div>
                                </div>
                                <div className="col-sm-12" style={{ borderBottom: '1px solid #b4b4b4' }}>
                                    <label className="col-sm-2 control-label text-left">Channel</label>
                                    <div className="col-sm-2 form-group">
                                        <select className="form-control" defaultValue={feed.channelId}>
                                            <option value={0}>Channel</option>
                                            {lstChnl.map((obj, idx) => {
                                                return (
                                                    <option key={idx} value={obj.channelId}>
                                                        {obj.channel}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div >

                        {/* <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 m-0 p-0">
                        <div type="button" className="btn btn-light text-center btn_slider" id="closeAddFeedbackForm" onClick={(e) => setFlgAddReply(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                <path stroke="#ffc107" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div> */}

                    </div>



                    {/* <div>
                        {!flgDivAddReply &&
                            <button onClick={(e) => handleClick()} style={{ marginTop: '1%' }} className="btn btn-outline-warning text-dark fw-bold btn_primary">
                                Reply
                            </button>}
                        {flgDivAddReply &&
                            <button onClick={(e) => saveReply()} style={{ marginTop: '1%' }} className="btn btn-outline-warning text-dark fw-bold btn_primary">
                                Save
                            </button>}
                    </div> */}
                </div>}
                <div className="form form-horizontal formwrapper col-sm-12 feedbackReply" style={{ borderTop: '1px solid #b4b4b4' }}>
                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left">
                            Details
                        </label>
                        <div className="col-sm-10 form-group">
                            <textarea className="form-control" rows="5" name="text" value={reply} onChange={(event) => setReply(event.target.value)} />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <label className="col-sm-2 control-label text-left">Channel </label>
                        <div className="col-sm-2 form-group">
                            <select className="form-control" value={channelId} onChange={(event) => setChannelId(event.target.value)}>
                                <option value={0}>Channel</option>
                                {lstChnl.map((obj) => {
                                    return (
                                        <option key={obj.channelId} value={obj.channelId}>
                                            {obj.channel}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <span title="Reply" onClick={(e) => saveReply()} className="addIcon glyphicon glyphicon-send pull-right"></span>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FeedbackSlider