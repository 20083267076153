import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';


const tokenid = localStorage.getItem("tokenid");
const httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port + "/sms/exam_syllabus";
const httpURLComman = lstModules['performance'].url + ":" + lstModules['performance'].port;


export const getExamListSvc = (cbExamList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/exam_list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getExamListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstExam = response.data.lstExam;
        }
        cbExamList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbExamList(obj);
    })
}


export const getClassSectionListSvc = (cbClassSectionList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLComman + '/sms/performance/mst/all_section_list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getClassSectionListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstAllClassSections = response.data.lstAllClassSections;
        }
        cbClassSectionList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbClassSectionList(obj);
    })
}


export const getSyllabusDetailsSvc = (examId, sectionId, cbSyllabusDetails) => {
    // console .log("getSyllabusDetailsSvc called, examId", examId, ", sectionId", sectionId);
    const formData = new FormData();
    formData.append("examId", examId);
    formData.append("sectionId", sectionId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/syllabus_dtls',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params:{"syllabusId":syllabusId},
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getSyllabusDetailsSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // console .log("response.data in getSyllabusDetailsSvc", response.data);
            obj.headerSection = response.data.headerSection;
            obj.lstExam = response.data.lstExam;
        }
        cbSyllabusDetails(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSyllabusDetails(obj);
    })
}



