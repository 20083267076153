import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
import Datepicker from "react-datepicker";
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');

function StudentSlider(props) {
    const [loader, setLoader] = useState('none')
    const [lstStop, setLstStop] = useState([])
    const [name, setName] = useState("")
    const [age, setAge] = useState()
    const [cls, setCls] = useState()
    const [gender, setGender] = useState()
    const [stopId, setStopId] = useState()
    const [rtId, setRtId] = useState()
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const [monthInt, setMonthInt] = useState()
    const [dt, setDt] = useState()
    const [rt, setRt] = useState("")
    const [selIdx, setSelIdx] = useState()
    const [loc, setLoc] = useState("")
    const [lstFound, setLstFound] = useState(lstStop)
    const [baseDist, setBaseDist] = useState()
    const lstMonth = ['Month', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    const lstYear = [2022, 2023, 2024, 2025, 2026, 2027, 2028,
        2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
        2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044,
        2045, 2046, 2047, 2048, 2049, 2050]
    function save(cbSave) {
        var stopDtls = {
            studentId: props.selStudentId,
            stopId: stopId,
            routeId: rtId,
            tptStartFeeYyyyMm: dt,
            baseDistance: baseDist
        }
        const formData = new FormData();
        formData.append("stopDtls",JSON.stringify(stopDtls));
        axios({
            url: httpURL + '/sms/tpt/student/stop/save',
            method: 'POST',
            // params: { stopDtls },
            data:formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }).then((Response) => {
            var data = Response.data
            cbSave(data)
        }).catch((error) => {
            // console .log('error' + error)
        });
    }
    function cbSave(data) {
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg)
            props.closeSlider()
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    useEffect(() => {
        if (!!props.selStudentId) {
            getLstStop(cbGetLstStop)
        }
        setName(props.selObj.name)
        setAge(props.selObj.age)
        setCls(props.selObj.cls)
        setGender(props.selObj.gender)
        setStopId(props.selObj.stopId)
        setRtId(props.selObj.routeId)
        setDt(props.selObj.tptStartFeeYyyyMm)
        getMonthYr(props.selObj.tptStartFeeYyyyMm)
    }, [])
    function getMonthYr(dt) {
        var mon = (dt % 100)
        var yr = Math.floor(dt / 100)
        // console .log('yr::' + yr + ' :: ' + typeof (yr))
        // console .log('mon::' + mon + ' :: ' + typeof (mon))
        setMonth(lstMonth[mon])
        setYear(yr)
        setMonthInt(mon)
    }

    function getLstStop(callBack) {
        axios({
            url: httpURL + '/sms/tpt/student/stop/list',
            method: 'POST',
            params: { studentId: props.selStudentId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }).then((Response) => {
            var data = Response.data
            callBack(data)
        }).catch((error) => {
            // console .log('error' + error)
        });
    }
    function cbGetLstStop(data) {
        setLoader('none')
        if (data.SvcStatus === 'Success') {
            setLstStop(data.lstStop)
            getSelRow(data.lstStop)
            setLstFound(data.lstStop)
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function getSelRow(param) {
        for (var i = 0; i < param.length; i++) {
            if (param[i].selected === 1) {
                setSelIdx(i)
            }
        }
    }
    function handleMonthChange(param) {
        var m = lstMonth.indexOf(param)
        // console .log(m)
        setMonth(param)
        var dt = year * 100 + m
        // console .log(dt)
        setDt(dt)
        setMonthInt(m)
    }

    function handleYearChange(param) {
        var dt = param * 100 + monthInt
        // console .log(dt)
        setDt(dt)
        setYear(param)
    }


    const colgrp = (
        <colgroup>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="12%"></col>
        </colgroup>
    );
    function handleRowClick(idx, obj) {
        setRtId(obj.routeId)
        setStopId(obj.stopId)
        setBaseDist(obj.baseDistance)
        setSelIdx(idx)

    }
    const filterLoc = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = lstFound.filter((user) => {
                return user.locality.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setLstFound(results);
        } else {
            setLstFound(lstStop);
            // If the text field is empty, show all users
        }

        setLoc(keyword);
    };
    const filterRt = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = lstFound.filter((user) => {
                return user.routeNo.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setLstFound(results);
        } else {
            setLstFound(lstStop);
            // If the text field is empty, show all users
        }

        setRt(keyword);
    };
    return (<form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: '100%', width: '100%', margin: '0px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider}>
            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
        </button>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Name</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" defaultValue={name} readOnly />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Age</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" defaultValue={age} readOnly />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Class</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" defaultValue={cls} readOnly />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Gender</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" defaultValue={gender} readOnly />
                </div>
            </div>
        </div>
        <div className="form-group col-md-12">
            <div className='col-sm-12 padding-remove'>
                <table className="col-sm-8  col-sm-offset-1">
                    {colgrp}
                    <thead>
                        <tr>
                            <th>Distance From Home</th>
                            <th style={{ width: 75 }}>
                                <input onChange={filterRt} type="text" value={rt} className="form-control inp-search" placeholder="Route " />
                            </th>
                            <th>Stop</th>
                            <th>Stop Range</th>
                            <th>City</th>
                            <th style={{ width: 75 }}>
                                <input onChange={filterLoc} type="text" value={loc} className="form-control inp-search" placeholder="Locality" />
                            </th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lstFound.map((obj, idx) => {
                            return (<tr key={idx}
                                className={idx === selIdx ? 'selected' : ''}
                                onClick={(e) => handleRowClick(idx, obj)}

                            >
                                <td>{obj.baseDistance}</td>
                                <td>{obj.routeNo}</td>
                                <td>{obj.stopNo}</td>
                                <td>{obj.stopRange}</td>
                                <td>{obj.city}</td>
                                <td>{obj.locality}</td>
                                <td>{obj.address}</td>

                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Fee Start Month</label>
                <div className='col-sm-2 padding-remove'>
                    <select
                        className="form-control "

                        onChange={(e) => handleYearChange(e.target.value)}
                        value={year}
                    >
                        <option value={""}>Year</option>

                        {lstYear.map((year, idx) => {
                            return (
                                <option key={idx} value={year}>{year}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-sm-3 ">
                    <select
                        className="form-control"

                        onChange={(e) => handleMonthChange(e.target.value)}
                        value={month}
                    >
                        {lstMonth.map((month, idx) => {
                            return (
                                <option key={idx} value={month}>{month}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>
        <div className="row">
            <button type="button" className="btn btn-info btn-space col-md-offset-3" onClick={(e) => save(cbSave)}>Save</button>
            {/* </div>
            <div className="row"> */}
        </div>
    </form>)
}
export default StudentSlider;