import React, { useEffect } from 'react'
import '../css/EnquiryCtrl.css'
import { useState } from 'react'
import EvaluateTestTable from './EvaluateTestTable'
import ResultForm from './ResultForm'

function EvaluateTestSlider(props) {

    const [testSlotId, setTestSlotId] = useState(0)
    const [randNoEvalForm, setRandNoEvalForm] = useState(0);
    const [lstTestId, setLstTestId] = useState([])
    const [loader,setLoader]=useState('none')

    //const [lstTestSlotId, setLstTestSlotId] = useState([])
    function generateRandomNo() {
        return Math.floor(Math.random() * 999999);
    }
    function setSelectedTestId(param) {
        // console .log("setSelectedTestId triggered in app.js   " + param)
        setLstTestId(param);
    }
    function setSelectedTestSlotId(param) {
        // console .log("setSelectedTestSlotId triggered in app.js   " + param)
        setTestSlotId(param);
        setRandNoEvalForm(generateRandomNo);
    }
    useEffect(() => {
        // console .log("ClassId EvaluateTest" + props.clsId)

    }, [])
    return (
        <div>
            <div className="col-sm-8" >
           
                <EvaluateTestTable
                    setSelectedTestId={setSelectedTestId}
                    setSelectedTestSlotId={setSelectedTestSlotId}
                    clsId={props.clsId}
                    enquiryId={props.enquiryId}
                    allowMultiSelect={false}
                    setTestSlotId={setTestSlotId}
                    flgSliderType={props.flgSliderType}
                    loader={loader}>

                    Test-Table
                </EvaluateTestTable>
            </div>
 
            <div className="col-sm-4">
           
                <ResultForm
                    key={randNoEvalForm}
                    enquiryId={props.enquiryId}
                    testSlotId={testSlotId}
                    closeSlider={props.closeSlider}
                    flgSliderType={props.flgSliderType}
                    loader={loader}
                >
                </ResultForm>
            </div>
            </div>
    );
}

export default EvaluateTestSlider;