import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var tokenid = localStorage.getItem("tokenid");
var httpURLStudent = lstModules['students'].url + ":" + lstModules['students'].port;

function getAttendanceSvc(cb) {
    axios({
        url: httpURLStudent + "/sms/student/attendance/yearly",
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        cb(response.data);
    }).catch((error) => {
        cb(error);
    });
}

export { getAttendanceSvc };