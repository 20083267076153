import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import '../css/transport.css'
import { getRouteList, saveRouteInfoSvc } from './vehicleSvc';
const VehicleRouteslider = (props) => {
    const [loader, setLoader] = useState("none")
    const [lstRoute, setLstRoute] = useState([])
    const [selRouteId, setSelRouteId] = useState(0)
    const [lstRouteId, setLstRouteId] = useState([])
    useEffect(() => {
        if (props.vehicleId) {
            getRouteList(props.vehicleId, cbRouteList)
            // console .log(props)
        }
    }, [props])
    function cbRouteList(data) {
        if (data.SvcStatus === 'Success') {
            setLstRoute(data.lstRoute)
            var tempArr = []
            for (var i = 0; i < data.lstRoute.length; i++) {
                if (data.lstRoute[i].selected) {
                    tempArr.push(data.lstRoute[i].routeId)
                }
            }
            setLstRouteId(tempArr)
            // console .log(tempArr)
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function handleSelRoute(param) {
        setSelRouteId(param)
    }
    function handleCBoxChange(route_id, status) {
        // console .log("handleCBoxChange triggered");
        if (status && !lstRouteId.includes(route_id)) {
            var lstTempArr = lstRouteId
            lstTempArr.push(route_id)
            setLstRouteId(lstTempArr)
            // console .log("checked " + lstTempArr)
        }
        else {
            var lstTempArr = lstRouteId
            lstTempArr.splice(lstTempArr.indexOf(route_id), 1)
            setLstRouteId(lstTempArr)
            // console .log("unchecked" + lstTempArr)
        }
    }
    function saveRouteInfo() {
        setLoader("block")
        saveRouteInfoSvc(props.vehicleId, lstRouteId, cbSaveRouteInfo)
    }
    function cbSaveRouteInfo(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.closeSlider()
        } else {
            toast.error(data.SvcMsg)
        }
    }
    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider} style={{ right: "50%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='row' style={{ width: '100%', marginLeft: "15px" }}>
                    <div className="form-group col-md-12">
                        {/* <label className="control-label col-sm-5">Interview Panel<span className="red-color">*</span></label> */}
                        <table className='tableLayout1'>
                            <thead>
                                <tr>
                                    <th>Route No.</th>
                                    <th>Route Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                        </table>
                        <div className='tablebodyWrapper1' style={{ height: '44vh' }}>
                            <table className='tableLayout'><tbody>{
                                lstRoute.map((obj, idx) => {
                                    return <tr
                                        //onClick={(e) => handleSelRoute(obj.routeId)} className={obj.routeId === selRouteId ? "selected" : ""} 
                                        key={idx}>
                                        <td>{obj.routeNo}</td>
                                        <td>{obj.routeDesc}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                defaultChecked={obj.selected}
                                                onClick={(e) => handleCBoxChange(obj.routeId, e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                })
                            }</tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveRouteInfo()}>Save</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default VehicleRouteslider;