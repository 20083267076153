import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import { getClsPtmSummaryListSvc } from './ClsPtmFeedbackTableSvc.js'

const ClsPtmFeedbackTable = props => {
  const [lstClsPtmFeedbackSummary, setLstClsPtmFeedbackSummary] = useState([])
  const [ptmId, setPtmId] = useState(0)
  const [sectionId, setSectionId] = useState();
  const [sectionCount, setSectionCount] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [avgAttendance, setAvgAttendance] = useState(0);
  const [avgFeedback, setAvgFeedback] = useState(0);
  const [avgStdFeedback, setAvgStdFeedback] = useState(0);
  const [avgHygieneFeedback, setAvgHygieneFeedback] = useState(0);
  const [avgActivityFeedback, setAvgActivityFeedback] = useState(0);
  const [avgInfraFeedback, setAvgInfraFeedback] = useState(0);
  const [avgSchoolFeedback, setAvgSchoolFeedback] = useState(0);

  useEffect(() => {
    setPtmId(props.ptmId)
    getClsPtmSummaryListSvc(props.ptmId, cbClsPtmListResponse)
    
  }, [props.ptmId])

  const setSummary = (lstClassSection) => {
    if (lstClsPtmFeedbackSummary) {
      var noOfStd = 0;
      var row = 0;
      var atnd = 0;
      var stdFb = 0;
      var fb = 0;
      var hygFb = 0;
      var actFb = 0;
      var infrFb = 0;
      var schFb = 0;
      lstClassSection.map((feedbackInstance) => {
        row++;
        setSectionCount(sectionCount + 1);
        noOfStd = noOfStd + feedbackInstance.studentCount;
        atnd = atnd + feedbackInstance.attendancePct * feedbackInstance.studentCount;
        // console .log("feedbackInstance.ratingStudi", feedbackInstance.ratingStudies)
        // console .log(feedbackInstance.cls + feedbackInstance.section);
        // console .log("feedbackInstance.studentCount",  feedbackInstance.studentCount);
        // console .log("feedbackPct",feedbackInstance.feedbackPct);
        if (feedbackInstance.feedbackPct) {
          fb = fb + feedbackInstance.studentCount *  feedbackInstance.feedbackPct / 100;
          stdFb += feedbackInstance.ratingStudies * feedbackInstance.studentCount *  feedbackInstance.feedbackPct / 100 ;
          hygFb += feedbackInstance.ratingHygiene * feedbackInstance.studentCount * feedbackInstance.feedbackPct / 100 ;
          actFb += feedbackInstance.ratingActivities * feedbackInstance.studentCount *  feedbackInstance.feedbackPct / 100 ;
          infrFb += feedbackInstance.ratingInfra * feedbackInstance.studentCount * feedbackInstance.feedbackPct / 100 ;
          schFb += feedbackInstance.ratingSchool * feedbackInstance.studentCount *  feedbackInstance.feedbackPct / 100 ;
          // console .log("fb",fb);
        }
      })
      setSectionCount(row);
      // console .log("row", row);
      setTotalStudents(noOfStd);
      // console .log("noOfStd",noOfStd );
      // console .log("fb",fb);
      setAvgAttendance((atnd / noOfStd).toFixed(2));
      // console .log("atnd/noOfStd",atnd/noOfStd );
      setAvgStdFeedback((stdFb / fb).toFixed(1));
      // console .log("stdFb",stdFb );
      // console .log("stdFb/fb",stdFb/fb );
      setAvgActivityFeedback((actFb / fb).toFixed(1));
      // console .log("actFb/fb",actFb/fb );
      setAvgInfraFeedback((infrFb / fb).toFixed(1));
      // console .log("infrFb/fb",infrFb/fb );
      setAvgSchoolFeedback((schFb / fb).toFixed(1));
      // console .log("schFb/fb",schFb/fb );
      setAvgHygieneFeedback((hygFb / fb).toFixed(1));
      // console .log("hygFb/fb",hygFb/fb );
      setAvgFeedback(((stdFb + actFb + infrFb + schFb + hygFb) / (fb * 5)).toFixed(1));
    }
  }

  async function cbClsPtmListResponse(data) {
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      await setLstClsPtmFeedbackSummary(data.lstClsSec)
      await setSummary(data.lstClsSec);
    } else {
      toast.error(data.SvcStatus)
    }
  }
  const colgrp = (
    <colgroup>
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
    </colgroup>
  )


  return (
    <div>
      <form className='form form-horizontal'>
        <div className='col-sm-12'>
          <div className='col-sm-4 text-left no-display'>
            <div className=''>
              <input
                readOnly
                className='form-control-plain text-left'
                style={{
                  border: 'none',
                  background: '#ffffff',
                  fontWeight: 'bold'
                }}
                value={props.ptmId}
                disabled
              ></input>
            </div>
          </div>
        </div>

        <div className='col-sm-12'>
          <label className='col-sm-2 control-label text-left'>Objective:</label>
          <div className='form-group col-sm-3'>
            <input
              type='text'
              readOnly
              className='form-control'
              defaultValue={props.objective}
            />
          </div>
        </div>

        <div className='col-sm-12 '>
          <label className='col-sm-2 control-label text-left'>Timings</label>
          <div className='form-group col-sm-3'>
            <input
              type='text'
              className='form-control'
              defaultValue={props.timings}
              readOnly
            />
          </div>
        </div>
        <div className='col-sm-12 '>

          <div className='col-sm-4 padding-remove'>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                {/* <label className=" col-sm-9 text-left " style={{backgroundColor:"#"}}  >Sections</label> */}
                <label className=" col-sm-12 text-center padding-remove" style={{fontWeight:400}}  >Sections: {sectionCount}</label>
                {/* <div className='col-sm-3 padding-remove '>
                <input
                  type='text'
                  readOnly
                  className=' form-control text-center'
                  style={{border:"none", borderRadius:0}}
                  defaultValue={sectionCount} />
              </div> */}
              </div>
            </div>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center padding-remove  padding-remove " style={{fontWeight:400}}  >Students: {totalStudents}</label>
              </div>
            </div>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-11 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center padding-remove  padding-remove " style={{fontWeight:400}}  >Attendance: {avgAttendance !== "NaN" ? avgAttendance + "%" : "-"}</label>
              </div>
            </div>
          </div>

          <div className='col-sm-4 padding-remove'>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center  padding-remove " style={{fontWeight:400}}    >Average Rating: {avgFeedback !== "NaN" ? avgFeedback : "-"}</label>
              </div>
            </div>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center  padding-remove "   style={{fontWeight:400}}  >Rating studies: {avgStdFeedback !== "NaN" ? avgStdFeedback : "-"}</label>
              </div>
            </div>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center  padding-remove "  style={{fontWeight:400}}   >Rating Hygiene: {avgHygieneFeedback !== "NaN" ? avgHygieneFeedback : "-"}</label>
              </div>
            </div>
          </div>

          <div className='col-sm-4 padding-remove'>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-11 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center padding-remove"  style={{fontWeight:400}}   >Rating Activities: {avgActivityFeedback !== "NaN" ? avgActivityFeedback : "-"}</label>
              </div>
            </div>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center padding-remove " style={{fontWeight:400}}  >Rating Infra: {avgInfraFeedback !== "NaN" ? avgInfraFeedback : "-"}</label>
              </div>
            </div>
            <div className="col-sm-4 padding-remove "  >
              <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                <label className=" col-sm-12 text-center padding-remove" style={{fontWeight:400}}    >Rating School: {avgSchoolFeedback !== "NaN" ? avgSchoolFeedback : "-"}</label>
              </div>
            </div>
          </div>
        </div>


      </form>

      <div className='col-sm-11' style={{ margin: '1% auto' }}>
        <table className='tableLayout1'>
          {colgrp}
          <thead>
            <tr>
              <th className='text-center'>Class</th>
              <th className='text-center'>Section</th>
              <th className='text-center'>Students</th>
              <th className='text-center'>Attendance</th>
              <th className='text-center'>Feedback</th>
              <th className='text-center'>Studies</th>
              <th className='text-center'>Hygiene</th>
              <th className='text-center'>Activities</th>
              <th className='text-center'>Infra</th>
              <th className='text-center'>School</th>
            </tr>
          </thead>
        </table>
        <div
          className='tableBodyWrapper'
          style={{
            height: '500px',
            overflowY: 'scroll',
            border: '1px solid #ccc'
          }}
        >
          <table id='ptmClsFeedbackTable' className='tableLayout'>
            {colgrp}
            <tbody>
              {lstClsPtmFeedbackSummary &&
                lstClsPtmFeedbackSummary.length > 0 &&
                lstClsPtmFeedbackSummary.map((obj, idx) => {
                  return (
                    <tr
                      className={obj.sectionId === sectionId ? 'selected' : ''}
                      key={idx}
                      onClick={() => {
                        setSectionId(obj.sectionId)
                        window.open(
                          '/studentPtmFeedback?' +
                          'ptmId=' +
                          ptmId +
                          '&sectionId=' +
                          obj.sectionId +
                          '&cls=' +
                          obj.cls +
                          '&section=' +
                          obj.section,
                          '_blank'
                        )
                      }}
                    >

                      <td className='no-display'>{obj.sectionId}</td>
                      <td className='text-center'>{obj.cls}</td>
                      <td className='text-center'>{obj.section}</td>
                      <td className='text-center'>{obj.studentCount}</td>
                      <td className='text-center'>{obj.attendancePct + '%'}</td>
                      <td className='text-center'>{obj.feedbackPct + '%'}</td>
                      <td className='text-center'>
                        {obj.ratingStudies == 'NaN' ? '0' : obj.ratingStudies}
                      </td>
                      <td className='text-center'>
                        {obj.ratingHygiene == 'NaN' ? '0' : obj.ratingHygiene}
                      </td>
                      <td className='text-center'>
                        {obj.ratingActivities == 'NaN'
                          ? '0'
                          : obj.ratingActivities}
                      </td>
                      <td className='text-center'>
                        {obj.ratingInfra == 'NaN' ? '0' : obj.ratingInfra}
                      </td>
                      <td className='text-center'>
                        {obj.ratingSchool == 'NaN' ? '0' : obj.ratingSchool}
                      </td>
                    </tr>
                  )
                }
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default ClsPtmFeedbackTable

