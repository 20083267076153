import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { saveAttendanceSvc, informSvc } from "./dailyTptAttendanceTableSvc.js";
import { getAttendanceSvc } from "./dailyTptAttendanceTableSvc.js";

var lstTmpAttendance = [];

const DailyTptAttendanceTable = (props) => {
  const [loader, setLoader] = useState("none");
  const [submitBtnLoader, setSubmitBtnLoader] = useState("none");
  const [lstAttendance, setLstAttendance] = useState([]);
  const [lstSvcAttendance, setLstSvcAttendance] = useState([]);
  const [dtDdMmYyyy, setDtDdMmYyyy] = useState(null);
  const [routeId, setRouteId] = useState(0);
  const [theKey, setTheKey] = useState(0);

  useEffect(() => {
    // console .log(props.dtDdMmYyyy);
    // console .log(props.routeId);
    if (props.dtDdMmYyyy && props.routeId) {
      setDtDdMmYyyy(props.dtDdMmYyyy);
      setRouteId(props.routeId);
      setLoader("block");
      getAttendanceSvc(
        convert(props.dtDdMmYyyy),
        props.routeId,
        props.flgMorEve,
        cbAttendanceResponse
      );
    } else {
      setLstAttendance([]);
    }
  }, [props.dtDdMmYyyy, props.routeId, props.flgMorEve]);
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console .log(lRandNo);
    setTheKey(lRandNo);
  }

  async function cbAttendanceResponse(data) {
    setLoader("none");
    lstTmpAttendance = [];
    setLstSvcAttendance([]);
    if (data.SvcStatus === "Success") {
      await setLstAttendance(data.lstStudent);
      generateRandomNo();
      // console .log(lstAttendance);
      for (var i = 0; i < data.lstStudent.length; i++) {
        lstTmpAttendance.push({
          studentId: data.lstStudent[i].studentId,
          attendance: data.lstStudent[i].attendance.toUpperCase(),
        });
      }
      setLstSvcAttendance(lstTmpAttendance);
    } else {
      toast.error(data.SvcMsg);
    }
  }

  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="15%"></col>
      <col width="15%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );

  function handleAttendanceChange(e, pStudentId, pVal) {
    // console .log(pStudentId + ", val=" + pVal);
    // console .log(lstTmpAttendance);
    pVal = pVal.toUpperCase();
    var idx = existsAt(pStudentId, lstTmpAttendance);
    if (idx > -1) lstTmpAttendance[idx].attendance = pVal;
    else lstTmpAttendance.push({ studentId: pStudentId, attendance: pVal });

    setLstSvcAttendance(lstTmpAttendance);
  }

  function cbMarkAttendance(data) {
    setSubmitBtnLoader("none");
    if (data.status === "Success") {
      toast.success(data.message);
      props.generateRandomNo();
      props.closeSlider();
    } else {
      toast.error(data.message);
    }
  }
  function existsAt(id, arr) {
    var idx = -1;
    // console .log(typeof id);
    for (var i = 0; i < arr.length; i++) {
      // console .log(typeof arr[i].studentId);
      if (arr[i].studentId === id) {
        idx = i;
        break;
      }
    }
    return idx;
  }

  function saveAtt(e) {
    e.preventDefault();
    // console .log("submit called");
    submitAttendance();
  }
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    // console .log([day, mnth, date.getFullYear()].join("-"));
    return [day, mnth, date.getFullYear()].join("-");
  }

  function submitAttendance() {
    setSubmitBtnLoader("block");
    saveAttendanceSvc(
      convert(dtDdMmYyyy),
      routeId,
      props.flgMorEve,
      lstSvcAttendance,
      cbMarkAttendance
    );
  }

  function inform(e, pStudentId) {
    e.preventDefault();
    informSvc(convert(dtDdMmYyyy), pStudentId, cbInformResponse);
  }

  function cbInformResponse(data) {
    if (data.status === "Success") {
      toast.success(data.message);
      getAttendanceSvc(
        convert(props.dtDdMmYyyy),
        props.routeId,
        props.flgMorEve,
        cbAttendanceResponse
      );
    } else {
      toast.error(data.message);
    }
  }

  function alphaOnly(e) {
    var key = e.keyCode;
    // console .log(key);

    if (
      !(
        key === 65 ||
        key === 80 ||
        key === 97 ||
        key === 112 ||
        key === 76 ||
        key === 102 ||
        key === 9
      )
    ) {
      e.preventDefault();
      e.currentTarget.select();
    }
  }

  async function markAllP() {
    var lstUpdAttendance = lstAttendance;
    lstTmpAttendance = [];
    for (var i = 0; i < lstAttendance.length; i++) {
      lstUpdAttendance[i].attendance = "P";
      await lstTmpAttendance.push({
        studentId: lstAttendance[i].studentId,
        attendance: "P",
      });
    }
    setLstSvcAttendance(lstTmpAttendance);
    await setLstAttendance(lstUpdAttendance);
    generateRandomNo();
    // console .log(lstTmpAttendance);
    /*setSubmitBtnLoader('block')
        saveAttendanceSvc(dtDdMmYyyy, routeId, lstTmpAttendance, cbMarkAttendance);*/
  }

  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <div
        style={{
          position: "absolute",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <button
        type="button"
        className="btn btn-info pull-right"
        onClick={() => markAllP()}
      >
        Mark All P
      </button>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Stop No.</th>
            <th>Stop Desc</th>
            <th>Student</th>
            <th>Class-Section</th>
            <th>Gender</th>
            <th>Attendance</th>
            <th>Inform Parent</th>
          </tr>
        </thead>
      </table>
      <div style={{ height: "65vh", overflowY: "scroll" }}>
        <table className="tableLayout">
          {colgrp}
          <tbody key={theKey}>
            {lstAttendance.map((obj, idx) => {
              return (
                <tr
                  key={idx}
                  id={obj.studentId}
                  onClick={(e) => props.setStudentId(obj.studentId)}
                  className={
                    obj.studentId === props.studentId ? "selected" : ""
                  }
                >
                  <td className="no-display">{obj.studentId}</td>
                  <td>{obj.stopNo}</td>
                  <td>{obj.stopDesc}</td>
                  <td className="text-left">{obj.student}</td>
                  <td className="text-left">{obj.clsSection}</td>
                  <td className="text-center">{obj.gender}</td>
                  <td className="text-center">
                    <input
                      onKeyDown={(e) => alphaOnly(e)}
                      maxLength="1"
                      style={{
                        margin: "0 auto",
                        textTransform: "uppercase",
                        width: 25,
                        textAlign: "center",
                      }}
                      type="text"
                      className="form-control"
                      onClick={(e) => e.target.select()}
                      defaultValue={obj.attendance}
                      onChange={(e) =>
                        handleAttendanceChange(e, obj.studentId, e.target.value)
                      }
                    />
                  </td>
                  <td className="text-center">
                    {obj.attendance === "A" && obj.mailInformDttm === "-" ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          inform(e, obj.studentId);
                        }}
                      >
                        Inform
                      </a>
                    ) : (
                      obj.mailInformDttm
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        className="col-sm-12 form-group"
        style={{ pointerEvents: submitBtnLoader === "none" ? "auto" : "none" }}
      >
        <div
          style={{
            right: 0,
            position: "absolute",
            zIndex: 10000,
            display: submitBtnLoader,
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
        <button
          type="button"
          className="btn btn-info pull-right"
          onClick={(e) => saveAtt(e)}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DailyTptAttendanceTable;
