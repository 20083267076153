import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';

import { getExamSubjectSecionsListSvc } from "./examSubjectsSvc";

const ExamSubjectsTable = (props) => {

    const [id, setId] = useState(0);
    const [sectionFiltered, setSectionFiltered] = useState("");
    const [classFiltered, setClassFiltered] = useState("");
    const [lstClsSection, setLstClsSection] = useState([]);
    const [lstClsSectionFiltered, setLstClsSectionFiltered] = useState([]);


    useEffect(() => {
        // console .log("examSubjectsTable called");
        const tempLstClsSection = [
            {
                clsId: 1,
                cls: "Class 10",
                sectionId: "A",
                section: "Section A",
                totalSubjects: 10,
                selectedSubjects: 5
            },
            {
                clsId: 2,
                cls: "Class 12",
                sectionId: "B",
                section: "Section B",
                totalSubjects: 12,
                selectedSubjects: 7
            },
            {
                clsId: 3,
                cls: "Class 9",
                sectionId: "C",
                section: "Section C",
                totalSubjects: 8,
                selectedSubjects: 4
            }
        ];
        setLstClsSection([]);
        setLstClsSectionFiltered([]);
        getClassSectionList(props.examId);
    }, [props.examId, props.tableKey]);

    const getClassSectionList = (examId) => {
        // console .log("getClassSectionList called, examId passed", examId);
        if (examId !== 0)
            return;
        props.setLoader("block");
        props.setOpacity(0.5);
        getExamSubjectSecionsListSvc(examId, clsSectionListResponse);
    }

    const clsSectionListResponse = (data) => {
        // console .log("clsSectionListResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            // console .log("successful response in clsSectionListResponse");
            setLstClsSection(obj.lstClsSection);
            setLstClsSectionFiltered(obj.lstClsSection);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }


    const filterClass = (fClass) => {
        // console .log("filterClass called, fClass", fClass);
        setClassFiltered(fClass);
        filterClassSectionList(fClass, sectionFiltered);
    }

    const filterSection = (fSection) => {
        // console .log("filterSection called, fSection", fSection);
        setSectionFiltered(fSection);
        filterClassSectionList(classFiltered, fSection);
    }

    const filterClassSectionList = (fClass, fSection) => {
        // console .log("filterClassSectionList called fClass", fClass, ", fSection", fSection);
        let tempLstClsSection = [];
        tempLstClsSection = tempLstClsSection.filter((clsSec) => clsSec.cls.toLowerCase().includes(fClass.toLowerCase()));
        tempLstClsSection = lstClsSection.filter((clsSec) => clsSec.section.toLowerCase().includes(fSection.toLowerCase()));
        setLstClsSectionFiltered(tempLstClsSection);
    }

    return (
        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th className="no-display" style={{ width: 50 }}>
                            Section Id
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={classFiltered}
                                onChange={(e) => filterClass(e.target.value)}
                                name="Student Class"
                                placeholder="Class" />
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={sectionFiltered}
                                onChange={(e) => filterSection(e.target.value)}
                                name="Section"
                                placeholder="Section" />
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            Subjects
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            Selected
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv tableBodyBorder" style={{ height: "500px" }}>
                <table className='tableLayout'>
                    <tbody id="examSubjectsTable">
                        {
                            lstClsSectionFiltered && lstClsSectionFiltered ?
                                lstClsSectionFiltered.map((clsSec) => (
                                    <tr key={clsSec.sectionId}
                                        onClick={(e) => setId(clsSec.sectionId)}
                                        className={clsSec.sectionId === id ? "selected" : ""}>
                                        <td style={{ width: 50 }} className='no-display text-center'>{clsSec.sectionId}</td>
                                        <td style={{ width: 50 }} className='text-center'>{clsSec.cls}</td>
                                        <td style={{ width: 50 }} className='text-center'>{clsSec.section}</td>
                                        <td style={{ width: 50 }} className='text-center' >
                                            <label
                                                style={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer'
                                                }}
                                                className='text-primary'
                                                onClick={e => { e.preventDefault(); props.selectSubjects(clsSec); }}
                                            >
                                                {clsSec.totalSubjects}
                                            </label>
                                        </td>
                                        <td style={{ width: 50 }} className='text-center'>{clsSec.selectedSubjects}</td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ExamSubjectsTable;