import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import 'common/js/tabs.css'
import Header from 'common/js/header/header'
import DatePicker from 'react-datepicker'
import { toast, ToastContainer } from 'react-toastify'

import {
  getBlogListSvc,
  getBlogListDtlsSvc,
  saveBlogSvc,
  deleteBlogSvc,
  deleteBlogImgSvc,
  saveBlogImgSvc
} from './blogSvc'
var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
var vTmpLstBlogImgPath = [],
  tmpLstBlogImgPath = []
const BlogCtrl = () => {
  const [state, setState] = useState({
    loader: 'none',
    opacity: 1,
    tokenid: localStorage.getItem('tokenid')
  })
  const [blogId, setBlogId] = useState(0)
  const [blogText, setBlogText] = useState('')
  const [title, setTitle] = useState('')
  const [blogDt, setBlogDt] = useState(null)
  const [author, setAuthor] = useState('')
  const [role, setRole] = useState('')
  const [tag, setTag] = useState('')
  const [lstBlogImgPath, setLstBlogImgPath] = useState([])
  const [lstBlogImgUrl, setLstBlogImgUrl] = useState([])
  const [lstBlog, setLstBlog] = useState([])

  useEffect(() => {
    var dt = new Date()
    setBlogDt(dt)
    getBlogList()
  }, [])

  function getBlogList () {
    getBlogListSvc(cbBlogListResponse)
  }
  function getBlogListDtls (pBlogId) {
    getBlogListDtlsSvc(pBlogId, cbBlogListDtlsResponse)
  }

  const colgrpBlog = (
    <colgroup>
      <col width='13%'></col>
      <col width='35%'></col>
      <col width='20%'></col>
      <col width='15%'></col>
      <col width='17%'></col>
    </colgroup>
  )

  function setBlogDtls (obj) {
    // console .log('obj = ' + JSON.stringify(obj))
    setBlogId(obj.blogId)
    setBlogText(obj.blogText)
    setTitle(obj.title)
    setAuthor(obj.author)
    setRole(obj.role)
    setTag(obj.tag)

    var dt = obj.blogDt
    var dtparts = dt.split('-')
    var vDt = new Date(dtparts[2], dtparts[1] - 1, dtparts[0])
    setBlogDt(vDt)
    vTmpLstBlogImgPath = []
    tmpLstBlogImgPath = []
    for (var i = 0; i < lstBlog.length; i++) {
      if (obj.blogId === lstBlog[i].blogId) {
        vTmpLstBlogImgPath.push({
          blogId: obj.blogId,
          url: lstBlog[i].img1Path
        })
        vTmpLstBlogImgPath.push({
          blogId: obj.blogId,
          url: lstBlog[i].img2Path
        })
        vTmpLstBlogImgPath.push({
          blogId: obj.blogId,
          url: lstBlog[i].img3Path
        })
      }
    }
    // console .log(vTmpLstBlogImgPath)
    for (var j = 0; j < vTmpLstBlogImgPath.length; j++) {
      // console .log(j + '::' + vTmpLstBlogImgPath[j].url)
      if (vTmpLstBlogImgPath[j].url === '-') {
        viewBlogSvc(
          'banner/no-image.png',
          vTmpLstBlogImgPath[j].blogId,
          cbGetBlogImgSvc
        )
      } else {
        viewBlogSvc(
          vTmpLstBlogImgPath[j].url,
          vTmpLstBlogImgPath[j].blogId,
          cbGetBlogImgSvc
        )
      }
    }
    setLstBlogImgPath(vTmpLstBlogImgPath)
  }
  function viewBlogSvc (pBlogPath, pBlogId, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', httpURL + '/sms/cms/img/view?imgPath=' + pBlogPath, true)
    xhr.setRequestHeader('tokenid', state.tokenid)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (e) {
      // console .log(xhr)
      var arrayBufferView = new Uint8Array(this.response)
      var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(blob)
      // console .log(blob)
      callback(imageUrl, pBlogId, xhr.response)
    }
    xhr.send()
  }
  function cbGetBlogImgSvc (url, pBlogId, response) {
    // console .log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      tmpLstBlogImgPath.push({ blogId: pBlogId, url: url })
      setLstBlogImgUrl(tmpLstBlogImgPath)
    }
    hideLoader()
  }
  function convert (str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    // console .log([day, mnth, date.getFullYear()].join('-'))
    return [day, mnth, date.getFullYear()].join('-')
  }
  function saveBlog () {
    var blogObj = {
      blogId: blogId,
      title: title,
      author: author,
      role: role,
      blogText: blogText,
      tag: tag,
      blogDt: convert(blogDt)
    }
    var formData = new FormData()
    formData.append('blogObj', JSON.stringify(blogObj))
    saveBlogSvc(formData, cbSaveBlogResponse)
  }
  function cbSaveBlogResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getBlogListSvc(cbBlogListResponse)
      setBlankBlogDtls()
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function cbBlogListDtlsResponse (pBlogId, data) {
    if (data.status === 'Success') {
      setLstBlog(data.lstBlog)
      var vLstBlog = data.lstBlog
      document.getElementById('blog_' + pBlogId).click()

      /* for (var i = 0; i < vLstBlog.length; i++) {
        var vObj = vLstBlog[i]
        if (vObj.blogId == pBlogId) {
          setBlogDtls(vObj)
          break
        }
      } */
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }

  function cbBlogListResponse (data) {
    if (data.status === 'Success') {
      setLstBlog(data.lstBlog)
      /* var vLstBlog = data.lstBlog
      vTmpLstBlogImgPath = []
      for (var i = 0; i < vLstBlog.length; i++) {
        vTmpLstBlogImgPath.push({
          blogId: vLstBlog[i].blogId,
          url: vLstBlog[i].img1Path
        })
        vTmpLstBlogImgPath.push({
          blogId: vLstBlog[i].blogId,
          url: vLstBlog[i].img2Path
        })
        vTmpLstBlogImgPath.push({
          blogId: vLstBlog[i].blogId,
          url: vLstBlog[i].img3Path
        })
      }
      setLstBlogImgPath(vTmpLstBlogImgPath) */
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function setBlankBlogDtls () {
    var dt = new Date()
    var tmpDd = dt.getDate()
    var dd = tmpDd < 9 ? '0' + tmpDd : tmpDd
    var mon = dt.getMonth() + 1
    var mm = mon < 10 ? '0' + mon : mon
    var ddmmyyyy = dd + '-' + mm + '-' + dt.getFullYear()
    setBlogDtls({
      blogId: 0,
      author: '',
      role: '',
      blogText: '',
      tag: '',
      title: '',
      blogDt: ddmmyyyy
    })
  }
  function deleteBlog (pBlogId) {
    // console .log(pBlogId)
    showLoader()
    deleteBlogSvc(pBlogId, cbDeleteBlogResponse)
  }

  function cbDeleteBlogResponse (data, pBlogId) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getBlogListDtls(pBlogId)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function deleteBlogImg (pBlogId, pos) {
    // console .log(pBlogId)
    showLoader()
    deleteBlogImgSvc(pBlogId, pos, cbDeleteBlogImgResponse)
  }

  function cbDeleteBlogImgResponse (data) {
    hideLoader()
    // console .log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getBlogList()
      setBlankBlogDtls()
    } else {
      toast.error(data.SvcMsg)
    }
  }
  const handlePhotoChange = (e, pBlogId, pIdx) => {
    // console .log(e.target.files[0].name)
    // console .log('src:' + URL.createObjectURL(e.target.files[0]))
    var vLstBlogImgUrl = lstBlogImgUrl
    if (e.target.files && e.target.files[0]) {
      vLstBlogImgUrl[pIdx].url = URL.createObjectURL(e.target.files[0])
      // console .log(vLstBlogImgUrl)
      showLoader()
      setLstBlogImgUrl(vTmpLstBlogImgPath)
      var formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('blogId', blogId)
      formData.append('pos', pIdx + 1)
      saveBlogImgSvc(formData, blogId, cbSaveBlogImgResponse)
    }
  }
  const handlePhotoClick = (e, pos) => {
    document.getElementById('img_blog' + pos).click()
  }

  function cbSaveBlogImgResponse (data, pBlogId) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getBlogListDtls(pBlogId)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader()
  }
  function onErrorBlogImg (pos) {
    // console .log('onError BlogImgImg :: pos=' + pos)
    vTmpLstBlogImgPath[pos].url = window.appURL + '/no-image.png'
    setLstBlogImgUrl(vTmpLstBlogImgPath)
  }

  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0.5 })
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1 })
  }
  return (
    <div style={{ height: '100%' }}>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: 'none'
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div
        className='MainContainer'
        style={{ opacity: state.opacity, marginTop: 15 }}
      >
        <div className='col-sm-12 col-centered'>
          <div className='col-sm-6'>
            <table className='tableLayout1'>
              {colgrpBlog}
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
            <div className='' style={{ height: '300px', overflowY: 'scroll' }}>
              <table className='tableLayout'>
                {colgrpBlog}
                <tbody>
                  {lstBlog &&
                    lstBlog.length > 0 &&
                    lstBlog.map((blogObj, idx) => {
                      return (
                        <tr
                          key={idx}
                          onClick={() => setBlogId(blogObj.blogId)}
                          className={
                            blogObj.blogId === blogId ? 'selected' : ''
                          }
                        >
                          <td className='text-center'>{blogObj.blogDt}</td>
                          <td className='text-left'>{blogObj.title}</td>
                          <td className='text-left'>{blogObj.author}</td>
                          <td className='text-left'>{blogObj.role}</td>
                          <td className='text-center'>
                            <span
                              id={'blog_' + blogObj.blogId}
                              className='glyphicon glyphicon-edit'
                              onClick={e => setBlogDtls(blogObj)}
                            ></span>
                            <span
                              className='glyphicon glyphicon-remove'
                              onClick={() => deleteBlog(blogObj.blogId)}
                            ></span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <span
            className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
            style={{ fontSize: '30px', width: '4%' }}
            onClick={e => {
              setBlankBlogDtls()
            }}
          ></span>
          <div className='col-sm-6' style={{ width: '45%' }}>
            <form
              className='form form-horizontal'
              style={{
                width: '100%',
                margin: '10px auto',
                padding: 10,
                background: '#fff',
                border: '1px solid #ccc',
                borderRadius: 10,
                pointerEvents: state.loader === 'none' ? 'auto' : 'none'
              }}
            >
              <div className='row'>
                <label className='control-label col-md-2 text-left'>
                  Title
                </label>
                <div className='form-group col-md-10'>
                  <input
                    type='text'
                    value={title}
                    className='form-control'
                    onChange={e => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className='row'>
                <label className='control-label col-md-2 text-left'>
                  Author
                </label>
                <div className='col-md-4 padding-remove'>
                  <input
                    type='text'
                    value={author}
                    className='form-control'
                    onChange={e => setAuthor(e.target.value)}
                  />
                </div>
                <label className='control-label col-md-2 text-left'>Role</label>
                <div className='form-group col-md-4 padding-remove'>
                  <input
                    type='text'
                    value={role}
                    className='form-control'
                    onChange={e => setRole(e.target.value)}
                  />
                </div>
              </div>

              <div className='row'>
                <label className='control-label col-md-2 text-left'>Date</label>
                <div className='form-group col-md-3'>
                  <DatePicker
                    id='inp_date'
                    className='form-control text-center'
                    placeholderText='Date'
                    selected={blogDt}
                    onChange={setBlogDt}
                    dateFormat='dd-MM-yyyy'
                    autoComplete='off'
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode='select'
                  />
                </div>
              </div>
              <div className='row'>
                <label className='control-label col-md-2 text-left'>Tags</label>
                <div className='form-group col-md-10'>
                  <input
                    type='text'
                    value={tag}
                    className='form-control col-md-4'
                    onChange={e => setTag(e.target.value)}
                  />
                </div>
              </div>
              <div className='row'>
                <label className='control-label col-md-2 text-left'>
                  Blog Text
                </label>
                <div className='form-group col-md-10'>
                  <textarea
                    rows='6'
                    value={blogText}
                    className='form-control'
                    onChange={e => setBlogText(e.target.value)}
                  />
                </div>

                <div className='form-group col-md-12'>
                  <button
                    type='button'
                    className='btn btn-warning pull-right'
                    onClick={e => saveBlog()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>

            <form>
              {lstBlog && lstBlog.length < 3 && (
                <div
                  className='col-sm-3'
                  style={{ background: '#ddd', width: 160 }}
                >
                  <div className='col-sm-12 padding-remove'>
                    <input
                      docid={'doc_' + 0}
                      type='file'
                      id={'img_blog' + 0}
                      name='img'
                      onChange={e => handlePhotoChange(e, 0, 0)}
                      className='hidden'
                      accept='image/*'
                    />

                    <span
                      className='col-centered col-sm-1 glyphicon glyphicon-plus padding-remove'
                      style={{
                        fontSize: '30px',
                        width: '4%',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        height: 90
                      }}
                      onClick={e => handlePhotoClick(e, 0)}
                    ></span>
                  </div>
                </div>
              )}
              {blogId &&
                lstBlogImgUrl &&
                lstBlogImgUrl.length > 0 &&
                lstBlogImgUrl.map((obj, idx) => {
                  return (
                    blogId === obj.blogId && (
                      <div className='col-sm-3' key={'blog_img_' + (idx + 1)}>
                        <div
                          className='col-sm-12 banner padding-remove'
                          style={{
                            background: '#fff',
                            boxSizing: 'border-box',
                            width: 'auto'
                          }}
                        >
                          <input
                            docid={'doc_' + (idx + 1)}
                            type='file'
                            id={'img_blog' + (idx + 1)}
                            name='img'
                            onChange={e =>
                              handlePhotoChange(e, obj.blogId, idx)
                            }
                            className='hidden'
                            accept='image/*'
                          />
                          <img
                            src={obj.url}
                            alt='img'
                            onClick={e => handlePhotoClick(e, idx + 1)}
                            style={{ width: '100%', border: '1px solid #ddd' }}
                            onError={() => onErrorBlogImg(idx)}
                          />
                          <div
                            className='col-sm-12'
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              width: '100%',
                              height: 20,
                              opacity: '0.7',
                              background: '#eee'
                            }}
                          >
                            <div className='pull-right'>
                              <span
                                key={'del_' + (idx + 1)}
                                id={'del_' + (idx + 1)}
                                className='glyphicon glyphicon-remove'
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  top: 0,
                                  fontSize: 20,
                                  color: '#aaa',
                                  cursor: 'pointer'
                                }}
                                onClick={e => {
                                  deleteBlogImg(obj.blogId, idx + 1)
                                }}
                              ></span>
                              {/* <span
                                className='glyphicon glyphicon-eye-open'
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  top: 0,
                                  fontSize: 20,
                                  color: '#aaa',
                                  cursor: 'pointer'
                                }}
                                onClick={e => viewBlogImg(idx, lstBlogImgPath[idx])}
                              ></span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )
                })}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCtrl
