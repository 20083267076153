import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getLstExamSvc } from "./ExamTableSvc";

const ExamTable = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstExam, setLstExam] = useState([
    /* {
        "examId": 1,
        "exam": "U.T.-5",
        "maxScore": 20,
        "passScore": 10,
        "typeId": 10,
        "type": "Test",
        "weight": 20,
        "reportCardId": 10,
        "reportCard": "Standalone",
        "examOrder": 1
    }, {
        "examId": 2,
        "exam": "UT-1",
        "maxScore": 20,
        "passScore": 10,
        "typeId": 10,
        "type": "Test",
        "weight": 20,
        "reportCardId": 10,
        "reportCard": "Standalone",
        "examOrder": 1
    }, {
        "examId": 3,
        "exam": "U.T.-4",
        "maxScore": 20,
        "passScore": 10,
        "typeId": 10,
        "type": "Test",
        "weight": 20,
        "reportCardId": 10,
        "reportCard": "Standalone",
        "examOrder": 2
    }, {
        "examId": 4,
        "exam": "U.T.-6",
        "maxScore": 20,
        "passScore": 10,
        "typeId": 10,
        "type": "Test",
        "weight": 20,
        "reportCardId": 10,
        "reportCard": "Standalone",
        "examOrder": 3
    }, {
        "examId": 5,
        "exam": "U.T.-5",
        "maxScore": 20,
        "passScore": 10,
        "typeId": 10,
        "type": "Test",
        "weight": 20,
        "reportCardId": 10,
        "reportCard": "Standalone",
        "examOrder": 4
    }, {
        "examId": 8,
        "exam": "Ist Term",
        "maxScore": 80,
        "passScore": 40,
        "typeId": 20,
        "type": "Exam",
        "weight": 80,
        "reportCardId": 20,
        "reportCard": "Combined",
        "examOrder": 9
    }, {
        "examId": 6,
        "exam": "Half Yearly",
        "maxScore": 80,
        "passScore": 40,
        "typeId": 20,
        "type": "Exam",
        "weight": 80,
        "reportCardId": 20,
        "reportCard": "Combined",
        "examOrder": 10
    }, {
        "examId": 7,
        "exam": "Ist Term",
        "maxScore": 80,
        "passScore": 40,
        "typeId": 20,
        "type": "Exam",
        "weight": 80,
        "reportCardId": 20,
        "reportCard": "Combined",
        "examOrder": 6
    }, {
        "examId": 9,
        "exam": "Ist Term",
        "maxScore": 80,
        "passScore": 40,
        "typeId": 20,
        "type": "Exam",
        "weight": 80,
        "reportCardId": 20,
        "reportCard": "Combined",
        "examOrder": 9
    }, {
        "examId": 10,
        "exam": "Ist Term",
        "maxScore": 80,
        "passScore": 40,
        "typeId": 20,
        "type": "Exam",
        "weight": 80,
        "reportCardId": 20,
        "reportCard": "Combined",
        "examOrder": 8
    } */
  ]);
  const [examId, setExamId] = useState(0);
  const [termId, setTermId] = useState(0);

  useEffect(() => {
    if (props.termId) {
      setTermId(props.termId);
      setExamId(props.examId);
      getLstExamSvc(props.termId, props.examId, cbLstExamResponse);
      setLoader("block");
    }
  }, [props.termId, props.examId]);

  function cbLstExamResponse(data, pExamId) {
    setLoader("none");
    // console .log(data);
    if (data.status === "Success") {
      setLstExam(data.lstExam);
      // console .log("pExamId=" + pExamId);
      // if (!pExamId && pExamId !== 0) {
      //   setExamId(data.lstExam[0].examId);
      //   props.clickExam(data.lstExam[0]);
      // }
    } else {
      toast.error(data.message);
    }
  }

  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );

  function editTest(pExamObj) {
    setExamId(pExamObj.examId);
    props.clickExam(pExamObj);
  }
  function addTest() {
    setExamId(0);
    props.clickExam({
      examId: 0,
      exam: "",
      examOrder: "",
      weight: "",
      typeId: 0,
      reportCardId: 0,
      passScore: "",
      maxScore: "",
    });
  }
  return (
    <>
      <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: loader,
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
        <div className="col-sm-11">
          <table className="tableLayout1">
            {colgrp}
            <thead>
              <tr>
                <th>Exam</th>
                <th>Max. Score</th>
                <th>Pass Score</th>
                <th>Type</th>
                <th>Weight</th>
                <th>Report Card</th>
                <th>Order</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
          <div className="tablebodyWrapper">
            <table className="tableLayout">
              {colgrp}
              <tbody>
                {lstExam.map((test, idx) => {
                  return (
                    <tr
                      onClick={(e) => editTest(test)}
                      className={test.examId === examId ? "selected" : ""}
                      key={idx}
                    >
                      <td className="text-left">{test.exam}</td>
                      <td className="text-right">{test.maxScore}</td>
                      <td className="text-right">{test.passScore}</td>
                      <td className="text-left">{test.type}</td>
                      <td className="text-right">{test.weight}</td>
                      <td className="text-left">{test.reportCard}</td>
                      <td className="text-right">{test.examOrder}</td>
                      <td className="text-center">
                        <span
                          className="glyphicon glyphicon-edit"
                          onClick={(e) => editTest(test)}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-1  col-xs-1 pull-right" onClick={addTest}>
        <span className="addIcon glyphicon glyphicon-plus-sign"></span>
      </div>
    </>
  );
};

export default ExamTable;
