import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import Select from 'react-select';
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
//var httpURL = lstModules['payrollSetup'].url + ":" + lstModules['enquiry'].port;
var tokenid = localStorage.getItem('tokenid');
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;


function AdvanceSlider(props){
    const [staff,setStaff]=useState([])
    const [advId,setAdvId]=useState()
    const [selStaffId,setSelStaffId]=useState()
    const [loader, setLoader] = useState("none");
    const [selStaff,setSelStaff]=useState()
    const [lstStaff,setLstStaff]=useState([])
    const [advAmt,setAdvAmt]=useState()
    const [yrMth,setYrMth]=useState()
    const [payback,setPayback]=useState()
    const lstMonth=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec']
    const lstYear=[2022,2023,2024,2025,2026,2027,2028,
        2029,2030,2031,2032,2033,2034,2035,2036,
        2037,2038,2039,2040,2041,2042,2043,2044,
        2045,2046,2047,2048,2049,2050]
    const [year,setYear]=useState()
    const [month,setMonth]=useState()
    const [monthInt,setMonthInt]=useState()
    const [yearp,setYearp]=useState()
    const [monthp,setMonthp]=useState()
    const [monthIntp,setMonthIntp]=useState()
    const [paybackFrom,setPaybackFrom]=useState()
    const [yrMthp,setYrMthp]=useState()
    const [status,setStatus]=useState()
    const [label,setLabel]=useState('Staff')
       
    function handleMonthChange(param){
        var mth=lstMonth.indexOf(param)
        mth=++mth
        setMonth(param)
        var temp=(year*100)+mth
        setYrMth(temp)
        setMonthInt(mth)
    }

    function handleYearChange(param){

        var temp=(parseInt(param)*100)+parseInt(monthInt)

        setYear(param)
        setYrMth(temp)
    }

    function handleMonthpChange(param){
        var mth=lstMonth.indexOf(param)
        mth=++mth
        setMonthp(param)
        var temp=(yearp*100)+mth
        setYrMthp(temp)
        setMonthIntp(mth)        
    }

    function handleYearpChange(param){
        var temp=(parseInt(param)*100)+parseInt(monthIntp)

        setYearp(param)
        setYrMthp(temp)
    }

    function handlePaybackChange(param){
        setPayback(param)
    }

    function handleAdvIdChange(param){
        setAdvId(param)
    }
    function handleAdvAmtChange(param){
        setAdvAmt(param)
    }
    useEffect(()=>{
        setSelStaffId(props.advObj.staffId)
        setAdvId(props.advObj.advanceId)
        setSelStaff(props.advObj.staff)
        getStaffListSvc(cbGetStaffListSvc)
        setAdvAmt(props.advObj.advAmt)
        getMonthYear(props.advObj.advMonth)
        // setYrMth(props.advObj.advMonth)
        setPayback(props.advObj.tenure)
        // setYrMthp(props.advObj.paybackFrom)
        getMonthYearp(props.advObj.paybackFrom)
        setStatus(props.advObj.status)
    },[])

    function getSelectedStaffLabel(param){

 
        for(var i=0;i<param.length;i++){
            if(param[i].staffId===props.advObj.staffId){
                setLabel(param[i].staff)

            }
        }
        
    }
    function getMonthYear(param){

        if (props.add===true){
            
            var d=new Date()
            var mon=d.getMonth()
            var yr=d.getFullYear()
            param=JSON.stringify((yr*100+mon))
            var part = param.slice(0, 4);
            setYear(parseInt(part))
            var part2 =param.slice(4)
            setMonthInt(part2)
            // console .log(part2)
            setMonth(lstMonth[parseInt(part2)])
            var ym=(yr*100+(++mon))
            setYrMth(ym)

        }
        else{
            
            // console .log(param+typeof(param))
            var temp=JSON.stringify(param)
            var part = temp.slice(0, 4);
            setYear(parseInt(part))
            var part2 =temp.slice(4)
            setMonthInt(part2)
            // console .log(part2)
            part2=--part2
            setMonth(lstMonth[parseInt(part2)])
            setYrMth(temp)
        }

    }
    function getMonthYearp(param){
        if (props.add===true){
            var d=new Date()
            var mon=d.getMonth()
            var yr=d.getFullYear()
            param=JSON.stringify((yr*100+mon))
            var part = param.slice(0, 4);
            setYearp(parseInt(part))
            var part2 =param.slice(4)
            setMonthIntp(part2)
            // console .log(part2)
            setMonthp(lstMonth[parseInt(part2)])
            
        }
        else{
            // console .log(param+typeof(param))
            var temp=JSON.stringify(param)
            var part = temp.slice(0, 4);
            setYearp(parseInt(part))
            var part2 =temp.slice(4)
            setMonthIntp(part2)
            // console .log(part2)
            part2=--part2
            setMonthp(lstMonth[parseInt(part2)])
        }
    }
    function getStaffListSvc(callBack){
        axios(
            {
              url: httpURLCommon+"/sms/mst/staff/list",
              method: "POST",
              params: {},
              headers: { 'tokenid': tokenid },
              withCredentials: true
            }
          ).then((Response) => {
        
            var ourData = Response.data
            if (ourData.SvcStatus == "Success") {
              callBack(ourData.lstStaff)
              getSelectedStaffLabel(ourData.lstStaff)
            } else {
              toast.error(ourData.SvcMsg)
            }
          }
          ).catch((error) => {
            console.error(error)
          });
    }
    function cbGetStaffListSvc(data){
        setLstStaff(data)
        for(var i=0;i<data.length;i++){
            var obj={ value:data[i].staffId, label:data[i].staff}
            staff.push(obj)
        }
        }

    function saveAdvance(cbSaveAdvance){

        const advance ={
            advanceId:advId,
            staffId:selStaffId,
            advAmt:advAmt,
            tenure:payback,
            advMonth:JSON.stringify(yrMth),
            paybackFrom:JSON.stringify(yrMthp),
        }
        if(parseInt(yrMth)>parseInt(yrMthp)){
            toast.warn('Payback can not start before advance')
            return;
        }
        if(!selStaffId){
            toast.warn('Please Select a Staff')
            return;
        }
        if(!advAmt){
            toast.warn('Please enter advance amount')
            return;
        }
        if(!payback){
            toast.warn('Please enter Payback Tenure')
            return;
        }
        axios(
            {
              url: httpURL+"/sms/payroll/advance/save",
              method: "POST",
              params: {advance:JSON.stringify(advance)},
              headers: { 'tokenid': tokenid },
              withCredentials: true
            }
          ).then((Response) => {
        
            var ourData = Response.data
                cbSaveAdvance(ourData)
          }
          ).catch((error) => {

          });
    }

    function cbSaveAdvance(data){
        if(data.SvcStatus==='Success'){
            toast.success(data.SvcMsg)
            props.generateRandomNo()
        }
        else{
            toast.error(data.SvcMsg)
        }
    }
    function deleteAdvanceSvc(cbDeleteAdvanceSvc){
        axios(
            {
              url: httpURL+"/sms/payroll/advance/delete",
              method: "POST",
              params: {advanceId:props.advObj.advanceId},
              headers: { 'tokenid': tokenid },
              withCredentials: true
            }
          ).then((Response) => {
        
            var ourData = Response.data
                cbDeleteAdvanceSvc(ourData)
          }
          ).catch((error) => {

          });
    }
    function cbDeleteAdvanceSvc(data)
    {
        if(data.SvcStatus==='Success'){
            toast.success(data.SvcMsg)
            props.generateRandomNo()
        }
        else{
            toast.error(data.SvcMsg)
        }
    }
    function handleStaffChange(e){
        var val=e.value
        setSelStaffId(val)
    }

    return(<form    
        className="form form-horizontal"
        style={{
            pointerEvents: loader === "none" ? "auto" : "none",
            height: "100%",
            width: "100%",
            overflowX: 'hidden',
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: 10,
        }}>
<ToastContainer autoClose={3000} hideProgressBar />
                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Advance Id<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.advObj.advanceId} onChange={(e) => handleAdvIdChange(e.target.value)} readOnly></input>
                    </div>
                </div>            

                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Employee<span className="red-color">*</span></label>
                    <div className="col-sm-6 padding-remove ">
                        <Select
                        key={label}
                        style={{fontSize:'20px'}}
                        id="staffSelect"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={{'value':props.advObj.staffId,'label':label}}
                        isSearchable={true}
                        name="color"
                        onChange={(e)=>handleStaffChange(e)}
                        options={staff}
                         />
                        </div>

                    </div> 
                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Amount<span className="red-color">*</span></label>
                    <div className='col-sm-4 padding-remove'>
                        <input type='text' className="form-control" style={{textAlign:'right'}} defaultValue={props.advObj.advAmt} onChange={(e) => handleAdvAmtChange(e.target.value)} ></input>
                    </div>
                </div>   

                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Advance Month<span className="red-color">*</span></label>
                    <div className="col-sm-3 padding-remove ">
                    <select
                            className="form-control "

                            onChange={(e) => handleYearChange(e.target.value)}
                            value={year}
                        >
                            <option value={""}>Year</option>

                            {lstYear.map((year, idx) => {
                                return (
                                    <option key={idx} value={year}>{year}</option>
                                );
                            })}
                        </select>
                        </div>
                        <div className="col-sm-3 ">
                        <select
                            className="form-control"

                            onChange={(e) => handleMonthChange(e.target.value)}
                            value={month}
                        >
                            <option value={""}>Month</option>

                            {lstMonth.map((month, idx) => {
                                return (
                                    <option key={idx} value={month}>{month}</option>
                                );
                            })}
                        </select>
                        </div>
                    </div> 
                    <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Payback Tenure<span className="red-color">*</span></label>
                    <div className='col-sm-3 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.advObj.tenure} onChange={(e) => handlePaybackChange(e.target.value)} ></input>
                    </div>
                    <div className='col-sm-3 padding-remove'>
                    <span className='col-sm-2 ' style={{paddingTop:'5px',fontSize:'15px'}}>Months</span>
                    </div>
                </div>   
                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Payback From<span className="red-color">*</span></label>
                    <div className="col-sm-3 padding-remove ">
                    <select
                            className="form-control "

                            onChange={(e) => handleYearpChange(e.target.value)}
                            value={yearp}
                        >
                            <option value={""}>Year</option>

                            {lstYear.map((year, idx) => {
                                return (
                                    <option key={idx} value={year}>{year}</option>
                                );
                            })}
                        </select>
                        </div>
                        <div className="col-sm-3">
                        <select
                            className="form-control col-sm-2"

                            onChange={(e) => handleMonthpChange(e.target.value)}
                            value={monthp}
                        >
                            <option value={""}>Month</option>

                            {lstMonth.map((month, idx) => {
                                return (
                                    <option key={idx} value={month}>{month}</option>
                                );
                            })}
                        </select>
                        </div>
                    </div> 
                    <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Status<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.advObj.status} readOnly></input>
                    </div>
                </div> 
                <div className="form-group col-md-12">
                    <div className="col-md-offset-4 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info" onClick={(e) => saveAdvance(cbSaveAdvance)}>Save</button>
                    </div>
                    <div className="col-md-offset-2 col-md-1 padding-remove">
                        <button type="button" className="btn btn-danger" onClick={(e) => deleteAdvanceSvc(cbDeleteAdvanceSvc)}>Delete</button>
                    </div>
                </div>
        </form>)
}
export default AdvanceSlider