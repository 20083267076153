import axios from 'axios';
import lstModules from 'common/json/lstModules';
let httpURLCommon = lstModules['main'].url + ":" + lstModules["main"].port;
var sysopstokenid = localStorage.getItem("sysopstokenid");

function getLstSchoolSvc(cb) {
    var obj = { status: "Success", message:"" };

    axios({
        // url: "http://192.168.1.10:8280/sms/sysops/settlement/school/list",
        url: httpURLCommon + "/sms/sysops/settlement/school/list",
        method: "POST",
        headers: { tokenid: sysopstokenid },
        params: {},
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        obj.lstSchool = response.data.lstSchool;
        // console .log(obj);
        cb(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    })

}

export { getLstSchoolSvc }