import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['fees'].url + ':' + lstModules['fees'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

function getClassListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/cls/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data

      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function reGenForBillNoSvc (billGenerationId, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/cls/regenerate',
    method: 'POST',
    params: { billGenerationId: billGenerationId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function publishForBillNoSvc (billGenerationId, flg, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/cls/publish',
    method: 'POST',
    params: { billGenerationId: billGenerationId, flgAll: flg },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function getLstBillSvc (clsId, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/list',
    method: 'POST',
    params: { clsId: clsId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      // console .log(ourData.SvcMsg)
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function getLstStudentSvc (billGenerationId, clsId, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/student/list',
    method: 'POST',
    params: { billGenerationId: billGenerationId, clsId: clsId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function reGenForBillForStudSvc (billNo, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/student/regenerate',
    method: 'POST',
    params: { billNo: billNo },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function publishBillForStudSvc (billNo, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/student/publish',
    method: 'POST',
    params: { billNo: billNo },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function viewBillForStudSvc (billNo, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/student/view',
    method: 'POST',
    params: { billNo: billNo },
    headers: { tokenid: tokenid },
    withCredentials: true,
    responseType: 'arraybuffer'
  })
    .then(Response => {
      // console .log(Response)
      var ourData = Response
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

function generateBillSvc (clsId, months, callBack) {
  // console .log("generateBillSvc called, clsId", clsId, ", months", months);
  var formData = new FormData()
  formData.append('clsId', clsId)
  formData.append('months', months)
  axios({
    url: httpURL + '/sms/fee/bill/generate',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console .log('error')
    })
}

function generateStudentBillSvc (billGenerationId, studentId, callBack) {
  // console .log("generateBillSvc called, clsId", clsId, ", months", months);
  // var formData = new FormData()
  // formData.append('clsId', clsId)
  // formData.append('months', months)
  axios({
    url: httpURL + '/sms/fee/bill/student/generate_new',
    method: 'POST',
    // data: formData,
    params:{billGenerationId:billGenerationId, studentId:studentId},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console .log('error')
    })
}

function initiateCallSvc (billNo, studentId, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/student/rem_call',
    method: 'POST',
    params: { billNo: billNo, studentId: studentId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}
function initiateNotifSvc (billNo, studentId, callBack) {
  axios({
    url: httpURL + '/sms/fee/bill/student/rem_notify',
    method: 'POST',
    params: { billNo: billNo, studentId: studentId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console .log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console .log('error')
    })
}

export {
  initiateNotifSvc,
  initiateCallSvc,
  getClassListSvc,
  reGenForBillNoSvc,
  publishForBillNoSvc,
  getLstBillSvc,
  getLstStudentSvc,
  reGenForBillForStudSvc,
  publishBillForStudSvc,
  viewBillForStudSvc,
  generateBillSvc,
  generateStudentBillSvc
}
