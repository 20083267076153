import React, { useState, useEffect } from "react";
import "../css/TestConfigCtrl.css";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import SlotConfigTable from "./SlotConfigTable";
import SlotConfigSlider from "./SlotConfigSlider";
import { getSlotListSvc } from "./SlotConfigSvc";

function SlotConfigCtrl() {
  const [loader, setLoader] = useState("none");
  const [slotIdForEdit, setSlotIdForEdit] = useState(0);
  const [sObj, setSObj] = useState({});
  const [flgSlider, setFlgSlider] = useState(false);
  const [lstSlot, setLstSlot] = useState([]);
  const [randNo, setRandNo] = useState(0);

  useEffect(() => {
    setLoader("block");
    getSlotListSvc(cbGetSlotListSvcResponse);
  }, []);

  function cbGetSlotListSvcResponse(data) {
    // console .log("cbGetSlotListSvcResponse called, data", data);
    if (data.SvcStatus === "Success") {
      setLstSlot(data.lstSlot);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console .log(lRandNo);
    setRandNo(lRandNo);
  }
  function openSlider() {
    setFlgSlider(true);
    // setSlotForEdit(0,{})
  }
  function closeSlider(pSlotId, pSlotObj) {
    setFlgSlider(false);
    // setSlotIdForEdit(0);
    // setSObj({})
    setSlotForEdit(pSlotId, pSlotObj);
  }

  function setSlotForEdit(pSlotId, pSlotObj) {
    // console .log("selectedSlotId" + pSlotId);
    setSlotIdForEdit(pSlotId);
    setSObj(pSlotObj);
  }
  function sObjForEdit(slotIdForEdit) {
    var found = false;
    for (var i = 0; i < lstSlot.length; i++) {
      if (lstSlot[i].slotId == slotIdForEdit && !found) {
        setSObj(lstSlot[i]);
        found = true;
        break;
      }
    }
    if (!found) {
      setSObj({});
    }
  }
  const getWingId = (wingId) => {
    // console .log("wingId = " + wingId);
  };
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header wingFromChild={getWingId} />

      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="TestTblWrapper">
        <div className="SlotTable">
          <SlotConfigTable
            key={randNo + "_" + slotIdForEdit}
            slotId={slotIdForEdit}
            // closeSlider={closeSlider}
            openSlider={openSlider}
            setSlotForEdit={setSlotForEdit}
            generateRandomNo={generateRandomNo}
          ></SlotConfigTable>
        </div>
        <div
          className=""
          onClick={(e) => {
            openSlider();
            setSlotForEdit(0, {});
          }}
        >
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
          ></span>
        </div>
      </div>
      <div
        style={{ display: flgSlider ? "block" : "none" }}
        className="SlotSlider"
      >
        <SlotConfigSlider
          key={slotIdForEdit}
          slotIdForEdit={slotIdForEdit}
          flgSlider={flgSlider}
          sObj={sObj}
          tm={sObj.tm}
          dt={sObj.dt}
          clsId={sObj.clsId}
          slotId={sObj.slotId}
          informInvigilator1Dt={sObj.informInvigilator1Dt}
          informInvigilator2Dt={sObj.informInvigilator2Dt}
          invigilator1Id={sObj.invigilator1Id}
          invigilator2Id={sObj.invigilator2Id}
          candidateRemarks={sObj.candidateRemarks}
          invigilatorRemarks={sObj.invigilatorRemarks}
          closeSlider={closeSlider}
          openSlider={openSlider}
        ></SlotConfigSlider>
      </div>
    </div>
  );
}

export default SlotConfigCtrl;
