import React, { useState, useEffect } from "react";
import { getSchoolList, setApproveSchoolFormSvc, approveSchoolSvc, rejectSchoolSvc, approvedModulesSvc } from "./approveSchoolService"
import SAHeader from "sa/header/js/saHeader";
import ApprovedTable from "./approvedTable";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ApproveSchoolForm from "./approveSchoolForm";


const ApproveSchools = () => {
    const [loader, setLoader] = useState('none');
    const [opacity, setOpacity] = useState(1);
    const [lstSchool, setLstSchool] = useState([]);
    const [approvedFormData, setApprovedFormData] = useState({});
    const [schoolId, setSchoolId] = useState(0);
    const [lstModule, setLstModule] = useState([]);


    const [showSlider, setShowSlider] = useState(false);

    useEffect(() => {
        setLoader('block');
        setOpacity(0.5);
        getSchoolList(schoolListResponse);

    }, [])

    const schoolListResponse = (data) => {
        var obj = data;

        // console .log(obj.lstSchool);
        if (obj.status === 'Success') {
            setLstSchool(obj.lstSchool)
        } else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                // console .log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader('none');
        setOpacity(1);

    }

    const setApproveSchoolForm = (schoolId) => {
        setLoader("block");
        setOpacity(0.5);
        setApproveSchoolFormSvc(schoolId, setApproveSchoolFormResponse);
    };

    const setApproveSchoolFormResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setApprovedFormData(obj.approvedFormData);
            setSchoolId(obj.schoolId);
            openSlider();
        } else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                // console .log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    };

    const approveModules = (pSchoolId) => {
        setLoader("block");
        setOpacity(0.5);
        approvedModulesSvc(pSchoolId, approvedModulesResponse);

    }
    const approvedModulesResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstModule(obj.lstModule);

        } else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                // console .log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const openSlider = () => {
        setShowSlider(true);
    }

    const closeSlider = () => {
        setShowSlider(false);
    }

    const callApproveSchool = (note, moduleActive) => {
        setLoader("block");
        setOpacity(0.5);
        approveSchoolSvc(note, schoolId, moduleActive, approveSchoolResponse);
    };

    const approveSchoolResponse = (data) => {

        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            getSchoolList(schoolListResponse);
            closeSlider();
        } else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                // console .log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader("none");
        setOpacity(1);

    };

    const callRejectSchool = (rejectReasonCustomer, rejectReasonInternalNotes) => {
        setLoader("block");
        setOpacity(0.5);

        rejectSchoolSvc(rejectReasonCustomer, rejectReasonInternalNotes, schoolId, rejectSchoolResponse);
    };

    const rejectSchoolResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            getSchoolList(schoolListResponse);
            closeSlider();
        } else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                // console .log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    };


    return (
        <div style={{ height: '100%' }}>
            <SAHeader />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer Timing" >
                <div className="col-xs-10 col-centered clearfix">
                    {lstSchool && lstSchool.length > 0 &&
                        <ApprovedTable 
                        lstSchool={lstSchool}
                        setApproveSchoolForm={setApproveSchoolForm}
                        approveModules={approveModules} 
                        setSchoolId={setSchoolId}
                        />
                    }
                </div>
                {showSlider && <div className="col-xs-8 padding-remove TestSlider">
                    <ApproveSchoolForm 
                    approvedFormData={approvedFormData} 
                    closeSlider={closeSlider} 
                    loader={loader} 
                    callApproveSchool={callApproveSchool}
                    callRejectSchool={callRejectSchool}
                    lstModule={lstModule}
                    key={schoolId}
                    schoolId={schoolId} />
                </div>}
            </div>



        </div>


    )


}

export default ApproveSchools;