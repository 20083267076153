import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

// const httpURL = "http://192.168.1.5:8280" + "/sms/wt/test/student";
const httpURL =  lstModules['test'].url + ':' + lstModules['test'].port + "/sms/wt/test/student";
const tokenid = localStorage.getItem('tokenid');
// const schoolId = 31270;



export const getQuestionSvc = (testId, studentId, pSchoolId, rankers, cbGetQuestion) => {
    // console .log("getQuestionSvc called, url", httpURL + '/question');
    // console .log("schoolId", pSchoolId, ", testId", testId, ", studentId", studentId, ", rankers", rankers);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    formData.append("studentId", studentId);
    const obj = { status: "", message: "", "testId":testId, "studentId":studentId, "schoolId":pSchoolId, "rankers":rankers };
    axios({
        url: httpURL + '/question',
        method: "POST",
        // params: { "testId": testId, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getQuestionSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.qusObj = response.data.qusObj;
        }
        cbGetQuestion(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetQuestion(obj);
    })
}


export const studentListSvc = (testId, studentId, pSchoolId,  qusNo, rankers, cbStudentList) => {
    // console .log("studentListSvc called, url", httpURL + '/question_avatars_answered');
    // console .log("schoolId", pSchoolId, ", testId", testId, ", studentId", studentId, ", qusNo", qusNo, ", rankers", rankers);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    formData.append("studentId", studentId);
    formData.append(" qusNo",  qusNo);
    const obj = { status: "", message: "", "testId":testId, "studentId":studentId, "qusNo":qusNo, "schoolId":pSchoolId, "rankers":rankers };
    axios({
        url: httpURL + '/question_avatars_answered',
        method: "POST",
        // params: { "testId": testId, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in studentListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstStudent = response.data.lstStudent;
            obj.nextAction = response.data.nextAction;       // 10: Stay, 20: next qus, 30:leaderboard
        }
        cbStudentList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbStudentList(obj);
    })
}


export const submitAnswerSvc = (answerObj, pSchoolId, cbSubmitAnswer) => {
    // console .log("submitAnswerSvc called, url", httpURL + '/question_answered');
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("answerObj", JSON.stringify(answerObj));
    formData.append("schoolId", pSchoolId);
    // console .log("formData", formData);
    axios({
        url: httpURL + '/question_answered',
        method: "POST",
        // params: { "answerObj": JSON.stringify(answerObj) },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in submitAnswerSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSubmitAnswer(obj);
    }).catch((error) => {
        // console .log("error in submitAnswerSvc", error);
        obj.status = "Exception";
        obj.message = error;
        cbSubmitAnswer(obj);
    })
}
