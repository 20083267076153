import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['wings'].url + ':' + lstModules['wings'].port;

var tokenid = localStorage.getItem("tokenid");
var screenId = 8;

function getClassList(callback) {

    axios({
        url: httpURL + '/sms/mst/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        callback(response.data);
    }).catch((error) => {
        callback(error);
    });
}

function getWingsList(handleToFunction) {

    axios({
        url: httpURL + '/sms/wings/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        if (data.SvcStatus === 'Success') {
            handleToFunction(data)
        }
    }).catch((error) => {
        handleToFunction(error)
    });
}

function saveWing(wingObj, handleSaveResponse) {
    var lstClass = [];
    var status = 'Success'
    var obj = { lstClass: lstClass, status: status };

    axios({
        url: httpURL + '/sms/wings/save',
        method: "POST",
        params: { 'wings': JSON.stringify(wingObj), screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("finished service call");
        if (response.data.SvcStatus === 'Success') {
            handleSaveResponse(response.data, wingObj)
        }
    }).catch((error) => {
        // console .log("finished service call: but failure");
        obj.status = 'Exception';
        obj.SvcMsg = error;
        return obj;
    });
    // console .log("may be the service call is not finished");
}

export { getClassList, getWingsList, saveWing }