import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['recruitment'].url + ":" + lstModules['recruitment'].port;



function recruitmentTchLstSvc(cbTchLstResponse) {
    axios(
        {   url:                httpURL + "/sms/recruitment/tch/list",
            method:             "POST",
            withCredentials:    true,
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbTchLstResponse(response.data.lstTch)
    })
    .catch((error)=>{
        // console .log( error )
    });

    
}



export { recruitmentTchLstSvc }