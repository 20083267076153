import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");


function getDashboardListSvc(pExamId, callback) {
    var obj = { status: "Success", lstClsSection: [] }
    axios({
        url: httpURL + '/sms/performance/dashboard/list',
        method: "POST",
        params: { examId: pExamId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;

        if (response.data.SvcStatus === "Success") {
            obj.lstClsSection = response.data.lstClsSection;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstClsSection = [];
        callback(obj);
    });
}
export { getDashboardListSvc }