import React from 'react';
function TableBodyDtsheet({ bodyCls, tableId, tbodyId, colswidth, lstDateSheet}) {  
    // console .log("lstDateSheet = "+JSON.stringify(lstDateSheet));
    
    return (
          <div >
                <table id={tableId} className="tableLayout">
                <colgroup>
                {colswidth.map((width,idx)=>{
                    return  <col key={idx} width={width} ></col>
                })}
                </colgroup>
                <tbody id={tbodyId}> 
                {lstDateSheet.map((item,index)=>{   
                    
                    return <tr key={index}>
                        <td>{item.examDt}</td>
                        <td>{item.startTm}</td>
                        <td id={item.subId}>{item.sub}</td>
                        <td id={item.tchId}>{item.tch}</td>
                    </tr>
                })}
                </tbody>
                </table>
          </div>
    );
}
export default TableBodyDtsheet;