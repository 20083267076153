import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';

// project related common imports

import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import ExamDateHeader from './examDateHeader';
import ExamScheduleTable from './ExamScheduleTable';
import { populateSubSvc, populateTchSvc } from './ExamHeaderSvc';


const ExamScheduleCtrl = () => {
    const [examId, setExamId] = useState(0)
    const [cellId, setCellId] = useState(0)
    const [selSectionId, setSelSectionId] = useState(0)
    const [examDt, setExamDt] = useState('')
    const [randNo, setRandNo] = useState(0);
    const [holiday, setHoliday] = useState(false);


    useEffect(() => {

    })

    function populateSub() {
        populateSubSvc(examId,holiday, cb)
    }
    function populateTch() {
        populateTchSvc(examId, cb)
    }
    function changeHoliday(e) {
        setHoliday(e.target.checked)
    }
    function cb(data) {
        if (data.status === 'Success') {
            toast.success(data.message);
            generateRandomNo()
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(data.message);
                return false;
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console .log(lRandNo)
        setRandNo(lRandNo)
    }
    function refreshExamSchedule(pExamId) {
        setExamId(pExamId)
        generateRandomNo()
    }
    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" style={{ marginTop: '15px', opacity: '1' }}>
                <div className="col-sm-12 col-centered ">
                    <div className="col-sm-7 padding-remove">
                        <ExamDateHeader examClick={refreshExamSchedule} examId={examId} />
                    </div>
                    <div className="col-sm-5">
                        <div className="col-sm-4 padding-remove">
                            <span className="control-label col-sm-9 padding-remove" style={{ top: 5 }}>Atleast One holiday</span>
                            <div className="col-sm-1">
                                <input type="checkbox" defaultChecked={holiday} onChange={(e) => changeHoliday(e)}></input>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <button type="button" className="btn btn-info" onClick={() => populateSub()}>Populate Subjects</button>
                            <button type="button" className="btn btn-info pull-right" onClick={() => populateTch()}>Populate Teachers</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <ExamScheduleTable key={randNo} examId={examId} setCellId={setCellId} setSelSectionId={setSelSectionId} />
                </div>
            </div>
        </div>

    )
}
export default ExamScheduleCtrl