import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL =
  lstModules['performance'].url + ':' + lstModules['performance'].port

var tokenid = localStorage.getItem('tokenid')

function getLstExamSvc (callback) {
  axios({
    url: httpURL + '/sms/performance/mst/exam/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      // console .log('data.SvcStatus=' + response.data.SvcStatus)
      callback(response.data)
    })
    .catch(error => {
      callback(error)
    })
}

function populateSubSvc (pExamId, holiday, callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/performance/exam/schedule/populate_subjects',
    method: 'POST',
    params: { examId: pExamId, atLeastOneHoliday: holiday },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      obj.status = response.data.SvcStatus
      obj.message = response.data.SvcMsg
      callback(obj)
    })
    .catch(error => {
      console.error(error)
    })
}

function populateTchSvc (pExamId, callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/performance/exam/schedule/populate_clsteacher',
    method: 'POST',
    params: { examId: pExamId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      obj.status = response.data.SvcStatus
      obj.message = response.data.SvcMsg
      callback(obj)
    })
    .catch(error => {
      console.error(error)
    })
}
export { getLstExamSvc, populateSubSvc, populateTchSvc }
