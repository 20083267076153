import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header.js'
import { getPayrollView, getStaffSalarySlip, getSalaryRegisterExcel, getBankTransferExcel, getStaffTypeList, getSalaryRegisterSlip, getBankTransferSlip } from './PayrollMonthProcessSvc';
// var yrMonth = localStorage.getItem('yrMonth')
const params = new URLSearchParams(window.location.search);
let yrMonth = params.get("yrMonth");
export default class PayrollMonthProcess extends Component {
    constructor() {
        super()
        this.state = {
            lstStaff: [],
            lstLabelAllowance: [],
            lstLabelDeduction: [],
            lstAllowance: [],
            lstDeduction: [],
            selStaffId: 0,
            selRowStaffId: 0,
            loader: "none",
            flgSlider: false,
            month: "",
            monthDays: 0,
            school: "",
            plstStdAllow: [],
            chequeNo: "",
            lstStaffType: [],
            lstStaffTypeId: [],
            flgStaffType: false,
            flgSlry: false,
            flgBankTrnsfer: false
        }
        getPayrollView(yrMonth, this.cbPayrollView)
        getStaffTypeList(this.cbStaffTypeLst)

    }
    cbStaffTypeLst = (data) => {
        if (data.SvcStatus === 'Success') {
            this.setState({ lstStaffType: data.lstStaffType })
            // console .log("cbStaffType")
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    cbPayrollView = (data) => {
        if (data.SvcStatus === "Success") {
            // console .log("cbPayroll")
            this.setState({ month: data.salaryRegister.month, monthDays: data.salaryRegister.monthDays, school: data.salaryRegister.school, lstStaff: data.salaryRegister.lstStaff, lstAllowance: data.lstAllAllowances, lstDeduction: data.lstAllDeductions })
            var temp = []
            for (var i = 0; i < data.lstAllAllowances.length; i++) {
                if (data.lstAllAllowances[i].stdAllowance) {
                    temp.push(data.lstAllAllowances[i])
                }
            }
            // console .log(temp)
            this.setState({ plstStdAllow: temp })
        } else {
            toast.error(data.SvcMsg)
        }
    }
    cbSalarySlip = (data) => {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
    }
    openStaffSalarySlip = (pstaffId) => {
        this.setState({ selStaffId: pstaffId })
        getStaffSalarySlip(yrMonth, pstaffId, this.cbSalarySlip)
        this.openSlider()
    }
    salaryRegisterExcel = () => {
        getSalaryRegisterExcel(yrMonth, this.cbSalaryRegisterExcel)
    }
    cbSalaryRegisterExcel = (data) => {
        var url = document.createElement("a");
        url.href = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/octet-stream" })
        );
        url.download = "Salary Register.xlsx";
        document.body.appendChild(url);
        url.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(url);
        }, 200);
    }
    BankTransferExcel = () => {
        if (this.state.lstStaffTypeId.length === this.state.lstStaffType.length) {
            // console .log("here1")
            getBankTransferExcel(yrMonth, this.state.chequeNo, [], this.cbBankTransferExcel)
        }
        else {
            // console .log("here2")
            getBankTransferExcel(yrMonth, this.state.chequeNo, this.state.lstStaffTypeId, this.cbBankTransferExcel)
        }
    }
    cbBankTransferExcel = (data) => {
        var url = document.createElement("a");
        url.href = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/octet-stream" })
        );
        url.download = "Bank Transfer.xlsx";
        document.body.appendChild(url);
        url.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(url);
        }, 200);
    }
    openSlider = () => {
        this.setState({ flgSlider: true })
    }
    openSlrySlider = () => {
        this.setState({ flgSlry: true })
    }
    openBankTransferSlider = () => {
        this.setState({ flgBankTrnsfer: true })
    }
    closeSlider = () => {
        this.setState({ selStaffId: 0, flgSlider: false, flgStaffType: false, flgSlry: false, flgBankTrnsfer: false })
    }
    handleChequeNoChange = (param) => {
        this.setState({ chequeNo: param })
    }
    handleCBoxChange = (type_id, status) => {
        // console .log("handleCBoxChange triggered");
        var lstTempArr = this.state.lstStaffTypeId
        if (status && !this.state.lstStaffTypeId.includes(type_id)) {
            lstTempArr.push(type_id)
            // console .log("checked " + lstTempArr)
        }
        else {
            lstTempArr.splice(lstTempArr.indexOf(type_id), 1)
            // console .log("unchecked" + lstTempArr)
        }
        // console .log(this.state.lstStaffTypeId)
    }
    openStaffTypeDrpdown = () => {
        this.setState({ flgStaffType: !this.state.flgStaffType })
    }
    handleSelStaffId = (param) => {
        this.setState({ selRowStaffId: param })
    }
    opensalaryRegisterslip = (e) => {
        getSalaryRegisterSlip(yrMonth, this.cbSalaryRegister)
        this.openSlrySlider()
    }
    cbSalaryRegister = (data) => {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('registerview');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
    }
    openBankTransferSlip = () => {
        if (this.state.lstStaffTypeId.length === this.state.lstStaffType.length) {
            // console .log("here1")
            getBankTransferSlip(yrMonth, this.state.chequeNo, [], this.cbBankTransfer)
            this.openBankTransferSlider()
        }
        else {
            // console .log("here2")
            getBankTransferSlip(yrMonth, this.state.chequeNo, this.state.lstStaffTypeId, this.cbBankTransfer)
            this.openBankTransferSlider()
        }
    }
    cbBankTransfer = (data) => {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('bankview');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
    }
    getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    render() {
        return (
            <>
                <div className='MainContainer'
                    style={{ pointerEvents: this.state.loader === "none" ? "auto" : "none" }}>
                    <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                    </div>
                    <Header wingFromChild={this.getWingId} />
                    <ToastContainer autoClose={3000} hideProgressBar />
                    <form className='form form-horizontal col-sm-6' style={{ height: "20vh", width: '25%', overflowX: 'hidden', margin: '15px', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                        <div className="col-sm-12" style={{ pointerEvents: this.state.loader === "none" ? "auto" : "none" }}>
                            <ToastContainer autoClose={3000} hideProgressBar />
                            <div className="form-group col-md-12">
                                <h5>Salary Ledger ({this.state.school})</h5>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="control-label col-md-5" style={{ paddingRight: "0px" }}>Month</label>
                                <div className='col-sm-6' style={{ paddingTop: "3px", textAlign: "left" }}>
                                    <label>{this.state.month}</label>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="control-label col-md-5" style={{ paddingRight: "0px" }}>Month Days</label>
                                <div className='col-sm-6' style={{ paddingTop: "3px", textAlign: "left" }}>
                                    <label>{this.state.monthDays}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='col-sm-6' style={{ float: "right", paddingTop: "70px", paddingRight: "0px" }}>
                        <div className="col-sm-12" style={{ pointerEvents: this.state.loader === "none" ? "auto" : "none" }}>
                            <ToastContainer autoClose={3000} hideProgressBar />
                            <div className="form-group col-md-12" style={{ float: "right", paddingLeft: "358px" }}>
                                <label className="control-label col-sm-6">Salary Register:</label>
                                <div className='col-sm-3 padding-remove'>
                                    <button
                                        onClick={(e) => this.opensalaryRegisterslip()}
                                    >PDF</button>
                                </div>
                                <div className='col-sm-3 padding-remove'>
                                    <button
                                        onClick={(e) => this.salaryRegisterExcel()}
                                    >XLS</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12" style={{ pointerEvents: this.state.loader === "none" ? "auto" : "none" }}>
                            <ToastContainer autoClose={3000} hideProgressBar />
                            <div className="form-group col-md-12">
                                <div className='col-sm-6 padding-remove'>
                                    <label className="control-label col-sm-6">Cheque No.</label>
                                    <div className='col-sm-6 padding-remove'>
                                        <input type="text" className="form-control"
                                            defaultValue={this.state.chequeNo} onChange={(e) => this.handleChequeNoChange(e.target.value)}
                                        ></input>
                                    </div>
                                </div>
                                <div className='col-sm-6 padding-remove'>
                                    <label className="control-label col-sm-6">Bank Tfr:</label>
                                    <div className='col-sm-3 padding-remove'>
                                        <button
                                            onClick={(e) => this.openBankTransferSlip()}
                                        >PDF</button>
                                    </div>
                                    <div className='col-sm-3 padding-remove'>
                                        <button
                                            onClick={(e) => this.BankTransferExcel()}
                                        >XLS</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='TestTblWrapper' style={{ width: "98%", height: "auto" }}>
                        <div className='TestTbl' style={{ width: "100%", height: "47vh", overflowX: "scroll" }}>
                            <table className='tableLayout3' style={{ width: "170%", tableLayout: "fixed" }}>
                                <thead>
                                    <tr>
                                        <th rowSpan={2} id="tableTh">Emp. Code</th>
                                        <th rowSpan={2} id="tableTh">Name</th>
                                        <th rowSpan={2} id="tableTh">Grade</th>
                                        <th rowSpan={2} id="tableTh">Staff Type
                                            <><button className={!this.state.flgStaffType ? 'glyphicon glyphicon-triangle-bottom' : 'glyphicon glyphicon-triangle-top'} onClick={(e) => this.openStaffTypeDrpdown()} style={{ backgroundColor: "rgb(74, 134, 232)" }}
                                            ></button></>
                                        </th>
                                        <th colSpan={2} id="tableTh">Leaves</th>
                                        <th colSpan={this.state.plstStdAllow.length} id="tableTh">Base Salary</th>
                                        <th colSpan={this.state.lstAllowance.length} id="tableTh">Paid</th>
                                        <th rowSpan={2} id="tableTh">Gross</th>
                                        <th colSpan={this.state.lstDeduction.length} id="tableTh">Deductions</th>
                                        <th rowSpan={2} id="tableTh">Total Deduction</th>
                                        <th rowSpan={2} id="tableTh">Net Pay</th>
                                    </tr>
                                    <tr>
                                        <th id="tableTh">Paid</th>
                                        <th id="tableTh">LWP</th>
                                        {this.state.lstAllowance.slice(0, this.state.plstStdAllow.length).map((obj, idx) => {
                                            return (<th key={idx} id="tableTh">
                                                {obj.allowance}</th>)
                                        })}
                                        {this.state.lstAllowance.map((obj, idx) => {
                                            return (<th key={idx} id="tableTh">
                                                {obj.allowance}</th>)
                                        })}
                                        {this.state.lstDeduction.map((obj1, idx1) => {
                                            return (<th key={idx1} id="tableTh">
                                                {obj1.deduction}</th>)
                                        })}
                                    </tr>
                                </thead>
                                <tbody>{
                                    this.state.lstStaff.map((staff, idx) => {
                                        return <tr onClick={(e) => this.handleSelStaffId(staff.staffId)} className={staff.staffId === this.state.selRowStaffId ? "selected" : ""}
                                            key={idx} >
                                            <td >{staff.empCode}</td>
                                            <td onClick={(e) => this.openStaffSalarySlip(staff.staffId)} style={{ color: staff.staffId === this.state.selStaffId ? "blue" : "black" }}>{staff.staff}</td>
                                            <td >{staff.grade}</td>
                                            <td>{staff.staffType}</td>
                                            <td >{staff.pl}</td>
                                            <td >{staff.lwp}</td>
                                            {staff.lstAllowance.slice(0, this.state.plstStdAllow.length).map((obj1, idx1) => {
                                                return (
                                                    <td key={idx1}>{obj1.amount}</td>

                                                )
                                            })}
                                            {staff.lstAllowance.map((obj2, idx2) => {
                                                return (
                                                    <td key={idx2}>{obj2.calcAmount}</td>
                                                )
                                            })}
                                            <td>{staff.gross}</td>
                                            {staff.lstDeduction.map((obj3, idx3) => {
                                                return (
                                                    <td key={idx3}>{obj3.amount}</td>
                                                )
                                            })}
                                            <td >{staff.deductions}</td>
                                            <td >{staff.netPayable}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-3 col-centered staffType" style={{ display: this.state.flgStaffType ? 'block' : 'none' }}>
                        <div style={{ height: "fit-content" }}>
                            <div className='tablebodyWrapper1' style={{ height: '22vh', overflowY: "hidden" }}>
                                <table className='tableLayout' style={{ width: "90%" }}>
                                    <tbody>{
                                        this.state.lstStaffType.map((staff, idx) => {
                                            return <tr key={idx}>
                                                <td>{staff.type}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onClick={(e) => this.handleCBoxChange(staff.typeId, e.target.checked)}
                                                    />
                                                </td>
                                            </tr>
                                        })
                                    }</tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="TestSlider" style={{ display: this.state.flgSlider ? 'block' : 'none' }}>
                    <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                        onClick={(e) => this.closeSlider()}>
                        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                    </button>
                    <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                        <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="viewer" style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
                <div className="TestSlider" style={{ display: this.state.flgSlry ? 'block' : 'none' }}>
                    <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                        onClick={(e) => this.closeSlider()}>
                        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                    </button>
                    <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                        <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="registerview" style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
                <div className="TestSlider" style={{ display: this.state.flgBankTrnsfer ? 'block' : 'none' }}>
                    <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                        onClick={(e) => this.closeSlider()}>
                        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                    </button>
                    <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                        <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="bankview" style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
            </>
        )
    }
}
