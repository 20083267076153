import React, { useState, useEffect } from 'react'
import { getHeadListSvc } from './AdmissionSvc'
import { toast, ToastContainer } from 'react-toastify'

const OneHeadListPopup = props => {
  const [headId, setOneTimeHeadId] = useState(0)
  const [lstOneTimeHead, setLstOneTimeHead] = useState([])
  const [admissionId, setAdmissionId] = useState(0)

  useEffect(() => {
    getHeadListSvc(cbOneTimeHeadListResponse)
    setOneTimeHeadId(props.headId)
  }, [props.headId])

  function cbOneTimeHeadListResponse (data) {
    if (data.SvcStatus === 'Success') {
      setLstOneTimeHead(data.lstHead)
    } else if (data.SvcStatus === 'Failure') {
      if (data.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.SvcMsg)
      }
    } else {
      toast.error('Service failed: ' + data.error)
    }
  }

  function clickOneTimeHead (pOneTimeHeadId, pOneTimeHead, pAmt) {
    // console .log(pOneTimeHeadId)
    setOneTimeHeadId(pOneTimeHeadId)
    props.setOneTimeHeadId(pOneTimeHeadId)
    props.setOneTimeHead(pOneTimeHead)
    props.setAmount(pAmt)
    closeClick()
  }
  function closeClick () {
    // console .log('lstOneTimeHead::' + JSON.stringify(lstOneTimeHead))
    props.closePopup()
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-info pull-right'
        onClick={() => closeClick()}
      >
        <span
          id='close'
          title='Close'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
      <div>
        <h6 className='text-center'>Registration Fee</h6>
        <table className='tableLayout1'>
          <thead>
            <tr>
              <th className='no-display' style={{ width: 40 }}>
                {' '}
                Id
              </th>
              <th style={{ width: 40 }}> Fee </th>
              <th style={{ width: 40 }}> Amount </th>
            </tr>
          </thead>
        </table>
        <div className='tableDivSmall'>
          <table id='' className='tableLayout'>
            <tbody id=''>
              {lstOneTimeHead.map((feeDtls, i) => (
                <tr
                  className={feeDtls.headId == headId ? 'selected' : ''}
                  id={feeDtls.headId}
                  key={feeDtls.headId}
                  onClick={e =>
                    clickOneTimeHead(
                      feeDtls.headId,
                      feeDtls.head,
                      feeDtls.amount
                    )
                  }
                >
                  <td width='40' className='no-display'>
                    {feeDtls.headId}
                  </td>
                  <td key={feeDtls.head} width='40' className='text-left'>
                    {feeDtls.head}
                  </td>
                  <td key={feeDtls.amount} width='40' className='text-right'>
                    {feeDtls.amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default OneHeadListPopup
