import axios from 'axios'
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['wings'].url + ':' + lstModules['wings'].port;

var tokenid = localStorage.getItem("tokenid");

function getLstSubjectSvc(cb) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/mst/sub/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        if (data.SvcStatus === 'Success') {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
            obj.lstSubject = data.lstSubject;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

}
function deleteSubjectSvc(subId, callBack) {
    // console .log(subId)
    axios(
        {
            url: httpURL + "/sms/mst/sub/delete",
            method: 'POST',
            params: { subId: subId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
export { getLstSubjectSvc, deleteSubjectSvc };