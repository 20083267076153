import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL =  lstModules['communication'].url + ':' + lstModules['communication'].port + '/sms/communication/group';
const tokenid = localStorage.getItem('tokenid');


export const communicationGroupListSvc = (cbLstCommunicationGroup) => {
    // console .log("CommunicationGroupListSvc called, url", httpURL + '/list');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in communicationGroupListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstCommGroup = response.data.lstCommGroup;
        }
        cbLstCommunicationGroup(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstCommunicationGroup(obj);
    })
}


export const communicationGroupStudentListSvc = (commGroupId, cbLstCommunicationGroupStudents) => {
    // console .log("CommunicationGroupStudentListSvc called, url", httpURL + '/student/list');
    // console .log("parameters passed, commGroupId", commGroupId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/student/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { "commGroupId": commGroupId },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in communicationGroupStudentListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstCommGroupStudent = response.data.lstCommGroupStudent;
        }
        cbLstCommunicationGroupStudents(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstCommunicationGroupStudents(obj);
    })
}


export const communicationGroupStudentRemoveSvc = (commGroupId, cbCommunicationGroupStudentsRemove) => {
    // console .log("CommunicationGroupStudentRemoveSvc called, url", httpURL + '/student/remove_all');
    // console .log("parameters passed, commGroupId", commGroupId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/student/remove_all',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { "commGroupId": commGroupId },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in communicationGroupStudentListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // console .log("response status success in communicationGroupStudentRemoveSvc");
        }
        cbCommunicationGroupStudentsRemove(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbCommunicationGroupStudentsRemove(obj);
    })
}


export const saveCommunicationGroupSvc = ( commGroupId, commGroupName, cbSaveCommunicationGroup) => {
    // console .log("CommunicationGroupListSvc called, url", httpURL + '/save ');
    // console .log("parameters passed, commGroupId", commGroupId, ", commGroupName", commGroupName);
    const obj = { status: "", message: "", commGroupId:commGroupId };
    axios({
        url: httpURL + '/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params: { "commGroupId": commGroupId, "commGroupName":  JSON.stringify(commGroupName), },
        params: { "commGroupId": commGroupId, "commGroupName":  commGroupName },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in communicationGroupListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.commGroupId = response.data.commGroupId;
        }
        cbSaveCommunicationGroup(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveCommunicationGroup(obj);
    })
}


export const saveCommunicationGroupStudentsSvc = ( commGroupId, lstStudent, cbSaveCommunicationGroupStudents) => {
    // console .log("CommunicationGroupListSvc called, url", httpURL + '/student/save ');
    // console .log("parameters passed, commGroupId", commGroupId, ", lstStudent", lstStudent);
    const formData = new FormData;
    formData.append("commGroupId", commGroupId);
    formData.append("lstStudent",  JSON.stringify(lstStudent));
    const obj = { status: "", message: "", commGroupId:commGroupId };
    axios({
        url: httpURL + '/student/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params: { "commGroupId": commGroupId, "lstStudent":  JSON.stringify(lstStudent), },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console .log("response in communicationGroupListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // console .log("response status success in saveCommunicationGroupStudentSvc");
        }
        cbSaveCommunicationGroupStudents(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveCommunicationGroupStudents(obj);
    })
}
