import React, { useState, useEffect } from "react";
import "../css/UploadError.css";

export default function UploadErrorSlider(props) {
    const [errorCount, setErrorCount] = useState(0)
    const [lstError, setLstError] = useState([]);
    const [heading, setHeading] = useState('')
    /*[{
            sheet: "Syllabus",rowNo: "1",columnNo: "A",field: "Syllabus Name",error: "Can't be more than 20 chars",solution: "Reduce the lenght to fit in 20 characters",
        },
        {sheet: "Syllabus",rowNo: "2",columnNo: "A",field: "Syllabus Name",error: "Can't be empty",solution: "Make sure to enter the name of syllabus",
        },
        {
            sheet: "Syllabus",rowNo: "3",columnNo: "A",field: "Syllabus Name",error: "Can't be empty",solution: "Make sure to enter the name of syllabus",
        }
    ]*/
    useEffect(() => {
        // console .log(props.lstErrors.length)
        setLstError(props.lstErrors)
        setErrorCount(props.countError)
        setHeading(props.heading)
    }, [props.lstErrors, props.countError, props.heading])

    function closeSlider() {
        props.removeClassFromBody()
    }
    const colgrp = <colgroup>
        <col width="15%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="12%"></col>
        <col width="20%"></col>
        <col width="23%"></col>
    </colgroup>
    return (
        <div className="col-sm-11 col-centered">

            <div className="">
                <div className="pull-left" style={{ fontWeight: 'bold' }}>{heading} Upload Errors</div>
                <div className="errorNo">
                    Errors: {errorCount}
                </div>
            </div>
            <div className="">
                <table className="tableLayout1">
                    {colgrp}
                    <thead>
                        <tr>
                            <th>Sheet</th>
                            <th>Row No.</th>
                            <th>Column No.</th>
                            <th>Field</th>
                            <th>Error</th>
                            <th>Solution</th>

                        </tr>
                    </thead>
                </table>
                <div className="" style={{ height: '83vh', overflowY: 'scroll' }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody>
                            {lstError.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="text-center">{value.sheetName}</td>
                                        <td className="text-center">{value.rowNo}</td>
                                        <td className="text-center">{value.colNo}</td>
                                        <td className="text-center">{value.fieldName}</td>
                                        <td className="text-left">{value.message}</td>
                                        <td>{value.solution}</td>
                                    </tr>
                                );
                            })}

                        </tbody>

                    </table>
                </div>
            </div>
            <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={(e) => closeSlider()}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
        </div>
    );
}