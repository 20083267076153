import React, { useState, useEffect } from "react";
import 'common/css/master.css'
import '../css/transport.css'
import { toast, ToastContainer } from 'react-toastify';


const RouteTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [lstRoute, setLstRoute] = useState(props.lstRoute)
    const [routeId, setRouteId] = useState(0)
    useEffect(() => {
        setLstRoute(props.lstRoute)
    }, [props])
    const goToStopScreen = async (pRouteId, pRouteNo) => {
        /* await localStorage.setItem("routeId", pRouteId)
        await localStorage.setItem("routeNo", pRouteNo) */
        window.open(`/stop?routeId=${pRouteId}&routeNo=${pRouteNo}`, '_blank')
    }
    function setRowRouteId(param) {
        setRouteId(param)
    }
    function openPopup(pRouteId){
        // console .log(pRouteId)
        document.querySelector("body").classList.add("showPopup");
        props.setRouteId(pRouteId)
        props.setFlgPopup(true);
    }
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="6%"></col>
        <col width="6%"></col>
        <col width="6%"></col>
        <col width="6%"></col>
        <col width="25%"></col>
    </colgroup>
    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Route No.</th>
                        <th>Driver</th>
                        <th>Contact No.</th>
                        <th>Vehicle</th>
                        <th>Vehicle Type</th>
                        <th>Capacity</th>
                        <th>Students</th>
                        <th>Stops</th>
                        <th>Duration</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {
                            lstRoute.map((obj, idx) => {
                                return <tr key={idx}
                                    onClick={(e) => setRowRouteId(obj.routeId)} className={obj.routeId === routeId ? "selected" : ""}
                                >
                                    <td>{obj.routeNo}</td>
                                    <td>{obj.driver}</td>
                                    <td>{obj.contactNo}</td>
                                    <td>{obj.vehicleRegsNo}</td>
                                    <td>{obj.vehicleType}</td>
                                    <td>{obj.capacity}</td>
                                    <td>{obj.studentCount}</td>
                                    <td>{obj.stopCount}</td>
                                    <td>{obj.duration}</td>
                                    <td className="text-center">
                                        <span className="glyphicon glyphicon-edit" onClick={(e) => props.editRoute(obj.routeId)} title="Edit"></span>
                                        <span className="glyphicon glyphicon-user" onClick={(e) => props.openStaffSlider(obj.routeId)} title="Staff"></span>
                                        <span className="glyphicon glyphicon-stop" onClick={(e) => goToStopScreen(obj.routeId, obj.routeNo)} title="Stops"><a href="/stop" target="_blank"></a></span>
                                        <span className="glyphicon glyphicon-map-marker" onClick={(e) => props.openMapSlider(obj.routeId)} title="Map View"></span>
                                        <span className="glyphicon glyphicon-time" onClick={(e) => props.openArrDepSlider(obj.routeId)} title="Arrival-Departure"></span>
                                        <span className="glyphicon glyphicon-film" title="Publish" onClick={(e)=>openPopup(obj.routeId)}></span>
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default RouteTable;