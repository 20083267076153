import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import ExamDateHeader from 'common/js/examDateHeader';
import ClsHeader from 'common/js/ClsHeader.js';

import StudentSubScoreTable from './StudentSubScoreTable';
import { getClassListSvc, getSectionListSvc} from './ExamEvaluateSvc.js';



const ExamEvaluateCtrl = () => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [examId, setExamId] = useState(0)
    const [classId, setClassId] = useState(0)
    const [randNo, setRandNo] = useState(0)
    const [lstCls, setLstCls] = useState([]);
    const [lstSection, setLstSection] = useState([]);
    const [sectionId, setSectionId] = useState(0);
    const [syllabusId, setSyllabusId] = useState("0");
    const [state, setState] = useState(initialState);
    const [studentId, setStudentId] = useState(0);
    const [maxScore, setMaxScore] = useState(0);
     
    useEffect(() => {
        // console .log("useEffect called in ExamEvaluateCtrl.js");
        setClass();
    }, []);
    
    function setClass() {
        // console .log("setClassMethod called");
        getClassListSvc(cbClassResponse);
    }

    function cbClassResponse(data) {
        // console .log("cbClassResponse called, data", data);
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstCls(obj.lstCls);
            // console .log("obj.lstCls", obj.lstCls);
            var tmpClassId = obj.lstCls[0].classId;
            changeClass(tmpClassId)
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function setSection(clsId) {
        // console .log("setSection called, clsId", clsId);
        getSectionListSvc(handleSectionResponse, clsId);
    }
    
    function handleSectionResponse(data) {
        // console .log("handleSectionResponse called, data", data);
        return new Promise((resolve) => {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstSection(obj.lstClassSection);
            // console .log("obj.lstClassSEction", obj.lstClassSection);
            if(obj.lstClassSection && obj.lstClassSection.length){
                changeSection(obj.lstClassSection[0].sectionId,obj.lstClassSection[0].syllabusId)
            }
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
        resolve();
    });
    }

    async function changeClass(pClsId) {
        // console .log("changeClass called, pClsId", pClsId);
        await setState({ classId: pClsId });
        await setClassId(pClsId);
        await setLstSection([]);
        // console .log("changeClass: " + pClsId + ", sectionId::" + sectionId + ", sessionId::" + sessionId);
        setSection(pClsId);
        
        // pClsId !== 0 && sectionId !== 0 && setSubjectScoreTable(sessionId, pClsId, sectionId);
    }

    async function changeSection(pSectionId,pSyllabusId) {
        // console .log("changeSection called, pSectionId", pSectionId,", pSyllabusId",pSyllabusId);
        await setState({ sectionId: pSectionId, syllabusId:pSyllabusId?pSyllabusId:0 });
        setSectionId(pSectionId);
        setSyllabusId(pSyllabusId?pSyllabusId:0);
        generateRandNo();
        // console .log("changeSection: " + pSectionId + ", clsId::" + classId + ", sessId::" + sessionId);
        // classId !== 0 && pSectionId !== 0 && setSubjectScoreTable(classId, pSectionId);
    }

    function refreshExamSchedule(pExam) {
        // console .log("refreshEXamSchedule called", pExam);
        setExamId(pExam.examId);
        setMaxScore(pExam.maxScore);
        generateRandNo()
    }

     function generateRandNo() {
        return Math.floor(Math.random() * 999999);
    }

    const getWingId = (wingId) => {
        // console .log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" style={{ marginTop: '15px', opacity: '1' }}>
                <div className="col-sm-12 col-centered ">
                    <div className="col-sm-7">
                        <ExamDateHeader examClick={refreshExamSchedule} examId={examId} />
                    </div>
                    <div className="col-sm-5 clearfix">        
                        <ClsHeader
                            changeClass={changeClass}
                            changeSection={changeSection}
                            lstSection={lstSection}
                            lstCls={lstCls}
                            classId={classId}
                            selSectionId={sectionId}
                            selSyllabusId={syllabusId}
                        />
                    </div>

                    <div className="col-sm-12" style={{ top: 10, height: '100%' }}>
                        
                        <StudentSubScoreTable
                            key={randNo}
                            sectionId={sectionId}
                            syllabusId={syllabusId}
                            setStudentId={setStudentId}
                            examId={examId}
                            generateRandNo={generateRandNo}
                            maxScore={maxScore}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamEvaluateCtrl;