import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['staff'].url + ":" + lstModules['staff'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
function getLstStaff(callBack) {
    axios(
        {
            url: httpURL + "/sms/system/staff/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data

            callBack(data)

        }
    ).catch((error) => {
        console.error(error)
    });
}
function deleteStaffSvc(callback, userId) {

    axios({
        url: httpURL + "/sms/system/staff/delete",
        method: "POST",
        params: { userId: userId },
        headers: { 'tokenId': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        callback(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}

function getStaffDtlsSvc( pUserId, callBack) {
    if (!pUserId) {
        return;
    }
    axios(
        {
            url: httpURL + "/sms/system/staff/dtls",
            method: "POST",
            params: { userId: pUserId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData)
    }).catch((error) => {
        callBack(error)
    });
}

function saveStaffSvc(pStaffObj, cbSaveStaffResponse) {
    axios({
        url: httpURL + "/sms/system/staff/save",
        method: "POST",
        params: { staff: JSON.stringify(pStaffObj) },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveStaffResponse(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}

function getRoleListSvc(callBack) {
    axios(
        {
            url: httpURLCommon + "/sms/mst/roles/list",
            method: "POST",
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData)
    }).catch((error) => {
        callBack(error)
    });
}
function savePrimaryRoleSvc(pUserId, pPrimaryRoleId, cbSavePrimaryRoleResponse) {
    axios({
        url: httpURL + "/sms/system/staff/primary_role/save",
        method: "POST",
        params: { userId: pUserId, primaryRoleId: pPrimaryRoleId },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSavePrimaryRoleResponse(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}
function getSecondaryRoleListSvc(pUserId, callBack) {
    axios(
        {
            url: httpURL + "/sms/system/staff/secondary_role/list",
            method: "POST",
            params: { userId: pUserId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData)
    }).catch((error) => {
        callBack(error)
    });
}
function saveSecondaryRoleSvc(pUserId, pLstsecondaryRoleId, cbSaveSecondaryRoleResponse) {
    var formData = new FormData();
    // console .log(typeof (pLstsecondaryRoleId))
    formData.append("userId", pUserId)
    formData.append("lstSecondaryRoleId", JSON.stringify(pLstsecondaryRoleId))
    axios({
        url: httpURL + "/sms/system/staff/secondary_role/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveSecondaryRoleResponse(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}
function getStaffClassSectionListSvc(pUserId, callBack) {
    axios(
        {
            url: httpURL + "/sms/system/staff/cls_section/list",
            method: "POST",
            params: { userId: pUserId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData)
    }).catch((error) => {
        callBack(error)
    });
}
function deleteStaffSectionSvc(pUserId, pSectionId, callBack) {
    axios(
        {
            url: httpURL + "/sms/system/staff/cls_section/delete",
            method: 'POST',
            params: { userId: pUserId, sectionId: pSectionId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getClassListSvc(callBack) {
    axios(
        {
            url: httpURLCommon + "/sms/mst/cls/list",
            method: "POST",
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData)
    }).catch((error) => {
        callBack(error)
    });
}
function getClassSectionListSvc(pClassId, callBack, pSectionId) {
    axios(
        {
            url: httpURL + "/sms/cls/sec/list",
            method: "POST",
            params: { classId: pClassId, screenId: 8 },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData, pSectionId)
    }).catch((error) => {
        callBack(error)
    });
}
function getSectionSubjectListSvc(pUserId, pSectionId, callBack) {
    axios(
        {
            url: httpURL + "/sms/system/staff/section_subject/list",
            method: "POST",
            params: { userId: pUserId, sectionId: pSectionId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        var ourData = Response.data
        callBack(ourData)
    }).catch((error) => {
        callBack(error)
    });
}
function saveClassSectionSvc(pUserId, pClassId, pSectionId, pLstSubjectId, cbSaveClassSection) {
    var formData = new FormData();
    formData.append("userId", pUserId)
    formData.append("clsId", pClassId)
    formData.append("sectionId", pSectionId)
    formData.append("lstSubjectId", JSON.stringify(pLstSubjectId))
    axios({
        url: httpURL + "/sms/system/staff/section_subject/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveClassSection(data, pClassId, pSectionId)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}

function generatePasswordSvc(userId, callBack) {
    axios({
        url: httpURL + "/sms/system/staff/reset_password",
        method: "POST",
        params: { userId: userId },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        callBack(data)
    }).catch((error) => { // console .log("Error Occured") 
    });
}

function saveStaffAccessSvc(userId, flgAccess, callBack) {
    // console .log('typeof flgAllowAccess ' + typeof (flgAccess))
    // console .log('value of allow access ' + flgAccess)
    // console .log('user id for access is ' + userId)
    axios({
        url: httpURL + "/sms/system/staff/system_access/save",
        method: "POST",
        params: { userId: userId, flgAllowAccess: flgAccess },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        callBack(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}

function saveAllocationSvc(userId, minAllocation, maxAllocation, cbSaveAllocationResponse) {
    axios({
        url: httpURL + "/sms/system/staff/allocation/save",
        method: "POST",
        params: { "userId": userId, "minAllocation": minAllocation, "maxAllocation": maxAllocation },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveAllocationResponse(data)
    }).catch((error) => { 
        // console .log("Error Occured") 
    });
}

function uploadStaffExcelSvc(formData, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/staff/excel/upload',
        method: "POST",
        data: formData,
        headers: { tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            obj.errors = response.data.errors;
        }
        cb(obj)
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)

    });
}

function viewSignSvc(userId, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/system/staff/sign/view?userId=' + userId, true);
    xhr.setRequestHeader('tokenid', tokenid);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
        // console .log(xhr)
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // console .log(blob)
        callback(imageUrl, xhr.response);
    };
    xhr.send();
}

function saveStaffSignSvc(userId, roleId, file, callBack) {
    var formData = new FormData();
    formData.append("sign", file)
    formData.append("userId", userId)
    formData.append("roleId", roleId)


    axios({
        url: httpURL + "/sms/system/staff/sign/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        callBack(response.data)
    }).catch((e) => {
        console.error("Service failed " + e);
    });
}
export { getLstStaff }
export { saveStaffSvc };
export { getStaffDtlsSvc };
export { getRoleListSvc };
export { savePrimaryRoleSvc };
export { getSecondaryRoleListSvc };
export { saveSecondaryRoleSvc };
export { getStaffClassSectionListSvc };
export { deleteStaffSectionSvc };
export { getClassListSvc };
export { getClassSectionListSvc };
export { getSectionSubjectListSvc };
export { saveClassSectionSvc };
export { deleteStaffSvc };
export { generatePasswordSvc };
export { saveStaffAccessSvc }
export { saveAllocationSvc };
export { uploadStaffExcelSvc };
export { viewSignSvc };
export { saveStaffSignSvc };