import axios from 'axios'
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
const httpURL1 = lstModules['wings'].url + ':' + lstModules['wings'].port;

var tokenid = localStorage.getItem("tokenid");
function getLstMstRouteSvc(cb){
    var status = 'Success'
    var obj = { status: status, lstRoute:[]};
    axios({
        url: httpURL + '/sms/tpt/mst/route/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        // console .log(data)
        if (data.SvcStatus === 'Success') {
            obj.status = data.SvcStatus;
            obj.lstRoute = data.lstRoute;
            obj.message = data.SvcMsg;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

}
function getAttendanceSummarySvc(from,to,flgMorEve, cb) {
    var status = 'Success'
    var obj = { status: status, lstRoute:[]};
    axios({
        url: httpURL + '/sms/tpt/attendance/route/list',
        method: "POST",
        params: {fromDt:from, toDt:to, flgMorEve:flgMorEve},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        // console .log(data)
        if (data.SvcStatus === 'Success') {
            obj.status = data.SvcStatus;
            obj.lstRoute = data.lstRoute;
            obj.message = data.SvcMsg;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

}
/* 
function saveWebsiteSvc(pWebsite, cb){
    var status = 'Success'
    var obj = { status: status, website:'' };
    axios({
        url: httpURL + '/sms/website/save',
        method: "POST",
        params: {website:pWebsite},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        if (data.SvcStatus === 'Success') {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

}

function generateApiKeySvc(cb){
    var status = 'Success'
    var obj = { status: status, apiKey:'' };
    axios({
        url: httpURL + '/sms/website/generate_api_key',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        // console .log(data)
        if (data.SvcStatus === 'Success') {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
            obj.apiKey=data.apiKey;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

} */
export {getLstMstRouteSvc, getAttendanceSummarySvc}