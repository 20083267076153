import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
var queryString = require("querystring");



export const saveSiblingSvc = (studentSibling, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: httpURL + "/sms/student/save_sibling",
        method: 'POST',
        data: queryString.stringify({
             studentSibling: JSON.stringify(studentSibling)
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console .log("response in saveSiblingSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const deleteSiblingSvc = (siblingId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    const schoolSibling = {
        schoolId: schoolId,
        siblingId: parseInt(siblingId),
    };
    axios({
        url: `${httpURL}/sms/student/remove_sibling`,
        method: 'POST',
        data: queryString.stringify({
            schoolSibling: JSON.stringify(schoolSibling),
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console .log("response in deleteSiblingSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const getSiblingsListSvc = (studentId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    const schoolStudent = {
        schoolId: schoolId,
        studentId: parseInt(studentId),
    };
    axios({
        url: `${httpURL}/sms/student/list_siblings`,
        method: 'POST',
        data: queryString.stringify({
            schoolStudent: JSON.stringify(schoolStudent),
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console .log("response in getSiblingsListSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstSiblings = response.data.lstSiblings;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};