import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


const screenId = 32;
let queryString = require('querystring');
const tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['academicSchedule'].url + ":" + lstModules['academicSchedule'].port;

export const saveAcademicYearSvc = (academicSchedule, cbSaveAcademicSchedule) => {
    // console .log("saveAcademicYearSvc called, academicSchedule object", academicSchedule);
    const obj = { status: "", message: "" };
    const formData = queryString.stringify({ 'academicSchedule': JSON.stringify(academicSchedule), screenId });
    axios({
        url: httpURL + '/sms/acdSch/save',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.academicScheduleId = response.data.academicScheduleId;
        cbSaveAcademicSchedule(obj);
        // console .log("response", response);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveAcademicSchedule(obj);
    })
}

export const saveAcademicSchedulePeriodSvc = (schedulePeriod, SaveAcademicSchedulePeriod) => {
    // console .log("saveAcademicYearSvc called");
    const obj = { status: "", message: "" };
    const formData = queryString.stringify({ 'schedulePeriod': JSON.stringify(schedulePeriod), screenId });
    axios({
        url: httpURL + '/sms/acdSch/saveSchPeriod',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.schedulePeriodId = response.data.schedulePeriodId;
        SaveAcademicSchedulePeriod(obj);
        // console .log("response", response);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        SaveAcademicSchedulePeriod(obj);
    })
}


export const getAcademicYearListSvc = ( cbGetAcademicYearList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdyr/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstAcademicYear = response.data.lstAcademicYear;
        cbGetAcademicYearList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetAcademicYearList(obj);
    })
}


export const getAcademicSchedulePeriodTypeListSvc = ( cbGetAcademicSchedulePeriodTypeList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/mst/periodType/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstPeriodType = response.data.lstPeriodType;
        // console .log("response getAcademicSchedulePeriodTypeListSvc", response);
        cbGetAcademicSchedulePeriodTypeList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetAcademicSchedulePeriodTypeList(obj);
    })
}


export const getAcademicScheduleListSvc = (acdYrId, wingId, cbFetAcademicScheduleList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdSch/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { screenId, acdYrId, wingId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstAcademicSchedule = response.data.lstAcademicSchedule;
        cbFetAcademicScheduleList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbFetAcademicScheduleList(obj);
    })
}


export const getAcademicYearWingListSvc = (acdYrId, cbAcademicYearWingList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdSch/wings',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { screenId, acdYrId},
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstWing= response.data.lstWing;
        cbAcademicYearWingList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbAcademicYearWingList(obj);
    })
}


export const getAcademicScheduleDetailsSvc = (acdSchId, cbAcademicScheduleDetails) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdSch/dtls',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { screenId, acdSchId},
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.academicScheduleData= response.data.academicSchedule;
        cbAcademicScheduleDetails(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbAcademicScheduleDetails(obj);
    })
}


export const getSchedulePeriodListSvc = (acdSchId, cbSchedulePeriodList) => {
    // console .log("academicScheduleId in getSchedulePeriodListSvc method passed", acdSchId, ", url",  httpURL + '/sms/acdSch/listSchPeriod',);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdSch/listSchPeriod',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { acdSchId, screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstSchedulePeriod = response.data.lstSchedulePeriod;
        cbSchedulePeriodList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSchedulePeriodList(obj);
    })
}

export const deleteAcademicScheduleSvc = (acdSchId, wingId, cbDeleteAcademicSchedule) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdSch/delete',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { acdSchId, wingId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbDeleteAcademicSchedule(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbDeleteAcademicSchedule(obj);
    })
}

export const deleteAcademicSchedulePeriodSvc = (schedulePeriodId, adjustTimings, cbDeleteAcademicSchedulePeriod) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdSch/deleteSchPeriod',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { schedulePeriodId, adjustTimings, screenId },
        // params: queryString.stringify({ 'schedulePeriodId': schedulePeriodId, screenId, adjustTimings }),
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbDeleteAcademicSchedulePeriod(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbDeleteAcademicSchedulePeriod(obj);
    })
}