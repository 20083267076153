import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify'

import Chapter from "./chapter";
import Topic from './topic'
import SyllabusSubjectTable from "./syllabusSubjectTable";
import { getLstSyllabusSubjectSvc, getTopicListSvc, saveSubjectSvc } from "./syllabusSvc";


const SyllabusForm = (props) => {

    const [syllabusNotes, setSyllabusNotes] = useState("");
    const [syllabusCode, setSyllabusCode] = useState("");
    const [lstSyllabusSubject, setLstSyllabusSubject] = useState([]);
    const [chapterId, setChapterId] = useState(0);
    const [subjectId, setSubjectId] = useState(0);
    const [sybSubId, setSybSubId] = useState(0);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [isChapterSliderOpen, setIsChapterSliderOpen] = useState(false);
    const [lstSubject, setLstSubject] = useState([]);
    const [syllabusId, setSyllabusId] = useState(0);
    const [lstTopic, setLstTopic] = useState([]);
    // const [lstChapter, setLstChapter] = useState([]);

    useEffect(() => {
        // console .log("SyllabusForm component called, props", props);
        if (props.syllabusId !== 0) {
            getLstSyllabusSubject(props.syllabusId);
            var objData = getObjData(props.syllabusId)
            setSyllabusCode(objData.syllabusCode);
            setSyllabusNotes(objData.syllabusNotes);
        } else {
            setSyllabusCode("");
            setSyllabusNotes("");
            setLstSyllabusSubject([]);
        }
    }, [props.syllabusId, props.updateKey]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // console .log("handleSubmit called, syllabusCode", syllabusCode, ", syllabusNotes", syllabusNotes);
        if (syllabusCode === '') {
            toast.warning('Please Enter Syllabus Code')
            return false
        }
        if (syllabusNotes === '') {
            toast.warning('Please Enter Syllabus Notes')
            return false
        }
        var objToSave = {
            syllabusNotes,
            syllabusCode
        }
        props.addSyllabus(objToSave);

    }

    const clearForm = () => {                  // setAllState 
        // console .log("setAllState called");
        if (props.syllabusId === 0) {
            setSyllabusCode("");
            setSyllabusNotes("");
        }
    }

    const handleSyllabusCodeChange = (e, pSybCode) => {
        // console .log("handleSyllabusCodeChange method called");
        e.preventDefault();
        setSyllabusCode(pSybCode);
    }

    const handleSyllabusNotesChange = (e, pSybNotes) => {
        // console .log("handleSyllabusNotesChange method called");
        e.preventDefault();
        setSyllabusNotes(pSybNotes);
    }

    const toggleSelectAll = (check) => {
        // console .log("select all mehtod called");
        if (lstSyllabusSubject && lstSyllabusSubject.length) {
            let tempLstSyllabusSubject = [...lstSyllabusSubject];
            tempLstSyllabusSubject.forEach(group => {
                group.selected = check;
            })
            setLstSyllabusSubject(tempLstSyllabusSubject);
            setIsSelectAllChecked(check);
        }
    }

    const checkedClick = (e, subject) => {
        let target = e.target;
        let tempLstSyllabusSubject = [...lstSyllabusSubject];
        if (Array.isArray(lstSyllabusSubject)
            && target.id != null
            && lstSyllabusSubject[target.id] != null) {
            tempLstSyllabusSubject[target.id].selected = target.checked;
            setIsSelectAllChecked(!lstSyllabusSubject.some(group => !group.selected));
            setLstSyllabusSubject(tempLstSyllabusSubject);
        }
    }

    const getObjData = (syllabusId) => {
        for (let i = 0; i < props.lstSyllabus.length; i++) {
            if (parseInt(syllabusId) === props.lstSyllabus[i].syllabusId)
                return props.lstSyllabus[i];
        }
    }

    const getLstSyllabusSubject = (syllabusId) => {
        setLoader('block');
        setOpacity(0.5);
        getLstSyllabusSubjectSvc(syllabusId, lstSyllabusSubjectResponse);
    }

    const lstSyllabusSubjectResponse = (data) => {
        // console .log("lstSyllabusSubjectResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstSyllabusSubject(obj.lstSyllabusSubject);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const saveSubject = () => {
        // console .log("saveSubject called");
        let syllabusSubject = {};
        let subject = {};
        let tempLstSubject = [];
        if (lstSyllabusSubject && lstSyllabusSubject.length) {
            lstSyllabusSubject.map((syllabus) => {
                if (syllabus.selected) {
                    subject = {
                        subId: syllabus.subjectId,
                        sub: syllabus.subject,
                        subType: '',
                        splitAllowed: true
                    }
                    tempLstSubject.push(subject);
                }
            })
        }
        if (tempLstSubject.length === 0) {
            toast.warning('Please Select Altleast one Subject')
            return false
        }
        syllabusSubject = {
            syllabusId:props.syllabusId,
            lstSubject:tempLstSubject
        }
        saveSubjectSvc(syllabusSubject, saveSubjectResponse);
    }

    const saveSubjectResponse = (data) => {
        // console .log("saveSubjectResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            getLstSyllabusSubject(props.syllabusId);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }
    
    const getData = () => {
        getLstSyllabusSubject(props.syllabusId);
    }

    const showSlider = async (sybSubId, subId) => {
        // console .log("showSlider called (chapter link clicked) passed sybSubId", sybSubId, ", subId", subId);
        setSubjectId(subId);
        setSybSubId(sybSubId);
        // props.getChapterList(sybSubId);
        setIsChapterSliderOpen(true);
    }

    const closeSlider = () => {
        // console .log("closeSlider called");
        setIsChapterSliderOpen(false);
    }

    const getTopicList = (pChapterId) => {
        // console .log("getTopicList called, chapterId", pChapterId);
        getTopicListSvc(pChapterId, topicListResponse);
    }


    const topicListResponse = (data) => {
        // console .log("topicListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstTopic(obj.lstTopics);
        }
        else {
            setChapterId(0);
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }



    return (
        <div>
            <form
                id='syllabusForm'
                onSubmit={handleSubmit}
                className='form-horizontal formWrapper'
            >
                <div className='form-group'>
                    <label className='control-label col-sm-4'>
                        Syllabus Code
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                    </label>
                    <div className='col-sm-7 padding-remove'>
                        <input
                            autoComplete='off'
                            id='syllabusCode'
                            name='syllabusCode'
                            type='text'
                            className='form-control '
                            placeholder='Syllabus Code'
                            value={syllabusCode  }
                            onChange={(e) => handleSyllabusCodeChange(e, e.target.value)}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label className='control-label col-sm-4'>
                        Notes<span style={{ color: 'red', marginLeft: 2 }}>*</span>
                    </label>
                    <div className='col-sm-7 padding-remove'>
                        <textarea
                            id='syllabusNotes'
                            name='syllabusNotes'
                            className='form-control'
                            placeholder='Notes'
                            value={syllabusNotes}
                            onChange={(e) => handleSyllabusNotesChange(e, e.target.value)}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <div className='col-sm-6 pull-right userButtonWrap'>
                        <button
                            type='submit'
                            style={{ marginTop: 20 }}
                            title='Save'
                            className='btn btn-info'
                        >
                            Save
                        </button>
                    </div>
                </div>
                {lstSyllabusSubject && lstSyllabusSubject.length ? (
                    < SyllabusSubjectTable
                        style={{ marginTop: 20 }}
                        lstSyllabusSubject={lstSyllabusSubject}
                        setLstSyllabusSubject={setLstSyllabusSubject}
                        selectAll={toggleSelectAll}
                        checkedClick={checkedClick}
                        saveSubject={saveSubject}
                        showSlider={showSlider}
                        checkedAll={isSelectAllChecked}
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                    />
                ) : null}
            </form>
            {isChapterSliderOpen ?
                <div className='slider1-80'>
                    <>
                        <Chapter
                            key={subjectId}
                            // key={this.props.sybSubId}
                            // lstChapters={lstChapter}
                            // lstChapters={this.props.lstChapters}
                            sybSubId={sybSubId}
                            subjectId={subjectId}
                            closeSlider={closeSlider}
                            getTopicList={getTopicList}
                            syllabusId={props.syllabusId}
                        />
                        {chapterId 
                            && lstTopic
                            && lstTopic.length !== 0
                             ? (
                            <Topic
                                chapterId={chapterId}
                                lstTopics={lstTopic}
                                getTopicList={getTopicList}
                            />
                        )
                            : null
                        }
                    </>
                </div>
                : null
            }
        </div>
    );
}
export default SyllabusForm;