import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['wings'].url + ':' + lstModules['wings'].port;
var tokenid = localStorage.getItem("tokenid");

function getLstWard(callback) {

    axios({
        url: httpURL + '/pp/ward/list',
        method: "POST",
        params: { userId: 1 },
        headers: { /* 'tokenid': tokenid */ },
        withCredentials: true,
    }).then((response) => {
        // console .log('data.SvcStatus=' + response.data.SvcStatus);
        callback(response.data);
    }).catch((error) => {
        callback(error);
    });
}

export {getLstWard};