import { getStudentListSvc } from './StudentFeeHeadSvc.js'
import React, { useState, useRef, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'

const StudentFeeHeadTable = props => {
  const [lstEnq, setLstEnq] = useState([])
  const [flgSliderType, setFlgSliderType] = useState([])
  const [loader, setLoader] = useState(false)
  const [name, setName] = useState('')
  const [clsSection, setClsSection] = useState('')
  const [foundEnquiry, setFoundEnquiry] = useState(lstEnq)
  const [lstSelEnquiry, setLstSelEnquiry] = useState([])

  useEffect(() => {
    setLoader('block')
    getStudentListSvc(handleLstStudentResponse)
  }, [])

  function handleLstStudentResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstEnq(data.lstStudent)
      setFoundEnquiry(data.lstStudent)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function handleCBoxChange (enqId, status) {
    // console .log('handleCBoxChange triggered')
    if (status && !lstSelEnquiry.includes(enqId)) {
      var lstTempArr = lstSelEnquiry
      lstTempArr.push(enqId)
      setLstSelEnquiry(lstTempArr)
      // console .log('checked ' + lstTempArr)
      returnSelEnqId()
    } else {
      var lstTempArr = lstSelEnquiry
      lstTempArr.splice(lstTempArr.indexOf(enqId))
      setLstSelEnquiry(lstTempArr)
      // console .log('unchecked' + lstTempArr)
      returnSelEnqId()
    }
  }

  function returnSelEnqId () {
    props.setEnqForAssign(lstSelEnquiry)
  }

  function actionClick (pEnqId, pClsId) {
    props.setSelectedEnquiryId(pEnqId)
    props.setSelectedClsId(pClsId)
  }
  const filterName = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundEnquiry.filter(user => {
        return user.name.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundEnquiry(results)
    } else {
      setFoundEnquiry(lstEnq)
      // If the text field is empty, show all users
    }

    setName(keyword)
  }

  const filterClass = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundEnquiry.filter(user => {
        return user.cls.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundEnquiry(results)
    } else {
      setFoundEnquiry(lstEnq)
      // If the text field is empty, show all users
    }

    setClsSection(keyword)
  }
  const colgrp = (
    <colgroup>
      <col width='10%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='10%'></col>
      <col width='30%'></col>
      <col width='10%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
    </colgroup>
  )

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Class-Section</th>
            <th>Enquiry Id</th>
            <th>Registration Id</th>
            <th>Student Id</th>
            <th style={{ width: 75 }}>
              <input
                onChange={filterName}
                type='text'
                value={name}
                className='form-control inp-search'
                placeholder='Name'
              />
            </th>
            <th>Roll No.</th>
            <th>New/Regular</th>
            <th>Amount</th>
            <th>Selected</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {foundEnquiry.map((enq, idx) => {
              return (
                <tr
                  className={
                    enq.enqId === props.selectedEnquiryId ? 'selected' : ''
                  }
                  key={idx}
                  onClick={e => props.setSelectedEnquiryId(enq.enqId)}
                >
                  <td>{enq.clsSection}</td>
                  <td>{enq.enquiryId}</td>
                  <td>{enq.regsId}</td>
                  <td>{enq.studentId}</td>
                  <td className='text-left'>{enq.student}</td>
                  <td>{enq.rollno}</td>
                  <td>{enq.studentType}</td>
                  <td>{enq.amount}</td>
                  <td>
                    <input
                      type='checkbox'
                      onClick={e =>
                        handleCBoxChange(enq.enqId, e.target.checked)
                      }
                    />
                  </td>
                  <td className='text-center'>
                    <span
                      className='glyphicon glyphicon-option-vertical'
                      onClick={e => actionClick(enq.enqId, enq.clsId)}
                    >
                      Payment
                    </span>
                  </td>
                  {/* <td>
                  <div className="dropdown">
                    <button className="dropbtn" onClick={e => actionClick(enq.enqId)}>Action</button>
                  </div>
                </td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default StudentFeeHeadTable
