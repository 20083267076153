import React, { useEffect, useState } from 'react'
import ClassList from './classList'
import SectionList from './sectionList'

const ClsHeader = (props) => {
    const [state, setState] = useState({});

    useEffect(()=>{
        // console .log("clsHeader.js called");
        // console .log("props.lstSession ttSubHead",props.lstSession);
        // console .log("props.selSessionId ttSubHead",props.selSessionId);
        // console .log("props.lstCls ttSubHead",props.lstCls);
        // console .log("props.lstSection ttSubHead",props.lstSection);
        // console .log("props.classId ttSubHead",props.classId);
        // console .log("props.selSectionId ttSubHead",props.selSectionId);
    },[props.lstCls]);


    return (
        <div className="row" style={{ //boxShadow: '#ccc 0px 1px 4px 0px', borderWidth: '1px 0px', borderStyle: 'solid', height: '50px', borderColor: '#ccc', 
            padding: '0px 20px', height: 'auto' }}>

            <ClassList lstCls={props.lstCls} classClick={props.changeClass}  classId={props.classId}/>
            <SectionList lstSection={props.lstSection} sectionClick={props.changeSection} selSectionId={props.selSectionId} selSyllabusId={props.selSyllabusId} classId={props.classId} />

        </div>
    )
}

export default ClsHeader;