import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const tokenid = localStorage.getItem("tokenid");
const httpURL = lstModules['test'].url + ":" + lstModules['test'].port ;




export const getQuestionsSvc = (testId, testScheduleId, cbTestQuestions) => {
    // console .log("getQuestionsSvc called, testId", testId);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("testScheduleId", testScheduleId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/wt/schedule/print_questions',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console .log("response in getQuestionsSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstQuestion = response.data.lstQuestion;
            obj.header = response.data.header;
        }
        cbTestQuestions(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbTestQuestions(obj);
    })
}

export function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // console .log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}


