import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

// const httpURL = "http://192.168.1.5:8280" + "/sms/wt/test/student";
const httpURL = lstModules['test'].url + ':' + lstModules['test'].port + "/sms/wt/test/student";
const tokenid = localStorage.getItem('tokenid');



export const studentFeedbackSvc = (feedbackObj, pSchoolId, cbStudentFeedback) => {
    // console .log("studentFeedbackSvc called, url", httpURL + '/feedback');
    // console .log("feedbackObj", feedbackObj);
    const formData = new FormData();
    formData.append("feedbackObj", JSON.stringify(feedbackObj));
    formData.append("schoolId", pSchoolId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/feedback',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in studentFeedbackSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbStudentFeedback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbStudentFeedback(obj);
    })
}
