import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

// const httpURL = "http://192.168.1.5:8280" + "/sms/wt/test/student";
const httpURL =  lstModules['test'].url + ':' + lstModules['test'].port + "/sms/wt/test/student";
const tokenid = localStorage.getItem('tokenid');



export const testTimerSvc = (testId, pSchoolId, cbTestTimer) => {
    // console .log("testTimerSvc called, url", httpURL + '/time_to_start');
    // console .log("schoolId", schoolId, ", testId", testId);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/time_to_start',
        method: "POST",
        // params: { "testId": testId, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in testTimerSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // console .log("response.data.timeTostart in testTimeSvc", response.data.timeToStart);
            // console .log("minToStart", response.data.timeToStart.timeTostart);
            obj.minToStart = response.data.timeToStart.timeTostart;
            // console .log("secondsTostart", response.data.timeToStart.secondsTostart);
            obj.secondsTostart = response.data.timeToStart.secondsTostart;
            // console .log("noOfQuestions", response.data.timeToStart.noOfQus);
            obj.noOfQuestions = response.data.timeToStart.noOfQus;
        }
        cbTestTimer(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbTestTimer(obj);
    })
}


export const testStartedSvc = (testId, studentId, pSchoolId, rankers, cbTestStarted) => {
    // console .log("testStartedSvc called, url", httpURL + '/started');
    // console .log("schoolId", schoolId, ", testId", testId, ", studentId", studentId);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    formData.append("studentId", studentId);
    const obj = { status: "", message: "", "testId":testId, "studentId":studentId, "schoolId":pSchoolId, "rankers":rankers };
    axios({
        url: httpURL + '/started',
        method: "POST",
        // params: { "testId": testId, "studentId":studentId, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in testStartedSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.testStarted = response.data.testStarted;
            obj.lstStudent = response.data.lstStudent;
        }
        cbTestStarted(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbTestStarted(obj);
    })
}
