import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

const httpURL =  lstModules['test'].url + ':' + lstModules['test'].port + "/sms/wt";
const tokenid = localStorage.getItem('tokenid');




export const studentDtlsSvc = (testId, otp, pSchoolId, cbStudentDtls) => {
    // console .log("studentDtlsSvc called, url", httpURL + '/test/student/initiate_test');
    // console .log("schoolId", pSchoolId, ", testId", testId, ", otp", otp);
    const formData = new FormData();
    formData.append("testId", testId);
    formData.append("schoolId", pSchoolId);
    formData.append("otp", otp);
    const obj = { status: "", message: "", "testId":testId, "schoolId":pSchoolId };
    // console .log("obj before service call", obj);
    axios({
        url: httpURL + '/test/student/initiate_test',
        method: "POST",
        // params: { "testId": testId, "otp":otp, "schoolId":schoolId },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in studentDtlsSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.studentObj = response.data.studentObj;
        }
        cbStudentDtls(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbStudentDtls(obj);
    })
}


export const avatarListSvc = ( pSchoolId, cbAvatarList) => {
    // console .log("avatarListSvc called, url", httpURL + '/avatars/list');
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/avatars/list',
        method: "POST",
        params: { "schoolId": pSchoolId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in avatarListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.mapAvatar = response.data.mapAvatar;
            obj.avatarPath = response.data.avatarPath;
        }
        cbAvatarList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbAvatarList(obj);
    })
}


export const joinTestSvc = (joinObj, pSchoolId, cbJoinTest) => {
    // console .log("joinTestSvc called, url", httpURL + '/test/student/join');
    const obj = { status: "", message: "", "schoolId":pSchoolId };
    const formData = new FormData();
    formData.append("joinObj", JSON.stringify(joinObj));
    formData.append("schoolId", pSchoolId);
    axios({
        url: httpURL + '/test/student/join',
        method: "POST",
        // params: { "joinObj": JSON.stringify(joinObj) },
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console .log("response in joinTestSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbJoinTest(obj);
    }).catch((error) => {
        // console .log("error in joinTestSvc", error);
        obj.status = "Exception";
        obj.message = error;
        cbJoinTest(obj);
    })
}
