import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Header from 'common/js/header/header.js'
// import Header from 'common/js/header/headerOld.js'
import PtmTable from './PtmTable.js'
import PtmSlider from './PtmSlider.js'
import PtmAttendanceSlider from './PtmAttendanceSlider.js'

const PtmCtrl = () => {
  const [loader, setLoader] = useState('none')
  const [selPtmId, setSelPtmId] = useState(0)
  const [flgSlider, setFlgSlider] = useState(0)
  const [tableKey, setTableKey] = useState(0)
  const [objective, setObjective] = useState('')
  const [timings, setTimings] = useState('')
  const [sliderKey, setSliderKey] = useState(0);

  async function openSlider(pFlgSlider, pPtmId) {
    // console .log(pPtmId)
    var lRandNo = Math.floor(Math.random() * 999999)
    setSliderKey(lRandNo);
    await setSelPtmId(pPtmId)
    setFlgSlider(pFlgSlider)
  }

  function closeSlider() {
    setFlgSlider(0)
  }

  function refreshTable() {
    // console .log("refresh table called");
    setFlgSlider(0);
    setSelPtmId(0);
    var lRandNo = Math.floor(Math.random() * 999999)
    setTableKey(lRandNo);
  }
  
  function setSelPtmData(pPtmId, pObjective, pTimings) {
    setSelPtmId(pPtmId)
    setObjective(pObjective)
    setTimings(pTimings)
  }

  const getWingId = wingId => {
    // console .log('wingId = ' + wingId)
  }
  
  
  return (
    <div className='MainContainer'>
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='col-sm-10 col-centered' style={{ top: '1%' }}>
        <PtmTable
          key={tableKey}
          setFlgSlider={setFlgSlider}
          setPtmData={setSelPtmData}
          openSlider={openSlider}
          refreshTable={refreshTable}
        />

        <div
          className='col-sm-1'
          onClick={e => openSlider(10, 0)}
          title='Add New PTM'
        >
          <span
            id='addReadyId'
            title='Add'
            className='addIcon glyphicon glyphicon-plus-sign'
          ></span>
        </div>
        <div
          style={{ display: flgSlider == 10 ? 'block' : 'none', width: '80%' }}
          className='SlotSlider'
        >
          <button
            type='button'
            className='btn btn-info text-center btnCloseDiv'
            onClick={e => closeSlider()}
            style={{ right: '81%' }}
          >
            <span
              id='close'
              title='Close Slider'
              className='glyphicon glyphicon-remove'
            ></span>
          </button>

          <div
            className='col-sm-12'
            style={{
              pointerEvents: loader === 'none' ? 'auto' : 'none',
              height: '100%',
              margin: '0 auto',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: 10
            }}
          >
            <PtmSlider
              sliderKey={sliderKey}
              key={'s_' + selPtmId}
              ptmId={selPtmId}
              refreshTable={refreshTable}
              flgSlider={flgSlider}
            />
          </div>
        </div>

        <div
          style={{ display: flgSlider == 20 ? 'block' : 'none' }}
          className='SlotSlider'
        >
          <button
            type='button'
            className='btn btn-info text-center btnCloseDiv'
            onClick={e => closeSlider()}
          >
            <span
              id='close'
              title='Close Slider'
              className='glyphicon glyphicon-remove'
            ></span>
          </button>

          <div
            className='col-sm-12'
            style={{
              pointerEvents: loader === 'none' ? 'auto' : 'none',
              height: '100%',
              margin: '0 auto',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: 10
            }}
          >
            <PtmAttendanceSlider
              flgSlider={flgSlider}
              ptmId={selPtmId}
              key={selPtmId}
              objective={objective}
              timings={timings}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PtmCtrl
