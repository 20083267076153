import React, { useState, useEffect } from "react";
import Header from "common/js/header/header";
import 'common/css/master.css';
import './css/validateSummary.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClsHeader from "common/js/ClsHeader";
import ValidateTable from "./validateSummaryTbl";

import { getClassListSvc, getSectionListSvc } from "../ExamEvaluateSvc.js";

const ValidateSummary = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [classId, setClassId] = useState(0);
    const [lstCls, setLstCls] = useState([]);
    const [lstSection, setLstSection] = useState([]);
    const [sectionId, setSectionId] = useState(0);
    const [syllabusId, setSyllabusId] = useState(0);

    useEffect(() => {
        // console .log("ValidateSummary (ValidateSummaryCtrl.js) called");
        setClass();
    }, []);


    function setClass() {
        // console .log("setClassMethod called");
        getClassListSvc(cbClassResponse);
    }

    function cbClassResponse(data) {
        // console .log("cbClassResponse called, data", data);
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstCls(obj.lstCls);
            // console .log("obj.lstCls", obj.lstCls);
            var tmpClassId = obj.lstCls[0].classId;
            changeClass(tmpClassId)
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function setSection(clsId) {
        // console .log("setSection called, clsId", clsId);
        getSectionListSvc(handleSectionResponse, clsId);
    }

    function handleSectionResponse(data) {
        // console .log("handleSectionResponse called, data", data);
        return new Promise((resolve) => {
            var obj = data;
            if (obj.SvcStatus == 'Success') {
                setLstSection(obj.lstClassSection);
                // console .log("obj.lstClassSEction", obj.lstClassSection);
                if (obj.lstClassSection && obj.lstClassSection.length) {
                    changeSection(obj.lstClassSection[0].sectionId, obj.lstClassSection[0].syllabusId)
                }
            } else if (obj.SvcStatus == 'Failure') {
                if (obj.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (obj.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(obj.SvcMsg);
                }
            } else {
                toast.error("Service failed " + obj.SvcMsg);
            }
            resolve();
        });
    }

    async function changeClass(pClsId) {
        // console .log("changeClass called, pClsId", pClsId);
        await setClassId(pClsId);
        // console .log("changeClass: " + pClsId + ", sectionId::" + sectionId + ", sessionId::" + sessionId);
        // pClsId !== 0 && sectionId !== 0 && setSubjectScoreTable(sessionId, pClsId, sectionId);
        setSection(pClsId);
        setLstSection([])
        
    }

    async function changeSection(pSectionId, pSyllabusId) {
        // console .log("changeSection called, pSectionId", pSectionId);
        await setSectionId(pSectionId);
        await setSyllabusId(pSyllabusId)
        // console .log("changeSection: " + pSectionId + ", clsId::" + classId + ", sessId::" + sessionId);
        // classId !== 0 && pSectionId !== 0 && setSubjectScoreTable(classId, pSectionId);
    }


    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className="mainContainer" style={{ opacity: opacity }}>
                <div className="col-xs-11 col-centered padding-remove">
                    <div className="col-xs-12 padding-remove">
                        <div className="col-sm-5 padding-remove">
                            <ClsHeader
                                changeClass={changeClass}
                                changeSection={changeSection}
                                lstSection={lstSection}
                                lstCls={lstCls}
                                classId={classId}
                                selSectionId={sectionId}
                                selSyllabusId={syllabusId}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 padding-remove">
                        <ValidateTable
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            sectionId={sectionId}
                            syllabusId={syllabusId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ValidateSummary;