import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import { ToastContainer, toast } from 'react-toastify';
import Header from 'common/js/header/header.js';
import 'common/css/master.css';
import '../css/teacherdtls.css';
// import './../bootstrap/css/bootstrap.min.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
//import {baseUrl} from './const';

var httpURL = "";
var queryString = require('querystring');
var schoolId = 1;
var lstClass = [];
var lstSubject = [];
var lstCity = [];
var lstState = [];
var lstTeacher = [];
var lstEducation = [];
var lstTeacherEducation = [];
var lstTeacherClass = [];
var lstDocType = [];
var lstTeacherDoc = [];
var lstTeacherSubject = [];
var lstRole = [];
var lstGrades = [];
var lstGender = [{ "genderId": "Male", "gender": "Male" }, { "genderId": "Female", "gender": "Female" }];
var lstDocType = [{ docTypeId: 1, docType: 'ID' },
{ docTypeId: 2, docType: 'Address' },
{ docTypeId: 3, docType: 'Education' },
{ docTypeId: 4, docType: 'Experience' }];
var COL_TEACHERID = 'td:eq(0)';
var lstEducation = [];
var tokenid = localStorage.getItem("tokenid");
var teacher = {};
var MAX_TEACHER_LENGTH = 30;
var screenId = 16;
var teacherId = localStorage.getItem("teacherId");

class TeacherDtls extends Component {
	constructor(props) {
		super(props);
		this.state = {
			schoolId: 1,
			lstClass: [],
			lstSubject: [],
			lstTeacher: [],
			lstCity: [],
			lstState: [],
			teacherId: 0,
			lstEducation: [],
			educationFlg: true,
			docFlg: true,
			classFlg: true,
			subjectFlg: true,
			lstTeacherEducation: [],
			lstTeacherClass: [],
			lstDocType: [],
			lstTeacherDoc: [],
			lstTeacherSubject: [],
			teacher: {},
			flg: true,
			width: '97%',
			loader: 'none',
			opacity: 1,
			tokenid: tokenid
		}
	}
	async componentWillMount() {
		// console .log("componentWillMount() called:" + localStorage.getItem("teacherId"));
		const params = new URLSearchParams(window.location.search);
		await this.setState({
			// teacherId: parseInt(localStorage.getItem("teacherId")),
			// flg: localStorage.getItem("flg"),
			teacherId: params.get("teacherId"),
			flg: params.get("flg"),
		});

	}

	componentDidMount = () => {
		this.handleResize();
		window.addEventListener("load", this.handleResize);
		window.addEventListener("resize", this.handleResize);
		this.getMasterData();
		this.fillStateCmb();
		this.fillCityCmb(parseInt($('#inp_state').find('option:selected').val()));
		$('#inp_role').prepend($("<option> </option>").val(0).html('Role'));
		$('#inp_grade').prepend($("<option> </option>").val(0).html('Grade'));
		this.fillGenderCmb();

		this.setState({ 'teacherId': teacherId });

		// this.setTeacherTable();
	}

	getMasterData = () => {
		this.setState({ loader: 'block', opacity: 0.5 });

		axios({
			url: httpURL + '/sms/mst/state/list',
			method: "POST",
			data: queryString.stringify({ 'schoolId': schoolId, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					lstState: response.data.lstState,
				})
				lstState = this.state.lstState;
				// console .log(lstState);
				this.fillStateCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});


		axios({
			url: httpURL + '/sms/mst/class/list',
			method: "POST",
			data: queryString.stringify({ 'schoolId': schoolId, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					lstClass: response.data.lstClass,
				})
				lstClass = this.state.lstClass;

				// console .log(lstClass);
				this.setClassCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});


		axios({
			url: httpURL + '/sms/mst/subject/list',
			method: "POST",
			data: queryString.stringify({ 'schoolId': schoolId, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					lstSubject: response.data.lstSubject,
				})
				lstSubject = this.state.lstSubject;
				// console .log(lstSubject);
				this.setSubjectCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});

		axios({
			url: httpURL + '/sms/mst/education/list',
			method: "POST",
			data: queryString.stringify({ 'schoolId': schoolId, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					lstEducation: response.data.lstEducation,
				})
				lstEducation = this.state.lstEducation;
				// console .log(lstEducation);
				this.setEducationCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});

		axios({
			url: httpURL + '/sms/mst/roles/dept/list?deptId=' + 2,
			method: "POST",
			data: queryString.stringify({ screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {

				lstRole = response.data.lstRole;
				// console .log(lstEducation);
				this.setRolesCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});

		axios({
			url: httpURL + '/sms/mst/grade/list',
			method: "POST",
			data: queryString.stringify({ screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {

				lstGrades = response.data.lstGrades;
				// console .log(lstEducation);
				this.setGradesCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});



		if (localStorage.getItem('teacherId') != null) {

			var schoolTeacher = {
				'schoolId': schoolId,
				'teacherId': parseInt(localStorage.getItem('teacherId'))
			};
			// ;// console .log(JSON.stringify(schoolTeacher));
			// ;// console .log(localStorage.getItem('teacherId'));
			this.getTeacher(schoolTeacher);
			this.getTeacherEducationList(schoolTeacher);
			this.getTeacherClassList(schoolTeacher);
			this.getTeacherDocsList(schoolTeacher);
			// set the data in state and use it through the component
			//   localStorage.removeItem("teacherId");

		}
		// this.getMstCityList(schoolId);
		// this.getMstClassList(schoolId);
		// this.getMstSubjectList(schoolId);
		// this.getMstEducationList(schoolId);
		//this.getTeacherList(schoolId);
	}
	getTeacher = (schoolTeacher) => {
		var scTc = JSON.stringify(schoolTeacher);
		axios({
			url: httpURL + '/sms/teacher/get',
			method: "POST",
			data: queryString.stringify({ 'schoolTeacher': scTc, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					teacher: response.data.teacher,
				})
				var teacher = this.state.teacher;
				// console .log(teacher);
				this.setTeacherForm(teacher);
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});

	}

	handleResize = () => {
		var width1 = ($('.tableLayout1').parent().width()) - 18;
		var width2 = ($('.tableLayout2').parent().width()) - 18;
		var width3 = ($('.tableLayout3').parent().width()) - 18;
		this.setState({ width1: width1, width2: width2, width3: width3 });
	}
	fillGenderCmb() {
		$('#inp_gender').empty();
		$('#inp_gender').prepend($("<option> </option>").val('Gender').html('Gender'));
		for (var i = 0; i < lstGender.length; i++) {
			var genderId = lstGender[i].genderId;
			var gender = lstGender[i].gender;
			$('#inp_gender').append($("<option> </option>").val(genderId).html(gender));
		}
	}
	fillStateCmb() {
		$('#inp_state').empty();
		$('#inp_state').prepend($("<option> </option>").val('State').html('State'));
		for (var i = 0; i < lstState.length; i++) {
			var stateId = lstState[i].stateId;
			var state = lstState[i].state;
			$('#inp_state').append($("<option> </option>").val(stateId).html(state));
		}
	}
	getCityList = async () => {
		var stateId = $('#inp_state').val();
		await axios({
			url: httpURL + '/sms/mst/city/list',
			method: "POST",
			data: queryString.stringify({ 'schoolId': schoolId, 'stateId': stateId, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					lstCity: response.data.lstCity,
				})
				lstCity = this.state.lstCity;

				// console .log(lstCity);
				this.fillCityCmb();
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});
	}
	fillCityCmb() {
		var stateId = $('#inp_state').find("option:selected").val();
		$('#inp_city').empty();
		$('#inp_city').prepend($("<option> </option>").val('City').html('City'));
		for (var i = 0; i < lstCity.length; i++) {
			if (stateId == lstCity[i].stateId) {
				var cityId = lstCity[i].cityId;
				var city = lstCity[i].city;
				$('#inp_city').append($("<option> </option>").val(cityId).html(city));
			}
		}
	}
	setTeacherForm = async (teacherData) => {
		this.fillStateCmb();

		// console .log("teacherData: "+JSON.stringify(teacherData));
		if (teacherData !== -1) {
			var dd = teacherData.dob.split('-')[0];
			var mm = teacherData.dob.split('-')[1];
			var yr = teacherData.dob.split('-')[2];
			var d = teacherData.doj.split('-')[0];
			var m = teacherData.doj.split('-')[1];
			var y = teacherData.doj.split('-')[2];

			this.setState({
				dob: new Date(yr, mm, dd),
				doj: new Date(y, m, d),
			})
			$('#inp_teacher').val(teacherData.teacher);
			$('#inp_address1').val(teacherData.address1);
			$('#inp_address2').val(teacherData.address2);
			$('#inp_state').val(teacherData.stateId);
			$('#inp_pin').val(teacherData.pin);
			$('#inp_married').attr('checked', teacherData.married);
			$('#inp_blocked').attr('checked', teacherData.blocked);
			$('#inp_gender').val(teacherData.gender);
			$('#inp_role').val(teacherData.roleId);
			$('#inp_grade').val(teacherData.gradeId);
			// $('#inp_doj').val(teacherData.doj);
			// $('#inp_dob').val(teacherData.dob);
			// console .log(teacherData.doj);
			// console .log(teacherData.dob);
			await this.getCityList(parseInt($('#inp_state').find('option:selected').val()));


			$('#inp_pin').val(teacherData.pin);
			$('#inp_city').val(teacherData.cityId);
			$('#inp_experience').val(teacherData.experience);
			$('#inp_email').val(teacherData.email);
			$('#inp_phoneNo').val(teacherData.mobileNo);
			$('#inp_emergencyContact').val(teacherData.emergencyContactNo);
		}

	}

	setEducationCmb() {
		$('#inp_education').empty();
		$('#inp_education').prepend($("<option> </option>").val(0).html('Education'));
		for (var i = 0; i < lstEducation.length; i++) {
			var educationId = lstEducation[i].educationId;
			var education = lstEducation[i].education;
			$('#inp_education').append($("<option> </option>").val(educationId).html(education));
		}
	}
	setRolesCmb() {
		$('#inp_role').empty();
		$('#inp_role').prepend($("<option> </option>").val(0).html('Role'));
		for (var i = 0; i < lstRole.length; i++) {
			var roleId = lstRole[i].roleId;
			var role = lstRole[i].role;
			$('#inp_role').append($("<option> </option>").val(roleId).html(role));
		}
	}
	setGradesCmb() {
		$('#inp_grade').empty();
		// console .log('lstGrades' + lstGrades);
		$('#inp_grade').prepend($("<option> </option>").val(0).html('Grade'));
		for (var i = 0; i < lstGrades.length; i++) {
			var gradeId = lstGrades[i].gradeId;
			var grade = lstGrades[i].grade;
			$('#inp_grade').append($("<option> </option>").val(gradeId).html(grade));
		}
	}

	maintainTeacher = () => {
		// console .log("this.state.teacherId = "+this.state.teacherId);
		if (this.state.teacherId == 0) {
			this.addTeacher();
		} else {
			this.editTeacher();
		}
	}
	addTeacher = () => {
		var teacherObj = {};
		var teacher = $('#inp_teacher').val();
		var stateId = parseInt($('#inp_state').find('option:selected').val());
		var cityId = parseInt($('#inp_city').find('option:selected').val());
		var mobileNo = $('#inp_phoneNo').val();
		var emergencyContactNo = $('#inp_emergencyContact').val();
		var email = $('#inp_email').val();
		var dob = $('#inp_dob').val();
		var doj = $('#inp_doj').val();
		var married = $('#inp_married').attr('checked');
		var blocked = $('#inp_blocked').attr('checked');
		var gender = $('#inp_gender').find('option:selected').val();
		// console .log("gender = "+gender);
		var address1 = !$('#inp_address1').val() ? null : $('#inp_address1').val();
		var address2 = !$('#inp_address2').val() ? null : $('#inp_address2').val();
		var experience = !$('#inp_experience').val() ? null : $('#inp_experience').val();
		var mainRoleId = $('#inp_role').find('option:selected').val();
		var mainGradeId = $('#inp_grade').find('option:selected').val();



		// console .log("teacher: "+teacher);

		/*let maxTeacherId = lstTeacher => (
			Math.max(lstTeacher.map(teacher => teacher.teacherId))
		)
		let newTeacherId = maxTeacherId+1;*/
		teacherObj = {
			'teacherId': 0,
			'teacher': teacher,
			'stateId': stateId,
			'mobileNo': mobileNo,
			'email': email,
			'cityId': cityId,
			'dob': dob,
			'doj': doj,
			'married': married == 1 ? true : false,
			'blocked': blocked == 1 ? true : false,
			'gender': gender,
			'address1': address1,
			'address2': address2,
			'experience': experience,
			'emergencyContactNo': emergencyContactNo,

		};
		// console .log("teacherObj: "+teacherObj);
		this.setState({ loader: 'block', opacity: 0.5 });
		//lstTeacher.push(teacherObj);
		//this.setTeacherTable();
		// maxTeacherId = lstTeacher => (
		// 	Math.max(lstTeacher.map(teacher => teacher.teacherId))
		// )
		//this.setTeacherForm(maxTeacherId);
		//this.highlightRow('teacherTableBody', maxTeacherId);
		//this.setState({flg : false})
		this.setState({ loader: 'none', opacity: 1 });
		if (teacher === '') {
			toast.warning('Please fill teacher');
			document.getElementById('inp_teacher').focus();
			return false;
		}

		if (teacher.length > MAX_TEACHER_LENGTH) {
			toast.warning('Teacher length must not exceed ' + MAX_TEACHER_LENGTH + ' characters');
			document.getElementById('inp_teacher').focus();
			return false;
		}
		axios.post(httpURL + '/sms/teacher/save',
			queryString.stringify({ 'teacher': JSON.stringify(teacherObj), screenId, mainGradeId, mainRoleId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				teacherObj.teacherId = response.data.teacherId;
				lstTeacher.push(teacherObj);
				// this.setTeacherTable();
				this.setTeacherForm(response.data.teacherId);
				//this.setState({flg : false})

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});
	}



	addTD(row, val, classname, pColSpan) {
		var cell = document.createElement('td');
		if (val != '-') {
			cell.className = classname;
		} else {
			cell.className = 'text-center';
		}
		var text = document.createTextNode(val);
		cell.colSpan = pColSpan;
		cell.style.border = 'none';
		cell.append(text);
		row.append(cell);
	}

	addTD(row, val, classname) {
		var cell = document.createElement('td');
		if (val != '-') {
			cell.className = classname;
		} else {
			cell.className = 'text-center';
		}
		var text = document.createTextNode(val);

		cell.append(text);
		row.append(cell);
	}
	getTeacherClassList = (schoolTeacher) => {
		var teacherId = schoolTeacher.teacherId;

		this.setState({ loader: 'block', opacity: '0.5' });
		axios({
			url: httpURL + '/sms/teacher/classlist',
			method: "POST",
			data: queryString.stringify({ 'teacherId': teacherId, screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);

				this.setClassTable(response.data.lstTeacherClass);

			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});
	}
	getTeacherEducationList = (schoolTeacher) => {
		this.setState({ loader: 'block', opacity: '0.5' });
		var teacherId = schoolTeacher.teacherId;

		axios.post(httpURL + '/sms/teacher/educationlist',
			queryString.stringify({ 'teacherId': JSON.stringify(teacherId), screenId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				// console .log(response.data.lstTeacherEducation);
				this.setEducationTable(response.data.lstTeacherEducation);

			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});

	}
	deleteEducation = (e) => {

		var teacherEducation = {
			"teacherEducationId": $(e.target).closest('tr').find("td:eq(0)").text(),
		}
		axios({
			url: httpURL + '/sms/teacher/deleteeducation',
			method: "POST",
			params: { 'teacherEducation': JSON.stringify(teacherEducation), screenId },
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				var schoolTeacher = {
					'schoolId': schoolId,
					'teacherId': parseInt(localStorage.getItem('teacherId'))
				};
				this.getTeacherEducationList(schoolTeacher);
				// this.setState({
				// 	lstTeacherEducation:response.data.lstTeacherEducation,
				// });
				//$(event.target).closest('tr').click();
				//$(event.target).closest('tr').remove();//var docs = this.getTeacherDocById(teacherId);
				//this.setEducationTable(this.state.lstTeacherEducation);
			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});

	}
	deleteDoc = (event) => {
		var teacherDocument = {
			"teacherDocId": $(event.target).closest('tr').find("td:eq(1)").text(),
		}

		axios({
			url: httpURL + '/sms/teacher/deletedoc',
			method: "POST",
			params: { 'teacherDocument': JSON.stringify(teacherDocument), screenId },
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				var schoolTeacher = {
					'schoolId': schoolId,
					'teacherId': parseInt(localStorage.getItem('teacherId'))
				};
				this.getTeacherDocsList(schoolTeacher);

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});
		// toast.success("Service not available");
		// $(event.target).closest('tr').click();
		// $(event.target).closest('tr').remove();
	}
	deleteClass = (event) => {
		var teacherClass = {
			"teacherClassId": $(event.target).closest('tr').find("td:eq(0)").text(),
		}

		axios({
			url: httpURL + '/sms/teacher/deleteclass',
			method: "POST",
			params: { 'teacherClass': JSON.stringify(teacherClass), screenId },
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				var schoolTeacher = {
					'schoolId': schoolId,
					'teacherId': parseInt(localStorage.getItem('teacherId'))
				};
				this.getTeacherClassList(schoolTeacher);

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});
		// toast.success("Service not available");
		// $(event.target).closest('tr').click();
		// $(event.target).closest('tr').remove();
	}
	deleteSubject = (event) => {
		var teacherSubject = {
			"teacherClassSubjectId": $(event.target).closest('tr').find("td:eq(0)").text(),
		}

		axios({
			url: httpURL + '/sms/teacher/deletesubject',
			method: "POST",
			params: { 'teacherSubject': JSON.stringify(teacherSubject), screenId },
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				$('#classTableBody tr.selected').click();

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});

		// toast.success("Service not available");
		// $(event.target).closest('tr').click();
		// $(event.target).closest('tr').remove();
	}
	setEducationPopup = (event) => {
		$(event.target).closest('tr').click();
		this.setState({
			selEducation: $('#educationTableBody tr.selected').find("td:eq(1)").text()
		});

		var selEducation = this.state.selEducation;
		// console .log("selEducation: "+selEducation);
		this.setEducationSlider();
		this.setTeacherEducation(selEducation);

		this.openEducationPopup();
	}
	setDocPopup = (event) => {
		$(event.target).closest('tr').click();
		this.setState({
			selDocTypeId: parseInt($('#docTableBody tr.selected').find("td:eq(0)").text()),
			selDoc: $('#docTableBody tr.selected').find("td:eq(2)").text()
		});
		var selDocTypeId = this.state.selDocTypeId;
		var selDoc = this.state.selDoc;
		this.setDocSlider();
		this.setTeacherDocs(selDocTypeId, selDoc);

		this.openDocPopup();
	}

	setSubjectPopup = (event) => {
		$(event.target).closest('tr').click();
		// console .log($(event.target).closest('tr').find("td:eq(1)").text());
		this.setState({
			selSubjectId: parseInt($('#subjectTableBody tr.selected').find("td:eq(1)").text()),
			selSubject: $('#subjectTableBody tr.selected').find("td:eq(2)").text()
		});
		var selSubjectId = this.state.selSubjectId;
		//var selSubject= this.state.selSubject;
		this.setSubjectSlider();
		this.setTeacherSubject(selSubjectId);

		this.openSubjectPopup();
	}
	setClassPopup = (event) => {
		$(event.target).closest('tr').click();
		this.setState({
			selClassId: parseInt($('#classTableBody tr.selected').find("td:eq(1)").text()),
			selClass: $('#classTableBody tr.selected').find("td:eq(2)").text()
		});
		var selClassId = this.state.selClassId;
		var selClass = this.state.selClass;
		this.setClassSlider();
		this.setTeacherClasses(selClassId);
		this.openClassPopup();
	}
	onClickAddDocIcon = () => {
		this.setDocSlider();
		this.openDocPopup();
		$('body').addClass('showPopup');
	}
	onClickAddEducationIcon = () => {
		this.setEducationSlider();
		this.openEducationPopup();
		$('body').addClass('showPopup');
	}

	onClickAddClassIcon = () => {
		this.setClassSlider();
		this.openClassPopup();
		var lstTeacherSubject = [];
		this.setSubjectTable(lstTeacherSubject);
		$('#classTableBody tr').removeClass('selected');
		$('body').addClass('showPopup');
	}

	onClickAddSubjectIcon = () => {
		this.setSubjectSlider();
		this.openSubjectPopup();
		$('body').addClass('showPopup');
	}

	openEducationPopup = () => {
		$('.cd-panel').addClass('is-visible');
		$('#educationSlider').show();
		$('#educationSlider').siblings('#educationSlider').hide();
		$('.mainContainer').addClass('addOpacity');
		$('.educationPopup').css("display", 'block');
	}
	openDocPopup = () => {
		$('.cd-panel').addClass('is-visible');
		$('#docSlider').show();
		$('#docSlider').siblings('#classSlider').hide();
		$('.mainContainer').addClass('addOpacity');
		$('.docPopup').css("display", 'block');
	}

	openClassPopup = () => {
		$('.cd-panel').addClass('is-visible');
		$('#classSlider').show();
		$('#classSlider').siblings('#docSlider,#subjectSlider').hide();
		$('.mainContainer').addClass('addOpacity');
		$('.classPopup').css("display", 'block');
	}
	openSubjectPopup = () => {
		$('.cd-panel').addClass('is-visible');
		$('#subjectSlider').show();
		$('#subjectSlider').siblings('#docSlider,#classSlider').hide();
		$('.mainContainer').addClass('addOpacity');
		$('.subjectPopup').css("display", 'block');
	}
	setTeacherDocs(pDocTypeId, pDoc) {
		$('#inp_docType').val(pDocTypeId);
		$('#inp_doc').val(pDoc);
	}
	setDocSlider = () => {
		//lstTeacherDocs = getTeacherDocsById(pTeacherId);
		$('#inp_doc').val("");
		this.setDocTypeCmb();


	}
	setEducationSlider = () => {
		//lstTeacherDocs = getTeacherDocsById(pTeacherId);
		// console .log(lstEducation);
		$('#inp_education').val("");
		this.setEducationCmb();


	}
	setClassSlider = () => {
		//lstTeacherDocs = getTeacherDocsById(pTeacherId);
		this.setClassCmb();
		//$('#inp_class').val("");

	}
	setSubjectSlider = () => {
		//lstTeacherDocs = getTeacherDocsById(pTeacherId);
		this.setSubjectCmb();
		//$('#inp_subject').val("");

	}
	setTeacherClasses(pClassId) {
		$('#inp_class').val(pClassId);
		//$('#inp_class').val(pClass);
	}
	setTeacherEducation(pEducationId) {
		$('#inp_education').val(pEducationId);
		//$('#inp_class').val(pClass);
	}
	setTeacherSubject(pSubjectId) {
		$('#inp_subject').val(pSubjectId);
		//$('#inp_class').val(pClass);
	}

	setDocTable = (lstTeacherDoc) => {
		$('#docTableBody tr').remove();
		var table = document.getElementById('docTable');
		var tbody = document.getElementById('docTableBody');

		for (var i = 0; i < lstTeacherDoc.length; i++) {
			var teacherDocId = lstTeacherDoc[i].teacherDocId;
			var typeId = lstTeacherDoc[i].docTypeId;
			var id = lstTeacherDoc[i].docTypeId;
			var docType = lstTeacherDoc[i].docType;
			var row = document.createElement('tr');
			row.id = id;
			row.onclick = (e) => {
				$(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');

			}
			this.addTD(row, id, 'no-display');
			this.addTD(row, teacherDocId, 'no-display');
			this.addTD(row, typeId, 'no-display');
			this.addTD(row, docType, '');
			this.addDocTDActions(row, 'text-center');
			tbody.append(row);
		}
		table.append(tbody);
	}
	getClassById = (pClassId) => {
		// console .log(lstClass);
		for (var i = 0; i < lstClass.length; i++) {
			var obj = lstClass[i];
			if (pClassId == obj.classId)
				return obj.className;
		}
		return -1;
	}
	getSubjectById = (pSubjectId) => {
		for (var i = 0; i < lstSubject.length; i++) {
			var obj = lstSubject[i];
			if (pSubjectId == obj.subjectId)
				return obj.subject;
		}
		return -1;
	}
	async setClassTable(lstClass) {
		$('#classTableBody tr').remove();
		var table = document.getElementById('classTable');
		var tbody = document.getElementById('classTableBody');
		for (var i = 0; i < lstClass.length; i++) {
			var teacherClassId = lstClass[i].teacherClassId;
			var classId = lstClass[i].classId;
			var className = lstClass[i].className;
			var row = document.createElement('tr');
			row.id = teacherClassId;
			row.onclick = (e) => {
				$(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
				var schoolTeacherClass = {
					'teacherId': parseInt(localStorage.getItem('teacherId')),
					'classId': parseInt($(e.target).closest('tr').find("td:eq(1)").text())
				}
				this.getTeacherSubjectList(schoolTeacherClass);
			}
			this.addTD(row, teacherClassId, 'no-display');
			this.addTD(row, classId, 'no-display');
			this.addTD(row, className, '');

			this.addClassTDActions(row, 'text-center');
			tbody.append(row);
		}
		table.append(tbody);
	}
	getTeacherSubjectList = (schoolTeacherClass) => {
		// var schoolTeacherClass = {
		// 	'teacherId': parseInt(localStorage.getItem('teacherId')),
		// 	'classId': $('#classTableBody tr.selected').find('td:eq(1)').text(),
		// }
		// console .log(schoolTeacherClass);

		axios.post(httpURL + '/sms/teacher/subjectlist',
			queryString.stringify({ 'schoolTeacherClass': JSON.stringify(schoolTeacherClass), screenId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.setState({
					lstTeacherSubject: response.data.lstSubject,
				})
				lstTeacherSubject = response.data.lstSubject;

				this.setSubjectTable(lstTeacherSubject);
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});

	}
	async setEducationTable(lstTeacherEducation) {
		$('#educationTableBody tr').remove();
		var table = document.getElementById('educationTable');
		var tbody = document.getElementById('educationTableBody');

		for (var i = 0; i < lstTeacherEducation.length; i++) {
			var teacherEducationId = lstTeacherEducation[i].teacherEducationId;
			var educationId = lstTeacherEducation[i].educationId;
			var education = lstTeacherEducation[i].education;

			var row = document.createElement('tr');
			row.id = educationId;
			row.onclick = (e) => {
				$(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
				this.setState({
					educationFlg: false,
				})
			}
			this.addTD(row, teacherEducationId, 'no-display');
			this.addTD(row, educationId, 'no-display');
			this.addTD(row, education, '');

			this.addEducationTDActions(row, 'text-center');
			tbody.append(row);
		}
		table.append(tbody);
	}
	async setSubjectTable(lstSubject) {
		$('#subjectTableBody tr').remove();
		var table = document.getElementById('subjectTable');
		var tbody = document.getElementById('subjectTableBody');

		for (var i = 0; i < lstSubject.length; i++) {
			var teacherClassSubjectId = lstSubject[i].teacherClassSubjectId;
			var subjectId = lstSubject[i].subjectId;
			// console .log("subjectId:"+subjectId);
			var subject = await this.getSubjectById(subjectId);

			var row = document.createElement('tr');
			row.id = subjectId;
			row.onclick = (e) => {
				$(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
				this.setState({
					subjectFlg: false,
				})
			}
			this.addTD(row, teacherClassSubjectId, 'no-display');
			this.addTD(row, subjectId, 'no-display');
			this.addTD(row, subject, '');

			this.addSubjectTDActions(row, 'text-center');
			tbody.append(row);
		}
		table.append(tbody);
	}

	addDocTDActions(row, classname) {
		var cell = document.createElement('td');
		cell.className = classname;
		var img1 = document.createElement("img");
		img1.src = require("common/images/edit.png");
		img1.width = 15;
		img1.addEventListener('click', (e) => this.setDocPopup(e));
		var img2 = document.createElement("img");
		img2.src = require("common/images/bin.png");
		img2.width = 15;
		img2.addEventListener('click', (e) => this.deleteDoc(e));
		cell.append(img1);
		cell.append(img2);
		row.append(cell);
	}

	addClassTDActions(row, classname) {
		var cell = document.createElement('td');
		cell.className = classname;
		var img1 = document.createElement("img");
		img1.src = require("common/images/edit.png");
		img1.width = 15;
		img1.addEventListener('click', (e) => this.setClassPopup(e));
		var img2 = document.createElement("img");
		img2.src = require("common/images/bin.png");
		img2.width = 15;
		img2.addEventListener('click', (e) => this.deleteClass(e));
		cell.append(img1);
		cell.append(img2);
		row.append(cell);
	}
	addEducationTDActions(row, classname) {
		var cell = document.createElement('td');
		cell.className = classname;
		var img1 = document.createElement("img");
		img1.src = require("common/images/edit.png");
		img1.width = 15;
		img1.addEventListener('click', (e) => this.setEducationPopup(e));
		var img2 = document.createElement("img");
		img2.src = require("common/images/bin.png");
		img2.width = 15;
		img2.addEventListener('click', (e) => this.deleteEducation(e));
		cell.append(img1);
		cell.append(img2);
		row.append(cell);
	}
	addSubjectTDActions(row, classname) {
		var cell = document.createElement('td');
		cell.className = classname;
		var img1 = document.createElement("img");
		img1.src = require("common/images/edit.png");
		img1.width = 15;
		img1.addEventListener('click', (e) => this.setSubjectPopup(e));
		var img2 = document.createElement("img");
		img2.src = require("common/images/bin.png");
		img2.width = 15;
		img2.addEventListener('click', (e) => this.deleteSubject(e));
		cell.append(img1);
		cell.append(img2);
		row.append(cell);
	}
	setDocTypeCmb() {
		$('#inp_docType').empty();
		$('#inp_docType').prepend($("<option> </option>").val('Document Type').html('Document Type'));
		for (var i = 0; i < lstDocType.length; i++) {
			var docTypeId = lstDocType[i].docTypeId;
			var docType = lstDocType[i].docType;
			$('#inp_docType').append($("<option> </option>").val(docTypeId).html(docType));
		}
	}
	deleteEducation() {
	}
	setClassCmb() {
		$('#inp_class').empty();
		$('#inp_class').prepend($("<option> </option>").val('Class').html('Class'));
		for (var i = 0; i < lstClass.length; i++) {
			var classId = lstClass[i].classId;
			var classNm = lstClass[i].className;
			$('#inp_class').append($("<option> </option>").val(classId).html(classNm));
		}
	}
	setSubjectCmb() {
		$('#inp_subject').empty();
		$('#inp_subject').prepend($("<option> </option>").val('Subject').html('Subject'));
		for (var i = 0; i < lstSubject.length; i++) {
			var subjectId = lstSubject[i].subjectId;
			var subject = lstSubject[i].subject;
			$('#inp_subject').append($("<option> </option>").val(subjectId).html(subject));
		}
	}

	hideEducationSlider() {
		$('.cd-panel').removeClass('is-visible');
		$('.mainContainer').removeClass('addOpacity');
		$('.educationPopup').hide();
		$('body').removeClass('showPopup');
	}
	hideDocSlider() {
		$('.cd-panel').removeClass('is-visible');
		$('.mainContainer').removeClass('addOpacity');
		$('.docPopup').hide();
		$('body').removeClass('showPopup');
	}

	hideClassSlider() {
		$('.cd-panel').removeClass('is-visible');
		$('.mainContainer').removeClass('addOpacity');
		$('.classPopup').hide();
		$('body').removeClass('showPopup');
	}
	hideSubjectSlider() {
		$('.cd-panel').removeClass('is-visible');
		$('.mainContainer').removeClass('addOpacity');
		$('.subjectPopup').hide();
		$('body').removeClass('showPopup');
	}
	triggerUploadFile() {
		$('#uploadFile').trigger('click');
	}
	uploadDoc = () => {
		// toast.success("Service not available")
		if (document.getElementById("uploadFile").value != "") {
			var ext = $('#uploadFile').val().split('.').pop().toLowerCase();  	//input type file value

			if ($.inArray(ext, ['pdf', 'jpg', 'png']) === -1) {
				toast.warning("ext: " + ext);
				toast.warning('Invalid File Uploaded. Please, Upload Again!');
			}
			else {
				var file = document.getElementById("uploadFile");					//input type file value
				var file_data = file.files[0];									//No need Extra
				var file_data = $("#uploadFile").prop("files")[0];					//input type file value
				var form_data = new FormData();
				form_data.append("file", file_data);
				form_data.append("teacherDocId", 0);
				form_data.append("docPath", $('#inp_doc').val());
				form_data.append("docTypeId", $('#inp_docType').find("option:selected").val());
				form_data.append("teacherId", this.state.teacherId);
				form_data.append("schoolId", schoolId);
				form_data.append('screenId', screenId);
				this.setState({ loader: 'block', opacity: 0.5 });
				axios({
					url: httpURL + "/sms/teacher/savedoc",
					method: "POST",
					data: form_data,
					config: {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					},
					headers: { 'tokenid': this.state.tokenid },
					withCredentials: true,
				}).then((response) => {
					if (response.data.SvcStatus === "Success") {
						$('#uploadFile').val('');
						toast.success(response.data.SvcMsg);
						$('.cd-panel').removeClass('is-visible');
						$('.mainContainer').removeClass('addOpacity');
						$('.docPopup').hide();
						var schoolTeacher = {
							'teacherId': parseInt(localStorage.getItem('teacherId'))
						};
						this.getTeacherDocsList(schoolTeacher);
						this.hideDocSlider();
					}
					else {
						toast.error(response.data.SvcMsg);
						$('#uploadFile').val('');
					}
					this.setState({ loader: 'none', opacity: 1 });
				}).catch((e) => {
					this.setState({ loader: 'none', opacity: 1 });
					toast.error("Service Failed: " + e);
				});
			}
		}
	}
	getTeacherDocsList = (schoolTeacher) => {
		this.setState({ loader: 'block', opacity: '0.5' });
		var teacherId = schoolTeacher.teacherId;
		axios({
			url: httpURL + '/sms/teacher/docslist',
			method: "POST",
			data: queryString.stringify({ 'teacherId': JSON.stringify(teacherId), screenId }),
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				this.setDocTable(response.data.lstTeacherDoc);
			}
			else {
				toast.error(response.data.SvcMsg);
				if (response.data.SvcMsg == 'You are not Logged In') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
				else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
					setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
					return false;
				}
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((error) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error("Service failed " + error);
		});
	}
	saveClass = () => {
		//toast.success("Service not available");

		var teacherClassObj = {};
		var teacher = $('#inp_teacher').val();
		var classId = parseInt($('#inp_class').find('option:selected').val());

		if (teacher === '') {
			toast.warning('Please fill teacher');
			document.getElementById('inp_teacher').focus();
			return false;
		}

		if (classId == 0) {
			toast.warning('Please select class');
			document.getElementById('inp_cls').focus();
			return false;
		}

		teacherClassObj = {
			'teacherId': parseInt(localStorage.getItem('teacherId')),
			'classId': classId,
			'teacherClassId': $('#classTableBody tr.selected').find('td:eq(0)').text(),
		};
		// console .log(teacherClassObj);
		this.setState({ loader: 'block', opacity: 0.5 });
		//this.setState({flg : false})
		axios.post(httpURL + '/sms/teacher/saveclass',
			queryString.stringify({ 'teacherClass': JSON.stringify(teacherClassObj), screenId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				this.hideClassSlider();

				var schoolTeacher = {
					'schoolId': schoolId,
					'teacherId': parseInt(localStorage.getItem('teacherId'))
				};
				this.getTeacherClassList(schoolTeacher);

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});

	}

	saveEducation = () => {
		//toast.success("Service not available");

		var teacherEducationObj = {};
		var teacherEducationId = 0;
		var teacher = $('#inp_teacher').val();
		var educationId = parseInt($('#inp_education').find('option:selected').val());

		if (teacher === '') {
			toast.warning('Please select teacher');
			document.getElementById('inp_teacher').focus();
			return false;
		}

		if (educationId == 0) {
			toast.warning('Please select education');
			document.getElementById('inp_education').focus();
			return false;
		}

		if (this.state.educationFlg) {
			teacherEducationId = 0;
		}
		else {
			teacherEducationId = $('#educationTableBody tr.selected').find('td:eq(0)').text();
		}

		teacherEducationObj = {
			'educationId': educationId,
			'teacherId': parseInt(localStorage.getItem('teacherId')),
			'teacherEducationId': teacherEducationId,
		};
		// console .log(teacherEducationObj);
		this.saveTeacherEducation(teacherEducationObj);
	}

	editTeacher = () => {
		var teacherObj = {};
		// var teacherId 	= $('#teacherTableBody tr.selected').find(COL_STAFFID).text();
		// var teacher	    = $('#inp_teacher').val();
		// var cityId	    = parseInt($('#inp_city').find('option:selected').val());
		// var city    	= $('#inp_city').find('option:selected').text();
		// var stateId	    = $('#inp_state').find('option:selected').val();
		// var state    	= $('#inp_state').find('option:selected').text();
		// var deptHeadId	= parseInt($('#inp_dept_head').find('option:selected').val());
		// var deptHead    = $('#inp_dept_head').find('option:selected').text();

		var teacherId = parseInt(localStorage.getItem('teacherId'));
		var teacher = $('#inp_teacher').val();
		var cityId = $('#inp_city').find('option:selected').val();
		var mobileNo = $('#inp_phoneNo').val();
		var stateId = $('#inp_state').find('option:selected').val();
		var email = $('#inp_email').val();
		var dob = $('#inp_dob').val();
		var doj = $('#inp_doj').val();
		var married = $('#inp_married').is(':checked');
		var blocked = $('#inp_blocked').is(':checked');
		var gender = $('#inp_gender').find('option:selected').val();
		var address1 = $('#inp_address1').val();
		var address2 = $('#inp_address2').val();
		var experience = $('#inp_experience').val();
		var emergencyContactNo = $('#inp_emergencyContact').val();
		var mainRoleId = $('#inp_role').find('option:selected').val();
		var mainGradeId = $('#inp_grade').find('option:selected').val();

		teacherObj = {
			'teacherId': teacherId,
			'teacher': teacher,
			'stateId': stateId == 'State' ? 0 : stateId,
			'mobileNo': mobileNo,
			'email': email,
			'cityId': cityId == 'City' ? 0 : cityId,
			'dob': !dob ? null : dob,
			'doj': !doj ? null : doj,
			'married': married,
			'blocked': blocked,
			'gender': gender,
			'address1': address1,
			'address2': address2,
			'experience': experience,
			'emergencyContactNo': emergencyContactNo
		};
		this.setState({ loader: 'block', opacity: 0.5 });
		// var teacherIdx = this.getIdxTeacher(teacherId);
		// lstTeacher.splice(teacherIdx,1,teacherObj);
		// this.setTeacherForm(teacherId);
		// this.setTeacherTable();
		// this.highlightRow('teacherTableBody', teacherId);
		this.setState({ loader: 'none', opacity: 1 });
		// console .log("teacherObj = "+JSON.stringify(teacherObj));

		axios.post(httpURL + '/sms/teacher/save',
			queryString.stringify({ 'teacher': JSON.stringify(teacherObj), mainGradeId, mainRoleId, screenId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				var schoolTeacher = {
					'teacherId': teacherId,
					'schoolId': schoolId
				}
				this.getTeacher(schoolTeacher);
				//var teacherIdx = this.getIdxTeacher(teacherId);
				//lstTeacher.splice(teacherIdx,1,teacherObj);
				// this.setTeacherForm(teacherId);
				//this.setTeacherTable();
				// this.highlightRow('teacherTableBody', teacherId);

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});

	}
	saveSubject = () => {

		//toast.success("Service not available");

		var teacherSubject = {};
		var teacher = $('#inp_teacher').val();
		var subjectId = parseInt($('#inp_subject').find('option:selected').val());
		var teacherClassSubjectId = 0;

		if (teacher === '') {
			toast.warning('Please select teacher');
			document.getElementById('inp_teacher').focus();
			return false;
		}

		if (subjectId == 0) {
			toast.warning('Please select subject');
			document.getElementById('inp_subject').focus();
			return false;
		}
		if (this.state.subjectFlg) {
			teacherClassSubjectId = 0;
		}
		else {
			teacherClassSubjectId = $('#subjectTableBody tr.selected').attr('id');
		}


		var teacherSubject = {
			'teacherClassSubjectId': teacherClassSubjectId,
			'subjectId': subjectId,
			'teacherClassId': $('#classTableBody').find('tr.selected').attr('id'),

		};
		// console .log(teacherSubject);
		this.saveTeacherSubject(teacherSubject);
	}
	// toast.success("Service not available");
	// this.setState({loader:'block', opacity:0.5});
	//this.setState({flg : false})
	saveTeacherSubject = (teacherClassSubject) => {
		this.setState({ loader: 'none', opacity: 1 });

		axios.post(httpURL + '/sms/teacher/savesubject',
			queryString.stringify({ 'teacherSubject': JSON.stringify(teacherClassSubject), screenId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				this.hideSubjectSlider();
				$('.cd-panel').removeClass('is-visible');
				$('.mainContainer').removeClass('addOpacity');
				$('.subjectPopup').hide();
				toast.success(response.data.SvcMsg);
				$('#classTableBody tr.selected').click();
				// var schoolTeacherClass = {
				// 'schoolId' : schoolId,
				// 'teacherId': teacherId,
				// 'classId'	:$('#classTableBody').find('tr:selected').find('td:eq(1)').text(),
				// }
				// this.getTeacherSubjectList(schoolTeacherClass);

				//this.setState({flg : false})

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});

	}

	getEducationById = (pEducationId) => {
		for (var i = 0; i < lstEducation.length; i++) {
			var obj = lstEducation[i];
			if (pEducationId == obj.educationId)
				return obj.education;
		}
		return -1;
	}
	saveTeacherEducation = (teacherEducationObj) => {

		// this.setState({loader:'block', opacity:0.5});
		//this.setState({flg : false})
		this.setState({ loader: 'none', opacity: 1 });

		axios.post(httpURL + '/sms/teacher/saveeducation',
			queryString.stringify({ 'teacherEducation': JSON.stringify(teacherEducationObj), screenId }), {
			headers: { 'tokenid': this.state.tokenid },
			withCredentials: true,
		}).then((response) => {
			if (response.data.SvcStatus === 'Success') {
				toast.success(response.data.SvcMsg);
				var schoolTeacher = {
					'schoolId': schoolId,
					'teacherId': parseInt(localStorage.getItem('teacherId'))
				};
				this.getTeacherEducationList(schoolTeacher);
				this.hideEducationSlider();

			} else {
				toast.error(response.data.SvcMsg);
			}
			this.setState({ loader: 'none', opacity: 1 });
		}).catch((e) => {
			this.setState({ loader: 'none', opacity: 1 });
			toast.error('Service failed: ' + e);
		});

	}
	getWingId = (wingId) => {
		// console .log("wingId" + wingId);
	}
	render() {
		httpURL = lstModules['teacher'].url + ":" + lstModules['teacher'].port;
		return (
			<div style={{ height: '100%' }}>
				<Header wingFromChild={this.getWingId} />
				<ToastContainer autoClose={3000} hideProgressBar />
				<div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
					<img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
				</div>

				<div className="mainContainer" style={{ opacity: this.state.opacity }}>
					<div className="container-fluid">
						<div className="col-sm-12 col-centered">
							<div className="col-sm-12 col-centered" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
								<div className="col-sm-12 " >
									<form id="teacherAddForm" className="col-sm-12 form-horizontal">
										<div className="col-sm-6">
											<div className="form-group">
												<label className="control-label col-sm-5">Teacher:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" placeholder="Teacher" id="inp_teacher" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Gender:</label>
												<div className="col-sm-6">
													<select className="form-control" id="inp_gender" ></select>
												</div>
											</div>

											<div className="form-group">
												<label className="control-label col-sm-5">Date of birth:</label>
												<div className="col-sm-6">
													<DatePicker id="inp_dob"
														className='form-control'
														placeholderText="Date of birth"
														dateFormat="dd-MM-yyyy"
														dropdownMode="select"
														selected={this.state.dob}
														onChange={(date) => { this.setState({ dob: date }) }}
														autoComplete='off'
														showMonthDropdown
														showYearDropdown
														adjustDateOnChange
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Date of joining:</label>
												<div className="col-sm-6">
													<DatePicker id="inp_doj"
														className='form-control'
														placeholderText="Date of joining"
														dateFormat="dd-MM-yyyy"
														dropdownMode="select"
														selected={this.state.doj}
														onChange={(date) => { this.setState({ doj: date }) }}
														autoComplete='off'
														showMonthDropdown
														showYearDropdown
														adjustDateOnChange
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Role:</label>
												<div className="col-sm-6">
													<select className="form-control" id="inp_role" ></select>
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Grade:</label>
												<div className="col-sm-6">
													<select className="form-control" id="inp_grade" ></select>
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Experience:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" id="inp_experience" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Married:</label>
												<div className="col-sm-6">
													<input type="checkbox" className="form-control" id="inp_married" />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form-group">
												<label className="control-label col-sm-5">Address 1:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" placeholder="Address 1" id="inp_address1" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Address 2:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" placeholder="Address 2" id="inp_address2" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">State:</label>
												<div className="col-sm-6">
													<select className="form-control" id="inp_state" onChange={this.getCityList}></select>
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">City:</label>
												<div className="col-sm-6">
													<select className="form-control" id="inp_city"></select>
												</div>
											</div>

											<div className="form-group">
												<label className="control-label col-sm-5">E-Mail Id:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" id="inp_email" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Phone No:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" id="inp_phoneNo" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Emergency Contact:</label>
												<div className="col-sm-6">
													<input type="text" className="form-control" id="inp_emergencyContact" />
												</div>
											</div>
											<div className="form-group">
												<label className="control-label col-sm-5">Blocked:</label>
												<div className="col-sm-6">
													<input type="checkbox" className="form-control" id="inp_blocked" />
												</div>
											</div>
										</div>
										<div className="form-group" style={{ marginTop: 50 }}>
											<div className="col-sm-11 text-right">
												<button type="button" onClick={this.maintainTeacher} className="btn btn-info">Save</button>
											</div>
										</div>
										<div className="mainContainer">
											<div className="col-sm-6">
												<div className="col-sm-6 form-horizontal ed-formWrapper" style={{ padding: '20px', paddingBottom: 0, background: '#fff', borderRadius: '10px', marginTop: '15px', border: '2px solid #e4e4e4' }}>
													<div className="col-sm-10 padding-remove" >
														<table className="tableLayout1" style={{ width: this.state.width1 }}>
															<thead>
																<tr>
																	<th style={{ width: 50, display: 'none' }}>Teacher Education Id</th>
																	<th style={{ width: 100, display: 'none' }}>Education Type</th>
																	<th style={{ width: '50%' }}>Education</th>
																	<th style={{ width: '50%' }}>Action</th>
																</tr>
															</thead>
														</table>
														<div className="educationTableDiv col-sm-12 col-xs-11 padding-remove">
															<table id="educationTable" className="tableLayout">
																<colgroup>
																	<col width="50%" />
																	<col width="50%" />
																</colgroup>
																<tbody id="educationTableBody">
																</tbody>
															</table>
														</div>
													</div>
													<div className="col-sm-2 col-xs-2">
														<span id="addEducation" title="Add Education" onClick={this.onClickAddEducationIcon} className="addIcon glyphicon glyphicon-plus-sign"></span>
													</div>
												</div>
												<div className="col-sm-6 form-horizontal doc-formWrapper" style={{ padding: '20px', paddingBottom: 0, background: '#fff', borderRadius: '10px', marginTop: '15px', border: '2px solid #e4e4e4' }}>
													<div className="col-sm-10 padding-remove" >
														<table className="tableLayout1" style={{ width: this.state.width1 }}>
															<thead>
																<tr>
																	<th style={{ width: 50, display: 'none' }}>Teacher Doc Id</th>
																	<th style={{ width: 100, display: 'none' }}>Document Type</th>
																	<th style={{ width: '50%' }}>Document</th>
																	<th style={{ width: '50%' }}>Action</th>
																</tr>
															</thead>
														</table>
														<div className="docTableDiv col-sm-12 col-xs-11 padding-remove">
															<table id="docTable" className="tableLayout">
																<colgroup>
																	<col width="50%" />
																	<col width="50%" />
																</colgroup>
																<tbody id="docTableBody">
																</tbody>
															</table>
														</div>
													</div>
													<div className="col-sm-2 col-xs-2">
														<span id="addDoc" title="Add Document" onClick={this.onClickAddDocIcon} className="addIcon glyphicon glyphicon-plus-sign"></span>
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="col-sm-12 form-horizontal ed-formWrapper" style={{ padding: '20px', paddingBottom: 0, background: '#fff', borderRadius: '10px', marginTop: '15px', border: '2px solid #e4e4e4' }}>
													<div className="col-sm-4 padding-remove" >
														<table className="tableLayout2" style={{ width: this.state.width2 }}>
															<thead>
																<tr>
																	<th style={{ width: 50, display: 'none' }}>Teacher Class Id</th>
																	<th style={{ width: 100, display: 'none' }}> Class Id</th>
																	<th style={{ width: 100 }}>Class</th>
																	<th style={{ width: 50 }}>Action</th>
																</tr>
															</thead>
														</table>
														<div className="classTableDiv col-sm-12 col-xs-11 padding-remove">
															<table id="classTable" className="tableLayout">
																<colgroup>
																	<col width="100" />
																	<col width="50" />
																</colgroup>
																<tbody id="classTableBody">
																</tbody>
															</table>
														</div>
													</div>
													<div className="col-sm-1 col-xs-1">
														<span id="addClass" title="Add Class" onClick={this.onClickAddClassIcon} className="addIcon glyphicon glyphicon-plus-sign"></span>
													</div>

													<div className="col-sm-5 padding-remove">
														<table className="tableLayout3" style={{ width: this.state.width3 }}>
															<thead>
																<tr>
																	<th style={{ width: 50, display: 'none' }}>Teacher Class Id</th>
																	<th style={{ width: 50, display: 'none' }}>Teacher Subject Id</th>
																	<th style={{ width: 100, display: 'none' }}> Subject Id</th>
																	<th style={{ width: 100 }}>Subject</th>
																	<th style={{ width: 50 }}>Action</th>
																</tr>
															</thead>
														</table>
														<div className="subjectTableDiv col-sm-12 col-xs-11 padding-remove">
															<table id="subjectTable" className="tableLayout">
																<colgroup>
																	<col width="100" />
																	<col width="50" />
																</colgroup>
																<tbody id="subjectTableBody">
																</tbody>
															</table>
														</div>
													</div>
													<div className="col-sm-1 col-xs-1">
														<span id="addSubject" title="Add Subject" onClick={this.onClickAddSubjectIcon} className="addIcon glyphicon glyphicon-plus-sign"></span>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="educationPopup" style={{ width: '33%', left: '77%', height: '53%' }}>
					<header style={{ width: '90%' }}>
						<h3 id="classHeader">Add/Edit Education</h3>
						<a href="#0" id="hideEducationSlider" className="cd-panel-close glyphicon glyphicon-remove" onClick={this.hideEducationSlider}>Close</a>
					</header>

					<div style={{ width: '90%' }}>
						<div className="cd-panel-content" style={{ padding: '70px 0% 20px' }}>
							<div id="EducationSlider" className="col-sm-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<div style={{ width: '90%', paddingLeft: 20 }}>
									<form id="educationAddForm" className="form-horizontal">
										<div className="form-group">
											<label htmlFor="inp_class" className="control-label col-sm-6">Education:</label>
											<div className="col-sm-6 padding-remove">
												<select className="form-control" id="inp_education">
												</select>
											</div>
										</div>

										<div className="text-center" style={{ marginTop: 30, marginBottom: 30 }}>
											<button type="button" onClick={this.saveEducation} className="btn btn-info">Save</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="docPopup" style={{ width: '33%', left: '77%', height: '78%' }}>
					<header style={{ width: '90%' }}>
						<h3 id="docHeader">Add/Edit Document</h3>
						<a href="#0" className="cd-panel-close glyphicon glyphicon-remove" onClick={this.hideDocSlider}>Close</a>
					</header>

					<div style={{ width: '90%' }}>
						<div className="cd-panel-content" style={{ padding: '70px 0% 20px' }}>
							<div id="docSlider" className="col-sm-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<div style={{ width: '90%', paddingLeft: 20 }}>
									<form id="docAddForm" className="form-horizontal">
										<div className="form-group nodisplay">
											<label htmlFor="inp_docId" className="control-label col-sm-6">Document Id:</label>
											<div className="col-sm-6 padding-remove">
												<input readOnly type="text" className="form-control" id="inp_docId" placeholder="Document Id" />
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="inp_docTypeId" className="control-label col-sm-6">Document Type:</label>
											<div className="col-sm-6 padding-remove">
												<select className="form-control" id="inp_docType">
												</select>
											</div>
										</div>

										<div className="form-group">
											<label htmlFor="inp_doc" className="control-label col-sm-6">Document:</label>
											<div className="col-sm-6 padding-remove">
												<input type="text" className="col-sm-11 form-control" id="inp_doc" placeholder="Document" />
												<div className="col-sm-1 padding-remove">
													<img src={require("common/images/browse_icon.png")} style={{ height: 20, width: 20, marginLeft: 10, marginTop: 10 }} type="button" className="" onClick={this.triggerUploadFile} id="uploadPOStatus" />
													<input type="file" id="uploadFile" style={{ display: 'none' }} />
												</div>
											</div>

										</div>
										<div className="text-center" style={{ marginTop: 30, marginBottom: 30 }}>
											<button type="button" onClick={this.uploadDoc} className="btn btn-info">Save</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="classPopup" style={{ width: '33%', left: '77%', height: '53%' }}>
					<header style={{ width: '90%' }}>
						<h3 id="classHeader">Add/Edit Class</h3>
						<a href="#0" className="cd-panel-close glyphicon glyphicon-remove" onClick={this.hideClassSlider}>Close</a>
					</header>

					<div style={{ width: '90%' }}>
						<div className="cd-panel-content" style={{ padding: '70px 0% 20px' }}>
							<div id="classSlider" className="col-sm-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<div style={{ width: '90%', paddingLeft: 20 }}>
									<form id="classAddForm" className="form-horizontal">
										<div className="form-group">
											<label htmlFor="inp_class" className="control-label col-sm-6">Class:</label>
											<div className="col-sm-6 padding-remove">
												<select className="form-control" id="inp_class">
												</select>
											</div>
										</div>

										<div className="text-center" style={{ marginTop: 30, marginBottom: 30 }}>
											<button type="button" onClick={this.saveClass} className="btn btn-info">Save</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="subjectPopup" style={{ width: '33%', left: '77%', height: '53%' }}>
					<header style={{ width: '90%' }}>
						<h3 id="subjectHeader">Add/Edit Subject</h3>
						<a href="#0" className="cd-panel-close glyphicon glyphicon-remove" onClick={this.hideSubjectSlider}>Close</a>
					</header>

					<div style={{ width: '90%' }}>
						<div className="cd-panel-content" style={{ padding: '70px 0% 20px' }}>
							<div id="subjectSlider" className="col-sm-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<div style={{ width: '90%', paddingLeft: 20 }}>
									<form id="subjectAddForm" className="form-horizontal">
										<div className="form-group">
											<label htmlFor="inp_subject" className="control-label col-sm-6">Subject:</label>
											<div className="col-sm-6 padding-remove">
												<select className="form-control" id="inp_subject">
												</select>
											</div>
										</div>

										<div className="text-center" style={{ marginTop: 30, marginBottom: 30 }}>
											<button type="button" onClick={this.saveSubject} className="btn btn-info">Save</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default TeacherDtls;
